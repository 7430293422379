import React, { useState } from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import api from "services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";

CollaboratorModal.propTypes = {
  show: PropTypes.bool,
  eventId: PropTypes.number,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

const formSchema = yup.object({
  name: yup.string().trim().required("O nome é obrigatório."),
  email: yup
    .string()
    .email("O e-mail informado deve ser válido.")
    .required("O e-mail é obrigatório."),
  lastname: yup.string().trim().required("O sobrenome é obrigatório."),
});

export default function CollaboratorModal(props) {
  const { show, eventId, onClose, onError, onSuccess } = props;

  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [disableInputs, setDisableInput] = useState(false);

  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const email = watch("email");

  const handleClose = () => {
    if (!loadingForm) {
      onClose();
    }
  };

  const handleSubmitForm = (form) => {
    setLoadingForm(true);

    const data = {
      nome: form.name,
      sobrenome: form.lastname,
      email: form.email,
      event_id: eventId,
    };

    api
      .post("collaborators", data)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        onError(err.response.data);
      })
      .finally(() => setLoadingForm(false));
  };

  const checkEmail = () => {
    setLoadingUser(true);

    api
      .get(`/verify/user/${email}`)
      .then((res) => {
        if (res.data) {
          setValue("name", res.data.first_name);
          setValue("lastname", res.data.last_name);
          setDisableInput(true);
        } else {
          setDisableInput(false);
        }
      })
      .catch(() => setDisableInput(false))
      .finally(() => setLoadingUser(false));
  };

  useEffect(() => {
    reset({ email: "", name: "", lastname: "" });
    setDisableInput(false);
  }, [show, reset]);

  return (
    <div
      className={`shadow side-modal ${
        show ? "side-modal-visible" : "side-modal-hidden"
      }`}
    >
      <div className="card-header">
        <h4 className="card-header-title">
          <FontAwesomeIcon icon={faUser} className="ml-2 mr-2" />
          Adicionar colaborador
        </h4>
        <button
          onClick={handleClose}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div className="col-12 mt-2">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="form-row">
            <div className="col-12 form-group">
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                type="text"
                className={
                  errors.email?.message
                    ? "form-control is-invalid"
                    : "form-control"
                }
                {...register("email")}
                onBlur={() => checkEmail()}
              />
              <small className="text-danger">{errors.email?.message}</small>
            </div>
          </div>
          <div className="form-row">
            <div className="col-6 form-group">
              <label htmlFor="name">Nome</label>
              <input
                id="name"
                type="text"
                disabled={disableInputs}
                className={
                  errors.name?.message
                    ? "form-control is-invalid"
                    : "form-control"
                }
                {...register("name")}
              />
              <small className="text-danger">{errors.name?.message}</small>
            </div>
            <div className="col-6 form-group">
              <label htmlFor="lastname">Sobrenome</label>
              <input
                id="lastname"
                type="text"
                disabled={disableInputs}
                className={
                  errors.lastname?.message
                    ? "form-control is-invalid"
                    : "form-control"
                }
                {...register("lastname")}
              />
              <small className="text-danger">{errors.lastname?.message}</small>
            </div>
          </div>
          <div className="form-row justify-content-end mt-2">
            <div className="col-4 offset-6 form-group">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loadingUser || loadingForm}
              >
                {loadingUser
                  ? "Buscando..."
                  : loadingForm
                  ? "Processando..."
                  : "Adicionar"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
