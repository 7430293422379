import moment from "moment";
import "moment-timezone";

const originalTz = "America/Sao_Paulo";

const defaultOptions = {
  targetTz: originalTz,
  originalTz,
};

/**
 *
 * @param {Date} date
 * @param {object} options
 * @param {string?} options.targetTz
 * @param {string?} options.originalTz
 *
 * @returns {string}
 */
export const formatTime = (date, options) => {
  const { targetTz, originalTz } = { ...defaultOptions, ...options };
  const originalDateTime = moment.tz(date, originalTz);
  const convertedDateTime = originalDateTime.clone().tz(targetTz);
  return convertedDateTime.format("YYYY-MM-DD");
};

export const getAllTimezones = () => {
	const allTimezones = moment.tz.names().map((timezone) => {
		const offset = moment.tz(timezone).format("Z")
		return {
			timezone,
			offset,
			formatted: `(GMT${offset}) ${timezone}`
		}
	}).sort((a, b) => {
		const aOffset = Number(a.offset.split(':')[0]);
		const bOffset = Number(b.offset.split(':')[0]);
		if (aOffset < bOffset) return -1;
		if (aOffset > bOffset) return 1;
		return 0;
	});

	return allTimezones;
}

