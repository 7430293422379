import styled from "styled-components";

export const Container = styled.div`
  border: ${({ isTransferPage }) =>
    isTransferPage ? "none" : "1px solid #b7b6bf"};
  height: 102px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ isTransferPage }) =>
    isTransferPage ? "white" : "initial"};

  h3 {
    margin: 0;
    margin-left: 24px;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #171719;
  }

  .tp-container {
    color: #3a3940;
    margin: 0;
    margin-left: 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
`;
