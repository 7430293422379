import React from "react";
import csswelcome from "cssdinamico";
import Assentos from "components/cadastroevento/assentos";
import api from "services/api";
import Swal from "sweetalert2";

export default class CadastroAssentos extends React.Component {
  constructor(props) {
    super(props);
    csswelcome();

    const urlParams = new URLSearchParams(window.location.search);
    const event_id = urlParams.get("event_id");
    this.state = {
      event_id,
      sector_id: null,
      rows: 1,
      columns: 1,
      seatRows: [],
    };

    if (event_id) {
      api.get(`event/seats-form/${event_id}`).then((res) => {
        let { sector_id } = res.data;
        this.setState({ sector_id });
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      });
    }
  }

  generateSeatRows = (rows, columns) => {
    let charCode = 65; // a
    let { event_id, sector_id } = this.state;
    let seatRows = [];
    for (var i = 0; i < rows; i++) {
      let row = {
        event_id,
        label: String.fromCharCode(charCode + i),
        seats: [],
      };
      for (let j = 1; j <= columns; j++) {
        let seat = {
          sector_id,
          number: j,
          disabled: 0,
          isReserved: 0,
          isSelected: 1,
        };
        row.seats.push(seat);
      }
      seatRows.push(row);
    }
    this.setState({ seatRows });
  };

  saveSeats = () => {
    let { event_id, seatRows } = this.state;
    api
      .post(`event/create-seats`, {
        event_id,
        seatRows,
      })
      .then((res) => {
        Swal.fire(
          "Sucesso!",
          "Os assentos foram criados com sucesso!",
          "success"
        ).then((result) => {
          window.location.pathname = "/eventos";
        }).catch(() => {
          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })
        });
      })
      .catch((e) => {
        Swal.fire("Erro", "Erro ao gravar os dados.", "error");
      });
  };

  render() {
    return (
      <div className="content">
        <div className="card-content">
          <div className="card-body">
            <h1>Cadastro de Lugares</h1>
            <form>
              <div className="form-row mb-3">
                <div className="form-group col-md-4">
                  <label htmlFor="rows">Fileiras</label>
                  <input
                    type="number"
                    className="form-control inputs"
                    id="rows"
                    value={this.state.rows}
                    onChange={(e) => {
                      let rows = e.target.value <= 0 ? 1 : e.target.value;
                      this.setState({ rows });
                    }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="columns">Lugares</label>
                  <input
                    type="number"
                    className="form-control inputs"
                    id="columns"
                    value={this.state.columns}
                    onChange={(e) => {
                      let columns = e.target.value <= 0 ? 1 : e.target.value;
                      this.setState({ columns });
                    }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-info"
                    disabled={!this.state.sector_id}
                    onClick={() => {
                      this.generateSeatRows(
                        this.state.rows,
                        this.state.columns
                      );
                    }}
                    style={{ width: "100%" }}
                    type="button"
                  >
                    Gerar Lugares
                  </button>
                </div>
              </div>
              {this.state.seatRows.length > 0 && (
                <div className="form-row">
                  <div className="col-md-12">
                    <label>
                      Assentos (clique no assento para ativar/desativar a venda
                      do lugar)
                    </label>
                    <Assentos
                      seatRows={this.state.seatRows}
                      updateState={() => {
                        this.setState({ seatRows: this.state.seatRows });
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-info"
                      onClick={this.saveSeats}
                      style={{ width: "100%" }}
                      type="button"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
