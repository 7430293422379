import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuperCheckbox from 'components/super-checkbox';
import React, { useEffect, useState } from 'react'
import EventCreateHeader from '../../event-create-header';

const EventCreateStep3 = (props) => {

  const [stepData, setStepData] = useState(props.dates);

  useEffect(() => {

    if (!props.stepCheck) {
      return;
    }

    props.callBack(stepData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stepCheck]);

  return (
    <>
      <EventCreateHeader
        title="3. Configurações gerais"
        text="configure suas vendas. <a href='/evento-help' target='_blank'>Saiba mais como configurar.</a>"
      >
      </EventCreateHeader>

      <div className="mt-4 event-step">
        <div className="col-12 pl-0">
          <div className="card mt-0">
            <div className="card-body">
              <b>Configurações dos ingressos</b>
              <hr />
              <p className="form-label">
                Selecione os tipos de ingresso disponiveis para cada setor.
              </p>
              {stepData.map((sector, sector_i) => (
                <form key={sector_i}>
                  <div className="form-row">
                    <div className="col-md-12 mb-2 form-label">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;
                      {sector.name}
                    </div>
                  </div>
                  <div className="pl-2 border-left">
                    <div className="tickets-inputs">
                      <div className="row p-2">
                        {sector.ticketTypes.map((type, type_i) => (
                          <div key={type_i} className="mt-1 col-md-3" style={{ maxWidth: '200px' }}>
                            <SuperCheckbox
                              name={`ticketType-${type_i}`}
                              value={type.active}
                              label={type.name}
                              callback={value => {
                                type.active = value;
                                setStepData([...stepData]);
                              }}
                              space={true}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              ))}
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default EventCreateStep3;
