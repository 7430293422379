import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import Lottie from "react-lottie";
import animationData from "../lotties/load.json";

const animationConfigs = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const dataType = PropTypes.shape({
  id: PropTypes.any,
  className: PropTypes.string,
  infos: PropTypes.arrayOf(
    PropTypes.shape({
      sectionId: PropTypes.any,
      value: PropTypes.any,
      className: PropTypes.string,
    })
  ),
});

const columnType = PropTypes.shape({
  title: PropTypes.string,
  className: PropTypes.string,
});

SimpleTable.propTypes = {
  borders: PropTypes.bool,
  rows: PropTypes.arrayOf(dataType),
  footer: PropTypes.arrayOf(dataType),
  sections: PropTypes.arrayOf(dataType),
  columns: PropTypes.arrayOf(columnType),
  loading: PropTypes.bool,
  tableClassName: PropTypes.string,
  onItemClick: PropTypes.func,
  emptyText: PropTypes.string,
};

export default function SimpleTable(props) {
  const {
    borders,
    rows,
    footer,
    columns,
    sections,
    loading,
    onItemClick,
    emptyText,
    tableClassName,
  } = props;

  const handleClickItem = (id) => {
    if (onItemClick) {
      onItemClick(id);
    }
  };

  const renderRowsBySection = (sectionId) => {
    const sectionRows = rows.filter((row) => row.sectionId === sectionId);

    return (
      <>
        {sectionRows.map((item, i) => (
          <tr
            key={i}
            className={`${item.className || ""} ${
              onItemClick ? "pointer" : ""
            }`}
            onClick={() => handleClickItem(item.id)}
          >
            {item.infos.map((info, index) => (
              <td
                key={index}
                className={`${info.className || ""} ${
                  i === 0 ? "no-borders" : ""
                } ${borders ? "edge-borders" : ""}`}
              >
                {info.value}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  if (loading) {
    return (
      <div className="mt-5">
        <Lottie options={animationConfigs} height={50} width={50} />
      </div>
    );
  }

  return (
    <div className="table table-responsive simpletablecontainer">
      <table className={`table table-sm simpletable ${tableClassName || ""}`}>
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index} className={item.className || ""}>
                {item.title}
              </th>
            ))}
          </tr>
        </thead>

        {rows.length === 0 ? (
          <tbody>
            <tr>
              <td colspan={columns.length}>
                {emptyText || "Nenhum resultado encontrado."}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {sections ? (
              <>
                {sections.map((section, i) => (
                  <Fragment key={i}>
                    <tr className="section">
                      {section.infos.map((info, index) => (
                        <td key={index} className={info.className || ""}>
                          {info.value}
                        </td>
                      ))}
                    </tr>

                    {renderRowsBySection(section.id)}
                  </Fragment>
                ))}
              </>
            ) : (
              renderRowsBySection()
            )}

            {footer && (
              <>
                <tr className="spacer"></tr>
                {footer.map((item, i) => (
                  <tr key={i} className="footer">
                    {item.infos.map((info, index) => (
                      <td key={index} className={info.className || ""}>
                        {info.value}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
}
