import React from "react";
import Modal from "react-modal";
import "./styles.css";

Modal.setAppElement("#root");

export default function CentralizedModal({
  children,
  isOpen,
  onRequestClose,
  ...restProps
}) {
  return (
    <Modal
      overlayClassName="overlay-container-modal"
      className="container-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...restProps}
    >
      {children}
    </Modal>
  );
}
