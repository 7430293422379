import React, { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastr';
import Swal from 'sweetalert2';

import ButtonContained from 'components/buttonContained';
import IntegrationCard from 'components/integrationCard';
import Loading from 'components/loading';
import NewPageHeader from 'components/newPageHeader';
import NewSelect from 'components/newSelect';
import useQuery from 'customerHooks/useQuery';
import New_api from 'services/new-api';

import { ReactComponent as AddCircleWhite } from '../../assets/icons/AddCircleWhite.svg';
import ConfigurationModalContent from './configuration-modal-content';
import IntegrationModal from './IntegrationModal';
import InformationsConfig from './IntegrationModal/informationsConfig';
import ModalContent from './modal-content';

import './styles.css';
import NewAlert from 'components/newAlert';
import { useTranslation } from 'react-i18next';

let container;

const isWhitelabel = Number(process.env.REACT_APP_IS_WHITELABEL);

const initialFormState = {
  action_type_id: '',
  address: '',
  discount_id: '',
  expected: 'true',
  informations: '',
  verb: 'get',
  name: '',
  headers: [
    {
      key: '',
      value: '',
    },
  ],
  parameters: [
    {
      key: '',
      value: '',
    },
  ],
};

const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API;

const addCustomOption = (options) => {
  if (options.length > 0) {
    const fullOptions = [
      ...options,
      // { value: options.length + 1, label: "Personalizado" },
    ];
    return fullOptions;
  } else {
    return options;
  }
};

export default function Integrations() {
  const [modalStep, setModalStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [type, setType] = useState('');
  const [customIntegrations, setCustomIntegrations] = useState([]);
  const [integrationCards, setIntegrationCards] = useState({
    meta: { checked: false, type: 'marketing' },
    analytics: { checked: false, type: 'marketing' },
    ads: { checked: false, type: 'marketing' },
    ...(!isWhitelabel && {publicApi: { checked: false, type: 'general' }})
  });
  const [valueOptions, setValueOptions] = useState([]);
  const [integrationBody, setIntegrationBody] = useState(initialFormState);
  const [integrationId, setIntegrationId] = useState(null);
  const query = useQuery();
  const eventId = query.get('event_id');
  const [formValues, setFormValues] = useState({
    parameters: {},
  });
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [expiredToken, setExpiredToken] = useState(false);
  const [tokenId, setTokenId] = useState(null);

  let isCompleted = false;

  const { t } = useTranslation('integrations');

  const categoriesFilterOptions = [
    { value: '', label: t('filter.options.all') },
    { value: 'custom', label: t('filter.options.custom') },
    { value: 'marketing', label: t('filter.options.marketing') },
  ];

  const [options, setOptions] = useState(categoriesFilterOptions);

  const loadData = () => {
    getCustomIntegrations();
    getIntegrations();
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [refresh]);

  const [customFields, setCustomFields] = useState({});

  //TODO: Set these labels on frontend and have the options arrive as the backend value, maybe have both the key and labels arriving from the backend
  const labelMap = {
    Nome: 'first_name',
    Sobrenome: 'last_name',
    'E-mail do usuário': 'email',
  };

  const labelMapRevert = (option, index) => {
    const customFieldValue = valueOptions.length;
    const fieldNameToLabelMap = Object.keys(labelMap).reduce((acc, label) => {
      const fieldName = labelMap[label];
      acc[fieldName] = label;
      return acc;
    }, {});
    const foundOption = valueOptions.find(
      (valueOption) => fieldNameToLabelMap[option] === valueOption.label
    );

    if (foundOption) {
      return foundOption.value;
    } else {
      setCustomFields((prevState) => ({ ...prevState, [index]: option }));
      return customFieldValue;
    }
  };

  const getCustomIntegrations = async () => {
    setIsLoading(true);
    const res = await New_api.get(`/custom-integrations/${eventId}`);
    const customIntegrations = res.data.data.map((customIntegration) => ({
      ...customIntegration,
      text: t('cards.custom.description'),
      checked: !!customIntegration.active,
    }));
    if (res.data.data.length === 0) {
      setOptions((prevState) =>
        prevState.filter((state) => state.value !== 'custom')
      );
    }
    setIsLoading(false);
    setCustomIntegrations(customIntegrations);
  };

  const getIntegrations = async () => {
    setIsLoading(true);

    let isDateExpired = false;

    const {
      data: {
        g_id_ads,
        g_conversion_snippet_ads,
        g_analytics_code,
        fb_domain_verification_code,
        fb_pixel_id,
      },
    } = await New_api.get(`/events/${eventId}`, {
      params: {
        fields: [
          'g_id_ads',
          'g_conversion_snippet_ads',
          'g_analytics_code',
          'fb_domain_verification_code',
          'fb_pixel_id',
        ],
      },
    });

    let tokenData = await handleGetToken().then((res) => {
      let data = res

      if (!res) {
        data = {
          data: {
            active: false,
            expires_at: null,
            token: null,
          },
        };
      }

      return data
    });

    const { token, active, expires_at, id } = tokenData;
    setTokenId(id);
    isDateExpired = new Date(expires_at) <= new Date();

    if (token && isDateExpired) {
      setExpiredToken(isDateExpired);
    }

    setIntegrationCards({
      meta: {
        infos: { fb_domain_verification_code, fb_pixel_id },
        checked: !!fb_pixel_id,
        type: 'marketing',
      },
      analytics: {
        infos: { g_analytics_code },
        checked: !!g_analytics_code,
        type: 'marketing',
      },
      ads: {
        infos: { g_id_ads, g_conversion_snippet_ads },
        checked: !!(g_id_ads && g_conversion_snippet_ads),
        type: 'marketing',
      },
      ...(!isWhitelabel && {publicApi: {
        infos: {
          token,
          buyers_url: PUBLIC_API_URL + 'tickets',
          checkin_url: PUBLIC_API_URL + 'checkins',
          expire_date: expires_at,
        },
        checked: isDateExpired ? true : !!active,
        type: 'general',
      }})
    });

    setIsLoading(false);
  };

  const handleGetToken = async () => {
    const {
      data: { data },
    } = await New_api.get(`/events/${eventId}/public-api-tokens`);

    const activeToken = data.filter((token) => token.active === 1);

    return activeToken[0];
  };

  const handleUpdateToken = async () => {
    Swal.fire({
      icon: 'warning',
      title: t('cards.publicApi.updateToken.title'),
      text: t('cards.publicApi.updateToken.description'),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: t('cards.publicApi.updateToken.confirmButton'),
      cancelButtonText: t('cards.publicApi.updateToken.cancelButton'),
      confirmButtonColor: '#0050C3',
      cancelButtonColor: '#95aac9',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await New_api.patch(`/public-api-tokens/${tokenId}`, { active: false });

        await New_api.post(`/events/${eventId}/public-api-tokens`).then(() => {
          container.success(t('cards.publicApi.updateToken.successToken'));
          setRefresh(true);
          onClose();
        });
        setTimeout(() => {
          handleEditIntegration('publicApi');
          setExpiredToken(false);
        }, 500);
      }
    });
  };

  const handleToggleIntegration = (type, checked) => {
    setType(type);

    const toggleIntegration = async () => {
      setIntegrationCards((prevState) => ({
        ...prevState,
        [type]: { ...prevState, checked: !prevState[type].checked },
      }));

      if (type === 'publicApi') {
        await New_api.patch(`/public-api-tokens/${tokenId}`, { active: false });
      }

      const params = {
        ads: {
          g_id_ads: null,
          g_conversion_snippet_ads: null,
        },
        analytics: {
          g_analytics_code: null,
        },
        meta: {
          fb_domain_verification_code: null,
          fb_pixel_id: null,
        },
      };

      await New_api.put(`/events/${eventId}`, params[type]);
    };

    if (!checked) {
      setIsOpen(true);
      setModalStep(0);
    }
    if (checked) {
      Swal.fire({
        icon: 'warning',
        title: t('common.disableIntegration.title'),
        text: t('common.disableIntegration.description'),
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: t('common.disableIntegration.confirmButton'),
        cancelButtonText: `<i class="fas fa-long-arrow-alt-left"></i> ${t(
          'common.disableIntegration.cancelButton'
        )}`,
        confirmButtonColor: '#0050C3',
        cancelButtonColor: '#95aac9',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await toggleIntegration()
            .then(() =>
              container.success(t('common.disableIntegration.success'))
            )
            .catch(() => {
              container.error(t('common.disableIntegration.error'));
              setIntegrationCards((prevState) => ({
                ...prevState,
                [type]: { checked: !prevState[type].checked },
              }));
            });
        }
      });
    } else {
      setIntegrationCards((prevState) => ({
        ...prevState,
        [type]: { checked: !prevState[type].checked },
      }));
    }
  };

  const handleToggleCustomIntegration = async (id, checked) => {
    const toggleIntegration = async () => {
      setCustomIntegrations((prevState) =>
        prevState.map((item) => {
          if (item.id === id) {
            return { ...item, checked: !item.checked, active: !item.checked };
          }
          return item;
        })
      );
      await New_api.put(`custom-integrations/toggle/${id}/${eventId}`).catch(
        () => {
          setCustomIntegrations((prevState) =>
            prevState.map((item) => {
              if (item.id === id) {
                return { ...item, checked: !item.checked };
              }
              return item;
            })
          );
        }
      );
    };

    if (checked) {
      Swal.fire({
        icon: 'warning',
        title: t('common.disableIntegration.title'),
        text: t('common.disableIntegration.descriptionCustom'),
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: t('common.disableIntegration.confirmButton'),
        cancelButtonText: `<i class="fas fa-long-arrow-alt-left"></i> ${t(
          'common.disableIntegration.cancelButton'
        )}`,
        confirmButtonColor: '#0050C3',
        cancelButtonColor: '#95aac9',
      })
        .then((result) => {
          if (result.isConfirmed) {
            toggleIntegration();
            container.success(t('common.disableIntegration.success'));
          }
        })
        .catch(() => {
          container.error(t('common.disableIntegration.error'));
        });
    } else {
      await toggleIntegration()
        .then(() => {
          container.success(t('common.enableIntegration.success'));
        })
        .catch(() => {
          container.error(t('common.enableIntegration.error'));
        });
    }
  };

  const handleEditIntegration = (type) => {
    setType(type);
    setModalStep(0);
    setIsOpen(true);
  };

  const handleFilterToggle = (item) => {
    setFilter(item.value);
  };

  const onClose = (isDefaultIntegration) => {
    setIsOpen(false);
    setIntegrationBody(initialFormState);
    setCustomFields({});
    setIntegrationId(null);

    if (isDefaultIntegration === true) {
      getIntegrations();
    }

    if (!isCompleted && type !== 'custom' && modalStep === 0) {
      if (!integrationCards[type].infos) {
        setIntegrationCards((prevState) => ({
          ...prevState,
          [type]: { ...prevState, checked: !prevState[type].checked },
        }));
      }
    }

    setRefresh(false);
  };

  const loadOptions = async () => {
    try {
      const res = await New_api.get('/custom-integration-parameters');
      const options = res.data.data.map((item) => {
        return { value: item.id, label: item.description };
      });
      return options;
    } catch {}
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const newOptions = await loadOptions();
      setValueOptions(addCustomOption(newOptions));
    };

    fetchOptions();
    // eslint-disable-next-line
  }, []);

  const loadStep = () => {
    const {
      name,
      address,
      expected,
      action_type_id,
      discount_id,
      informations,
      parameters,
      headers,
      verb,
    } = integrationBody;
    switch (modalStep) {
      case 0:
        return (
          <ConfigurationModalContent
            type={type}
            integration={integrationCards}
            onSubmit={
              type === 'publicApi' ? handleSubmitPublicApi : handleSubmitForm
            }
            expired={expiredToken}
            onUpdateToken={handleUpdateToken}
            onClose={onClose}
          />
        );
      default:
      case 1:
        return (
          <InformationsConfig
            eventId={eventId}
            onSubmit={handleSubmitNext}
            defaultValues={{
              name,
              verb,
              address,
              expected,
              action_type_id,
              discount_id,
              informations,
            }}
          />
        );
      case 2:
        return (
          <ModalContent
            onSubmit={handleSubmitParameters(handleSubmitNext)}
            type='parameters'
            handleBackClick={handleSubmitParameters(handleBackClick)}
            defaultValues={{ parameters }}
            options={valueOptions}
            customFieldValueProp={customFields}
          />
        );
      case 3:
        return (
          <ModalContent
            onSubmit={handleSubmit}
            type='headers'
            integrationId={integrationId}
            handleBackClick={handleBackClick}
            defaultValues={{ headers }}
            isLoading={isLoadingSubmit}
          />
        );
    }
  };

  const handleBackClick = (form) => {
    setIntegrationBody((prevState) => ({ ...prevState, ...form }));
    setModalStep((prevState) => prevState - 1);
  };

  const handleSubmitNext = (form) => {
    setIntegrationBody((prevState) => ({ ...prevState, ...form }));
    setModalStep((prevState) => prevState + 1);
  };

  const handleSubmitParameters = (action) => (form) => {
    setFormValues((prevState) => ({
      ...prevState,
      parameters: extractValues(form, 'parameters', true),
    }));
    action(form);
  };

  const extractValues = (form, type, useLabels = false) => {
    let result = {};
    form[type].forEach((row, index) => {
      let value;
      if (useLabels) {
        const label = form.selectedLabels[index];
        value = label in labelMap ? labelMap[label] : label;
        if (+row.value === valueOptions.length) {
          setCustomFields((prevState) => ({ ...prevState, [index]: value }));
        } else {
          setCustomFields((prevState) => {
            const newState = { ...prevState };
            delete newState[index];
            return newState;
          });
        }
      } else {
        value = row.value;
      }
      if (row.key) {
        result[row.key] = value;
      }
    });
    return result;
  };

  const handleSubmit = (form, integrationId) => {
    setIsLoadingSubmit(true);

    const body = {
      ...integrationBody,
      ...form,
      event_id: eventId,
      action_type_id: 1,
      expected: 'true',
      headers: extractValues(form, 'headers'),
      parameters: formValues.parameters,
    };
    delete body.selectedLabels;

    setIntegrationBody((prevState) => ({ ...prevState, ...form }));

    const finalizeRequest = () => {
      setIntegrationBody(initialFormState);
      setIsLoadingSubmit(false);
      loadData();
      setIsOpen(false);
      setModalStep(1);
      setIntegrationId(null);
    };

    if (integrationId) {
      New_api.put(
        `custom-integrations/${integrationId}?event_id=${eventId}`,
        body
      )
        .then(() => container.success(t('common.editIntegration.success')))
        .catch(() => {
          container.error(t('common.editIntegration.error'));
        })
        .finally(finalizeRequest);
    } else {
      New_api.post('custom-integrations', body)
        .then(() => container.success(t('common.createIntegration.success')))
        .catch(() => {
          container.error(t('common.createIntegration.error'));
        })
        .finally(finalizeRequest);
    }
  };

  const handleSubmitPublicApi = async () => {
    await New_api.post(`/events/${eventId}/public-api-tokens`)
      .then(() => {
        container.success(t('common.enableIntegration.success'));
        isCompleted = true;
      })
      .catch(() => {
        container.error(t('common.enableIntegration.error'));
      })
      .finally(() => {
        onClose(isCompleted);
      });

    setTimeout(() => {
      handleEditIntegration('publicApi');
    }, 500);
  };

  const handleSubmitForm = async (form) => {
    const params = {
      ads: {
        g_id_ads: form.adsConversionId,
        g_conversion_snippet_ads: form.adsConversionSnippet,
      },
      analytics: {
        g_analytics_code: form.analyticsCode,
      },
      meta: {
        fb_domain_verification_code: form.metaCodeVerifier,
        fb_pixel_id: form.metaPixelId,
      },
    };

    await New_api.put(`/events/${eventId}`, params[type])
      .then(() => {
        container.success(t('common.enableIntegration.success'));
        isCompleted = true;
      })
      .catch(() => {
        container.error(t('common.enableIntegration.error'));
      })
      .finally(() => {
        onClose(isCompleted);
      });
  };

  const handleOpenIntegrationModal = (integration) => {
    if (integration) {
      const { headers, parameters, id, informations } = integration;
      const params = JSON.parse(parameters);
      // eslint-disable-next-line
      const heads =
        headers !== '{}' ? JSON.parse(headers) : { [String('')]: '' };

      const paramsArray = Object.entries(params).map(([key, value], index) => {
        return {
          key,
          value: labelMapRevert(value, index),
        };
      });

      const headersArray = Object.entries(heads).map(([key, value]) => {
        return {
          key,
          value,
        };
      });
      setIntegrationId(id);
      setIntegrationBody({
        ...integration,
        action_type_id: 'Habilitar cupom de desconto',
        headers: headersArray,
        parameters: paramsArray,
        informations: informations ? informations : '',
      });
    }
    setIsOpen((state) => !state);
    setModalStep(1);
  };

  return (
    <div>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className='toast-top-right'
      />
      {isLoading ? (
        <Fragment>
          <Loading title={t('common.wait')} text={t('common.loadingText')} />
        </Fragment>
      ) : (
        <Fragment>
          <NewPageHeader
            title={t('header.title')}
            subtitle={t('header.subtitle')}
            children={
              <div className='integrations-button'>
                <ButtonContained
                  id='create-integration-button'
                  className='primary-contained'
                  onClick={() => handleOpenIntegrationModal()}
                  content={
                    <Fragment>
                      {t('header.actionButton')} <AddCircleWhite />
                    </Fragment>
                  }
                />
              </div>
            }
          />
          <div className='col-12 page-body'>
            {expiredToken && (
              <div className='mt-3 p-0 col-12 w-100'>
                <NewAlert
                  variant='warning'
                  children={
                    <Fragment>
                      <p>
                        {t('cards.publicApi.expiredToken.text')}
                        <button
                          onClick={() => handleEditIntegration('publicApi')}
                          className='ml-1 update-token-button'
                        >
                          {t('cards.publicApi.expiredToken.button')}
                        </button>
                      </p>
                    </Fragment>
                  }
                />
              </div>
            )}
            <div className='row mt-3'>
              <div className='col-md-4 col-12 col-sm-6'>
                <NewSelect
                  isSearchable={false}
                  options={options}
                  defaultValue={categoriesFilterOptions[0]}
                  onChange={(item) => handleFilterToggle(item)}
                  label={t('header.categoryFilter')}
                />
              </div>
            </div>
            <div className='row mt-4'>
              {customIntegrations.map((integration) => {
                if (filter !== 'marketing' && filter !== 'general') {
                  return (
                    <div
                      className='col-12 col-sm-6 col-md-4'
                      key={integration.id}
                    >
                      <IntegrationCard
                        type='custom'
                        checked={integration.checked || false}
                        onEditClick={() =>
                          handleOpenIntegrationModal(integration)
                        }
                        onSwitchToggle={() =>
                          handleToggleCustomIntegration(
                            integration.id,
                            integration.checked
                          )
                        }
                        name={integration.name}
                        text={integration.text}
                      />
                    </div>
                  );
                }

                return null;
              })}
              {Object.keys(integrationCards).map((type, index) => {
                if (filter === '' || filter === integrationCards[type].type) {
                  if (integrationCards[type].infos?.buyers_url && isWhitelabel) {
                    return null
                  }
                  return (
                    <div className='col-12 col-sm-6 col-md-4' key={index}>
                      <IntegrationCard
                        type={type}
                        checked={integrationCards[type].checked}
                        expired={expiredToken}
                        onSwitchToggle={() =>
                          handleToggleIntegration(
                            type,
                            integrationCards[type].checked
                          )
                        }
                        onEditClick={() => handleEditIntegration(type)}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </Fragment>
      )}
      <IntegrationModal
        isOpen={isOpen}
        onClose={onClose}
        title={`${integrationId ? t('common.edit') : t('common.makeNew')} ${t(
          'common.integration'
        )}`}
      >
        {loadStep()}
      </IntegrationModal>
    </div>
  );
}
