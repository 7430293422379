import PropTypes from "prop-types";
import React from "react";
import { ToastContainer } from "react-toastr";

Toast.propTypes = {
  type: PropTypes.oneOf(["error", "info", "success", "warning"]),
  toastText: PropTypes.string,
  showToast: PropTypes.bool,
  setToastConfs: PropTypes.func,
  showTime: PropTypes.number,
};

export default function Toast(props) {
  let triggerToast;
  const { type, toastText, setToastConfs, showToast, showTime } = props;
  React.useEffect(() => {
    if (showToast) {
      triggerToast[type](toastText);
      setTimeout(() => {
        setToastConfs({ showToast: false });
        // eslint-disable-next-line no-magic-numbers
      }, showTime);
    }
    // eslint-disable-next-line
  }, [showToast]);

  return (
    <ToastContainer
      preventDuplicates
      // eslint-disable-next-line no-return-assign
      ref={(ref) => (triggerToast = ref)}
      className="toast-top-right"
    />
  );
}
