import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

Card.propTypes = {
  children: PropTypes.node,
  existsTitleBody: PropTypes.bool,
  header: PropTypes.string,
  titleBody: PropTypes.string,
};

export default function Card(props) {
  const { header, existsTitleBody, children, titleBody } = props;
  return (
    <div className="card mt-2">
      {header && (
        <div className="card-header">
          <h4 className="card-header-title">{header}</h4>
        </div>
      )}
      <div className="card-body">
        {existsTitleBody && <span className="title-body">{titleBody}</span>}
        {children}
      </div>
    </div>
  );
}
