import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { capitalizeEachFirstWord, capitalizeFirstWord } from "utils/str-format";

export function changeState(value) {
  let item = document.getElementsByClassName("subheader-item");
  for (let i = 0; i < item.length; i++) {
    item[i].classList.remove("active");
  }
  item[value].classList.add("active");
  return value;
}

const IngressoHeader = ({ position, evento }) => {
  const { t } = useTranslation("components", { keyPrefix: "subheader" });
  useEffect(() => {
    changeState(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return (
    <div className="col" id="subheader">
      <ul className="nav nav-tabs nav-overflow header-tabs">
        <li className="nav-item">
          <NavLink
            to={`/tickets/ingressos/evento?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={() => changeState(0)}
          >
            {capitalizeFirstWord(t("tickets"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`/tickets/courtesies?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={() => changeState(1)}
          >
            {capitalizeEachFirstWord(t("courtesies"))}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`/tickets/ingressos/grupos/evento?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={(e) => changeState(2)}
          >
            {t("passport")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`/tickets/ingressos/codigos/evento?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={(e) => changeState(3)}
          >
            {t("promo")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`/tickets/ingressos/comissarios/evento?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={(e) => changeState(4)}
          >
            {t("promoters")}
            {/* <span className="badge rounded-pill bg-secondary-soft">22</span> */}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`/tickets/ingressos/SortingOfSectors/evento?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={() => changeState(5)}
          >
            {t("sortSectors")}
            {/* <span className="badge rounded-pill bg-secondary-soft">22</span> */}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/tickets/ingressos/localizadores/evento?event_id=${evento}`}
            className="nav-link text-nowrap subheader-item"
            onClick={(e) => changeState(6)}
          >
            {t("locators")}
            {/* <span className="badge rounded-pill bg-secondary-soft">22</span> */}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
export default IngressoHeader;
