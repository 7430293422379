import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import styled from "styled-components";
import * as yup from "yup";

import ButtonContained from "components/buttonContained";
import FormField from "components/FormField";

import { ReactComponent as Add } from "../../../assets/icons/Add.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/ArrowRight.svg";
// import { ReactComponent as TrashCan } from "../../../assets/icons/editPencilNew.svg";
import { ReactComponent as TrashCan } from "../../../assets/icons/trashCan.svg";

import "./styles.css";

const switchStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  control: (control) => ({
    ...control,
    borderRadius: "8px",
    border: "1px solid #B7B6BF",
    cursor: "pointer",
  }),
  dropdownIndicator: (dropdownIndicator) => ({
    ...dropdownIndicator,
    color: "#525059",
  }),
  singleValue: (singleValue) => ({
    ...singleValue,
    color: "#171719",
  }),
};

const headerSchema = yup.object({
  headers: yup.array().of(
    yup.object().shape({
      key: yup.string().trim(),
      value: yup.string().trim(),
    })
  ),
});
const parameterSchema = yup.object({
  parameters: yup.array().of(
    yup.object().shape({
      key: yup.string().trim().required("A chave é obrigatória."),
      value: yup.string().trim().required("O valor é obrigatório."),
    })
  ),
});

const buttonText = {
  parameters: "parâmetro",
  headers: "cabeçalho",
};

// const createCustomValueContainer =
//   (
//     index,
//     setCustomFieldValue,
//     setShowField,
//     customFieldValues,
//     setMenuIsOpen
//   ) =>
//   (props) => {
//     const isCustomSelectField = !!customFieldValues[index];

//     const handleOnClick = (e) => {
//       e.preventDefault();
//       const toggleVisibility = (toggleFunction) =>
//         toggleFunction((prevState) => ({
//           ...prevState,
//           [index]: !prevState[index],
//         }));
//       if (isCustomSelectField) {
//         setCustomFieldValue(customFieldValues[index]);
//         toggleVisibility(setShowField);
//       } else {
//         toggleVisibility(setMenuIsOpen);
//       }
//     };

//     return (
//       <components.ValueContainer {...props}>
//         <ContainerEmptyStyle onClick={handleOnClick} onTouchEnd={handleOnClick}>
//           {props.children}
//         </ContainerEmptyStyle>
//       </components.ValueContainer>
//     );
//   };

// const createCustomSingleValue = (customValue) => (props) => {
//   return (
//     <components.SingleValue {...props}>
//       {customValue ? (
//         <Fragment>
//           {customValue} <EditPencil />
//         </Fragment>
//       ) : (
//         props.children
//       )}
//     </components.SingleValue>
//   );
// };

const Spinner = () => (
  <span
    className="spinner-border spinner-border-sm mx-1"
    role="status"
    aria-hidden="true"
  />
);

const createCustomIndicatorContainer = (setMenuIsOpen, index) => (props) => {
  const onIndicatorClick = (e) => {
    e.preventDefault();
    setMenuIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <components.IndicatorsContainer {...props}>
      <ContainerEmptyStyle
        onClick={onIndicatorClick}
        onTouchEnd={onIndicatorClick}
      >
        {props.children}
      </ContainerEmptyStyle>
    </components.IndicatorsContainer>
  );
};

ModalContent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["parameters", "headers"]).isRequired,
  integrationId: PropTypes.number,
  handleBackClick: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  customFieldValueProp: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default function ModalContent({
  onSubmit,
  type,
  integrationId,
  handleBackClick,
  defaultValues,
  options,
  customFieldValueProp,
  isLoading,
}) {
  // const [showField, setShowField] = useState({});
  // const [customFieldValue, setCustomFieldValue] = useState("");
  const [customFieldValues, setCustomFieldValues] = useState({});
  const [menuIsOpen, setMenuIsOpen] = useState({});
  const [isLastSelectMenuPlacementTop, setIsLastSelectMenuPlacementTop] =
    useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid },
  } = useForm({
    defaultValues: defaultValues,
    mode: "all",
    resolver: yupResolver(type === "headers" ? headerSchema : parameterSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: type,
  });

  const addFields = () => {
    append({
      key: "",
      value: "",
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, type, reset]);

  const titleText = (type) => {
    switch (type) {
      default:
      case "parameters":
        return "Configurar parâmetros";
      case "headers":
        return "Configurar cabeçalho";
    }
  };

  const wrapperBackClick = () => {
    if (type === "parameters") {
      const values = getValues();
      let labels = mapParametersToLabels(values.parameters, options);
      labels = updateLabelsWithCustomValues(labels, customFieldValues);
      const submitData = prepareSubmitData(values, labels, type);
      handleBackClick(submitData);
    } else {
      handleBackClick(getValues());
    }
  };

  const handleChange = (e, index, field) => {
    const isVisible = e.value === options.length ? true : false;
    // setShowField((prevState) => ({
    //   ...prevState,
    //   [index]: isVisible,
    // }));
    if (!isVisible) {
      setCustomFieldValues((prevState) => ({
        ...prevState,
        [index]: null,
      }));
    }
    field.onChange(e.value);
    setMenuIsOpen((prevState) => ({ ...prevState, [index]: false }));
  };

  useEffect(() => {
    if (!!customFieldValueProp) {
      setCustomFieldValues(customFieldValueProp);
      setMenuIsOpen((prevState) => {
        const newState = { ...prevState };
        Object.keys(customFieldValueProp).forEach((key) => {
          newState[key] = false;
        });
        return newState;
      });
    }
  }, [customFieldValueProp]);

  useEffect(() => {
    const updateMenuPlacementForLastSelect = () => {
      const isMobile = window.innerWidth < 768;
      setIsLastSelectMenuPlacementTop(isMobile);
    };

    window.addEventListener("resize", updateMenuPlacementForLastSelect);
    return () =>
      window.removeEventListener("resize", updateMenuPlacementForLastSelect);
  }, [fields.length]);

  // const handleCustomFieldChange = (event, index) => {
  //   setCustomFieldValue(event.target.value);
  //   setMenuIsOpen((prevState) => ({ ...prevState, [index]: false }));
  // };

  const loadButtonText = (type, isLoading, id) => {
    switch (type) {
      default:
      case "parameters":
        return "Avançar";
      case "headers":
        if (isLoading) {
          return "Em andamento";
        }
        if (id) {
          return "Salvar alterações";
        } else {
          return "Criar Integração";
        }
    }
  };

  // const handleSaveCustomField = (index) => {
  //   setCustomFieldValues((prevState) => ({
  //     ...prevState,
  //     [index]: customFieldValue,
  //   }));
  //   setMenuIsOpen((prevState) => ({ ...prevState, [index]: false }));
  //   setCustomFieldValue("");
  //   setShowField((prevState) => ({
  //     ...prevState,
  //     [index]: false,
  //   }));
  // };

  const mapParametersToLabels = (parameters, options) => {
    let labels = {};
    parameters.forEach((parameter, index) => {
      const matchingOption = options.find(
        (option) => +option.value === +parameter.value
      );
      const label = matchingOption ? matchingOption.label : "";
      labels[index] = label;
    });
    return labels;
  };

  const updateLabelsWithCustomValues = (labels, customFieldValues) => {
    Object.keys(labels).forEach((key) => {
      if (customFieldValues.hasOwnProperty(key) && customFieldValues[key]) {
        labels[key] = customFieldValues[key];
      }
    });
    return labels;
  };

  const prepareSubmitData = (form, labels, type, integrationId) => {
    let submitData = { ...form };
    if (type === "parameters") {
      submitData.selectedLabels = labels;
    }
    return submitData;
  };

  const handleFormSubmit = async (form) => {
    if (type === "parameters") {
      let labels = mapParametersToLabels(form.parameters, options);
      labels = updateLabelsWithCustomValues(labels, customFieldValues);
      const submitData = prepareSubmitData(form, labels, type);
      onSubmit(submitData);
    } else {
      const submitData = prepareSubmitData(form, {}, type, integrationId);
      onSubmit(submitData, integrationId);
    }
  };

  const handleRemoveClick = (indexToRemove) => {
    remove(indexToRemove);
  };

  return (
    <div className="custom-integration-body sector hiddenX">
      <div className="integration-body-title">{titleText(type)}</div>
      {type === "parameters" && (
        <p className="m-0 p-0 integration-label-info">
          Essa integração irá checar se todos os parâmetros cadastrados abaixo
          são verdadeiros, ou seja, conferem com a resposta retornada pela sua
          URL.
        </p>
      )}

      <form className="form-content" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="fields-container">
          {fields.map((_, index) => (
            <div className="row" key={index}>
              <div className={"col-md-12"}>
                <div className={`row ${index > 0 ? "removable-row" : null}`}>
                  <div
                    className={`col-sm-12 ${
                      index > 0 ? "removable-row-item col-md-5" : "col-md-6"
                    }`}
                  >
                    <FormField
                      label="Chave"
                      controlId={`validationKey${index}.`}
                    >
                      <Form.Control
                        {...register(`${type}.${index}.key`)}
                        autoComplete="nope"
                        type="text"
                        placeholder="Digite a chave"
                      />
                    </FormField>
                  </div>
                  <div
                    className={`col-sm-12 ${
                      index > 0 ? "removable-row-item col-md-5" : "col-md-6"
                    }`}
                  >
                    <FormField
                      label="Valor"
                      controlId={`validationValue${index}.`}
                    >
                      {type === "parameters" ? (
                        <Controller
                          name={`${type}.${index}.value`}
                          control={control}
                          rules={{ required: "O valor é obrigatório." }}
                          render={({ field }) => (
                            <Fragment>
                              <Select
                                {...field}
                                menuIsOpen={menuIsOpen[index]}
                                className="col-12 p-0 value-field w-100"
                                onChange={(e) => handleChange(e, index, field)}
                                onBlur={field.onBlur}
                                placeholder="Selecione o valor"
                                options={options}
                                value={options.find(
                                  (option) => +option.value === +field.value
                                )}
                                isSearchable={false}
                                components={{
                                  IndicatorSeparator: () => null,
                                  // SingleValue: createCustomSingleValue(
                                  //   customFieldValues[index]
                                  // ),
                                  // ValueContainer: createCustomValueContainer(
                                  //   index,
                                  //   setCustomFieldValue,
                                  //   setShowField,
                                  //   customFieldValues,
                                  //   setMenuIsOpen
                                  // ),
                                  IndicatorsContainer:
                                    createCustomIndicatorContainer(
                                      setMenuIsOpen,
                                      index
                                    ),
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 1000000,
                                  }),
                                  ...switchStyles,
                                }}
                                menuPlacement={
                                  fields.length - 1 === index &&
                                  isLastSelectMenuPlacementTop
                                    ? "top"
                                    : "auto"
                                }
                              />
                              {/* {showField && showField[index] && (
                                <div className="custom-field">
                                  <div
                                    style={{
                                      borderBottom: "1px solid #EAE9ED",
                                    }}
                                    className="d-inline-flex flex-grow-1 ml-2 mr-2 pb-2 flex-nowrap align-items-center"
                                  >
                                    <div className="col-9 p-0">
                                      <input
                                        className="modal-content-custom-input"
                                        style={{ border: "none" }}
                                        autoComplete="nope"
                                        type="text"
                                        placeholder="Digite o valor"
                                        value={customFieldValue}
                                        onChange={(event) =>
                                          handleCustomFieldChange(event, index)
                                        }
                                      />
                                    </div>
                                    <div className="col-3 d-flex justify-content-end">
                                      <button
                                        className="custom-field-save-button"
                                        disabled={!customFieldValue?.length > 0}
                                        onClick={() =>
                                          handleSaveCustomField(index)
                                        }
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                            </Fragment>
                          )}
                        />
                      ) : (
                        <Form.Control
                          {...register(`${type}.${index}.value`)}
                          autoComplete="nope"
                          type="text"
                          placeholder="Digite o valor."
                        />
                      )}
                    </FormField>
                  </div>
                  {index > 0 && (
                    <button
                      className="remove-button"
                      type="button"
                      onClick={() => handleRemoveClick(index)}
                    >
                      <TrashCan stroke="#F53C5A" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          <hr />
          <div className="row justify-content-between align-items-center ">
            <div className="col-md-4 col-12">
              <AddFieldButton type="button" onClick={addFields}>
                <Add className="mr-2" /> {`Adicionar ${buttonText[type]}`}
              </AddFieldButton>
            </div>
            <div className="col-md-6 col-12 buttons-container">
              <div
                className={`button-modal-${
                  type === "headers" ? "submit" : "next"
                } p-0`}
              >
                <ButtonContained
                  type="submit"
                  disabled={!isValid || isLoading}
                  className={
                    isValid && !isLoading
                      ? "primary-contained"
                      : "disabled-contained-2"
                  }
                  content={
                    <Fragment>
                      {loadButtonText(type, isLoading, integrationId)}{" "}
                      {type === "parameters" ? (
                        <ArrowRight stroke={isValid ? "white" : "black"} />
                      ) : null}{" "}
                      {isLoading ? <Spinner /> : null}
                    </Fragment>
                  }
                />
              </div>
              <div className="button-modal-back p-0">
                <ButtonContained
                  type="button"
                  onClick={wrapperBackClick}
                  className="back-button"
                  content={
                    <Fragment>
                      <ArrowLeft strokeWidth={1.75} /> Voltar
                    </Fragment>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const AddFieldButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  color: #0050c3;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: transparent;
  &:hover {
  }
`;
const ContainerEmptyStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`;
