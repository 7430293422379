import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOnClickOutside } from "App";
import EmptyData from "components/empty-data";
import React, { useRef, useState } from "react";
import SelectSearch from "react-select-search";
import { ToastContainer } from "react-toastr";
import api from "services/api";
import Swal from "sweetalert2";

let container;

const ModalEventInventory = (props) => {
  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close();
    }
  });

  function close() {
    props.callback("modal-hidden");
  }

  const [itens, setItens] = useState([]);
  const [event, setEvent] = useState("");
  const [data, setData] = useState([]);

  function search() {
    if (event === "") {
      container.error("Selecione um evento antes de procurar");
      return;
    }

    api
      .get(`inventory/event/${event}`)
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div ref={ref} className={`shadow side-modal ${props.status}`}>
        <div className="card-header">
          <h4 className="card-header-title">
            Histórico de iventário por evento
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-10">
              <SelectSearch
                options={itens}
                search={true}
                name="ncm"
                placeholder="procure o evento desejado!."
                value={event}
                getOptions={(query) => {
                  api
                    .post("inventory/seachevent", { name: query })
                    .then((res) => {
                      let aux = [];
                      res.data.data.forEach((item) => {
                        aux.push({ name: item.name, value: String(item.id) });
                      });
                      setItens(aux);
                    })
                    .catch(() => {
                      Swal.fire({
                        title: "Ops!",
                        icon: "error",
                        text: "Ocorreu um erro interno, por favor tente novamente",
                        confirmButtonText: "Ok",
                      });
                    });
                }}
                emptyMessage="procure o evento desejado!."
                onChange={(e) => setEvent(e)}
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-secondary" onClick={search}>
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
              </button>
            </div>
          </div>
          {data.length === 0 ? (
            <EmptyData title="Nenhum resultado disponível." />
          ) : (
            <div className="row">
              <div className="col-12 table-responsive">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Local</th>
                      <th className="text-center">Tipo</th>
                      <th className="text-right">Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((history, i) => {
                      var status;
                      if (history.type === "entrada") {
                        status = "success";
                      } else {
                        status = "danger";
                      }
                      return (
                        <tr key={i}>
                          <td>{history.nome}</td>
                          <td className="text-center">
                            <span
                              className={"badge badge-pill badge-" + status}
                            >
                              {history.type}
                            </span>
                          </td>
                          <td className="text-right">{history.quantity}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalEventInventory;
