import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function SidebarCadastro(props) {
    useEffect(() => {
        let step = props.posicao;
        let paradas = document.getElementsByClassName('step-step');
        for (let index = 0; index < paradas.length; index++) {
            const element = paradas[index];
            element.classList.remove('step-active');
        }
        if (step === 1) {
            paradas[0].classList.add('step-active')
        }
        if (step === 2) {
            paradas[1].classList.add('step-active')
        }
        if (step === 3) {
            paradas[2].classList.add('step-active')
        }
        if (step === 4) {
            paradas[3].classList.add('step-active')
        }
    }, [props.posicao])
    return (
        <>
            <div className="col-md-12 col-lg-2">
                <div id="event-steps">
                    <div className="card-header container-register-user">
                        <h4 className="card-header-title">Passos para criação</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="step-step col-3 col-lg-12 step-active">
                                <div className="step-title">
                                    <span className="step-bullet">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                    <span>Dados básicos</span>
                                </div>
                                <span>
                                    <small>cadastro de informações básicas do evento</small>
                                </span>
                            </div>
                            <div className="step-step col-3 col-lg-12">
                                <div className="step-title">
                                    <span className="step-bullet"> 2 </span>
                                    <span>Setores, lotes e tipos</span>
                                </div>
                                <span>
                                    <small>configure os tipos de ingresso</small>
                                </span>
                            </div>
                            <div className="step-step col-3 col-lg-12">
                                <div className="step-title">
                                    <span className="step-bullet"> 3 </span>
                                    <span>Configurações gerais</span>
                                </div>
                                <span>
                                    <small>configure suas vendas</small>
                                </span>
                            </div>
                            <div className="step-step col-3 col-lg-12">
                                <div className="step-title">
                                    <span className="step-bullet"> 4 </span>
                                    <span>Preços e quantidades</span>
                                </div>
                                <span>
                                    <small>configure os valores e quantidades dos seus ingressos</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
