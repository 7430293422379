import React, { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faShare,
  faTimesCircle,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import SuperRadioButton from "components/super-radiobutton";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useOnClickOutside } from "App";
import { ToastContainer } from "react-toastr";
import Api from "services/api.js";
import BankAccountInfosModal from "components/bank-account-infos-modal";
import {
  antifraudRecommendation,
  antifraudStatus,
  orderStatusNames,
} from "services/constants";
import { numberMask } from "mask";
import { CHANNEL_LABELS } from "services/constants";
import "./style.css";
import { useLanguageSettings } from "hooks/language-settings";
import _ from "lodash";

let container;

const ModalTicket = (props) => {
  const {
    currencyInputFormatter,
    noCentsCurrencyFormatter,
    currencyFormatter,
  } = useLanguageSettings();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [participante, setParticipante] = useState({});
  const [drop, setDrop] = useState(false);
  const [ticket, setTicket] = useState([]);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);

  // Click outside
  let ref2 = useRef();
  useOnClickOutside(ref2, () => {
    if (drop) {
      setDrop(false);
    }
  });
  let ref = useRef();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -60%)",
      inset: "50% auto auto 50%;",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };

  const changeBadge = () => {
    switch (props.dataTicket?.statusSup) {
      case "finalizado":
        return "badge badge-success";
      case "estornado":
        return "badge badge-warning";
      case "não pagou" || "falhada":
        return "badge badge-danger";
      case "pendente":
        return "badge badge-dark";
      case "finalizada":
        return "badge badge-success";
      case "disputa":
        return "badge badge-warning";
      case "charged_back" || "charged back":
        return "badge badge-info";
      case "carrinho":
        return "badge badge-primary";
      case "expirado":
        return "badge badge-dark";
      // no default
    }

    return "badge bg-secondary-soft";
  };

  const changeStatus = (e) => {
    setDrop(false);

    Swal.fire({
      title: "Você tem certeza que deseja alterar o status desta venda?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, alterar!",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        if (props.dataTicket.statusSup) {
          props.dataTicket.statusSup = e;
        }

        let status = props.dataTicket?.statusSup.toLowerCase();
        let id = props.dataTicket?.orderId;

        Api.post("changeStatus", { status, id })
          .then((res) => {
            container.success(res.data.message);
            setTimeout(() => {
              props.fetchData();
            }, 1000);
          })
          .catch(() => {
            container.error("Erro ao alterar o status!");
          });
      }
    });
  };

  function dropdown() {
    if (drop === false) {
      setDrop(true);
    } else {
      setDrop(false);
    }
  }

  const atualizaTicket = () => {
    let { id, partFname, partLname } = participante;
    Api.post("update/ticket", { ticket: { id, partFname, partLname } })
      .then((res) => {
        closeModal();
        container.success("Editado com sucesso");
      })
      .catch(() => {
        container.error("Erro na hora de editar!");
      });
  };

  function openModal(item) {
    setIsOpen(true);
    setParticipante(item);
  }

  async function closeModal() {
    setIsOpen(false);
  }

  async function _sendRefund(refund_type, data_banks) {
    const refund_value = noCentsCurrencyFormatter(
      refund_type === "refund_without_taxes"
        ? (props.dataTicket?.totalValue * 100 - props.dataTicket?.oursTax) / 100
        : props.dataTicket?.totalValue
    );

    const swal_title =
      refund_type === "partial_refund"
        ? "Você tem certeza que deseja estornar parcialmente esta venda?"
        : ` Você tem certeza que deseja estornar o valor de ${refund_value} desta venda?`;

    const swal_html = `Informe sua senha abaixo para prosseguir:<br/> <input id="swal-input1" class="swal2-input" type="password" placeholder="Senha de acesso"> ${
      refund_type === "partial_refund"
        ? `Informe o valor que deverá ser estornado para o cliente:<br/> <input id="swal-input2" class="swal2-input" value="R$ 0,00" type="text"><br/>`
        : ""
    }<small><b>Você não poderá recuperar esta ação depois de processada!</b></small>`;

    await Swal.fire({
      icon: "warning",
      title: swal_title,
      html: swal_html,
      focusConfirm: false,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: "Sim, estornar pagamento!",
      cancelButtonText: "Não",
      onClose: () => setLoadingRefund(false),
      onOpen: (element) => {
        if (element.querySelector("#swal-input2")) {
          const moneyInput = element.querySelector("#swal-input2");
          moneyInput.addEventListener("keyup", (e) => {
            e.target.value = currencyInputFormatter(e.target.value);
          });
        }
      },
      preConfirm: async () => {
        setLoadingRefund(true);
        const inputs = {
          password: document.getElementById("swal-input1").value,
          ...(refund_type === "partial_refund" && {
            value: numberMask(document.getElementById("swal-input2").value),
          }),
        };
        const validator = await swalInputValidator(inputs, refund_type);

        if (!validator.status) {
          return Swal.fire({
            title: "Atenção",
            text:
              validator.message ||
              "Ocorreu um erro ao realizar o estorno. Tente novamente.",
            icon: "warning",
          }).then(() => _sendRefund(refund_type));
        }
        if (props.dataTicket?.paymentType === "Boleto") {
          props.onClose();
          setShowAccountModal(true);
        } else {
          return Api.post(`/estorno/venda/${props.dataTicket?.tokenTicket}`, {
            password: inputs.password,
            ...(refund_type === "partial_refund" && {
              amount: inputs.value / 100,
            }),
            ...(data_banks && {
              ...data_banks,
            }),
            refund_type,
          })
            .then(() => {
              Swal.fire(
                "Sucesso",
                "Venda estornada junto a operadora!",
                "success"
              ).then((props.dataTicket.statusSup = "estornado"));
            })
            .catch((err) => {
              const message =
                err.response && err.response.data
                  ? err.response.data.message
                  : "";
              Swal.fire({
                title: "Atenção",
                text:
                  message ||
                  "Ocorreu um erro ao realizar o estorno. Tente novamente.",
                icon: "error",
              }).then(() => _sendRefund(refund_type));
            })
            .finally(() => {
              changeBadge();
              props.fetchData();
              setLoadingRefund(false);
              setShowAccountModal(false);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  const swalInputValidator = (inputs_values, refund_type) => {
    const number = inputs_values.value
      ? Number(numberMask(inputs_values.value)) / 100
      : null;
    if (isNaN(number) && refund_type === "partial_refund") {
      return { status: false, message: "O valor informado é inválido" };
    } else if (number <= 0 && refund_type === "partial_refund") {
      return {
        status: false,
        message: "O valor informado deve ser maior que 0",
      };
    } else if (
      number > props?.dataTicket?.totalValue &&
      refund_type === "partial_refund"
    ) {
      return {
        status: false,
        message: "O valor informado deve ser menor que o valor total da compra",
      };
    } else if (!inputs_values.password) {
      return {
        status: false,
        message: "É necessário informar a senha para concluir a ação",
      };
    } else {
      return { status: true };
    }
  };

  function sendRefundWithAccountInfo(bankInfos) {
    const infos = {
      agency: bankInfos.agencia,
      account_number: bankInfos.conta,
      document: bankInfos.documento,
      account_digit: bankInfos.digitoconta,
      bank_code: bankInfos.idBanco,
      account_holder: bankInfos.nome,
      type: bankInfos.tipo,
    };

    _sendRefund("full_refund", infos);
  }

  useEffect(() => {
    setTicket(props.ticket);
  }, [props.ticket]);

  return (
    <Fragment>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div
        className={`shadow modal-participantes ${
          props.show ? "modal-show" : "modal-hidden"
        }`}
        ref={ref}
        role="dialog"
        id="modal-order"
      >
        <div className="w-100 mt-2">
          <div className="card-header">
            <div className="row">
              <span className={changeBadge() + " badge badge-pill"}>
                {orderStatusNames[props.dataTicket?.statusSup] ||
                  props.dataTicket?.statusSup}
              </span>
              <span className="ml-2 card-header-title">
                {props.dataTicket?.tokenTicket}
              </span>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => props.onClose()}
            >
              <b aria-hidden="true">×</b>
            </button>
          </div>
          <h4 className="card-header-title col-12 mt-2">
            Informações do evento:
          </h4>
          <div className="col-md-12 sales-details mt-2">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <b>Nome:</b> {props.dataTicket?.eventNameSup}
                </p>
                <p>
                  <b>Data:</b> {props.dataTicket?.eventStartDateSup}
                </p>
              </div>
            </div>
          </div>
          <h4 className="card-header-title col-12 mt-2">
            Informações da transação:
          </h4>
          <div className="col-md-12 sales-details mt-2">
            <div className="row">
              <div className="col-md-6">
                {props.dataTicket?.transaction && (
                  <>
                    <p>
                      <b>ID Transação:</b>{" "}
                      {props.dataTicket?.transaction || "-"}
                    </p>
                    <p>
                      <b>ID Cobrança: </b> {props.dataTicket?.charge_id || "-"}
                    </p>
                    <p>
                      <b>Origem: </b>{" "}
                      {props.dataTicket?.channel === "pos"
                        ? `${CHANNEL_LABELS[props.dataTicket?.channel]}-${
                            props.dataTicket?.serial
                          }`
                        : CHANNEL_LABELS[props.dataTicket?.channel]}
                    </p>
                  </>
                )}
                <p>
                  <b>Desconto:</b>{" "}
                  {props.dataTicket?.discountCode
                    ? `${props.dataTicket?.discountCode} - ${
                        props.dataTicket?.discountType === 1
                          ? `${props.dataTicket?.discountValue}%`
                          : `${Number(
                              props.dataTicket?.discountValue
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}`
                      }`
                    : "Não aplicado"}
                </p>
                <p>
                  <b>Taxas: </b>
                  {currencyFormatter(props.dataTicket?.oursTax || 0)}
                </p>
                <p>
                  <b>Total: </b>
                  {noCentsCurrencyFormatter(props.dataTicket?.totalValue)}
                </p>
                <p>
                  <b>Valor do produtor: </b>
                  {currencyFormatter(props.dataTicket?.taxSup || 0)}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <b>Data da compra:</b> {props.dataTicket?.createdAt}
                </p>
                <p>
                  <b>Forma de pagamento:</b> {props.dataTicket?.paymentType}
                </p>
                {props.dataTicket?.firstnumbers && (
                  <p>
                    <b>Cartão:</b>{" "}
                    {props.dataTicket?.firstnumbers +
                      "***" +
                      props.dataTicket?.lastnumbers}
                  </p>
                )}
              </div>
            </div>
          </div>
          <h4 className="card-header-title col-12 mt-2">
            Informações do comprador:
          </h4>
          <div className="col-md-12 sales-details mt-2">
            <div className="row">
              <div className="col-md-6">
                <p>
                  <b>Nome:</b> {props.dataTicket?.userSup}
                </p>
                <p>
                  <b>Facebook:</b> {props.dataTicket?.userFacebook}
                </p>
                <p>
                  <b>X (Twitter):</b> {props.dataTicket?.userTwitter}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <b>E-mail:</b>{" "}
                  {props.dataTicket?.userSupEmail
                    ? props.dataTicket?.userSupEmail
                    : "*Email não informado!"}
                </p>
                <p>
                  <b>Instagram:</b> {props.dataTicket?.userInstagram}
                </p>
              </div>
            </div>
          </div>
          {props.dataTicket?.antifraud_transaction_id && (
            <>
              <h4 className="card-header-title col-12 mt-2 d-flex justify-content-between align-items-center">
                <span>Informações da análise de fraude:</span>

                {props.dataTicket.antifraud_status !== "not_analyzed" && (
                  <a
                    class="btn btn-outline-info btn-sm mt-2"
                    target="_blank"
                    style={{ fontSize: 10 }}
                    href={`${process.env.REACT_APP_KONDUTO_BASE_URL}/${props.dataTicket.antifraud_transaction_id}`}
                    rel="noopener noreferrer"
                  >
                    Acessar detalhes da análise
                  </a>
                )}
              </h4>
              <div id="antifraud_info" className="col-md-12 sales-details mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <b>Status:</b>{" "}
                      {props.dataTicket.antifraud_status
                        ? antifraudStatus[props.dataTicket.antifraud_status]
                        : "-"}
                    </p>
                    <p>
                      <b>3DS:</b>{" "}
                      {props.dataTicket.secure_mode === 0 ? "Não" : "Sim"}
                    </p>
                  </div>

                  <div className="col-md-6">
                    <p>
                      <b>Recomendação:</b>{" "}
                      {antifraudRecommendation[
                        props.dataTicket.antifraud_recommendation
                      ] || "-"}
                    </p>
                    <p>
                      <b>Score:</b> {props.dataTicket.antifraud_score / 100}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <h4 className="card-header-title col-12 mt-2">
            Ingressos da compra:
          </h4>
          <div className="details-user-info col-md-12 mt-3">
            <div className="row">
              <div className="col-md-12">
                <table class="table table-sm table-bordered table-block">
                  <thead className="">
                    <tr>
                      <th scope="col">Nº Ingresso</th>
                      <th scope="col">Lote</th>
                      <th scope="col">Participante</th>
                      <th scope="col">Checkin</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  {ticket && (
                    <tbody>
                      {ticket.map((sale, i) => (
                        <tr key={i}>
                          <td>{sale.code ? sale.code : "Não informado!"}</td>
                          <td>{sale.ticket_name}</td>
                          <td>
                            {_.compact([sale.partFname, sale.partLname]).join(
                              " "
                            ) || "Não informado"}
                          </td>
                          <td>
                            {sale.checkin
                              ? sale.checkin
                              : "Checkin nao realizado!"}
                          </td>
                          <td>{noCentsCurrencyFormatter(sale.ticketSold)}</td>
                          <td>
                            <button
                              className="btn-table"
                              onClick={() => {
                                openModal(sale);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPen}
                                className="icon-search"
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                      {/*  */}
                    </tbody>
                  )}
                </table>
              </div>
              <div className="col-md-12">
                <div className="row mb-1">
                  {props.dataTicket?.statusSup === "finalizado" && (
                    <div className="col-lg-4 sm-12 mb-1 sales-details">
                      <button
                        className="btn btn-primary c3 col-12"
                        onClick={() => {
                          Api.post("send/ticket", {
                            order_id: props.dataTicket?.orderId,
                          })
                            .then((res) => {
                              container.success(
                                "Ingresso reenviado com sucesso!"
                              );
                            })
                            .catch((e) => {
                              container.error("Erro ao reenviar os ingressos!");
                            });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faShare}
                          className="icon-search"
                        />
                        Reenviar ingressos
                      </button>
                    </div>
                  )}
                  <div className="col-lg-4 sm-12 mb-1">
                    <button
                      className="btn btn-secondary col-12"
                      onClick={(e) => {
                        dropdown();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faExchangeAlt}
                        className="icon-search mr-1"
                      />
                      Alterar status
                    </button>
                    {drop ? (
                      <div ref={ref2} className="dropdown-content-handle">
                        <SuperRadioButton
                          value={props.dataTicket?.statusSup}
                          requiredValue={"Estornado"}
                          label={"Estornado"}
                          callback={(value) => {
                            changeStatus(value);
                          }}
                        />
                        <SuperRadioButton
                          value={props.dataTicket?.statusSup}
                          requiredValue={"Finalizado"}
                          label={"Finalizado"}
                          callback={(value) => {
                            changeStatus(value);
                          }}
                        />
                        <SuperRadioButton
                          value={props.dataTicket?.statusSup}
                          requiredValue={"Pendente"}
                          label={"Pendente"}
                          callback={(value) => {
                            changeStatus(value);
                          }}
                        />
                        <SuperRadioButton
                          value={props.dataTicket?.statusSup}
                          requiredValue={"disputa"}
                          label={"Em disputa"}
                          callback={(value) => {
                            changeStatus(value);
                          }}
                        />
                        <SuperRadioButton
                          value={props.dataTicket?.statusSup}
                          requiredValue={"charged_back"}
                          label={"Chargeback"}
                          callback={(value) => {
                            changeStatus(value);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {props.usuarioLogado.is_admin &&
                    props.usuarioLogado.type === "GOD" &&
                    props.dataTicket?.statusSup === "finalizado" &&
                    props?.dataTicket?.paymentType === "Cortesia" && (
                      <div className="col-lg-4 sm-12">
                        <button
                          disabled={loadingRefund}
                          className="btn btn-danger col-12"
                          onClick={() => _sendRefund("cancel_courtesy")}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />{" "}
                          {loadingRefund ? "Processando..." : "Cancelar venda"}
                        </button>
                      </div>
                    )}
                </div>
                {props.usuarioLogado.is_admin &&
                  props.usuarioLogado.type === "GOD" &&
                  ["finalizado", "pendente-estorno-sistema"].includes(
                    props.dataTicket?.statusSup
                  ) && (
                    <>
                      {props?.dataTicket?.paymentType !== "Cortesia" && (
                        <h4 className="card-header-title mt-2 mb-3">
                          Opções de estorno:
                        </h4>
                      )}
                      <div className="row mb-1">
                        {props?.dataTicket?.paymentType !== "Cortesia" && (
                          <div className="col-lg-4 sm-12 mb-1">
                            <button
                              disabled={loadingRefund}
                              className="btn btn-danger col-12"
                              onClick={() => _sendRefund("full_refund")}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />{" "}
                              {loadingRefund ? "Processando..." : "Total"}
                            </button>
                          </div>
                        )}
                        {props?.dataTicket.situationRate === "Repassa" &&
                          props?.dataTicket?.paymentType !== "Cortesia" && (
                            <div className="col-lg-4 sm-12 mb-1">
                              <button
                                disabled={loadingRefund}
                                className="btn btn-danger col-12"
                                onClick={() =>
                                  _sendRefund("refund_without_taxes")
                                }
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                {loadingRefund ? "Processando..." : "Sem taxas"}
                              </button>
                            </div>
                          )}
                        {!["Dinheiro", "PicPay", "Cortesia", "picpay"].includes(
                          props?.dataTicket?.paymentType
                        ) && (
                          <div className="col-lg-4 sm-12">
                            <button
                              disabled={loadingRefund}
                              className="btn btn-danger col-12"
                              onClick={() => _sendRefund("partial_refund")}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />{" "}
                              {loadingRefund ? "Processando..." : "Parcial"}
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Editar participante do ingresso"
        >
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="first_name">Nome</label>
              <input
                className="form-control"
                type="text"
                defaultValue={participante.partFname}
                onChange={(e) => {
                  participante.partFname = e.target.value;
                  setParticipante(participante);
                }}
                id="first_name"
                placeholder="Nome"
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="last_name">Sobrenome</label>
              <input
                className="form-control"
                type="text"
                defaultValue={participante.partLname}
                onChange={(e) => {
                  participante.partLname = e.target.value;
                }}
                id="last_name"
                placeholder="Sobrenome"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-primary c3"
                onClick={() => {
                  atualizaTicket();
                  closeModal();
                }}
              >
                Editar
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {showAccountModal && (
        <BankAccountInfosModal
          show
          isActionDisable={loadingRefund}
          onFinish={sendRefundWithAccountInfo}
          onClose={() => setShowAccountModal(false)}
        />
      )}
    </Fragment>
  );
};

export default ModalTicket;
