import React, { useEffect, useState } from "react";
import api from "services/api";
import PageHeader from "../../../../components/page-header";
import { ToastContainer } from "react-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../../components/loading";
import ReactTooltip from "react-tooltip";
import CustomButtonSpinner from "components/buttonSpinner";
import "./style.css";
import Swal from "sweetalert2";

let container;

let porPagina = 25;

export default function Emails() {
  const [reenviarmail, setReenviarmail] = useState(false);
  const [emails, setEmails] = useState([]);
  const [, /*listamail*/ setListaMail] = useState([]);
  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //  Listar emails função para identificar qual botão esta sendo clicado no front
  const listEmails = (typePage, numberPage, perPage, search) => {
    setIsLoading(true);
    let pageConsult = { page: numberPage, perPage: perPage, search: search };

    switch (typePage) {
      case "Initial":
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }

        break;

      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        break;
      default:
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
    }

    api
      .post("lista/emails/sistema", pageConsult)
      .then((res) => {
        setEmails(res.data.data);
        setListaMail(res.data.data);

        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
        setIsLoading(false);

        if (reenviarmail) {
          setReenviarmail(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.error.status === 500) {
          container.error("Rota não encontrada");
        } else {
          container.error(err.response.data.error.message);
        }
        //Encerra loading
        document.getElementById("loading").classList.add("esconde");
      });
  };

  const formataDataHora = (data) => {
    let date = new Date(data);
    return (
      ("00" + date.getDate()).slice(-2) +
      "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " - " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  };
  useEffect(() => {
    const usuarioLogado = JSON.parse(localStorage.getItem("user"));
    if (usuarioLogado.type !== "GOD") {
      window.location.href = "/eventos";
    } else {
      listEmails("initial", 1, porPagina);

      document.getElementById("loading").classList.add("esconde");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Reenviar = (id) => {
    setReenviarmail(true);
    document.getElementById("loading").classList.remove("esconde");
    api
      .get(`/reenviar/email/${id}`)
      .then((res) => {
        //carregaEmails();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      })
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      listEmails("initial", 1, porPagina, pesquisa.pesquisa);
    }
  };

  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Loading
        title="aguarde..."
        text={
          reenviarmail
            ? "estamos reenviando o email :)"
            : "estamos carregando os e-mails :)"
        }
      />

      <PageHeader
        title="E-mails"
        text="Lista de e-mails para envio do sistema."
      ></PageHeader>

      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <ReactTooltip className="tooltip-custom" place="top" effect="solid" />
      <div className="content mt-4 container-fluid">
        <div className="row">
          <div id="list-mails" className="col-12">
            <div className="col-12 mb-5">
              <div className="row justify-content-between">
                <div className="col-md-10 mb-2">
                  <div className="card-search d-flex">
                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                    <input
                      className="input-custom"
                      name="pesquisa"
                      value={pesquisa.pesquisa || ""}
                      onChange={updateData}
                      autoComplete="off"
                      placeholder="Pesquise pelo e-mail"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-md-2 mb-2">
                  {/* <button
                    className="btn btn-outline-secondary btn-height"
                    value={pesquisa.pesquisa || ""}
                    onClick={(e) =>
                      listEmails("initial", 1, porPagina, e.target.value)
                    }
                  >
                    Pesquisar
                  </button> */}
                  <CustomButtonSpinner
                    customStyle={"btn btn-outline-secondary btn-height"}
                    value={pesquisa.pesquisa || ""}
                    triggerOnClick={listEmails}
                    isLoading={isLoading}
                    text={"Pesquisar"}
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table
                  id="table-auditmails"
                  class="table table-sm mt-4 datatable-custom"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Titulo</th>
                      <th scope="col" className="text-center">
                        Enviado
                      </th>
                      <th scope="col" className="text-center">
                        Data
                      </th>
                      <th scope="col" className="text-right">
                        Reenviar
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {emails.map((email, i) => (
                      <tr key={i}>
                        <td>{email.para}</td>
                        <td>{email.titulo}</td>
                        <td className="text-center">
                          {email.enviado === 1 ? (
                            <span className="badge badge-pill badge-success">
                              Sim
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-warning">
                              Não
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {formataDataHora(email.updated_at)}
                        </td>
                        <td>
                          <button
                            onClick={() => Reenviar(email.id)}
                            className="btn btn-outline-secondary"
                          >
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="mr-2"
                            />{" "}
                            Reenviar email
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="row d-flex justify-content-between mb-3 datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2 ">Resultados por Página:</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) =>
                          listEmails("initial", 1, (porPagina = e.target.value))
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    {porPagina * atualizaPaginaAtual >=
                    atualizaQuantPesquisa ? (
                      <span>
                        {" "}
                        Exibindo {atualizaQuantPesquisa} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        Exibindo {porPagina * atualizaPaginaAtual} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    )}

                    <button onClick={() => listEmails("initial", 1, porPagina)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        listEmails("left", atualizaPaginaAtual, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                    </span>

                    <button
                      onClick={() =>
                        listEmails("right", atualizaPaginaAtual, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        listEmails("lastPage", atualizaUltimaPagina, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
