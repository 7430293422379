import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { formatCurrency } from "utils";
import { useTranslation } from "react-i18next";
import { useCurrencyEventContext } from "contexts/currency-event-context";

export function SalesByOrigin({ originSales }) {
  const { t } = useTranslation("global");
  const { currency } = useCurrencyEventContext();

  return (
    <Fragment>
      <Card>
        <Card.Header className="new-sales-card-title">
          {t("salesByOrigin")}
        </Card.Header>
        {originSales.map((origin, index) => (
          <Card.Body key={index} className="new-detailed-sales">
            <div className="d-flex mb-2">
              <span
                className="new-sector-title text-start"
                style={{ flexBasis: "40%" }}
              >
                {origin.name}
              </span>
              <span
                className="new-sector-quantity-information text-start"
                style={{ flexBasis: "40%", textAlign: "left" }}
              >
                {origin.qty > 0
                  ? t("global:ticketWithCount", { count: origin.qty })
                  : ""}
              </span>
              <span
                className="new-sector-value text-end"
                style={{ flexBasis: "20%", textAlign: "right" }}
              >
                {formatCurrency(origin.value, {
                  isCents: false,
                  currency,
                })}
              </span>
            </div>
            {origin.types?.map((type, index) => (
              <Fragment key={index}>
                <div className="d-flex mb-2">
                  <span
                    className="new-lot-title text-start"
                    style={{ flexBasis: "40%" }}
                  >
                    {type.name === "Não realizado"
                      ? t("tickets:dashboard.notInformed")
                      : type.name}
                  </span>
                  <span
                    className="new-lot-quantity text-start"
                    style={{ flexBasis: "40%", textAlign: "left" }}
                  >
                    {type.qty > 0 && (
                      <Fragment>
                        {t("global:ticketWithCount", { count: type.qty })}
                      </Fragment>
                    )}
                  </span>
                  <span
                    className="new-lot-value text-end"
                    style={{ flexBasis: "20%", textAlign: "right" }}
                  >
                    {formatCurrency(type.value, {
                      isCents: false,
                      currency,
                    })}
                  </span>
                </div>

                {type.payments?.length > 0 &&
                  type.payments.map((payment, index) => (
                    <div key={index} className="d-flex">
                      <span
                        className="new-type-name text-start"
                        style={{ flexBasis: "40%" }}
                      >
                        {payment.name === "Não realizado"
                          ? t("tickets:dashboard.notInformed")
                          : payment.name}
                      </span>
                      <span
                        className="new-type-quantity text-start"
                        style={{ flexBasis: "40%", textAlign: "left" }}
                      >
                        {t("global:ticketWithCount", {
                          count: payment.qty,
                        })}
                      </span>
                      <span
                        className="new-type-value text-end"
                        style={{ flexBasis: "20%", textAlign: "right" }}
                      >
                        {formatCurrency(payment.value, {
                          isCents: false,
                          currency,
                        })}
                      </span>
                    </div>
                  ))}
              </Fragment>
            ))}
          </Card.Body>
        ))}
      </Card>
    </Fragment>
  );
}
