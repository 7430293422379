import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ModalEventCommandDaily from "./modal-event-command-daily";
import Modal from "react-modal";
import { DatePicker } from "components/datepicker";
import api from "services/api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const EventComandDaily = () => {
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [filter, setFilter] = useState(null);
  const [modalStatus, setModalStatus] = useState("modal-hidden");
  const [modalItem, setModalItem] = useState("");

  // new card modal
  const [newCardModal, setNewCardModal] = useState(false);
  const [newCard, setNewCard] = useState({
    name: "",
    code: "",
    event_id: "",
  });

  const [dates, setDates] = useState({
    dataIni: "",
    dataFim: "",
  });

  const updateCard = (e) => {
    setNewCard({
      ...newCard,
      [e.target.name]: e.target.value,
    });
  };

  const [dataFilter, setDataFilter] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (filter === 1) {
      setData([...dataFilter]);
    }

    if (filter === 2) {
      let aux = [];
      dataFilter.forEach((item) => {
        if (item.sit === "aberto") {
          aux.push(item);
        }
      });
      setData(aux);
    }

    if (filter === 3) {
      let aux = [];
      dataFilter.forEach((item) => {
        if (item.sit === "faltando") {
          aux.push(item);
        }
      });
      setData(aux);
    }

    if (filter === 4) {
      let aux = [];
      dataFilter.forEach((item) => {
        if (item.sit === "pago") {
          aux.push(item);
        }
      });
      setData(aux);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    api
      .post(`dash/commands/day/establishment/${event_id}`)
      .then((res) => {
        setData(res.data);
        setDataFilter(res.data);
        setFilter(2);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }, [event_id]);

  const updateData = () => {
    setNewCardModal(false);
  };

  function openModal(item) {
    setModalItem(item);
    setModalStatus("modal-show");
  }

  function closeModal() {
    setModalItem("");
    setModalStatus("modal-hidden");
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
      height: "350px",
      width: "500px",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };

  return (
    <>
      <PageHeader
        title="Comandas ou mesas abertas"
        text="lista de clientes com mesas ou comandas abertas"
      ></PageHeader>

      <div className="container-fluid mt-4">
        <div className="filter-tags">
          <div className="row">
            <div className="col-md-1">
              <div
                className={
                  filter === 1 ? "rounded-tag tag-active" : "rounded-tag"
                }
                id={`tag-1`}
                onClick={(e) => setFilter(1)}
              >
                todas
              </div>
            </div>
            <div className="col-md-1">
              <div
                className={
                  filter === 2 ? "rounded-tag tag-active" : "rounded-tag"
                }
                id={`tag-2`}
                onClick={(e) => setFilter(2)}
              >
                abertas
              </div>
            </div>
            <div className="col-md-1">
              <div
                className={
                  filter === 3 ? "rounded-tag tag-active" : "rounded-tag"
                }
                id={`tag-4`}
                onClick={(e) => setFilter(3)}
              >
                faltando
              </div>
            </div>
            <div className="col-md-1">
              <div
                className={
                  filter === 4 ? "rounded-tag tag-active" : "rounded-tag"
                }
                id={`tag-5`}
                onClick={(e) => setFilter(4)}
              >
                pago
              </div>
            </div>
            <div className="col-md-3 mt-n2">
              <DatePicker
                value={dates.dataIni}
                showTimeSelect
                onChange={(date) => {
                  setDates({
                    ...dates,
                    dataIni: date,
                  });
                }}
                placeholderText={t("components:datepicker.startDate")}
              />
            </div>
            <div className="col-md-3 mt-n2">
              <DatePicker
                value={dates.dataFim}
                showTimeSelect
                onChange={(date) => {
                  setDates({
                    ...dates,
                    dataFim: date,
                  });
                }}
                placeholderText={t("components:datepicker.endDate")}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-2">
            <div
              className="card-create text-center p-auto"
              onClick={(e) => setNewCardModal(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>
              <p>criar nova</p>
            </div>
          </div>
          {data.map((item, i) => (
            <div key={i} className="col-md-2">
              <div
                className="card card-command shadow-sm p-2"
                onClick={(e) => openModal(item)}
              >
                <div className="d-flex justify-content-end">
                  <div className={"tag-sit " + item.sit}>{item.sit}</div>
                </div>
                <div className="mt-2 card-name text-center">{item.code}</div>
                <div className="mt-2">
                  <div className="event-progress-bar">
                    {/* Value */}
                    <div className="small mr-2">
                      Pago: {item.paid} / {item.cost} |{" "}
                      <b>
                        {item.cost !== 0 &&
                          ((item.paid / item.cost) * 100).toFixed(2)}
                        %
                      </b>
                    </div>
                    {/* Progress */}
                    <div className="progress progress-sm">
                      <div
                        className={`progress-bar ${item.sit}`}
                        role="progressbar"
                        style={{
                          width: `${((item.paid / item.cost) * 100).toFixed(
                            2
                          )}%`,
                        }}
                        aria-valuenow="29"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={newCardModal}
        onRequestClose={updateData}
        ariaHideApp={false}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        contentLabel="Abrir comanda"
      >
        <div className="card-header d-flex justify-content-between">
          <h4 className="card-header-title">Abrir comanda</h4>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            className="close"
            onClick={(e) => setNewCardModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <label htmlFor="name" className="form-label">
                Telefone
              </label>
              <input
                className="form-control form-control-sm"
                name="name"
                value={newCard.name}
                onChange={updateCard}
              />
            </div>
            <div className="col-md-12">
              <label htmlFor="name" className="form-label">
                Código
              </label>
              <input
                className="form-control form-control-sm"
                name="code"
                value={newCard.code}
                onChange={updateCard}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="w-50">
            <button className="btn btn-secondary"> Abrir</button>
          </div>
        </div>
      </Modal>
      <ModalEventCommandDaily
        status={modalStatus}
        callback={closeModal}
        item={modalItem}
      ></ModalEventCommandDaily>
    </>
  );
};

export default EventComandDaily;
