import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BiTrashAlt } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import styled from "styled-components";
import Swal from "sweetalert2";

import LoteTable from "components/ingressos/LoteTable";
import Loading from "components/loading";
import Toast from "components/Toast/Toast";
import { breakPoints } from "services/breakPoints";
import { COLORS, SPACINGS } from "services/constants";
import New_api from "services/new-api";

import api from "../../services/api";
import ModalPassportLot from "./ModalPassportLot";
import ModalPassportSector from "./ModalPassportSector";
import { useTranslation } from "react-i18next";
import { useCurrencyEventContext } from "contexts/currency-event-context";

SectorTable.propTypes = {
  sectorData: PropTypes.shape({
    id: PropTypes.string,
    esl_id: PropTypes.number,
    sector: PropTypes.string,
    min_purchase: PropTypes.number,
    max_purchase: PropTypes.number,
    cpf_max_purchase: PropTypes.number,
    cpf_limit: PropTypes.number,
    description: PropTypes.string,
    tickets: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setRefetch: PropTypes.func,
  refetch: PropTypes.bool,
  sectorNames: PropTypes.arrayOf(PropTypes.string),
  setReload: PropTypes.func,
  editModal: PropTypes.func,
  openModalSetor: PropTypes.func,
  isPassport: PropTypes.bool,
  isAutomaticTurn: PropTypes.bool,
};

export default function SectorTable(props) {
  // eslint-disable-next-line no-undef
  const windowJS = window;
  const urlParams = new URLSearchParams(windowJS.location.search);
  const eventId = urlParams.get("event_id");
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    sectorData,
    setRefetch,
    refetch,
    sectorSeatsId,
    setReload,
    isPassport,
    openModalSetor,
    editModal,
    isAutomaticTurn,
  } = props;
  const { id, tickets, sector } = sectorData;
  const [toastConfs, setToastConfs] = React.useState({
    type: "",
    toastText: "",
    showTime: 0,
    showToast: false,
  });
  const [isSectorOpen, setIsSectorOpen] = React.useState(false);
  const [resourceData, setResourceData] = React.useState();
  const sectorTextRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [seatsEventKey, setSeatsEventKey] = React.useState();
  const [seatsCategories, setSeatsCategories] = React.useState([]);
  const { currency } = useCurrencyEventContext();
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.sector-table",
  });

  async function openEditSectorModal() {
    api.get("/passports/" + id).then((res) => {
      setResourceData(res.data);
      setIsSectorOpen(!isSectorOpen);
    });
  }

  function closeSectorModal(closed) {
    setResourceData(null);
    setIsSectorOpen(closed);
  }

  function deleteEventSector(eventSector) {
    Swal.fire({
      title: t("alert.remove.title", { sector: eventSector.sector }),
      icon: "warning",
      text: t("alert.remove.text"),
      showCancelButton: true,
      confirmButtonColor: COLORS.danger,
      cancelButtonColor: COLORS.blue,
      confirmButtonText: t("alert.remove.confirm"),
      cancelButtonText: t("alert.remove.cancel"),
    }).then((result) => {
      if (result.value) {
        setIsLoading(true);
        New_api.delete(`/event_sectors/${eventSector.id}`)
          .then(() => {
            Swal.fire({
              title: t("alert.remove.success.title"),
              icon: "success",
              confirmButtonText: t("alert.remove.button-text"),
            });
            setIsLoading(false);
            setReload(true);
          })
          .catch((error) => {
            Swal.fire({
              title: t("alert.remove.error.title"),
              icon: "error",
              text: t(
                error.response.data.code === "RESTRICTION_WITH_ERROR"
                  ? "alert.remove.error.restriction"
                  : "alert.remove.error.generic"
              ),
              confirmButtonText: t("alert.remove.button-text"),
            });
            setIsLoading(false);
          });
      }
    });
  }

  useEffect(() => {
    New_api.get(`/events/${eventId}`, {
      params: { fields: ["seats_integration_id"] },
    })
      .then(({ data: { seats_integration_id } }) => {
        if (seats_integration_id) {
          setSeatsEventKey(seats_integration_id);

          return New_api.get(
            `/events/${seats_integration_id}/seats/categories`
          );
        }
      })
      .then((res) => {
        if (res?.data) {
          setSeatsCategories(res.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectorContainer className="col-12 p-0 card">
      {isLoading ? <Loading title={`${t("loading")}...`} /> : null}
      <SectorHeaderContainer>
        <EditNameContainer>
          <SectorNamePaddingContainer>
            <SectorNameText ref={sectorTextRef}>{sector}</SectorNameText>
          </SectorNamePaddingContainer>
          <IconCircle
            style={{
              transition: "all 350ms ease-in-out",
              opacity: 1,
            }}
            onClick={() => {
              if (isPassport) {
                openEditSectorModal();
              } else {
                openModalSetor();
                editModal(true, sector, sectorSeatsId, sectorData.id);
              }
            }}
          >
            <EditIcon />
          </IconCircle>
          <IconCircleDanger
            style={{
              transition: "all 350ms ease-in-out",
              opacity: 1,
            }}
            onClick={() => {
              deleteEventSector(sectorData);
            }}
          >
            <TrashIcon />
          </IconCircleDanger>
        </EditNameContainer>
        <NewLoteButtonContainer>
          <NewLoteButton onClick={() => setIsOpen(true)}>
            <NewLoteText>{t("add-lot-button")}</NewLoteText>
            <AddButon />
          </NewLoteButton>
        </NewLoteButtonContainer>
      </SectorHeaderContainer>
      <ScrollBarContainer className="sector overflow-x-auto">
        <InfoBarAndLoteContainer>
          <InfoTextBarContainer className="col-12">
            <LotesText className="col-3 justify-content-start">
              {t("text-bar.lot")}
            </LotesText>
            <ValorText className="col-1 justify-content-start">
              {t("text-bar.value")}
            </ValorText>
            <VendidosText className="col-3 justify-content-end">
              {t("text-bar.sold-plus-pending")}
            </VendidosText>
            <VendidosText className="col-2 justify-content-start">
              {t("text-bar.available")}
            </VendidosText>
            <DisponibilidadeText className="col-2">
              {t("text-bar.availability")}
            </DisponibilidadeText>
            <AcoesText className="col-1 justify-content-end">
              {t("text-bar.actions")}
            </AcoesText>
          </InfoTextBarContainer>
          <PaddingLoteContainer>
            {tickets.length === 0 && <p>{t("pending-lot")}</p>}

            {tickets.map((lotes) => (
              <LoteTable
                currency={currency}
                key={lotes.id}
                lotes={lotes}
                eventId={eventId}
                sectorId={id}
                setRefetch={setRefetch}
                refetch={refetch}
                isPassport={isPassport}
                loteNumber={tickets.length}
                isAutomaticTurn={isAutomaticTurn}
                loteNames={tickets
                  .map((ticket) => {
                    if (ticket.lot.name !== lotes.lot.name) {
                      return ticket.lot.name;
                    }
                    return false;
                  })
                  .filter(Boolean)}
                setReload={setReload}
              />
            ))}
          </PaddingLoteContainer>
        </InfoBarAndLoteContainer>
      </ScrollBarContainer>
      {toastConfs.showToast && (
        <Toast
          type={toastConfs.type}
          toastText={toastConfs.toastText}
          showToast={toastConfs.showToast}
          setToastConfs={setToastConfs}
          showTime={toastConfs.showTime}
        />
      )}

      <ModalPassportLot
        setRefetch={setRefetch}
        eventId={parseInt(eventId)}
        sectorId={parseInt(id)}
        toggleFunction={{ isOpen, setIsOpen }}
        isPassport={isPassport}
        isAutomaticTurn={isAutomaticTurn}
      />
      <ModalPassportSector
        sector={resourceData}
        eventId={eventId}
        setRefetch={setRefetch}
        toggleFunction={{ isOpen: !!resourceData, setIsOpen: closeSectorModal }}
        seatsEventKey={seatsEventKey}
        seatsCategoriesList={seatsCategories}
      />
    </SectorContainer>
  );
}
// /////////////////////////////////// CONTAINERs /////////////////////////

const InfoTextBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.gray};
`;
const ScrollBarContainer = styled.div`
  display: flex;
  overflow: overlay;
  overflow-y: hidden;
`;

const InfoBarAndLoteContainer = styled.div`
  min-width: 700px;
  width: 100%;
`;

const SectorContainer = styled.div`
  height: auto;
  background-color: ${COLORS.white};
  border-radius: 5px;
  overflow: hidden;
`;

const EditNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewLoteButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PaddingLoteContainer = styled.div`
  padding: ${SPACINGS.space2} ${SPACINGS.space2} 0 ${SPACINGS.space2};
  background-color: ${COLORS.white};
`;

const SectorNamePaddingContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 0;
`;

const SectorHeaderContainer = styled.div`
  padding: ${SPACINGS.space2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media ${breakPoints.mobileL} {
    flex-direction: row;
  }
`;

// /////////////////////////////////// TEXTs /////////////////////////

const LotesText = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  min-height: 32px;
  padding-left: 55px;
  color: ${COLORS.white};
  font-weight: 400;
`;
const ValorText = styled.p`
  margin: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${COLORS.white};
  font-weight: 400;
`;
const VendidosText = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  color: ${COLORS.white};
  padding-left: 12px;
  font-weight: 400;
`;
const DisponibilidadeText = styled.p`
  margin: 0;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 12px;
  color: ${COLORS.white};
  padding: 0;
  font-weight: 400;
  @media (min-width: 1150px) {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 41px;
  }
`;

const AcoesText = styled.p`
  margin: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${COLORS.white};
  padding-right: 14px;
  font-weight: 400;
`;

const NewLoteText = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  padding-top: 1px;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  color: inherit;
  padding: 1px ${SPACINGS.space2} 0 0;
`;

const SectorNameText = styled.span`
  display: list-item;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  color: ${COLORS.darkBlue};
  padding: 1px ${SPACINGS.space0} 0 ${SPACINGS.space0};
`;

// /////////////////////////////////// ICONs /////////////////////////

const EditIcon = styled(BsPencil)`
  color: inherit;
  font-size: 14px;
`;

const AddButon = styled(IoIosAddCircleOutline)`
  font-size: 20px;
`;

const TrashIcon = styled(BiTrashAlt)`
  color: danger;
  font-size: 14px;
`;

// /////////////////////////////////// BUTTONs /////////////////////////

const IconCircle = styled.button`
  background-color: transparent;
  border: 1px ${COLORS.darkBlue} solid;
  display: flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${COLORS.darkBlue};
  &:hover {
    background-color: ${COLORS.blue};
    border: 1px ${COLORS.blue} solid;
    color: ${COLORS.white};
  }
`;

const IconCircleDanger = styled.button`
  background-color: transparent;
  border: 1px ${COLORS.danger} solid;
  display: flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
  color: ${COLORS.danger};
  &:hover {
    background-color: ${COLORS.danger};
    border: 1px ${COLORS.danger} solid;
    color: ${COLORS.white};
  }
`;

const NewLoteButton = styled.button`
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  width: 250px;
  height: 41px;
  border-radius: 5px;
  border: 1px solid ${COLORS.darkBlue};
  color: ${COLORS.darkBlue};
  background-color: transparent;
  transition: all 350ms ease-in-out;
  &:hover {
    border: 1px solid ${COLORS.blue};
    background-color: ${COLORS.blue};
    color: ${COLORS.white};
  }
  &:active {
    border: 1px solid ${COLORS.darkBlue};
    background-color: ${COLORS.darkBlue};
    color: ${COLORS.white};
  }
  @media ${breakPoints.mobileL} {
    flex-direction: row;
    width: 240px;
  }
`;
