import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import api from "services/api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";


FileUpload.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  fileTitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  uploadFolder: PropTypes.string,
  description: PropTypes.string,
  containerClassName: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onLoading: PropTypes.func,
};

export default function FileUpload(props) {
  const {
    id,
    title,
    label,
    error,
    url,
    accept,
    fileTitle,
    buttonTitle,
    description,
    uploadFolder,
    containerClassName,
    onChange,
    onBlur,
    onLoading,
  } = props;

  const { t } = useTranslation()
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const uploadImage = (event) => {
    setLoading(true);
    onLoading(true);

    const form_data = new FormData();
    const file_data = event.target.files[0];
    form_data.append("image", file_data);

    api
      .post(`uploads?local=${uploadFolder}`, form_data, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        setValue(res.data.url);
        onChange(res.data.url);
      })
      .catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      })
      .finally(() => {
        setLoading(false);
        onLoading(false);
      });
  };

  useEffect(() => setValue(url), [url]);

  return (
    <Container
      body
      className={`${containerClassName || ""} ${
        error ? "with-error" : ""
      } mb-0`}
    >
      <Row>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <PreText>{title}</PreText>
            </InputGroup.Prepend>

            <InputFile
              custom
              id={id}
              label={loading ? t("global:loadingUpload") : label}
              accept={accept}
              data-browse={loading ? t("global:loadingUpload") : buttonTitle}
              isInvalid={error}
              onChange={uploadImage}
              onBlur={onBlur}
            />
          </InputGroup>
          <HelpText>{description}</HelpText>

          {value && (
            <FileUrl target="_blank" href={value}>
              {fileTitle}
            </FileUrl>
          )}
        </Col>
      </Row>
    </Container>
  );
}

//------ Styled Components ------//
const Container = styled(Card)`
  border: 2px dashed #bacad3;
  box-shadow: none;

  &.with-error {
    border-color: #dc3545;
  }
`;

const PreText = styled(InputGroup.Text)`
  font-size: 14px;
  height: 35px;
`;

const InputFile = styled(Form.File)`
  .custom-file-label {
    font-weight: 400;
    font-size: 14px !important;
  }

  .custom-file-label::after {
    color: #fff;
  }
`;

const HelpText = styled.span`
  margin: 5px 0 0;
  display: block;
  color: #98afbc;
  font-weight: 300;
  font-size: 13px;
`;

const FileUrl = styled.a`
  font-size: 14px;
  text-decoration: underline;
  font-weight: 300 !important;
`;
