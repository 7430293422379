/* eslint-disable no-restricted-globals */
import React, { Fragment, useEffect } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import styled from "styled-components";
import Swal from "sweetalert2";

import ModalPassportSector from "components/ingressos/ModalPassportSector";
import SectorTable from "components/ingressos/SectorTable";
import PageHeader from "components/page-header/index";
import IngressoHeader from "components/subheader";
import api from "services/api";
import { breakPoints } from "services/breakPoints";
import { COLORS, FONTS_SIZE, SPACINGS } from "services/constants";
import New_api from "services/new-api";
import EventAutomaticallyLotCheck from "components/event-automatically-lot-check";
import { useTranslation } from "react-i18next";

export default function Passports() {
  // eslint-disable-next-line no-undef
  const windowJS = window;
  const urlParams = new URLSearchParams(windowJS.location.search);
  const eventId = urlParams.get("event_id");

  const [isOpen, setIsOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [tickets, setTickets] = React.useState([]);
  const [refetch, setRefetch] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalIngressos, setTotalIngressos] = React.useState({
    qty: 0,
    sold: 0,
    pending: 0,
    available: 0,
  });
  const [seatsEventKey, setSeatsEventKey] = React.useState();
  const [seatsCategories, setSeatsCategories] = React.useState([]);
  const [automaticallyTurnsLots, setAutomaticallyTurnsLots] = React.useState();
  const { t } = useTranslation("tickets", {
    keyPrefix: "passports",
  });

  useEffect(() => {
    if (tickets.length === 0) {
      setIsLoading(true);
    }

    New_api.get(`/events/${eventId}`, {
      params: { fields: ["automatically_turns_lots", "seats_integration_id"] },
    })
      .then(({ data: { automatically_turns_lots, seats_integration_id } }) => {
        setAutomaticallyTurnsLots(!!automatically_turns_lots);

        if (seats_integration_id) {
          setSeatsEventKey(seats_integration_id);

          return New_api.get(
            `/events/${seats_integration_id}/seats/categories`
          );
        }
      })
      .then((res) => {
        if (res?.data) {
          setSeatsCategories(res.data);
        }
      });

    api
      .get(`/event/tickets/${eventId}`, { params: { passports: true } })
      .then((res) => {
        setTickets(res.data);

        const totalIngressosMap = res.data
          .map((sectors) => sectors.tickets)
          .map((totalTickets) => {
            return totalTickets.map((lotes) => {
              return {
                qty: lotes.qty,
                sold: lotes.total_sold,
                pending: lotes.qty - lotes.total_available + lotes.total_sold,
              };
            });
          })
          .flat()
          .reduce(
            (previusLote, currentLote) => {
              return {
                qty: previusLote.qty + currentLote.qty,
                sold: previusLote.sold + currentLote.sold,
                pending: previusLote.pending + currentLote.pending,
              };
            },
            { qty: 0, sold: 0, pending: 0 }
          );

        setTotalIngressos(totalIngressosMap);
      })
      .catch((error) => {
        Swal.fire({
          title: t("alert.title", { error: error.response.status }),
          icon: "error",
          text: t("alert.text"),
        });
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        setRefetch(false);
      });
    // eslint-disable-next-line
  }, [eventId, refetch, isOpen, reload]);

  return (
    <Fragment>
      <PageHeader
        title={t("header.title")}
        text={t("header.text")}
        btnAction={() => setIsOpen(!isOpen)}
        btnText={t("header.button")}
      />
      <IngressoHeader position={2} evento={eventId} />
      {isLoading || automaticallyTurnsLots === undefined ? (
        <LoadingContainer>
          <LoadingIcon color={COLORS.darkBlue} size={80} />
          <LoadingText>{t("loading")}</LoadingText>
        </LoadingContainer>
      ) : (
        <PageContainer>
          {automaticallyTurnsLots !== undefined && (
            <EventAutomaticallyLotCheck
              checked={automaticallyTurnsLots}
              eventId={eventId}
              onChange={setAutomaticallyTurnsLots}
            />
          )}

          <TicketsDataContainer className="container-fluid">
            {tickets.map((sector) => (
              <SectorTable
                key={sector.id}
                sectorData={sector}
                setRefetch={setRefetch}
                refetch={refetch}
                setReload={setReload}
                isPassport={true}
                isAutomaticTurn={automaticallyTurnsLots}
              />
            ))}
            {totalIngressos.available !== 0 && (
              <TotalIngressosContainer className="col-12">
                <QuantidadeTotalText className="col-6 col-md-3 pl-3 pl-md-5">
                  {t("total")}
                </QuantidadeTotalText>
                <ValorTotalText className="col-6 col-md-5">{`${
                  totalIngressos.sold || 0
                } / ${totalIngressos.qty || 0}`}</ValorTotalText>
              </TotalIngressosContainer>
            )}
          </TicketsDataContainer>

          <ModalPassportSector
            eventId={eventId}
            setRefetch={setRefetch}
            toggleFunction={{ isOpen, setIsOpen }}
            seatsEventKey={seatsEventKey}
            seatsCategoriesList={seatsCategories}
          />
        </PageContainer>
      )}
    </Fragment>
  );
}
// /////////////////////////////////// CONTAINERs /////////////////////////

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //60px é o tamanho do footer que aparece nesse breakpoint
  padding-bottom: calc(${SPACINGS.space6} + 60px);
  @media ${breakPoints.tablet} {
    padding-bottom: ${SPACINGS.space6};
  }
`;
const LoadingContainer = styled.div`
  flex-direction: column;
  min-width: 32px;
  height: calc(100% - 240px);
  align-items: center;
  display: flex;
  justify-content: center;
  @media ${breakPoints.tablet} {
    height: calc(100% - 180px);
  }
`;

const TotalIngressosContainer = styled.div`
  display: flex;
  height: 48px;
  background-color: ${COLORS.darkBlue};
  min-width: 290px;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  overflow: overlay;
  font-family: Rubik, sans-serif;
  @media ${breakPoints.mobileL} {
    min-width: 550px;
  }
`;

const TicketsDataContainer = styled.div`
  justify-content: center;
  padding: 0 0 ${SPACINGS.space4} 0;
  width: 100%;
`;

// /////////////////////////////////// TEXTs //////////////////////////////
const QuantidadeTotalText = styled.span`
  padding-left: 45px;
  color: ${COLORS.white};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
`;
const ValorTotalText = styled.span`
  text-align: right;
  color: ${COLORS.white};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
`;
const LoadingText = styled.span`
  color: ${COLORS.gray};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
  width: 80%;
  @media ${breakPoints.mobileL} {
    font-size: ${FONTS_SIZE.fontSize1};
    width: auto;
  }
`;
// /////////////////////////////////// ICONs //////////////////////////////

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
