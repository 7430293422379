// Common
import React, { useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

// Components
// import PageHeader from "components/page-header";
// import SelectSearch from 'react-select-search';

// Providers
// eslint-disable-next-line import/no-named-as-default-member
import Api from "../../../../services/api";

let container;

export default function ModalFaq(props) {
  const editor = useRef(null);
  const config = { readonly: false };

  const [faq, setFaq] = useState({
    id: "",
    pergunta: "",
    resposta: "",
    categorias: "",
    descricao: "",
  });

  const updateData = (e) => {
    setFaq({
      ...faq,
      [e.target.name]: e.target.value,
    });
  };

  const updateEditor = (e) => {
    setFaq({
      ...faq,
      resposta: e,
    });
  };

  useEffect(() => {
    if (props.id) {
      Api.get(`/faq/${props.id}`)
        .then((res) => {
          setFaq({
            id: res.data.id,
            pergunta: res.data.pergunta,
            resposta: res.data.resposta,
            categorias: res.data.categorias,
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  }, [props.id]);

  const registerFAQ = () => {
    if (faq.name === "") {
      container.error("Nome da faq é obrigatório!");
      return;
    }

    if (props.id) {
      Api.post("/faq/edit", faq)
        .then((res) => {
          container.success("Faq editada com sucesso!");
          close();
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    } else {
      Api.post("/faq", faq)
        .then((res) => {
          container.success("Faq cadastrada com sucesso!");
          close();
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    }
  };

  const close = () => {
    setFaq({
      id: "",
      pergunta: "",
      resposta: "",
      categorias: "",
      descricao: "",
    });
    props.callback("modal-hidden");
  };

  return (
    <div className={"shadow side-modal " + props.status} role="dialog">
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className="card-header">
        <h4 className="card-header-title">
          {props.id ? "Editar Faq" : "Cadastrar Faq"}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => close(e)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <span>Título da Pergunta</span>
            <input
              type="text"
              className="form-control"
              name="pergunta"
              value={faq.pergunta}
              onChange={updateData}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <span>Tópicos</span>
          </div>
          <div className="form-row">
            <div
              className="radio-inline mtmb-0 "
              style={{
                marginLeft: "2rem",
                display: "flex",
                alignItems: "self-end",
              }}
            >
              <input
                type="radio"
                id="Ingresso-Cliente"
                name="mde"
                value="Ingresso-Cliente"
                checked={faq.categorias === "Ingresso-Cliente"}
                onChange={(e) => setFaq({ ...faq, categorias: e.target.value })}
              />
              <label htmlFor="Ingresso-Cliente" style={{ paddingLeft: "4px" }}>
                Ingresso-Cliente
              </label>
            </div>
            <div
              className="radio-inline mtmb-0"
              style={{
                marginLeft: "2rem",
                display: "flex",
                alignItems: "self-end",
              }}
            >
              <input
                type="radio"
                id="Ingresso-Produtor"
                name="mde"
                value="Ingresso-Produtor"
                checked={faq.categorias === "Ingresso-Produtor"}
                onChange={(e) => setFaq({ ...faq, categorias: e.target.value })}
              />
              <label htmlFor="Ingresso-Produtor" style={{ paddingLeft: "4px" }}>
                Ingresso-Produtor
              </label>
            </div>
            <div
              className="radio-inline mtmb-0"
              style={{
                marginLeft: "2rem",
                display: "flex",
                alignItems: "self-end",
              }}
            >
              <input
                type="radio"
                id="Delivery-Cliente"
                name="mde"
                value="Delivery-Cliente"
                checked={faq.categorias === "Delivery-Cliente"}
                onChange={(e) => setFaq({ ...faq, categorias: e.target.value })}
              />
              <label htmlFor="Delivery-Cliente" style={{ paddingLeft: "4px" }}>
                Delivery-Cliente
              </label>
            </div>
            <div
              className="radio-inline mtmb-0"
              style={{
                marginLeft: "2rem",
                display: "flex",
                alignItems: "self-end",
              }}
            >
              <input
                type="radio"
                id="Delivery-Produtor"
                name="mde"
                value="Delivery-Produtor"
                checked={faq.categorias === "Delivery-Produtor"}
                onChange={(e) => setFaq({ ...faq, categorias: e.target.value })}
              />
              <label htmlFor="Delivery-Produtor" style={{ paddingLeft: "4px" }}>
                Delivery-Produtor
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <label>Resposta</label>
            <JoditEditor
              className="form-control"
              name="resposta"
              useRef={editor}
              value={faq.resposta}
              config={config}
              tabIndex={1}
              onBlur={(e) => updateEditor(e)}
            />
            {/* <textarea
                    className="form-control"
                    value={descricao ? descricao : ""}
                    onBlur={(e) => {
                      setDescricao(e.target.value);
                    }}
                    onChange={(e) => {
                      setDescricao(e.target.value);
                    }}
                  ></textarea> */}
          </div>
        </div>
        <div className="box-button">
          <button className="btn btn-primary col-md-3 c3" onClick={registerFAQ}>
            {props.id ? "Salvar" : "Cadastrar"}
          </button>
        </div>
      </div>
    </div>
  );
}
