import React, { useState } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "services/api";
import EventAutocomplte from "components/inputeventautocomplete";
import ModalUserDetail from "./modalUserDetail";
import "./styles.css";
import Swal from "sweetalert2";
import { useLanguageSettings } from "hooks/language-settings";

export default function AntiFraud() {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const [data, setData] = useState([]);
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [value, setValue] = useState("");
  const [userDetail, setUserDetail] = useState({
    id: "",
    email: "",
    name: "",
    telephone: "",
    date_create: "",
  });

  const listUser = (event) => {
    api
      .get(`/antiFraud/${event.id}`)
      .then((res) => {
        setData(res.data);
        setValue("");
        // setListEvent([])
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  function openModal() {
    setStatusModal("modal-show");
  }

  function closeModal() {
    setStatusModal("modal-hidden");
  }

  return (
    <>
      <PageHeader
        title="Antifraude"
        text="Esta é uma página para análise de compras suspeitas em eventos."
      />

      <div className="container-fluid mt-3">
        <EventAutocomplte
          value={value}
          onChange={setValue}
          onSelect={listUser}
        />
        <div className="table-responsive">
          <table className="table table-sm mt-3 datatable-custom">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">
                  Email
                </th>
                <th scope="col" className="text-center">
                  Valor total
                </th>
                <th scope="col" className="text-center">
                  Compras
                </th>
                <th scope="col" className="text-center">
                  Tickets
                </th>
                <th scope="col" className="text-center">
                  Status
                </th>
                <th scope="col" className="text-center">
                  Evento
                </th>
                <th scope="col" className="text-center">
                  Detalhes
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr
                  key={user.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openModal();
                    setUserDetail({
                      id: user.id,
                      email: user.email,
                      name: user.name,
                      telephone: user.telephone,
                      date_create: user.dateAdress,
                    });
                  }}
                >
                  <td className="text-center">{user.email}</td>
                  <td className="text-center">
                    {noCentsCurrencyFormatter(user.valor)}
                  </td>
                  <td className="text-center">{user.compras}</td>
                  <td className="text-center">{user.totalTickets}</td>
                  <td className="text-center">
                    <span
                      className={
                        user.totalTickets <= 4
                          ? "badge badge-pill badge-warning"
                          : "badge badge-pill badge-danger"
                      }
                    >
                      {user.totalTickets <= 4 ? "suspeito" : "crítico"}
                    </span>
                  </td>
                  <td className="text-center">{user.nomeDoEvento}</td>
                  <td className="d-flex justify-content-center">
                    <button type="button" className="btn-table">
                      <FontAwesomeIcon icon={faPlus} className="icon-search" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ModalUserDetail
        callback={closeModal}
        status={statusModal}
        userDetail={userDetail}
      />
    </>
  );
}
