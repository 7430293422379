import React from "react";

const EventCreateSide = (props) => {
  const stepStepClassname = `step-step ${
    props.sectors?.length > 1 ? "col-3" : "col-4"
  } col-lg-12`;

  return (
    <>
      {props.step !== 5 && (
        <div className="col-md-12 col-lg-2">
          <div id="event-steps">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">Passos para criação</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div
                  className={`${stepStepClassname} ${
                    props.step === 1 ? "step-active" : ""
                  }`}
                  onClick={() => props.navigateStep(1)}
                >
                  <div className="step-title">
                    <span className="step-bullet">1</span>
                    <span>Dados básicos</span>
                  </div>
                  <span>
                    <small>cadastro de informações básicas do evento</small>
                  </span>
                </div>
                <div
                  className={`${stepStepClassname} ${
                    props.step === 2 ? "step-active" : ""
                  }`}
                  onClick={() => props.navigateStep(2)}
                >
                  <div
                    className={`step-title ${
                      props.locationArr?.length ? "" : "transparent"
                    }`}
                  >
                    <span className="step-bullet"> 2 </span>
                    <span>Setores, lotes e tipos</span>
                  </div>
                  <span>
                    <small>configure os tipos de ingresso</small>
                  </span>
                </div>
                {props.sectors.length > 1 ? (
                  <div
                    className={`${stepStepClassname} ${
                      props.step === 3 ? "step-active" : ""
                    }`}
                    onClick={() =>
                      props.sectors.length > 1 &&
                      props.datesArr?.length &&
                      props.sectors.every((obj) => "active" in obj)
                        ? props.navigateStep(3)
                        : null
                    }
                  >
                    <div
                      className={`step-title ${
                        props.datesArr.length ? "" : "transparent"
                      }`}
                    >
                      <span className="step-bullet"> 3 </span>
                      <span>Configurações gerais</span>
                    </div>
                    <span>
                      <small>configure suas vendas</small>
                    </span>
                  </div>
                ) : null}
                <div
                  className={`${stepStepClassname} ${
                    props.step === 4 ? "step-active" : ""
                  }`}
                  onClick={() =>
                    props.sectors.length > 1 &&
                    props.dateLocations.length &&
                    props.sectors.every((obj) => "active" in obj)
                      ? props.navigateStep(4)
                      : null
                  }
                >
                  <div
                    className={`step-title ${
                      props.dateLocations.length ? "" : "transparent"
                    }`}
                  >
                    <span className="step-bullet">
                      {props.sectors.length > 1 ? 4 : 3}
                    </span>
                    <span>Preços e quantidades</span>
                  </div>
                  <span>
                    <small>
                      Configure os valores e quantidades dos seus ingressos
                    </small>
                  </span>
                </div>
              </div>
              <div className="step-mobile">
                {props.step === 1 ? (
                  <>
                    <span>Dados básicos</span>
                    <span>
                      <small>Cadastro de informações básicas do evento.</small>
                    </span>
                  </>
                ) : props.step === 2 ? (
                  <>
                    <span>Setores, lotes e tipos</span>
                    <span>
                      <small>Configure os tipos de ingresso.</small>
                    </span>
                  </>
                ) : props.step === 3 ? (
                  <>
                    <span>Configurações gerais</span>
                    <span>
                      <small>Configure suas vendas.</small>
                    </span>
                  </>
                ) : props.step === 4 ? (
                  <>
                    <span>Preços e quantidades</span>
                    <span>
                      <small>
                        Configure os valores e quantidades dos seus ingressos.
                      </small>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCreateSide;
