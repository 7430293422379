import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOnClickOutside } from "App";
import React, { useEffect, useRef, useState } from "react";
import SelectSearch from "react-select-search";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

// Providers
import api from "services/api";

let container;

const ModalIventory = (props) => {
  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close();
    }
  });

  const [data, setData] = useState({
    event_id: null,
    item_id: "",
    quantity: "",
    type: "entrada",
  });

  const [itens, setItens] = useState([]);
  const [events, setEvents] = useState([]);

  function close() {
    setData({
      event_id: null,
      item_id: "",
      quantity: "",
      type: "entrada",
    });
    props.callback("modal-hidden");
  }

  useEffect(() => {
    api.post("inventory/seach", { name: "" }).then((res) => {
      let aux = [];
      res.data.forEach((item) => {
        aux.push({
          name: item.code + " - " + item.name,
          value: String(item.id),
        });
      });
    });
  }, [props.status]);

  function save() {
    api
      .post(`inventory/move/${data.item_id}`, data)
      .then((res) => {
        container.success("Lançamento realizado com sucesso!");
        close();
      })
      .catch((err) => {
        container.error(err.response.data.message);
      });
  }

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div ref={ref} className={`shadow side-modal ${props.status}`}>
        <div className="card-header">
          <h4 className="card-header-title">
            Realizar Lançamento de inventário
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-md-6">
              <label>Item</label>
              <SelectSearch
                options={itens}
                search={true}
                name="ncm"
                placeholder="Digite o nome do Item."
                value={data.item_id}
                className="form-control"
                getOptions={(query) => {
                  api
                    .post("inventory/seach", { name: query })
                    .then((res) => {
                      let aux = [];
                      res.data.forEach((item) => {
                        aux.push({
                          name: item.code + " - " + item.name,
                          value: String(item.id),
                        });
                      });
                      setItens(aux);
                    })
                    .catch(() => {
                      Swal.fire({
                        title: "Ops!",
                        icon: "error",
                        text: "Ocorreu um erro interno, por favor tente novamente",
                        confirmButtonText: "Ok",
                      });
                    });
                }}
                emptyMessage="Digite o nome do Item."
                onChange={(e) =>
                  setData({
                    ...data,
                    item_id: e,
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <label>Quantidade</label>
              <input
                className="form-control"
                name="quantity"
                value={data.quantity}
                onChange={(e) =>
                  setData({ ...data, quantity: Number(e.target.value) })
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div
              className="col-md-12"
              onClick={(e) => {
                if (data.type === "entrada") {
                  setData({
                    ...data,
                    type: "saida",
                  });
                } else {
                  setData({
                    ...data,
                    type: "entrada",
                  });
                }
              }}
            >
              <div
                className={`custom-input-option ${
                  data.type === "entrada" ? "" : "active"
                }`}
              >
                {data.type === "entrada" ? (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleDown}
                    ></FontAwesomeIcon>{" "}
                    Entrada{" "}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowAltCircleUp}
                    ></FontAwesomeIcon>{" "}
                    Saída{" "}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <label>Evento</label>
              <SelectSearch
                options={events}
                search={true}
                name="event_id"
                placeholder="Digite o nome do Evento"
                value={data.event_id}
                getOptions={(query) => {
                  api
                    .post("inventory/seachevent", { name: query })
                    .then((res) => {
                      let aux = [];
                      res.data.data.forEach((item) => {
                        aux.push({ name: item.name, value: String(item.id) });
                      });
                      setEvents(aux);
                    })
                    .catch(() => {
                      Swal.fire({
                        title: "Ops!",
                        icon: "error",
                        text: "Ocorreu um erro interno, por favor tente novamente",
                        confirmButtonText: "Ok",
                      });
                    });
                }}
                emptyMessage="Digite o ome do evento"
                onChange={(e) =>
                  setData({
                    ...data,
                    event_id: e,
                  })
                }
              />
            </div>
          </div>
          <div className="box-button">
            <button className="btn btn-secondary col-md-6 c3" onClick={save}>
              Realizar lançamento
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalIventory;
