import Cookies from "js-cookie";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import New_api from "services/new-api";
import { formatCurrency, currencyFromInput } from "utils";

/**
 * @typedef {Object} Locale
 * @property {'pt-BR'|'en-US'| 'es-ES'} language - The language of the locale.
 * @property {'BRL'|'USD'|'EUR'} currency - The country of the locale.
 * @property {'pt-BR'|'en-US'| 'es-ES'} backofficeLanguage
 * @property {string} timezone
 */

/**
 * @type {Locale}
 */
const defaults = {
  backofficeLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
  timezone: process.env.REACT_APP_DEFAULT_TIMEZONE,
  currency: process.env.REACT_APP_DEFAULT_CURRENCY,
};

const currencySymbol = {
  BRL: "R$",
  USD: "$",
  EUR: "€",
};

/**
 * Get the storage locale.
 * @returns {?Locale} The parsed locale object, or null if the locale is not found.
 */
const isLogged = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

export function getStorageLocale() {
  const locale = Cookies.get("locale");
  if (locale) {
    return JSON.parse(locale);
  }
  return null;
}

function saveLocaleSettings(locale) {
  Cookies.set("locale", JSON.stringify(locale));
}

async function updateUserDbLanguage(language, oldLanguage) {
  try {
    if (oldLanguage === language || !isLogged()) {
      return;
    }
    await New_api.put("/users/configure-language", {
      chosenLanguage: language,
    });
  } catch {}
}

async function getApiLocale() {
  try {
    const apiLocaleData = await New_api.get("/settings/locale");
    saveLocaleSettings(apiLocaleData.data);
    return apiLocaleData.data;
  } catch {}
}

async function getLocaleSettings() {
  const locale = getStorageLocale();
  if (locale) {
    return locale;
  }
  const apiLocaleData = await getApiLocale();
  return apiLocaleData.data;
}

export const useLanguageSettings = () => {
  const { i18n } = useTranslation();

  const setLanguage = async (language, chosenLanguage) => {
    let oldLocale = getStorageLocale();
    if (!oldLocale) {
      oldLocale = await getApiLocale();
    }
    const newLocale = {
      ...(oldLocale || defaults),
      backofficeLanguage: language,
    };
    saveLocaleSettings(newLocale);
    i18n.changeLanguage(language);
    updateUserDbLanguage(
      chosenLanguage || language,
      oldLocale.backofficeLanguage
    );
  };
  const updateDefaultSettings = async () => {
    const locale = await getLocaleSettings();
    if (locale) {
      setLanguage(locale.backofficeLanguage);
    }
  };

  const currency = useMemo(() => {
    return getStorageLocale()?.currency || defaults.currency;
    // eslint-disable-next-line
  }, [i18n.language]);

  /**
   * @description Currency formatcurrencyFromInputter, acceps amount and isCents(default true)  as parameters
   * @param {number} amount
   * @param {boolean} [isCents]
   * @param {boolean} [removeSymbol]
   * @returns {string}
   */
  const currencyFormatter = (amount, isCents = true, removeSymbol = false) => {
    return formatCurrency(amount, { currency, isCents, removeSymbol });
  };
  const noCentsCurrencyFormatter = (
    amount,
    isCents = false,
    removeSymbol = false
  ) => {
    return formatCurrency(amount, { currency, isCents, removeSymbol });
  };
  const currencyInputFormatter = (amount, inputCurrency) => {
    return currencyFromInput(amount, { currency: inputCurrency ?? currency });
  };
  const symbol = currencySymbol[currency];

  return {
    setLanguage,
    updateDefaultSettings,
    currency,
    currencyFormatter,
    symbol,
    currencyInputFormatter,
    noCentsCurrencyFormatter,
  };
};
