import { faCalendarAlt, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "components/datepicker";
import PageHeader from "components/page-header";
import SimpleTable from "components/simple-table";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { UtilsHelper } from "services/utils";
import Swal from "sweetalert2";
import { formatCurrency } from "utils";
import PosSalesDetails from "../posSalesDetails";
import "./styles.css";

const VendasPDV = () => {
  const { t } = useTranslation();
  const { currency } = useCurrencyEventContext();
  const [data, setData] = useState([]);
  const [pdvList, setPdvList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasFilters, setHasFilters] = useState(0);
  const [pos, setPos] = useState("");
  const [endedAt, setEndedAt] = useState(null);
  const [startedAt, setStartedAt] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({});

  const eventId = new URLSearchParams(window.location.search).get("event_id");

  const handleSelectPOS = (event) => {
    setHasFilters(0);
    setPos(event.target.value);
  };

  const handleSelectDate = (mode, date) => {
    setHasFilters(0);

    if (mode === "start") {
      setStartedAt(date);
    } else {
      setEndedAt(date);
    }
  };

  const handleFilter = () => {
    if (hasFilters > 0) {
      setPos("");
      setHasFilters(0);
      setStartedAt(null);
      setEndedAt(null);
      _loadData();
    } else {
      let filterCounter = 0;
      filterCounter += pos !== "" ? 1 : 0;
      filterCounter += startedAt ? 1 : 0;
      filterCounter += endedAt ? 1 : 0;

      setHasFilters(filterCounter);
      _loadData(pos, startedAt, endedAt);
    }
  };

  const handlePressItem = (itemId) => {
    setDetailsData(itemId);
    setShowDetails(true);
  };

  const _loadData = (pdvIndex, startedAt, endedAt) => {
    setLoading(true);

    const params = {
      by_pdvs: Array.from(new Set((pdvList[pdvIndex] || {}).ids || [])),
      started_at: startedAt
        ? moment
            .tz(startedAt, "America/Sao_Paulo")
            .format("YYYY-MM-DDTHH:mm:00")
        : null,
      ended_at: endedAt
        ? moment.tz(endedAt, "America/Sao_Paulo").format("YYYY-MM-DDTHH:mm:59")
        : null,
    };

    api
      .get(`sales-by-pos/${eventId}`, { params })
      .then((res) => {
        setData(res.data);

        if (pdvList.length === 0) {
          const pdvsByName = UtilsHelper.groupBy(res.data, "name");

          setPdvList(
            Object.keys(pdvsByName).map((key) => ({
              ids: pdvsByName[key].map((item) => item.id),
              name: pdvsByName[key][0].name,
            }))
          );
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Falha",
          text: "Não foi possível obter as informações, atualize a página e tente novamente.",
          icon: "warning",
        });
      })
      .finally(() => setLoading(false));
  };
// eslint-disable-next-line
  useEffect(_loadData, [eventId]);

  return (
    <>
      <PageHeader
        title="Vendas por PDV"
        text="Resumo de vendas por ponto de venda."
      ></PageHeader>
      <div id="page-salespos" className="container-fluid">
        <div className="pdv-sales-filter-container row mb-2">
          <div className="col">
            <div className="col">
              <div className="card">
                <div className="pdv-sales-filters card-body row d-flex align-items-center">
                  <div className="col-12 col-md-3">
                    <select value={pos} onChange={handleSelectPOS}>
                      {pdvList.length === 0 ? (
                        <option>nenhum pdv encontrado</option>
                      ) : (
                        <>
                          <option value="">selecione o ponto de venda</option>
                          {pdvList.map((item, index) => (
                            <option key={index} value={index}>
                              {item.name || "Sem nome"}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>

                  <div className="col-12 col-md-3 d-flex align-items-center justify-content-space-between filter-sale">
                    <span className="date-period-text">de:</span>
                    <DatePicker
                      showTimeInput
                      placeholderText={t("components:datepicker.startDate")}
                      todayButton
                      value={startedAt}
                      maxDate={endedAt}
                      onChange={(date) => handleSelectDate("start", date)}
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="datetimeinput-icon"
                    />
                  </div>

                  <div className="col-12 col-md-3 d-flex align-items-center justify-content-space-between filter-sale">
                    <span className="date-period-text">até:</span>
                    <DatePicker
                      showTimeInput
                      placeholderText={t("components:datepicker.endDate")}
                      todayButton
                      value={endedAt}
                      minDate={startedAt}
                      onChange={(date) => handleSelectDate("end", date)}
                      rightIcon
                    />
                  </div>

                  <div className="col-12 col-md-3">
                    <button
                      type="button"
                      className={`btn ${
                        hasFilters > 0
                          ? "btn-outline-secondary"
                          : "btn-outline-primary"
                      } w-100 apply-filters`}
                      onClick={handleFilter}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      {hasFilters > 0 && (
                        <span className="filter-counter">{hasFilters}</span>
                      )}
                      {hasFilters > 0 ? "limpar filtros" : "aplicar filtros"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col mb-5">
          <SimpleTable
            loading={loading}
            columns={[
              { title: "Ponto de Venda" },
              { title: "Terminal", className: "text-center" },
              { title: "Quantidade de Vendas", className: "text-center" },
              { title: "Ingressos Vendidos", className: "text-center" },
              { title: "Total", className: "text-right" },
            ]}
            rows={data.map((item) => ({
              id: { id: item.id, serial: item.terminal },
              infos: [
                { value: item.name || "Sem nome" },
                { value: item.terminal, className: "text-center" },
                { value: item.order_amount, className: "text-center" },
                { value: item.ticket_amount, className: "text-center" },
                {
                  value: formatCurrency(item.total, {
                    isCents: false,
                    currency,
                  }),
                  className: "text-right",
                },
              ],
            }))}
            footer={[
              {
                infos: [
                  {
                    value: "Quantidade total",
                  },
                  { value: "" },
                  {
                    value: data.reduce(
                      (acc, item) => acc + item.order_amount,
                      0
                    ),
                    className: "text-center",
                  },
                  {
                    value: data.reduce(
                      (acc, item) => acc + item.ticket_amount,
                      0
                    ),
                    className: "text-center",
                  },
                  {
                    value: formatCurrency(
                      data.reduce((acc, item) => acc + item.total, 0),
                      { isCents: false, currency }
                    ),
                    className: "text-right",
                  },
                ],
              },
            ]}
            onItemClick={handlePressItem}
          />
        </div>
      </div>

      <PosSalesDetails
        show={showDetails}
        pdvId={detailsData.id}
        serial={detailsData.serial}
        eventId={eventId}
        startedAt={startedAt}
        endedAt={endedAt}
        onClose={() => setShowDetails(false)}
      />
    </>
  );
};

export default VendasPDV;
