//Common
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastr';
import CurrencyInput from "react-currency-input";
import Swal from "sweetalert2";

//Providers
import Api from "../../../../../services/api.js";
import { useOnClickOutside } from 'App.js';

let container;

const ModalRegisterType = (props) => {

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => { if (props.status === 'modal-show') { close() } });

    const [model, setModel] = useState({
        id: "",
        name: "",
        default_price: "",
        description: ""
    });


    const registerModel = e => {
        e.preventDefault();

        if (props.id) {
            Api.post("equipments/editCategorie", model)
                .then(res => {
                    container.success("Categoria editada com sucesso!")
                    close();
                }).catch(err => {
                    container.error("Erro ao editar categoria!")
                    close();
                });
        } else {

            Api.post("equipments/createCategorie", model)
                .then(res => {
                    container.success("Categoria criada com sucesso!")
                    close();
                }).catch(err => {
                    container.error("Erro ao criar categoria!")
                    close();
                });
        }

    };


    const close = () => {

        setModel({
            id: "",
            name: "",
            default_price: "",
            description: ""
        });

        props.callback('modal-hidden')
    }

    useEffect(() => {

        if (props.id) {
            Api.get(`equipments/getCategorie/${props.id}`).then((res) => {
                setModel({
                    id: res.data.data.id,
                    name: res.data.data.name,
                    default_price: res.data.data.default_price / 100,
                    description: res.data.data.description
                });
            }).catch(() => {
                Swal.fire({
                  title: 'Ops!',
                  icon: 'error',
                  text: 'Ocorreu um erro interno, por favor tente novamente',
                  confirmButtonText: 'Ok'
                })
              })
        }

    }, [props.id])

    const updateModel = e => {
        setModel({
            ...model,
            [e.target.name]: e.target.value
        });
    };

    return (

        <>
            <div ref={ref} className={"shadow side-modal " + props.status} role="dialog">
                <div className="card-header ">
                    <h4 className="card-header-title">{props.id ? 'Editar tipo' : 'Cadastrar categoria'}</h4>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => close(e)}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card-body">
                    <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
                    <div className="row mt-2">
                        <div className="col-md-8">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nome da Categoria"
                                name="name"
                                value={model.name}
                                onChange={updateModel}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="default_price">Preço</label>
                            <CurrencyInput
                                value={model.default_price}
                                name="default_price"
                                id="default_price"
                                className="form-control"
                                prefix="R$ "
                                onChangeEvent={e => updateModel(e)}
                            />
                        </div>

                        <div className="col-md-12 mt-2">
                            <label htmlFor="desc">Descrição</label>
                            <textarea
                                className="form-control"
                                name="description"
                                placeholder="Descreva a categoria"
                                value={model.description}
                                onChange={updateModel}
                            />
                        </div>

                    </div>
                </div>

                <div className="box-button">
                    <button onClick={registerModel} className="btn btn-primary col-md-4 mr-3 c3">
                        Salvar
                    </button>
                </div>
            </div>
        </>
    )

}

export default ModalRegisterType;
