import React from "react";
import { ReactComponent as ArrowHeadRight } from "../../../../../assets/icons/arrowHeadRight.svg";
import { ReactComponent as ArrowHeadLeft } from "../../../../../assets/icons/arrowHeadLeft.svg";
import { ReactComponent as ArrowDoubleHeadRight } from "../../../../../assets/icons/arrowDoubleHeadRight.svg";
import { ReactComponent as ArrowDoubleHeadLeft } from "../../../../../assets/icons/arrowDoubleHeadLeft.svg";
import { useTranslation } from "react-i18next";

const Pagination = ({ pageInfo, handleNavigation, isMobile, setPageLimit }) => {
  const { t } = useTranslation("tickets");

  function calculatePaginationDisplay(totalItems, currentPage, itemsPerPage) {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    let endItem = currentPage * itemsPerPage;
    if (endItem > totalItems) {
      endItem = totalItems;
    }

    return (
      <span>
        <strong>{startItem}</strong>-<strong>{endItem}</strong>{" "}
        {t("nominalTransfers.of")} {totalItems}
      </span>
    );
  }

  const handlePageLimitChange = (event) => {
    setPageLimit(Number(event.target.value));
  };

  return (
    <>
      {!isMobile && (
        <div className="pagination-limit">
          <select
            onChange={handlePageLimitChange}
            value={pageInfo.itemsPerPage}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          {t("nominalTransfers.resultsPerPage")}
        </div>
      )}
      <div className="navigation-container">
        {!isMobile && (
          <div>
            {calculatePaginationDisplay(
              pageInfo.totalItems,
              pageInfo.currentPage,
              pageInfo.itemsPerPage
            )}
          </div>
        )}
        <button
          onClick={() => handleNavigation(1)}
          disabled={pageInfo.currentPage === 1}
        >
          <ArrowDoubleHeadLeft stroke="#0050C3" strokeWidth={"1.5px"} />
        </button>
        <button
          onClick={() => {
            if (pageInfo.currentPage > 1)
              handleNavigation(pageInfo.currentPage - 1);
          }}
          disabled={pageInfo.currentPage === 1}
        >
          <ArrowHeadLeft stroke="#0050C3" strokeWidth={"1.5px"} />
        </button>
        <button>
          <strong>{t("nominalTransfers.page")} </strong>
          <strong>
            {pageInfo.currentPage < 10
              ? `0${pageInfo.currentPage}`
              : pageInfo.currentPage}
          </strong>
          {" " + t("nominalTransfers.of") + " "}
          <strong>
            {pageInfo.totalPages < 10
              ? `0${pageInfo.totalPages}`
              : pageInfo.totalPages}
          </strong>
        </button>
        <button
          onClick={() => {
            if (pageInfo.currentPage < pageInfo.totalPages)
              handleNavigation(pageInfo.currentPage + 1);
          }}
          disabled={pageInfo.currentPage === pageInfo.totalPages}
        >
          <ArrowHeadRight stroke="#0050C3" strokeWidth={"1.5px"} />
        </button>
        <button
          onClick={() => handleNavigation(pageInfo.totalPages)}
          disabled={pageInfo.currentPage === pageInfo.totalPages}
        >
          <ArrowDoubleHeadRight stroke="#0050C3" strokeWidth={"1.5px"} />
        </button>
      </div>
    </>
  );
};

export default Pagination;
