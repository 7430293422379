import {
  faListUl,
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyData from "components/empty-data";
import PageHeader from "components/page-header";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

// Providers
import api from "services/api";

// Entry Components
import ModalIventory from "./modalInventory";
import ModalIventoryItem from "./modalIventoryItem";
import ModalIventoryHistoric from "./modalInventoryHistoric";
import ModalEventInventory from "./modalEventInventory";
import { useLanguageSettings } from "hooks/language-settings";

let container;

const Inventory = () => {
  const { noCentsCurrencyFormatter } = useLanguageSettings();

  const [editItem, setEditItem] = useState(null);

  const [data, setData] = useState([]);

  useEffect(() => {
    api
      .get("inventory/list")
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }, []);

  function reload() {
    api
      .get("inventory/list")
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }

  function deleteItem(row) {
    api
      .get(`inventory/delete/${row.id}`)
      .then((res) => {
        container.success("Item deletado com sucesso!");
        reload();
      })
      .catch((err) => {
        container.error(err.response.data.message);
      });
  }

  const [modalItens, setModalItens] = useState("modal-hidden");
  const [modalInventory, setModalInventory] = useState("modal-hidden");
  const [modalIventoryHistoric, setModalInventoryHistoric] =
    useState("modal-hidden");
  const [modalHistoricValue, setModalHisotircValue] = useState(null);
  const [modalEventInventory, setModalEventInventory] =
    useState("modal-hidden");

  function openModal(row) {
    setEditItem(row);
    setModalItens("modal-show");
  }

  function closeModal() {
    reload();
    setModalItens("modal-hidden");
  }

  function openModalInventory() {
    setModalInventory("modal-show");
  }

  function closeModalInventory() {
    reload();
    setModalInventory("modal-hidden");
  }

  function openModalIventoryHistoric(row) {
    setModalHisotircValue(row);
    setModalInventoryHistoric("modal-show");
  }

  function closeModalIventoryHistoric() {
    setModalInventoryHistoric("modal-hidden");
  }

  function openModalEventIventory(row) {
    setModalEventInventory("modal-show");
  }

  function closeModalEventIventory() {
    setModalEventInventory("modal-hidden");
  }

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <PageHeader
        title="Inventário"
        text="Gerenciamento do inventário interno"
        btnAction={(e) => openModal()}
        btnText="+ criar item"
      />
      <div className="container-fluid mt-4 mb-2">
        <div className="row">
          {/* <div className="col-md-4">
						<button  className="btn btn-secondary">
							<FontAwesomeIcon icon={faExchangeAlt} /> transferência entre centros
						</button>
					</div> */}
          <div className="col-md-6">
            <button className="btn btn-secondary" onClick={openModalInventory}>
              <FontAwesomeIcon icon={faListUl} /> lançamentos e remoção de
              inventário
            </button>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-secondary"
              onClick={openModalEventIventory}
            >
              <FontAwesomeIcon icon={faListUl} /> histórico por evento
            </button>
          </div>
        </div>
        <div className="row mt-4">
          <div
            className="col-md-12 table-responsive"
            style={{ paddingBottom: "60px" }}
          >
            {data.length === 0 ? (
              <EmptyData title="Nenhum resultado disponível." />
            ) : (
              <table className="table table-sm datatable-custom">
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th className="text-center">#Código</th>
                    <th className="text-center">Qtd Inicial</th>
                    <th className="text-center">Qtd Atual</th>
                    <th className="text-center">Custo</th>
                    <th className="text-center">Custo da Perda</th>
                    <th className="text-center">Custo da Locação</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((history, i) => {
                    return (
                      <tr key={i}>
                        <td>{history.name}</td>
                        <td className="text-center">{history.code}</td>
                        <td className="text-center">{history.quantity}</td>
                        <td className="text-center">
                          {history.actual_quantity}
                        </td>
                        <td className="text-center">
                          {noCentsCurrencyFormatter(history.cost)}
                        </td>
                        <td className="text-center">
                          {noCentsCurrencyFormatter(history.price_lost)}
                        </td>
                        <td className="text-center">
                          {noCentsCurrencyFormatter(history.price_location)}
                        </td>
                        <td className="text-right">
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn-table"
                              onClick={(e) => openModal(history)}
                            >
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                              ></FontAwesomeIcon>
                            </button>
                            <button
                              className="btn-table"
                              onClick={(e) =>
                                openModalIventoryHistoric(history)
                              }
                            >
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            </button>
                            <button
                              className="btn-table"
                              onClick={(e) => deleteItem(history)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                              ></FontAwesomeIcon>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ModalIventoryItem
        status={modalItens}
        callback={closeModal}
        item={editItem}
      />
      <ModalIventory status={modalInventory} callback={closeModalInventory} />
      <ModalIventoryHistoric
        status={modalIventoryHistoric}
        item={modalHistoricValue}
        callback={closeModalIventoryHistoric}
      />
      <ModalEventInventory
        status={modalEventInventory}
        callback={closeModalEventIventory}
      />
    </>
  );
};

export default Inventory;
