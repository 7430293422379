import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";

const StatusCheckin = (props) => {
	function timerToClose(){
		setTimeout(() => props.nextCheckin, 50);
	}
	return (
		
		<div onLoad= {() => timerToClose()}>
			{props.answer === "sucesso" ?
					<div className="checkin_result checkinSuccess" >
						<div className="checkin_content">
							<FontAwesomeIcon icon={faCheckCircle} />
							<h1>
								{`Checkin do código ${props.code} realizado em ${props.hour}   `}
							</h1>
						</div>
						<button onClick={props.nextCheckin}>
							Próximo
						</button>
					</div>
					:
					<div className="checkin_result checkinError" onKeyPress={event => {if (event.key === 'Enter') {alert("ok")}}}>
						<div className="checkin_content">
							<FontAwesomeIcon icon={faTimesCircle} />
							<h1>
								{`${props.typeError}  `}
							</h1>
						</div>
						<button onClick={props.nextCheckin}>
							Voltar
						</button>
					</div>
			}
		</div>
	)
}

export default StatusCheckin;