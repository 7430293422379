import { DatePicker } from "components/datepicker";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircleOutline, IoMdArrowBack } from "react-icons/io";
import Modal from "react-modal";
import Select from "react-select";
import Switch from "react-switch";
import { breakPoints } from "services/breakPoints";
import { COLORS, SPACINGS } from "services/constants";
import styled from "styled-components";
import Swal from "sweetalert2";
import api from "../../services/api";

Modal.setAppElement("#root");
ModalPassportLot.propTypes = {
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
  }),
  eventId: PropTypes.number,
  sectorId: PropTypes.number,
  sectorLot: PropTypes.object,
  setRefetch: PropTypes.func,
  isPassport: PropTypes.bool,
  isAutomaticTurn: PropTypes.bool,
};

export default function ModalPassportLot(props) {
  const {
    toggleFunction: { isOpen, setIsOpen },
    eventId,
    sectorLot,
    sectorId,
    setRefetch,
    isPassport,
  } = props;
  const { currencySymbol } = useCurrencyEventContext();
  const [error, setError] = useState(false);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [saleChannels, setSaleChannels] = useState([]);
  const [passportData, setPassportData] = useState({
    id: null,
    qty: 0,
    name: "",
    price: 0,
    cpf_limit: false,
    expiration: "",
    description: "",
    min_purchase: null,
    max_purchase: null,
    cpf_max_purchase: null,
    ticket_sale_channel_ids: [],
  });
  const [ticketOptions, setTicketOptions] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState({});
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modal-lot",
  });

  function chooseNumberLimit(value, min, max) {
    if (typeof value !== "number" || (value >= min && value <= max)) {
      return value;
    }

    if (value < min) {
      return min;
    }

    return max;
  }

  function setIntOrNull(value) {
    if (value) {
      return parseInt(value, 10);
    }
    return "";
  }

  function updateDate(value) {
    const timezoneDate = moment.tz(value, "America/Sao_Paulo");
    const formattedDate = timezoneDate.format();
    setPassportData((data) => ({
      ...data,
      expiration: formattedDate,
    }));
  }

  function updateData(event, value) {
    const finalValue = value !== undefined ? value : event.target.value;

    if (event.target && event.target.name === "price") {
      setPassportData((data) => ({
        ...data,
        [event.target.name]: Number(`${Math.round(`${finalValue}e2`)}e-2`),
      }));

      return;
    }

    setPassportData((data) => ({
      ...data,
      [!event.target.name ? event : event.target.name]: finalValue,
    }));
  }

  // eslint-disable-next-line no-undef
  document.onkeydown = async function submit(evt) {
    if (evt.key === "Enter") {
      if (blockSubmit) {
        return;
      }
      if (isOpen) {
        setBlockSubmit(true);
        salvar();
        // eslint-disable-next-line no-magic-numbers
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setBlockSubmit(false);
      }
    }
  };

  function updateCPFLimit() {
    setPassportData((data) => ({
      ...data,
      cpf_limit: data.cpf_limit === 1 ? 0 : 1,
    }));
  }

  function loadSaleChannels() {
    api
      .get("/ticket_sale_channels", {
        params: { page: 1, per_page: Number.MAX_SAFE_INTEGER },
      })
      .then((res) => setSaleChannels(res.data.data))
      .catch(() => {
        Swal.fire(t("alert.error.load-channels"), "", "warning");
      });
  }

  function loadTickets() {
    api
      .get(`/event/tickets/${eventId}`, { params: { passports: false } })
      .then((res) => {
        const options = (res.data || []).flatMap((item) =>
          item.tickets.flatMap((ticket) =>
            ticket.types.map((type) => ({
              value: type.id,
              label: `${item.sector} - ${ticket.lot.name} - ${type.type}`,
            }))
          )
        );

        setTicketOptions(options);
      })
      .catch((error) =>
        Swal.fire({
          title: t("alert.error.load-tickets.title"),
          icon: "error",
          text: t("alert.error.load-tickets.text"),
        })
      );
  }

  function addSelectedTickets(event) {
    if (!Object.keys(selectedTickets).includes(event.value.toString())) {
      const data = { ...selectedTickets };

      data[event.value] = {
        id: event.value,
        name: event.label,
        price: "",
        quantity: 1,
      };

      setSelectedTickets(data);
    }
  }

  function removeTicketData(key) {
    const data = { ...selectedTickets };
    delete data[key];
    setSelectedTickets(data);
  }

  function updateTicketData(key, field, value) {
    const data = { ...selectedTickets };
    data[key][field] = Number(`${Math.round(`${value}e2`)}e-2`);

    if (isNaN(data[key][field])) {
      data[key][field] = "";
    }

    setSelectedTickets(data);
  }

  React.useEffect(() => {
    if (!isOpen) {
      setError(false);
      setPassportData({
        id: null,
        qty: 0,
        name: "",
        price: 0,
        tickets: [],
        cpf_limit: false,
        expiration: "",
        description: "",
        min_purchase: 1,
        max_purchase: 5,
        cpf_max_purchase: null,
        ticket_sale_channel_ids: [],
      });
      setSelectedTickets({});
    } else {
      loadTickets();
      loadSaleChannels();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  React.useEffect(() => {
    if (sectorLot) {
      setPassportData({
        id: sectorLot.id,
        qty: sectorLot.qty,
        name: sectorLot.name,
        price: sectorLot.price,
        cpf_limit: sectorLot.cpf_limit,
        expiration: sectorLot.expiration,
        description: sectorLot.description,
        min_purchase: sectorLot.min_purchase,
        max_purchase: sectorLot.max_purchase,
        cpf_max_purchase: sectorLot.cpf_max_purchase,
        ticket_sale_channel_ids: sectorLot.ticket_sale_channel_ids || [],
      });
    }
  }, [sectorLot]);

  React.useEffect(() => {
    if (sectorLot && ticketOptions) {
      setSelectedTickets(
        sectorLot.passport_tickets.reduce((acc, item) => {
          acc[item.ticket_type_id] = {
            id: item.ticket_type_id,
            name: ticketOptions.find((opt) => opt.value === item.ticket_type_id)
              ?.label,
            price: item.price,
            quantity: item.quantity,
          };

          return acc;
        }, {})
      );
    }
  }, [sectorLot, ticketOptions]);

  async function salvar(event) {
    event.preventDefault();

    if (isPassport) {
      if (Object.keys(selectedTickets).length === 0) {
        setError(t("error.min-tickets"));
        return;
      }
      if (Object.values(selectedTickets).some((item) => item.quantity === "")) {
        setError(t("error.selected-fields"));
        return;
      }
    }
    const passportInfo = {
      qty: passportData.qty,
      name: passportData.name,
      price: passportData.price,
      cpf_limit: passportData.cpf_limit,
      event_id: eventId,
      sector_id: sectorId,
      expiration: passportData.expiration,
      description: passportData.description,
      min_purchase: passportData.min_purchase,
      max_purchase: passportData.max_purchase,
      cpf_max_purchase: passportData.cpf_max_purchase,
      tickets: Object.values(selectedTickets).map((item) => ({
        price: item.price,
        quantity: item.quantity,
        ticket_type_id: item.id,
      })),
      ticket_sale_channel_ids: passportData.ticket_sale_channel_ids.map(
        (item) => item.value
      ),
    };

    try {
      if (passportData.id) {
        await api.patch(
          "/passport-sector-lots/" + passportData.id,
          passportInfo
        );
      } else {
        await api.post("/passport-sector-lots", passportInfo);
      }

      setRefetch(true);
      const operation = passportData.id ? "alterado" : "criado";
      Swal.fire({
        title: t("alert.operation", { operation }),
        icon: "success",
        confirmButtonColor: "#23b936",
        cancelButtonColor: "#188125",
        text: t("alert.text"),
        confirmButtonText: "Ok",
      });

      setTimeout(() => {
        setIsOpen(false);
        // eslint-disable-next-line no-magic-numbers
      }, 1000);
    } catch (error) {
      const operation = passportData.id
        ? t("operation.alter")
        : t("operation.create");
      setError(t("error.lot-operation", { operation }));
    }
  }
  const operationLabel = passportData.id
    ? t("operation.label.edit")
    : t("operation.label.create");
  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      <ModalContainer>
        <HeaderContainer>
          <ArrowTextContainer>
            <BackArrowIcon size={23} onClick={() => setIsOpen(false)} />
            <HeaderText>{t("header.passport", { operationLabel })}</HeaderText>
          </ArrowTextContainer>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon size={25} />
          </CloseButton>
        </HeaderContainer>
        <InputContainer>
          <Form onSubmit={salvar}>
            <InfoLoteContainer>
              <LoteInfoText style={{ gridArea: "loteInfoText" }}>
                {t("info-text.lot")}
              </LoteInfoText>

              <div style={{ gridArea: "nomeInput" }}>
                <DescriptionInputText>{t("label.name")}</DescriptionInputText>
                <CustomInput
                  required
                  type="text"
                  name="name"
                  value={passportData.name}
                  onChange={updateData}
                  className="form-control"
                />
              </div>
              {!isPassport && (
                <div style={{ gridArea: "priceInput" }}>
                  <DescriptionInputText>
                    {t("label.sell-value")}
                  </DescriptionInputText>
                  <ValueInputContainer
                    className="d-flex flex-row form-control p-0"
                    style={{ height: 38 }}
                  >
                    <span
                      style={{ padding: "0 5px 0 10px" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {currencySymbol}
                    </span>
                    <EditarIngressoInput
                      style={{ outline: 0, border: "none", height: 38 }}
                      required
                      type="number"
                      name="price"
                      value={passportData.price}
                      onChange={updateData}
                    />
                  </ValueInputContainer>
                </div>
              )}
              <div style={{ gridArea: "dateInput" }}>
                <DescriptionInputText>{t("label.expire")}</DescriptionInputText>
                <DatePicker
                  showTime
                  value={
                    passportData.expiration
                      ? new Date(passportData.expiration)
                      : ""
                  }
                  showTimeSelect
                  name="expiration"
                  onChange={updateDate}
                  onKeyDown={(e) => e.preventDefault()}
                  placeholder={t("placeholder.expiration")}
                />
              </div>
              <div style={{ gridArea: "description" }}>
                <DescriptionInputText>
                  {t("label.description")}
                </DescriptionInputText>

                <CustomInput
                  type="text"
                  name="description"
                  value={passportData.description}
                  onChange={updateData}
                  className="form-control"
                />
              </div>
              <div style={{ gridArea: "qtyInput" }}>
                <DescriptionInputText>
                  {t("label.quantity")}
                </DescriptionInputText>
                <EditarIngressoInput
                  required
                  min={0}
                  type="number"
                  name="qty"
                  value={chooseNumberLimit(
                    setIntOrNull(passportData.qty),
                    0,
                    Number.MAX_SAFE_INTEGER
                  )}
                  onChange={(e) =>
                    updateData(
                      e,
                      chooseNumberLimit(
                        setIntOrNull(e.target.value),
                        0,
                        Number.MAX_SAFE_INTEGER
                      )
                    )
                  }
                  className="form-control"
                />
              </div>

              <div style={{ gridArea: "minBuy" }}>
                <DescriptionInputText>
                  {t("label.min-buy")}
                </DescriptionInputText>
                <EditarIngressoInput
                  required
                  min={1}
                  type="number"
                  name="min_purchase"
                  value={chooseNumberLimit(
                    setIntOrNull(passportData.min_purchase || 5),
                    1,
                    Number.MAX_SAFE_INTEGER
                  )}
                  onChange={(e) =>
                    updateData(
                      e,
                      chooseNumberLimit(
                        setIntOrNull(e.target.value),
                        1,
                        Number.MAX_SAFE_INTEGER
                      )
                    )
                  }
                  className="form-control"
                />
              </div>
              <div style={{ gridArea: "maxBuy" }}>
                <DescriptionInputText>
                  {t("label.max-buy")}
                </DescriptionInputText>
                <EditarIngressoInput
                  required
                  min={1}
                  type="number"
                  name="max_purchase"
                  value={chooseNumberLimit(
                    setIntOrNull(passportData.max_purchase || 5),
                    1,
                    Number.MAX_SAFE_INTEGER
                  )}
                  onChange={(e) =>
                    updateData(
                      e,
                      chooseNumberLimit(
                        setIntOrNull(e.target.value),
                        1,
                        Number.MAX_SAFE_INTEGER
                      )
                    )
                  }
                  className="form-control"
                />
              </div>
              <div style={{ gridArea: "selectPonto" }}>
                <DescriptionInputText>
                  {t("label.channels")}
                </DescriptionInputText>
                <PontoSelectContainer
                  name="ticket_sale_channel_ids"
                  value={passportData.ticket_sale_channel_ids}
                  isMulti={true}
                  placeholder={t("placeholder.channels")}
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(event) =>
                    updateData(
                      { target: { name: "ticket_sale_channel_ids" } },
                      event
                    )
                  }
                  options={saleChannels.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </div>
              <div style={{ gridArea: "switchCPF" }}>
                <DescriptionInputText
                  style={{ paddingBottom: SPACINGS.space2 }}
                >
                  {t("label.limit")}
                </DescriptionInputText>
                <SwitchContainer>
                  <Switch
                    handleDiameter={16}
                    onColor="#75d1f2"
                    onHandleColor={COLORS.middleBlue}
                    offColor={COLORS.weakGray}
                    offHandleColor={COLORS.gray}
                    height={12}
                    width={28}
                    name="cpf_limit"
                    onChange={updateCPFLimit}
                    checked={!!passportData.cpf_limit}
                    uncheckedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    checkedIcon={false}
                  />
                  <LimitText
                    style={{
                      color: passportData.cpf_limit
                        ? COLORS.middleBlue
                        : COLORS.darkGray,
                    }}
                  >
                    {t(passportData.cpf_limit ? "limited" : "unlimited")}
                  </LimitText>
                </SwitchContainer>
              </div>
              <div style={{ gridArea: "maxCPF" }}>
                <RowContainer>
                  <DescriptionInputText>
                    {t("label.limit-quantity")}
                  </DescriptionInputText>
                </RowContainer>
                <CustomInput
                  disabled={!passportData.cpf_limit}
                  min={1}
                  type="number"
                  name="cpf_max_purchase"
                  value={chooseNumberLimit(
                    setIntOrNull(passportData.cpf_max_purchase),
                    1,
                    5
                  )}
                  onChange={(e) =>
                    updateData(
                      e,
                      chooseNumberLimit(setIntOrNull(e.target.value), 1, 5)
                    )
                  }
                  className="form-control"
                />
              </div>
              {isPassport && (
                <>
                  <LoteInfoText style={{ gridArea: "ticketsInfoText" }}>
                    {t("info-text.tickets")}
                  </LoteInfoText>

                  <div style={{ gridArea: "tickets" }}>
                    <PontoSelectContainer
                      value={null}
                      options={ticketOptions}
                      className="mb-4"
                      placeholder={t("placeholder.tickets")}
                      closeMenuOnSelect={true}
                      onChange={addSelectedTickets}
                    />

                    {Object.keys(selectedTickets).map((key) => (
                      <TicketItem className="col-12 mb-4" key={key}>
                        <div className="col-12">
                          <TicketName>{selectedTickets[key].name}</TicketName>
                        </div>
                        <div className="row col-12 mt-2">
                          <div className="form-group col-12 col-md-5">
                            <DescriptionInputText>
                              {t("label.quantity")}
                            </DescriptionInputText>
                            <input
                              required
                              min={1}
                              type="number"
                              value={selectedTickets[key].quantity}
                              onChange={(e) =>
                                updateTicketData(
                                  key,
                                  "quantity",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-12 col-md-4">
                            <DescriptionInputText>
                              {t("label.price")}
                            </DescriptionInputText>
                            <input
                              required
                              type="number"
                              name="price"
                              value={selectedTickets[key].price}
                              onChange={(e) =>
                                updateTicketData(
                                  key,
                                  "price",
                                  chooseNumberLimit(
                                    e.target.value,
                                    1,
                                    Number.MAX_SAFE_INTEGER
                                  )
                                )
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-12 col-md-3 mt-0 mt-md-4">
                            <button
                              type="button"
                              onClick={() => removeTicketData(key)}
                              className="btn btn-danger w-100"
                            >
                              {t("button.remove")}
                            </button>
                          </div>
                        </div>
                      </TicketItem>
                    ))}
                  </div>

                  <ErroText
                    style={{
                      gridArea: "erroText",
                    }}
                  >
                    {error && error}
                  </ErroText>
                </>
              )}
              <SubmitButtonContainer style={{ gridArea: "submitButton" }}>
                <SubmitButton
                  className="btn btn-primary c3 btn-block mb-4"
                  type="submit"
                >
                  <SubmitButtonText>{t("button.save")}</SubmitButtonText>
                </SubmitButton>
              </SubmitButtonContainer>
            </InfoLoteContainer>
          </Form>
        </InputContainer>
      </ModalContainer>
    </ModalComponent>
  );
}

// /////////////////////////////////// CONTAINERs /////////////////////////
const TicketName = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const TicketItem = styled.div`
  padding: 10px 5px 5px;
  background-color: #e9e9e9;
  border-radius: 5px;
`;

const SubmitButtonContainer = styled.div`
  padding-bottom: ${SPACINGS.space4};
`;

const ValueInputContainer = styled.div`
  border: 1px solid ${COLORS.gray};
  height: 34px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
`;
const InputContainer = styled.div`
  padding: 0 ${SPACINGS.space0} 0 ${SPACINGS.space0};
  @media ${breakPoints.tablet} {
    padding: 0 ${SPACINGS.space4} 0 ${SPACINGS.space4};
  }
`;
const HeaderContainer = styled.div`
  padding: ${SPACINGS.space4};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 2px ${COLORS.gray} solid;
`;

const InfoLoteContainer = styled.div`
  display: grid;
  column-gap: 15px;
  padding-top: ${SPACINGS.space3};
  height: 300px;
  row-gap: 5px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto;
  grid-template-areas:
    "loteInfoText loteInfoText"
    "nomeInput priceInput"
    "description description"
    "dateInput dateInput"
    "editText editText"
    "qtyInput qtyInput"
    "minBuy  maxBuy"
    "selectPonto selectPonto"
    "switchCPF maxCPF"
    "ticketsInfoText ticketsInfoText"
    "tickets tickets"
    "erroText erroText"
    "submitButton submitButton";

  @media ${breakPoints.tablet} {
    row-gap: 15px;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas:
      "loteInfoText loteInfoText loteInfoText loteInfoText"
      "nomeInput nomeInput nomeInput priceInput"
      "description description description description"
      "editText editText editText editText"
      "qtyInput dateInput minBuy maxBuy"
      "selectPonto selectPonto switchCPF maxCPF"
      "ticketsInfoText  ticketsInfoText ticketsInfoText ticketsInfoText"
      "tickets tickets tickets tickets"
      "erroText erroText submitButton submitButton";
  }
`;

const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: visible;
`;

const PontoSelectContainer = styled(Select)`
  border-radius: 5px;
  width: 100%;
`;

// /////////////////////////////////// TEXTs //////////////////////////////

const ArrowTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButtonText = styled.span`
  color: ${COLORS.white};
  font-size: 18px;
  height: 38px;
  white-space: nowrap;
`;
const DescriptionInputText = styled.span`
  font-size: 12px;
  color: ${COLORS.darkGray};
  padding-right: ${SPACINGS.space0};
  white-space: nowrap;
`;
const HeaderText = styled.span`
  padding-left: ${SPACINGS.space2};
  color: ${COLORS.darkGray};
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
`;
const LoteInfoText = styled.span`
  color: ${COLORS.black};
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  white-space: nowrap;
`;
const ErroText = styled.span`
  padding-left: 10px;
  color: red;
  height: 38px;
`;
const LimitText = styled.span`
  padding-left: ${SPACINGS.space2};
  font-size: 14px;
  color: ${COLORS.darkGray};
`;
// /////////////////////////////////// INPUTs /////////////////////////////

const CustomInput = styled.input`
  border: 1px solid ${COLORS.gray};
  border-radius: 5px;
  width: 100%;
  height: 38px;
`;
const EditarIngressoInput = styled.input`
  border: 1px solid ${COLORS.gray};
  border-radius: 5px;
  width: 120px;
  height: 38px;
  background: none;
  /* &:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
		-webkit-transition-delay: 9999s;
	} */
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;
// /////////////////////////////////// BUTTONs ////////////////////////////

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
const SubmitButton = styled.button`
  border: none;
  background-color: ${COLORS.middleBlue};
  border-radius: 10px;
  height: 38px;
  width: 100%;
  &:hover {
    background-color: ${COLORS.darkBlue};
  }
`;
// /////////////////////////////////// ICONs //////////////////////////////

const BackArrowIcon = styled(IoMdArrowBack)`
  color: ${COLORS.gray};
  cursor: pointer;
  font-size: 15px;
`;
const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 15px;
`;

const ModalComponent = styled(Modal)`
  width: 100%;
  @media ${breakPoints.sideBarClosed} {
    width: 55%;
  }
`;
