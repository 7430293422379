import React, { useEffect, useRef, useState, useMemo } from "react";
import PageHeader from "components/page-header";
import "../salesTickets/style.css";
import EmptyData from "components/empty-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSortDown,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import Api from "services/api.js";
import { useOnClickOutside } from "App";
import Loading from "components/loading";
import ModalTicket from "./modalTicket/index";
import { ToastContainer } from "react-toastr";
import { orderStatusNames } from "services/constants";
import { useLanguageSettings } from "hooks/language-settings";
import New_api from "services/new-api";
import { useTranslation } from "react-i18next";

let container;

const SalesTickets = () => {
  let refdrop = useRef();
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const {t} = useTranslation()

  const [showModal, setShowModal] = useState(false);
  const [load /*SetLoad*/] = useState(true);
  const [dataTicket, setDataTicket] = useState();
  const [dataList, setDataList] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [drop, setDrop] = useState("");
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [paginate, setPaginate] = useState(false);
  const [filter, setFilter] = useState({
    token_ticket: "",
    user_name: "",
    email: "",
    status: "",
    page: 1,
    perPage: 25,
  });

  const usuarioLogado = useMemo(
    () => JSON.parse(localStorage.getItem("user")),
    []
  );

  useOnClickOutside(refdrop, () => setDrop(false));

  const statusPayment = {
    finalizado: "Finalizado",
    estornado: "Estornado",
    carrinho: "Carrinho",
    pendente: "Pendente",
    disputa: "Disputa",
    expirado: "Expirado",
    expirada: "Expirada",
    charged_back: "Charged Back",
    estornado_parcialmente: "Estorno Parcial",
    pendente_estorno: "Estorno Pendente",
    "erro-adquirencia": "Erro de adquirência",
  };

  function dropdown() {
    if (drop === "show") {
      setDrop("");
    } else {
      setDrop("show");
    }
  }

  const updateData = (e) => {
    if (e.target.name === "token_ticket") {
      setFilter({
        status: "",
        user_name: "",
        email: "",
        [e.target.name]: e.target.value,
      });
      setDataList([]);
    } else {
      setFilter({
        ...filter,
        [e.target.name]: e.target.value,
      });
    }
  };

  const pagination = (typePage, numberPage, perPage) => {
    switch (typePage) {
      case "Initial":
        setFilter({
          ...filter,
          page: numberPage,
          perPage: perPage,
        });
        break;
      case "left":
        numberPage--;
        if (numberPage <= 0) {
          setFilter({
            ...filter,
            page: 1,
            perPage: perPage,
          });
        } else {
          setFilter({
            ...filter,
            page: numberPage,
            perPage: perPage,
          });
        }
        break;
      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          setFilter({
            ...filter,
            page: numberPage,
            perPage: perPage,
          });
        } else {
          numberPage++;
          setFilter({
            ...filter,
            page: numberPage,
            perPage: perPage,
          });
        }
        break;

      case "lastPage":
        setFilter({
          ...filter,
          page: numberPage,
          perPage: perPage,
        });
        break;
      default:
        setFilter({
          ...filter,
          page: 1,
          perPage: 25,
        });
        break;
    }
    setPaginate(true);
  };

  const fetchData = (type, status) => {
    if (type === "status") {
      filter.status = status;
    }

    document.getElementById("loading").classList.remove("esconde");
    Api.post(`/support/venda/`, filter)
      .then((res) => {
        if (res.data.total !== 0) {
          if (filter.token_ticket) {
            setDataTicket({
              statusSup: res.data.data[0]?.statusSup,
              orderId: res.data.data[0]?.orderId,
              tokenTicket: res.data.data[0]?.tokenTicket,
              eventNameSup: res.data.data[0]?.eventNameSup,
              paymentType: res.data.data[0]?.paymentType,
              userSup: res.data.data[0]?.userSup,
              totalValue: res.data.data[0]?.totalValue,
              userSupEmail: res.data.data[0]?.userSupEmail,
              oursTax: res.data.data[0]?.oursTax,
              eventStartDateSup: res.data.data[0]?.eventStartDateSup,
              createdAt: res.data.data[0]?.createdAt,
              transaction: res.data.data[0]?.transaction,
              taxSup: res.data.data[0]?.taxSup,
              firstnumbers: res.data.data[0]?.firstnumbers,
              lastnumbers: res.data.data[0]?.lastnumbers,
              total: res.data.data[0]?.total,
              acquirer: res.data.data[0]?.acquirer,
              situationRate: res.data.data[0]?.situationRate,
              channel: res.data.data[0]?.channel,
              discountCode: res.data.data[0]?.discount_code,
              discountType: res.data.data[0]?.discount_type,
              discountValue: res.data.data[0]?.discount_value,
              antifraud_transaction_id:
                res.data.data[0]?.antifraud_transaction_id,
              antifraud_status: res.data.data[0]?.antifraud_status,
              secure_mode: res.data.data[0]?.secure_mode,
              antifraud_recommendation:
                res.data.data[0]?.antifraud_recommendation,
              antifraud_score: res.data.data[0]?.antifraud_score,
              charge_id: res.data.data[0]?.charge_id,
              userFacebook: res.data.data[0]?.facebook,
              userInstagram: res.data.data[0]?.instagram,
              userTwitter: res.data.data[0]?.x_twitter,
              serial: res.data.data[0]?.serial,
            });
          } else {
            setDataList(res.data.data);
            document.getElementById("loading").classList.add("esconde");
          }
          setAtualizaPaginaAtual(res.data.page);
          setAtualizaUltimaPagina(res.data.lastPage);
          setAtualizaQuantPesquisa(res.data.total);
        } else {
          container.error("Dados incorretos ou inexistentes!");
          document.getElementById("loading").classList.add("esconde");
        }
      })
      .catch(() => {
        container.error("Dados incorretos ou inexistentes!");
      });
  };

  const handleKeyDown = (event) => {
    if (filter.token_ticket) {
      if (event.key === "Enter") {
        fetchData();
        setDataList([]);
        setFilter({
          ...filter,
          status: "",
          user_name: "",
          email: "",
        });
      }
    } else {
      if (event.key === "Enter") {
        fetchData();
      }
    }
  };

  const changeBadge = (value) => {
    switch (value) {
      case "finalizado":
        return "badge badge-pill badge-success";
      case "estornado":
        return "badge badge-pill badge-warning";
      case "não pagou":
        return "badge badge-pill badge-danger";
      case "pendente":
        return "badge badge-pill badge-dark";
      case "finalizada":
        return "badge badge-pill badge-success";
      case "disputa":
        return "badge badge-pill badge-warning";
      case "charged_back" || "charged back":
        return "badge badge-pill badge-info";
      case "carrinho":
        return "badge badge-pill badge-primary";
      case "expirado":
        return "badge badge-pill badge-dark";
      // no default
    }

    return "badge  badge-pill bg-secondary-soft";
  };

  function openModal(item) {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleClickItem(item) {
    setDataTicket({
      statusSup: item.statusSup,
      orderId: item.orderId,
      tokenTicket: item.tokenTicket,
      eventNameSup: item.eventNameSup,
      paymentType: item.paymentType,
      userSup: item.userSup,
      totalValue: item.totalValue,
      userSupEmail: item.userSupEmail,
      oursTax: item.oursTax,
      eventStartDateSup: item.eventStartDateSup,
      createdAt: item.createdAt,
      transaction: item.transaction,
      taxSup: item.taxSup,
      firstnumbers: item.firstnumbers,
      lastnumbers: item.lastnumbers,
      situationRate: item.situationRate,
      antifraud_transaction_id: item.antifraud_transaction_id,
      antifraud_status: item.antifraud_status,
      secure_mode: item.secure_mode,
      antifraud_recommendation: item.antifraud_recommendation,
      antifraud_score: item.antifraud_score,
      charge_id: item.charge_id,
      userFacebook: item.facebook || "Não informado",
      userInstagram: item.instagram || "Não informado",
      userTwitter: item.x_twitter || "Não informado",
    });
  }

  useEffect(() => {
    if (!dataTicket) {
      if (load) {
        document.getElementById("loading").classList.add("esconde");
      }
    }

    if (dataTicket) {
      const orderToken = dataTicket.tokenTicket
          ? dataTicket.tokenTicket
          : filter.token_ticket

      const perPage = Number.MAX_SAFE_INTEGER
      const page = 1

      New_api.get('/tickets', { params: { orderToken, page, perPage} })
        .then((res) => {
          document.getElementById("loading").classList.add("esconde");
          setTicket(res.data);
          openModal();
        })
        .catch((err) => {
          container.error("Dados incorretos ou inexistentes!");
          document.getElementById("loading").classList.add("esconde");
        });
    }

    if (paginate) {
      fetchData();
      setPaginate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, dataTicket, paginate]);

  return (
    <>
      <PageHeader
        title="Auditoria"
        text="Busca de informações pelo código da venda"
      ></PageHeader>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <Loading title={t("global:loadingText")} />
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="card-search mb-2">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  className="input-custom"
                  placeholder="Filtrar pelo código"
                  name="token_ticket"
                  value={filter.token_ticket || ""}
                  onChange={updateData}
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-search mb-2">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  className="input-custom"
                  placeholder="Filtrar por nome"
                  name="user_name"
                  value={filter.user_name || ""}
                  onChange={updateData}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="col-md-3 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  className="input-custom col-12"
                  placeholder="Filtrar por email"
                  name="email"
                  value={filter.email || ""}
                  onChange={updateData}
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-md-3 mb-2">
              <div
                className={`card-search drop-custom ${drop}`}
                ref={refdrop}
                onClick={dropdown}
              >
                <div className="input-custom">
                  {filter.status
                    ? statusPayment[filter.status]
                    : "Filtrar por status"}
                </div>
                <FontAwesomeIcon
                  icon={faSortDown}
                  className="icon-search"
                  id="arrowDown"
                />
                <div className="dropdown-menu">
                  <div
                    className="dropdown-item"
                    onClick={(e) => {
                      setFilter({
                        ...filter,
                        status: "",
                      });
                      fetchData();
                    }}
                  >
                    Limpar filtro
                  </div>
                  {Object.entries(statusPayment).map(([value, key]) => (
                    <div
                      key={key}
                      className="dropdown-item"
                      onClick={(e) => {
                        fetchData("status", value);
                      }}
                    >
                      {key}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {filter.user_name === "" &&
          filter.email === "" &&
          filter.status === "" ? (
            <>
              <EmptyData title="Preencha algum dos campos para pesquisar." />
            </>
          ) : (
            <div className="card">
              <div className="table-responsive">
                <table class="table table-sm card-table">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Código</th>
                      <th scope="col" className="text-left">
                        Evento
                      </th>
                      <th scope="col" className="text-left">
                        Cliente
                      </th>
                      <th scope="col" className="text-center">
                        Pagamento
                      </th>
                      <th scope="col" className="text-center">
                        Status
                      </th>
                      <th scope="col" className="text-center">
                        Data
                      </th>
                      <th scope="col" className="text-center">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.map((item, i) => (
                      <tr
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickItem(item)}
                      >
                        <td>
                          <span className="spanPointer">
                            {item.tokenTicket}
                          </span>
                        </td>
                        <td className="text-left">{item.eventNameSup}</td>
                        <td className="text-left">{item.userSup}</td>
                        <td className="text-center">{item.paymentType}</td>
                        <td className="text-center">
                          <span className={changeBadge(item.statusSup)}>
                            {orderStatusNames[item.statusSup] || item.statusSup}
                          </span>
                        </td>
                        <td className="text-center">{item.createdAt}</td>
                        <td className="text-center">
                          {noCentsCurrencyFormatter(item.totalValue)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-12">
                <div className="row d-flex justify-content-between datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2">Resultados por Página:</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) =>
                          pagination(
                            "Initial",
                            filter.page,
                            (filter.perPage = e.target.value)
                          )
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    {filter.perPage * atualizaPaginaAtual >=
                    atualizaQuantPesquisa ? (
                      <span>
                        {" "}
                        Exibindo {atualizaQuantPesquisa} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        Exibindo {filter.perPage * atualizaPaginaAtual} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    )}

                    <button
                      onClick={() => pagination("Initial", 1, filter.perPage)}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        pagination("left", atualizaPaginaAtual, filter.perPage)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                    </span>

                    <button
                      onClick={() =>
                        pagination("right", atualizaPaginaAtual, filter.perPage)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        pagination(
                          "lastPage",
                          atualizaUltimaPagina,
                          filter.perPage
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalTicket
        toggleModal={(showModal, setShowModal)}
        show={showModal}
        dataTicket={dataTicket}
        fetchData={fetchData}
        usuarioLogado={usuarioLogado}
        ticket={ticket}
        onClose={handleCloseModal}
        onUpdate={handleCloseModal}
      />
    </>
  );
};

export default SalesTickets;
