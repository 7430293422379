import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import { NavLink } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import Api from "services/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faEyeSlash,
  faTrashAlt,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastr";
import "./styles.css"
import EmptyData from "components/empty-data";

let container;

const Sector = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [data, setData] = useState([]);

  async function fetchData() {
    const result = await Api.get(`/bar-sector/fetchSectors/${event_id}`);
    setData(result.data);

  }

  useEffect(() => {

    async function fetchData() {
      const result = await Api.get(`/bar-sector/fetchSectors/${event_id}`);
      setData(result.data);

    }

    fetchData();
  }, [event_id])

  const HandleClick = id => {
    Swal.fire({
      title: "Deletar",
      icon: "warning",
      text: "Deseja realmente deletar este Setor?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        Api.post(`bar-sector/deleteSector/${id}`)
          .then(res => {
            container.success("Setor delatado com sucesso!");
            fetchData();
          })
          .catch(err => {
            Swal.fire("Deletar", "Setor não deletado", "error");
          });
      }
    });
  };

  const HandleActivate = id => {
    Swal.fire({
      title: "Ativar",
      icon: "warning",
      text: "Deseja realmente ativar este Setor?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        Api.post(`bar-sector/statusActSector/${id}`)
          .then(res => {
            container.success("Setor ativado com sucesso!");
            fetchData();
          })
          .catch(err => {
            Swal.fire("Ativar", "Setor não ativado", "error");
          });
      }
    });
  };

  const HandleDeactivate = id => {
    Swal.fire({
      title: "Desativar",
      icon: "warning",
      text: "Deseja realmente desativar este Setor?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        Api.post(`bar-sector/statusDelSector/${id}`)
          .then(res => {
            container.success("Setor dessativado com sucesso!");
            fetchData();
          })
          .catch(err => {
            Swal.fire("Desativar", "Setor não desativado", "error");
          });
      }
    });
  };

  createTheme("ticket", {
    text: {
      primary: "#4F6C7C",
      secondary: "#CBC1BF",
      font: "roboto"
    },
    headertext: {
      primary: "#2F81DA"
    },
    divider: {
      default: "#e9f2f2"
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
      divider: {
        default: "#3F25A8"
      }
    },
    button: {
      default: "#3F25A8",
      focus: "#00A7E1",
      hover: "#20C4F4",
      disabled: "rgba(0, 0, 0, 0.12)"
    }
  });
  const [selectableRows] = useState(false);
  const [noSelectAll] = useState(false);
  const [selectableRowsHighlight] = useState(false);
  const [expandableRows] = useState(false);
  const [expandOnRowClick] = useState(false);
  const [pagination] = useState(true);
  const [highlight] = useState(false);
  const [striped] = useState(false);
  const [pointer] = useState(false);
  const [dense] = useState(false);
  const [persist] = useState(false);
  const [tableHead] = useState(false);
  const [loading] = useState(false);
  const [noHeader] = useState(true);
  const [subHeader] = useState(false);
  const [fixedHeader] = useState(false);

  const columns = [
    {
      name: "Nome",
      selector: "name"
    }, {
      name: "Tipo",

      cell: row => {

        switch (row.type) {
          case 0:
            return (<div>Master</div>)
            // break;
          case 1:
            return (<div>Consumo</div>)
            // break;
          case 2:
            return (<div>Recarga</div>)
            // break;
          default:
            return (<div></div>)
            // break;
        }

      }
    },
    {
      name: 'Status',
      cell: row => (
        row.status === 1 ? (
          <div className="badge badge-success">Ativo</div>
        ) : (
            <div className="badge badge-danger">Desativado</div>
          )
      )
    },
    {
      right: true,
      cell: row => (
        <>
          <ReactTooltip className="tooltip-custom" effect="solid" place="top" />
          <NavLink
            className="btn-table"
            to={`/bar/edit-sector?id=${row.id}&event_id=${event_id}`}
            style={{ marginRight: "6px" }}
            data-tip="Editar"
          >
            <FontAwesomeIcon icon={faPen} />
          </NavLink>

          <button
            className="btn-table"
            style={{ marginRight: "6px" }}
            onClick={() => HandleClick(row.id)}
            data-tip="Deletar"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>

          {row.status === 1 ? (
            <button
              className="btn-table"
              onClick={() => HandleDeactivate(row.id)}
              data-tip="Desativar"
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </button>
          ) : (
              <button
                className="btn-table"
                onClick={() => HandleActivate(row.id)}
                data-tip="Ativar"
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            )}
        </>
      )
    }
  ];

  return (
    <>
      <PageHeader
        title="Setores"
        text="Controle e crie setores para dividir as áreas e relatórios do seu evento."
        btnLink={`/bar/register-sector?event_id=${event_id}`}
        btnText="criar setor"
      >
      </PageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-top-right"
        />
        <div className="row mt-4">
          <div className="col-md-12">
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="title"
              selectableRows={selectableRows}
              selectableRowsNoSelectAll={noSelectAll}
              selectableRowsHighlight={selectableRowsHighlight}
              expandableRows={expandableRows}
              expandOnRowClicked={expandOnRowClick}
              pagination={pagination}
              paginationComponentOptions={{ rowsPerPageText: 'Resultados por páginas:', rangeSeparatorText: 'de', noRowsPerPage: false }}
              highlightOnHover={highlight}
              striped={striped}
              pointerOnHover={pointer}
              dense={dense}
              noTableHead={tableHead}
              persistTableHead={persist}
              progressPending={loading}
              noHeader={noHeader}
              noDataComponent={
                <EmptyData title="Nenhum resultado disponível."/>
              }
              subHeader={subHeader}
              theme="ticket"
              fixedHeader={fixedHeader}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sector;
