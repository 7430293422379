import axios from "axios";

const financialApi = axios.create({
  baseURL: process.env.REACT_APP_FINANCIAL_API,
});

financialApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


export default financialApi;
