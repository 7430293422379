import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { ReactComponent as NoSalesTicket } from "assets/icons/NoSalesTicket.svg";
import { useTranslation } from "react-i18next";

export function NoSalesYet() {
  const { t } = useTranslation("global");

  return (
    <Fragment>
      <Card className="p-5">
        <Card.Body className="d-flex flex-column justify-content-center align-items-center">
          <NoSalesTicket />
          <h3 className="no-sales-title mt-3 mb-0">{t("noSalesTitle")}</h3>
          <p className="no-sales-subtitle">{t("noSalesSubtitle")}</p>
        </Card.Body>
      </Card>
    </Fragment>
  );
}
