import React, { useEffect, useState } from "react";
import api from "services/api";
import "./styles.css";
import EmptyData from "components/empty-data";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { useLanguageSettings } from "hooks/language-settings";

const ModalDetail = (props) => {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const [payments, setPayments] = useState([]);
  const [mostSellers, setMostSellers] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalQuantPay, setTotalQuantPay] = useState(0);
  const [totalProd, setTotalProd] = useState(0);
  const [totalQuantProd, setTotalQuantProd] = useState(0);
  const [type, setType] = useState("geral");

  const closeModal = () => {
    props.callback();
    setType("geral");
  };

  const selectedType = (type) => {
    var ActiveBtns = document.getElementsByClassName("type-button");
    for (let index = 0; index < ActiveBtns.length; index++) {
      const element = ActiveBtns[index];
      element.classList.remove("active");
    }
    document.getElementById("type-" + type).classList.add("active");
    setType(type);
  };

  useEffect(() => {
    if (props.serial || props.caixa) {
      api
        .post(`dash/caixaReportDetail/${props.event}`, {
          dataInit: props.dataInit
            ? moment(props.dataInit)
                .tz("America/Sao_Paulo")
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          dataFim: props.dataEnd
            ? moment(props.dataEnd)
                .tz("America/Sao_Paulo")
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          caixa: props.caixa,
          serial: props.serial,
          operator: props.operator,
          type: type,
        })
        .then((res) => {
          setPayments(res.data.result);
          setTotalPayment(res.data.totalPayment);
          setTotalQuantPay(res.data.totalQuant);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .post(`dash/caixaProductsDetails/${props.event}`, {
          dataInit: props.dataInit
            ? moment(props.dataInit)
                .tz("America/Sao_Paulo")
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          dataFim: props.dataEnd
            ? moment(props.dataEnd)
                .tz("America/Sao_Paulo")
                .format("YYYY-MM-DD HH:mm:ss")
            : null,
          caixa: props.caixa,
          serial: props.serial,
          operator: props.operator,
          type: type,
        })
        .then((res) => {
          setMostSellers(res.data.listProducts);
          setTotalProd(res.data.totalValueProd);
          setTotalQuantProd(res.data.totalQuantProd);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      selectedType(type);
    }
    // eslint-disable-next-line
  }, [props.serial, props.event, props.caixa, type]);

  return (
    <>
      <div className={"shadow side-modal " + props.status} role="dialog">
        <div className="card-header">
          <h4 className="card-header-title">Detalhes por ponto de venda</h4>
          <button
            type="button"
            className="close"
            onClick={(e) => closeModal(e)}
          >
            &times;
          </button>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-around mt-3 m-1">
            <div
              className="type-button col"
              id="type-geral"
              onClick={(e) => selectedType("geral")}
            >
              {"Geral (Compilado)"}
            </div>
            <div
              className="type-button col"
              id="type-ticket"
              onClick={(e) => selectedType("ticket")}
            >
              Ticket
            </div>
            <div
              className="type-button col"
              id="type-cashless"
              onClick={(e) => selectedType("cashless")}
            >
              Cashless
            </div>
          </div>
          {totalPayment > 0 && (
            <>
              <h4 className="card-header-title mt-4 ml-3">
                Informações de pagamento:
              </h4>
              <div className="table table-responsive">
                <table
                  id="table-totalsales"
                  className="table table-sm mt-4 datatable-custom"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">FORMA DE PAGAMENTO</th>
                      <th scope="col" className="text-center">
                        QUANTIDADE DE VENDAS
                      </th>
                      <th scope="col" className="text-right">
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item.raw_method !== "cash" ||
                          item.name === "Dinheiro"
                            ? item.name
                            : `${item.name} (dinheiro)`}
                        </td>
                        <td className="text-center">{item.count}</td>
                        <td className="text-right">
                          {noCentsCurrencyFormatter(item.total)}
                        </td>
                      </tr>
                    ))}
                    <tr className="thead-light">
                      <td>Total</td>
                      <td className="text-center">{totalQuantPay}</td>
                      <td className="text-right">
                        {noCentsCurrencyFormatter(totalPayment)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
          {totalProd > 0 && (
            <>
              <h4 className="card-header-title mt-3">
                Informações dos produtos:
              </h4>
              <div className="table table-responsive">
                <table
                  id="table-totalsales"
                  className="table table-sm mt-4 datatable-custom"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">PRODUTO</th>
                      <th scope="col" className="text-center">
                        UNIDADE
                      </th>
                      <th scope="col" className="text-center">
                        VALOR UNITÁRIO
                      </th>
                      <th scope="col" className="text-right">
                        VALOR TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mostSellers.map((item, i) => (
                      <tr key={i}>
                        <td>{item.product}</td>
                        <td className="text-center">{item.count}</td>
                        <td className="text-center">
                          {noCentsCurrencyFormatter(item.unique_price)}
                        </td>
                        <td className="text-right">
                          {noCentsCurrencyFormatter(item.total)}
                        </td>
                      </tr>
                    ))}
                    <tr className="thead-light">
                      <td>Total</td>
                      <td className="text-center">{totalQuantProd}</td>
                      <td></td>
                      <td className="text-right">
                        {noCentsCurrencyFormatter(totalProd)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
          {totalProd <= 0 && totalPayment <= 0 && (
            <>
              <EmptyData title="Nenhum resultado disponível." />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalDetail;
