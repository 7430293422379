import { useMediaQuery } from "react-responsive";
export const useResponsive = () => {
  const isXs = useMediaQuery({ query: "(max-width: 576px)" });
  const isSm = useMediaQuery({
    query: "(min-width: 577px) and (max-width: 768px)",
  });
  const isMd = useMediaQuery({
    query: "(min-width: 769px) and (max-width: 992px)",
  });
  const isLg = useMediaQuery({
    query: "(min-width: 993px) and (max-width: 1600px)",
  });
  const isXl = useMediaQuery({ query: "(min-width: 1601px)" });

  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
  };
};
