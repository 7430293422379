import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiLoaderAlt } from "react-icons/bi";
import Select from "react-select";
import { ToastContainer } from "react-toastr";
import styled from "styled-components";
import * as yup from "yup";

import { useOnClickOutside } from "App";
import ButtonContained from "components/buttonContained";
import ButtonOutline from "components/buttonOutline";
import FormField from "components/FormField";
import api from "services/api";
import { COLORS, SponsorCategories } from "services/constants";
import { ValidationsHelper } from "services/validations";

import download_svg from "../../../../../../../assets/icons/download.svg";

import "./styles.css";

SponsorsModal.propTypes = {
  callback: PropTypes.func,
  status: PropTypes.string,
  onClose: PropTypes.func,
  sponsorsList: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
      logo_url: PropTypes.string,
      category: PropTypes.string,
      order: PropTypes.number,
    })
  ),
  sponsor: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    logo_url: PropTypes.string,
    category: PropTypes.string,
    order: PropTypes.number,
  }),
};

let container;

export default function SponsorsModal({
  onClose,
  status,
  sponsor,
  sponsorsList,
  callback,
}) {
  const formSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required("O nome é obrigatório.")
      .test(
        "isValidSponsorName",
        "Já existe um parceiro cadastrado com este nome.",
        (value) =>
          sponsor
            ? !sponsorsList
                .map((sponsor) => sponsor.name)
                .filter((name) => sponsor?.name !== name)
                .includes(value)
            : !sponsorsList.map((sponsor) => sponsor.name).includes(value)
      ),
    category: yup.string().trim().required("A categoria é obrigatória."),
    logo_url: yup.string().trim().required("A imagem é obrigatória."),
    urlExists: yup.boolean(),
    url: yup.string().when("urlExists", {
      is: (value) => value,
      then: yup
        .string()
        .trim()
        .test(
          "isValidSponsorUrl",
          "Favor inserir um link válido.",
          (value) => !value || ValidationsHelper.isWebLinkValid(value)
        ),
      otherwise: yup.string(),
    }),
  });

  let ref = useRef();
  const hiddenFileInput = useRef(null);
  useOnClickOutside(ref, () => {
    if (status === "modal-show") {
      close();
    }
  });

  const [imageLoading, setImageLoading] = useState(false);
  const [sponsorImage, setSponsorImage] = useState("");

  const {
    clearErrors,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: sponsor?.name || "",
      urlExists: !!sponsor?.url || false,
      url: sponsor?.url || "",
      category: sponsor?.category || "",
      logo_url: sponsor?.logo_url || "",
    },
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  const formSponsor = watch();

  const switchStyles = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor: errors.category?.message ? "#dc3545" : "#ced4da",
      borderRadius: "0.375rem",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  const close = () => {
    hiddenFileInput.current.value = "";
    onClose("modal-hidden");
    setSponsorImage("");
    setImageLoading(false);
    reset();
  };

  const uploadSponsorImage = (event) => {
    setImageLoading(true);
    const form_data = new FormData();
    const file_data = event.target.files[0];
    form_data.append("image", file_data);

    api
      .post("uploads?local=sponsors", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setValue("logo_url", res.data.url, {
          shouldValidate: true,
          shouldTouch: true,
        });
        clearErrors("logo_url");
        setSponsorImage(res.data.url);
      })
      .catch(() =>
        container.error(
          "Ocorreu um erro durante o upload. Por favor tente novamente."
        )
      )
      .finally(() => setImageLoading(false));
  };

  const uploadImg = () => hiddenFileInput.current.click();

  const onSubmit = (form) => {
    delete form["urlExists"];

    const action = sponsor ? "edit" : "create";

    if (action === "edit") {
      const payload = {
        ...form,
        id: sponsor.id,
        url: form.url || null,
      };
      callback(payload, action);
      close();
    } else {
      callback(form, action);
      close();
    }
  };

  useEffect(() => {
    if (sponsor && status === "modal-show") {
      setSponsorImage(sponsor?.logo_url);
      setValue("name", sponsor.name, {
        shouldTouch: true,
      });
      if (sponsor.url) {
        setValue("url", sponsor.url);
      }
      setValue("logo_url", sponsor.logo_url, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("category", sponsor.category, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
    // eslint-disable-next-line
  }, [sponsor, status]);

  return (
    <div ref={ref} className={"shadow side-modal " + status} role="dialog">
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className="col-12 p-0">
        <div className="card-header container-register-user">
          <h4 className="card-header-title">Adicionar parceiro</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div className="sponsors-container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-3">
            <div
              className="sponsors-logo-container"
              style={{
                backgroundImage: `url(${sponsorImage})`,
              }}
            >
              {imageLoading && (
                <LoadingContainer>
                  <LoadingIcon color={COLORS.darkBlue} size={50} />
                </LoadingContainer>
              )}
            </div>
          </div>
          <div className="col-md-6 col-9 d-flex justify-content-end">
            <div className="sponsors-logo-button-container">
              A dimensão recomendada é de 300 x 300
              <div className="d-flex justify-content-end">
                <div className="sponsors-logo-button-content">
                  <ButtonOutline
                    className="primary"
                    onClick={uploadImg}
                    text={
                      <Fragment>
                        <img src={download_svg} alt="download_svg_sponsors" />
                        Logo do apoiador
                      </Fragment>
                    }
                  />
                  <input
                    type="file"
                    accept="image/png,image/jpeg,image/jpg"
                    ref={hiddenFileInput}
                    onChange={uploadSponsorImage}
                    className="d-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {errors.logo_url?.message && (
          <h6 className="mb-0">
            <small className="text-danger">{errors.logo_url?.message}</small>
          </h6>
        )}
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" d-flex flex-column p-0 sponsor-input-container">
              <FormField
                label="Nome"
                classNameLabel="sponsor-label-input"
                classNameGroup="mb-0"
                errorMessage={errors.name?.message}
                controlId="validationName"
              >
                <Form.Control
                  {...register("name")}
                  name="name"
                  maxLength={50}
                  autoComplete="nope"
                  type="text"
                  placeholder="Nome do parceiro"
                  isInvalid={errors.name?.message}
                />
              </FormField>
            </div>
            <div className=" d-flex flex-column p-0 sponsor-input-container">
              <FormField
                label="Url (Opcional)"
                classNameLabel="sponsor-label-input"
                classNameGroup="mb-0"
                errorMessage={errors.url?.message}
                controlId="validationUrl"
              >
                <Form.Control
                  {...register("url")}
                  name="url"
                  autoComplete="nope"
                  onChange={(e) =>
                    setValue("urlExists", !!e.target.value, {
                      shouldValidate: true,
                      shouldTouch: true,
                    })
                  }
                  maxLength={60}
                  type="text"
                  placeholder="https://exemplo.com"
                  isInvalid={errors.url?.message}
                />
              </FormField>
            </div>
            <div className=" d-flex flex-column p-0 sponsor-input-container">
              <label className="sponsor-label-input">Categorias</label>
              <Select
                placeholder="Selecionar"
                styles={switchStyles}
                className="col-12 p-0"
                value={
                  formSponsor
                    ? SponsorCategories.filter(
                        (option) => option.value === formSponsor?.category
                      )
                    : ""
                }
                onChange={(e) =>
                  setValue("category", e.value, {
                    shouldValidate: true,
                    shouldTouch: true,
                  })
                }
                options={SponsorCategories}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              {errors.category?.message && (
                <h6>
                  <small className="text-danger">
                    {errors.category?.message}
                  </small>
                </h6>
              )}
            </div>
            <div className=" d-flex add-sponsor-button-container  justify-content-end">
              <div className="add-sponsor-button-content">
                <ButtonContained
                  className={
                    !isValid ? "disabled-contained" : "primary-contained"
                  }
                  disabled={!isValid}
                  content="Salvar"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const LoadingContainer = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
`;

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
