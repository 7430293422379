import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve();
    }
  });

  failedQueue = [];
};

const refreshCredentials = async () => {
  const refresh = localStorage.getItem("refresh_token");
  const response = await api.post(`/token`, {
    refresh_token: refresh,
  });

  localStorage.setItem("token", response.data.token);
  localStorage.setItem("refresh_token", response.data.refresh_token);
};

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) =>
          failedQueue.push({ resolve, reject })
        )
          .then(() => api.request(originalRequest))
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        await refreshCredentials();
        processQueue();

        return api.request(originalRequest);
      } catch (err) {
        await processQueue(err);

        localStorage.clear();
        window.location.href = "/sign-in";
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
