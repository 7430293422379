import {
  faFileDownload,
  faPaperPlane,
  faPencilAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BankAccountInfosModal from "components/bank-account-infos-modal";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { useLanguageSettings } from "hooks/language-settings";
import { numberMask } from "mask";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { ToastContainer } from "react-toastr";
import {
  CHANNEL_LABELS,
  translatedAntifraudRecommendation,
  translatedAntifraudStatus,
  translatedOrderStatusNames,
} from "services/constants";
import Swal from "sweetalert2";
import { formatCurrency } from "utils";
import { dateFormatter } from "utils/date-formatter";
import { capitalizeFirstWord } from "utils/str-format";
import Api from "../../../../services/api";
import "./style.css";
import _ from "lodash";

let container;

const builderTicketUrl = process.env.REACT_APP_TICKET_BUILDER_URL;
const translatedNotprovided = [
  "Não informado",
  "Not provided",
  "No especificado",
];

const ModalVendas = (props) => {
  const { currencyInputFormatter } = useLanguageSettings();
  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");

  const { currency } = useCurrencyEventContext();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [participante, setParticipante] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [payment_type, setPayment_type] = useState();
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [infoParticipante, setInfoParticipante] = useState([]);
  const [showAccountModal, setShowAccountModal] = useState(false);

  const [infoComprador, setInfoComprador] = useState({
    fullName: "",
    email: "",
    telefone: "",
    facebook: "",
    instagram: "",
    x_twitter: "",
    dataHora: "",
    origem: "",
    comissario: "",
    desconto: "",
    transactionCode: "",
    chargeCode: "",
    ultimosNumeros: "",
    status: "",
    nomeComissario: "",
    total: "",
    fee: "",
    raw: null,
  });
  useEffect(() => {
    const { facebook, instagram, x_twitter } = infoComprador;
    Object.keys({ facebook, instagram, x_twitter }).forEach((key) => {
      if (translatedNotprovided.includes(infoComprador[key])) {
        setInfoComprador((prev) => ({
          ...prev,
          [key]: t("tickets:sales.detailedExtract.notProvided"),
        }));
      }
    });
    // eslint-disable-next-line
  }, [language]);

  function openModal(item) {
    setIsOpen(true);
    setParticipante(item);
  }

  async function closeModal() {
    setIsOpen(false);
  }

  const atualizaTicket = () => {
    const { id, partFname, partLname } = participante;

    Api.post("update/ticket", { ticket: { id, partFname, partLname } })
      .then(() => {
        container.success(t("sales.detailedExtract.ticketUpdated"));
      })
      .catch(() => {
        container.error(t("sales.detailedExtract.ticketUpdateError"));
      });
  };

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -60%)",
      inset: "50% auto auto 50%;",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };

  async function _sendRefund(refund_type, data_banks) {
    const refund_value = formatCurrency(
      refund_type === "refund_without_taxes"
        ? (infoParticipante[0]?.total_value * 100 - infoParticipante[0]?.tax) /
            100
        : infoParticipante[0]?.total_value,
      { isCents: false, currency }
    );

    const swal_title =
      refund_type === "partial_refund"
        ? t("sales.detailedExtract.surePartialRefund")
        : t("sales.detailedExtract.sureRefund", { refundValue: refund_value });
    const currencyPlaceholder = formatCurrency(0, { currency });
    const swal_html = `${t(
      "sales.detailedExtract.inputPassword"
    )}<br/> <input id="swal-input1" class="swal2-input" type="password" placeholder="${t(
      "sales.detailedExtract.password"
    )}"> ${
      refund_type === "partial_refund"
        ? `${t(
            "sales.detailedExtract.setClientRefundValue"
          )}<br/> <input id="swal-input2" class="swal2-input" value="${currencyPlaceholder}" type="text"><br/>`
        : ""
    }<small><b>${t("sales.detailedExtract.cantUndoThisAction")}</b></small>`;

    await Swal.fire({
      icon: "warning",
      title: swal_title,
      html: swal_html,
      focusConfirm: false,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: t("sales.detailedExtract.reverseYes"),
      cancelButtonText: t("global:no"),
      onClose: () => setLoadingRefund(false),
      onOpen: (element) => {
        if (element.querySelector("#swal-input2")) {
          const moneyInput = element.querySelector("#swal-input2");
          moneyInput.addEventListener("keyup", (e) => {
            e.target.value = currencyInputFormatter(e.target.value, currency);
          });
        }
      },
      preConfirm: async () => {
        setLoadingRefund(true);
        const inputs = {
          password: document.getElementById("swal-input1").value,
          ...(refund_type === "partial_refund" && {
            value: numberMask(document.getElementById("swal-input2").value),
          }),
        };
        const validator = await swalInputValidator(inputs, refund_type);

        if (!validator.status) {
          return Swal.fire({
            title: t("sales.detailedExtract.attention"),
            text:
              validator.message ||
              t("sales.detailedExtract.errorReversingSale"),
            icon: "warning",
          }).then(() => _sendRefund(refund_type));
        }
        if (infoParticipante[0]?.payment_type === "Boleto") {
          props.onClose();
          setShowAccountModal(true);
        } else {
          return Api.post(
            `/estorno/venda/${infoParticipante[0]?.token_ticket}`,
            {
              password: inputs.password,
              ...(refund_type === "partial_refund" && {
                amount: inputs.value / 100,
              }),
              ...(data_banks && {
                ...data_banks,
              }),
              refund_type,
            }
          )
            .then(() => {
              Swal.fire(
                t("global:success"),
                t("sales.detailedExtract.reverseSuccess"),
                "success"
              );
              props.onRefund();
            })
            .catch((err) => {
              const message =
                err.response && err.response.data
                  ? err.response.data.message
                  : "";
              Swal.fire({
                title: "Atenção",
                text: message || t("sales.detailedExtract.errorReversingSale"),
                icon: "error",
              }).then(() => _sendRefund(refund_type));
            })
            .finally(() => {
              setLoadingRefund(false);
              setShowAccountModal(false);
            });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  const swalInputValidator = (inputs_values, refund_type) => {
    const number = inputs_values.value
      ? Number(numberMask(inputs_values.value)) / 100
      : null;
    if (isNaN(number) && refund_type === "partial_refund") {
      return {
        status: false,
        message: t("sales.detailedExtract.invalidInformedValue"),
      };
    } else if (number <= 0 && refund_type === "partial_refund") {
      return {
        status: false,
        message: t("sales.detailedExtract.valueShouldBeGreaterThanZero"),
      };
    } else if (
      number > infoComprador.total &&
      refund_type === "partial_refund"
    ) {
      return {
        status: false,
        message: t("sales.detailedExtract.valueShouldBeLessThanTotal"),
      };
    } else if (!inputs_values.password) {
      return {
        status: false,
        message: t("sales.detailedExtract.passwordRequired"),
      };
    } else {
      return { status: true };
    }
  };

  function sendRefundWithAccountInfo(bankInfos) {
    const infos = {
      agency: bankInfos.agencia,
      account_number: bankInfos.conta,
      document: bankInfos.documento,
      account_digit: bankInfos.digitoconta,
      bank_code: bankInfos.idBanco,
      account_holder: bankInfos.nome,
      type: bankInfos.tipo,
    };

    _sendRefund("full_refund", infos);
  }

  function openPdf(codigo) {
    const code = Buffer.from(`${codigo}$${props.eventId}`).toString("base64");
    window.open(`${builderTicketUrl}/${code}`);
  }

  function handleResendTickets() {
    infoParticipante.resending = true;
    let cb = () => {
      delete infoParticipante.resending;
    };

    Api.post("send/ticket", {
      order_id: props.compradorId,
    })
      .then(() => {
        Swal.fire(
          t("global:success"),
          t("sales.detailedExtract.resendSuccess"),
          "success"
        ).then(cb);
      })
      .catch(() => {
        Swal.fire(t("global:error"), "err", "error").then(cb);
      });
  }

  useEffect(() => {
    if (props.compradorId) {
      Api.get(`detailsBuyerSalesEvent/${props.compradorId}`)
        .then((res) => {
          const item = res.data.detailsBuyerSalesEvent[0];
          setPayment_type(item.payment_type);
          let channel = item.channel;
          channel =
            item?.channel === "pos"
              ? `${CHANNEL_LABELS[item?.channel]}-${item?.serial}`
              : CHANNEL_LABELS[item?.channel];

          setInfoComprador({
            email: item?.email,
            fullName: item?.first_name + " " + item?.last_name,
            telefone: item?.telephone,
            facebook: item?.facebook || t("sales.detailedExtract.notProvided"),
            instagram:
              item?.instagram || t("sales.detailedExtract.notProvided"),
            x_twitter:
              item?.x_twitter || t("sales.detailedExtract.notProvided"),
            dataHora: item?.created_at,
            origem: res.data.detailsBuyerSalesEvent.payment_place,
            codigoDesconto: item?.discount_code,
            tipoDesconto: item?.discount_type,
            valorDesconto: item?.discount_value,
            transactionCode: item?.transaction_id,
            chargeCode: item?.charge_id,
            primeirosNumeros: item?.card_firstsnumbers,
            ultimosNumeros: item?.card_lastsnumbers,
            status: item?.status,
            comissario: item?.steward_id,
            nomeComissario:
              res.data.detailsSteward[0]?.first_name +
              " " +
              res.data.detailsSteward[0]?.last_name,
            subtotal: item.producer_net_value / 100,
            fee: (item.tax || 0) / 100,
            total: item.total_value,
            fee_situation:
              item.situation_rate === "Repassa"
                ? t("sales.detailedExtract.feeSituation.passThrough")
                : t("sales.detailedExtract.feeSituation.absorbed"),
            channel,
            acquirer: item.acquirer,
            raw: item,
          });
          setInfoParticipante(res.data.detailsBuyerSalesEvent);
        })
        .catch(() => {
          Swal.fire({
            title: t("global:error.ops"),
            icon: "error",
            text: t("global:error.internalServerError"),
            confirmButtonText: t("global:confirmButtonText"),
          });
        });
    }
    // eslint-disable-next-line
  }, [props.compradorId, infoComprador.status]);

  // Definição sobre cor das badges
  const badgeStatus =
    infoComprador.status === "finalizado"
      ? "bg-success"
      : infoComprador.status === "pendente"
      ? "bg-warning"
      : infoComprador.status === "estornado" ||
        infoComprador.status === "charged_back"
      ? "bg-danger"
      : "bg-secondary-soft";

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div
        className={`shadow modal-participantes ${
          props.show ? "modal-show" : "modal-hidden"
        }`}
        role="dialog"
        id="modal-order"
      >
        <div className="w-100 mt-2">
          <div className="card-header">
            <div className="row">
              <span className={badgeStatus + " badge badge-pill"}>
                {t(
                  `constants:${
                    translatedOrderStatusNames[infoComprador.status]
                  }`
                ) || infoComprador.status}
              </span>
              <span className="ml-2 card-header-title">
                {infoParticipante[0]?.token_ticket}
              </span>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => props.onClose()}
            >
              <b aria-hidden="true">×</b>
            </button>
          </div>

          <h4 className="card-header-title col-12 mt-2">
            {t("sales.detailedExtract.transactionInformation")}
          </h4>
          <div className="col-md-12 sales-details mt-2">
            <div className="row">
              <div className="col-md-6">
                {infoComprador.transactionCode && (
                  <>
                    <p>
                      <b>{t("sales.detailedExtract.transactionId")}:</b>{" "}
                      {infoComprador.transactionCode || "-"}
                    </p>
                    <p>
                      <b>{t("sales.detailedExtract.chargeId")}: </b>{" "}
                      {infoComprador.chargeCode || "-"}
                    </p>
                  </>
                )}
                <p>
                  <b>{t("sales.detailedExtract.origin")}:</b>{" "}
                  {infoComprador.channel}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.discount")}:</b>{" "}
                  {infoComprador.codigoDesconto
                    ? `${infoComprador.codigoDesconto} - ${
                        infoComprador.tipoDesconto === 1
                          ? `${infoComprador.valorDesconto}%`
                          : `${formatCurrency(infoComprador.valorDesconto, {
                              currency,
                              isCents: false,
                            })}`
                      }`
                    : t("sales.detailedExtract.notApplied")}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.taxes")}: </b>
                  {formatCurrency(infoComprador.fee, {
                    isCents: false,
                    currency,
                  })}{" "}
                  ({infoComprador.fee_situation})
                </p>
                <p>
                  <b>{t("sales.detailedExtract.total")}: </b>
                  {formatCurrency(infoComprador.total, {
                    isCents: false,
                    currency,
                  })}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <b>{t("sales.detailedExtract.dateHour")}:</b>{" "}
                  {dateFormatter(infoComprador.dataHora, {
                    locale: language,
                    shouldShowTime: true,
                  })}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.promoterId")}:</b>{" "}
                  {infoComprador.comissario
                    ? infoComprador.comissario
                    : t("sales.detailedExtract.dontHaveIt")}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.promoterName")}:</b>{" "}
                  {infoComprador.nomeComissario !== "undefined undefined"
                    ? infoComprador.nomeComissario
                    : t("sales.detailedExtract.dontHaveIt")}
                </p>
                {infoComprador.primeirosNumeros ? (
                  <p>
                    <b>{t("sales.detailedExtract.card")}:</b>{" "}
                    {infoComprador.primeirosNumeros +
                      "***" +
                      infoComprador.ultimosNumeros}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <h4 className="card-header-title col-12 mt-2">
            {t("sales.detailedExtract.buyerInformation")}:
          </h4>
          <div id="buyer_info" className="col-md-12 sales-details mt-2">
            <div className="row">
              <div className="col-md-6">
                <p>
                  <b>{t("sales.detailedExtract.name")}:</b>{" "}
                  {infoComprador.fullName || "Não informado"}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.phone")}:</b>{" "}
                  {infoComprador.telefone}{" "}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.instagram")}:</b>{" "}
                  {infoComprador.instagram}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <b>{t("sales.detailedExtract.email")}:</b>{" "}
                  {infoComprador.email}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.facebook")}:</b>{" "}
                  {infoComprador.facebook}
                </p>
                <p>
                  <b>{t("sales.detailedExtract.twitter")}:</b>{" "}
                  {infoComprador.x_twitter}
                </p>
              </div>
            </div>
          </div>

          {infoComprador?.raw?.antifraud_transaction_id && (
            <>
              <h4 className="card-header-title col-12 mt-2 d-flex justify-content-between align-items-center">
                <span>
                  {t("sales.detailedExtract.fraudAnalysisInformation")}:
                </span>

                {infoComprador.raw.antifraud_status !== "not_analyzed" && (
                  <a
                    class="btn btn-outline-info btn-sm mt-2"
                    target="_blank"
                    style={{ fontSize: 10 }}
                    href={`${process.env.REACT_APP_KONDUTO_BASE_URL}/${infoComprador.raw.antifraud_transaction_id}`}
                    rel="noopener noreferrer"
                  >
                    {t("sales.detailedExtract.seeMore")}
                  </a>
                )}
              </h4>
              <div id="antifraud_info" className="col-md-12 sales-details mt-2">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <b>{capitalizeFirstWord(t("global:status"))}:</b>{" "}
                      {infoComprador.raw.antifraud_status
                        ? t(
                            `constants:${
                              translatedAntifraudStatus[
                                infoComprador.raw.antifraud_status
                              ][infoComprador.raw.antifraud_recommendation]
                            }`
                          )
                        : "-"}
                    </p>
                    <p>
                      <b>3DS:</b>{" "}
                      {infoComprador.raw.secure_mode === 0
                        ? t("global:no")
                        : t("global:yes")}
                    </p>
                  </div>

                  <div className="col-md-6">
                    <p>
                      <b>{t("sales.detailedExtract.recommendation")}:</b>{" "}
                      {t(
                        `constants:${
                          translatedAntifraudRecommendation[
                            infoComprador.raw.antifraud_recommendation
                          ]
                        }`
                      ) || "-"}
                    </p>
                    <p>
                      <b>{t("sales.detailedExtract.score")}:</b>{" "}
                      {infoComprador.raw.antifraud_score / 100}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          <h4 className="card-header-title col-12 mt-2">
            {t("sales.detailedExtract.orderTickets")}:
          </h4>
          <div className="col-md-12 table-responsive mt-2">
            <table className="table table-sm table-bordered">
              <thead className="">
                <tr>
                  <th scope="col">{t("sales.detailedExtract.name")}</th>
                  <th scope="col">{t("sales.detailedExtract.code")}</th>
                  <th scope="col">{t("sales.detailedExtract.ticket")}</th>
                  <th scope="col">{t("sales.detailedExtract.value")}</th>
                  <th scope="col">{t("sales.detailedExtract.checkin")}</th>
                  <th scope="col">{t("sales.detailedExtract.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {infoParticipante.map((participante, i) => (
                  <tr key={i}>
                    <td>
                      {_.compact([
                        participante.partFname,
                        participante.partLname,
                      ]).join(" ") || "Não informado"}
                    </td>
                    <td>{participante.code}</td>
                    <td>
                      {participante.seat_name && (
                        <>
                          {t("sales.detailedExtract.seat")}:{" "}
                          {participante.seat_name}
                          <br />
                        </>
                      )}
                      {participante.passport_sector_name && (
                        <>
                          {t("sales.detailedExtract.group")}{" "}
                          {participante.passport_ticket}:{" "}
                          {participante.passport_sector_name} -{" "}
                          {participante.passport_lot_name}
                          <br />
                        </>
                      )}
                      {participante.nameSector} - {participante.nameType} -{" "}
                      {participante.nameLot}
                    </td>
                    <td>
                      {formatCurrency(participante.value, {
                        isCents: false,
                        currency,
                      })}
                    </td>
                    <td>
                      {participante.data + participante.hora
                        ? participante.data + " - " + participante.hora
                        : t("sales.detailedExtract.notCheckedIn")}
                    </td>
                    <td className="text-center">
                      <FontAwesomeIcon
                        className="custom-control-label"
                        icon={faFileDownload}
                        onClick={() => openPdf(participante.code)}
                      />
                      <FontAwesomeIcon
                        className="custom-control-label ml-2"
                        icon={faPencilAlt}
                        onClick={() => {
                          openModal(participante);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <div className="row justify-content-between">
              {props.usuarioLogado.is_admin &&
                props.usuarioLogado.type === "GOD" &&
                ["finalizado", "pendente-estorno-sistema"].includes(
                  infoComprador.status
                ) && (
                  <Fragment>
                    {props?.payment_type !== "Cortesia" && (
                      <div className="col-sm-12 col-md-4 pt-2">
                        <button
                          disabled={loadingRefund}
                          className="btn btn-danger w-100"
                          onClick={() => _sendRefund("full_refund")}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                          {loadingRefund
                            ? t("sales.detailedExtract.processing")
                            : t("sales.detailedExtract.totalRefund")}
                        </button>
                      </div>
                    )}
                    {props.situation_rate === "Repassa" &&
                      props?.payment_type !== "Cortesia" && (
                        <div className="col-sm-12 col-md-4 pt-2">
                          <button
                            disabled={loadingRefund}
                            className="btn btn-danger w-100"
                            onClick={() => _sendRefund("refund_without_taxes")}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                            {loadingRefund
                              ? t("sales.detailedExtract.processing")
                              : t("sales.detailedExtract.noTaxesRefund")}
                          </button>
                        </div>
                      )}
                    {!["Dinheiro", "PicPay", "Cortesia", "picpay"].includes(
                      props?.payment_type
                    ) && (
                      <div className="col-sm-12 col-md-4 pt-2">
                        <button
                          disabled={loadingRefund}
                          className="btn btn-danger w-100"
                          onClick={() => _sendRefund("partial_refund")}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                          {loadingRefund
                            ? t("sales.detailedExtract.processing")
                            : t("sales.detailedExtract.partialRefund")}
                        </button>
                      </div>
                    )}
                    {props?.payment_type === "Cortesia" && (
                      <div className="col-sm-12 col-md-4 pt-2">
                        <button
                          disabled={loadingRefund}
                          className="btn btn-danger w-100"
                          onClick={() => _sendRefund("cancel_courtesy")}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                          {loadingRefund
                            ? t("sales.detailedExtract.processing")
                            : t("sales.detailedExtract.cancelSale")}
                        </button>
                      </div>
                    )}
                  </Fragment>
                )}
              {infoComprador.status === "finalizado" && (
                <div className="col-sm-12 col-md-4 pt-2">
                  <button
                    className="btn btn-primary c3 w-100"
                    disabled={infoParticipante.resending}
                    onClick={handleResendTickets}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                    {t("sales.detailedExtract.resendTickets")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          className={"editaParticipante"}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
          ariaHideApp={false}
          contentLabel={t("sales.detailedExtract.editParticipant")}
        >
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <label htmlFor="first_name">
                {t("sales.detailedExtract.name")}
              </label>
              <input
                className="form-control"
                type="text"
                defaultValue={participante.partFname}
                onChange={(e) => {
                  participante.partFname = e.target.value;
                  setParticipante(participante);
                }}
                id="first_name"
                placeholder={t("sales.detailedExtract.name")}
              />
            </div>
            <div className="col-md-6 col-12">
              <label htmlFor="last_name">
                {t("sales.detailedExtract.lastName")}
              </label>
              <input
                className="form-control"
                type="text"
                defaultValue={participante.partLname}
                onChange={(e) => {
                  participante.partLname = e.target.value;
                }}
                id="last_name"
                placeholder={t("sales.detailedExtract.lastName")}
              />
            </div>
          </div>
          <div className="col-md-12 col-12">
            <button
              className="btn btn-primary c3"
              onClick={() => {
                atualizaTicket();
                closeModal();
              }}
            >
              {t("global:edit")}
            </button>
          </div>
        </Modal>
      </div>

      {showAccountModal && (
        <BankAccountInfosModal
          show
          isActionDisable={loadingRefund}
          onFinish={sendRefundWithAccountInfo}
          onClose={() => setShowAccountModal(false)}
        />
      )}
    </>
  );
};

export default ModalVendas;
