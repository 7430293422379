import PageHeader from "components/page-header";
import React, { Fragment, useState, useEffect } from "react";
import "./style.css";
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import New_api from "services/new-api";
import Loading from "components/loading";
import { Dropdown } from "react-bootstrap";
import ModalEventCategorization from "./modal-event-categorization";

function EventCategorization() {
  const [changeItem, setChangeItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [categorizationId, setCategorizationId] = useState();
  const [perPage, setPerPage] = useState(25);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [categorizationName, setCategorizationName] = useState("");
  const [isSubcategory, setIsSubcategory] = useState(false);
  const [, setRoute] = useState("");

  useEffect(() => {
    categorizationList("Initial", 1, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeItem, reload]);

  const categorizationList = async (type, page, perPage) => {
    setLoading(true);
    let params = { page, per_page: perPage };
    const route = changeItem === 0 ? "/categories" : "/subcategories";

    switch (type) {
      case "Initial":
        params = { page: 1, per_page: perPage };
        break;

      case "right":
        if (page >= lastPage) {
          params = { page, per_page: perPage };
        } else {
          page++;
          params = { page, per_page: perPage };
        }
        break;

      case "left":
        page = Math.max(1, page - 1);
        params = { page, per_page: perPage };
        break;

      default:
        params = { page: 1, per_page: perPage };
    }

    const categories = await New_api.get(route, { params });
    setCategories(categories.data.data);
    setCurrentPage(categories.data.meta.current_page);
    setLastPage(categories.data.meta.last_page);
    setTotalPages(categories.data.meta.total);

    setReload(false);
    setLoading(false);
  };

  const handleUpdatePerPage = (event) => {
    setPerPage(event.target.value);
    categorizationList("Initial", 1, event.target.value);
  };

  const editModal = async (id) => {
    setLoading(true);
    const routeSelected = changeItem === 0 ? "/categories" : "/subcategories";
    setRoute(routeSelected);
    const categorization = await New_api.get(`${routeSelected}/${id}`);
    const source = categorization.data.children || categorization.data.parents;

    const categorizationMap = source.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setCategorizationName(categorization.data.name);
    setDefaultValue(categorizationMap);
    setCategorizationId(id);
    setIsSubcategory(!!categorization.data.is_subcategory)
    setLoading(false);
    setIsOpen(true);
  };

  const resetModal = () => {
    setCategorizationName("");
    setDefaultValue(null);
    setCategorizationId(null);
    setLoading(false);
    setIsOpen(false);
  }

  return (
    <Fragment>
      <PageHeader
        id="event-categorization"
        title="Categorização de eventos"
        text="Essa é a página inicial dos tipos de categoria de ingressos."
        btnAction={() => setIsOpen(true)}
        btnText={changeItem === 0 ? "Criar categoria" : "Criar subcategoria"}
      />

      {loading ? (
        <Fragment>
          <Loading title="aguarde..." />
        </Fragment>
      ) : (
        <Fragment>
          <div className="col mt-1" id="subheader">
            <ul className="nav nav-tabs nav-overflow header-tabs">
              <li className="nav-item">
                <span
                  className={`nav-link text-nowrap subheader-item ${
                    changeItem === 0 ? "active" : ""
                  }`}
                  onClick={() => setChangeItem(0)}
                >
                  Categorias
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link text-nowrap subheader-item ${
                    changeItem === 1 ? "active" : ""
                  }`}
                  onClick={() => setChangeItem(1)}
                >
                  Subcategorias
                </span>
              </li>
            </ul>
          </div>

          <div className="col-12 mt-3">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-sm card-table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col-8">
                        {changeItem === 0 ? "Categoria" : "Subcategoria"}
                      </th>
                      <th scope="col-4" className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((categories, id) => (
                      <tr key={id}>
                        <td>{categories?.name}</td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Dropdown>
                              <Dropdown.Toggle className="dotsMobile">
                                <FontAwesomeIcon icon={faEllipsisV} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="dropdown-menu-show">
                                <Dropdown.Item
                                  className="d-flex justify-content-start align-items-center"
                                  onClick={() => editModal(categories.id)}
                                >
                                  <img
                                    src="/Pencil.svg"
                                    alt="Pencil Icon"
                                    width={24}
                                    height={24}
                                    className="mr-1"
                                  />
                                  <span className="edit-text">Editar</span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="col-12">
                  <div className="row d-flex justify-content-between datatable-footer">
                    <div className="col-md-6 d-flex align-items-center">
                      <span className="mr-2">Resultados por Página:</span>
                      <div>
                        <select
                          name="SelecaoPorPagina"
                          value={perPage}
                          onChange={handleUpdatePerPage}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                      {perPage * currentPage >= totalPages ? (
                        <span>
                          {" "}
                          Exibindo {totalPages} de {totalPages}{" "}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          Exibindo {perPage * currentPage} de {totalPages}{" "}
                        </span>
                      )}

                      <button
                        onClick={() =>
                          categorizationList("Initial", 1, perPage)
                        }
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </button>
                      <button
                        onClick={() =>
                          categorizationList("left", currentPage, perPage)
                        }
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <span>
                        Página {currentPage} de {lastPage}
                      </span>

                      <button
                        onClick={() =>
                          categorizationList("right", currentPage, perPage)
                        }
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>

                      <button
                        onClick={() =>
                          categorizationList("right", lastPage, perPage)
                        }
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <ModalEventCategorization
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        item={changeItem}
        setReload={setReload}
        defaultValue={defaultValue}
        onClose={resetModal}
        categorizationId={categorizationId}
        categorizationName={categorizationName}
        isSubcategory={isSubcategory}
        route={changeItem === 0 ? 'categories' : 'subcategories'}
      />
    </Fragment>
  );
}

export default EventCategorization;
