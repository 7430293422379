import React, { Fragment, useState, useMemo, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Avatar from "react-avatar";
import "./styles.css";
import { AccountDetailsMenu } from "./components/accountDropdown";
import { useTranslation } from "react-i18next";

export function AccountDetails({ handleOpenQrCode }) {
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
  const { first_name, last_name, picture, email } = user || {};
  const fullName = `${first_name} ${last_name}`;
  const [open, setOpen] = useState(false);

  const { t } = useTranslation("components", {
    keyPrefix: "header",
  });

  const handleClickOutside = (event) => {
    if (!event.target.closest("#new-account-details")) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <button
        id="new-account-details"
        className="d-flex flex-row justify-content-center align-items-center"
        type="button"
        onClick={() => setOpen(!open)}
      >
        <div className="avatar-name-details d-flex flex-row align-items-center justify-content-center">
          <Avatar
            maxInitials={2}
            name={fullName}
            size="24"
            round
            src={picture}
          />
          <p className="d-md-flex d-sm-flex d-none hello-text-account">
            {t("hello")}, {first_name}
          </p>
        </div>

        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
      </button>

      {open && (
        <AccountDetailsMenu
          fullName={fullName}
          email={email}
          handleOpenQrCode={handleOpenQrCode}
        />
      )}
    </Fragment>
  );
}
