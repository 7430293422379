const sizes = {
	mobile: '321px',
	mobileL: '600px',
	tablet: '768px',
	sideBarClosed: '991px',
	desktop720p: '1280px',
	desktop1080p: '1920px',
	desktop4k: '3000px'
};

export const breakPoints = {
	mobile: `(min-width: ${sizes.mobile})`,
	mobileL: `(min-width: ${sizes.mobileL})`,
	tablet: `(min-width: ${sizes.tablet})`,
	sideBarClosed: `(min-width: ${sizes.sideBarClosed})`,
	desktop720p: `(min-width: ${sizes.desktop720p})`,
	desktop1080p: `(min-width: ${sizes.desktop1080p})`,
	desktop4k: `(min-width: ${sizes.desktop4k})`
};
