import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

ColorfulSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  customStyles: PropTypes.object,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  components: PropTypes.object,
};

export default function ColorfulSelect({
  id,
  options,
  value,
  onChange,
  customStyles,
  isDisabled,
  isSearchable,
  components,
}) {
  return (
    <Select
      id={id}
      isSearchable={isSearchable}
      styles={customStyles}
      options={options}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      components={components}
    />
  );
}
