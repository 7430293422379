import React from "react";

export default function Cadastroevento4() {
  return (
      <div className="finalizacao text-center">
        <p id="congratulations">Parabéns,</p>
        <div className="text-center">
          <p>
            Seu evento foi criado com sucesso e está pronto para ser publicado.
            <br></br>
            Deseja publicá-lo agora?
          </p>
        </div>
      </div>
  );
}
