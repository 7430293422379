import React, { Fragment, useState } from "react";
import RecycleBin from "../../assets/icons/RecycleBin.svg";
import "react-google-places-autocomplete/dist/assets/index.css";
import { Button } from "react-bootstrap";
import AddCircle from "../../assets/icons/addCircle.svg";
import { ToastContainer } from "react-toastr";
import ButtonDashed from "components/buttonDashed";
import AsyncCategoryInput from "components/asyncCategoryInput";
import PropTypes from "prop-types";

CreateCategorySelector.propTypes = {
  categories: PropTypes.array,
  callback: PropTypes.func,
  edit: PropTypes.bool,
};

export default function CreateCategorySelector(props) {
  const [newCategory, setNewCategory] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [newCategoryId, setNewCategoryId] = useState(null);
  const [openOnClick, setOpenOnClick] = useState(true);
  const [openSecondClick, setOpenSecondClick] = useState(true);

  // Arrays
  const [firstSubcategory, setFirstSubcategory] = useState([]);
  const [secondSubcategory, setSecondSubcategory] = useState([]);

  // Extra
  let container;

  const { categories, callback } = props;

  const categorySelect = (category_id, position) => {
    if (position === 1) {
      setCategoryId(category_id.value === categoryId?.value ? {...category_id } : category_id);
      setFirstSubcategory([])
    } else {
      setNewCategoryId(category_id.value === newCategoryId?.value ? {...category_id } : category_id);
      setSecondSubcategory([])
    }

    const filteredStepData = categories.map((el) => {
      if (el.position === position) {
        el.mainCategory = category_id.value;
        el.sub = [];
      }
      return el;
    });
    if (
      (newCategory && selectedCategories < 2) ||
      (!newCategory && selectedCategories === 0)
    ) {
      setSelectedCategories(selectedCategories + 1);
    }

    callback(filteredStepData);
  };

  const selectSubcategory = (subcategory, position) => {
    const subcategoriesValue = subcategory.map(
      (subcategory) => subcategory.value
    );

    if (position === 1) {
      setFirstSubcategory(subcategoriesValue);
    } else {
      setSecondSubcategory(subcategoriesValue);
    }

    if (firstSubcategory.length < 3) {
      setOpenOnClick(true);
    }

    if (secondSubcategory.length < 3) {
      setOpenSecondClick(true);
    }

    const filteredStepData = categories.map((el) => {
      if (el.position === position) {
        el.sub = subcategoriesValue;
      }
      return el;
    });

    callback(filteredStepData);
  };

  const removeCategory = () => {
    setNewCategory(false);
    if (selectedCategories > 0) {
      setSelectedCategories(1);
    }

    const categoriesUpdated = [
      { position: 1, mainCategory: categoryId?.value, sub: firstSubcategory },
      { position: 2, mainCategory: 0, sub: [] },
    ];

    setSecondSubcategory([]);
    setOpenSecondClick(true);
    setNewCategoryId(null);
    callback(categoriesUpdated);
  };

  const handleAddNewCategory = () => {
    if (!categoryId || firstSubcategory.length === 0) {
      container.error("Favor adicionar uma categoria com subcategoria antes de criar outra.");
      return false;
    }
    setNewCategory(true);
  };

  return (
    <Fragment>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label">Categoria</label>
          <AsyncCategoryInput
            callBack={(params) => categorySelect(params, 1)}
            otherCategory={newCategoryId}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="form-label">Subcategoria</label>
          <AsyncCategoryInput
            isMulti
            isSubcategory
            isDisabled={!categoryId}
            isClearable={false}
            onEventEditOrCreate
            openMenuOnClick={openOnClick}
            callBack={(params) => selectSubcategory(params, 1)}
            category={categoryId}
          />
        </div>

        {!newCategory ? (
          <div className="col">
            <ButtonDashed
              onClick={() => handleAddNewCategory()}
              svg={AddCircle}
              text="Adicionar nova categoria"
            />
          </div>
        ) : (
          <Fragment>
            <div className="form-group col-md-6">
              <label className="form-label">Categoria</label>
              <AsyncCategoryInput
                callBack={(params) => categorySelect(params, 2)}
                category={categoryId}
                otherCategory={categoryId}
              />
            </div>
            <div className="form-group col-md-5">
              <label className="form-label">Subcategoria</label>
              <AsyncCategoryInput
                isMulti
                isSubcategory
                isClearable={false}
                onEventEditOrCreate
                openMenuOnClick={openSecondClick}
                callBack={(params) => selectSubcategory(params, 2)}
                category={newCategoryId}
                isDisabled={!newCategoryId}
              />
            </div>
            <div className="form-group col-md-1">
              <label className="form-label remove">Remover</label>
              <Button
                variant="outline-primary trash-icon"
                block
                onClick={() => removeCategory()}
              >
                <img
                  src={RecycleBin}
                  alt="Delete category icon"
                  className="align-items-center justify-content-center recycle-bin"
                ></img>
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
