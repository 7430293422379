import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

FormField.propTypes = {
  label: PropTypes.node,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  controlId: PropTypes.string,
  classNameGroup: PropTypes.string,
  classNameLabel: PropTypes.string,
};

export default function FormField({
  label,
  errorMessage,
  children,
  controlId,
  classNameGroup,
  classNameLabel,
}) {
  return (
    <Form.Group className={classNameGroup} controlId={controlId}>
      <Form.Label className={classNameLabel}>{label}</Form.Label>
      {children}
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
