import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

CardWhite.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  containerStyle: PropTypes.object,
  isDashboard: PropTypes.bool
};

export default function CardWhite({
  name,
  title,
  value,
  subTitle,
  icon,
  containerStyle,
  isDashboard
}) {
  return (
    <div
      name={name}
      style={containerStyle}
      className="totalizer-transfer-card shadow-sm"
    >
      <p className={`totalizer-transfer-card-title ${!subTitle && 'text-center'}`} >
        {title}
        {icon}
      </p>
      <p className={`totalizer-transfer-card-value primary-color-value ${!subTitle && 'text-center'}`}>{value}</p>
      {subTitle}
    </div>
  );
}
