import styled from "styled-components";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  max-width: fit-content;
`;

export const DropdownContent = styled.div`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  right: 0;
  text-align: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const Item = styled.div`
  color: black;
  padding: 0.5rem 1rem;
	gap: 0.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
  }
  cursor: pointer;
  :hover {
    background-color: #f1f1f1;
  }
`;