import React from "react";
import "./styles.css";

const EmptyData = ({title, text}) => (
  <div id="empty-data" className="card-content text-center">
    <div>{title}</div>
    <div>{text}</div>
    <img src="../../empty-tray.png" alt=""/>
  </div>
)

export default EmptyData;