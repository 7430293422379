import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

IconInput.propTypes = {
  onSelect: PropTypes.func,
  icons: PropTypes.any,
  fieldId: PropTypes.number,
  props: PropTypes.any,
};

export default function IconInput({ onSelect, icons, defaultValue, options }) {
  const [selectedItem, setSelectedItem] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState();
  const [defaultIcon, setDefaultIcon] = useState();
  let optionsList = [];

  useEffect(() => {
    addingIconToOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (defaultValue) {
      const optionDefault = optionsList.filter(
        (option) => option.value === defaultValue
      );
      setSelectedItem(defaultValue ? optionDefault : "");
      setDefaultIcon(optionDefault[0].icon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = (value) => {
    setSelectedItem(value);
    onSelect(value);
  };

  const addingIconToOptions = (option) => {
    for (let index = 0; index < option.length; index++) {
      const types = option.map(({ type }) => type);
      const element = types[index];

      optionsList.push({
        label: option[index].description,
        value: option[index].id,
        icon: icons[element],
        type: option[index].type,
      });
    }
    setDefaultOptions(optionsList);
  };

  const Option = (props) => {
    return (
      <components.Option {...props} className="item-options">
        {!!props.data.icon && (
          <img src={props.data.icon} alt="icon" className="icon-options pr-2" />
        )}
        {props.data.label}
      </components.Option>
    );
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {!!selectedItem.icon && (
        <img
          src={selectedItem.icon || defaultIcon}
          alt="selected item icon"
          className="selected-icon pr-2"
        />
      )}
      {children}
    </components.SingleValue>
  );

  return (
    <Fragment>
      <Select
        placeholder="Insira o tipo do campo"
        value={selectedItem}
        options={defaultOptions}
        onChange={handleChange}
        styles={{
          singleValue: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
          }),
        }}
        components={{
          Option,
          SingleValue,
        }}
      />
    </Fragment>
  );
}
