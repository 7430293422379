/* eslint-disable*/
import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import Api from "../../../../services/api.js";
import Swal from "sweetalert2";
import axios from "axios";
import MaskedInput from "react-text-mask";
import "./styles.css";
import { ToastContainer } from "react-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import PaymentButtons from "components/paymentbuttons/index.js";
import moment from "moment";
import ImageFileUpload from "components/image-file-upload/index.js";
import { DatePicker } from "components/datepicker";
import NumberFormat from "react-number-format";
import { ImageHandlerService } from "services/image-handler";
import { slugify } from "mask";
import Loading from "components/loading/index.js";
import OrganizationsInput from "components/organizations-input/index.js";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings.js";

let container;
const user = JSON.parse(localStorage.getItem("user"));

// date Picker Config
const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const days = ["Dom", "Seg", "Ter", "Quar", "Qui", "Sex", "Sab"];

const locale = {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {},
};

const RegisterEstablishments = (props) => {
  const { currencyInputFormatter } = useLanguageSettings();
  const { t } = useTranslation();
  const linkEmpresa = process.env.REACT_APP_LINK_EMPRESA;
  const [categorias, setCategorias] = useState();
  const [establishmentId, setEstablishmentId] = useState();
  const [nome, setName] = useState("");
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setTelefone] = useState("");
  const [slug, setSlug] = useState("");
  const [thumbnail, setThumbnail] = useState();
  const [cnpj, setCNPJ] = useState("");
  const [category, setCategoria] = useState();
  const [description, setDescription] = useState("");
  const [shortdescription, setShortdescription] = useState("");
  const [zip_code, setZipcode] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setEstado] = useState("");
  const [sale_model, setSalemodel] = useState();
  const [payment_web, setPaymentweb] = useState(0);
  const [order_web, setOrderweb] = useState(0);
  const [cash_delivery, setCashdelivery] = useState(0);
  const [card_delivery, setCarddelivery] = useState(0);
  const [seller, setSeller] = useState("");
  const [printImage, setPrintImage] = useState();
  const [printTicketType, setPrintTicketType] = useState();
  const [enableQrCode, setEnableQrCode] = useState();
  const [interestRate, setInterestRate] = useState(2.5);
  const [maxInstallments, setMaxInstallments] = useState(1);
  const [printCashlessReceipt, setPrintCashlessReceipt] = useState();
  const [enableTip, setEnableTip] = useState();
  const [tax, setTax] = useState(0);

  const [posTypePayments, setPosTypePayments] = useState([]);
  const [posTypePaymentsUser, setPosTypePaymentsUser] = useState([]);
  const [posTypes, setPosTypes] = useState([]);
  const [onlineTypes, setOnlineTypes] = useState([]);
  const [insertPaymentUser, setInsertPaymentUser] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [organizationId, setOrganizationId] = useState("");
  const [card_value, setCardvalue] = useState(0);
  const [loadingThumb, setLoadingThumb] = useState(false);
  const [loadingPrintImage, setLoadingPrintImage] = useState(false);
  const [remoteThumb, setRemoteThumb] = useState();
  const [remotePrintImage, setRemotePrintImate] = useState();
  const [infoCard, setInfoCard] = useState([]);
  const [click, setClick] = useState(false);
  const [organizationName, setOrganizationName] = useState();
  const [activeCheck, SetActiveCheck] = useState({
    name: "",
    document: "",
    telephone: "",
  });

  // date Picker Config
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const days = ["Dom", "Seg", "Ter", "Quar", "Qui", "Sex", "Sab"];

  const locale = {
    localize: {
      month: (n) => months[n],
      day: (n) => days[n],
    },
    formatLong: {},
  };

  function errorMensage(message) {
    Swal.fire({
      type: "error",
      title: "Oops...",
      text: message,
    });
  }

  const getCep = () => {
    axios
      .get(`https://viacep.com.br/ws/${zip_code}/json`)
      .then((res) => {
        setNeighborhood(res.data.bairro);
        setCity(res.data.localidade);
        setEstado(res.data.uf);
        setStreet(res.data.logradouro);
      })
      .catch(() =>
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        })
      );
  };

  function registerEsta() {
    var form_data = new FormData();

    if (!nome) {
      errorMensage("Nome do estabelecimento é obrigatório.");
      return;
    }

    if (!slug) {
      errorMensage("Slug do estabelecimento é obrigatório.");
      return;
    }

    if (!type) {
      errorMensage("O tipo de estabelecimento é obrigatório.");
      return;
    }

    if (!category) {
      errorMensage("A categoria do estabelecimento é obrigatória.");
      return;
    }
    if (!organizationId) {
      errorMensage("A organização do estabelecimento é obrigatória.");
      return;
    }

    if (posTypes.length === 0) {
      errorMensage(
        "Escolha pelo menos uma forma de recebimento para o estabelecimento, é obrigatório!"
      );
      return;
    }

    form_data.append("nome", nome);
    form_data.append("type", type);
    form_data.append("email", email);
    form_data.append("phone", phone || "");
    form_data.append("slug", slug);
    form_data.append("document", cnpj);
    form_data.append("category", category);
    form_data.append("description", description || "");
    form_data.append("shortdescription", shortdescription || "");
    form_data.append("zip_code", zip_code || "");
    form_data.append("street", street || "");
    form_data.append("number", number || "");
    form_data.append("neighborhood", neighborhood || "");
    form_data.append("city", city || "");
    form_data.append("state", state || "");
    form_data.append("sale_model", sale_model);
    form_data.append("payment_web", payment_web);
    form_data.append("order_web", order_web);
    form_data.append("cash_delivery", cash_delivery);
    form_data.append("card_delivery", card_delivery);
    form_data.append("seller", seller || "");
    form_data.append("ab_print_ticket_type", printTicketType || "");
    form_data.append("ab_print_qrcode", enableQrCode);
    form_data.append("ab_print_cashless_receipt", printCashlessReceipt);
    form_data.append("ab_max_installments", maxInstallments);
    form_data.append("ab_interest_rate", (interestRate || 0) * 100);
    form_data.append("posTypes", posTypes);
    form_data.append("inputsCashless", infoCard);
    form_data.append("ab_enable_tips", enableTip);
    form_data.append("ab_tax", (tax || 0) * 100);

    if (thumbnail) {
      form_data.append("thumb", thumbnail);
    }

    if (printImage) {
      form_data.append("ab_print_image", printImage);
    }

    form_data.append(
      "card_value",
      card_value
        ? card_value.replace(".", "").replace(",", "").replace("R$", "") / 100
        : 0
    );
    form_data.append("organization_id", organizationId);
    form_data.append(
      "start_date",
      moment(startDate).format("YYYY-MM-DD HH:mm:ss")
    );

    if (establishmentId) {
      document.getElementById("loading").classList.remove("esconde");
      form_data.append("id", establishmentId);
      Api.post("establishment/edit", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          document.getElementById("loading").classList.add("esconde");
          if (res.data.message === "Slug em uso!") {
            container.error(res.data.message);
          } else {
            Swal.fire({
              title: "Sucesso",
              icon: "success",
              text: "Estabelecimento atualizado com sucesso!",
              confirmButtonText: "Ok",
            }).then(() => {
              window.location.href = "/establishments";
            });
          }
        })
        .catch(() => {
          document.getElementById("loading").classList.add("esconde");
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    } else {
      document.getElementById("loading").classList.remove("esconde");
      Api.post("establishment", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          document.getElementById("loading").classList.add("esconde");
          if (res.data.message === "Slug em uso!") {
            container.error(res.data.message);
          } else {
            Swal.fire({
              title: "Sucesso",
              icon: "success",
              text: "Estabelecimento criado com sucesso!",
              confirmButtonText: "Ok",
            }).then(() => {
              window.location.href = "/establishments";
            });
          }
        })
        .catch(() => {
          document.getElementById("loading").classList.add("esconde");
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  }

  useEffect(() => {
    carregaFormasPagamento();
    loadCategoriesAndOrganizations();
    if (props.match.params.id) {
      let id = props.match.params.id;
      setEstablishmentId(id);
      Api.get("edit/establishment/" + id)
        .then((res) => {
          if (res.data.estabelecimento) {
            let estabelecimento = res.data.estabelecimento.estabelecimento;
            let paymentsTypes = res.data.estabelecimento.paymentsTypes;
            if (
              res.data.estabelecimento.inputs &&
              res.data.estabelecimento.inputs.length > 0
            ) {
              let fields = res.data.estabelecimento.inputs.map(
                (item) => item.name
              );
              const nameCheck = fields.includes("Nome");
              const documentCheck = fields.includes("CPF");
              const telephoneCheck = fields.includes("Telefone");
              setInfoCard(fields);
              SetActiveCheck({
                name: nameCheck ? "active" : "",
                document: documentCheck ? "active" : "",
                telephone: telephoneCheck ? "active" : "",
              });
            }
            setName(estabelecimento.name);
            setEmail(estabelecimento.email);
            setType(estabelecimento.type);
            setSlug(estabelecimento.slug);
            setTelefone(estabelecimento.phone);
            setCNPJ(estabelecimento.document);
            setCategoria(estabelecimento.category?.category_id);
            setShortdescription(estabelecimento.short_description);
            setDescription(estabelecimento.description);
            setZipcode(estabelecimento.location?.zip_code);
            setNumber(estabelecimento.location?.number);
            setStreet(estabelecimento.location?.street);
            setCity(estabelecimento.location?.city);
            setEstado(estabelecimento.location?.state);
            setSalemodel(estabelecimento.configuration?.sale_model);
            setNeighborhood(estabelecimento.location?.neighborhood);
            setCarddelivery(estabelecimento.configuration?.card_delivery);
            setCashdelivery(estabelecimento.configuration?.cash_delivery);
            setPaymentweb(estabelecimento.configuration?.payment_web);
            setOrderweb(estabelecimento.configuration?.order_web);
            setSeller(estabelecimento.zoop_seller);
            setPrintTicketType(estabelecimento.ab_print_ticket_type);
            setEnableQrCode(estabelecimento.ab_print_qrcode);
            setPrintCashlessReceipt(estabelecimento.ab_print_cashless_receipt);
            setMaxInstallments(estabelecimento.ab_max_installments);
            setInterestRate((estabelecimento.ab_interest_rate || 0) / 100);
            setEnableTip(estabelecimento.ab_enable_tips);
            setTax((estabelecimento.ab_tax || 0) / 100);
            setCardvalue(
              estabelecimento.card_value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            );
            setThumbnail(estabelecimento.thumb);
            setPrintImage(estabelecimento.ab_print_image);
            setOrganizationId(estabelecimento.organization_id);
            setStartDate(new Date(estabelecimento.start_date));
            setPosTypes(paymentsTypes.map((item) => item.type_payment_id));

            if (estabelecimento.thumb) {
              setRemoteThumb(
                ImageHandlerService.getImageFromUrl(estabelecimento.thumb, {
                  format: "webp",
                  resize: { width: 100, fit: "cover" },
                })
              );
            }

            if (estabelecimento.ab_print_image) {
              setRemotePrintImate(
                ImageHandlerService.getImageFromUrl(
                  estabelecimento.ab_print_image,
                  {
                    format: "webp",
                    grayscale: true,
                    resize: { width: 100, fit: "cover" },
                  }
                )
              );
            }
            if (
              estabelecimento.organizations ||
              estabelecimento.organization_id
            ) {
              setOrganizationName({
                label: estabelecimento.organizations.name,
                value: estabelecimento.organization_id,
              });
            }
          } else {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Você não tem permissão para editar este estabelecimento!",
              confirmButtonText: "Ok",
            }).then(() => {
              window.location.pathname = "/establishments";
            });
          }
          document.getElementById("loading").classList.add("esconde");
          carregaFormasPagamento();
          loadCategoriesAndOrganizations();
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Você não tem permissão para editar este evento!",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.pathname = "/establishments";
          });
        })
        .finally(() =>
          document.getElementById("loading").classList.add("esconde")
        );
    } else {
      document.getElementById("loading").classList.add("esconde");
      setOrganizationName(null);
      setEnableQrCode(0);
      setEnableTip(0);
      setPrintCashlessReceipt(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "ticket") {
      setCardvalue(0);
    }
  }, [type]);

  const inserirFormaPagamentoUsurario = () => {
    setClick(true);
    if (insertPaymentUser.length < 2) {
      errorMensage(
        "Insira um nome com no mínimo 2 caracteres para a forma de pagamento!"
      );
      setClick(false);
      return;
    }
    let newPayUser = {
      name: insertPaymentUser,
      cost: null,
      mode: "aeb",
      raw_method: "cash",
      user_id: user.id,
      event_id: establishmentId,
    };

    Api.post("type_payments/insertUserAndAdmin", newPayUser)
      .then((res) => {
        setClick(false);
        container.success(res.data.message);
        setInsertPaymentUser("");
        carregaFormasPagamento();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const deletePaymentUser = (idPayment) => {
    Swal.fire({
      title: "Você tem certeza que deseja excluir?",
      text: "Caso a forma de pagamento já esteja em uso, não será possível excluir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        Api.post("type_payments/delete/" + idPayment)
          .then((res) => {
            container.success(res.data.message);
            carregaFormasPagamento();
          })
          .catch((err) => {
            container.error(err.response.data.message);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const carregaFormasPagamento = () => {
    Api.get("type_payments").then((res) => {
      setPosTypePayments(
        res.data.filter(
          (item) =>
            item.mode === "common" || item.mode === "aeb" || item.mode === "pos"
        )
      );
    });

    const idestablishment = props.match.params.id;
    Api.get("type_payments/showUserAdmin/" + idestablishment)
      .then((res) => {
        setPosTypePaymentsUser(res.data.filter((item) => item.user_id));
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };
  const handlePaymentType = (typeId, mode) => {
    let items = mode === "online" ? onlineTypes : posTypes;
    const index = items.findIndex((id) => typeId === id);

    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.push(typeId);
    }

    if (mode === "online") {
      setOnlineTypes([...items]);
    } else {
      setPosTypes([...items]);
    }
  };

  const selectedInfosCard = (type) => {
    const arrayTypes = infoCard;
    const index = arrayTypes.find((item) => type === item);
    if (index) {
      arrayTypes.splice(arrayTypes.indexOf(type), 1);
      setInfoCard(arrayTypes);
    } else {
      arrayTypes.push(type);
      setInfoCard(arrayTypes);
    }
  };

  const loadCategoriesAndOrganizations = () => {
    Api.get(`users/events/categories-and-organizations`)
      .then((res) => {
        let { categories } = res.data;
        setCategorias(categories);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const updateCurrency = (e) => {
    setCardvalue(currencyInputFormatter(e.target.value));
  };

  const handleUploadFile = (fileData, fieldSetter, fieldLoaderSetter) => {
    fieldLoaderSetter(true);

    let formData = new FormData();
    formData.append("image", fileData);

    Api.post("uploads?local=estabelecimentos", formData, {
      headers: { "content-type": "multipart/form-data" },
    })
      .then((res) => fieldSetter(res.data.url))
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => fieldLoaderSetter(false));
  };

  const organizationSelected = (organization_id) => {
    setOrganizationId(organization_id || organizationId);
  };

  const checkSlug = (receivedSlug) => {
    Api.get(`check/event/slug/${receivedSlug}`)
      .then(() => {})
      .catch(() => {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Já existe um estabelecimento com este nome, por favor tente outro.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      <PageHeader
        title="Estabelecimentos"
        text={
          establishmentId
            ? "Editar estabelecimento"
            : "Cadastrar novo estabelecimento"
        }
      />
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <Loading title="aguarde..." />
      <div
        id="register-establishment"
        className="container-fluid mt-4"
        style={{ paddingBottom: "60px" }}
      >
        <div className="col-12">
          <div className="card">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">1. Dados básicos</h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Nome*</label>
                  <input
                    type="text"
                    placeholder="Nome do estabelecimento"
                    className="form-control"
                    name="name"
                    value={nome || ""}
                    onBlur={() => checkSlug(slug)}
                    onChange={(event) => setName(event.target.value)}
                    onKeyUp={(e) => setSlug(slugify(e.target.value))}
                  />
                </div>
                <div className="col-md-6">
                  <label>Slug (link)</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text inputs text-small"
                        id="inputTags"
                      >
                        {linkEmpresa}
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Link"
                      disabled
                      className="form-control"
                      name="slug"
                      value={slug || ""}
                      onChange={(event) => setSlug(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Categoria*</label>
                  <select
                    name="category"
                    value={category || ""}
                    className="form-control"
                    onChange={(event) => setCategoria(event.target.value)}
                  >
                    <option value="">Selecione</option>
                    {categorias?.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <DatePicker
                    label={t("components:datepicker.startDate")}
                    value={startDate}
                    showTimeSelect
                    minDate={new Date()}
                    onChange={(value) => setStartDate(value)}
                    placeholderText={t("components:datepicker.startDate")}
                    leftIcon
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label>E-mail</label>
                  <input
                    type="email"
                    placeholder="E-mail@exemplo.com.br"
                    className="form-control"
                    name="email"
                    value={email || ""}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <label>CNPJ do estab.</label>

                  <MaskedInput
                    value={cnpj}
                    placeholder="00.000.000/0000-00"
                    className="form-control"
                    name="cnpj"
                    id="cnpj"
                    onChange={(e) => setCNPJ(e.target.value)}
                    guide={false}
                    mask={[
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                    ]}
                  />
                </div>
                <div className="col-md-3">
                  <label>Número do Whatsapp</label>
                  <input
                    type="text"
                    placeholder="(00)0000-0000"
                    className="form-control"
                    name="phone"
                    value={phone || ""}
                    onChange={(event) => setTelefone(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <label>Descrição curta</label>
                  <input
                    type="text"
                    placeholder="Descrição curta"
                    className="form-control"
                    name="desc1"
                    value={shortdescription || ""}
                    onChange={(event) =>
                      setShortdescription(event.target.value)
                    }
                  />
                </div>
                <div className="col-md-6">
                  <ImageFileUpload
                    id="image"
                    image={remoteThumb}
                    label="Logomarca"
                    loading={loadingThumb}
                    onChange={(image, base64, file) => {
                      handleUploadFile(file, setThumbnail, setLoadingThumb);
                    }}
                    placeholder="Selecione uma imagem"
                  />
                </div>
                <div className="col-md-12">
                  <label>Descrição</label>
                  <textarea
                    className="form-control"
                    name="desc2"
                    value={description || ""}
                    onChange={(event) => setDescription(event.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">2. Endereço</h4>
            </div>
            <div className="card-body">
              <div className="row mt-2">
                <div className="col-md-3">
                  <label>Cep</label>
                  <MaskedInput
                    name="cep"
                    placeholder="00000-000"
                    className="form-control"
                    value={zip_code}
                    guide={false}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                    onChange={(e) => setZipcode(e.target.value)}
                    onBlur={getCep}
                  />
                </div>
                <div className="col-md-3">
                  <label>Número</label>
                  <input
                    type="text"
                    name="number"
                    className="form-control"
                    value={number || ""}
                    onChange={(event) => setNumber(event.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <label>Cidade</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={city || ""}
                    onChange={(event) => setCity(event.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <label>Estado</label>
                  <input
                    type="text"
                    name="state"
                    className="form-control"
                    value={state || ""}
                    onChange={(event) => setEstado(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Rua/ Logradouro / Avenida</label>
                  <input
                    type="text"
                    name="street"
                    className="form-control"
                    value={street || ""}
                    onChange={(event) => setStreet(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              <h4 className="card-header-title">
                3. Formas de pagamento*
                <span className="tips">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <div className="tips-content">
                    Formas de pagamento padrão.
                  </div>
                </span>
              </h4>
            </div>

            <div className="card-body">
              <div className="custom-input custom-checkbox row mb-2">
                {posTypePayments.map((item, i) => (
                  <div
                    key={item.id}
                    className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2"
                  >
                    <div
                      className={
                        posTypes.includes(item.id)
                          ? "btnClass active"
                          : "btnClass"
                      }
                      onClick={() => handlePaymentType(item.id, "pos")}
                    >
                      <PaymentButtons
                        method={item.raw_method}
                        name={item.name}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <hr></hr>
              <h4 className="card-header-title">
                Formas de pagamento personalizadas
              </h4>

              <div className="custom-input custom-checkbox row mt-4 mb-2">
                {posTypePaymentsUser.map((item) => (
                  <div
                    key={item.id}
                    className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2 btnGroup"
                  >
                    <div
                      className={
                        posTypes.includes(item.id)
                          ? "btnClass active"
                          : "btnClass"
                      }
                      onClick={() => handlePaymentType(item.id, "pos")}
                    >
                      <PaymentButtons
                        method={item.raw_method}
                        name={item.name}
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="payment-delete"
                      onClick={() => deletePaymentUser(item.id)}
                    />
                  </div>
                ))}
              </div>

              <hr />
              <h4 className="card-header-title">
                Cadastrar forma de pagamento personalizada
              </h4>

              <div className="row mt-4">
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    placeholder="Insira o nome da sua forma de pagamento"
                    className="form-control"
                    name="UserPay"
                    value={insertPaymentUser || ""}
                    onChange={(event) =>
                      setInsertPaymentUser(event.target.value)
                    }
                    disabled={click}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inserirFormaPagamentoUsurario();
                      }
                    }}
                  />
                </div>

                <div className="col-md-4 mb-2">
                  <button
                    disabled={click}
                    className="btn btn-secondary"
                    onClick={inserirFormaPagamentoUsurario}
                  >
                    cadastrar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">4. Configurações A/B</h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Tipo*</label>
                  <select
                    required
                    className="form-control"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Escolha tipo</option>
                    <option value="ticket">Ticket</option>
                    <option value="cashless-pre">Cashless Pré-Pago</option>
                    <option value="cashless-pos">Cashless Pós-Pago</option>
                  </select>
                </div>

                <div className="col-md-6 mb-2">
                  <ImageFileUpload
                    id="printImage"
                    image={remotePrintImage}
                    label="Logo de impressão (preto e branco)"
                    loading={loadingPrintImage}
                    onChange={(image, base64, file) => {
                      handleUploadFile(
                        file,
                        setPrintImage,
                        setLoadingPrintImage
                      );
                    }}
                    placeholder="Selecione uma imagem"
                  />
                  <span className="upload-desc text-small">
                    A dimensão recomendada é de 240 x 240
                  </span>
                </div>
              </div>
              {type === "" ? (
                ""
              ) : type === "ticket" ? (
                <div className="col-12">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>Imprimir QRCode no ticket?</label>
                      {enableQrCode !== undefined && (
                        <div className="custom-control custom-switch col-md-6">
                          <input
                            id="enableQrCode"
                            type="checkbox"
                            className="custom-control-input"
                            name="enableQrCode"
                            defaultChecked={enableQrCode}
                            onChange={() =>
                              setEnableQrCode(enableQrCode ? 0 : 1)
                            }
                          ></input>
                          <label
                            className="custom-control-label"
                            htmlFor="enableQrCode"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Tipo de impressão do ticket</label>
                      <select
                        required={type === "ticket"}
                        className="form-control"
                        value={printTicketType || ""}
                        onChange={(e) => setPrintTicketType(e.target.value)}
                      >
                        <option value="">Escolha tipo de impressão</option>
                        <option value="simple">Simples</option>
                        <option value="grouped">Agrupada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>Habilitar gorjeta?</label>
                      {enableTip !== undefined && (
                        <div className="custom-control custom-switch col-md-6">
                          <input
                            id="enableTip"
                            type="checkbox"
                            className="custom-control-input"
                            name="enableTip"
                            defaultChecked={enableTip}
                            onChange={() => setEnableTip(enableTip ? 0 : 1)}
                          ></input>
                          <label
                            className="custom-control-label"
                            htmlFor="enableTip"
                          ></label>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Imposto sobre o pedido</label>

                      <NumberFormat
                        suffix="%"
                        decimalScale={1}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        value={tax}
                        className="form-control"
                        onValueChange={({ floatValue }) => setTax(floatValue)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Imprimir recibo cashless?</label>
                        {printCashlessReceipt !== undefined && (
                          <div className="custom-control custom-switch">
                            <input
                              id="printCashlessReceipt"
                              type="checkbox"
                              className="custom-control-input"
                              name="printCashlessReceipt"
                              defaultChecked={printCashlessReceipt}
                              onChange={() =>
                                setPrintCashlessReceipt(
                                  printCashlessReceipt ? 0 : 1
                                )
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="printCashlessReceipt"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <span>Informações solicitadas no cartão</span>
                        <div className="row mb-2">
                          <div
                            className={`col mt-1 btnClass ${activeCheck.name}`}
                            onClick={() => {
                              selectedInfosCard("Nome");
                              if (activeCheck.name === "active") {
                                SetActiveCheck({
                                  ...activeCheck,
                                  name: "",
                                });
                              } else {
                                SetActiveCheck({
                                  ...activeCheck,
                                  name: "active",
                                });
                              }
                            }}
                          >
                            <PaymentButtons method="Nome" name="Nome" />
                          </div>
                          <div
                            className={`col mt-1 btnClass ${activeCheck.document}`}
                            onClick={() => {
                              selectedInfosCard("CPF");
                              if (activeCheck.document === "active") {
                                SetActiveCheck({
                                  ...activeCheck,
                                  document: "",
                                });
                              } else {
                                SetActiveCheck({
                                  ...activeCheck,
                                  document: "active",
                                });
                              }
                            }}
                          >
                            <PaymentButtons method="CPF" name="CPF" />
                          </div>
                          <div
                            className={`col mt-1 btnClass ${activeCheck.telephone}`}
                            onClick={() => {
                              selectedInfosCard("Telefone");
                              if (activeCheck.telephone === "active") {
                                SetActiveCheck({
                                  ...activeCheck,
                                  telephone: "",
                                });
                              } else {
                                SetActiveCheck({
                                  ...activeCheck,
                                  telephone: "active",
                                });
                              }
                            }}
                          >
                            <PaymentButtons method="Telefone" name="Telefone" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Custo Cartão Cashless</label>
                    <input
                      value={card_value || ""}
                      name="cost"
                      placeholder="R$ 00.00"
                      className="form-control"
                      onChange={(e) => updateCurrency(e)}
                    />
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="form-group col-md-4">
                  <label>Organização</label>
                  <OrganizationsInput
                    defaultValue={organizationName}
                    callBack={organizationSelected}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label>Parcelamento máximo (cartão de crédito)</label>
                  <NumberFormat
                    allowNegative={false}
                    value={maxInstallments}
                    className="form-control"
                    onValueChange={({ floatValue }) =>
                      setMaxInstallments(floatValue)
                    }
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label>Taxa de juros do parcelamento (a.m.)</label>
                  <NumberFormat
                    suffix="%"
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    value={interestRate}
                    className="form-control"
                    onValueChange={({ floatValue }) =>
                      setInterestRate(floatValue)
                    }
                    disabled={user.is_admin ? false : true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-button">
          <button
            className="btn btn-primary col-md-3 c3"
            onClick={registerEsta}
          >
            {establishmentId ? "Salvar" : "Cadastrar"}
          </button>
        </div>
      </div>
    </>
  );
};

export default RegisterEstablishments;
