import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import PageHeader from 'components/page-header';
import Swal from 'sweetalert2';
import Card from 'components/card';
import ContainerFluid from 'components/containers/container-fluid';
import Input from 'components/input';
import DivFormGroup from 'components/div-form-group';
import DataTip from 'components/data-tip';
import CustomButton from 'components/custom-button';
import InputCheckbox from 'components/input-checkbox';
import EventAutocomplte from 'components/inputeventautocomplete';
import api from 'services/api';
import './styles.css';

export default function RegisterPDV() {
	const isTrue = true;
	const [inputValue, setInputValue] = useState('');
	// eslint-disable-next-line
	const urlParams = new URLSearchParams(window.location.search);
	// eslint-disable-next-line
	const pdv_id = urlParams.get('pdv_id');
	const [disabled, setDisabled] = useState({
		submitButton: false,
		userInput: true
	});
	// eslint-disable-next-line
	const [events_names, setEventNames] = useState([]);
	// eslint-disable-next-line
	const [user_attributes, setUser_attributes] = useState({
		email: '',
		first_name: '',
		last_name: ''
	});
	const [errorInput, setErrorInput] = useState({
		email: '',
		name: ''
	});
	const [value, setValue] = useState({
		name: '',
		user_id: '',
		ticket_sale_channel_id: 2,
		type: 'generic',
		mode: 'ticket',
		enabled: true,
		event_ids: []
	});

	const changePdvMode = (mode) => {
		setValue({
			...value,
			mode
		});
	};

	const changePdvType = (type) => {
		setValue({
			...value,
			ticket_sale_channel_id: type
		});
	};

	const searchByEvent = (event) => {
		if (event && !value.event_ids.includes(event.id)) {
			const arrayIds = value.event_ids;
			// eslint-disable-next-line
			const arrayNames = events_names;
			arrayIds.push(event.id);
			arrayNames.push(event);
			setValue({
				...value,
				eventName: event.name,
				event_ids: arrayIds
			});
			setEventNames(arrayNames);
			setInputValue('');
		}
	};

	const removeEventArray = (key) => {
		const arrayIds = value.event_ids;
		// eslint-disable-next-line
		const arrayNames = events_names;
		arrayIds.splice(key, key !== 0 ? key : 1);
		arrayNames.splice(key, key !== 0 ? key : 1);
		setValue({
			...value,
			event_ids: arrayIds
		});
		setEventNames(arrayNames);
	};

	const verifyEmail = (checkValue) => {
		// eslint-disable-next-line
		api.get(`/verify/user/${user_attributes.email}`)
			.then((res) => {
				if (res.data) {
					setValue({
						...value,
						user_id: res.data.id
					});
					setUser_attributes({
						// eslint-disable-next-line
						...user_attributes,
						first_name: res.data.first_name,
						last_name: res.data.last_name
					});
					setDisabled({ ...disabled, userInput: true });
				} else {
					setUser_attributes({
						// eslint-disable-next-line
						...user_attributes,
						first_name: '',
						last_name: ''
					});
					setDisabled({ ...disabled, userInput: false });
				}
			})
			.catch(() => {
				setDisabled(false);
				// eslint-disable-next-line
					Swal.fire({
					  title: 'Ops!',
					  icon: 'error',
					  text: 'Ocorreu um erro interno, por favor tente novamente',
					  confirmButtonText: 'Ok'
					})
				if (!user_attributes.email) {
					setErrorInput({ ...errorInput, [checkValue.target.name]: 'error' });
					setDisabled({ ...disabled, userInput: false });
				} else {
					setDisabled({ ...disabled, userInput: false });
				}
			});
	};

	const actions = () => {
		// eslint-disable-next-line
		if (!value.name) {
			Swal.fire({
				text: 'Atencão! Nome do PDV é obrigatório!',
				icon: 'warning',
				confirmButtonText: 'Ok'
			});
			// eslint-disable-next-line
		} else if (!user_attributes.email) {
			Swal.fire({
				text: 'Atencão! Nome do usuário é obrigatório!',
				icon: 'warning',
				confirmButtonText: 'Ok'
			});
		} else {
			setDisabled({ ...disabled, submitButton: true });
			// eslint-disable-next-line
			if (pdv_id) {
				// eslint-disable-next-line
				api.put(`/pdvs/${pdv_id}`, {
					name: value.name,
					user_id: value.user_id,
					type: value.type,
					mode: value.mode,
					ticket_sale_channel_id: value.ticket_sale_channel_id,
					enabled: value.enabled,
					event_ids: value.event_ids,
					user_attributes: {
						// eslint-disable-next-line
						email: user_attributes.email,
						// eslint-disable-next-line
						first_name: user_attributes.first_name,
						// eslint-disable-next-line
						last_name: user_attributes.last_name
					}
				})
					.then(() => {
						Swal.fire({
							text: 'PDV atualizado com sucesso!',
							icon: 'success',
							confirmButtonText: 'Ok'
						}).then(() => {
							// eslint-disable-next-line
							window.location.pathname = '/PdvList';
						}).catch(() => {
							Swal.fire({
							  title: 'Ops!',
							  icon: 'error',
							  text: 'Ocorreu um erro interno, por favor tente novamente',
							  confirmButtonText: 'Ok'
							})
						  });
					})
					.catch(() => {
						Swal.fire({
							text: 'Ocorreu um problema, tente novamente!',
							icon: 'error',
							confirmButtonText: 'Ok'
						}).then(() => {
							// eslint-disable-next-line
							window.location.pathname = '/PdvList';
						});
					});
			} else {
				setDisabled({ ...disabled, submitButton: true });
				api.post('/pdvs', {
					name: value.name,
					user_id: value.user_id,
					type: value.type,
					mode: value.mode,
					ticket_sale_channel_id: value.ticket_sale_channel_id,
					enabled: value.enabled,
					event_ids: value.event_ids,
					user_attributes: {
						// eslint-disable-next-line
						email: user_attributes.email,
						// eslint-disable-next-line
						first_name: user_attributes.first_name,
						// eslint-disable-next-line
						last_name: user_attributes.last_name
					}
				})
					.then(() => {
						Swal.fire({
							text: 'PDV criado com sucesso!',
							icon: 'success',
							confirmButtonText: 'Ok'
						}).then(() => {
							// eslint-disable-next-line
							window.location.pathname = '/PdvList';
						}).catch(() => {
							Swal.fire({
							  title: 'Ops!',
							  icon: 'error',
							  text: 'Ocorreu um erro interno, por favor tente novamente',
							  confirmButtonText: 'Ok'
							})
						  });
					})
					.catch(() => {
						Swal.fire({
							text: 'Ocorreu um problema, tente novamente!',
							icon: 'error',
							confirmButtonText: 'Ok'
						}).then(() => {
							// eslint-disable-next-line
							window.location.pathname = '/PdvList';
						}).catch(() => {
							Swal.fire({
							  title: 'Ops!',
							  icon: 'error',
							  text: 'Ocorreu um erro interno, por favor tente novamente',
							  confirmButtonText: 'Ok'
							})
						  });
					});
			}
		}
	};

	function checkInput(checkValue) {
		if (!checkValue.target.value) {
			setErrorInput({ ...errorInput, [checkValue.target.name]: 'error' });
		} else {
			setErrorInput('');
		}
	}
	function inputFocus(checkFocus) {
		setErrorInput({
			...errorInput,
			[checkFocus.target.name]: ''
		});
	}

	useEffect(() => {
		// eslint-disable-next-line
		if (pdv_id) {
			// eslint-disable-next-line
			api.get(`/pdvs/${pdv_id}`).then((res) => {
				// eslint-disable-next-line
				const event_ids = res.data.events.map((data) => data.id);
				setEventNames(res.data.events);
				setValue({
					...value,
					name: res.data.name,
					ticket_sale_channel_id: res.data.ticket_sale_channel_id,
					type: res.data.type,
					mode: res.data.mode,
					user_id: res.data.user.id,
					enabled: res.data.enabled,
					// eslint-disable-next-line
					event_ids
				});
				setUser_attributes({
					email: res.data.user.email,
					first_name: res.data.user.first_name,
					last_name: res.data.user.last_name
				});
			}).catch(() => {
				Swal.fire({
				  title: 'Ops!',
				  icon: 'error',
				  text: 'Ocorreu um erro interno, por favor tente novamente',
				  confirmButtonText: 'Ok'
				})
			  });
		}
		// eslint-disable-next-line
	}, [pdv_id]);

	return (
		<>
			<PageHeader title="Dashboard" text="" />
			<ContainerFluid containerId="pdvs-manager">
				<Card header="1. Configurações do ponto de venda">
					<div className="row">
						<DivFormGroup adjutsCss="col-md-6 col-12" label="Nome do ponto de venda*">
							<Input
								type="text"
								placeholder="digite aqui o nome do ponto de venda..."
								name="name"
								value={value.name}
								error={errorInput.name}
								onFocus={(e) => inputFocus(e)}
								onBlur={(e) => checkInput(e)}
								onChange={(e) => {
									setValue({ ...value, [e.target.name]: e.target.value });
								}}
								className="form-control mt-2 col-12"
							/>
						</DivFormGroup>
						<Input
							classNameInput="custom-control-input"
							classNameDivInput="custom-control custom-switch mt-3"
							classNameLabel="custom-control-label"
							divInputClass="form-group col-md-6"
							label="Ponto de venda ativo?"
							custom="switch-toggle"
							id="pdvActiveCheck"
							type="checkbox"
							name="pdvActiveCheck"
							checkBoxValue={value.enabled}
							defaultChecked={value.enabled}
							onChange={() => {
								if (!value.enabled) {
									setValue({
										...value,
										enabled: true
									});
								} else {
									setValue({
										...value,
										enabled: false
									});
								}
							}}
						>
							<label htmlFor="enabled">
								o ponto de venda está
								<b>
									<u>{value.enabled ? ' ativo' : ' desativado'}</u>
								</b>
							</label>
						</Input>
					</div>
					<hr />
					<div className="row mb-2">
						<DivFormGroup adjutsCss="col-md-6" label="Modo de exibição">
							<DataTip
								tipContent="Configure os modos de visualização disponíveis para esse
                      PDV, você pode escolher apenas um."
							/>
							<div className="row">
								<InputCheckbox
									active={value.mode === 'ticket' ? true : null}
									name="Ingresso"
									value="ticket"
									onClick={changePdvMode}
									className="col-md-4 mb-2"
								/>
								<InputCheckbox
									active={value.mode === 'aeb' ? true : null}
									name="A&B"
									value="aeb"
									onClick={changePdvMode}
									className="col-md-4 mb-2"
								/>
								<InputCheckbox
									active={value.mode === 'all' ? true : null}
									name="Ingresso + A&B"
									value="all"
									onClick={changePdvMode}
									className="col-md-4 mb-2"
								/>
							</div>
						</DivFormGroup>
						<DivFormGroup adjutsCss="col-md-6" label="Tipo">
							<DataTip tipContent="Configure aqui o tipo de PDV, você pode escolher apenas um." />
							<div className="row">
								<InputCheckbox
									active={value.ticket_sale_channel_id === 2 ? true : null}
									name="PDV Tiqueteira"
									value={2}
									onClick={changePdvType}
									buttonType={false}
									className="col-md-4 mb-2"
								/>
								<InputCheckbox
									active={value.ticket_sale_channel_id === 3 ? true : null}
									name="PDV Produtor"
									value={3}
									buttonType={false}
									onClick={changePdvType}
									className="col-md-4 mb-2"
								/>
								<InputCheckbox
									active={value.ticket_sale_channel_id === 4 ? true : null}
									name="Bilheteria"
									value={4}
									buttonType={false}
									onClick={changePdvType}
									className="col-md-4 mb-2"
								/>
							</div>
						</DivFormGroup>
					</div>
				</Card>
				<Card
					header="2. Usuário do ponto de venda"
					existsTitleBody={isTrue}
					titleBody="Adicionar novo usuário"
				>
					<div className="row">
						<DivFormGroup adjutsCss="col-md-4" label="E-mail*" classNameLabel="mt-2">
							<Input
								type="text"
								placeholder="digite o e-mail do usuário"
								name="email"
								error={errorInput.email}
								onFocus={inputFocus}
								onBlur={verifyEmail}
								// eslint-disable-next-line
								value={user_attributes.email}
								onChange={(e) => {
									setUser_attributes({
										// eslint-disable-next-line
										...user_attributes,
										[e.target.name]: e.target.value
									});
								}}
								className="form-control col-12"
							/>
						</DivFormGroup>
						<DivFormGroup adjutsCss="col-md-4" label="Nome" classNameLabel="mt-2">
							<Input
								type="text"
								name="first_name"
								disabled={disabled.userInput}
								// eslint-disable-next-line
								value={user_attributes.first_name}
								onChange={(e) => {
									setUser_attributes({
										// eslint-disable-next-line
										...user_attributes,
										[e.target.name]: e.target.value
									});
								}}
								className="form-control col-12"
							/>
						</DivFormGroup>
						<DivFormGroup adjutsCss="col-md-4" label="Sobrenome" classNameLabel="mt-2">
							<Input
								type="text"
								divInputClass="form-group "
								name="last_name"
								disabled={disabled.userInput}
								// eslint-disable-next-line
								value={user_attributes.last_name}
								onChange={(e) => {
									setUser_attributes({
										// eslint-disable-next-line
										...user_attributes,
										[e.target.name]: e.target.value
									});
								}}
								className="form-control col-12"
							/>
						</DivFormGroup>
					</div>
				</Card>
				<Card
					header="3. Eventos atrelados"
					existsTitleBody={isTrue}
					titleBody="Adicionar eventos"
				>
					<div className="mt-2">
						<EventAutocomplte
							value={inputValue}
							onChange={setInputValue}
							onSelect={searchByEvent}
						/>
					</div>
					{
						// eslint-disable-next-line
						events_names.length > 0 && <hr />
					}
					{
						// eslint-disable-next-line
						events_names.length > 0 && (
							<DivFormGroup
								adjutsCss="col-md-6"
								label="Lista de eventos atuais (disponíveis para venda)"
							>
								{
									// eslint-disable-next-line
									events_names.map((events, i) => (
										<div key={events.name} className="row">
											<DivFormGroup adjutsCss="col-md-11 col-10">
												<Input
													type="text"
													disabled={isTrue}
													value={events.name}
													className="form-control col-12"
												/>
											</DivFormGroup>
											<DivFormGroup adjutsCss="button-group col-2 col-md-1">
												<CustomButton
													className="button-delete"
													propskey={i}
													onClick={() => removeEventArray(i)}
												>
													<FontAwesomeIcon icon={faTimesCircle} />
												</CustomButton>
											</DivFormGroup>
										</div>
									))
								}
							</DivFormGroup>
						)
					}
				</Card>
				<DivFormGroup adjutsCss="button-group offset-md-9 mb-4">
					<CustomButton
						className="btn btn-primary"
						disabled={disabled.submitButton}
						onClick={actions}
						// eslint-disable-next-line
						text={pdv_id ? 'Atualizar PDV' : 'Criar PDV'}
					/>
				</DivFormGroup>
			</ContainerFluid>
		</>
	);
}
