///SEQUENCIA DE CORES DA PALETA PARA UTILIZAR NO DASH.

let colors = [];
colors.push("rgba(0, 165, 227, 1)");
colors.push("rgba(0, 131, 197, 1)");
colors.push("rgba(0, 97, 170, 1)");
colors.push("rgba(33, 64, 154, 1)");
colors.push("rgba(65, 64, 153, 1)");
colors.push("rgba(91, 67, 154, 1)");
colors.push("rgba(124, 85, 163, 1)");
colors.push("rgba(179, 105, 171, 1)");
colors.push("rgba(207, 75, 155, 1)");
colors.push("rgba(236, 0, 140, 1)");
colors.push("rgba(143, 68, 220, 1)");
colors.push("rgba(68, 86, 154, 1)");
colors.push("rgba(63, 37, 168, 1)");
colors.push("rgba(216, 226, 231, 1)");
colors.push("rgba(186, 202, 211, 1)");
// colors.push("rgba(91, 67, 154, 1)");
// colors.push("rgba(124, 85, 163, 1)");
// colors.push("rgba(179, 105, 171, 1)");
// colors.push("rgba(207, 75, 155, 1)");
// colors.push("rgba(236, 0, 140, 1)");

export function colorPicker(size) {
  let colorReturn = [];
  let value = 0;
  for (let index = 0; index < size; index++) {
    colorReturn.push(colors[value]);
    value++;
    if(value === colors.length) {
      value = 0;
    }
  }
  return colorReturn;
}
