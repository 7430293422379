import EmptyData from "components/empty-data";
import Loading from "components/loading";
import PageHeader from "components/page-header";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input";
import DataTable, { createTheme } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import SelectSearch from "react-select-search";
import { ToastContainer } from "react-toastr";
import api from "services/api";
import Swal from "sweetalert2";

let container;

const EventStockTrans = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("type");
  const menu_id = urlParams.get("menu_id");
  const event_id = urlParams.get("event_id");
  const history = useHistory();

  const [itens, setItens] = useState([]);
  const [filter, setFilter] = useState("");
  const [stocks, setStocks] = useState([]);
  let columns = [
    {
      name: "Nome do Produto",
      selector: "name",
      sortable: true,
    },
    {
      name: "Quantidade",
      cell: (row, index) => (
        <input
          type="number"
          className="table-input"
          value={row.quantity}
          id={`input-quantity-row-${index}`}
          onChange={(e) => {
            row.quantity = Number(e.target.value);
            setItens([...itens]);
            setTimeout(() => {
              document.getElementById(`input-quantity-row-${index}`).focus();
            }, 0);
          }}
        />
      ),
      sortable: true,
      maxWidth: "200px",
    },
    {
      name: "Custo",
      sortable: true,
      cell: (row, index) => (
        <CurrencyInput
          value={row.cost}
          name="custo"
          className="table-input"
          prefix="R$ "
          id={`input-cost-row-${index}`}
          onChangeEvent={(e, maskedvalue, floatvalue) => {
            row.cost = floatvalue;
            setItens([...itens]);
            setTimeout(() => {
              document.getElementById(`input-cost-row-${index}`).focus();
            }, 0);
          }}
        />
      ),
      maxWidth: "200px",
    },
  ];

  if (type === "3") {
    columns = [
      {
        name: "Nome do Produto",
        selector: "name",
        sortable: true,
      },
      {
        name: "Quantidade",
        cell: (row, index) => (
          <input
            type="number"
            className="table-input"
            value={row.quantity}
            id={`input-quantity-row-${index}`}
            onChange={(e) => {
              row.quantity = Number(e.target.value);
              setItens([...itens]);
              setTimeout(() => {
                document.getElementById(`input-quantity-row-${index}`).focus();
              }, 0);
            }}
          />
        ),
        sortable: true,
        maxWidth: "200px",
      },
      {
        name: "Custo",
        sortable: true,
        cell: (row, index) => (
          <CurrencyInput
            value={row.cost}
            name="custo"
            className="table-input"
            prefix="R$ "
            id={`input-cost-row-${index}`}
            onChangeEvent={(e, maskedvalue, floatvalue) => {
              row.cost = floatvalue;
              setItens([...itens]);
              setTimeout(() => {
                document.getElementById(`input-cost-row-${index}`).focus();
              }, 0);
            }}
          />
        ),
        maxWidth: "200px",
      },
      {
        name: "",
        maxWidth: "300px",
        cell: (row, index) => (
          <>
            {" "}
            <span className={!row.type ? "span-active" : "span-default"}>
              {" "}
              Saída
            </span>
            <div
              className="custom-control custom-switch"
              style={{ paddingLeft: "3em" }}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={`switch-${index}`}
                name={`switch-name-${index}`}
                onChange={(e) => {
                  if (row.type === true) {
                    row.type = false;
                  } else {
                    row.type = true;
                  }
                  setItens([...itens]);
                }}
                value={row.type}
                defaultChecked={row.type}
              />
              <label
                className="custom-control-label"
                htmlFor={`switch-${index}`}
              ></label>
            </div>
            <span className={row.type ? "span-active" : "span-default"}>
              {" "}
              Entrada
            </span>
          </>
        ),
      },
    ];
  }

  const colunas = [
    {
      name: "Nome do Produto",
      selector: "name",
      sortable: true,
    },
    {
      name: "Vendidos",
      cell: (row, index) => (
        <input
          type="number"
          className="table-input"
          value={row.sold}
          id={`input-sold-row-${index}`}
          disabled
        />
      ),
      maxWidth: "200px",
    },
    {
      name: "Quantidade",
      cell: (row, index) => (
        <input
          type="number"
          className="table-input"
          value={row.quantity}
          id={`input-quantity-row-${index}`}
          onChange={(e) => {
            row.quantity = Number(e.target.value);
            setItens([...itens]);
            setTimeout(() => {
              document.getElementById(`input-quantity-row-${index}`).focus();
            }, 0);
          }}
          // onBlur={(e) => {
          //     if (Number(e.target.value) < Number(row.sold)) {
          //         container.error('A quantidade não pode ser menor que o número de produtos vendios');
          //         row.quantity = row.sold
          //         setItens([...itens]);
          //         return;
          //     }

          // }}
        />
      ),
      sortable: true,
      maxWidth: "200px",
    },
    {
      name: "Custo",
      sortable: true,
      cell: (row, index) => (
        <CurrencyInput
          value={row.cost}
          name="custo"
          className="table-input"
          prefix="R$ "
          id={`input-cost-row-${index}`}
          onChangeEvent={(e, maskedvalue, floatvalue) => {
            row.cost = floatvalue;
            setItens([...itens]);
            setTimeout(() => {
              document.getElementById(`input-cost-row-${index}`).focus();
            }, 0);
          }}
        />
      ),
      maxWidth: "200px",
    },
  ];

  useEffect(() => {
    if (type === "4") {
      api
        .get(`/stock/list/init/menu/${menu_id}`)
        .then((res) => {
          let aux = [];
          res.data.forEach((item) => {
            aux.push({
              menu_product_id: item.id,
              name: item.name,
              quantity: 0,
              sold: item.quantity,
              cost: item.cost,
            });
          });
          setItens(aux);
          if (document.getElementById("loading")) {
            document.getElementById("loading").classList.add("esconde");
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }

    if (type === "2") {
      api
        .get(`stock/list/event/${event_id}`)
        .then((res) => {
          let options = [];

          res.data.forEach((item) => {
            options.push({ name: item.name, value: `${item.id}` });
          });
          setStocks(options);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .get(`/stock/transfer/menu/${menu_id}`)
        .then((res) => {
          let aux = [];
          res.data.forEach((item) => {
            aux.push({
              menu_product_id: item.id,
              name: item.name,
              quantity: item.quantity,
              cost: item.cost,
            });
          });
          setItens(aux);
          if (document.getElementById("loading")) {
            document.getElementById("loading").classList.add("esconde");
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }

    if (type === "3") {
      api
        .get(`/stock/transfer/menu/${menu_id}`)
        .then((res) => {
          let aux = [];
          res.data.forEach((item) => {
            aux.push({
              menu_product_id: item.id,
              name: item.name,
              quantity: 0,
              cost: 0,
              type: true,
            });
          });
          setItens(aux);
          if (document.getElementById("loading")) {
            document.getElementById("loading").classList.add("esconde");
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu_id]);

  createTheme("ticket", {
    text: {
      primary: "#4F6C7C",
      secondary: "#CBC1BF",
      font: "roboto",
    },
    headertext: {
      primary: "#2F81DA",
    },
    divider: {
      default: "#e9f2f2",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
      divider: {
        default: "#edf2f9",
      },
    },
    button: {
      default: "#3F25A8",
      focus: "#00A7E1",
      hover: "#20C4F4",
      disabled: "rgba(0, 0, 0, 0.12)",
    },
  });

  function salvar() {
    // for (let index = 0; index < itens.length; index++) {
    //     const element = itens[index];
    //     if (element.quantity < element.sold) {
    //         container.error('Existem produtos com a quantidade em estoque menor que vendido! ' + element.name);
    //         break;
    //     }
    // }

    // let falhas = [];

    // itens.forEach(item => {
    //     if(item.quantity < item.sold) {
    //         falhas.push('\n ' + item.name);
    //     }
    // });

    // if (falhas.length > 0) {
    //     container.error('Existem produtos com a quantidade em estoque menor que vendido! \n' + falhas)
    //     return;
    // }

    if (type === "4") {
      const query = {
        menu_id: menu_id,
        event_id: event_id,
        products: itens,
      };

      api
        .post("/stock/sold/menu/", query)
        .then((res) => {
          container.success(res.data.message);
          setTimeout(() => {
            history.goBack();
          }, 1000);
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    }

    if (type === "2") {
      const query = {
        menu_id: menu_id,
        event_id: event_id,
        products: itens,
        newmenu_id: filter,
      };

      api
        .post(`stock/transfer`, query)
        .then((res) => {
          container.success(res.data.message);
          setTimeout(() => {
            history.goBack();
          }, 1000);
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    }

    if (type === "3") {
      const query = {
        menu_id: menu_id,
        event_id: event_id,
        products: itens,
      };

      api
        .post(`stock/manual`, query)
        .then((res) => {
          container.success(res.data.message);
          setTimeout(() => {
            history.goBack();
          }, 1000);
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    }
  }

  // function updatetoSold() {
  //     itens.forEach(item => {
  //         item.quantity = item.sold
  //     });

  //     setItens([...itens]);
  // }

  return (
    <>
      <Loading title="aguarde..." />

      <PageHeader
        title="Estoque"
        text="Movimentação de Estoque"
        btnAction={() => salvar()}
        btnText="Salvar"
      ></PageHeader>

      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <div className="container-fluid mt-4">
        {/* <div className="row">
                    <div className="col-md-4">
                        <button onClick={updatetoSold} className="btn btn-secondary">
                            <FontAwesomeIcon icon={faAdjust} /> colocar vendidos em quantidade.
                        </button>
                    </div>
                </div> */}
        {type === "2" && (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="w-100 d-flex justify-content-between pt-2 px-4 pb-2">
                  <div className="flex-start my-auto">
                    <span className="span-default">
                      {" "}
                      Selecione para qual estoque deseja enviar:{" "}
                    </span>
                  </div>
                  <div
                    id="select-stock"
                    className="d-flex col-md-8"
                    align="right"
                  >
                    <SelectSearch
                      style={{ marginBottom: "0px" }}
                      key="stocks"
                      options={stocks}
                      value={filter}
                      onChange={(value) => setFilter(value)}
                      defaultValue={0}
                      search={true}
                      name="stocks"
                      placeholder="Selecione o estoque"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div
            className="col-md-12 table-responsive"
            style={{ paddingBottom: "40px" }}
          >
            <DataTable
              className={"tabela card"}
              columns={type === "4" ? colunas : columns}
              noHeader={true}
              data={itens}
              pagination={true}
              paginationPerPage={20}
              paginationRowsPerPageOptions={[20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Resultados por páginas:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
              }}
              noDataComponent={
                <EmptyData title="Nenhum resultado disponível." />
              }
              theme="ticket"
            ></DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventStockTrans;
