//Mascara: modo de uso # = caracter qualquer (ex: mascara de cep "#####-###"")
export const mask = (text, mask) => {
  let value = text;
  var i = value.length;
  var saida = mask.substring(1, 0);
  var texto = mask.substring(i);
  if (texto.substring(0, 1) !== saida) {
    value += texto.substring(0, 1);
  }
  return value;
};

//CPF
export const cpfMask = (value) => {
  if (!value) {
    return value;
  }

  if (value.length < 15) {
    return value
      .replace(/\D/g, "") // substitui qualquer caractxer que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  } else {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2") //pega os 4 numeros depois da barra e separa os proximos 2 por traço
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
};

//CNPJ
export const cnpjMask = (value) => {
  if (!value) {
    return value;
  }

  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2") //pega os 4 numeros depois da barra e separa os proximos 2 por traço
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const phoneMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2") //pega dois valores e coloca entre parenteses como DDD
    .replace(/(\d{9})\d+?$/, "$1") //lmita o numero de caracteres para 9 apos o DDD
    .replace(/(.{4})$/, "-$1");
};

export const cepMask = (value) => {
  return value.replace(/\D/g, "");
};

export const dashMask = (value) => {
  return value.replace(/(\d{3})\d+?$/, "$1");
};

export const percentageMask = (value) =>
  value.replace(/\D/g, "").replace(/(\d{3})\d+?$/, "$1");

export const moneyMask = (value, sufix = "R$ ") => {
  value = value
    ? String(value).replace(".", "").replace(",", "").replace(/\D/g, "")
    : 0;

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );

  if (result === "NaN") {
    return;
  }

  return sufix + result;
};

export const dateMask = (value) => {
  var v = value;
  if (v.match(/^\d{2}$/) !== null) {
    value = v + "/";
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    value = v + "/";
  }

  return value;
};

export const emailMask = (value) =>
  value.replace(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i);

export const numberMask = (value) => value.replace(/\D/g, "");

export const numberFloatMask = (value) =>
  value
    .replace(/[^0-9.]/g, "")
    .replace(/^[.]/, "")
    .replace(/[.]{2}/, "")
    .replace(/(\.\d{2})\d/, "$1");

export const slugify = (text, ampersand = "e") => {
  const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
  const b = "aaaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
  const p = new RegExp(a.split("").join("|"), "g");

  text = text
    .toString()
    .toLowerCase()
    .replace(/[\s_]+/g, "-")
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, `-${ampersand}-`)
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+|-+$/g, "");

  return text;
};
