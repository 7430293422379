import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";
import FormField from "components/FormField";
import New_api from "services/new-api";
import { ReactComponent as AddCircle2 } from "../../../../../../assets/icons/addCircle2.svg";
import { ReactComponent as TrashCan } from "../../../../../../assets/icons/trashCan.svg";
import "./styles.css";
import { COLORS } from "services/constants";
import { ModalFooter } from "../modalFooter";
import { useTranslation } from "react-i18next";

CourtesyForm.propTypes = {
  event_id: PropTypes.string,
  slug: PropTypes.string,
  onClose: PropTypes.func,
  changeTypeModal: PropTypes.func,
  setIsDirty: PropTypes.func,
  refresh: PropTypes.func,
};

const MIN_NUMBER = 1;
const MAX_NUMBER = 70;

// Schema
const createFormSchema = (t) =>
  yup.object({
    courtesies: yup.array().of(
      yup.object().shape({
        attendee_name: yup.string(),
        attendee_lastname: yup.string(),
        amount: yup
          .number(t("validation.amount.number"))
          .typeError(t("validation.amount.required"))
          .integer(t("validation.amount.integer"))
          .required(t("validation.amount.required"))
          .min(MIN_NUMBER, t("validation.amount.min", { number: MIN_NUMBER }))
          .max(MAX_NUMBER, t("validation.amount.max", { number: MAX_NUMBER })),
        event_sector_id: yup
          .number()
          .integer()
          .required(t("validation.eventSector")),
        description: yup.string(),
        receiver_email: yup
          .string()
          .trim()
          .email(t("validation.email.email"))
          .required(t("validation.email.required")),
      })
    ),
  });

const FormComponent = ({
  index,
  errors,
  setValue,
  register,
  sectors,
  length,
  remove,
  slug,
  control,
}) => {
  const switchStyles = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor: errors?.courtesies?.[index]?.event_sector_id?.message
        ? "#dc3545"
        : "#ced4da",
      borderRadius: "0.375rem",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#767380",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  const { t } = useTranslation("tickets", {
    keyPrefix: "courtesies.modalForm",
  });

  useEffect(() => {
    setValue(
      `courtesies.${index}.nickname`,
      `cortesia-${slug}-${day}-${month}-${year}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="row justify-content-between pl-3 pr-3 align-items-center">
            <CourtesyPositionText>
              {t("title")} {index + 1}
            </CourtesyPositionText>
            {length > 1 && (
              <RemoveButton type="button" onClick={() => remove(index)}>
                <TrashCan stroke="#1A212B" />
              </RemoveButton>
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <FormField
                label={t("label.name")}
                classNameLabel="custom-label"
                errorMessage={
                  errors?.courtesies?.[index]?.attendee_name?.message
                }
                controlId={`validationAttendee_name${index}`}
              >
                <Form.Control
                  {...register(`courtesies.${index}.attendee_name`)}
                  name={`courtesies.${index}.attendee_name`}
                  className="custom-input-form"
                  autoComplete="nope"
                  type="text"
                  placeholder={t("label.name")}
                  isInvalid={
                    errors?.courtesies?.[index]?.attendee_name?.message
                  }
                />
              </FormField>
            </div>
            <div className="col-6">
              <FormField
                label={t("label.lastName")}
                classNameLabel="custom-label"
                errorMessage={
                  errors?.courtesies?.[index]?.attendee_lastname?.message
                }
                controlId={`validationAttendee_lastname${index}`}
              >
                <Form.Control
                  {...register(`courtesies.${index}.attendee_lastname`)}
                  name={`courtesies.${index}.attendee_lastname`}
                  className="custom-input-form"
                  autoComplete="nope"
                  type="text"
                  placeholder={t("label.lastName")}
                  isInvalid={
                    errors?.courtesies?.[index]?.attendee_lastname?.message
                  }
                />
              </FormField>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <FormField
                label={t("label.sector")}
                classNameLabel="custom-label"
                controlId={`validationEvent_sector_id${index}`}
              >
                <Controller
                  name={`courtesies.${index}.event_sector_id`}
                  control={control}
                  rules={{ required: "O setor é obrigatório." }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      styles={switchStyles}
                      className="col-12 p-0"
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      onBlur={field.onBlur}
                      placeholder={t("placeholder.sector")}
                      options={sectors}
                      value={sectors.find(
                        (option) => option.value === field.value
                      )}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  )}
                />
                {errors?.courtesies?.[index]?.event_sector_id?.message && (
                  <h6 className="mt-1">
                    <small className="text-danger">
                      {t("validation.eventSector")}
                    </small>
                  </h6>
                )}
              </FormField>
            </div>
            <div className="col-6">
              <FormField
                label={t("label.quantity")}
                classNameLabel="custom-label"
                errorMessage={errors?.courtesies?.[index]?.amount?.message}
                controlId={`validationAmount${index}`}
              >
                <Form.Control
                  {...register(`courtesies.${index}.amount`)}
                  name={`courtesies.${index}.amount`}
                  className="custom-input-form"
                  autoComplete="nope"
                  onBlur={(e) => {
                    if (e.target.value < 1) {
                      return (e.target.value = 1);
                    } else if (isNaN(e.target.value)) {
                      return (e.target.value = 1);
                    }
                    return e.target.value;
                  }}
                  type="number"
                  placeholder={t("placeholder.quantity")}
                  isInvalid={errors?.courtesies?.[index]?.amount?.message}
                />
              </FormField>
            </div>
            <div className="col-12">
              <FormField
                label={t("label.email")}
                classNameLabel="custom-label"
                errorMessage={
                  errors?.courtesies?.[index]?.receiver_email?.message
                }
                controlId={`validationReceiver_email${index}`}
              >
                <Form.Control
                  {...register(`courtesies.${index}.receiver_email`)}
                  autoComplete="nope"
                  className="custom-input-form"
                  type="text"
                  placeholder={t("placeholder.email")}
                  isInvalid={
                    errors?.courtesies?.[index]?.receiver_email?.message
                  }
                />
              </FormField>
            </div>
            <div className="col-12">
              <FormField
                label={t("label.description")}
                classNameLabel="custom-label"
                errorMessage={errors?.courtesies?.[index]?.description?.message}
                controlId={`validationDescription${index}`}
              >
                <Form.Control
                  {...register(`courtesies.${index}.description`)}
                  name={`courtesies.${index}.description`}
                  autoComplete="nope"
                  className="custom-input-form"
                  type="text"
                  placeholder={t("placeholder.description")}
                  isInvalid={errors?.courtesies?.[index]?.description?.message}
                />
              </FormField>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function CourtesyForm({
  event_id,
  changeTypeModal,
  setIsDirty,
  slug,
  onClose,
  refresh,
}) {
  // States
  const [sectors, setSectors] = useState([]);
  //

  const { t } = useTranslation("tickets", {
    keyPrefix: "courtesies.modalForm",
  });

  const { t: tg } = useTranslation("global");

  // Hook-Form
  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      courtesies: [
        {
          nickname: "",
          description: "",
          receiver_email: "",
          event_sector_id: null,
          attendee_name: "",
          attendee_lastname: "",
          amount: 1,
        },
      ],
    },
    mode: "all",
    resolver: yupResolver(createFormSchema(t)),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "courtesies",
  });

  // Functions
  const onSubmit = (form) => {
    //
    Swal.fire({
      title: t("alert.submit.title"),
      icon: "info",
      text: t("alert.submit.text"),
      showCancelButton: true,
      confirmButtonText: t("alert.submit.confirmButton"),
      confirmButtonColor: "#0050c3",
      reverseButtons: true,
      cancelButton: t("alert.submit.cancelButton"),
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "courtesy-button-contained",
        cancelButton: "courtesy-button-outline",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onClose();
        const courtesies = form.courtesies.map((courtesy) => ({
          ...courtesy,
          attendee_lastname: !courtesy.attendee_lastname
            ? null
            : courtesy.attendee_lastname,
          description: !courtesy.description ? null : courtesy.description,
          attendee_name: !courtesy.attendee_name
            ? null
            : courtesy.attendee_name,
        }));
        Swal.fire({
          html: `
          <div class="loading alert-content">
            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M44 11C62.2255 11 77 25.7746 77 44C77 62.2255 62.2255 77 44 77C25.7746 77 11 62.2255 11 44C11 25.7746 25.7746 11 44 11Z" stroke="#C8E6FA" stroke-width="8" stroke-linecap="round"/>
              <path d="M44 11C62.2255 11 77 25.7746 77 44" stroke="#0050C3" stroke-width="8" stroke-linecap="round"/>
            </svg>
          </div>
          <div>
            <h2 class="alert-title">${t("alert.loading.title")}</h2>
            <p class="alert-text">${t("alert.loading.text")}</p>
          </div>
          `,
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          timerProgressBar: true,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        New_api.post(`events/${event_id}/courtesies`, {
          courtesies,
        })
          .then((res) => {
            let sum = 0;
            res.data.forEach((row) => {
              sum += row.amount;
            });
            Swal.fire({
              title: `${sum} ${t(
                sum > 1
                  ? "alert.post.successMultiple"
                  : "alert.post.successSingle"
              )}`,
              icon: "success",
              text: t("alert.post.text"),
              confirmButtonColor: "#0050c3",
              confirmButtonText: tg("confirmButtonText"),
            });
            reset();
            refresh();
          })
          .catch(() => {
            Swal.fire({
              title: tg("ops"),
              icon: "error",
              text: tg("internalServerError"),
              confirmButtonText: tg("confirmButtonText"),
            });
          });
      }
    });
  };
  const addMoreCourtesies = () => {
    append({
      event_sector_id: null,
      attendee_name: "",
      nickname: "",
      description: "",
      receiver_email: "",
      attendee_lastname: "",
      amount: 1,
    });
  };
  const listSectors = () => {
    New_api.get(`events/${event_id}/event_sectors`, {
      params: { per_page: Number.MAX_SAFE_INTEGER },
    }).then((res) => {
      const sectorsArray = res.data.data.map((item) => ({
        id: item.id,
        name: item.name,
        value: item.id,
        label: item.name,
      }));
      setSectors(sectorsArray.sort((a, b) => a.name.localeCompare(b.name)));
    });
  };
  //

  useEffect(() => {
    listSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {fields.map((field, index) => (
            <Fragment key={field.id}>
              <FormComponent
                index={index}
                errors={errors}
                remove={remove}
                sectors={sectors}
                setValue={setValue}
                register={register}
                slug={slug}
                length={fields.length}
                control={control}
              />
            </Fragment>
          ))}
          <div>
            <MoreCourtesiesButton type="button" onClick={addMoreCourtesies}>
              <StyleRightLineMoreCourtesies />
              <TextMoreCourtesiesContainer>
                {t("alert.addMoreCourtesies")}
                <AddCircle2 />
              </TextMoreCourtesiesContainer>
              <StyleLeftLineMoreCourtesies />
            </MoreCourtesiesButton>
          </div>
        </div>
        <ModalFooter buttonText={t("alert.submitButton")} isValid={isValid} />
      </form>
    </Fragment>
  );
}

////////////////////// Quick-send styles //////////////////////

const MoreCourtesiesButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  color: #0050c3;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: transparent;
  &:hover {
  }
`;

const StyleRightLineMoreCourtesies = styled.div`
  background-color: #0050c3;
  height: 1px;
  width: 100%;
  margin-right: 10px;
`;
const StyleLeftLineMoreCourtesies = styled.div`
  background-color: #0050c3;
  height: 1px;
  width: 100%;
  margin-left: 10px;
`;
const TextMoreCourtesiesContainer = styled.div`
  width: 50rem;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    width: 90rem;
  }
`;

const CourtesyPositionText = styled.div`
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: ${COLORS.neutralColorDarkD2};
`;

const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
`;
