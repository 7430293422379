import { yupResolver } from "@hookform/resolvers/yup";
import FormField from "components/FormField";
import ButtonContained from "components/buttonContained";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import useQuery from "customerHooks/useQuery";
import React, { Fragment, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import New_api from "services/new_api";
import { currencyFromInput, formatCurrency } from "utils";
import * as yup from "yup";
import "./styles.css";

export default function DeductionsModal({
  isOpen,
  onClose,
  toast,
  setReload,
  expense,
}) {
  const { t } = useTranslation("tickets");
  const { currency } = useCurrencyEventContext();

  const formSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(3, t("financialStatement.expenseModal.name.min"))
      .max(140, t("financialStatement.expenseModal.name.max"))
      .required(t("financialStatement.expenseModal.name.required")),
    quantity: yup
      .number()
      .transform((value) => (isNaN(value) || !value ? 0 : value))

      .min(1, t("financialStatement.expenseModal.quantity.min"))
      .default(1)
      .required(t("financialStatement.expenseModal.name.required")),
    value: yup
      .string()
      .required(t("financialStatement.expenseModal.value.required")),
    totalValue: yup.string(),
    description: yup.string().trim(),
  });

  const {
    watch,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: expense?.name || "",
      quantity: expense?.quantity || 1,
      value: formatCurrency(expense?.value || 0, { currency }),
      totalValue: formatCurrency(expense?.totalValue || 0, { currency }),
      description: expense?.description || "",
    },
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (expense) {
      setValue("name", expense?.name);
      setValue("description", expense?.description);
      setValue("quantity", expense?.quantity);
      setValue(
        "value",
        formatCurrency(expense?.value, { currency, isCents: true })
      );
      setValue(
        "totalValue",
        formatCurrency(expense?.totalValue, { currency, isCents: true })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expense]);

  const quantity = watch("quantity");
  const value = watch("value");

  const formatedValue = Number(value.replace(/[^0-9]/g, ""));

  const query = useQuery();
  const event_id = query.get("event_id");

  function setIntOrNull(value) {
    if (value) {
      return parseInt(value, 10);
    }
    return;
  }

  value !== undefined
    ? setValue(
        "totalValue",
        currencyFromInput(formatedValue * quantity, { currency })
      )
    : setValue("value", currencyFromInput(0, { currency }));

  const handleValue = (e) => {
    setValue("value", currencyFromInput(e.target.value, { currency }));
  };

  const handleQuantityChange = (e) => {
    setValue("quantity", setIntOrNull(e.target.value));
  };

  const closeModal = () => {
    onClose();
    reset();
  };

  const submitForm = async ({ name, quantity, description }) => {
    const params = {
      event_id,
      name,
      quantity,
      value: formatedValue,
      description,
    };

    let promise;

    if (expense) {
      promise = New_api.put(
        `event/bordero/deductions/${expense.id}?event_id=${event_id}`,
        params
      );
    } else {
      promise = New_api.post(`event/bordero/deductions`, params);
    }

    await promise
      .then(() => {
        setReload(true);
        toast.success(
          expense
            ? t("financialStatement.expenseModal.expenseEdited")
            : t("financialStatement.expenseModal.expenseSucceeded")
        );
      })
      .catch(() => {
        toast.error(
          expense
            ? t("financialStatement.expenseModal.expenseEditError")
            : t("financialStatement.expenseModal.expenseError")
        );
      })
      .finally(() => {
        closeModal();
        setReload(false);
      });
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        contentLabel="DeductionsModal"
        className="shadow side-modal"
      >
        <div className="card-header">
          <h4 className="card-header-title">
            {t("financialStatement.expenseModal.headerTitle")}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="card-body">
          <Form onSubmit={handleSubmit(submitForm)}>
            <Form.Row>
              <h2 className="modal-header-text">
                {t("financialStatement.expenseModal.modalTitle")}
              </h2>
            </Form.Row>

            <Form.Row>
              <Col md="6">
                <FormField
                  classNameLabel="label-input"
                  label={t("financialStatement.expenseModal.expenseName")}
                  errorMessage={errors.name?.message}
                  controlId="validationName"
                >
                  <Form.Control
                    {...register("name")}
                    name="name"
                    type="text"
                    placeholder={t(
                      "financialStatement.expenseModal.placeholder.expenseName"
                    )}
                    className="input"
                    isInvalid={errors.name?.message}
                    maxLength={140}
                  />
                </FormField>
              </Col>
              <Col md="6">
                <FormField
                  classNameLabel="label-input"
                  label={t("financialStatement.expenseModal.expenseQuantity")}
                  errorMessage={errors.quantity?.message}
                  controlId="validationQuantity"
                >
                  <Form.Control
                    {...register("quantity")}
                    name="quantity"
                    type="number"
                    placeholder={t(
                      "financialStatement.expenseModal.placeholder.expenseQuantity"
                    )}
                    className="input"
                    onChange={(e) => handleQuantityChange(e)}
                    isInvalid={errors.quantity?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md="6">
                <FormField
                  classNameLabel="label-input"
                  label={t("financialStatement.expenseModal.expenseValue")}
                  errorMessage={errors.value?.message}
                  controlId="validationValue"
                >
                  <Form.Control
                    {...register("value")}
                    name="value"
                    type="string"
                    onChange={(e) => handleValue(e)}
                    className="input"
                    isInvalid={errors.value?.message}
                  />
                </FormField>
              </Col>
              <Col md="6">
                <FormField
                  classNameLabel="label-input"
                  label={t("financialStatement.expenseModal.expenseAmount")}
                  errorMessage={errors.totalValue?.message}
                  controlId="validationTotalValue"
                >
                  <Form.Control
                    {...register("totalValue")}
                    name="totalValue"
                    type="string"
                    placeholder="0,00"
                    readOnly
                    className="input"
                    isInvalid={errors.totalValue?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>

            {/* <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="label-input"
                  label={t(
                    "financialStatement.expenseModal.expenseDescription"
                  )}
                  errorMessage={errors.description?.message}
                  controlId="validationDescription"
                >
                  <Form.Control
                    {...register("description")}
                    name="description"
                    type="text"
                    placeholder={t(
                      "financialStatement.expenseModal.placeholder.expenseDescription"
                    )}
                    className="input"
                    isInvalid={errors.description?.message}
                    maxLength={140}
                  />
                </FormField>
              </Col>
            </Form.Row> */}
            <Form.Row className="justify-content-end align-content-end">
              <div className="modal-buttons">
                <ButtonContained
                  className="secondary-outlined"
                  type="button"
                  onClick={() => closeModal()}
                  content={
                    <Fragment>
                      {t("financialStatement.expenseModal.expenseCancel")}
                    </Fragment>
                  }
                />
                <ButtonContained
                  className="primary-contained ml-2"
                  type="submit"
                  content={
                    <Fragment>
                      {expense
                        ? t("financialStatement.expenseModal.expenseEdit")
                        : t("financialStatement.expenseModal.expenseRecord")}
                    </Fragment>
                  }
                />
              </div>
            </Form.Row>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
}
