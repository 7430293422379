import React, { Fragment } from "react";
import { formatCurrency } from "utils";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCurrencyEventContext } from "contexts/currency-event-context";

export function SalesByTicket({
  sectors,
  absorbedTaxes,
  totalTicketSales,
  filter,
}) {
  const { t } = useTranslation("global");
  const { currency } = useCurrencyEventContext();

  const salesHeader = [
    {
      label: t("totalSales"),
      value: totalTicketSales - absorbedTaxes,
      type: "primary",
      symbol: "",
    },
    {
      label: t("totalTickets"),
      value: totalTicketSales,
      type: "ticket",
      symbol: "+",
    },
    {
      label: t("absorvedTaxes"),
      value: absorbedTaxes,
      type: "taxes",
      symbol: "-",
    },
  ];

  return (
    <Fragment>
      <Card>
        <Card.Header className="new-sales-card-title">
          {!filter.value ? t("issuedTicket") : t("paidTicket")}
        </Card.Header>
        <Card.Body className="new-sales-header-card">
          {salesHeader.map((item, index) => (
            <div key={index} className="d-flex justify-content-between">
              <span className={`new-sales-title-${item.type}`}>
                {item.label}
              </span>
              <span className={`new-sales-value-${item.type}`}>
                {item.symbol}{" "}
                {formatCurrency(item.value, { isCents: false, currency })}
              </span>
            </div>
          ))}
        </Card.Body>
        {sectors.map((sector) => (
          <Card.Body key={sector.id} className="new-detailed-sales">
            <div className="d-flex mb-2">
              <span
                className="new-sector-title text-start"
                style={{ flexBasis: "40%" }}
              >
                {sector.name}
              </span>
              <span
                className="new-sector-quantity-information text-start"
                style={{ flexBasis: "40%", textAlign: "start" }}
              >
                {t("global:ticketWithCount", { count: sector.qty })}
              </span>
              <span
                className="new-sector-value text-end"
                style={{ flexBasis: "20%", textAlign: "right" }}
              >
                {formatCurrency(sector.value, {
                  isCents: false,
                  currency,
                })}
              </span>
            </div>
            {sector.lots.map((lot, i) => (
              <div key={`lot-list-${i}`}>
                <div className="d-flex mb-1">
                  <span
                    className="new-lot-title text-start"
                    style={{ flexBasis: "40%" }}
                  >
                    {lot.name}
                  </span>
                  <span
                    className="new-lot-quantity text-start"
                    style={{ flexBasis: "40%", textAlign: "start" }}
                  >
                    {t("global:ticketWithCount", { count: lot.qty })}
                  </span>
                  <span
                    className="new-lot-value text-end"
                    style={{ flexBasis: "20%", textAlign: "right" }}
                  >
                    {formatCurrency(lot.value, { isCents: false, currency })}
                  </span>
                </div>
                {lot.types.map((type, i) => (
                  <div key={`type-list-${i}`}>
                    {type.qty > 0 || type.unit_value > 0 ? (
                      <div className="d-flex">
                        <span
                          className="new-type-name text-start"
                          style={{ flexBasis: "40%" }}
                        >
                          {type.name}
                        </span>
                        <span
                          className="new-type-quantity text-start"
                          style={{ flexBasis: "40%", textAlign: "start" }}
                        >
                          {type.qty} x{" "}
                          {formatCurrency(type.value, {
                            isCents: false,
                            currency,
                          })}
                        </span>
                        <span
                          className="new-type-value text-end"
                          style={{ flexBasis: "20%", textAlign: "right" }}
                        >
                          {formatCurrency(type.value * type.qty, {
                            isCents: false,
                            currency,
                          })}
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div
                          className="new-type-name text-start"
                          style={{ flexBasis: "40%" }}
                        >
                          {type.name}
                        </div>
                        <div
                          className="new-type-quantity text-start"
                          style={{ flexBasis: "40%", textAlign: "start" }}
                        >
                          {type.qty}
                        </div>
                        <div
                          className="new-type-value text-end"
                          style={{ flexBasis: "20%", textAlign: "right" }}
                        >
                          {formatCurrency(type.value, {
                            isCents: false,
                            currency,
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Card.Body>
        ))}
      </Card>
    </Fragment>
  );
}
