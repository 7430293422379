import axios from "axios";

const importLambda = axios.create({
  baseURL: process.env.REACT_APP_IMPORT_LAMBDA_BASE_URL,
});

importLambda.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default importLambda;
