/* eslint-disable */
import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import { DatePicker } from "components/datepicker";
import api from "services/api";
import moment from "moment";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";

export default function ReportCategory() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const category = urlParams.get("category_id");
  const event_id = urlParams.get("event_id");
  const user_id = localStorage.getItem("user_id");
  const [prods, setProds] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState(true);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({
    id: 0,
    eventId: 0,
  });
  const [check, setCheck] = useState(true);
  const [dataInit, setDataInit] = useState(urlParams.get("dataInit") || "");
  const [dataFim, setDataFim] = useState(urlParams.get("dataFim") || "");
  const { noCentsCurrencyFormatter } = useLanguageSettings();

  useEffect(() => {
    if (search) {
      const payload = {
        dataInit: dataInit
          ? moment(dataInit).format("YYYY-MM-DD HH:mm:ss")
          : "",
        dataFim: dataFim ? moment(dataFim).format("YYYY-MM-DD HH:mm:ss") : "",
        event_id: event_id,
      };

      if (!category) {
        api
          .get(`dash/commands/products/findUserCategory/${user_id}`)
          .then((res) => {
            const categoryID = res.data.category_menu_event_id;

            if (check) {
              setActiveCategory({
                id: res.data.category_menu_event_id,
              });
            }

            api
              .post(
                `/commands/products/prodPerCategory/${
                  activeCategory.id !== 0 ? activeCategory.id : categoryID
                }`,
                payload
              )
              .then((res) => {
                setProds(res.data.listProducts);
                setTotal(res.data.totalizerValue);
              })
              .catch(() => {
                Swal.fire({
                  title: "Ops!",
                  icon: "error",
                  text: "Ocorreu um erro interno, por favor tente novamente",
                  confirmButtonText: "Ok",
                });
              });

            if (check) {
              api
                .get(`dash/categoryMenuEventsUser/${user_id}`)
                .then((res) => {
                  setCategories(res.data);
                })
                .catch(() => {
                  Swal.fire({
                    title: "Ops!",
                    icon: "error",
                    text: "Ocorreu um erro interno, por favor tente novamente",
                    confirmButtonText: "Ok",
                  });
                });
            }

            setSearch(false);
          });
      } else {
        api
          .post(`/commands/products/prodPerCategory/${category}`, payload)
          .then((res) => {
            setProds(res.data.listProducts);
            setTotal(res.data.totalizerValue);
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
        setSearch(false);
      }
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      <PageHeader
        title="Relatório por categoria"
        text="detalhamento de vendas por categoria."
      />
      <div className="container-fluid">
        {categories.length > 0 && (
          <div id="menus-navigation" className="shadow-sm">
            <div id="menus-list" className="col-12">
              <span>escolha a categoria:</span>
              <div>
                {categories.map((item, i) => (
                  <button
                    type="button"
                    className={
                      activeCategory.id === item.id
                        ? "btn btn-primary"
                        : "btn btn-secondary"
                    }
                    key={item.id}
                    id="estoque-1"
                    value={item.id}
                    onClick={() => {
                      setActiveCategory({
                        id: item.id,
                        eventId: item.event_id,
                      });
                      setCheck(false);
                      setSearch(true);
                    }}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="card mt-4">
          <div className="card-body">
            <div className="row ">
              <div className="col-md-5">
                <div className="input-group" style={{ padding: "0px" }}>
                  <DatePicker
                    name="data-ini"
                    showTimeSelect
                    value={dataInit ? moment(dataInit).toDate() : ""}
                    onChange={(date) => {
                      setDataInit(date);
                    }}
                    placeholderText={t("components:datepicker.startDate2")}
                  />
                </div>
              </div>

              <div className="col-md-5">
                <div className="input-group" style={{ padding: "0px" }}>
                  <DatePicker
                    name="data-fin"
                    showTimeSelect
                    value={dataFim ? moment(dataFim).toDate() : ""}
                    onChange={(date) => {
                      setDataFim(date);
                    }}
                    placeholderText={t("components:datepicker.endDate2")}
                  />
                </div>
              </div>
              <div className="col-md-2 col-12">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setSearch(true)}
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="col-md-12">
              <div className="subtitle">
                <span>Vendas por produto</span>
              </div>
              <div className="table table-responsive">
                <table
                  id="table-totalsales"
                  className="table table-sm mt-4 datatable-custom"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">NOME</th>
                      <th scope="col" className="text-center">
                        CATEGORIA
                      </th>
                      <th scope="col" className="text-center">
                        QUANTIDADE
                      </th>
                      <th scope="col" className="text-center">
                        VALOR UNITÁRIO
                      </th>
                      <th scope="col" className="text-right">
                        VALOR TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {prods.map((item, i) => (
                      <tr key={i}>
                        <td>{item.product}</td>
                        <td className="text-center">{item.category}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-center">
                          {noCentsCurrencyFormatter(item.unique_price)}
                        </td>
                        <td className="text-right">
                          {noCentsCurrencyFormatter(item.total)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className="text-right td-total">
                        {`Total: ${noCentsCurrencyFormatter(total)}`}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
