import React, { useState, useEffect } from "react";
import Api from "services/api";
import SelectSearch from "react-select-search";
import moment from "moment";
import DataTable, { createTheme } from "react-data-table-component";
import Swal from "sweetalert2";
import EmptyData from "components/empty-data";
import Loading from "components/loading";

const EquipSearch = () => {
  function changeColor(id) {
    const card = document.getElementById(`row-${id}`);

    if (card.classList.contains("jwPGvH")) {
      card.classList.remove("jwPGvH");
      card.classList.add("jwPGvH-color");
    } else {
      card.classList.remove("jwPGvH-color");
      card.classList.add("jwPGvH");
    }
  }

  function cleanColor() {
    const elements = document.getElementsByClassName("sc-iwsKbI");
    if (elements.length > 0) {
      for (let index = 0; index < elements.length; index++) {
        let element = elements[index];
        if (element.classList.contains("jwPGvH-color")) {
          element.classList.remove("jwPGvH-color");
          element.classList.add("jwPGvH");
        }
      }
    }
  }

  const [events, setEvents] = useState([]);
  const [eventsSub, setEventsSub] = useState([]);
  const [users, setUsers] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [listEquipments, setListEquipments] = useState([]);
  const [data, setData] = useState({
    user: null,
    event: null,
  });
  const [sub, setSub] = useState({
    pdv: "",
    user: "",
    event: "",
    equips: "",
  });

  const [dataReturn, setDataReturn] = useState({
    equips: "",
  });

  const [dataMaintence, setDataMaintence] = useState({
    equips: "",
  });

  const [patrimony, setPatrimony] = useState("");

  const [selectedEvent, setSelectedEvent] = useState(0);
  const [filter, setFilter] = useState(null);

  const [dataEvent, setDataEvent] = useState(0);
  const [dataUser, setDataUser] = useState(null);
  const [dataPDV, setDataPDV] = useState(null);
  const [pdvs, setPdv] = useState([]);

  const fetchItens = () => {
    let checkboxes = document.getElementsByName("check-item");
    let itens = [];
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        itens.push(checkboxes[i].value);
      }
    }
    return itens;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (e.target.value === "1") {
      if (
        dataUser === null ||
        dataUser === "" ||
        dataEvent === null ||
        dataEvent === ""
      ) {
        Swal.fire({
          title: "Atenção!",
          icon: "warning",
          text: "O nome do usuário é Obrigatório",
        });
      } else {
        let itens = fetchItens();
        if (itens) {
          setSub({
            pdv: dataPDV,
            user: dataUser,
            event: dataEvent,
            equips: itens,
          });
        }
      }
    } else if (e.target.value === "2") {
      let itens = fetchItens();
      if (itens) {
        setDataReturn({
          equips: itens,
        });
      }
    } else if (e.target.value === "3") {
      let itens = fetchItens();
      if (itens) {
        setDataMaintence({
          equips: itens,
        });
      }
    }
  };

  const changeEvent = (e) => {
    if (e.target.value === "0") {
      setSelectedEvent(0);
      setData({
        ...data,
        event: null,
      });
    } else {
      setSelectedEvent(e.target.value);
      setData({
        ...data,
        event: e.target.value,
      });
    }
    return true;
  };

  const updateFilter = (value) => {
    setFilter(value);
    setData({
      ...data,
      user: value,
    });
  };

  const apllyFilter = (e) => {
    searchPartimony(e.target.value);
  };

  const searchPartimony = (value) => {
    let ar = [];
    listEquipments.forEach((item) => {
      if (item.patrimony.includes(value.toLowerCase())) {
        ar.push(item);
      }
    });

    setEquipments(ar);
    if (patrimony === "") {
      setEquipments(listEquipments);
    }
  };

  let options = [];
  let optionsEvents = [];
  let optionsPDVs = [];

  useEffect(() => {
    async function fetchEquipments() {
      const resultEquipments = await Api.post(
        "equipments/equipmentControl",
        data
      );
      setEquipments(resultEquipments.data);
      setListEquipments(resultEquipments.data);
      setPatrimony("");
    }

    async function fetchSubmit() {
      if (sub.equips !== "") {
        await Api.post(`equipments/sendEquipments`, sub)
          .then((res) => {
            if (res.status === 201) {
              Swal.fire({
                title: "Atenção!",
                icon: "warning",
                text: res.data.message,
              });
              fetchEquipments();
              cleanColor();
            } else {
              Swal.fire({
                title: "Sucesso",
                icon: "success",
                text: res.data.message,
              });
              setSub({
                pdv: "",
                user: "",
                event: "",
                equips: "",
              });
              fetchEquipments();
              cleanColor();
            }
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    }
    fetchSubmit(sub);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchEquipments() {
      const resultEquipments = await Api.post(
        "equipments/equipmentControl",
        data
      );
      setEquipments(resultEquipments.data);
      setListEquipments(resultEquipments.data);
      setPatrimony("");
    }

    async function fetchReturn() {
      if (dataReturn.equips !== "") {
        await Api.post(`equipments/returnEquipments`, dataReturn)
          .then((res) => {
            if (res.status === 201) {
              Swal.fire({
                title: "Atenção!",
                icon: "warning",
                text: res.data.message,
              });
              fetchEquipments();
              cleanColor();
            } else {
              Swal.fire({
                title: "Sucesso",
                icon: "success",
                text: res.data.message,
              });
              setDataReturn({
                equips: "",
              });
              fetchEquipments();
              cleanColor();
            }
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    }
    fetchReturn(dataReturn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchEquipments() {
      const resultEquipments = await Api.post(
        "equipments/equipmentControl",
        data
      );
      setEquipments(resultEquipments.data);
      setListEquipments(resultEquipments.data);
      setPatrimony("");
    }

    async function fetchMaintence() {
      if (dataMaintence.equips !== "") {
        await Api.post(`equipments/maintenceEquipments`, dataMaintence)
          .then((res) => {
            if (res.status === 201) {
              Swal.fire({
                title: "Atenção!",
                icon: "warning",
                text: res.data.message,
              });
              fetchEquipments();
              cleanColor();
            } else {
              Swal.fire({
                title: "Sucesso",
                icon: "success",
                text: res.data.message,
              });
              setDataMaintence({
                equips: "",
              });
              fetchEquipments();
              cleanColor();
            }
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    }
    fetchMaintence(dataMaintence);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      const resultEvents = await Api.get("listEvents");
      setEvents(resultEvents.data);

      resultEvents.data.forEach((item) => {
        optionsEvents.push({ name: item.name, value: `${item.id}` });
      });

      setEventsSub(optionsEvents);

      const resultUsers = await Api.get("listUsers");

      resultUsers.data.forEach((item) => {
        options.push({
          name: item.first_name + " " + item.last_name,
          value: `${item.id}`,
        });
      });

      setUsers(options);

      const resultPDVs = await Api.get("listPDVs");
      resultPDVs.data.forEach((item) => {
        optionsPDVs.push({
          name: item.type + " - " + item.location,
          value: `${item.id}`,
        });
      });
      setPdv(optionsPDVs);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchEquipments() {
      const resultEquipments = await Api.post(
        "equipments/equipmentControl",
        data
      );
      setEquipments(resultEquipments.data);
      setListEquipments(resultEquipments.data);
      setPatrimony("");
    }

    fetchEquipments();
    if (document.getElementById("loading")) {
      document.getElementById("loading").classList.add("esconde");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectableRows] = useState(false);
  const [noSelectAll] = useState(false);
  const [selectableRowsHighlight] = useState(false);
  const [expandableRows] = useState(false);
  const [expandOnRowClick] = useState(false);
  const [pagination] = useState(true);
  const [highlight] = useState(false);
  const [striped] = useState(false);
  const [pointer] = useState(false);
  const [dense] = useState(false);
  const [persist] = useState(false);
  const [tableHead] = useState(false);
  const [loading] = useState(false);
  const [noHeader] = useState(true);
  const [subHeader] = useState(false);
  const [fixedHeader] = useState(false);

  createTheme("ticket", {
    text: {
      primary: "#4F6C7C",
      secondary: "#CBC1BF",
      font: "roboto",
    },
    headertext: {
      primary: "#2F81DA",
    },
    divider: {
      default: "#e9f2f2",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
      divider: {
        default: "#3F25A8",
      },
    },
    button: {
      default: "#3F25A8",
      hover: "#fff",
      disabled: "rgba(0, 0, 0, 0.12)",
    },
  });

  const columns = [
    {
      name: "Ação",
      cell: (row) => (
        <div className="custom-input custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={row.id}
            name="check-item"
            value={row.id}
            onClick={(e) => changeColor(row.id)}
          />
          <label
            className="custom-control-label"
            htmlFor={row.id}
            style={{ marginLeft: "25px" }}
          ></label>
        </div>
      ),
    },
    {
      name: "SKU",
      selector: "equipment_name",
      sortable: true,
    },
    {
      name: "TIPO",
      selector: "category",
    },
    {
      name: "Patrimônio",
      selector: "patrimony",
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.location === 0 ? (
            <span className="badge badge-success">Em estoque</span>
          ) : row.location === 1 ? (
            <span className="badge badge-warning">Enviado</span>
          ) : (
            <span className="badge badge-danger">Manuntenção</span>
          )}
        </>
      ),
    },
    {
      name: "Ultima atualização",
      cell: (row) => (
        <span>{moment(row.updated_at).format("DD/MM/YYYY [às] HH:mm:ss")}</span>
      ),
    },
    {
      name: "Evento",
      cell: (row) => (
        <span>
          {row.location === 0
            ? "Escritório"
            : row.location === 2
            ? "Escritório"
            : row.event_name}
        </span>
      ),
    },
    {
      name: "Usuario",
      cell: (row) => (
        <span>
          {row.location === 0
            ? "Escritório"
            : row.location === 2
            ? "Escritório"
            : row.username}
        </span>
      ),
    },
  ];

  return (
    <>
      <Loading title="aguarde..." />

      <div className="filters container-fluid">
        <div className="col-12">
          <div className="line-top"></div>
          <div className="row mt-3">
            <div className="col-md-3">
              <input
                className="form-control"
                type="text"
                placeholder="Digite o patrimônio"
                value={patrimony}
                name="value"
                onChange={(e) => setPatrimony(e.target.value)}
                onKeyUp={apllyFilter}
              />
            </div>
            <div className="col-md-6">
              <select
                className="form-control selectpicker"
                data-live-search="true"
                value={selectedEvent}
                name="event"
                onChange={changeEvent}
              >
                <option value={0}>Todos os eventos</option>
                {events.length &&
                  events.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3">
              <SelectSearch
                key="user"
                options={users}
                value={filter}
                onChange={(value) => updateFilter(value)}
                defaultValue={0}
                search={true}
                name="user"
                placeholder="Selecione o usuário"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="col-12">
          <DataTable
            columns={columns}
            data={equipments}
            // defaultSortField="title"
            selectableRows={selectableRows}
            selectableRowsNoSelectAll={noSelectAll}
            selectableRowsHighlight={selectableRowsHighlight}
            expandableRows={expandableRows}
            expandOnRowClicked={expandOnRowClick}
            pagination={pagination}
            paginationPerPage={50}
            paginationComponentOptions={{
              rangeSeparatorText: "de",
              noRowsPerPage: true,
            }}
            highlightOnHover={highlight}
            striped={striped}
            pointerOnHover={pointer}
            dense={dense}
            responsive={true}
            overflowY={false}
            noTableHead={tableHead}
            persistTableHead={persist}
            progressPending={loading}
            defaultSortField={"equipment_name"}
            noHeader={noHeader}
            noDataComponent={<EmptyData title="Nenhum resultado disponível." />}
            subHeader={subHeader}
            theme="ticket"
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight="500px"
          />
        </div>
      </div>

      <div className="footer-equip">
        <div className="row">
          <div className="col-md-4">
            <button
              className="btn btn-info"
              value={1}
              onClick={(value) => submit(value)}
            >
              Enviar
            </button>
            <button
              className="btn btn-info"
              value={2}
              onClick={(value) => submit(value)}
              style={{ marginLeft: "10px" }}
            >
              Retornar
            </button>
            <button
              className="btn btn-info"
              value={3}
              onClick={(value) => submit(value)}
              style={{ marginLeft: "10px" }}
            >
              Manuntenção
            </button>
          </div>
          {/* <div className="col-md-1"></div> */}
          <div className="col-md-2">
            <SelectSearch
              key="pos"
              options={pdvs}
              value={dataPDV}
              onChange={(value) => setDataPDV(value)}
              defaultValue={0}
              search={true}
              name="user"
              placeholder="Selecione o PDV"
            />
          </div>

          <div className="col-md-2">
            <SelectSearch
              key="user"
              options={users}
              value={dataUser}
              onChange={(value) => setDataUser(value)}
              defaultValue={0}
              search={true}
              name="user"
              placeholder="Selecione o usuário"
            />
          </div>
          <div className="col-md-3">
            <SelectSearch
              key="event"
              options={eventsSub}
              value={dataUser}
              onChange={(value) => setDataEvent(value)}
              defaultValue={0}
              search={true}
              name="event"
              placeholder="Selecione o evento"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EquipSearch;
