import styled from "styled-components";

const getTemplaceColums = (leftIcon, rightIcon) => {
  if (leftIcon && rightIcon) {
    return "2.5rem 1fr 2.5rem";
  }
  if (leftIcon) {
    return "2.5rem 1fr";
  }
  if (rightIcon) {
    return "1fr 2.5rem";
  }
  return "1fr";
};

export const Container = styled.div`
  .react-datepicker__navigation {
    border: 0.45rem solid transparent;
  }
  .react-datepicker__year-option {
    background: #95aac9;
    color: #fff;
    font-weight: 600;
  }

  .react-datepicker__year-read-view--selected-year {
    color: #fff;
    font-weight: 600;
  }

  .react-datepicker__year-read-view--down-arrow {
    border-top-color: #12263f;
    /* background: transparent !important; */
  }
  .react-datepicker__navigation--next {
    border-left-color: #12263f;
    background: transparent !important;
  }

  .react-datepicker__navigation--previous {
    left: 0px;
    border-right-color: #12263f;
    background: transparent !important;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 85px !important;
  }

  .react-datepicker-popper {
    z-index: 20000 !important;
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-left: ${({ marginRight }) => marginRight};
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline-block;
    width: 100%;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 0.944rem;
    text-transform: capitalize;
    margin-top: 0;
    color: white;
    font-weight: bold;
    font-size: 0.944rem;
  }
  .react-datepicker__day-name {
    color: #fff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    text-transform: capitalize;
  }
  .react-datepicker-wrapper input[type="text"] {
    border: none;
    width: 100%;
  }

  .react-datepicker__month-container {
    border-radius: 0;
  }

  .react-datepicker__today-button {
    background-color: #95aac9;
    color: #fff;
    font-size: 0.944rem;
    font-weight: bold;
    /* padding: 0.35rem 0; */
    border: none;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #95aac9;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }
  .react-datepicker-popper {
    z-index: 500000;
  }
  .date-container {
    width: 100%;
    display: flex;
    align-items: center;
		cursor: pointer;
  }

  input {
    padding: ${({ hasChild }) => (hasChild ? undefined : "0.375rem 0.75rem")};
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    ::placeholder {
      color: #9e9e9e !important;
    }
  }

  width: 100%;
  height: 2.5rem;
  align-items: center;
  display: grid;
  grid-template-columns: ${({ leftIcon, rightIcon }) =>
    getTemplaceColums(leftIcon, rightIcon)};
  border: 1px solid #ced4da;
  overflow: hidden;
  line-height: 1.5;
  border-radius: 4px;
  background: ${({ disabled }) => (disabled ? "#f3f6f9" : "#fff")};
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    border-color: ${({ disabled, error }) =>
      disabled ? "" : error ? "#f73016" : "#80c2e9"};
  }

  border-color: ${({ isActive, error }) =>
    error ? "#f73016" : isActive && !error && "#86b7fe"};
  outline: 0;
  box-shadow: ${({ isActive }) => isActive && "0 0 0.3rem #86b7fe"};
`;

export const LeftIcon = styled.div`
  background: ${({ hasChild }) => (hasChild ? undefined : "#f3f6f9")};
  width: ${({ hasChild }) => (hasChild ? undefined : "2.5rem")};
  display: flex;
  min-height: max-content;
  height: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* flex-grow: 1; */
  border-right: ${({ hasChild }) =>
    hasChild ? undefined : "1px solid #d1d1d1"};
`;
export const RightIcon = styled.div`
  cursor: pointer;
  background: ${({ hasChild }) => (hasChild ? undefined : "#f3f6f9")};
  width: ${({ hasChild }) => (hasChild ? undefined : "2.5rem")};
  display: flex;
  min-height: max-content;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* flex-grow: 1; */
  border-left: ${({ hasChild }) =>
    hasChild ? undefined : "1px solid #d1d1d1"};
`;
