import React from "react";
import "./styles.css"
import {NavLink} from "react-router-dom"

export default function PageError(){
    return(
        <>

        <div id="page-error" className="container">
            <h2>404!</h2>
            <h4>A página que você procura não foi encontrada :(</h4>
            <p>Essa página não existe ou você não tem permissão para acessá-la</p>
            <NavLink to="/">retornar para a página de login</NavLink>
        </div>

        </>
    )
}