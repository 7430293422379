import PageHeader from "components/page-header";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import Card from "components/card";
import Select, { components } from "react-select";
// import ToggleSwitch from "components/toggleSwitch";
import { getAllTimezones } from "utils";
import { ReactComponent as LanguageIcon } from "../../../../assets/icons/LanguageIcon.svg";
import { ReactComponent as CurrencyIcon } from "../../../../assets/icons/CurrencyIcon.svg";
import { ReactComponent as TimezoneIcon } from "../../../../assets/icons/TimezoneIcon.svg";
import New_api from "services/new-api";
import { useScreenWidth } from "customerHooks/useScreenWidth";
import { CircleFlag } from "react-circle-flags";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";

let container;

const CardContent = ({ title, text, icon, children }) => {
  const isMobile = useScreenWidth();
  return (
    <div className="preferences-card-content">
      {isMobile ? (
        <>
          <div className="preferences-card-content-icon">
            {React.cloneElement(icon, {
              className: "preferences-card-content-icon",
            })}
          </div>
          <div className="preferences-mobile-wrapper">
            <div className="preferences-card-content-text">
              <h6>{title}</h6>
              {text && <p>{text}</p>}
            </div>
            <div className="preferences-card-content-child">{children}</div>
          </div>
        </>
      ) : (
        <>
          <div className="preferences-card-content-info">
            <div className="preferences-card-content-icon">
              {React.cloneElement(icon, {
                className: "preferences-card-content-icon",
              })}
            </div>
            <div className="preferences-card-content-text">
              <h6>{title}</h6>
              {text && <p>{text}</p>}
            </div>
          </div>
          <div className="preferences-card-content-child">{children}</div>
        </>
      )}
    </div>
  );
};

const Preferences = () => {
  // const [newsToggle, setNewsToggle] = useState(false);
  // const [generalToggle, setGeneralToggle] = useState(false);
  // const [newFeaturesToggle, setNewFeaturesToggle] = useState(false);
  const [settings, setSettings] = useState({
    backofficeLanguage: "",
    currency: "",
    timezone: "",
    marketplaceLanguage: "",
  });
  const { t } = useTranslation("preferences");
  const isMobile = useScreenWidth();

  const { SingleValue } = components;

  const CustomSingleValue = (props) => {
    const clonedIcon = React.cloneElement(props.data.icon, {
      style: { marginRight: "8px" },
    });

    return (
      <SingleValue {...props}>
        {clonedIcon}
        {props.data.label}
      </SingleValue>
    );
  };

  const fetchData = useCallback(async () => {
    await New_api.get("settings/locale")
      .then((res) => {
        setSettings({ ...res.data });
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const languageOptions = [
    {
      label: "Português (BR)",
      value: "pt-BR",
      icon: <CircleFlag countryCode="br" height="20px" />,
    },
    {
      label: "Inglês (US)",
      value: "en-US",
      icon: <CircleFlag countryCode="us" height="20px" />,
    },
    {
      label: "Espanõl (ES)",
      value: "es-ES",
      icon: <CircleFlag countryCode="es" height="20px" />,
    },
  ];

  const currencyOptions = [
    { label: "Real (R$)", value: "BRL" },
    { label: "Dólar ($)", value: "USD" },
    { label: "Euro (€)", value: "EUR" },
  ];

  const timezoneOptions = getAllTimezones().map((tz) => ({
    value: tz.timezone,
    label: tz.formatted,
  }));

  const handleSelectChange = async (type, data) => {
    const body = { ...settings, [type]: data.value };
    Swal.fire({
      title: t(`alert.title.${type}`),
      text: t("alert.text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("alert.confirm"),
      cancelButtonText: t("alert.cancel"),
    }).then((result) => {
      if (result.value) {
        New_api.post("settings/locale", body)
          .then((res) => {
            setSettings({ ...body });
            container.success(t(`toast.success.${type}`));
          })
          .catch((e) => {
            container.error(t(`toast.error.${type}`));
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  return (
    <div className="preferences">
      <div className="preferences-header">
        <PageHeader title={t("header")}></PageHeader>
      </div>
      <main className="preferences-content">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <h6 className="section-header">{t("section.panel")}</h6>
        <Card>
          <CardContent
            title={t("card.title.language")}
            text={t("card.text.language")}
            icon={<LanguageIcon />}
          >
            <Select
              className="preference-select"
              options={languageOptions}
              value={languageOptions.filter(
                (option) => option.value === settings.backofficeLanguage
              )}
              onChange={(data) =>
                handleSelectChange("backofficeLanguage", data)
              }
              components={{
                IndicatorSeparator: () => null,
                SingleValue: CustomSingleValue,
              }}
              isSearchable={false}
            />
          </CardContent>
        </Card>
        <h6 className="section-header">{t("section.marketplace")}</h6>
        <Card>
          <CardContent
            title={t("card.title.currency")}
            text={t("card.text.currency")}
            icon={<CurrencyIcon />}
          >
            <Select
              className="preference-select"
              options={currencyOptions}
              value={currencyOptions.filter(
                (option) => option.value === settings.currency
              )}
              onChange={(data) => handleSelectChange("currency", data)}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent
            title={t("card.title.timezone")}
            text={t("card.text.timezone")}
            icon={<TimezoneIcon />}
          >
            <Select
              className="preference-select"
              options={timezoneOptions}
              value={timezoneOptions.filter(
                (option) => option.value === settings.timezone
              )}
              onChange={(data) => handleSelectChange("timezone", data)}
              menuPlacement={isMobile ? "top" : "auto"}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
            />
          </CardContent>
        </Card>
        {/* Essa seção não faz parte da entrega atual 
        <h6 className="section-header">{t("section.communication")}</h6>
        <Card>
          <CardContent title={t("card.title.news")}>
            {t(`card.toggleStatus.${newsToggle ? "on" : "off"}`)}
            <ToggleSwitch
              checked={newsToggle}
              onChange={() => {
                setNewsToggle(!newsToggle);
              }}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent title={t("card.title.general")}>
            {t(`card.toggleStatus.${generalToggle ? "on" : "off"}`)}
            <ToggleSwitch
              checked={generalToggle}
              onChange={() => {
                setGeneralToggle(!generalToggle);
              }}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent title={t("card.title.newFeatures")}>
            {t(`card.toggleStatus.${newFeaturesToggle ? "on" : "off"}`)}
            <ToggleSwitch
              checked={newFeaturesToggle}
              onChange={() => {
                setNewFeaturesToggle(!newFeaturesToggle);
              }}
            />
          </CardContent>
        </Card> */}
      </main>
    </div>
  );
};

export default Preferences;
