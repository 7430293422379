import React, { useEffect, useState } from 'react'
import api from "../../../../../services/api.js";
import Swal from "sweetalert2";

import { ToastContainer } from 'react-toastr';

let container;

export default function ModalEventCategories(props) {
    const [status, setStatus] = useState("modal-hidden");
    const [idNomenclature, setIdNomenclature] = useState(null)
    const [nameNomen, setNameNomen] = useState("")
    const [erroNomenclature, setErroNomenclature] = useState(false);

    const save = () => {

        if (!nameNomen) {
            verifyNomenclature(nameNomen)
            container.error('Nome da categoria é obrigatório!')
            return;
        }
        let data = { name: nameNomen }
        if (idNomenclature) {
            api.post(`/update/nomenclature/${idNomenclature}`, data).then(res => {
                // container.success(res.data.message)
                close()
            }).catch(err => {
                container.error(err.response.data.message)
            })
        } else {
            api.post(`/new/nomenclature`, data).then(res => {
                // container.success(res.data.message)
                close()
                Swal.fire(
                    'Criado!',
                    res.data.message,
                    'success'
                )
            }).catch(err => {
                container.error(err.response.data.message)
            })
        }
    }



    const close = () => {

        setNameNomen('')
        setStatus('modal-hidden')
        props.retorno('modal-hidden')
    }

    const verifyNomenclature = (value) => {
        if (value === '') {
            setErroNomenclature(true)
        } else {
            setErroNomenclature(false)
        }
    }

    useEffect(() => {
        setStatus(props.status);
        setIdNomenclature(props.idNomenclature)
        if (props.idNomenclature !== null && props.idNomenclature > 0) {
            api.get(`/edit/nomenclature/${props.idNomenclature}`).then(res => {
                let nomenclature = res.data
                setNameNomen(nomenclature.name)
            }).catch(() => {
                Swal.fire({
                  title: 'Ops!',
                  icon: 'error',
                  text: 'Ocorreu um erro interno, por favor tente novamente',
                  confirmButtonText: 'Ok'
                })
              })
        }
    }, [props.status, props.idNomenclature]);

    return (
        <>
            <div id="venda-detalhe" className={"side-modal " + status} role="dialog">
                <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
                <div className="card-header">
                    <h4 className="card-header-title">{idNomenclature ? "Editar " : "Criar nova "}Categoria</h4>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => close(e)}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className="col-12 mt-4">

                            <div className="form-row">
                                <div className="form-group col-12">
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label>Nome da categoria</label>
                                    <input required type="text" value={nameNomen} onChange={e => setNameNomen(e.target.value)} onBlur={() => verifyNomenclature(nameNomen)} className={erroNomenclature ? "form-control is-invalid" : 'form-control'} />

                                </div>
                                <div className="form-group offset-8 col-4 mt-4">
                                    <button onClick={() => save()} className='btn btn-primary c3'>Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
