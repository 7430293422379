import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import styled from "styled-components";
import SideModal from "components/side-modal";
import api from "services/api";
import Lottie from "react-lottie";
import animationData from "../../../../components/lotties/load.json";
import Swal from "sweetalert2";
import SimpleTable from "components/simple-table";
import moment from "moment-timezone";
import "./styles.css";
import { useLanguageSettings } from "hooks/language-settings";

PosSalesDetails.propTypes = {
  show: PropTypes.bool,
  pdvId: PropTypes.number,
  serial: PropTypes.string,
  eventId: PropTypes.string,
  endedAt: PropTypes.object,
  startedAt: PropTypes.object,
  onClose: PropTypes.func,
};

const animationConfigs = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function PosSalesDetails(props) {
  const { currencyFormatter, noCentsCurrencyFormatter } = useLanguageSettings();
  const [loading, setLoading] = useState(true);
  const [detailsData, setDetailsData] = useState();

  const { show, pdvId, eventId, serial, endedAt, startedAt, onClose } = props;

  const _loadData = () => {
    setLoading(true);

    const params = {
      started_at: startedAt
        ? moment
            .tz(startedAt, "America/Sao_Paulo")
            .format("YYYY-MM-DDTHH:mm:00")
        : null,
      ended_at: endedAt
        ? moment.tz(endedAt, "America/Sao_Paulo").format("YYYY-MM-DDTHH:mm:59")
        : null,
    };

    api
      .get(`pos-sales-details/${eventId}/${pdvId}/${serial}`, { params })
      .then((res) => setDetailsData(res.data))
      .catch(() => {
        Swal.fire({
          title: "Falha",
          text: "Não foi possível obter as informações, tente novamente.",
          icon: "warning",
        });

        onClose();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (show) {
      _loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!show) {
    return <></>;
  }

  return (
    <SideModal show={show} title="VENDAS POR PONTO DE VENDA" onClose={onClose}>
      {loading && (
        <div className="mt-5">
          <Lottie options={animationConfigs} height={50} width={50} />
        </div>
      )}

      {!loading && detailsData && (
        <>
          <InfoContainer>
            <InfoTitle>Informações de pagamento:</InfoTitle>

            <TableContainer>
              <SimpleTable
                borders
                tableClassName="details-info-table"
                columns={[
                  { title: "Forma de Pagamento" },
                  { title: "Quantidade de Vendas", className: "text-center" },
                  { title: "Total", className: "text-right" },
                ]}
                rows={detailsData.payments.map((item) => ({
                  infos: [
                    { value: item.payment_method, className: "text-uppercase" },
                    { value: item.amount, className: "text-center" },
                    {
                      value: currencyFormatter(Math.round(item.total)),
                      className: "text-right",
                    },
                  ],
                }))}
              />
            </TableContainer>
          </InfoContainer>
          <InfoContainer>
            <InfoTitle>Informações dos ingressos:</InfoTitle>

            <TableContainer className="mb-5">
              <SimpleTable
                borders
                tableClassName="details-info-table"
                columns={[
                  { title: "Total vendido" },
                  {
                    title: `${detailsData.tickets.amount} unidades`,
                    className: "text-center text-lowercase",
                  },
                  {
                    title: noCentsCurrencyFormatter(
                      Math.round(detailsData.tickets.total)
                    ),
                    className: "text-right",
                  },
                ]}
                sections={detailsData.tickets.sectors.map((sector) => ({
                  id: sector.id,
                  infos: [
                    { value: sector.name, className: "text-uppercase" },
                    {
                      value: `${sector.amount} unidades`,
                      className: "text-center",
                    },
                    {
                      value: noCentsCurrencyFormatter(sector.total),
                      className: "text-right",
                    },
                  ],
                }))}
                rows={detailsData.tickets.sectors.flatMap((sector) => {
                  return sector.tickets.map((ticket) => ({
                    sectionId: sector.id,
                    infos: [
                      { value: ticket.name },
                      {
                        value: `${ticket.amount} x ${noCentsCurrencyFormatter(
                          ticket.price
                        )}`,
                        className: "text-center",
                      },
                      {
                        value: noCentsCurrencyFormatter(
                          Math.round(ticket.total)
                        ),
                        className: "text-right",
                      },
                    ],
                  }));
                })}
              />
            </TableContainer>
          </InfoContainer>
        </>
      )}
    </SideModal>
  );
}

//----- Styled Components ------//
const InfoContainer = styled.div`
  margin-bottom: 10px;
`;

const InfoTitle = styled.p`
  color: #4f6c7c;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1.063rem;
  margin: 24px 16px 18px 16px;
`;

const TableContainer = styled.div`
  margin: 0 0 0 16px;
`;
