import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import "rsuite/lib/styles/index.less";
import { Dropdown } from "react-bootstrap";
import {
  faPencilAlt,
  faSearch,
  faChartPie,
  faCircle,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import Api from "../../../services/api";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";
import EmptyData from "components/empty-data";
import { ImageHandlerService } from "services/image-handler";
import Loading from "components/loading";
import { PERMISSIONAB } from "services/constants";

let container;

const user = JSON.parse(localStorage.getItem("user"));

const Establishments = () => {
  const [values, setValues] = useState([]);
  const [valuesFilter, setValuesFilter] = useState([]);

  useEffect(() => {
    Api.get("establishments")
      .then((res) => {
        setValues(res.data);
        setValuesFilter(res.data);
      })
      .catch((err) => {})
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  }, []);

  const [filter, setFilter] = useState({
    name: "",
  });

  const updateFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const apllyFilter = (e) => {
    searchName(e.target.value);
  };

  const searchName = (value) => {
    let ar = [];
    valuesFilter.forEach((item) => {
      if (item.name.toLowerCase().includes(value.toLowerCase())) {
        ar.push(item);
      }
    });
    setValues(ar);
    if (filter.name === "") {
      setValues(valuesFilter);
    }
  };

  const atualizaStatus = (e, item) => {
    item.status = Number(!item.status);
    setValues([...values]);

    Api.get("establishment/" + item.id + "/online/" + item.status)
      .then((res) => {
        if (item.status === 1) {
          container.success("Estabelecimento aberto com sucesso.");
        } else {
          container.success("Estabelecimento fechado com sucesso.");
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const createEstablishment = () => {
    Api.get("validToCreateEventOrEstablishment").then((res) => {
      if (res.data || user.is_admin) {
        window.location = "/register-establishment";
      } else {
        Swal.fire({
          text: "Crie uma organização antes de criar um estabelecimento!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Criar organização",
          cancelButtonText: "Deixar para depois",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            window.location = `/organizacao`;
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <Loading title="aguarde..." />

      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <PageHeader
        title="Estabelecimentos"
        text="Listagem de todos os estabelecimentos cadastrados na plataforma"
        btnAction={() => {
          if (PERMISSIONAB) {
            createEstablishment();
          }
          return;
        }}
        btnText={PERMISSIONAB && "criar estabelecimento"}
      />

      <div className="container-fluid mt-4">
        <div id="event-filters">
          <div className="col-md-12">
            <div className="card-search d-flex">
              <FontAwesomeIcon icon={faSearch} className="icon-search" />
              <input
                className="input-custom"
                value={filter.name}
                name="name"
                onChange={updateFilter}
                onKeyUp={apllyFilter}
                autoComplete="off"
                placeholder="Pesquisar por nome do estabelecimento"
              />
            </div>
          </div>
        </div>
        <div id="list-establishments" className="col-12 card-list">
          {values.length ? (
            values.map((item, i) => (
              <div
                key={i}
                className="card-grid card-establishments shadow-sm col-12"
              >
                <div className="card-establishments-infos col-10">
                  {item.thumb ? (
                    <div
                      className="establishments-img"
                      style={{
                        backgroundImage: `url(${ImageHandlerService.getImageFromUrl(
                          item.thumb,
                          {
                            format: "webp",
                            quality: 50,
                            resize: { width: 100, fit: "contain" },
                          }
                        )})`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className="establishments-img"
                      style={{ backgroundImage: "url(/logo-pocket.jpg)" }}
                    ></div>
                  )}

                  <div className="card-row-name">
                    <NavLink
                      to={`/bar/dashboard?event_id=${item.id}`}
                      data-tip={`Dashboard - ${item.name}`}
                    >
                      <span className="establishments-name">{item.name}</span>
                    </NavLink>
                  </div>
                </div>

                <Dropdown>
                  <Dropdown.Toggle className="dotsMobile">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-show">
                    <Dropdown.Item as="div">
                      <div className="toogle-button">
                        {item.status === 1 ? (
                          <span className="event-status event-online">
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                        ) : (
                          <span className="event-status event-offline">
                            <FontAwesomeIcon icon={faCircle} />
                          </span>
                        )}
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={item.id}
                            name={item.id}
                            defaultChecked={item.status}
                            onChange={(e) => atualizaStatus(e, item, i)}
                          ></input>
                          <label
                            className="custom-control-label"
                            htmlFor={item.id}
                          ></label>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <hr />
                    <Dropdown.Item
                      href={`/register-establishment/${item.id}?event_id=${item.id}`}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                      <span>Editar</span>
                    </Dropdown.Item>
                    <Dropdown.Item href={`/bar/dashboard?event_id=${item.id}`}>
                      <FontAwesomeIcon icon={faChartPie} />
                      <span>Dashboard</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ))
          ) : (
            <EmptyData title="nenhum estabelecimento registrado." />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Establishments;
