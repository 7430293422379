import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import JoditEditor from "jodit-react";
import { DatePicker } from "components/datepicker";
import "./styles.css";
import { useTranslation } from "react-i18next";

const Example = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());

  return (
    <div className="content">
      <ReactTooltip place="top" type="info" effect="solid" />
      <div className="card-content">
        <div className="row mt=20">
          <div className="col-md-12">
            <h1 id="titl-example">Botões</h1>
          </div>
        </div>
        {/* exemplo de botoes */}
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            <button className="btn btn-info" data-tip="btn btn-info">
              Botão
            </button>
          </div>

          <div className="col-md-2" id="autoajus">
            <button className="btn btn-red" data-tip="btn btn-red">
              Botão
            </button>
          </div>

          <div className="col-md-2" id="autoajus">
            <button className="btn btn-charts " data-tip="btn btn-charts">
              Botão
            </button>
          </div>

          <div className="col-md-2" id="autoajus">
            <button className="btn btn-orange" data-tip="btn btn-orange">
              Botão
            </button>
          </div>
        </div>

        <div className="row mt-2">
          <div className=" col-md-2 " id="autoajus">
            <button className="btn cor-azul" data-tip="btn cor-azul">
              Botão
            </button>
          </div>

          <div className="col-md-2" id="autoajus">
            <button className="btn btn-pri-out" data-tip="btn btn-pri-out">
              Botão
            </button>
          </div>

          <div className=" col-md-2" id="autoajus">
            <button className="btn btn-pri" data-tip="btn btn-pri">
              Botão
            </button>
          </div>

          <div className=" col-md-2" id="autoajus">
            <button className="btn btn-green" data-tip="btn btn-green">
              Botão
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-md-2" id="autoajus">
            <button
              className="btn btn-funz-cinza"
              data-tip="btn btn-funz-cinza"
            >
              Botão
            </button>
          </div>

          <div className=" col-md-2" id="autoajus">
            <button className="btn btn-link" data-tip="btn btn-link">
              Botão
            </button>
          </div>

          <div className="col-md-2" id="autoajus">
            <button className="btn btn-primary" data-tip="btn btn-primary">
              Botão
            </button>
          </div>

          <div className=" col-md-2" id="autoajus">
            <button className="btn-table" data-tip="btn-table">
              Icon
            </button>
          </div>
        </div>

        {/* exemplo de input */}
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 id="titl-example">Input</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-md-4" id="autoajus">
            <input
              type="text"
              className="form-control inputs"
              id="validationDefault01"
              placeholder="Nome do evento"
              data-tip="form-control inputs"
              required
            />
          </div>

          <div className=" col-md-4" id="autoajus">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="org-banner"
                aria-describedby="inputGroupFileAddon01"
              />
              <label className="custom-file-label" htmlFor="org-banner">
                Procurar...
              </label>
            </div>
          </div>

          <div className=" col-md-4" id="autoajus">
            <DatePicker
              name="date_end"
              value={date}
              onChange={(date) => setDate(date)}
              showTimeSelect
              placeholderText={t("components:datepicker.searchByDate")}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-md-10" id="autoajus">
            <JoditEditor
            // ref={editor}
            // config={config}
            // tabIndex={1} // tabIndex of textarea
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 id="titl-example">Select</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-md-4">
            <select
              id="inputCategoria"
              data-tip="form-control"
              className="form-control"
            >
              <option>1 </option>
              <option>2 </option>
              <option>3 </option>
            </select>
          </div>

          <div className=" col-md-4" id="autoajus">
            <select
              id="types"
              className="custom-select form-control selec-ajuste"
              multiple
            >
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
        {/* exemplo de Cards */}
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 id="titl-example">Card</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-content"
              data-tip="card-content"
              style={{ padding: 20 }}
            ></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-title-event shadow"
              data-tip="card-title-event shadow"
              style={{ padding: 20 }}
            ></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-one cards"
              data-tip="card-one"
              style={{ padding: 20 }}
            ></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-two cards"
              data-tip="card-two"
              style={{ padding: 20 }}
            ></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-three cards"
              data-tip="card-three"
              style={{ padding: 20 }}
            ></div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-four cards"
              data-tip="card-four"
              style={{ padding: 20 }}
            ></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div className="card" data-tip="card" style={{ padding: 20 }}></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card ajuste-card"
              data-tip="card ajuste-card"
              style={{ padding: 20 }}
            ></div>
          </div>

          <div className="col-md-2" id="autoajus">
            {" "}
            {/* nao utilizar style */}
            <div
              className="card-home"
              data-tip="card-home"
              style={{ padding: 20 }}
            ></div>
          </div>
        </div>
        {/* exemplo de titulos */}
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 id="titl-example">Titulos</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            <h1> h1</h1>
          </div>
          <div className="col-md-2" id="autoajus">
            <h2> h2</h2>
          </div>
          <div className="col-md-2" id="autoajus">
            <h3> h3</h3>
          </div>

          <div className="col-md-2" id="autoajus">
            <h4> h4</h4>
          </div>
          <div className="col-md-2" id="autoajus">
            <h5> h5</h5>
          </div>
          <div className="col-md-2" id="autoajus">
            <h6> h6</h6>
          </div>
        </div>

        {/* exemplo de span */}
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 id="titl-example">Span</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            <span className="input-group-text" data-tip="input-group-text">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="event-tag" data-tip="event-tag">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="titulo-info" data-tip="titulo-info">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="user-name-span" data-tip="user-name-span">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="date-event" data-tip="date-event">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="text-center" data-tip="text-center">
              span
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            <span className="subtitle" data-tip="subtitle">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="card-span" data-tip="card-span">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="card-subspan" data-tip="card-subspan">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="card-title" data-tip="card-title">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="card-infos-span" data-tip="card-infos-span">
              span
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <div className="tips">
              <span className="tips-content" data-tip="tips-content">
                span
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            <span className="expanded-title" data-tip="expanded-title">
              span{" "}
            </span>
          </div>

          <div className="col-md-2" id="autoajus">
            <span className="expanded-content" data-tip="expanded-content">
              span
            </span>
          </div>
        </div>

        {/* exemplo de checkebox, switch, radio  &&  o id do input precisa ser igual ao htmlFor do label */}
        <div className="row mt-3">
          <div className="col-md-12">
            <h1 id="titl-example">Checkebox</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2" id="autoajus">
            <div
              className="custom-control custom-switch"
              data-tip="custom-control custom-switch"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitches"
              />
              <label className="custom-control-label" htmlFor="customSwitches">
                switch
              </label>
            </div>
          </div>

          <div className="col-md-2" id="autoajus">
            {/* o id do input precisa ser igual ao htmlFor do label */}
            <div
              className="custom-control custom-checkbox"
              data-tip="custom-control custom-checkbox"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="defaultUnchecked"
              />
              <label
                className="custom-control-label"
                style={{ color: "#484848" }}
                htmlFor="defaultUnchecked"
              >
                checkbox
              </label>
            </div>
          </div>

          <div className="col-md-2" id="autoajus">
            {/* o id do input precisa ser igual ao htmlFor do label */}
            <div
              className="custom-control custom-radio"
              data-tip="custom-control custom-radio"
            >
              <label className="radio-inline ">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="percentual"
                  name="discountType"
                />
                <label className="custom-control-label" htmlFor="percentual">
                  radio
                </label>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Example;
