import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faFilter } from "@fortawesome/free-solid-svg-icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import styled from "styled-components";
import { Col, Container, Row, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import Loading from "components/loading";
import New_api from "services/new-api";
import EmptyData from "components/empty-data";

export default function SeatsListing() {
  const [isLoading, setIsLoading] = useState(true);
  const [seatsList, setSeatsList] = useState([]);
  const [filter, setFilter] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  let debounceTimeout;

  function seatChartsList(filter) {
    const params = {
      filter: filter,
    };
    setIsLoading(true);
    New_api.get(`/charts`, { params })
      .then((res) => {
        setSeatsList(res.data.data);
      })
      .catch(() =>
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno. Por favor, tente novamente.",
          confirmButtonText: "Ok",
          preConfirm: () => {
            seatChartsList();
          },
        })
      )
      .finally(() => setIsLoading(false));
  }

  const handleFilter = () => {
    if (isFilterApplied) {
      seatChartsList([]);
      setIsFilterApplied(false);
      setFilter("");
    } else {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        setIsFilterApplied(filter !== "" ? true : false);
        seatChartsList(filter);
      }, 500);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };

  function updateFilter(event) {
    setFilter(event.target.value);
    setIsFilterApplied(false);
  }

  useEffect(() => {
    seatChartsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openModal(tableItemUrl) {
    setIsModalOpen(true);
    setThumbnailUrl(tableItemUrl);
  }

  return (
    <>
      {isLoading && <Loading title="aguarde..." />}
      <StyledModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div
          style={{
            borderBottom: "1px solid lightgray",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseButton onClick={() => setIsModalOpen(false)}>
            <CloseIcon size={32} />
          </CloseButton>
        </div>

        <img src={thumbnailUrl} alt="thumbnail url" />
      </StyledModal>
      <PageHeader
        title="Mapas de Assentos"
        text="Aqui estão todos os mapas de assentos"
        btnLink="/mapas/novo"
        btnText="criar mapa de assentos"
      />
      <Container fluid>
        <Card className="p-3 mt-3">
          <Row>
            <Col sm={12} md={10}>
              <input
                name="map_name"
                value={filter}
                className="form-control"
                placeholder="Buscar por nome"
                onKeyDown={handleKeyDown}
                onChange={updateFilter}
              />
            </Col>
            <Col sm={12} md={2}>
              <button
                type="button"
                className={`btn ${
                  isFilterApplied
                    ? "btn-outline-secondary"
                    : "btn-outline-primary"
                } w-100`}
                onClick={handleFilter}
              >
                <FontAwesomeIcon icon={faFilter} />
                {isFilterApplied ? "limpar filtro" : "aplicar filtro"}
              </button>
            </Col>
          </Row>
        </Card>
        <Card className="p-3">
          {seatsList.length > 1 ? (
            <div className="col-12 table-responsive">
              <div className="table-small-desktop">
                <table className="table table-sm">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col" className="text-center">
                        Nome
                      </th>
                      <th scope="col" className="text-center">
                        Status
                      </th>
                      <th scope="col" className="text-center">
                        Arquivado
                      </th>
                      <th scope="col" className="text-center">
                        Válido
                      </th>
                      <th scope="col" className="text-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {seatsList.map((item) => (
                      <tr key={item.id}>
                        <th scope="col">{item.key}</th>
                        <th scope="col" className="text-center">
                          {item.name}
                        </th>
                        <th scope="col" className="text-center">
                          <span
                            className={
                              item.status === "NOT_USED"
                                ? "badge badge-pill badge-info"
                                : item.status === "PUBLISHED"
                                ? "badge badge-pill badge-success"
                                : item.status === "PUBLISHED_WITH_DRAFT"
                                ? "badge badge-pill badge-warning"
                                : ""
                            }
                          >
                            {item.status === "NOT_USED"
                              ? "Não utilizado"
                              : item.status === "PUBLISHED"
                              ? "Publicado"
                              : item.status === "PUBLISHED_WITH_DRAFT"
                              ? "Publicado com rascunho"
                              : ""}
                          </span>
                        </th>
                        <th scope="col" className="text-center">
                          <span
                            className={
                              item.archived
                                ? "badge badge-pill badge-success"
                                : "badge badge-pill badge-danger"
                            }
                          >
                            {item.archived ? "Ativo" : "Arquivado"}
                          </span>
                        </th>
                        <th scope="col" className="text-center">
                          <span
                            className={
                              item.validation === "VALID"
                                ? "badge badge-pill badge-success"
                                : item.validation === "WITH_ERROR"
                                ? "badge badge-pill badge-danger"
                                : item.validation === "WITH_WARNINGS"
                                ? "badge badge-pill badge-warning"
                                : ""
                            }
                          >
                            {item.validation === "VALID"
                              ? "Válido"
                              : item.validation === "WITH_ERROR"
                              ? "Com erros"
                              : item.validation === "WITH_WARNINGS"
                              ? "Com alertas"
                              : ""}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="d-flex justify-content-center"
                        >
                          <div className="card-table-actions">
                            <ReactTooltip
                              className="tooltip-custom"
                              place="top"
                              effect="solid"
                            />
                            <button className="btn-table" data-tip="Editar">
                              <NavLink to={`/mapas/${item.key}`}>
                                <FontAwesomeIcon icon={faPen} />
                              </NavLink>
                            </button>
                            <button
                              className="btn-table"
                              data-tip="Visualizar"
                              onClick={() => openModal(item.thumbnail)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                          </div>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <EmptyData title="Nenhum mapa encontrado" />
          )}
        </Card>
      </Container>
    </>
  );
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 533px;
  background-color: white;
  outline: none;
  border: none;
  overflow: auto;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: gray;
  font-size: 34px;
`;
