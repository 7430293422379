import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { CloseButton, Col, Form } from "react-bootstrap";
import FormField from "components/FormField";
import New_api from "services/new-api";
import IconInput from "components/iconInput";
import Text from "../../../../assets/icons/Text Style.svg";
import Checkbox from "../../../../assets/icons/Check Square.svg";
import Paragraph from "../../../../assets/icons/Paragraph.svg";
import Circlebox from "../../../../assets/icons/Circle.svg";
import Add from "../../../../assets/icons/Add.svg";
import CustomButtonSpinner from "components/buttonSpinner";

CustomFieldsModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func,
  question: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    field_list: PropTypes.object,
    required: PropTypes.bool,
    instructions: PropTypes.string,
    order_field: PropTypes.number,
    field: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  toast: PropTypes.any,
  event_id: PropTypes.number,
  order_field: PropTypes.number,
  setIsReload: PropTypes.func,
};

function CustomFieldsModal({
  isOpen,
  setIsOpen,
  onClose,
  question,
  toast,
  event_id,
  order_field,
  setIsReload,
}) {
  // const [instructionsExists, setInstructionsExists] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [formOptions, setFormOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(3, "O título da pergunta deve ter no mínimo 3 caracteres.")
      .max(140, "O máximo de caracteres foi atingido.")
      .required("O título da pergunta é obrigatório."),
    type: yup.string().trim().required("O tipo do campo é obrigatório."),
    ...(["checkbox", "radiobutton"].includes(selectedType) && {
      options: yup.array().of(
        yup.object().shape({
          value: yup.string().trim().required("A opção deve ser informada."),
        })
      ),
    }),
    required: yup.boolean(),
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: question?.name || "",
      type: question?.field.value || "",
      required: question?.required || false,
      options: [{ value: "" }],
    },
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  function closeModal() {
    onClose();
    reset();
    setIsOpen(false);
    setSelectedValue(null);
    setSelectedType("");
    // setInstructionsExists(false);
  }

  useEffect(() => {
    const getOptions = async () => {
      const options = await New_api.get("/field-types", {
        page: 1,
        per_page: 15,
      });
      setFormOptions(options.data.data);
    };
    getOptions();
  }, []);

  useEffect(() => {
    if (question) {
      setValue("name", question.description);
      setValue("required", !!question.required);
      setValue("type", question.field.value);
      setSelectedValue(question.field.value);

      if (
        question.field.label !== "Texto" &&
        question.field.label !== "Parágrafo"
      ) {
        setSelectedType(question.field.type);
        const fieldList = Object.entries(JSON.parse(question.field_list));
        const field = fieldList.flat().pop();
        const fieldOptions = Object.values(field);

        setValue(
          "options",
          fieldOptions.map((item) => ({ value: item }))
        );
        const listFields = {};
        fieldOptions.forEach((option, index) => {
          listFields[index] = option;
        });
      }
      // setValue("instructionsExists", !!question?.instructions);
      // setValue("instructions", question?.instructions);
      // setInstructionsExists(!!question?.instructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const onSelect = (field) => {
    setSelectedType(field.type);
    setValue("type", field.value, { shouldDirty: true, shouldValidate: true });
  };

  const addOption = () => {
    append({ value: "" });
  };

  const handleDeleteOption = (index) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const submitForm = async (form) => {
    setIsLoading(true);

    let option = null;

    if (form.options.some((option) => option.value)) {
      option = Object.fromEntries(
        form.options.map((option, index) => [index, option.value])
      );
    }

    const params = {
      event_id: event_id,
      description: form.name !== question?.description ? form.name : "",
      field_type_id: +form.type,
      field_list: option ? { option } : null,
      required: !!form.required,
      order_field: question ? question?.order_field : order_field,
    };
    let promise;
    if (!question) {
      promise = New_api.post(`/event-forms`, params);
    } else {
      promise = New_api.put(`/event-forms/${question.id}`, params);
    }
    await promise
      .then(() => {
        setIsReload(true);
        toast.success(
          !question
            ? "Campo adicionado com sucesso"
            : "Alteração realizada com sucesso"
        );
      })
      .catch(() => {
        toast.error(
          !question
            ? "Ocorreu um erro ao adicionar o campo"
            : "Ocorreu um erro ao alterar o campo"
        );
      })
      .finally(() => {
        setIsReload(false);
        setIsLoading(false);
        closeModal();
        reset();
      });
  };

  const switchStyles = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor: errors.type?.message ? "#dc3545" : "#ced4da",
      borderRadius: "0.375rem",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
      display: "flex",
      alignItems: "center",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        contentLabel="CustomFieldsModal"
        className="shadow side-modal"
      >
        <div className="card-header">
          <h4 className="card-header-title">
            {question ? "Editar o" : "Adicionar"} campo personalizado
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="card-body">
          <Form onSubmit={handleSubmit(submitForm)}>
            <Form.Row>
              <Col md="12">
                <h5 className="modal-header-text">Campo Personalizado</h5>
                <FormField
                  classNameLabel="label-input"
                  label="Título da pergunta* (Exemplo: Como ficou sabendo desse evento?) Máximo: 140 caracteres"
                  errorMessage={errors.name?.message}
                  controlId="validationName"
                >
                  <Form.Control
                    {...register("name")}
                    name="name"
                    type="text"
                    placeholder="Insira o rótulo do campo"
                    className="input"
                    isInvalid={errors.name?.message}
                    maxLength={140}
                  />
                </FormField>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="label-input"
                  label="Tipo*"
                  errorMessage={errors.type?.message}
                  controlId="validationType"
                >
                  <IconInput
                    name="type"
                    styles={switchStyles}
                    className="input"
                    icons={{
                      text: Text,
                      checkbox: Checkbox,
                      textarea: Paragraph,
                      radiobutton: Circlebox,
                    }}
                    onSelect={onSelect}
                    options={formOptions}
                    defaultValue={selectedValue}
                    isInvalid={errors.type?.message}
                  />
                  {!!errors.type?.message && (
                    <h6 className="mt-1">
                      <small className="text-danger">
                        {errors.type.message}
                      </small>
                    </h6>
                  )}
                </FormField>
              </Col>
            </Form.Row>
            {(selectedType === "checkbox" ||
              selectedType === "radiobutton") && (
              <Fragment>
                <Form.Row>
                  <Col md="12">
                    <FormField
                      classNameLabel="label-input"
                      label="Insira as opções*"
                      errorMessage={errors.type?.message}
                      controlId="validationOptions"
                    >
                      {fields.map((_, index) => (
                        <div
                          key={index}
                          className="mt-2 align-items-center row justify-content-start p-0 m-0"
                        >
                          <div className="col-auto pl-md-0">
                            <Form.Control
                              type={
                                selectedType === "checkbox"
                                  ? "checkbox"
                                  : "radio"
                              }
                              className="checkbox-setup"
                              disabled
                            />
                          </div>
                          <div className={`close-area p-0 col-md-11 col-10`}>
                            <Form.Control
                              {...register(`options.${index}.value`)}
                              type="text"
                              placeholder="Insira a opção"
                              name={`options.${index}.value`}
                              isInvalid={
                                errors?.options?.[index]?.value?.message
                              }
                            />
                            {!!errors?.options?.[index]?.value?.message && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.options?.[index]?.value?.message}
                              </Form.Control.Feedback>
                            )}
                            <CloseButton
                              className="close-btn"
                              onClick={() => handleDeleteOption(index)}
                            />
                          </div>
                        </div>
                      ))}
                      <Form.Row className="mt-3">
                        <button
                          type="button"
                          onClick={addOption}
                          className="custom-button d-flex align-items-center justify-content-start"
                        >
                          <img src={Add} alt="AddIcon" className="mr-1" /> Nova
                          Opção
                        </button>
                      </Form.Row>
                    </FormField>
                  </Col>
                </Form.Row>
              </Fragment>
            )}
            <Form.Row>
              <Col md="12">
                <h5 className="modal-header-text">Configurações adicionais</h5>
              </Col>
            </Form.Row>
            <Form.Row className="mt-1">
              <Col md="auto" className="d-flex align-items-center">
                <Form.Control
                  {...register("required")}
                  name="required"
                  type="checkbox"
                  className="checkbox-setup mb-0"
                  isInvalid={errors.required?.message}
                />
                <div>
                  <p className="mb-0 ml-2 checkbox-label">
                    Marcar campo como obrigatório
                  </p>
                </div>
              </Col>
            </Form.Row>
            {/* <hr />
                        <Form.Row>
                            <Col md="auto" className="d-flex align-items-center">
                                <Form.Control
                                    {...register("instructionsExists")}
                                    name="instructionsExists"
                                    type="checkbox"
                                    className="checkbox-setup"
                                    isInvalid={errors.instructionsExists?.message}
                                    onChange={() => setInstructionsExists(!instructionsExists)}
                                />
                                <p className="mb-0 ml-2 checkbox-label">
                                    Incluir texto de suporte no campo
                                </p>
                            </Col>
                        </Form.Row>

                        {!!instructionsExists && (
                            <Fragment>
                                <Form.Row>
                                    <Col md="12">
                                        <FormField
                                            classNameLabel="label-input"
                                            label="Texto de suporte"
                                            errorMessage={errors.instructions?.message}
                                            controlId="validationInstructions"
                                        >
                                            <Form.Control
                                                {...register("instructions")}
                                                placeholder="Insira o rótulo do campo"
                                                name="type"
                                                type="text"
                                                className="input"
                                                isInvalid={errors.instructions?.message}
                                            />
                                        </FormField>
                                    </Col>
                                </Form.Row>
                            </Fragment>
                        )} */}
            <div className="row d-flex justify-content-end align-items-end submit-button-container">
              <div className="col-12 mt-4 col-md-4">
                <CustomButtonSpinner
                  customStyle={"btn btn-primary btn-height w-100"}
                  type="submit"
                  isLoading={isLoading}
                  text={question ? "Salvar" : "Adicionar campo"}
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
}

export default CustomFieldsModal;
