import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

const BadgeStatusColor = ({ status }) => {
  const badgeColor = useMemo(() => {
    const statusMap = {
      offline: "badge-dark",
      adiado: "badge-warning",
      encerrado: "badge-secondary",
      cancelado: "badge-danger",
    };

    return statusMap[status] || "badge-success";
  }, [status]);

  return badgeColor;
};

const BadgeStatusText = ({ status }) => {
  const { t, i18n } = useTranslation();
  const badgeText = useMemo(() => {
    const statusMap = {
      offline: t("global:statusList.offline"),
      adiado: t("global:statusList.postponed"),
      encerrado: t("global:statusList.closed"),
      cancelado: t("global:statusList.canceled"),
    };

    return statusMap[status] || t("global:statusList.active");
    // eslint-disable-next-line
  }, [status, i18n.language]);

  return badgeText;
};

function EventStatus({ status }) {
  return (
    <Fragment>
      <div>
        <div
          style={{ maxHeight: "21px", fontWeight: "bold" }}
          className={`badge badge-pill ${BadgeStatusColor({ status })}`}
        >
          {BadgeStatusText({ status })}
        </div>
      </div>
    </Fragment>
  );
}

export default EventStatus;
