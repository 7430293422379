import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import React, { useCallback, useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import { ToastContainer } from "react-toastr";
import Api from "services/api";
import "./styles.css";
import Swal from "sweetalert2";

export default function BankAccountInfosModal(props) {
  const [bankTypeSub, SetBankTypeSub] = useState([]);
  const [bankType, setBankType] = useState("");
  const [validabankType /*setValidaBankType*/] = useState(false);
  const [tipo, setTipo] = useState("");
  const [nome, setNome] = useState("");
  const [documento, setDocumento] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [digitoconta, setDigitoConta] = useState("");
  const [validanome /*setValidaNome*/] = useState(false);
  const [validadocumento /*setValidaDocumento*/] = useState(false);
  const [validaagencia /*setValidaAgencia*/] = useState(false);
  const [validaconta /*setValidaConta*/] = useState(false);
  const [validacontadigito /*setValidacontadigito*/] = useState(false);

  const fetchData = useCallback(async () => {
    await Api.get("lista/bancos").then((res) => {
      let bancos = [];
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        let data = {
          value: element.code,
          name: element.code + "-" + element.name,
        };
        bancos.push(data);
      }
      SetBankTypeSub(bancos);
    }).catch(() => {
      Swal.fire({
        title: 'Ops!',
        icon: 'error',
        text: 'Ocorreu um erro interno, por favor tente novamente',
        confirmButtonText: 'Ok'
      });
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fecharModal = (e) => {
    e.preventDefault();
    props.onClose();

    document.getElementById("full-modal").classList.add("hide");
  };

  const registra = (e) => {
    e.preventDefault();

    var inputValidados = document.getElementsByClassName("form-control");
    var qty;
    var inputError = 0;

    for (qty = 0; qty < inputValidados.length; qty++) {
      var thisInput = inputValidados[qty];
      if (thisInput.value.length === 0) {
        thisInput.classList.add("is-invalid");
        inputError = 1;
      } else {
        thisInput.classList.remove("is-invalid");
      }
    }

    if (inputError === 1) {
      Swal.fire({
        title: "Erro",
        icon: "error",
        text: "Favor preencher todos os campos.",
      });

      return;
    }

    let idBanco = bankType;
    let dados = {
      nome,
      documento,
      agencia,
      conta,
      tipo,
      idBanco,
      digitoconta,
    };

    props.onFinish(dados);
  };

  // BLOQUEAR DÍGITOS NUMÉRICOS
  function checkNumbers(e) {
    var inputValue = typeof e.which == "number" ? e.which : e.keyCode;
    if (
      !(inputValue >= 65 && inputValue <= 120) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      // alert("Typed character: " + String.fromCharCode(inputValue));
      e.preventDefault();
    }
  }

  // FUNÇÃO MUDA COR DO SELECT E SETA VALOR
  function changeTipo(value) {
    setTipo(value);
    document.getElementById("tipo").style.color = "#12263f";
  }

  return (
    <>
      <ToastContainer className="toast-top-right" />

      <div id="full-modal" className={props.show ? "" : "hide"}>
        <div id="transfermodal-bg"></div>
        <div className="side-modal" id="transfer-modal">
          <div className="card-header">
            <h4 className="card-header-title">
              Informações bancárias para estorno da venda
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => fecharModal(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <div className="alert alert-danger">
              <span className="icon-circle-sm">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
              <span>
                Insira as informações para devolver o valor da compra na conta
                bancária do comprador
              </span>
            </div>

            <div className="needs-validation">
              <div className="form-row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="validationTooltip02">Nome Completo</label>
                  <input
                    type="text"
                    className={
                      validanome ? "form-control is-invalid" : "form-control"
                    }
                    id="nome"
                    name="nome"
                    value={nome}
                    placeholder="Nome Completo"
                    required=""
                    maxlength={30}
                    onKeyPress={(e) => checkNumbers(e)}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="validationTooltip02">Documento</label>
                  <CpfCnpj
                    className={
                      validadocumento
                        ? "form-control mask_document is-invalid"
                        : "form-control mask_document"
                    }
                    placeholder="Digite um CPF ou CNPJ"
                    type="tel"
                    value={documento}
                    onChange={(event, type) => {
                      setDocumento(event.target.value);
                      // setMask(type === "CPF");
                    }}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12 mb-3" data-select2-id="8">
                  <label htmlFor="bank_code" className="d-none d-md-block">
                    Banco
                  </label>
                  <SelectSearch
                    options={bankTypeSub}
                    value={bankType}
                    onChange={(value) => setBankType(value)}
                    search={true}
                    placeholder="Selecione o banco"
                    className={
                      validabankType
                        ? "select-search is-invalid"
                        : "select-search mb-0"
                    }
                  />
                </div>
                <div className="col-5 col-md-2 mb-3">
                  <label htmlFor="validationTooltip02">Agência</label>
                  <input
                    type="number"
                    className={
                      validaagencia
                        ? "form-control mask_numero is-invalid"
                        : "form-control mask_numero"
                    }
                    id="agencia"
                    name="agencia"
                    placeholder="Sem dígito"
                    value={agencia}
                    required=""
                    onChange={(e) => setAgencia(e.target.value)}
                  />
                </div>
                <div className="col-5 col-md-3 mb-3">
                  <label htmlFor="validationTooltipUsername">Conta</label>
                  <input
                    type="number"
                    className={
                      validaconta
                        ? "form-control mask_numero is-invalid"
                        : "form-control mask_numero"
                    }
                    id="conta"
                    name="conta"
                    value={conta}
                    placeholder="Sem dígito"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required=""
                    onChange={(e) => setConta(e.target.value)}
                  />
                </div>
                <div className="col-2 mb-3">
                  <label htmlFor="validationTooltipUsername">Digito</label>
                  <input
                    type="number"
                    className={
                      validacontadigito
                        ? "form-control mask_numero is-invalid"
                        : "form-control mask_numero"
                    }
                    id="conta"
                    name="conta"
                    value={digitoconta}
                    placeholder=""
                    aria-describedby="validationTooltipUsernamePrepend"
                    required=""
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 1))
                    }
                    onChange={(e) => setDigitoConta(e.target.value)}
                  />
                </div>
                <div className="col-md-5 mb-3">
                  <label htmlFor="type" className="d-none d-md-block">
                    Tipo de Conta
                  </label>
                  <select
                    className="form-control select2-hidden-accessible"
                    id="tipo"
                    name="tipo"
                    data-toggle="select"
                    required=""
                    data-select2-id="tipo"
                    tabIndex="-1"
                    aria-hidden="true"
                    onChange={(e) => changeTipo(e.target.value)}
                  >
                    <option value="" default>
                      Selecione o tipo de conta
                    </option>
                    <option value="checking">Corrente</option>
                    <option value="savings">Poupança</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-2">
                  <button
                    disabled={props.isActionDisable}
                    className="btn btn-secondary"
                    onClick={(e) => registra(e)}
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faFileAlt} className="mr-2" />{" "}
                    {props.isActionDisable
                      ? "Processando..."
                      : "Estornar compra"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
