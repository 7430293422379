//Common
import { useOnClickOutside } from 'App';
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastr';
import Swal from "sweetalert2";
import Select from "react-select";

//Providers
import Api from "services/api.js";

let container;

const ModalEquipment = (props) => {

    // Click outside
    let ref = useRef();
    useOnClickOutside(ref, () => { if (props.status === 'modal-show') { close() } });

    const [cat, setCat] = useState([]);

    const [equip, setEquip] = useState({
        id: "",
        categorie_id: "",
        name: "",
        patrimony: "",
        serial: "",
        description: ""
    });

    const updateEquip = e => {
        setEquip({
            ...equip,
            [e.target.name]: e.target.value
        });
    };

    const updateCategory = e => {
        setEquip({
            ...equip,
            categorie_id: e.value
        })
    }

    const registerEquipment = e => {
        // e.preventDefault();
        if (props.id) {

            Api.post("equipments/edit", equip).then(res => {

                container.success("Equipamento editado com sucesso!")
                close();

            }).catch(err => {

                container.error("Erro ao editar equipamento");

            });
        } else {

            Api.post("equipments/create", equip).then(res => {

                container.success("Equipamento cadastrado com sucesso!")
                close();

            }).catch(err => {

                container.error("Erro ao criar equipamento");

            });
        };
    };

    useEffect(() => {

        Api.get('equipments/categories').then((res) => {
            setCat(res.data.data);
        }).catch(() => {
            Swal.fire({
              title: 'Ops!',
              icon: 'error',
              text: 'Ocorreu um erro interno, por favor tente novamente',
              confirmButtonText: 'Ok'
            })
          });

        if (props.id) {
            Api.get(`equipments/get/${props.id}`).then((res) => {
                setEquip({
                    id: res.data.data.id,
                    categorie_id: res.data.data.categorie_id ? res.data.data.categorie_id : "",
                    name: res.data.data.name ? res.data.data.name : "",
                    patrimony: res.data.data.patrimony ? res.data.data.patrimony : "",
                    serial: res.data.data.serial ? res.data.data.serial : "",
                    description: res.data.data.description ? res.data.data.description : ""
                })
            }).catch(() => {
                Swal.fire({
                  title: 'Ops!',
                  icon: 'error',
                  text: 'Ocorreu um erro interno, por favor tente novamente',
                  confirmButtonText: 'Ok'
                })
              });
        }
    }, [props.id]);

    const close = () => {
        setEquip({
            id: "",
            categorie_id: "",
            name: "",
            patrimony: "",
            serial: "",
            description: ""
        });
        props.callback('modal-hidden')
    };

    return (
        <>
            <div id="venda-detalhe" ref={ref} className={"shadow side-modal " + props.status} role="dialog">
                <div className="card-header">
                    <h4 className="card-header-title">{props.id ? 'Editar Equipamento' : 'Cadastrar Equipamento'}</h4>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => close(e)}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card-body ">
                    <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />
                    {/* <div className="card-header container-register-user"><h4 className="card-header-title">1. Informações básicas.</h4></div> */}
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <label htmlFor="categorie_id">Categoria</label>
                            <Select
                            value={equip.categorie_id ? { value: equip.categorie_id, label: '' } : null}
                            options={cat.map((item) => ({ value: item.id, label: item.name }))}
                            name="categorie_id"
                            onChange={updateCategory}
                            getOptionLabel={(option) => cat.find((item) => item.id === option.value)?.name || ''}
                            placeholder="Categoria"
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={equip.name}
                                onChange={updateEquip}
                                placeholder="Nome do Equipamento"
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6">
                            <label htmlFor="patrimony">Serial - Patrimonio;</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Serial do Equipamento"
                                name="patrimony"
                                value={equip.patrimony}
                                onChange={updateEquip}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="serial">Serial - Aplicativo (uuid) </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Serial (uuid)"
                                name="serial"
                                value={equip.serial || ''}
                                onChange={updateEquip}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <label htmlFor="description">Anotações</label>
                            <textarea
                                className="form-control"
                                name="description"
                                placeholder="Anotações do equipamento"
                                value={equip.description}
                                onChange={updateEquip}
                            ></textarea>
                        </div>
                    </div>
                    <div className="box-button">
                        <button onClick={registerEquipment} className="btn btn-primary col-md-6 c3">
                            Salvar informações
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalEquipment;
