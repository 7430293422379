import { faCheckCircle, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnClickOutside } from 'App';
import html2canvas from 'html2canvas';
import React, { useRef } from 'react'
import { formataDataHora } from '..';
import './styles.css';

var pdfMake = require('pdfmake/build/pdfmake.js');

const Comprovante = (props) => {
	

	// Click outside
	let ref = useRef();
	useOnClickOutside(ref, () => { if (props.status === 'show') { close() } });

	function close() {
		props.callback('hidden')
	}

	const printToPdf = () => {
		const options = { scale: 1 }
		html2canvas(document.getElementById("print_to_pdf"), options).then(canvas => {
			var data = canvas.toDataURL();
			var pdfExportSetting = {
				content: [
					{
						image: data,
						width: 500,
						top: 0
					}
				]
			};
			pdfMake.createPdf(pdfExportSetting).download(`comprovante-${props.data.codIngresso}.pdf`);
		});
	};


	return (
		<>
			<div className={`modal-background ${props.status}`}>
				<div className="modal-center" ref={ref}>
					<div className="d-flex justify-content-between mb-2">
						<button className="btn-table" onClick={printToPdf}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon></button>
						<button className="close" onClick={close}>&times;</button>
					</div>
					<div id="print_to_pdf">
						<div className="receipt-header">
							<img
								className="receipt-logo"
								src="/logo_web.svg"
								alt="logo da empresa"
							></img>
						</div>
						<div className="receipt-check">
							<FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>&nbsp; checkin realizado
						</div>
						<div className="receipt-body">
							<span className="receipt-title-1">
								Data / Hora de Acesso:
							</span>
						</div>
						<div className="receipt-body">
							<span className="receipt-text-1">
								{formataDataHora(props.data?.diaCheckIn)}
								
							</span>
						</div>
						<div className="receipt-body">
							<span className="receipt-title-1">
								Local de Acesso
							</span>
						</div>
						<div className="receipt-body">
							<span className="receipt-text-1">
								{props.data?.localEvento}
								
							</span>
						</div>
						<div className="receipt-border"></div>
						<div className="mt-4">
							<span className="receipt-title-1">
								Favorecido
							</span>
						</div>
						<div>
							<span className="receipt-text-2">
								{props.data?.participante}
								
							</span>
						</div>
						<div className="d-flex justify-content-between mt-4">
							<div>
								<span className="receipt-title-1">
									Código da Compra
								</span>
							</div>
							<div>
								<span className="receipt-title-1">
									Código do ingresso
								</span>
							</div>
						</div>
						<div className="d-flex justify-content-between">
							<div>
								<span className="receipt-text-2">
									{props.data?.codCompra}
								</span>
							</div>
							<div>
								<span className="receipt-text-2">
									{props.data?.codIngresso}
									
								</span>
							</div>
						</div>
						<div className="receipt-border mt-4"></div>
						<div className="mt-4">
							<span className="receipt-title-1">
								E-mail utilizado para compra:
							</span>
						</div>
						<div>
							<span className="receipt-text-2">
								{props.data?.emailCompra}
							</span>
						</div>
						<div className="receipt-border mt-4"></div>
						<div className="mt-4">
							<span className="receipt-title-1">
								ID da transação:
							</span>
						</div>
						<div>
							<span className="receipt-text-2">
								{props.data?.trasactionId}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Comprovante;