import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
import pt from "date-fns/locale/pt-BR";

const locales = {
	'en-US': en,
	'es-ES': es,
	'pt-BR': pt,
}

/**
 *
 * @param {'en-US' | 'es-ES' | 'pt-BR'} language
 * @param {boolean} showTime
 */
export const datepickerHelper = (language,showTime = false) => {
	const hourFormat = language === "en-US" ? "h:mm aa" : "HH:mm";
	const date = language === "en-US" ? "MM/dd/yyyy" : "dd/MM/yyyy";
  const format = showTime ? `${date} ${hourFormat}` : date;
  const locale = locales[language]
  return {
    dateFormat: format,
    locale,
    hourFormat,
  };
};
