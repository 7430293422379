import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function SectorByFreeTickets({ sectors }) {
  const { t } = useTranslation("global");

  const totalCourtesies = sectors.reduce(
    (sum, courtesies) => sum + courtesies.qty,
    0
  );

  return (
    <Card>
      <Card.Header className="new-sales-card-title">
        {t("courtesiesBySector")}
      </Card.Header>
      <Card.Body className="new-sales-header-card">
        <div className="d-flex justify-content-between">
          <span className="new-sales-title-primary">
            {t("courtesiesTotal")}
          </span>
          <span className="new-sales-value-primary">{totalCourtesies}</span>
        </div>
      </Card.Body>
      <Card.Body className="new-detailed-sales">
        {sectors.map((courtesies) => (
          <div
            key={courtesies.id}
            className="d-flex justify-content-between mb-2"
          >
            <span className="new-sector-title">{courtesies.name}</span>
            <span className="new-sector-quantity-information">
              {courtesies.qty}
            </span>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
}
