import React, { useEffect } from "react";
import New_api from "services/new-api";

const currencySymbol = {
  BRL: "R$",
  USD: "$",
  EUR: "€",
};

/**
 * @typedef {Object} EventContextType
 * @property {string} eventId - The ID of the event.
 * @property {import("./type").EventDetails} eventDetails - Details of the event.
 * @property {'BRL' | 'USD' | 'EUR'} currency - Details of the event.
 * @property {'R$' | '$' | '€'} currencySymbol - Details of the event.
 * @property {boolean} isLoading - Indicates whether data is loading.
 */

/**
 * @type {EventContextType}
 */
const context = React.createContext({});
const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY;

export const CurrencyEventProvider = ({ children }) => {
  const [eventId, setEventId] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [eventDetails, setEventDetails] = React.useState({
    created_at: new Date(),
  });
  const [currency, setCurrency] = React.useState(defaultCurrency);

  const getEventDetails = async (id) => {
    setIsLoading(true);
    try {
      const { data: { currency, created_at } } = await New_api.get(`/events/${id}`, { params: { fields: ['currency', 'created_at']}});
     
      if (currency) {
        setCurrency(currency);
      }
      
      if (created_at) {
        setEventDetails({ created_at: new Date(created_at) });
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const event_id = urlParams.get("event_id");
    if (event_id !== eventId) {
      setEventId(event_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (eventId) {
      getEventDetails(eventId);
    }
  }, [eventId]);
  return (
    <context.Provider
      value={{
        eventId,
        isLoading,
        currency,
        eventDetails,
        currencySymbol: currencySymbol[currency],
      }}
    >
      {children}
    </context.Provider>
  );
};

/**
 * Custom hook to use event context.
 * @returns {EventContextType} The event context.
 */
export const useCurrencyEventContext = () => {
  const currencyEventContext = React.useContext(context);
  return currencyEventContext;
};
