import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import api from 'services/api';

// eslint-disable-next-line no-unused-vars
let container;

export default function NewPlanosPagamentos(props) {
  const [name, setName] = useState('');
  const [paymentplan1, setPaymentplan1] = useState(0);
  const [paymentplan2, setPaymentplan2] = useState(0);
  const [paymentplan3, setPaymentplan3] = useState(0);
  const [paymentplan4, setPaymentplan4] = useState(0);
  const [paymentplan5, setPaymentplan5] = useState(0);
  const [paymentplan6, setPaymentplan6] = useState(0);
  const [paymentplan7, setPaymentplan7] = useState(0);
  const [paymentplan8, setPaymentplan8] = useState(0);
  const [paymentplan9, setPaymentplan9] = useState(0);
  const [paymentplan10, setPaymentplan10] = useState(0);
  const [paymentplan11, setPaymentplan11] = useState(0);
  const [paymentplan12, setPaymentplan12] = useState(0);
  const [juros, setJuros] = useState(true);
  const [edita, setEdita] = useState(false);
  const [planoId, setPlanoId] = useState(null);

  const carregaPlano = (plano) => {
    setEdita(true);
    setPlanoId(plano.id);
    setName(plano.name);
    if(plano.compound_interest === 1){
      setJuros(true);
    }else{
      setJuros(false);
    }
    setPaymentplan1(plano['1x'] / 100);
    setPaymentplan2(plano['2x'] / 100);
    setPaymentplan3(plano['3x'] / 100);
    setPaymentplan4(plano['4x'] / 100);
    setPaymentplan5(plano['5x'] / 100);
    setPaymentplan6(plano['6x'] / 100);
    setPaymentplan7(plano['7x'] / 100);
    setPaymentplan8(plano['8x'] / 100);
    setPaymentplan9(plano['9x'] / 100);
    setPaymentplan10(plano['10x'] / 100);
    setPaymentplan11(plano['11x'] / 100);
    setPaymentplan12(plano['12x'] / 100);
  };

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if(props.plano != null){
      carregaPlano(props.plano);
    }
      let obj = document.getElementsByClassName('side-modal');
    // eslint-disable-next-line react/prop-types
    if(props.visivel){
      obj[0].classList.remove('side-modal-hidden');
    }else{
      obj[0].classList.add('side-modal-hidden');
    }

    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
  }, [props.plano, props.visivel]);

  const limpaDados = () => {
    setEdita(false);
    setPlanoId(null);
    setJuros(true);
    setName('');
    setPaymentplan1(0);
    setPaymentplan2(0);
    setPaymentplan3(0);
    setPaymentplan4(0);
    setPaymentplan5(0);
    setPaymentplan6(0);
    setPaymentplan7(0);
    setPaymentplan8(0);
    setPaymentplan9(0);
    setPaymentplan10(0);
    setPaymentplan11(0);
    setPaymentplan12(0);
    props.retorno(false);
    let obj = document.getElementsByClassName('side-modal');
    obj[0].classList.remove('side-modal-visible');
  };

  const fechaModal=()=>{
    limpaDados();
  }

  const salvaPlano = (e) => {
    e.preventDefault();
    // setSalvando(true);
    // setCarregando(true);
    const dados = {
      id: planoId,
      nome: name,
      parcelamento1: paymentplan1,
      parcelamento2: paymentplan2,
      parcelamento3: paymentplan3,
      parcelamento4: paymentplan4,
      parcelamento5: paymentplan5,
      parcelamento6: paymentplan6,
      parcelamento7: paymentplan7,
      parcelamento8: paymentplan8,
      parcelamento9: paymentplan9,
      parcelamento10: paymentplan10,
      parcelamento11: paymentplan11,
      parcelamento12: paymentplan12,
      juros:juros
    };
    api.post('/paymentplans', dados).then((res) => {
      container.success(res.data.message);;
      limpaDados();
    }).catch((err) => {
      container.error(err.message);
    });
  };

  return (
    <>
      <div id="alert-messages" className="shadow side-modal side-modal-hidden">
        <div className="card-header">
          <h4 className="card-header-title">
            <FontAwesomeIcon icon={faCreditCard} className="ml-2 mr-2" />
            {' '}
            Criar / atualizar plano
          </h4>
          <button onClick={()=>fechaModal()} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        </div>
        <div className="col-12 mt-4">

          <ToastContainer ref={(ref) => (container = ref)} className="toast-top-right" />

          <div className="form-row">
            <div className="form-group col-lg-6 col-xl-6">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Nome do plano</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className='form-group col-lg-6 col-xl-6'>
              <div className="input-group mt-4 mb-3">
                <div className="input-group-text">
                  <input className="form-check-input" type="radio" name='juros' value="1" onChange={e=>setJuros(true)}  style={{marginLeft: '-0.35rem'}}
                         aria-label="Checkbox for following text input" checked={juros}/>
                </div>
                Juros Compostos
                {' '}
                <div className="input-group-text">
                  <input className="form-check-input" type="radio" name='juros' value="0" onChange={e=>setJuros(false)} style={{marginLeft: '-0.45rem'}}
                         aria-label="Checkbox for following text input" checked={!juros}/>
                </div>
                Juros Simples
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>uma vezes (1x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan1}
                  onChange={(event) => setPaymentplan1(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>duas vezes (2x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan2}
                  onChange={(event) => setPaymentplan2(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              <label>três vezes (3x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan3}
                  onChange={(event) => setPaymentplan3(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              <label>quatro vezes (4x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan4}
                  onChange={(event) => setPaymentplan4(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6 col-lg-4 col-xl-3">
              <label>cinco vezes (5x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan5}
                  onChange={(event) => setPaymentplan5(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>seis vezes (6x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan6}
                  onChange={(event) => setPaymentplan6(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>sete vezes (7x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan7}
                  onChange={(event) => setPaymentplan7(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>oito vezes (8x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan8}
                  onChange={(event) => setPaymentplan8(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>nove vezes (9x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan9}
                  onChange={(event) => setPaymentplan9(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>dez vezes (10x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan10}
                  onChange={(event) => setPaymentplan10(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>onze vezes (11x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan11}
                  onChange={(event) => setPaymentplan11(event.target.value)}
                />
              </div>
            </div>
            <div className="form-group col-6 col-lg-4 col-xl-3">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>doze vezes (12x)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text inputs" id="inputTags">%</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  value={paymentplan12}
                  onChange={(event) => setPaymentplan12(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="box-button">
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              className="btn btn-primary col-md-4 c3"
              onClick={e=>salvaPlano(e)}
            >
              {edita ? 'Atualizar plano' : 'Criar novo plano'}
            </button>
          </div>

        </div>
      </div>
    </>
  );
}
