import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

ButtonDashed.propTypes = {
  svg: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.node.isRequired,
};

export default function ButtonDashed({ className, svg, text, ...restProps }) {
  return (
    <button {...restProps} className={`button-dashed ${className}`}>
      {svg && <img src={svg} alt="svg-test" />}
      {text}
    </button>
  );
}
