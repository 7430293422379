import DotNavigation from "components/dot-navigation";
import React, { Fragment, useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const CustomCarousel = ({ children, elements }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleNavClick = (e, selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Fragment>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={null}
        indicators={false}
        controls={false}
      >
        {React.Children.map(children, (child, cardIndex) => (
          <Carousel.Item key={cardIndex}>{child}</Carousel.Item>
        ))}
      </Carousel>
      <DotNavigation
        elements={elements}
        onClick={handleNavClick}
        selectedIndex={index}
      />
    </Fragment>
  );
};

export default CustomCarousel;
