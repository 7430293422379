import React, { useState, useEffect } from 'react'
import Api from 'services/api';
import SelectSearch from 'react-select-search';
import Swal from "sweetalert2";

const EquipValidation = () => {

    const [users, setUsers] = useState([]);
    const [eventsSub, setEventsSub] = useState([]);
    const [patrimony, setPatrimony] = useState('');
    const [data, setData] = useState({
        user: null,
        patrimony: null,
        event: null
    })

    const [option, setOption] = useState(1);
    const [event, setEvent] = useState('');

    const [filter, setFilter] = useState('')

    const updatePatrimony = e => {
        setPatrimony(e.target.value);
    }

    const registerEquipment = () => {
        if (option === 1) {
            if (patrimony === '' || filter === '') {
                Swal.fire({
                    title: "Atenção!",
                    icon: "warning",
                    text: "Digite o patrimônio e selecione o usuário"
                });
            } else {
                setData({
                    ...data,
                    user: filter,
                    patrimony: patrimony,
                    event: event
                });
            }
        } else {
            if (patrimony === '') {
                Swal.fire({
                    title: "Atenção!",
                    icon: "warning",
                    text: "Digite o patrimônio"
                });
            } else {
                setData({
                    ...data,
                    patrimony: patrimony
                });
            }
        }

    }

    useEffect(() => {

        async function fetchEquipment() {
            if (option === 1) {
                if (data.patrimony !== null && data.user !== null) {
                    await Api.post('equipments/userSent', data).then(res => {
                        if (res.status === 202 || res.status === 203 || res.status === 210) {
                            Swal.fire({
                                title: "Atenção!",
                                icon: "warning",
                                text: res.data.message
                            });
                        } else {
                            Swal.fire({
                                title: "Sucesso",
                                icon: "success",
                                text: res.data.message
                            });
                        }
                    }).catch(() => {
                        Swal.fire({
                          title: 'Ops!',
                          icon: 'error',
                          text: 'Ocorreu um erro interno, por favor tente novamente',
                          confirmButtonText: 'Ok'
                        })
                      })
                }
            } else {
                if (data.patrimony !== null) {
                    await Api.post('equipments/equipReturn', data).then(res => {
                        if (res.status === 202 || res.status === 203 || res.status === 210) {
                            Swal.fire({
                                title: "Atenção!",
                                icon: "warning",
                                text: res.data.message
                            });
                        } else {
                            Swal.fire({
                                title: "Sucesso",
                                icon: "success",
                                text: res.data.message
                            });
                        }
                    }).catch(() => {
                        Swal.fire({
                          title: 'Ops!',
                          icon: 'error',
                          text: 'Ocorreu um erro interno, por favor tente novamente',
                          confirmButtonText: 'Ok'
                        })
                      })
                }
            }
    
            ;
        }

        fetchEquipment();
    }, [option,data])

// eslint-disable-next-line
    let options = [];
		// eslint-disable-next-line
    let optionsEvents = [];

    

    useEffect(() => {

        async function fetchData() {

            const resultUsers = await Api.get('listUsers');
            setUsers(resultUsers.data);
    
            resultUsers.data.forEach(item => {
							
                 options.push({ name: item.first_name + ' ' + item.last_name, value: `${item.id}` });
            })
            setUsers(options);
    
            const resultEvents = await Api.get('listEvents');
    
            resultEvents.data.forEach(item => {
                optionsEvents.push({ name: item.name, value: `${item.id}` });
            })
            setEventsSub(optionsEvents);
        }

        fetchData();
				// eslint-disable-next-line
    }, [options, optionsEvents])


    return (
        <>
            <div className="filters p-4">
                <div className="line-top"></div>
                <div className="selector">
                    <div className="custom-control custom-radio mt-2" data-tip="custom-control custom-radio">
                        <label className="radio-inline ml-2">
                            <input
                                type="radio"
                                className="custom-control-input"
                                name="option"
                                id="event"
                                value={1}
                                defaultChecked={option}
                                onChange={e => setOption(1)}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor="event"
                            >
                                Enviar
                            </label>
                        </label>
                        <label className="radio-inline" style={{ marginLeft: '2.5rem' }}>
                            <input
                                type="radio"
                                className="custom-control-input"
                                name="option"
                                id="stock"
                                value={2}
                                onChange={e => setOption(2)}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor="stock"
                            >
                                Retornar
                            </label>
                        </label>
                    </div>
                </div>
                {option === 1 ? <div className="row mt-3">
                    <div className="col-md-3">
                        {option === 2 ? <SelectSearch key="user" options={users} value={filter} onChange={(value) => setFilter(value)} defaultValue={0}
                            search={true} name="user" placeholder="Selecione o usuário" disabled={true} /> : <SelectSearch key="user" options={users} value={filter} onChange={(value) => setFilter(value)} defaultValue={0}
                                search={true} name="user" placeholder="Selecione o usuário" />}

                    </div>
                    <div className="col-md-3">
                        <SelectSearch key="event" options={eventsSub} value={event} onChange={(value) => setEvent(value)} defaultValue={0} search={true} name="event" placeholder="Selecione o evento" />
                    </div>
                    <div className="col-md-4">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Digite o patrimônio"
                            value={patrimony}
                            onChange={updatePatrimony}
                        />
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-primary" onClick={registerEquipment} >
                            Registrar
                        </button>
                    </div>
                </div> : <div className="row mt-3">
                        <div className="col-md-10">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Digite o patrimônio"
                                value={patrimony}
                                onChange={updatePatrimony}
                            />
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-primary" onClick={registerEquipment} >
                                Registrar
                            </button>
                        </div>
                    </div>}

            </div>
        </>
    )
}

export default EquipValidation;