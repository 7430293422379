import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import Card from "components/card";
import ContainerFluid from "components/containers/container-fluid";
import Input from "components/input";
import DivFormGroup from "components/div-form-group";
import CustomButton from "components/custom-button";
import api from "services/api";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";

const config = {
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
  ],
};

export default function RegisterTerms() {
  const { t } = useTranslation();
  const [terms, setTerms] = useState({
    text: "",
    title: "",
    summary: "",
    active: true,
    version: "",
    start_date: new Date(),
  });

  const urlParams = new URLSearchParams(window.location.search);
  const term_id = urlParams.get("term_id");

  const [loadingTerms, setLoadingTerms] = useState(false);
  const [termURL, setTermURL] = useState("");
  const [fileName, setFileName] = useState("");

  const uploadImage = (event) => {
    const formData = new FormData();
    setFileName("");
    setLoadingTerms(true);

    formData.append("pdf-file", event.target.files[0]);

    api
      .post("uploads?local=termos&type=pdf-file", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        setTermURL(res.data.url);
        setFileName(event.target.files[0].name);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setLoadingTerms(false);
      });
  };

  const actions = () => {
    if (!terms.title) {
      Swal.fire({
        text: "Atencão! Título do termo é obrigatório!",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      if (term_id) {
        api
          .put(`/terms/${term_id}`, {
            title: terms.title,
            text: terms.text,
            summary: terms.summary,
            version: terms.version,
            active: terms.active,
            start_date: terms.start_date,
            terms_url: termURL,
          })
          .then(() => {
            Swal.fire({
              text: "Termo atualizado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            })
              .then(() => {
                // eslint-disable-next-line
                window.location.pathname = "/gestao-termo-de-uso";
              })
              .catch(() => {
                Swal.fire({
                  title: "Ops!",
                  icon: "error",
                  text: "Ocorreu um erro interno, por favor tente novamente",
                  confirmButtonText: "Ok",
                });
              });
          })
          .catch(() => {
            Swal.fire({
              text: "Ocorreu um problema, tente novamente!",
              icon: "error",
              confirmButtonText: "Ok",
            })
              .then(() => {
                // eslint-disable-next-line
                window.location.pathname = "/gestao-termo-de-uso";
              })
              .catch(() => {
                Swal.fire({
                  title: "Ops!",
                  icon: "error",
                  text: "Ocorreu um erro interno, por favor tente novamente",
                  confirmButtonText: "Ok",
                });
              });
          });
      } else {
        api
          .post("/terms", {
            title: terms.title,
            text: terms.text,
            summary: terms.summary,
            version: terms.version,
            active: terms.active,
            start_date: terms.start_date,
            terms_url: termURL,
          })
          .then(() => {
            Swal.fire({
              text: "Termo criado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            })
              .then(() => {
                window.location.pathname = "/gestao-termo-de-uso";
              })
              .catch(() => {
                Swal.fire({
                  title: "Ops!",
                  icon: "error",
                  text: "Ocorreu um erro interno, por favor tente novamente",
                  confirmButtonText: "Ok",
                });
              });
          })
          .catch(() => {
            Swal.fire({
              text: "Ocorreu um problema, tente novamente!",
              icon: "error",
              confirmButtonText: "Ok",
            })
              .then(() => {
                window.location.pathname = "/gestao-termo-de-uso";
              })
              .catch(() => {
                Swal.fire({
                  title: "Ops!",
                  icon: "error",
                  text: "Ocorreu um erro interno, por favor tente novamente",
                  confirmButtonText: "Ok",
                });
              });
          });
      }
    }
  };

  useEffect(() => {
    if (term_id) {
      api.get(`/terms/${term_id}`).then((res) => {
        setTerms({
          text: res.data.text,
          title: res.data.title,
          summary: res.data.summary,
          active: res.data.active,
          version: res.data.version,
          start_date: new Date(res.data.start_date),
          terms_url: termURL,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term_id]);

  return (
    <>
      <PageHeader
        title={term_id ? "Edição do termo de uso" : "Cadastro do termo de uso"}
      />
      <ContainerFluid containerId="pdvs-manager">
        <Card>
          <div className="row">
            <DivFormGroup
              adjutsCss="col-md-4 col-12"
              label="Título do termo de uso*"
            >
              <Input
                type="text"
                placeholder="digite aqui o título do termo de uso..."
                name="title"
                value={terms.title}
                onChange={(e) =>
                  setTerms({
                    ...terms,
                    [e.target.name]: e.target.value,
                  })
                }
                className="form-control mt-2 col-12"
              />
            </DivFormGroup>
            <Input
              classNameInput="custom-control-input"
              classNameDivInput="custom-control custom-switch mt-3"
              classNameLabel="custom-control-label"
              divInputClass="form-group col-md-4"
              label="Termo ativo?"
              custom="switch-toggle"
              id="active"
              type="checkbox"
              name="active"
              checkBoxValue={terms.active}
              defaultChecked={terms.active}
              onChange={() => {
                if (!terms.active) {
                  setTerms({
                    ...terms,
                    active: true,
                  });
                } else {
                  setTerms({
                    ...terms,
                    active: false,
                  });
                }
              }}
            >
              <label htmlFor="active">
                o termo está
                <b>
                  <u>{terms.active ? " ativo" : " desativado"}</u>
                </b>
              </label>
            </Input>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
              className=" col-md-4"
            >
              <span
                style={{
                  fontSize: "0.8rem",
                  color: "#506d7c",
                  paddingBottom: "10px",
                }}
              >
                Upload Termo em PDF
              </span>

              <input
                style={{ position: "absolute" }}
                type="file"
                accept="application/pdf"
                className="custom-file-input"
                id="registro-termos"
                disabled={loadingTerms}
                onChange={(e) => {
                  uploadImage(e);
                }}
                aria-describedby="inputGroupFileAddon01"
              />
              <label
                style={{
                  display: "flex",
                  position: "relative",
                }}
                className="custom-file-label"
                id="registro-termos"
                htmlFor="registro-termos"
              >
                <div
                  hidden={!loadingTerms}
                  className="spinner-border spinner-border-sm text-primary"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                {!loadingTerms && !termURL ? "Procurar..." : null}
                {fileName && fileName}
              </label>
            </div>
          </div>
          <hr />
          <div className="row">
            <DivFormGroup adjutsCss="col-md-6 col-12" label="Versão">
              <Input
                type="text"
                placeholder="Digite a versão do termo"
                name="version"
                value={terms.version}
                onChange={(e) =>
                  setTerms({
                    ...terms,
                    [e.target.name]: e.target.value,
                  })
                }
                className="form-control col-12"
              />
            </DivFormGroup>
            <div className="col-md-6">
              <DatePicker
                leftIcon
                labelStyle={{
                  marginBottom: "0.2rem",
                }}
                style={{
                  borderRadius: "0.375rem",
                  height: "38px",
                }}
                labelClassName="mb-1"
                label={t("components:datepicker.date")}
                value={terms.start_date}
                showTimeSelect
                minDate={new Date()}
                onChange={(value) => setTerms({ ...terms, start_date: value })}
                placeholderText={t("components:datepicker.selectDate")}
              />
            </div>
          </div>
          <hr />
          <DivFormGroup
            adjutsCss="col-md-12 col-12"
            label="Texto do termo de uso"
          >
            <JoditEditor
              defaultValue={terms.text}
              value={terms.text}
              config={config}
              onChange={(value) => setTerms({ ...terms, text: value })}
            />
          </DivFormGroup>
          <hr />
          <DivFormGroup
            adjutsCss="col-md-12 col-12"
            label="Resumo do termo de uso"
          >
            <JoditEditor
              defaultValue={terms.summary}
              value={terms.summary}
              config={config}
              onChange={(value) => setTerms({ ...terms, summary: value })}
            />
          </DivFormGroup>
        </Card>
        <DivFormGroup adjutsCss="button-group offset-md-9 mb-4">
          <CustomButton
            className="btn btn-primary"
            onClick={actions}
            text={term_id ? "Atualizar termo" : "Criar termo"}
          />
        </DivFormGroup>
      </ContainerFluid>
    </>
  );
}
