import React, { Fragment, useState } from "react";
import { ReactComponent as Copy } from "./../../../src/assets/icons/export.svg";
import { ReactComponent as Check } from "./../../../src/assets/icons/new_check.svg";
import "./style.css";

export default function CopyButton({ content, ...props }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Fragment>
      <button className="new-copy-button" onClick={handleCopy} {...props}>
        {isCopied ? <Check /> : <Copy />}
      </button>
    </Fragment>
  );
}
