import React from "react";
import PropTypes from "prop-types";

Input.propTypes = {
  type: PropTypes.string,
  divInputClass: PropTypes.string,
  classNameLabel: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkBoxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onKeyDown: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  custom: PropTypes.string,
  classNameDivLabel: PropTypes.string,
  classNameInput: PropTypes.string,
  children: PropTypes.node,
  classNameDivInput: PropTypes.string,
  error: PropTypes.string,
  onFocus: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  readOnly: PropTypes.bool,
};

export default function Input(props) {
  const {
    type,
    divInputClass,
    classNameLabel,
    label,
    id,
    className,
    placeholder,
    disabled,
    name,
    value,
    error,
    onFocus,
    checkBoxValue,
    onBlur,
    onChange,
    onKeyDown,
    readOnly,
    custom,
    classNameDivLabel,
    classNameInput,
    children,
    classNameDivInput,
  } = props;

  const updateValue = () => {
    if (value) {
      onChange(false);
    } else {
      onChange(true);
    }
  };

  return (
    <>
      {type !== "checkbox" && (
        <>
          <input
            id={id}
            className={`${className} ${error}`}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={
              typeof onFocus === "function" ? (e) => onFocus(e) : onFocus
            }
            name={name}
            onKeyDown={
              typeof onKeyDown === "function" ? (e) => onKeyDown(e) : onKeyDown
            }
            readOnly={readOnly}
            value={value}
            onBlur={typeof onBlur === "function" ? (e) => onBlur(e) : onBlur}
            onChange={(e) => onChange(e)}
          />
          {error && error !== "initial" && (
            <h6 className="text-danger ml-2 mt-1">
              <small>Este campo é obrigatório*</small>
            </h6>
          )}
        </>
      )}
      {type === "checkbox" && custom === "switch-toggle" && (
        <div className={divInputClass}>
          <label htmlFor={classNameDivLabel} className={classNameDivLabel}>
            {label}
          </label>
          <div className={classNameDivInput}>
            <input
              id={id}
              type={type}
              className={classNameInput}
              name={name}
              checked={checkBoxValue}
              onChange={(e) => updateValue(e)}
            />
            <label htmlFor={name} className={classNameLabel}>
              {children}
            </label>
          </div>
        </div>
      )}
    </>
  );
}
