import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Select from "react-select";

import "./styles.css";

NewSelect.propTypes = {
  label: PropTypes.string,
  props: PropTypes.any,
};

const switchStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  control: (control) => ({
    ...control,
    borderRadius: "8px",
    border: "1px solid #D3D2D9",
    cursor: "pointer",
    padding: "0.1rem",
  }),
  dropdownIndicator: (dropdownIndicator) => ({
    ...dropdownIndicator,
    color: "#525059",
  }),
  singleValue: (singleValue) => ({
    ...singleValue,
    color: "#171719",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "#171719",
  }),
};

export default function NewSelect({ label, ...props }) {
  return (
    <Fragment>
      {label && <label className="label-text">{label}</label>}
      <Select {...props} styles={switchStyles} />
    </Fragment>
  );
}
