import styled from 'styled-components';

export const NavigationContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  justify-content: center;
`;

export const NavButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;