import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import Select from "react-select";
import api from "services/api";
import CustomButton from "components/custom-button";
import EventAutocomplte from "components/inputeventautocomplete";
import Swal from "sweetalert2";

Modal.setAppElement("#root");

ModalImport.propTypes = {
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    setIsOpenModal: PropTypes.func,
  }),
  eventID: PropTypes.string,
  refresh: PropTypes.func,
};

export default function ModalImport(props) {
  const {
    toggleFunction: { isOpen, setIsOpenModal },
    eventID,
    refresh,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line
  const [menu_id, setMenuId] = useState("");
  const [sectors, setSectors] = useState();
  const [unique, setUnique] = useState();
  const close = (clause) => {
    setIsOpenModal(false);
    setShowButton(false);
    setInputValue("");
    setOptions([]);
    refresh(clause);
  };
  const sendEvent = (event) => {
    searchMenus(event.id);
  };

  const searchMenus = (selectedEvent) => {
    api.get(`stock/exist/menu/event/${selectedEvent}`).then((res) => {
      const menuOptions = res.data.map((menu) => {
        const properties = {
          id: menu.id,
          label: menu.name,
          value: menu.name,
          status: menu.status,
          description: menu.description,
        };
        return properties;
      });
      setOptions(menuOptions);
    }).catch(() => {
      Swal.fire({
        title: 'Ops!',
        icon: 'error',
        text: 'Ocorreu um erro interno, por favor tente novamente',
        confirmButtonText: 'Ok'
      })
    });
  };

  const handleChangeMenu = (selectedMenu) => {
    setMenuId(selectedMenu.id);
    if (sectors.length <= 1) {
      setShowButton(true);
    }
  };
  const handleChangeSector = (selectedSector) => {
    setUnique(selectedSector.id);
    setShowButton(true);
  };

  const sendMenu = () => {
    // eslint-disable-next-line
    api
      .post(`menu/importMenu/${eventID}`, { menu_id, unique })
      .then(() => {
        close(true);
      })
      .catch(() => {
        close(false)

          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })

      
      });
  };

  const switchStyles = {
    control: (control) => ({
      ...control,
      border: "none",
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#6e84a3",
    }),
    option: (option,state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
    menu: (menu) => ({
      ...menu,
      width: "95%",
    }),
  };

  useEffect(() => {
    api.get(`listSectors/${eventID}`).then((res) => {
      let sectorsArray = res.data.map((item) => {
        let arrayItens = {
          value: item.id,
          label: item.name,
        };
        return arrayItens;
      });
      setSectors(sectorsArray);
      if (res.data[0]) {
        setUnique(res.data[0].id);
      }
    }).catch(() => {
      Swal.fire({
        title: 'Ops!',
        icon: 'error',
        text: 'Ocorreu um erro interno, por favor tente novamente',
        confirmButtonText: 'Ok'
      })
    });
  }, [eventID]);

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={isOpen}
      onRequestClose={close}
      className="shadow side-modal"
    >
      <div>
        <div className="card-header">
          <h4 className="card-header-title">Importação de cardápio</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="m-3">
          <EventAutocomplte
            value={inputValue}
            onChange={setInputValue}
            onSelect={sendEvent}
          />
        </div>
        {options.length > 0 && (
          <>
            <div className="card-search m-3">
              <Select
                styles={switchStyles}
                className="col-12"
                onChange={handleChangeMenu}
                options={options}
                placeholder= "Selecione o cardápio..."
              />
            </div>
            {sectors.length > 1 && (
              <div className="card-search m-3">
                <Select
                  styles={switchStyles}
                  className="col-12"
                  onChange={handleChangeSector}
                  options={sectors}
                  placeholder= "Selecione o setor..."
                />
              </div>
            )}
          </>
        )}
        <div className="d-flex justify-content-end m-3">
          {showButton && (
            <CustomButton
              onClick={sendMenu}
              className="btn btn-primary col-md-4 col-12"
            >
              Importar cardápio
            </CustomButton>
          )}
        </div>
      </div>
    </Modal>
  );
}
