import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faChevronDown,
  faEye,
  faEyeSlash,
  faPlusCircle,
  faUpload,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastr";
import "./styles.css";
import api from "services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import ProdutoFiscal from "components/produtofiscal";
import { ImageHandlerService } from "services/image-handler";
import CustomButton from "components/custom-button";
import ModalMenu from "./modalMenu";
import ModalImport from "./modalImport";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { useLanguageSettings } from "hooks/language-settings";

// ESSE NUMERO É POR CAUSA DA FUNÇAO JS "AT" PARA PEGAR O ULTIMO ITEM DO ARRAY
const LAST_MENU_ARRAY = -1;
let container;
// eslint-disable-next-line no-undef
const windowJS = window;
// eslint-disable-next-line no-undef
const documentJS = document;
// eslint-disable-next-line no-undef
const localStorageJS = parseInt(localStorage.getItem("user_id"), 10);

export default function EventMenuNew() {
  const timeDebounce = 800;
  const { currencyInputFormatter } = useLanguageSettings();
  const [categorias, setCategorias] = useState([]);
  const [isOpen, setIsOpenModal] = useState(false);
  const [produto, setProduto] = useState({
    alias: "",
    category_menu_product_id: null,
    cost: 0,
    description: "",
    id: null,
    id_product: null,
    price: 0,
    eventID: null,
    fiscal: null,
    is_tax: false,
    id_menu: "",
  });
  const urlParams = new URLSearchParams(windowJS.location.search);
  const eventID = urlParams.get("event_id");
  const [modalIsOpen, setIsOpen] = useState("left-modal-hidden");
  const [modalStatus, setModalStatus] = useState("modal-hidden");
  const [menuModal, setMenuModal] = useState("modal-hidden");
  const [click, setClick] = useState(false);
  const [mostranewcat, setMostranewcat] = useState(false);
  const [novaCategoria, setNovaCategoria] = useState();
  // const [produtosSearch, setProdutosSearch] = useState([]);
  const [value, setValue] = useState(null);
  const [menus, setMenus] = useState([]);
  const [activeMenu, setActiveMenu] = useState({
    id: "",
    eventID: "",
    name: "",
  });
  const [reload, setReload] = useState(false);
  const [menuCategoriesOrder, setMenuCategoriesOrder] = useState(categorias);
  const { currencyFormatter } = useLanguageSettings();

  useEffect(() => {
    api
      .get(`stock/exist/menu/event/${eventID}`)
      .then((res) => {
        setMenus(res.data);
        if (res.data.length > 0) {
          setMostranewcat(true);

          setActiveMenu({
            eventID,
            id: res.data[0].id,
            name: res.data[0].name,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }, [eventID]);

  useEffect(() => {
    if (activeMenu.id !== "") {
      api
        .get(`/menu/showCategories/event/${eventID}/menu/${activeMenu.id}`)
        .then((res) => {
          setCategorias(res.data);
          setMenuCategoriesOrder(res.data);
          const catTemp = [];
          res.data.forEach((dado) => {
            catTemp.push({ name: dado.name, value: dado.id });
          });
        })
        .catch((err) => {
          container.error(err);
        });

      setProduto({
        ...produto,
        id_menu: activeMenu.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu, eventID, reload]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const selectedValue = (productSelected) => {
    const inputValue = {
      label: (
        <div className="selectedProduct">
          {productSelected.label.props.children[1].props.children}
          <button type="button" onClick={() => setValue(null)}>
            x
          </button>
        </div>
      ),
    };
    setProduto({
      ...produto,
      alias: productSelected.value.name,
      id_product: productSelected.value.id,
    });
    setValue(inputValue);
  };

  async function loadOptions(search, loadedOptions, { page }) {
    const temparray = [];
    let hasMore;
    let currentSearch = search;
    await sleep(timeDebounce);
    if (!currentSearch) {
      currentSearch = null;
    }
    // carregando os produtos conforme necessiade do componente SearchSelect.
    await api
      .get(`products/show/${currentSearch}/${page}`)
      .then((res) => {
        hasMore = res.data.total > loadedOptions.length;
        res.data.data.forEach((dado) => {
          temparray.push({
            label: (
              <div className="row ml-2">
                <img
                  alt=""
                  className="img-product-menu col-2"
                  style={{
                    backgroundImage: `url(${ImageHandlerService.getImageFromUrl(
                      dado.picture,
                      {
                        format: "webp",
                        quality: 50,
                        resize: { width: 80, fit: "contain" },
                      }
                    )})`,
                    backgroundSize: "cover",
                  }}
                />
                <div className="col-10">{dado.name}</div>
              </div>
            ),
            value: dado,
          });
        });
        // setProdutosSearch(temparray);
      })
      .catch((err) => {
        container.error(err);
      });
    return {
      options: temparray,
      additional: {
        page: page + 1,
      },
      hasMore,
    };
  }

  const updateData = (e) => {
    setProduto({
      ...produto,
      [e.target.name]: e.target.value,
    });
  };

  const updateCurrency = (e) => {
    setProduto({
      ...produto,
      [e.target.name]: currencyInputFormatter(e.target.value),
    });
  };

  const salvarCategoria = () => {
    setClick(true);
    if (!novaCategoria) {
      setClick(false);
      container.error("Digite um nome para a nova categoria!");
      return;
    }
    const data = {
      event_id: eventID,
      name: novaCategoria,
      menu_id: activeMenu.id,
    };
    api
      .post("menu/createCategory", data)
      .then((res) => {
        load();
        setNovaCategoria("");
        setMostranewcat(true);
        setClick(false);
        container.success(res.data.message);
        reload ? setReload(false) : setReload(true);
      })
      .catch((err) => {
        container.error(err.data.message);
      });
  };
  function openModal(prod = null, id = null) {
    setModalStatus("modal-show");
    if (prod) {
      setProduto({
        ...produto,
        alias: prod.alias,
        category_menu_product_id: prod.category_menu_product_id,
        cost: prod.cost,
        price: prod.price,
        id: prod.id,
        description: prod.description ? prod.description : "",
      });
    } else {
      setProduto({
        ...produto,
        category_menu_product_id: id,
        eventID,
      });
    }
  }

  function closeModal() {
    setModalStatus("modal-hidden");
    setProduto({
      ...produto,
      alias: "",
      category_menu_product_id: null,
      cost: 0,
      description: "",
      id: null,
      id_product: null,
      price: 0,
      eventID: null,
      fiscal: null,
      is_tax: false,
    });
    setValue(null);
  }

  function categoryExpand(categoryMenuValue) {
    const category = documentJS.getElementById(
      `menu-category-${categoryMenuValue}`
    );
    if (!category.classList.contains("category-expanded")) {
      category.classList.add("category-expanded");
    } else {
      category.classList.remove("category-expanded");
    }
  }

  function productStatus(prod, categoria) {
    const product = prod;
    const idCategoriaMenu = categoria.id;

    if (product.active === 1) {
      product.active = 0;
    } else {
      product.active = 1;
    }

    api
      .post(`menu/category/products/${prod.id}/active/${+prod.active}`, {
        idCategoriaMenu,
      })
      .then((res) => {
        container.success(res.data.menssage);
        load();
      })
      .catch((err) => {
        container.error(err);
        container.error(err.response.data.message);
      });
  }

  const load = () => {
    api
      .get(`/menu/showCategories/event/${eventID}/menu/${activeMenu.id}`)
      .then((res) => {
        setCategorias(res.data);
        const catTemp = [];
        res.data.forEach((dado) => {
          catTemp.push({ name: dado.name, value: dado.id });
        });
      })
      .catch((err) => {
        container.error(err);
      });
  };

  const salvar = (e) => {
    setClick(true);
    e.preventDefault();
    if (produto.id) {
      const product = {
        alias: produto.alias,
        category_menu_product_id: produto.category_menu_product_id,
        cost:
          typeof produto.cost === "string"
            ? produto.cost.replace(".", "").replace(",", "").replace("R$", "") /
              100
            : produto.cost / 100,
        description: produto.description,
        id: produto.id,
        id_product: produto.id_product,
        price:
          typeof produto.price === "string"
            ? produto.price
                .replace(".", "")
                .replace(",", "")
                .replace("R$", "") / 100
            : produto.price / 100,
        eventID: produto.eventID,
        fiscal: produto.fiscal,
        is_tax: produto.is_tax,
        id_menu: produto.id_menu,
      };
      api
        .post("menu/editProduct", product)
        .then((res) => {
          closeModal();
          setClick(false);
          container.success(res.data.message);
          setReload((prev) => !prev);
          load();
        })
        .catch((err) => {
          setClick(false);
          container.error(err.response.data.message);
        });
    } else {
      const product = {
        alias: produto.alias,
        category_menu_product_id: produto.category_menu_product_id,
        cost:
          typeof produto.cost === "string"
            ? produto.cost.replace(".", "").replace(",", "").replace("R$", "") /
              100
            : produto.cost / 100,
        description: produto.description,
        id: produto.id,
        id_product: produto.id_product,
        price:
          typeof produto.price === "string"
            ? produto.price
                .replace(".", "")
                .replace(",", "")
                .replace("R$", "") / 100
            : produto.price / 100,
        eventID: produto.eventID,
        fiscal: produto.fiscal,
        is_tax: produto.is_tax,
        id_menu: produto.id_menu,
      };
      api
        .post("menu/new/product", product)
        .then((res) => {
          closeModal();
          setClick(false);
          container.success(res.data.message);
          reload ? setReload(false) : setReload(true);
        })
        .catch((err) => {
          setClick(false);
          container.error(err.response.data.message);
        });
    }
  };

  const HandleActivate = (id) => {
    Swal.fire({
      title: "Ativar",
      icon: "warning",
      text: "Deseja realmente ativar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        api
          .post(`menu/activeDeactivateCategorie/${id}`)
          .then(() => {
            container.success("Categoria ativada com sucesso!");

            load();
          })
          .catch(() => {
            Swal.fire("Ativar", "Categoria não ativada", "error");
          });
      }
    });
  };

  const HandleDeactivate = (id) => {
    Swal.fire({
      title: "Desativar",
      icon: "warning",
      text: "Deseja realmente desativar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        api
          .post(`menu/activeDeactivateCategorie/${id}`)
          .then(() => {
            container.success("Categoria desativada com sucesso!");
            load();
          })
          .catch(() => {
            Swal.fire("Desativar", "Categoria não desativada", "error");
          });
      }
    });
  };

  function retornoFiscal() {
    setIsOpen("left-modal-hidden");
  }

  function salvarValores(values) {
    setProduto({
      ...produto,
      fiscal: values,
      is_tax: true,
    });
  }

  function openMenuModal(boolean) {
    if (boolean) {
      setActiveMenu({
        id: "",
        eventID,
        name: "",
      });
    }
    setMenuModal("modal-show");
  }

  function menuModalRetorno(imported) {
    setMenuModal("modal-hidden");

    api
      .get(`stock/exist/menu/event/${eventID}`)
      .then((res) => {
        if (imported === true) {
          container.success("Cardápio importado com sucesso!");
        } else if (imported === false) {
          container.error("Erro ao importar o cardápio!");
        }
        setMenus(res.data);
        if (res.data.length > 0) {
          setMostranewcat(true);
        }
        setActiveMenu({
          eventID,
          id: res.data.at(LAST_MENU_ARRAY).id,
          name: res.data.at(LAST_MENU_ARRAY).name,
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }

  function openModalImport() {
    setIsOpenModal(true);
  }

  function alterMenuCategoryName(category) {
    Swal.fire({
      title: "Alterar nome categoria",
      icon: "warning",
      text: "Insira o nome a ser atualizado na categoria.",
      input: "text",
      inputValue: category.name,
      showCancelButton: true,
      confirmButtonText: "Ok!",
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {
        return api
          .patch(`categories/${category.id}`, {
            name: value,
          })
          .catch((error) => {
            const message =
              error.response.status === 422
                ? `A categoria ${value} já existe e precisa ser única`
                : `Ocorreu um erro interno!`;
            Swal.fire("Erro ao atualizar!", message, "error");
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Atualizado!",
          "Nome da categoria atualizada com sucesso!",
          "success"
        );
        reload ? setReload(false) : setReload(true);
      }
    });
  }

  function deleteMenuCategory(category) {
    Swal.fire({
      title: "Você tem certeza que deseja excluir?",
      icon: "warning",
      text: "Caso alguma venda tenha sido realizada com este cardápio, não será possível excluir.",
      showCancelButton: true,
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`categories/${category.id}`)
          .then(() => {
            container.success("Categoria excluída com sucesso!");
            reload ? setReload(false) : setReload(true);
          })
          .catch((error) => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text:
                error.response.data.code === "ERR_DELETE_CATEGORY"
                  ? `Não é possível deletar uma categoria com produtos vendidos!`
                  : "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(menuCategoriesOrder);

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    const categoriesWithNewIndex = items.map((item, index) => {
      return { ...item, category_menu_index: index };
    });

    const updatedSectors = categoriesWithNewIndex.filter(
      (item, index) =>
        item.category_menu_index !== items[index].category_menu_index
    );

    api
      .patch(`sort-menu-categories`, {
        categories: updatedSectors.map((item) => ({
          id: item.id,
          category_menu_index: item.category_menu_index,
        })),
      })
      .then(() => container.success("Categorias ordenadas com sucesso!"))
      .catch(() => container.error("Ocorreu um erro ao ordenar categorias!"));

    setMenuCategoriesOrder(categoriesWithNewIndex);
  }

  return (
    <>
      <PageHeader title="Cardápio" text="Composição de cardápio" />
      <div id="menus-navigation" className="shadow-sm">
        <div id="menus-list" className="col-12">
          <span>Escolha o menu desejado:</span>
          <div>
            {menus.map((item) => (
              <button
                type="button"
                className={
                  activeMenu.id === item.id
                    ? "btn btn-primary"
                    : "btn btn-secondary"
                }
                key={item.id}
                id="estoque-1"
                value={item.id}
                onClick={() => {
                  setActiveMenu({
                    ...activeMenu,
                    eventID,
                    id: item.id,
                    name: item.name,
                  });
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div id="active-menu" className="col-12">
        <div>
          <i>
            Você está editando o menu:
            {activeMenu.name}
          </i>
        </div>
      </div>

      <ReactTooltip className="tooltip-custom" effect="solid" place="top" />

      <div className="container-fluid mt-4" id="page-menu-items">
        <ToastContainer
          ref={(ref) => {
            container = ref;
          }}
          className="toast-top-right"
        />
        <ReactTooltip place="left" className="tooltip-custom" effect="solid" />

        <div className="row">
          <div className="col-sm-4 mb-2">
            <button
              type="button"
              className="btn btn-outline-secondary"
              id="estoque-0"
              value={0}
              onClick={() => openMenuModal(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              Criar novo cardápio
            </button>
          </div>
          <div className="col-sm-4 mb-2">
            <button
              type="button"
              disabled={menus.length > 0 ? false : true}
              className="btn btn-outline-secondary"
              onClick={() => openMenuModal(false)}
            >
              <FontAwesomeIcon icon={faPen} />
              Editar cardápio selecionado
            </button>
          </div>
          <div className="col-sm-4 mb-2">
            <CustomButton
              onClick={openModalImport}
              className="btn btn-outline-secondary"
            >
              <FontAwesomeIcon icon={faUpload} />
              Importar cardápio
            </CustomButton>
          </div>
          {mostranewcat && menus.length > 0 && (
            <div className="col-md-12 mt-2 mb-4">
              <div id="btn-new-cat">
                <button
                  type="button"
                  onClick={() => {
                    setMostranewcat(false);
                  }}
                  className="mb-0 col-12"
                >
                  <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                  Clique para criar uma nova categoria
                </button>
              </div>
            </div>
          )}
          {!mostranewcat && menus.length > 0 && (
            <div className="col-md-12 mt-2 mb-4">
              <div id="create-new-category" className="row">
                <div className="col-7 col-md-9">
                  <input
                    className="form-control"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setMostranewcat(true);
                      } else {
                        setMostranewcat(false);
                      }
                    }}
                    onChange={(e) => setNovaCategoria(e.target.value)}
                  />
                </div>
                <div className="col-5 col-md-3">
                  <button
                    type="button"
                    disabled={click}
                    className="btn btn-primary"
                    onClick={() => salvarCategoria()}
                  >
                    criar categoria
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="mb-3 pb-5 w-100">
            {/* criação de categorias */}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="menuCategories">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="col-md-12"
                  >
                    {menuCategoriesOrder.map((categoria, index) => {
                      return (
                        <Draggable
                          key={categoria.id}
                          draggableId={`category-${categoria.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              key={categoria.id}
                              id={`menu-category-${categoria.id}`}
                              className={
                                categoria.active === 1
                                  ? "card menu-category-row card-category-border-left"
                                  : "card menu-category-row deactive-card card-category-border-left"
                              }
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="row card-header d-flex align-items-center mb-1 col-md-12">
                                <div className="d-flex align-items-left justify-content-left col-md-4">
                                  <div className="align-items-left justify-content-left">
                                    <FontAwesomeIcon
                                      icon={faGripVertical}
                                      className="category-grip-icon"
                                    />
                                  </div>
                                  <div>
                                    <button
                                      type="button"
                                      className="menu-category-expand d-flex col-md-6 text-nowrap "
                                      onClick={() =>
                                        categoryExpand(categoria.id)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faChevronDown} />
                                      <h5 className="ml-2 m-0 user-select-none">
                                        {categoria.name}
                                      </h5>
                                    </button>
                                  </div>
                                </div>

                                <div className="col-md-6 category-btns">
                                  {categoria.active === 1 ? (
                                    <button
                                      type="button"
                                      className="btn-table"
                                      onClick={() =>
                                        HandleDeactivate(categoria.id)
                                      }
                                      data-tip="Desativar"
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn-table"
                                      onClick={() =>
                                        HandleActivate(categoria.id)
                                      }
                                      data-tip="Ativar"
                                    >
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    className="btn-table"
                                    onClick={() =>
                                      alterMenuCategoryName(categoria)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-table"
                                    onClick={() =>
                                      deleteMenuCategory(categoria)
                                    }
                                    data-tip="Deletar"
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button>
                                  {categoria.active === 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary ml-2"
                                      onClick={() =>
                                        openModal(null, categoria.id)
                                      }
                                      data-tip="Adicionar produto"
                                    >
                                      <FontAwesomeIcon icon={faPlusCircle} />
                                      <span className="ml-2">
                                        adicionar produto
                                      </span>
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div className="card-body m-0 p-0">
                                {categoria.products.map((productsList) => (
                                  <div
                                    key={productsList.id}
                                    className={
                                      productsList.active
                                        ? "product-row mt-3 mb-2 ml-2 mr-2"
                                        : "product-disabled product-row mb-2"
                                    }
                                  >
                                    <div
                                      className="product-row-img"
                                      style={{
                                        backgroundImage: `url(${productsList.product.picture})`,
                                      }}
                                    />
                                    <input
                                      className="product-row-name"
                                      value={productsList.alias}
                                      readOnly
                                    />
                                    <input
                                      className="product-row-value mr-3"
                                      value={currencyFormatter(
                                        productsList.price
                                      )}
                                      readOnly
                                    />
                                    <div className="product-row-btns">
                                      <button
                                        type="button"
                                        className="btn-table"
                                        data-tip="Mudar status"
                                        id="icon-product-1"
                                        onClick={() => {
                                          productStatus(
                                            productsList,
                                            categoria
                                          );
                                        }}
                                      >
                                        {productsList.active ? (
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="icon-product-1"
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="icon-product-1"
                                          />
                                        )}
                                      </button>
                                      <button
                                        type="button"
                                        className="btn-table"
                                        data-tip="Editar produto"
                                        onClick={() => openModal(productsList)}
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* criação de categorias */}
          </div>
        </div>
      </div>
      <div
        id="produto-cadastrar"
        className={`shadow side-modal ${modalStatus}`}
        role="dialog"
      >
        <div className="card-header">
          <h4 className="card-header-title">
            {produto.id && !value ? "Editar Produto " : "Cadastrar Produto"}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => closeModal(e)}
          >
            &times;
          </button>
        </div>
        <div className="card-body">
          <div className="needs-validation">
            {!produto.id && (
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <span>Produto</span>
                  <AsyncPaginate
                    value={value}
                    loadOptions={loadOptions}
                    onChange={(e) => selectedValue(e)}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>
              </div>
            )}
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <span>Apelido</span>
                <input
                  value={produto.alias}
                  name="alias"
                  placeholder="Apelido."
                  className="form-control"
                  onChange={updateData}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <span>Custo</span>
                <input
                  value={currencyInputFormatter(produto.cost) || 0}
                  name="cost"
                  className="form-control"
                  onChange={(e) => updateCurrency(e)}
                />
              </div>
              <div className="col-md-6 mb-3" data-select2-id="8">
                <span>Preço</span>
                <input
                  value={currencyInputFormatter(produto.price) || 0}
                  name="price"
                  className="form-control"
                  onChange={(e) => updateCurrency(e)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <span>Descrição</span>
                <textarea
                  className="form-control"
                  name="description"
                  value={produto.description}
                  onChange={updateData}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <button
                  disabled={click}
                  className="btn btn-secondary"
                  onClick={(e) => salvar(e)}
                  type="submit"
                >
                  {produto.id ? "salvar informações" : "Cadastrar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProdutoFiscal
        status={modalIsOpen}
        retorno={retornoFiscal}
        valores={salvarValores}
        product_id={produto.id}
      />
      <ModalMenu
        status={menuModal}
        retorno={menuModalRetorno}
        activeMenu={activeMenu}
        eventID={eventID}
      />
      <ModalImport
        toggleFunction={{ isOpen, setIsOpenModal }}
        eventID={eventID}
        userId={localStorageJS}
        refresh={menuModalRetorno}
      />
    </>
  );
}
