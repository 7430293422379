import React, { useEffect, useState } from "react";
import api from "services/api";
import "./styles.css";
import Swal from "sweetalert2";
import { orderStatusNames } from "services/constants";
import { useLanguageSettings } from "hooks/language-settings";

const ModalUserDetail = (props) => {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const [dataTel, setDataTel] = useState([]);
  const [dataCPF, setDataCPF] = useState([]);
  const [dataSales, setDataSales] = useState([]);

  let count = [];

  function closeModal() {
    props.callback("modal-hidden");
  }

  useEffect(() => {
    if (props.userDetail.id) {
      let id = { idUser: props.userDetail.id };
      api
        .post(`/detailUser`, id)
        .then((res) => {
          setDataTel(res.data.userPhones);
          setDataCPF(res.data.userDocuments);
          setDataSales(res.data.userDetail);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  }, [props.userDetail.id]);

  const setClass = (status) => {
    switch (status) {
      case "finalizado":
        return "table-success";
      case "estornado":
        return "table-warning";
      case "não pagou":
        return "table-danger";
      case "pendente":
        return "table-warning";
      case "finalizada":
        return "table-success";
      case "disputa":
        return "table-danger";
      case "charged_back" || "charged back":
        return "table-danger";
      case "carrinho":
        return "table-primary";
      case "expirado":
        return "table-secondary";
      case "falhada":
        return "table-warning";
      // no default
    }
  };

  for (let index = 0; index < dataSales.length; index++) {
    const element = dataSales[index];

    if (element.status === "disputa") {
      count.push(element);
    }
  }

  return (
    <>
      <div className={"shadow side-modal " + props.status} role="dialog">
        <div className="w-100 mt-2 table-responsive">
          <div className="card-header">
            <h4 className="card-header-title">
              Dados de: {props.userDetail.name}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => closeModal(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="details-user-info col-md-12">
            <div className="row">
              <div className="details-user-1 col-md-7 mt-3">
                <div>
                  <h6>Nome: {props.userDetail.name}</h6>
                  <h6>Email cadastrado: {props.userDetail.email}</h6>
                  <h6>Criação da conta: {props.userDetail.date_create}</h6>
                  <h6>
                    Telefone cadastrado:{" "}
                    {props.userDetail.telephone
                      ? props.userDetail.telephone
                      : "Não preenchido!"}{" "}
                    {props.userDetail.telephone ? (
                      <a
                        href={`https://api.whatsapp.com/send?phone=55${props.userDetail.telephone}&text=Uniticket`}
                        className="link-whatts"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Chamar no Whatsapp
                      </a>
                    ) : (
                      ""
                    )}{" "}
                  </h6>
                </div>
              </div>
              <div className="details-user-2 col-md-5 mt-3">
                <div className="col">
                  <h6>Compras no sistema:{dataSales.length}</h6>
                  <h6>Compras com disputa:{count.length}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="details-user-table col-md-12 mt-3">
            <div className="row">
              <div className="col-md-6 table-responsive">
                <table class="table table-sm datatable-custom">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Telefones utilizados</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTel.map((tel, i) => (
                      <tr key={i}>
                        <td>
                          {tel.numero ? tel.numero : "Não preenchido"}{" "}
                          {tel.numero ? (
                            <a
                              href={`https://api.whatsapp.com/send?phone=55${tel.numero}&text=Uniticket`}
                              className="link-whatts"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Chamar no Whatsapp
                            </a>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-sm datatable-custom">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Cpf's utilizados</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataCPF.map((doc, i) => (
                      <tr key={i}>
                        <td>
                          {doc.documento ? doc.documento : "Não preenchido"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="details-user-table col-md-12 mb-3 text-center">
            <h6>Compras no sistema</h6>
          </div>
          <div className="col-12">
            <div className="col table-responsive">
              <table class="table table-sm datatable-custom">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Data da compra</th>
                    <th scope="col">Valor total</th>
                    <th scope="col">Nº de parcelas</th>
                    <th scope="col">Primeiros dígitos</th>
                    <th scope="col">Últimos dígitos</th>
                    <th scope="col">Evento</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSales.map((sale, i) => (
                    <tr key={i}>
                      <td>{sale.dataDaCompra}</td>
                      <td>{noCentsCurrencyFormatter(sale.valorTotal)}</td>
                      <td className="text-center">
                        {sale.installment_number
                          ? sale.installment_number
                          : "Não parcelado"}
                      </td>
                      <td>
                        {sale.firstsnumbers
                          ? sale.firstsnumbers
                          : "Não informado!"}
                      </td>
                      <td>
                        {sale.lastsnumbers
                          ? sale.lastsnumbers
                          : "Não informado!"}
                      </td>
                      <td>{sale.nomeDoEvento}</td>
                      <td className={setClass(sale.status)}>
                        {orderStatusNames[sale.status]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalUserDetail;
