import React from "react";
import PropTypes from 'prop-types';
import { SPACINGS } from "services/constants";
import styled from "styled-components";

ModalFooter.propTypes = {
    handleClick: PropTypes.func,
    buttonText: PropTypes.string.isRequired,
    isValid: PropTypes.bool
};

export function ModalFooter({handleClick, buttonText, isValid}){
    return(
        <ModalFooterDiv>
            <SubmitButton 
                type="submit" 
                onClick={handleClick} 
                isValid={isValid} 
                disabled={!isValid}
            >
                {buttonText}
            </SubmitButton>
        </ModalFooterDiv>
    )
}

const ModalFooterDiv = styled.div`
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: flex-end;
    align-items: flex-start;
    width: calc(100% + 2 * ${SPACINGS.space2});
    margin-left: calc(-1 * ${SPACINGS.space2}); 
    margin-right: calc(-1 * ${SPACINGS.space2}); 
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
`;

const SubmitButton = styled.button`
    padding: 0.5rem 1rem;
    color: white;
    background-color: #0050c3;
    border-radius: 5px;
    align-self: flex-end;
    color: white;
    background-color: ${({ isValid }) => (isValid ? "#0050c3" : "#D3D2D9")};
    border-radius: 5px;
    ${({ isValid }) =>
        isValid &&
        `  &:hover {
    background-color: #002d8c;
    }
    &:active {
    background-color: #00145f;
    }`}
`;