import React from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faCaretSquareDown,
    faCheckCircle
} from "@fortawesome/free-regular-svg-icons";
import "./styles.css"

const CategoriesReport = () => {

    return (
      <>
        <PageHeader
          title="Pedidos por categoria"
          text="Aqui você encontra o relatório e as últimas vendas de cada categoria"
        >
        </PageHeader>

        <div id="categories-report" className="container-fluid">
            <div className="col-12">
                <div className="category-sale row">
                    <div className="category-header col-12">
                        <div className="order-datetime">
                            <div className="order-date">
                                <span>09</span>
                                <span>DEZ</span>
                            </div>
                            <div className="order-time">
                                <FontAwesomeIcon icon={faClock} className="mr-2"></FontAwesomeIcon>20:10:30
                            </div>
                        </div>
                        <div className="order-resume">
                            <span className="order-qty text-small">
                                2 produto(s)
                            </span>
                            <span className="badge badge-pill bg-success-soft ml-2">
                                finalizado
                            </span>
                        </div>
                        <div className="order-buttons">
                        <button className="btn btn-outline-success">
                                aprovar pedido <FontAwesomeIcon icon={faCheckCircle} className="mr-2"></FontAwesomeIcon>
                            </button>
                            <button className="btn btn-outline-secondary ml-2">
                                expandir detalhes <FontAwesomeIcon icon={faCaretSquareDown} className="mr-2"></FontAwesomeIcon>
                            </button>
                        </div>
                    </div>
                    <div className="category-body col-12">
                        <div className="product-detail-row">
                            <div class="product-detail-row-img" style={{ backgroundImage: `url('https://superticketimagens.s3-sa-east-1.amazonaws.com/produtos/48c7fbd3-fb18-4ccb-8f79-dcc1e2addd19')` }}>
                            </div>
                            <div className="item-desc">
                                <span>Paio no Feijão - 03 unidade(s)</span>
                                <span>remover feijão, colocar mais caldo e adicionar pimenta</span>
                            </div>
                        </div>
                        <div className="product-detail-row">
                            <div class="product-detail-row-img" style={{ backgroundImage: `url('https://superticketimagens.s3-sa-east-1.amazonaws.com/produtos/48c7fbd3-fb18-4ccb-8f79-dcc1e2addd19')` }}>
                            </div>
                            <div className="item-desc">
                                <span>Paio no Feijão - 03 unidade(s)</span>
                                <span>remover feijão, colocar mais caldo e adicionar pimenta</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="category-sale row">
                    <div className="category-header col-12">
                        <div className="order-datetime">
                            <div className="order-date">
                                <span>09</span>
                                <span>DEZ</span>
                            </div>
                            <div className="order-time">
                                <FontAwesomeIcon icon={faClock} className="mr-2"></FontAwesomeIcon>20:10:30
                            </div>
                        </div>
                        <div className="order-resume">
                            <span className="order-qty text-small">
                                2 produto(s)
                            </span>
                            <span className="badge badge-pill bg-success-soft ml-2">
                                finalizado
                            </span>
                        </div>
                        <div className="order-buttons">
                        <button className="btn btn-outline-success">
                                aprovar pedido <FontAwesomeIcon icon={faCheckCircle} className="mr-2"></FontAwesomeIcon>
                            </button>
                            <button className="btn btn-outline-secondary ml-2">
                                expandir detalhes <FontAwesomeIcon icon={faCaretSquareDown} className="mr-2"></FontAwesomeIcon>
                            </button>
                        </div>
                    </div>
                    <div className="category-body col-12">
                        <div className="product-detail-row">
                            <div class="product-detail-row-img" style={{ backgroundImage: `url('https://superticketimagens.s3-sa-east-1.amazonaws.com/produtos/48c7fbd3-fb18-4ccb-8f79-dcc1e2addd19')` }}>
                            </div>
                            <div className="item-desc">
                                <span>Paio no Feijão - 03 unidade(s)</span>
                                <span>remover feijão, colocar mais caldo e adicionar pimenta</span>
                            </div>
                        </div>
                        <div className="product-detail-row">
                            <div class="product-detail-row-img" style={{ backgroundImage: `url('https://superticketimagens.s3-sa-east-1.amazonaws.com/produtos/48c7fbd3-fb18-4ccb-8f79-dcc1e2addd19')` }}>
                            </div>
                            <div className="item-desc">
                                <span>Paio no Feijão - 03 unidade(s)</span>
                                <span>remover feijão, colocar mais caldo e adicionar pimenta</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

      </>
    );
  };
  
  export default CategoriesReport;