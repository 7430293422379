import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

ButtonOutline.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node.isRequired,
};

export default function ButtonOutline({ className, text, ...restProps }) {
  return (
    <button className={`button-outline ${className}`} {...restProps}>
      {text}
    </button>
  );
}
