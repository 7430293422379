import PropTypes from "prop-types";
/* eslint-disable operator-linebreak */
import React, { Fragment, useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { BsLink45Deg } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from "react-modal";
import styled from "styled-components";
import Swal from "sweetalert2";

import api from "services/api";
import { COLORS, SPACINGS } from "services/constants";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");
ModalVincular.propTypes = {
  toggleFunction: PropTypes.shape({
    modalVincularIsOpen: PropTypes.bool,
    setModalVincularIsOpen: PropTypes.func,
  }),
  typeId: PropTypes.number,
};

export default function ModalVincular(props) {
  // eslint-disable-next-line no-undef
  const windowJS = window;
  const urlParams = new URLSearchParams(windowJS.location.search);
  const eventId = urlParams.get("event_id");

  const { toggleFunction, typeId } = props;
  const { modalVincularIsOpen: isOpen, setModalVincularIsOpen: setIsOpen } =
    toggleFunction;
  const [error, setError] = useState(false);
  const [comissarios, setComissarios] = useState([
    {
      name: "",
      steward_id: "",
      limit: "",
      selecionado: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modal-link",
  });

  function updateData(event, comissarioId) {
    if (event.target.value.includes("-") || event.target.value < 0) {
      return;
    }
    const comissariosChanged = comissarios.map((comissario) => {
      if (comissarioId === comissario.steward_id) {
        return {
          name: comissario.name,
          steward_id: comissario.steward_id,
          limit: event.target.value,
          selecionado: comissario.selecionado,
        };
      }
      return comissario;
    });
    setComissarios(comissariosChanged);
  }

  // eslint-disable-next-line no-undef
  document.onkeydown = function submit(evt) {
    if (evt.key === "Enter") {
      if (isOpen) {
        sendChanges();
      }
    }
  };

  function sendChanges() {
    setIsLoading(true);
    setError(false);
    const sendComissarios = {
      event_id: eventId,
      ticket_type_id: typeId,
      vinculacoes: comissarios
        .map((comissario) => {
          if (
            comissario.limit < 0 ||
            // eslint-disable-next-line no-restricted-globals
            isNaN(comissario.limit) ||
            comissario.limit === 0 ||
            comissario.limit === "0"
          ) {
            return false;
          }
          return {
            steward_id: comissario.steward_id,
            limit: comissario.limit,
            ticket_type_id: typeId,
          };
        })
        .filter(Boolean),
    };
    if (!sendComissarios.vinculacoes) {
      sendComissarios.vinculacoes = [];
    }
    api
      .post("steward/tickettype/connect", sendComissarios)
      .then(() => {
        setIsLoading(false);
        Swal.fire({
          title: t("alert.update.title"),
          icon: "success",
          confirmButtonColor: "#23b936",
          cancelButtonColor: "#188125",
          text: t("alert.update.text"),
          confirmButtonText: t("alert.button-text"),
        });
        setTimeout(() => {
          setIsOpen(false);
          setComissarios([]);
          // eslint-disable-next-line no-magic-numbers
        }, 1000);
      })
      .catch((err) => {
        Swal.fire({
          title: t("alert.update.error.title", { error: err.response.status }),
          icon: "error",
          text: t("alert.update.error.text"),
          confirmButtonText: t("alert.button-text"),
        });
      });
  }

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    setIsLoading(true);

    api
      .get(`list/stewards/event/${eventId}/${typeId}`)
      .then((res) => {
        const getComissariosResponse = [];
        res.data.forEach((item) => {
          if (item.limit === "") {
            getComissariosResponse.push({
              name: item.name,
              steward_id: item.id,
              limit: 0,
              selecionado: true,
            });
          } else {
            getComissariosResponse.push({
              name: item.name,
              steward_id: item.id,
              limit: item.limit,
              selecionado: false,
            });
          }
        });
        setComissarios(getComissariosResponse);
      })
      .catch((err) => {
        Swal.fire({
          title: t("alert.error.title", { error: err.response.status }),
          icon: "error",
          text: t("alert.error.text"),
        });

        setIsOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      <ModalContainer>
        {isLoading ? (
          <LoadingContainer>
            <LoadingIcon />
            <LoadingText>{t("loading")}</LoadingText>
          </LoadingContainer>
        ) : (
          <Fragment>
            <HeaderContainer>
              <HeaderText>{t("header")}</HeaderText>
              <CloseButton onClick={() => setIsOpen(false)}>
                <CloseIcon size={25} />
              </CloseButton>
            </HeaderContainer>
            <InfoTextBarContainer>
              <InfoText style={{ paddingLeft: SPACINGS.space2 }}>
                {t("label.name")}
              </InfoText>
              <InfoText
                style={{ paddingRight: SPACINGS.space2, textAlign: "center" }}
              >
                {t("label.limit")}
              </InfoText>
            </InfoTextBarContainer>
            <ComissariosContainer>
              {comissarios.map((comissario) => (
                <ComissarioContainer key={comissario.steward_id}>
                  <InfoText>{comissario.name}</InfoText>
                  <LimitInput
                    required
                    type="number"
                    name="limit"
                    value={parseInt(comissario.limit, 10)}
                    onChange={(event) =>
                      updateData(event, comissario.steward_id)
                    }
                    className="form-control"
                  />
                </ComissarioContainer>
              ))}
            </ComissariosContainer>
            <ButtonContainer>
              {error && <ErrorText>{error}</ErrorText>}
              <AssociarButton
                className="btn btn-primary c3 btn-block"
                onClick={() => sendChanges()}
              >
                {t("button")}
                <BsLink45Deg color={COLORS.gray} size={20} />
              </AssociarButton>
            </ButtonContainer>
          </Fragment>
        )}
      </ModalContainer>
    </Modal>
  );
}
// /////////////////////////////////// CONTAINERs /////////////////////////

const ButtonContainer = styled.div`
  width: 100%;
  padding-top: ${SPACINGS.space3};
  display: flex;
  justify-content: flex-end;
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const ComissariosContainer = styled.div`
  height: 60vh;
  overflow: overlay;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px ${COLORS.gray} solid;
`;
const ComissarioContainer = styled.div`
  display: flex;
  min-height: 42px;
  padding-right: ${SPACINGS.space4};
  align-items: center;
  border-bottom: 1px ${COLORS.gray} solid;
`;
const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: visible;
`;
const HeaderContainer = styled.div`
  padding: ${SPACINGS.space2};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 1px ${COLORS.gray} solid;
`;
const InfoTextBarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
`;
// /////////////////////////////////// TEXTs //////////////////////////////

const ErrorText = styled.span`
  padding-right: ${SPACINGS.space4};
  color: red;
`;
const LoadingText = styled.span`
  padding-top: ${SPACINGS.space3};
  color: ${COLORS.darkBlue};
  font-size: 26px;
`;
const InfoText = styled.span`
  width: 100%;
  color: ${COLORS.darkGray};
  font-size: 14px;
  padding-left: ${SPACINGS.space2};
`;
const HeaderText = styled.span`
  color: ${COLORS.darkGray};
  text-align: center;
  font-size: 16px;
`;
// /////////////////////////////////// INPUTs /////////////////////////////

const LimitInput = styled.input`
  height: 32px;
  padding-right: ${SPACINGS.space2};
`;

// /////////////////////////////////// BUTTONs ////////////////////////////

const AssociarButton = styled.button`
  height: 42px;
  border-radius: 5px;
  width: 100%;
  border: none;
  color: ${COLORS.white};
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
// /////////////////////////////////// ICONs //////////////////////////////

const LoadingIcon = styled(BiLoaderAlt)`
  color: ${COLORS.darkBlue};
  font-size: 80px;
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.darkGray};
  font-size: 15px;
`;
