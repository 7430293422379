//common
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import api from "services/api";

//assets
import "./styles.css";

export default function TermosUso() {
  const [text, setText] = useState("");
  const [version, setVersion] = useState("")
  const getData = () => {
    api
      .get("terms/current")
      .then((res) => {
        setText(res.data.text);
        setVersion(res.data.version)
      })
      .catch((error) => {
        return error;
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="content">
      <div className="termo-atual">
        <NavLink
          className="nav-link text-muted text-small"
          to="./docs/changelog.html"
        >
          <FontAwesomeIcon className="sidebar-icons" icon={faFileAlt} />{" "}
          <span>Termos de uso</span>{" "}
          <span className="badge badge-primary ml-auto">{`${version}`}</span>
        </NavLink>
        <hr></hr>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
}
