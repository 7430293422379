import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import "./styles.css";
import { DatePicker } from "components/datepicker";
import api from "services/api";
import ModalDetail from "./modalDetail";
import moment from "moment-timezone";
import Input from "components/input";
import Pagination from "components/Pagination";
import Loading from "components/loading/index.js";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";

const FilterCaixa = () => {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [dataInit, setDataInit] = useState(null);
  const [dataEnd, setDataEnd] = useState(null);
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [dataCaixa, setDataCaixa] = useState([]);
  const [operator, setOperator] = useState("");
  const [operators, setOperators] = useState([]);
  const [serial, setSerial] = useState();
  const [search, setSearch] = useState(true);
  const [caixa, setCaixa] = useState();
  const [tableSearch, setTableSearch] = useState({
    terminal: null,
    caixaOrOperator: null,
  });
  const [pageConfigCaixa, setPageConfigCaixa] = useState({
    page: 1,
    currentPage: 0,
    lastPage: 0,
    amount: 0,
    perPage: 25,
  });
  const [pageConfigTerminal, setPageConfigTerminal] = useState({
    page: 1,
    currentPage: 0,
    lastPage: 0,
    amount: 0,
    perPage: 25,
  });

  const openModalTerminal = (serial) => {
    setStatusModal("modal-show");
    setSerial(serial);
  };
  const openModalCaixa = (caixa, serial, operator) => {
    setStatusModal("modal-show");
    setCaixa(caixa);
    setSerial(serial);
    setOperator(operator);
  };

  const closeModal = () => {
    setStatusModal("modal-hidden");
    setCaixa();
    setSerial();
  };

  const getDataTerminal = (page, perPage, search) => {
    const paramsTerminal = {
      dateInit: dataInit
        ? moment(dataInit).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
        : null,
      dateEnd: dataEnd
        ? moment(dataEnd).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
        : null,
      page: page || pageConfigTerminal.page,
      perPage: perPage || pageConfigTerminal.perPage,
      search: search || tableSearch.caixaOrOperator,
    };
    api
      .get(`dash/terminalReport/${event_id}`, { params: paramsTerminal })
      .then((res) => {
        setPageConfigTerminal({
          page: res.data.page,
          currentPage: res.data.page,
          lastPage: res.data.lastPage,
          amount: res.data.total,
          perPage: res.data.perPage,
        });
        setDataCaixa(res.data.data);
        setSearch(false);
        document.getElementById("loading").classList.add("esconde");
      });
  };

  const getDataCaixa = (page, perPage, search) => {
    if (!page && !perPage) {
      document.getElementById("loading").classList.remove("esconde");
    }
    const paramsCaixa = {
      dateInit: dataInit
        ? moment(dataInit).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
        : null,
      dateEnd: dataEnd
        ? moment(dataEnd).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
        : null,
      page: page || pageConfigCaixa.page,
      perPage: perPage || pageConfigCaixa.perPage,
      search: search || tableSearch.caixaOrOperator,
    };
    api
      .get(`dash/caixaReport/${event_id}`, { params: paramsCaixa })
      .then((res) => {
        setPageConfigCaixa({
          page: res.data.page,
          currentPage: res.data.page,
          lastPage: res.data.lastPage,
          amount: res.data.total,
          perPage: res.data.perPage,
        });
        setOperators(res.data.data);
        document.getElementById("loading").classList.add("esconde");
        if (!page && !perPage) {
          getDataTerminal();
        }
      });
  };

  const exportReport = () => {
    let initial;
    let end;
    if (dataInit) {
      initial = moment(dataInit)
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if (dataEnd) {
      end = moment(dataEnd)
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    api
      .get(
        `exportReportXlsx?format=xlsx&id=${event_id}${
          initial ? `&dataInit=${initial}` : ""
        }${end ? `&dataEnd=${end}` : ""} `,
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"Relatório"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  useEffect(() => {
    if (search) {
      getDataCaixa();
    }
    // eslint-disable-next-line
  }, [event_id, search]);

  return (
    <>
      <PageHeader
        title="Relatório por caixas e terminais"
        text="Detalhamento dos dados do evento."
        btnAction={exportReport}
        btnText="Exportar relatório por caixas"
      />
      <Loading title="aguarde..." />
      <div className="mt-3 container-fluid" id="caixa-report">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-10 col-12">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="col-sm mb-2 filter-sale input-group"
                      style={{ padding: "0px" }}
                    >
                      <DatePicker
                        name="data-ini"
                        showTimeSelect
                        value={dataInit}
                        autoComplete="off"
                        onChange={(date) => setDataInit(date)}
                        placeholderText={t("components:datepicker.startDate2")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="col-sm mb-2 filter-sale input-group"
                      style={{ padding: "0px" }}
                    >
                      <DatePicker
                        name="data-ini"
                        showTimeSelect
                        value={dataEnd}
                        onChange={(date) => setDataEnd(date)}
                        autoComplete="off"
                        placeholderText={t("components:datepicker.endDate2")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-12">
                <button
                  className="btn btn-outline-primary"
                  onClick={(e) => setSearch(true)}
                >
                  aplicar filtros
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 card">
          <div className="card-body">
            <div className="subtitle">
              <div className="row d-flex justify-content-between">
                <div className="col-md-8 col-12 pl-3 d-flex align-items-center">
                  Resumo por caixa/operador
                </div>
                <div className="col-md-4 col-12 pr-3">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Pesquisa por caixa ou operador"
                    onBlur={() =>
                      getDataCaixa(1, 25, tableSearch.caixaOrOperator)
                    }
                    onKeyDown={() =>
                      getDataCaixa(1, 25, tableSearch.caixaOrOperator)
                    }
                    onChange={(e) =>
                      setTableSearch({
                        ...tableSearch,
                        caixaOrOperator: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="table table-responsive" id="table-caixa">
              <table
                id="table-totalsales"
                className="table table-sm mt-4 datatable-custom"
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">TERMINAL</th>
                    <th scope="col" className="text-center">
                      OPERADOR
                    </th>
                    <th scope="col" className="text-center">
                      CAIXA
                    </th>
                    <th scope="col" className="text-center">
                      AÇÕES
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {operators.map((item, i) => (
                    <tr key={i}>
                      <td>{item.serial_number}</td>
                      <td className="text-center">{item.operator}</td>
                      <td className="text-center">{item.caixa}</td>
                      <td
                        className="text-center caixa-link"
                        onClick={(e) =>
                          openModalCaixa(
                            item.caixa,
                            item.serial_number,
                            item.operator
                          )
                        }
                      >
                        ver detalhes
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                pageConfig={pageConfigCaixa}
                parentFunction={getDataCaixa}
              />
            </div>
          </div>
        </div>

        <div className="mt-3 card">
          <div className="card-body">
            <div className="subtitle">
              {" "}
              <div className="row d-flex justify-content-between">
                <div className="col-md-8 col-12 pl-3 d-flex align-items-center">
                  Resumo por terminal
                </div>
                <div className="col-md-4 col-12 pr-3">
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Pesquisa por terminal"
                    onKeyDown={() =>
                      getDataTerminal(1, 25, tableSearch.terminal)
                    }
                    onBlur={() => getDataTerminal(1, 25, tableSearch.terminal)}
                    onChange={(e) =>
                      setTableSearch({
                        ...tableSearch,
                        terminal: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="table table-responsive" id="table-caixa">
              <table
                id="table-totalsales"
                className="table table-sm mt-4 datatable-custom"
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">TERMINAL</th>
                    <th scope="col" className="text-center">
                      CONSUMO CASHLESS
                    </th>
                    <th scope="col" className="text-center">
                      RECARGAS CASHLESS
                    </th>
                    <th scope="col" className="text-center">
                      CANCELAMENTOS (TICKET)
                    </th>
                    <th scope="col" className="text-center">
                      TICKET (FICHAS)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataCaixa.map((item, i) => (
                    <tr key={i}>
                      <td
                        className="caixa-link"
                        onClick={(e) => openModalTerminal(item.serial_number)}
                      >
                        {item.serial_number}
                      </td>
                      <td className="text-center">
                        {noCentsCurrencyFormatter(item.consumed)}
                      </td>
                      <td className="text-center">
                        {noCentsCurrencyFormatter(item.recharge)}
                      </td>
                      <td className="text-center caixa-reversal">
                        - {noCentsCurrencyFormatter(item.canceled)}
                      </td>
                      <td className="text-center">
                        {noCentsCurrencyFormatter(item.ticket - item.canceled)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                pageConfig={pageConfigTerminal}
                parentFunction={getDataTerminal}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalDetail
        status={statusModal}
        callback={closeModal}
        serial={serial}
        caixa={caixa}
        event={event_id}
        dataInit={dataInit}
        dataEnd={dataEnd}
        operator={operator}
      />
    </>
  );
};

export default FilterCaixa;
