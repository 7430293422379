import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./style.css";
import AsyncCategoryInput from "components/asyncCategoryInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Col, Form } from "react-bootstrap";
import FormField from "components/FormField";
import New_api from "services/new-api";
import Swal from "sweetalert2";

ModalEventCategorization.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  item: PropTypes.number,
  setReload: PropTypes.func,
  categorizationId: PropTypes.number,
  onClose: PropTypes.func,
  route: PropTypes.string,
  categorizationName: PropTypes.string,
  defaultValue: PropTypes.arrayOf(
    PropTypes.objectOf({ label: PropTypes.string, value: PropTypes.number })
  ),
  isSubcategory: PropTypes.bool,
};

const formSchema = yup.object({
  name: yup.string().trim().required("O nome é obrigatório."),
  category: yup.number().optional(),
  subcategory: yup.array().optional(),
});

function ModalEventCategorization({
  isOpen,
  setIsOpen,
  onClose,
  item,
  isSubcategory,
  setReload,
  categorizationId,
  route,
  categorizationName,
  defaultValue,
}) {
  const [categoryId, setCategoryId] = useState(null);
  const [subcategoryId, setSubcategoryId] = useState(null);

  useEffect(() => {
    setValue("name", categorizationName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorizationName, isOpen]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  function closeModal() {
    onClose();
    setIsOpen(false);
    reset();
    setCategoryId(null);
    setSubcategoryId(null);
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const submitForm = async (form) => {
    setReload(true);

    let params = {
      name: form.name !== categorizationName ? form.name : "",
      child_ids: subcategoryId,
      parent_ids: categoryId,
    };

    const ids = defaultValue && defaultValue.map((item) => item.value);

    if (isSubcategory) {
      params.parent_ids = !categoryId ? ids : categoryId;
    } else {
      params.child_ids = !subcategoryId ? ids : subcategoryId;
    }

    if (!categorizationId) {
      await New_api.post(route, params)
        .then(async () => {
          await sleep(300);

          setReload(false);
          Swal.fire({
            title: "Criado!",
            icon: "success",
            text: `${
              isSubcategory ? "Subcategoria" : "Categoria"
            } criada com sucesso!`,
            confirmButtonText: "Fechar",
            confirmButtonColor: "#5B4299",
          });
        })
        .catch((err) => {
          const message = err.response.data.errors.name
            ? "Nome já existe e precisa ser único."
            : "Ocorreu um erro interno, por favor tente novamente";
          setReload(false);
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: message,
            confirmButtonText: "Fechar",
            confirmButtonColor: "#5B4299",
          });
        });
    } else {
      await New_api.put(`${route}/${categorizationId}`, params)
        .then(async () => {
          await sleep(300);

          setReload(false);
          Swal.fire({
            title: "Editado!",
            icon: "success",
            text: `${
              isSubcategory ? "Subcategoria" : "Categoria"
            } editada com sucesso!`,
            confirmButtonText: "Fechar",
            confirmButtonColor: "#5B4299",
          });
        })
        .catch((err) => {
          const message = err.response.data.errors.name
            ? "Nome já existe e precisa ser único."
            : "Ocorreu um erro interno, por favor tente novamente";
          setReload(false);
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: message,
            confirmButtonText: "Fechar",
            confirmButtonColor: "#5B4299",
          });
        });
    }
    closeModal();
    reset();

    setSubcategoryId(null);
    setCategoryId(null);
  };

  const subcategorySelected = (subcategory) => {
    const subcategoriesValue = subcategory.map(
      (subcategory) => subcategory.value
    );

    setSubcategoryId(subcategoriesValue);
  };

  const categorySelect = (category) => {
    const categoriesValue = category.map((category) => category.value);

    setCategoryId(categoriesValue);
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        contentLabel="CategorizationModal"
        className="shadow side-modal"
      >
        <div className="card-header">
          <h4 className="card-header-title">
            {categorizationId ? "Editar a" : "Criar nova"}{" "}
            {item === 0 ? "categoria" : "subcategoria"}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="card-body">
          <Form onSubmit={handleSubmit(submitForm)}>
            <Form.Row>
              <Col md="12">
                <FormField
                  label="Nome"
                  errorMessage={errors.name?.message}
                  controlId="validationName"
                >
                  <Form.Control
                    {...register("name")}
                    name="name"
                    type="text"
                    className="input"
                    isInvalid={errors.name?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
            <Form.Row>
              {item === 0 ? (
                <Fragment>
                  <Col md="12">
                    <FormField
                      label="Víncule subcategorias (Opcional)"
                      errorMessage={errors.subcategory?.message}
                      controlId="validationSubcategory"
                    >
                      <AsyncCategoryInput
                        {...register("subcategory")}
                        isMulti
                        defaultValue={defaultValue}
                        isSubcategory
                        isClearable={false}
                        callBack={subcategorySelected}
                      />
                    </FormField>
                  </Col>
                </Fragment>
              ) : (
                <Fragment>
                  <Col md="12">
                    <FormField
                      label="Víncule categorias (Opcional)"
                      errorMessage={errors.category?.message}
                      controlId="validationCategory"
                    >
                      <AsyncCategoryInput
                        {...register("category")}
                        isMulti
                        defaultValue={defaultValue}
                        isClearable={false}
                        callBack={categorySelect}
                      />
                    </FormField>
                  </Col>
                </Fragment>
              )}
            </Form.Row>

            <div
              style={{
                paddingBottom: "40px",
                justifyContent: "flex-end",
                display: "flex",
              }}
              className="form-group offset-8 col-4 mt-4"
            >
              <button
                style={{ width: "130px" }}
                type="submit"
                className="btn btn-primary c3 "
              >
                {categorizationId ? "Salvar" : "Criar"}
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
}

export default ModalEventCategorization;
