import React from "react";
import PropTypes from "prop-types";
import { badgeTypes } from "../../services/constants";
import "./styles.css";

Badge.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
};

export default function Badge({ status, text, className }) {
  return (
    <div className={`badge ${badgeTypes[status]} ${className}`}>{text}</div>
  );
}
