import React from "react";
import PropTypes from "prop-types";

ContainerFluid.propTypes = {
  children: PropTypes.node,
  containerId: PropTypes.string,
  className: PropTypes.string,
};

export default function ContainerFluid(props) {
  const { containerId, children, className } = props;

  return (
    <div id={containerId} className={`container-fluid ${className || ""}`}>
      {children}
    </div>
  );
}
