import React from "react";
import { SalesByTicket } from "./SalesByTicket";
import { SalesByOrigin } from "./SalesByOrigin";

export function TotalSalesOrPaidSales({
  sectors,
  totalTicketSales,
  absorbedTaxes,
  originSales,
  filter,
}) {
  return (
    <div className="row">
      <div className="col-lg-12 col-xl-6">
        <SalesByTicket
          sectors={sectors}
          totalTicketSales={totalTicketSales}
          absorbedTaxes={absorbedTaxes}
          filter={filter}
        />
      </div>
      <div className="col-lg-12 col-xl-6">
        <SalesByOrigin originSales={originSales} />
      </div>
    </div>
  );
}
