/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, Fragment } from "react";
import { useOnClickOutside } from "App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import OrganizationsInput from "components/organizations-input";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";

export default function ModalEvents(props) {
  const { t } = useTranslation();
  const [filterEventsStatus, setFilterEventsStatus] = useState("all");
  const [organizationName, setOrganizationName] = useState();
  const [organization_id, setOrganization_id] = useState();
  const [dataInit, setDataInit] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const apllyFilters = () => {
    const payload = {
      organization_id,
      startDate: moment(dataInit).format("YYYY-MM-DD"),
      endDate: moment(dataFim).format("YYYY-MM-DD"),
      statusFilter: filterEventsStatus,
    };

    props.filter(payload);
    closeFilter();
  };

  const chosenOrganization = (org_id) => setOrganization_id(org_id);

  const cleanFilters = () => {
    document.getElementById("status").value = "";
    setOrganizationName({
      label: "",
      value: "",
    });
    setDataInit(null);
    setDataFim(null);
    setFilterEventsStatus(null);

    let payload = {
      organization_id: null,
      startDate: null,
      endDate: null,
      statusFilter: null,
    };

    props.filter(payload);
    closeFilter();
  };

  // Click outside
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      closeFilter();
    }
  });

  function closeFilter() {
    props.callback("modal-hidden");
  }

  return (
    <Fragment>
      <div
        ref={ref}
        id="filter-modal"
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <div>
          <div className="card-header">
            <h4 className="card-header-title">
              {t("events:eventList.eventFiltersModal.title")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => closeFilter(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <DatePicker
                        label={t(
                          "events:eventList.eventFiltersModal.inputs.initialDate.label"
                        )}
                        leftIcon
                        name="data-ini"
                        value={dataInit}
                        onChange={(date) => setDataInit(date)}
                        showTimeSelect
                        showTime
                        placeholder={t(
                          "events:eventList.eventFiltersModal.inputs.initialDate.placeholder"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <DatePicker
                      showTime
                      leftIcon
                      marginLeft={[0, "-10rem", 0]}
                      label={t(
                        "events:eventList.eventFiltersModal.inputs.endDate.label"
                      )}
                      name="data-fim"
                      value={dataFim}
                      onChange={(date) => setDataFim(date)}
                      placeholder={t(
                        "events:eventList.eventFiltersModal.inputs.endDate.placeholder"
                      )}
                      showTimeSelect
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <label className="form-label mb-2" htmlFor="organization">
                  {t(
                    "events:eventList.eventFiltersModal.inputs.organizations.label"
                  )}
                </label>
                <OrganizationsInput
                  callBack={chosenOrganization}
                  defaultValue={organizationName}
                />
              </div>
              <div className="col-md-6 col-12">
                <label className="form-label mb-2" htmlFor="status">
                  {t("events:eventList.eventFiltersModal.inputs.status.label")}
                </label>
                <select
                  defaultValue={""}
                  className="form-control text-center"
                  name="status"
                  id="status"
                  placeholder={t(
                    "events:eventList.eventFiltersModal.inputs.status.placeholder"
                  )}
                  onChange={(e) => setFilterEventsStatus(e.target.value)}
                >
                  <option defaultValue value="">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.placeholder"
                    )}
                  </option>
                  <option value="all">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.options.allEvents"
                    )}
                  </option>
                  <option value="ativo">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.options.active"
                    )}
                  </option>
                  <option value="adiado">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.options.postponed"
                    )}
                  </option>
                  <option value="cancelado">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.options.canceled"
                    )}
                  </option>
                  <option value="encerrado">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.options.closed"
                    )}
                  </option>
                  <option value="offline">
                    {t(
                      "events:eventList.eventFiltersModal.inputs.status.options.offline"
                    )}
                  </option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12" style={{ marginTop: "35px" }}>
                <div
                  className="filter-button text-center"
                  id="cleanFilters"
                  onClick={cleanFilters}
                >
                  <span>
                    <FontAwesomeIcon icon={faFilter} />
                    {t(
                      "events:eventList.eventFiltersModal.buttons.clearFiltersText"
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-12" style={{ marginTop: "35px" }}>
                <div
                  className="filter-button text-center"
                  id="filter-button-sales"
                  onClick={apllyFilters}
                >
                  <span>
                    <FontAwesomeIcon icon={faFilter} className="mx-2" />
                    {t(
                      "events:eventList.eventFiltersModal.buttons.applyFiltersText"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
