import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import "./styles.css";
import Api from "../../../services/api.js";
import { cpfMask, phoneMask } from "../../../mask.js";
import { ToastContainer } from "react-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import api from "../../../services/api.js";

let container;

const UserData = () => {
  const [data, setData] = useState({
    id: "",
    document: "",
    phone: "",
    zip_code: "",
    street: "",
    neighborhood: "",
    complement: "",
    state: "",
    city: "",
    number: "",
    first_name: "",
    last_name: "",
    email: "",
    picture: "",
    img_address: "",
    img_document_front: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [userData, setUserData] = useState({});
  const [mostraEstado, setMostraEstado] = useState(1);
  const [senhaAtual, setSenhaAtual] = useState("");
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");

  const [loadingPicture, setLoadingPicture] = useState(false);
  const [loadingUserDoc, setLoadingUserDoc] = useState(false);
  const [loadingUserAddressDoc, setLoadingUserAddressDoc] = useState(false);

  const carregaUser = () => {
    let userId = localStorage.getItem("user_id");
    Api.get(`show/${userId}`)
      .then((res) => {
        setUserData(res.data);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("userFirst", res.data.user.first_name);
        localStorage.setItem("userLast", res.data.user.last_name);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    Api.get("profiles")
      .then((res) => {
        setData({
          ...data,
          id: res.data.user.id,
          document:
            res.data.user && res.data.user.document
              ? cpfMask(res.data.user.document)
              : "",
          phone:
            res.data.user && res.data.user.telephone
              ? phoneMask(res.data.user.telephone)
              : "",
          email: res.data.user?.email ? res.data.user?.email : "",
          first_name: res.data.user?.first_name
            ? res.data.user?.first_name
            : "",
          last_name: res.data.user?.last_name ? res.data.user?.last_name : "",
          picture: res.data.user?.picture ? res.data.user?.picture : "",
          zip_code: res.data.add?.zip_code ? res.data.add?.zip_code : "",
          street: res.data.add?.street ? res.data.add?.street : "",
          neighborhood: res.data.add?.neighborhood
            ? res.data.add?.neighborhood
            : "",
          complement: res.data.add?.complement ? res.data.add?.complement : "",
          state: res.data.add?.state ? res.data.add?.state : "",
          city: res.data.add?.city ? res.data.add?.city : "",
          number: res.data.add?.number ? res.data.add?.number : "",
          img_document_front: res.data.user?.img_document_front
            ? res.data.user?.img_document_front
            : "",
          img_address: res.data.user?.img_address
            ? res.data.user?.img_address
            : "",
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    carregaUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCep = () => {
    axios
      .get(`https://viacep.com.br/ws/${data.zip_code}/json`)
      .then((res) => {
        setData({
          ...data,
          street: res.data.logradouro,
          state: res.data.uf,
          city: res.data.localidade,
          neighborhood: res.data.bairro,
        });

        let x = document.getElementById("invalid-cep");
        if (!x.classList.contains("hide")) {
          x.classList.add("hide");
          x.classList.add("invalid-cep");
          x.classList.remove("invalid-cep-show");
        }
      })
      .catch((err) => {
        setData({
          ...data,
          street: "",
          state: "",
          city: "",
          neighborhood: "",
        });
        let x = document.getElementById("invalid-cep");
        if (x.classList.contains("hide")) {
          x.classList.remove("hide");
          x.classList.add("invalid-cep-show");
          x.classList.remove("invalid-cep");
        }
      });
  };

  const updateFirstName = (e) => {
    setData({
      ...data,
      first_name: e.target.value,
    });
  };

  const updateLastName = (e) => {
    setData({
      ...data,
      last_name: e.target.value,
    });
  };

  const updateEmail = (e) => {
    setData({
      ...data,
      email: e.target.value,
    });
  };

  const updateAddress = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const updateCpf = (e) => {
    setData({
      ...data,
      [e.target.name]: cpfMask(e.target.value),
    });
  };

  const updatePhone = (e) => {
    setData({
      ...data,
      [e.target.name]: phoneMask(e.target.value),
    });
  };

  const saveData = (e) => {
    e.preventDefault();
    Api.post("update", data)
      .then(() => {
        container.success("Usuário Atualizado com sucesso");
      })
      .catch((err) => {
        container.error(err);
      });
  };

  const handleUploadFile = (file, baseFolder, loadingSetter, fieldSetter) => {
    loadingSetter(true);

    let data = new FormData();
    data.append("image", file.target.files[0]);

    Api.post(`uploads?local=${baseFolder}`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => fieldSetter(res.data.url))
      .catch(() =>
        Swal.fire({
          title: "Ops! AQUI",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        })
      )
      .finally(() => loadingSetter(false));
  };

  const _handlePictureUpload = (result) => {
    setData({ ...data, picture: result });
  };

  const _handleUserDocumentUpload = (result, field) => {
    let {
      street,
      city,
      state,
      neighborhood,
      complement,
      number,
      zip_code,
      phone,
      ...userData
    } = data;
    userData[field] = result;
    userData["telephone"] = phone;
    setUserData((currentState) => ({
      ...currentState,
      [field]: result,
    }));

    api
      .post("user/update", { [field]: result })
      .then(() => {
        Swal.fire({
          title: `Documento alterado com sucesso!`,
          icon: "success",
          confirmButtonColor: "#23b936",
          text: "Alterações efetuadas!",
          confirmButtonText: "Ok",
        });
        setData({ ...data, [field]: result });
        carregaUser();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  let x = document.getElementById("informacoes");
  let y = document.getElementById("alterpassword");
  let z = document.getElementById("documentacao");

  const mudaestado = (valor) => {
    if (valor === 3) {
      if (!z.classList.contains("ativo")) {
        z.classList.add("ativo");
      }
      x.classList.remove("ativo");
      y.classList.remove("ativo");
    }

    if (valor === 2) {
      if (!y.classList.contains("ativo")) {
        y.classList.add("ativo");
      }
      x.classList.remove("ativo");
      z.classList.remove("ativo");
    }

    if (valor === 1) {
      if (!x.classList.contains("ativo")) {
        x.classList.add("ativo");
      }
      y.classList.remove("ativo");
      z.classList.remove("ativo");
    }
    setMostraEstado(valor);
  };

  const alterarSenha = (e) => {
    e.preventDefault();

    if (senhaAtual === "" || novaSenha === "" || confirmaSenha === "") {
      return Swal.fire({
        text: "Os campos, 'senha atual', 'nova senha' e 'confirmação de senha' são obrigatórios!",
        icon: "warning",
      });
    }

    if (confirmaSenha !== novaSenha) {
      return Swal.fire({
        text: "Os campos 'nova senha' e 'confirmação de senha' não são iguais!",
        icon: "warning",
      });
    }

    if (novaSenha.length < 6) {
      return Swal.fire({
        text: "A 'nova senha' tem que ter no mínimo 6 caracteres!",
        icon: "warning",
      });
    }
    Api.post("password/renew", { senha: senhaAtual, novasenha: novaSenha })
      .then((res) => {
        setSenhaAtual("");
        setNovaSenha("");
        setConfirmaSenha("");
        return Swal.fire({
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((err) => {
        return Swal.fire({
          text: err.response.data.message,
          icon: "warning",
        });
      });
  };

  return (
    <>
      <PageHeader
        title="Perfil"
        text={data.first_name + " " + data.last_name}
      />
      <div className="col" id="subheader">
        <ul className="nav nav-tabs nav-overflow header-tabs">
          <li className="nav-item">
            <span
              className="nav-link text-nowrap subheader-item ativo"
              id="informacoes"
              onClick={(e) => mudaestado(1)}
            >
              Informações
            </span>
          </li>
          <li className="nav-item">
            <span
              className="nav-link text-nowrap subheader-item"
              id="alterpassword"
              onClick={(e) => mudaestado(2)}
            >
              Alterar Senha
            </span>
          </li>
          <li className="nav-item">
            <span
              className="nav-link text-nowrap subheader-item"
              id="documentacao"
              onClick={(e) => mudaestado(3)}
            >
              Documentos
            </span>
          </li>
        </ul>
      </div>

      {mostraEstado === 1 ? (
        <div
          id="user-data"
          className="container-fluid mt-4"
          style={{ paddingBottom: "30px" }}
        >
          <div className="col-12">
            <ToastContainer
              ref={(ref) => (container = ref)}
              className="toast-top-right"
            />
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="profile-picture col-2">
                    {data.picture !== null ? (
                      <div
                        className="user-img shadow-sm"
                        style={{
                          backgroundImage: `url(${data.picture})`,
                        }}
                      ></div>
                    ) : (
                      <div
                        className="user-img b2 col-md-2 shadow-sm"
                        style={{ backgroundImage: "url(../../avatar.jpg)" }}
                      ></div>
                    )}
                  </div>

                  <div className="col-md-10 col-12">
                    <div className="input-group" style={{ marginTop: "2rem" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faImage} />
                          &nbsp; Imagem
                        </span>
                      </div>
                      <div className="col-md-10 col-8 custom-file">
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          disabled={loadingPicture}
                          className="custom-file-input"
                          id="picture"
                          onChange={(e) => {
                            handleUploadFile(
                              e,
                              "avatares",
                              setLoadingPicture,
                              _handlePictureUpload
                            );
                          }}
                          aria-describedby="inputGroupFileAddon01"
                        ></input>
                        <label
                          className="custom-file-label"
                          id="picture-label"
                          htmlFor="picture"
                        >
                          <div
                            hidden={!loadingPicture}
                            className="spinner-border spinner-border-sm text-primary mr-2"
                            role="status"
                          >
                            <span className="sr-only">Carregando...</span>
                          </div>

                          {loadingPicture ? "Carregando..." : "Procurar..."}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <label className="title-label" htmlFor="nome">
                      Nome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.first_name}
                      onChange={updateFirstName}
                      name="first_name"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="title-label" htmlFor="sobrenome">
                      Sobrenome
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.last_name}
                      onChange={updateLastName}
                      name="email"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="title-label" htmlFor="email">
                      E-mail
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.email}
                      onChange={updateEmail}
                      name="email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <label className="title-label" htmlFor="cpfnj">
                      CPF / CNPJ
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.document}
                      onChange={updateCpf}
                      name="document"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="title-label" htmlFor="phone">
                      Telefone
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.phone}
                      onChange={updatePhone}
                      name="phone"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="title-label" htmlFor="zip_code">
                      CEP
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.zip_code}
                      onChange={updateAddress}
                      onBlur={getCep}
                      name="zip_code"
                    />
                    <div className="invalid-cep hide" id="invalid-cep">
                      <span style={{ color: "#e63757" }}>Cep Inválido.</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <label className="title-label" htmlFor="adress">
                      Endereço
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.street}
                      onChange={updateAddress}
                      name="adress"
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="title-label" htmlFor="number">
                      Número
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.number}
                      onChange={updateAddress}
                      name="number"
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="title-label" htmlFor="complement">
                      Complemento
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.complement}
                      onChange={updateAddress}
                      name="complement"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-2">
                    <label className="title-label" htmlFor="neighborhood">
                      Bairro
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.neighborhood}
                      onChange={updateAddress}
                      name="neighborhood"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="title-label" htmlFor="city">
                      Cidade
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.city}
                      onChange={updateAddress}
                      name="city"
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="title-label" htmlFor="state">
                      Estado
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={data.state}
                      onChange={updateAddress}
                      name="state"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-button">
              <button
                className="btn btn-primary col-md-3 c3"
                onClick={saveData}
              >
                Salvar Perfil
              </button>
            </div>
          </div>
        </div>
      ) : mostraEstado === 2 ? (
        <>
          <div className="container-fluid mt-4">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Alterar senha de acesso</h4>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label className="title-label" htmlFor="nome">
                      Senha atual
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={senhaAtual}
                      onChange={(e) => setSenhaAtual(e.target.value)}
                      name="old_password"
                    />
                  </div>
                  <div className="form-group">
                    <label className="title-label" htmlFor="nome">
                      Nova senha
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={novaSenha}
                      onChange={(e) => setNovaSenha(e.target.value)}
                      name="new_password"
                    />
                  </div>
                  <div className="form-group">
                    <label className="title-label" htmlFor="nome">
                      Confirmar nova senha
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={confirmaSenha}
                      onChange={(e) => setConfirmaSenha(e.target.value)}
                      name="confirm_password"
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary c3"
                      onClick={alterarSenha}
                    >
                      Alterar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div id="user-docs" className="container-fluid mt-4">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">Documentos do usuário</h4>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="card card-img-upload">
                      <div className="card-body row">
                        <div className="col-md-9">
                          <span className="text-small">
                            Documento de identificação (RG ou CNH)
                          </span>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faImage} />
                              </span>
                            </div>
                            <div className="custom-file">
                              <input
                                type="file"
                                accept="image/png,image/jpeg,application/pdf"
                                disabled={loadingUserDoc}
                                className="custom-file-input"
                                aria-describedby="inputGroupFileAddon01"
                                id="user-doc"
                                onChange={(e) => {
                                  handleUploadFile(
                                    e,
                                    "documentos",
                                    setLoadingUserDoc,
                                    (result) =>
                                      _handleUserDocumentUpload(
                                        result,
                                        "img_document_front"
                                      )
                                  );
                                }}
                              ></input>
                              <label
                                className="custom-file-label"
                                htmlFor="user-doc"
                              >
                                <div
                                  hidden={!loadingUserDoc}
                                  className="spinner-border spinner-border-sm text-primary mr-2"
                                  role="status"
                                >
                                  <span className="sr-only">Carregando...</span>
                                </div>

                                {loadingUserDoc
                                  ? "Carregando..."
                                  : "Procurar documento..."}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="img-uploaded"
                            style={{
                              backgroundImage: `url(${data.img_document_front})`,
                            }}
                          ></div>
                        </div>
                        <span className="upload-desc text-small ml-3">
                          Arquivos JPEG, JPG, PDF ou PNG.{" "}
                          <b>* Tamanho máximo 2MB</b>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card card-img-upload">
                      <div className="card-body row">
                        <div className="col-md-9">
                          <span className="text-small">
                            Comprovante de endereço
                          </span>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faImage} />
                              </span>
                            </div>
                            <div className="custom-file">
                              <input
                                type="file"
                                accept="image/png,image/jpeg,application/pdf"
                                disabled={loadingUserAddressDoc}
                                className="custom-file-input"
                                aria-describedby="inputGroupFileAddon01"
                                id="user-comp"
                                onChange={(e) => {
                                  handleUploadFile(
                                    e,
                                    "documentos",
                                    setLoadingUserAddressDoc,
                                    (result) =>
                                      _handleUserDocumentUpload(
                                        result,
                                        "img_address"
                                      )
                                  );
                                }}
                              ></input>
                              <label
                                className="custom-file-label"
                                htmlFor="user-comp"
                              >
                                <div
                                  hidden={!loadingUserAddressDoc}
                                  className="spinner-border spinner-border-sm text-primary mr-2"
                                  role="status"
                                >
                                  <span className="sr-only">Carregando...</span>
                                </div>

                                {loadingUserAddressDoc
                                  ? "Carregando..."
                                  : "Procurar comprovante..."}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="img-uploaded"
                            style={{
                              backgroundImage: `url(${data.img_address})`,
                            }}
                          ></div>
                        </div>
                        <span className="upload-desc text-small ml-3">
                          Arquivos JPEG, JPG, PDF ou PNG.{" "}
                          <b>* Tamanho máximo 2MB</b>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserData;
