import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import ReactTooltip from "react-tooltip";
// import { ToastContainer } from "react-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faListUl,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import EmptyData from "components/empty-data";
import DataTable, { createTheme } from "react-data-table-component";
import api from "services/api";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import Loading from "components/loading";

// let container;

const EventStockNew = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [itens, setItens] = useState([]);
  const [filter, setFilter] = useState({
    name: "",
  });

  const [activeSide, setActiveSide] = useState(true);
  const [menus, setMenus] = useState([]);
  const [activeMenu, setActiveMenu] = useState({
    id: "",
    event_id: "",
  });

  const [tableValues, setTableValues] = useState({
    quantity: "",
    inicial: "",
    lancamentos: "",
    movimentacoes: "",
    custo: "",
    vendidos: "",
    inventario: "",
  });

  const updateFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  function changeColor(id, row) {
    const card = document.getElementById(`row-${id}`);
    if (card.classList.contains("row-active")) {
      card.classList.remove("row-active");
    } else {
      card.classList.add("row-active");
    }
  }

  function somaGeral(row) {
    if (!row.selecionado) {
      setTableValues({
        ...tableValues,
        quantity: Number(tableValues.quantity) + Number(row.quantidade),
        inicial: Number(tableValues.inicial) + Number(row.inicial),
        lancamentos: Number(tableValues.lancamentos) + Number(row.lancamentos),
        movimentacoes: Number(tableValues.movimentacao + row.movimentacao),
        custo: Number(tableValues.custo) + Number(row.customedio),
        vendidos: Number(tableValues.vendidos) + Number(row.vendidos),
        inventario: Number(tableValues.inventario) + Number(row.inventario),
      });
    } else {
      setTableValues({
        ...tableValues,
        quantity: Number(tableValues.quantity) - Number(row.quantidade),
        inicial: Number(tableValues.inicial) - Number(row.inicial),
        lancamentos: Number(tableValues.lancamentos) - Number(row.lancamentos),
        movimentacoes:
          Number(tableValues.movimentacoes) - Number(row.movimentacao),
        custo: Number(tableValues.custo) - Number(row.customedio),
        vendidos: Number(tableValues.vendidos) - Number(row.vendidos),
        inventario: Number(tableValues.inventario) - Number(row.inventario),
      });
    }
  }

  useEffect(() => {
    api.get(`stock/exist/menu/event/${event_id}`).then((res) => {
      if (res.data.length <= 0) {
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
        Swal.fire({
          title: "Catalógo de produtos",
          icon: "warning",
          text: "Você não possui itens atrelados a um cardápio, para configurar um estoque adicione itens para começar:",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "adicionar itens agora",
          cancelButtonText: "fazer isso depois",
        })
          .then((result) => {
            if (result.value) {
              window.location.href =
                window.location.pathname.substring(
                  0,
                  window.location.pathname.lastIndexOf("/") + 1
                ) +
                "menu-event-new?event_id=" +
                event_id;
            } else {
              window.location.href =
                window.location.pathname.substring(
                  0,
                  window.location.pathname.lastIndexOf("/") + 1
                ) +
                "dashboard?event_id=" +
                event_id;
            }
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      } else {
        setMenus(res.data);
        setActiveMenu({
          event_id: event_id,
          id: res.data[0].id,
        });
      }
    });
  }, [event_id]);

  const columns = [
    {
      name: "",
      sortable: true,
      selectable: true,
      cell: (row, index) => (
        <input
          type="checkbox"
          checked={row.selecionado}
          name={"checkbox-" + row.id}
          onChange={(e) => {
            if (row.selecionado === true) {
              row.selecionado = false;
            } else {
              row.selecionado = true;
            }
            setItens([...itens]);
          }}
          onClick={(e) => {
            changeColor(row.id, row);
            somaGeral(row);
          }}
        />
      ),
    },
    {
      name: "Nome",
      selector: "nome",
      sortable: true,
    },
    {
      name: "Inicial",
      selector: "inicial",
      sortable: true,
    },
    {
      name: "Lançamentos",
      selector: "lancamentos",
      sortable: true,
    },
    {
      name: "Movimentações",
      selector: "movimentacao",
      sortable: true,
    },
    {
      name: "Vendidos",
      selector: "vendidos",
      sortable: true,
    },
    {
      name: "Quantidade",
      selector: "quantidade",
      sortable: true,
    },
    {
      name: "Custo médio",
      sortable: true,
      cell: (row, index) => (
        <span>
          {Number(row.customedio).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
    {
      name: "Inventário",
      sortable: true,
      cell: (row, index) => (
        <span>
          {Number(row.inventario).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
  ];

  const colunas = [
    {
      name: "",
      sortable: true,
      selectable: true,
      cell: (row, index) => (
        <input
          type="checkbox"
          checked={row.selecionado}
          name={"checkbox-" + row.id}
          onChange={(e) => {
            if (row.selecionado === true) {
              row.selecionado = false;
            } else {
              row.selecionado = true;
            }
            setItens([...itens]);
          }}
          onClick={(e) => {
            changeColor(index);
            somaGeral(row);
          }}
        />
      ),
    },
    {
      name: "Quantidade",
      selector: "quantidade",
      sortable: true,
    },
    {
      name: "Custo médio",
      sortable: true,
      cell: (row, index) => (
        <span>
          {Number(row.customedio).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
    {
      name: "Inventário",
      sortable: true,
      cell: (row, index) => (
        <span>
          {Number(row.inventario).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (activeMenu.id !== "" && activeMenu.id) {
      api
        .get(`stock/event/${activeMenu.event_id}/menu/${activeMenu.id}`)
        .then((res) => {
          if (res.data.length <= 0) {
            Swal.fire({
              title: "Catalógo de produtos",
              icon: "warning",
              text: "Você já efetuou algumas vendas sem lançar estoque, para prosseguir você precisa setar um estoque mínimo com a quantidade vendida. Deseja configurar seu estoque inicial com base em suas vendas?",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#95aac9",
              confirmButtonText: "criar estoque base agora",
              cancelButtonText: "fazer isso depois",
            })
              .then((result) => {
                if (result.value) {
                  window.location.href =
                    window.location.pathname.substring(
                      0,
                      window.location.pathname.lastIndexOf("/") + 1
                    ) +
                    "event-stock-trans?type=4&event_id=" +
                    event_id +
                    "&menu_id=" +
                    activeMenu.id;
                } else {
                  window.location.href =
                    window.location.pathname.substring(
                      0,
                      window.location.pathname.lastIndexOf("/") + 1
                    ) +
                    "dashboard?event_id=" +
                    event_id;
                }
              })
              .catch(() => {
                Swal.fire({
                  title: "Ops!",
                  icon: "error",
                  text: "Ocorreu um erro interno, por favor tente novamente",
                  confirmButtonText: "Ok",
                });
              });
          }

          let aux = [];
          res.data.forEach((item, i) => {
            aux.push({
              ...item,
              id: i,
            });
          });

          setItens(aux);
          if (document.getElementById("loading")) {
            document.getElementById("loading").classList.add("esconde");
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu, event_id]);

  createTheme("ticket", {
    text: {
      primary: "#4F6C7C",
      secondary: "#CBC1BF",
      font: "roboto",
    },
    headertext: {
      primary: "#2F81DA",
    },
    divider: {
      default: "#e9f2f2",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
      divider: {
        default: "#edf2f9",
      },
    },
    button: {
      default: "#3F25A8",
      focus: "#00A7E1",
      hover: "#20C4F4",
      disabled: "rgba(0, 0, 0, 0.12)",
    },
  });

  return (
    <>
      <Loading title="aguarde..." />

      <PageHeader title="Estoque" text="Composição do estoque"></PageHeader>

      <ReactTooltip className="tooltip-custom" effect="solid" place="top" />

      <div id="navigationheader" className="shadow-sm">
        <div className="menu content">
          <ul className="nav justify-content">
            {menus.map((item, i) => (
              <li className="nav-item" key={i}>
                <span
                  className={
                    activeMenu.id === item.id ? "nav-link ativo" : "nav-link"
                  }
                  data-toggle="pill"
                  style={{ cursor: "pointer" }}
                  id="estoque-1"
                  value={item.id}
                  onClick={(e) => {
                    setActiveMenu({
                      ...activeMenu,
                      event_id: event_id,
                      id: item.id,
                    });
                  }}
                >
                  {item.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="container-fluid mt-4" id="page-menu-items">
        {/* <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        /> */}

        <div className="row">
          <div className="col-md-4">
            <NavLink
              exact
              to={`/bar/event-stock-trans?type=${1}&event_id=${event_id}&menu_id=${
                activeMenu.id
              }`}
              className="btn btn-outline-gray"
            >
              <FontAwesomeIcon icon={faPlus} /> adicionar novo centro de estoque
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink
              exact
              to={`/bar/event-stock-trans?type=${2}&event_id=${event_id}&menu_id=${
                activeMenu.id
              }`}
              className="btn btn-secondary"
            >
              <FontAwesomeIcon icon={faExchangeAlt} /> transferência entre
              centros
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink
              exact
              to={`/bar/event-stock-trans?type=${3}&event_id=${event_id}&menu_id=${
                activeMenu.id
              }`}
              className="btn btn-secondary"
            >
              <FontAwesomeIcon icon={faListUl} /> lançamentos e remoção de
              estoque
            </NavLink>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <div className="card-search d-flex">
              <FontAwesomeIcon icon={faSearch} className="icon-search" />
              <input
                className="input-custom"
                value={filter.name}
                name="name"
                onChange={updateFilter}
                autoComplete="none"
                placeholder="Pesquisar por nome do produto"
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card">
              <div className="w-100 d-flex justify-content-between pt-2 px-4 pb-2">
                <div className="flex-start">
                  {/* <span className="span-default"> estoque geral {'>'} listagem de produtos</span> */}
                </div>
                <div className="d-flex" align="right">
                  <span
                    className={!activeSide ? "span-active" : "span-default"}
                  >
                    {" "}
                    visualização simples
                  </span>
                  <div
                    className="custom-control custom-switch"
                    style={{ paddingLeft: "3em" }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"switch"}
                      name={"switch"}
                      onChange={(e) => {
                        if (activeSide === true) {
                          setActiveSide(false);
                        } else {
                          setActiveSide(true);
                        }
                      }}
                      value={activeSide}
                      defaultChecked={activeSide}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"switch"}
                    ></label>
                  </div>
                  <span className={activeSide ? "span-active" : "span-default"}>
                    {" "}
                    visualização completa
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 table-responsive mobile-max"
              style={{ paddingBottom: "60px" }}
            >
              <DataTable
                className={"tabela card"}
                columns={activeSide ? columns : colunas}
                noHeader={true}
                data={itens}
                pagination={true}
                paginationPerPage={20}
                paginationRowsPerPageOptions={[20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Resultados por páginas:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: false,
                }}
                noDataComponent={
                  <EmptyData title="Nenhum resultado disponível." />
                }
                theme="ticket"
              ></DataTable>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-page hidden-mobile">
        <div className="my-auto">
          <div className="row">
            <div className="col-md-2 my-auto">
              <span className="span-white">Soma Geral</span>
            </div>
            <div className="col-md-10">
              <table className="footer-table">
                <thead>
                  <tr>
                    <th>inicial</th>
                    <th>lançamentos</th>
                    <th>movimentações</th>
                    <th>vendidos</th>
                    <th>quantidade</th>
                    <th>custo médio</th>
                    <th>inventário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{tableValues.inicial ? tableValues.inicial : 0}</td>
                    <td>
                      {tableValues.lancamentos ? tableValues.lancamentos : 0}
                    </td>
                    <td>
                      {tableValues.movimentacoes
                        ? tableValues.movimentacoes
                        : 0}
                    </td>
                    <td>{tableValues.vendidos ? tableValues.vendidos : 0}</td>
                    <td>{tableValues.quantity ? tableValues.quantity : 0}</td>
                    <td>
                      {tableValues.custo
                        ? Number(tableValues.custo).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : 0}
                    </td>
                    <td>
                      {tableValues.inventario
                        ? Number(tableValues.inventario).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventStockNew;
