import React from "react";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import CustomButtonSpinner from "components/buttonSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Exporter = ({
  startOnChange,
  endOnChange,
  startValue,
  endValue,
  isLoading,
  exportFn,
  btnExportText,
  tipsText,
  headerText,
  tipsTitle,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">
            {headerText}
            <span className="tips">
              <img
                src="./icons/information.png"
                alt=""
                className="fluid imagem-quest"
              />
              <div className="tips-content">
                <h3 className="tips-header">{tipsTitle}</h3>
                {tipsText}
              </div>
            </span>
          </h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <DatePicker
                leftIcon
                label={t("components:datepicker.startDate")}
                name={"data-ini"}
                showTimeSelect
                value={startValue}
                onChange={startOnChange}
              />
            </div>
            <div className="col-md-4">
              <DatePicker
                leftIcon
                name={"data-fim"}
                showTimeSelect
                value={endValue}
                onChange={endOnChange}
                label={t("components:datepicker.endDate")}
              />
            </div>
            <div className="col-md-4 d-flex align-items-end">
              <CustomButtonSpinner
                customStyle={"btn btn-primary col-md-12"}
                triggerOnClick={exportFn}
                isLoading={isLoading}
                text={btnExportText}
              >
                <FontAwesomeIcon icon={faFileExcel} />
              </CustomButtonSpinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
