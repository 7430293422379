import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaRegCalendar } from "react-icons/fa";
import styled from "styled-components";
import * as yup from "yup";
import axios from "axios";
import ApproveButton from "components/approveButton";
import ContainerFluid from "components/containers/container-fluid";
import { DatePicker } from "components/datepicker";
import FileUpload from "components/file-upload";
import RefuseButton from "components/refuseButton";
import SideModal from "components/side-modal";
import TransferInfos from "components/transfer-modals/transfer-infos";
import { destinyKeys, finalityKeys } from "services/constants";
import New_api from "services/new-api";
import { useTranslation } from "react-i18next";

const originKeys = {
  ticket: "Ticket",
  "a&b": "A&B",
};

TransferDetailsModal.propTypes = {
  show: PropTypes.bool,
  transfer: PropTypes.object,
  onClose: PropTypes.func,
  onRefuse: PropTypes.func,
  onApprove: PropTypes.func,
  defaultEventOrgInfo: PropTypes.object,
  statusList: PropTypes.array,
  transactionStatus: PropTypes.object,
  handleTransactionStatus: PropTypes.func,
  statusOptionsListBuilder: PropTypes.func,
  setSelectedTransfer: PropTypes.func,
};

export default function TransferDetailsModal(props) {
  const {
    show,
    transfer,
    onClose,
    onRefuse,
    onApprove,
    currency,
    currencySymbol,
    defaultEventOrgInfo,
    statusList,
    transactionStatus,
    handleTransactionStatus,
    statusOptionsListBuilder,
    setSelectedTransfer,
  } = props;

  const { t } = useTranslation("transfers");

  const updateTransferSchema = yup.object({
    status: yup.string().oneOf(["approve", "refuse"]).nullable(),
    receipt: yup
      .string()
      .when("status", (status, field) =>
        status === "approve"
          ? field.required(t("transfers:validation.receipt"))
          : field.nullable()
      ),
    destiny: yup
      .string()
      .oneOf(["organizer", "third party"])
      .required(t("transfers:validation.destiny")),
    refuse_reason: yup
      .string()
      .trim()
      .when("status", (status, field) =>
        status === "refuse"
          ? field.required(t("transfers:validation.refuse_reason"))
          : field.nullable()
      ),
    last_updated_status: yup
      .date()
      .default(new Date())
      .typeError(t("transfers:validation.last_updated_status"))
      .required(t("transfers:validation.last_updated_status")),
    origin: yup
      .string()
      .required(t("transfers:validation.origin"))
      .oneOf(["ticket", "a&b"]),
    category: yup
      .string()
      .required(t("transfers:validation.category"))
      .oneOf(["advance", "transfer"]),
    finality: yup
      .string()
      .required(t("transfers:validation.finality"))
      .oneOf([
        "artist",
        "air",
        "food",
        "marketing",
        "bar",
        "structure",
        "operation",
        "online_anticipation",
        "pos_anticipation",
      ]),
  });

  const [transactionInfos, setTransactionInfos] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [justificationArchive, setJustificationArchive] = useState(null);
  const [customTransfer, setCustomTransfer] = useState();
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "all",
    defaultValues: {
      status: "",
      receipt: "",
      refuse_reason: "",
      last_updated_status: new Date(),
      origin: transfer?.origin || "",
      destiny: transfer?.destiny || "",
      category: transfer?.category || "",
      finality: transfer?.finality || "",
    },
    resolver: yupResolver(updateTransferSchema),
  });

  const status = watch("status");
  const lastUpdatedStatus = watch("last_updated_status");

  const origin = transfer?.origin || null;

  const category = transfer?.category || null;

  const categoryKeys = {
    advance: "Adiantamento",
    transfer: "Transferência",
  };
  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleEmptyField = (fieldName) => {
    clearErrors(fieldName);
  };

  const handleFormSubmit = (form) => {
    setLoading(true);
    if (form.status === "approve") {
      const data = {
        action: form.status,
        receipt_link: form.receipt,
        last_updated_status: form.last_updated_status,
        origin: form.origin,
        category: form.category,
        finality: form.finality,
        destiny: form.destiny,
        transfer_transaction_status_id:
          transactionInfos.transfer_transaction_status_id,
      };
      New_api.put(`transfers/${customTransfer.id}/approve`, data)
        .then((result) => onApprove(result.data, null, transactionInfos))
        .catch((error) => onApprove(null, error))
        .finally(() => setLoading(false));
    } else {
      const data = {
        action: "disapprove",
        refunse_reason: form.refuse_reason,
        last_updated_status: form.last_updated_status,
        origin: form.origin,
        category: form.category,
        finality: form.finality,
        destiny: form.destiny,
        transfer_transaction_status_id:
          transactionInfos.transfer_transaction_status_id,
      };

      New_api.put(`transfers/${customTransfer.id}/disapprove`, data)
        .then((result) => onRefuse(result.data, null, transactionInfos))
        .catch((error) => onRefuse(null, error))
        .finally(() => setLoading(false));
    }
  };

  const categorySelected = (category) => {
    setValue("category", category.target.value);
  };

  const originSelected = (origin) => {
    setValue("origin", origin.target.value);
  };

  const finalitySelected = (finality) => {
    setValue("finality", finality.target.value);
  };

  const destinySelected = (destiny) => {
    setValue("destiny", destiny.target.value);
  };

  const handleApproveOrRefuse = (status, infos) => {
    if (status === "PAGO") {
      setValue("status", "approve");
      setTransactionInfos(infos);
    } else if (status === "RECUSADO") {
      setValue("status", "refuse");
      setTransactionInfos(infos);
    } else {
      if (status === "SOLICITADO") {
        setSelectedTransfer((prevState) => ({
          ...prevState,
          refunse_reason: null,
          receipt_link: null,
        }));
      }
      setValue("status", "");
    }
  };

  useEffect(() => {
    reset({
      status: "",
      receipt: "",
      refuse_reason: "",
      last_updated_status: new Date(),
      origin: "",
      category: "",
      finality: "",
    });
  }, [show, reset]);

  useEffect(() => {
    setCustomTransfer(transfer);
    if (transfer) {
      setValue("origin", transfer.origin || "");
      setValue("category", transfer.category);
      setValue("finality", transfer.finality);
    }
    if (transfer?.justification_document_link) {
      const justificationFile = async () => {
        try {
          const { headers } = await axios.get(
            transfer?.justification_document_link
          );
          setJustificationArchive({
            type: `Arquivo-${headers["content-type"].split("/").pop()}`,
            size: `${(Number(headers["content-length"]) / 1024).toFixed(2)} KB`,
          });
        } catch (error) {
          console.error(error);
        }
      };

      justificationFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfer]);

  return (
    <SideModal
      show={show}
      title={t("transfers:detailsModal.title")}
      containerClassName="min-vh-100"
      onClose={handleClose}
    >
      {customTransfer && (
        <ContainerFluid className="d-flex flex-column flex-grow-1 justify-content-between">
          <TransferInfos
            currency={currency}
            defaultEventOrgInfo={defaultEventOrgInfo}
            justificationArchive={justificationArchive}
            transactionStatus={transactionStatus}
            handleTransactionStatus={handleTransactionStatus}
            handleApproveOrRefuse={handleApproveOrRefuse}
            statusList={statusList}
            currencySymbol={currencySymbol}
            transfer={customTransfer}
            onClose={handleClose}
            onEditTransfer={setCustomTransfer}
            statusOptionsListBuilder={statusOptionsListBuilder}
          />

          {status && (
            <Form
              className="d-flex flex-column flex-grow-1"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <FormLabel>
                    {status === "approve"
                      ? t("transfers:form.aprovalDate")
                      : t("transfers:form.refuseDate")}
                  </FormLabel>

                  <DatePicker
                    showTimeInput
                    placeholderText="Selecione a data e hora"
                    todayButton
                    name="last_updated_status"
                    value={lastUpdatedStatus}
                    timeIntervals={15}
                    maxDate={new Date()}
                    onChange={(date) => setValue("last_updated_status", date)}
                    rightIconChild={
                      <CalendarIcon
                        className={
                          errors.last_updated_status?.message
                            ? "text-danger"
                            : ""
                        }
                      />
                    }
                  />
                  {errors.last_updated_status?.message && (
                    <div class="invalid-feedback d-block">
                      {errors.last_updated_status.message}
                    </div>
                  )}
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>{t("transfers:form.category")}</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={
                      transfer && transfer.category
                        ? Object.keys(categoryKeys).find(
                            (category) =>
                              category.toLowerCase() ===
                              transfer.category.toLowerCase()
                          )
                        : ""
                    }
                    onBlur={() => handleEmptyField("category")}
                    onChange={categorySelected}
                    isInvalid={errors.category?.message}
                  >
                    <option value="" disabled>
                    {t("transfers:form.selectCategory")}
                    </option>
                    {Object.keys(categoryKeys).map((key) => (
                      <option key={key} value={key} selected={key === category}>
                        {categoryKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.category?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md={6}>
                  <Form.Label>{t("transfers:form.origin")}</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={
                      transfer && transfer.origin
                        ? Object.keys(originKeys).find(
                            (category) =>
                              category.toLowerCase() ===
                              transfer.origin.toLowerCase()
                          )
                        : ""
                    }
                    onBlur={() => handleEmptyField("origin")}
                    onChange={originSelected}
                    isInvalid={errors.origin?.message}
                  >
                    <option value="" disabled>
                    {t("transfers:form.selectOrigin")}
                    </option>
                    {Object.keys(originKeys).map((key) => (
                      <option key={key} value={key} selected={key === origin}>
                        {originKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.origin?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>{t("transfers:form.finality")}</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("finality")}
                    isInvalid={errors.finality?.message}
                    defaultValue={
                      transfer && transfer.finality
                        ? Object.keys(finalityKeys).find(
                            (finality) =>
                              finality.toLowerCase() ===
                              transfer.finality.toLowerCase()
                          )
                        : ""
                    }
                    onBlur={() => handleEmptyField("finality")}
                    onChange={finalitySelected}
                  >
                    <option value="" disabled>
                    {t("transfers:form.selectFinality")}
                    </option>
                    {Object.keys(finalityKeys).map((key) => (
                      <option key={key} value={key}>
                        {finalityKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.finality?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>{t("transfers:form.destination")}</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("destiny")}
                    isInvalid={errors.destiny?.message}
                    defaultValue={
                      transfer && transfer.destiny
                        ? Object.keys(destinyKeys).find(
                            (destiny) =>
                              destiny.toLowerCase() ===
                              transfer.destiny.toLowerCase()
                          )
                        : ""
                    }
                    onBlur={() => handleEmptyField("destiny")}
                    onChange={destinySelected}
                  >
                    <option value="" disabled>
                    {t("transfers:form.selectDestination")}
                    </option>
                    {Object.keys(destinyKeys).map((key) => (
                      <option key={key} value={key}>
                        {destinyKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.destiny?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                {status === "approve" && (
                  <Form.Group as={Col} md="8">
                    <FormLabel>{t("transfers:form.receipt")}</FormLabel>

                    <FileUpload
                      id="transfer-file"
                      url=""
                      title={t("transfers:form.fileUpload.title")}
                      label={t("transfers:form.fileUpload.label")}
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      buttonTitle={t("transfers:form.fileUpload.buttonTitle")}
                      description={t("transfers:form.fileUpload.description")}
                      containerClassName="mt-2"
                      fileTitle={t("transfers:form.fileUpload.fileTitle")}
                      uploadFolder="transferencias"
                      error={errors.receipt?.message}
                      onBlur={() => handleEmptyField("receipt")}
                      onChange={(url) => setValue("receipt", url)}
                      onLoading={setUploading}
                    />

                    {errors.receipt?.message && (
                      <div class="invalid-feedback d-block">
                        {errors.receipt.message}
                      </div>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {errors.refuse_reason?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {status === "refuse" && (
                  <Form.Group as={Col} md="12">
                    <FormLabel>{t("transfers:form.refuse_reason")}</FormLabel>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder={t("transfers:form.refuse_placeholder")}
                      {...register("refuse_reason")}
                      isInvalid={errors.refuse_reason?.message}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.refuse_reason?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Form.Row>

              <Row className="justify-content-center align-items-end flex-grow-1 pb-4 pt-2">
                <Col md="6" className="col-12 mt-3">
                  {status === "approve" && (
                    <ApproveButton
                      type="submit"
                      disabled={uploading || loading}
                      title={loading ? t("transfers:form.sendingTransfer") : null}
                    />
                  )}
                  {status === "refuse" && (
                    <RefuseButton
                      type="submit"
                      disabled={uploading || loading}
                      title={loading ? t("transfers:form.sendingTransfer") : null}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </ContainerFluid>
      )}
    </SideModal>
  );
}

//------ Styled Components -----//
const FormLabel = styled(Form.Label)`
  color: #8fa5b2 !important;
  font-weight: 400 !important;
  font-size: 13px !important;
`;

const CalendarIcon = styled(FaRegCalendar)`
  /* position: absolute; */
  /* right: 19px; */
  color: #98afbc;
  font-size: 1.1rem;
`;
