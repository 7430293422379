/* eslint-disable operator-linebreak */
import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import styled from "styled-components";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { COLORS, SPACINGS, breakPoints } from "services/constants.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWineBottle, faTicketAlt } from "@fortawesome/free-solid-svg-icons";

Modal.setAppElement("#root");
ModalVincular.propTypes = {
  toggleFunction: PropTypes.shape({
    modalPublicIsOpen: PropTypes.bool,
    setModalPublicIsOpen: PropTypes.func,
  }),
  togglePublic: PropTypes.func,
};

export default function ModalVincular(props) {
  const { toggleFunction } = props;
  const { modalPublicIsOpen: isOpen, setModalPublicIsOpen: setIsOpen } =
    toggleFunction;

  return (
    <ModalComponent
      className="shadow middle-modal"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>Criação de  um novo evento</HeaderText>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon size={32} />
          </CloseButton>
        </HeaderContainer>
        <InfoTextBar>
          <LargeText style={{ paddingLeft: SPACINGS.space2 }}>
            Vamos começar!
          </LargeText>
          <InfoText style={{ color: COLORS.silver }}>
            Escolha o tipo do evento: <br/>
            - A&B (Sistema de Bar, trabalhando com alimentos e bebidas em forma de fichas/tickets ou Cashless) <br/>
            - Venda de ingressos (PDV e Online).
          </InfoText>
        </InfoTextBar>
        <IconsContainer>
          <NavLink
            className="navLink"
            to={`/event/create-aeb`}
            style={{
              color: COLORS.darkGray,
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faWineBottle}
              size="lg"
              style={{
                color: COLORS.darkGray,
                transform: "rotate(-45deg)",
                margin: "0px 5px",
              }}
            />
            <span
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              A&B (Bar)
            </span>
          </NavLink>
        </IconsContainer>
        <IconsContainer>
          <NavLink
            className="navLink"
            to={`/event/create`}
            style={{
              color: COLORS.darkGray,
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              alignItems: "center",
              paddingRight: "5px",
            }}
          >
            <FontAwesomeIcon
              icon={faTicketAlt}
              size="lg"
              style={{
                color: COLORS.darkGray,
                transform: "rotate(-40deg)",
                margin: "0px 5px",
              }}
            />
            <span
              className="button-event-aeb"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "5px",
              }}
            >
              Ingressos
            </span>
          </NavLink>
        </IconsContainer>
      </ModalContainer>
    </ModalComponent>
  );
}
const ModalComponent = styled(Modal)`
  width: 90%;
  height: auto;
  left: 5%;
  position: absolute;
  top: 22%;
  right: 0;
  overflow-y: auto;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  @media ${breakPoints.mobileL} {
    width: 440px;
    height: auto;
    left: 50%;
    transform: translate(-40%, 0);
  }
  @media screen and (max-width: 768px) {
    top: 25%;
  }
`;

const IconsContainer = styled.div`
  align-items: center;
  height: 48px;
  margin-top: 15px;
  border-radius: 5px;
  width: 250px;
  background-color: ${COLORS.weakGray};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media ${breakPoints.mobileL} {
    width: 335px;
  }
`;

const InfoTextBar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${SPACINGS.space4} ${SPACINGS.space4} ${SPACINGS.space4};
  margin-top: 15px;
`;
const InfoText = styled.span`
  color: ${COLORS.silver};
  font-size: 13px;
  text-align: "center";
  @media screen and (max-width: 768px) {
    padding: 0 ${SPACINGS.space1};
  }
`;
const LargeText = styled.span`
  padding: ${SPACINGS.space2} 0 ${SPACINGS.space4} 0;
  color: ${COLORS.blue};
  font-family: rubik;
  font-size: 36px;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    text-align: center;
    padding: ${SPACINGS.space1} 0;
  }
`;

const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} ${SPACINGS.space5} ${SPACINGS.space2};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: visible;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;

const HeaderContainer = styled.div`
  padding: ${SPACINGS.space3} ${SPACINGS.space3} 0 ${SPACINGS.space3};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45px;
`;

const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 34px;
`;

const HeaderText = styled.span`
  color: ${COLORS.dark};
  text-align: left;
  font-size: 15px;
  display: flex;
  line-height: 45px;
  align-items: center;
`;
