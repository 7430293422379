import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import api from "services/api";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import PaymentButtons from "components/paymentbuttons/index.js";
import moment from "moment";
import ImageFileUpload from "components/image-file-upload/index.js";
import NumberFormat from "react-number-format";
import OrganizationsInput from "components/organizations-input";
import GooglePlacesAutocomplete from "react-google-places-autocomplete/dist/GooglePlacesAutocomplete";
import Loading from "components/loading";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";

let container;
const EventBar = () => {
	const {currencyInputFormatter, currencyFormatter} = useLanguageSettings()
  const { t } = useTranslation();
  const [nome, setName] = useState("");
  const [type, setType] = useState("");
  const [printTicketType, setPrintTicketType] = useState();
  const [enableQrCode, setEnableQrCode] = useState();
  const [interestRate, setInterestRate] = useState(2.5);
  const [maxInstallments, setMaxInstallments] = useState(1);
  const [printCashlessReceipt, setPrintCashlessReceipt] = useState();
  const [printImage, setPrintImage] = useState();
  const [posTypePayments, setPosTypePayments] = useState([]);
  const [posTypePaymentsUser, setPosTypePaymentsUser] = useState([]);
  const [posTypes, setPosTypes] = useState([]);
  const [onlineTypes, setOnlineTypes] = useState([]);
  const [insertPaymentUser, setInsertPaymentUser] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [hasTickets] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const [organizationId, setOrganizationId] = useState("");
  const [card_value, setCardvalue] = useState(0);
  const [loadingPrintImage, setLoadingPrintImage] = useState(false);
  const [click, setClick] = useState(false);
  const [infoCard, setInfoCard] = useState([]);
  const [enableTip, setEnableTip] = useState();
  const [tax, setTax] = useState(0);
  const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const [location, setLocation] = useState({
    locationName: "",
    address: "",
    number: "",
    complement: "",
  });
  const [activeCheck, SetActiveCheck] = useState({
    name: "",
    document: "",
    telephone: "",
  });

  // date Picker Config

  const errorMensage = (message) => {
    Swal.fire({
      type: "error",
      title: "Oops...",
      text: message,
    });
  };

  const registerEsta = () => {
    var form_data = new FormData();

    if (!nome) {
      document.getElementById("loading").classList.add("esconde");
      errorMensage("Nome do Bar é obrigatório.");
      return;
    }

    if (!type) {
      document.getElementById("loading").classList.add("esconde");
      errorMensage("O tipo de Bar é obrigatório.");
      return;
    }
    if (!location.address) {
      document.getElementById("loading").classList.add("esconde");
      errorMensage("O endereço é obrigatório.");
      return;
    }
    if (!location.locationName) {
      document.getElementById("loading").classList.add("esconde");
      errorMensage("O nome fantasia do endereço é obrigatório.");
      return;
    }

    if (posTypes.length === 0) {
      document.getElementById("loading").classList.add("esconde");
      errorMensage(
        "Escolha pelo menos uma forma de recebimento para o Bar, é obrigatório!"
      );
      return;
    }

    if (printImage) {
      form_data.append("ab_print_image", printImage);
    }

    form_data.append("nome", nome);
    form_data.append("posTypes", posTypes);
    form_data.append("type", type);
    form_data.append("hasTickets", hasTickets);
    form_data.append("ab_print_ticket_type", printTicketType || "");
    form_data.append("ab_print_qrcode", enableQrCode);
    form_data.append("ab_print_cashless_receipt", printCashlessReceipt);
    form_data.append("ab_max_installments", maxInstallments);
    form_data.append("ab_interest_rate", (interestRate || 0) * 100);
    form_data.append(
      "card_value",
      card_value
        ? card_value.replace(".", "").replace(",", "").replace("R$", "") / 100
        : 0
    );
    form_data.append("ab_enable_tips", enableTip);
    form_data.append("ab_tax", (tax || 0) * 100);

    form_data.append("organization_id", organizationId);
    form_data.append(
      "start_date",
      moment(startDate).format("YYYY-MM-DD HH:mm:ss")
    );
    form_data.append("inputsCashless", infoCard);
    form_data.append("formatted_address", location.address);
    form_data.append("location_name", location.locationName);
    form_data.append("number", location.number);
    form_data.append("complement", location.complement);
    document.getElementById("loading").classList.remove("esconde");
    api
      .post("/events/newEventsAeB", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        document.getElementById("loading").classList.add("esconde");
        if (res.data.message === "Slug em uso!") {
          container.error(res.data.message);
        } else {
          Swal.fire({
            title: "Sucesso",
            icon: "success",
            text: "Evento criado com sucesso!",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.href = `/bar/dashboard?event_id=${res.data.eventAeB.id}`;
          });
        }
      })
      .catch((error) => {
        document.getElementById("loading").classList.add("esconde");
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: error.response.data.message,
          confirmButtonText: "Ok",
        });
      });
  };

  const inserirFormaPagamentoUsurario = () => {
    setClick(true);

    if (insertPaymentUser.length < 2) {
      errorMensage(
        "Insira um nome com no mínimo 2 caracteres para a forma de pagamento!"
      );
      setClick(false);
      return;
    }
    let newPayUser = {
      name: insertPaymentUser,
      cost: null,
      mode: "aeb",
      raw_method: "cash",
      user_id: user.id,
      event_id: null,
    };

    api
      .post("type_payments/insertUserAndAdmin", newPayUser)
      .then((res) => {
        //   document.getElementById("picture-label").innerText = file_data.name;
        setClick(false);
        container.success(res.data.message);
        setInsertPaymentUser("");
        carregaFormasPagamento();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const deletePaymentUser = (idPayment) => {
    Swal.fire({
      title: "Você tem certeza que deseja excluir?",
      text: "Caso a forma de pagamento já esteja em uso, não será possível excluir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, Excluir!",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        api
          .post("type_payments/delete/" + idPayment)
          .then((res) => {
            container.success(res.data.message);
            carregaFormasPagamento();
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const carregaFormasPagamento = () => {
    api
      .get("type_payments")
      .then((res) => {
        document.getElementById("loading").classList.add("esconde");
        setPosTypePayments(
          res.data.filter(
            (item) =>
              item.mode === "common" ||
              item.mode === "aeb" ||
              item.mode === "pos"
          )
        );
      })
      .catch(() => {
        document.getElementById("loading").classList.add("esconde");
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });

    const urlParams = new URLSearchParams(window.location.search);
    let event_id = urlParams.get("event_id");

    api
      .get("type_payments/showUserAdmin/" + event_id)
      .then((res) => {
        setPosTypePaymentsUser(res.data.filter((item) => item.user_id));
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const handlePaymentType = (typeId, mode) => {
    let items = mode === "online" ? onlineTypes : posTypes;
    const index = items.findIndex((id) => typeId === id);

    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.push(typeId);
    }

    if (mode === "online") {
      setOnlineTypes([...items]);
    } else {
      setPosTypes([...items]);
    }
  };

  const updateCurrency = (e) => {
    setCardvalue(currencyInputFormatter(e.target.value));
  };

  const handleUploadFile = (fileData) => {
    setLoadingPrintImage(true);

    let formData = new FormData();
    formData.append("image", fileData);

    api
      .post("uploads?local=estabelecimentos", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => setPrintImage(res.data.url))
      .catch(
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente!",
          confirmButtonText: "Ok",
        })
      )
      .finally(() => setLoadingPrintImage(false));
  };

  const selectedInfosCard = (type) => {
    const arrayTypes = infoCard;
    const index = arrayTypes.find((item) => type === item);
    if (index) {
      arrayTypes.splice(arrayTypes.indexOf(type), 1);
      setInfoCard(arrayTypes);
    } else {
      arrayTypes.push(type);
      setInfoCard(arrayTypes);
    }
  };

  const initMap = () => {
    setGmapsLoaded({
      gmapsLoaded: true,
    });
  };

  useEffect(() => {
    window.initMap = initMap();
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGpw6QCOspt7qdLnpwWV9Aw3lypy246uU&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
    carregaFormasPagamento();

    const urlParams = new URLSearchParams(window.location.search);
    let event_id = urlParams.get("event_id");

    if (event_id) {
      api
        .get(`dash/getInfoPainelAB/${event_id}`)
        .then((res) => {
          let bar = res.data.barEstabelecimento;
          let paymentsTypes = res.data.paymentsTypes;
          setName(bar.name);
          // setSlug(bar.slug)
          setType(bar.type);
          setPrintImage(bar.ab_print_image);
          setPrintTicketType(bar.ab_print_ticket_type);
          setEnableQrCode(bar.ab_print_qrcode);
          setPrintCashlessReceipt(bar.ab_print_cashless_receipt);
          setMaxInstallments(bar.ab_max_installments);
          setInterestRate((bar.ab_interest_rate || 0) / 100);
          setCardvalue(
            bar.card_value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          );
          setEnableTip(bar.ab_enable_tips);
          setTax((bar.ab_tax || 0) / 100);

          setStartDate(new Date(bar.start_date));

          setPosTypes(paymentsTypes.map((item) => item.type_payment_id));
          if (user.is_admin) {
            setOrganizationId(bar.organization_id);
          } else {
            setOrganizationId(bar.organization_id);
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    } else {
      setEnableTip(0);
      setEnableQrCode(0);
      setPrintCashlessReceipt(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "ticket") {
      setCardvalue(0);
    }
  }, [type]);

  const address = (data) => {
    setLocation({
      ...location,
      [data.target.name]: data.target.value,
    });
  };

  const organizationSelected = (organization_id) => {
    setOrganizationId(organization_id);
  };

  return (
    <>
      <PageHeader
        title="Criação de Evento A&B"
        text="Cadastrar novo Painel A&B"
      />
      <Loading title="aguarde..." />
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <div
        id="register-establishment"
        className="container-fluid mt-4"
        style={{ paddingBottom: "60px" }}
      >
        <div className="col-12">
          <div className="card">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">1. Dados básicos</h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <label>Nome</label>
                  <input
                    type="text"
                    placeholder="Nome do estabelecimento"
                    className="form-control"
                    name="name"
                    value={nome || ""}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>

                <div className="col-md-3">
                  <label className="form-label">
                    {t("components:datepicker.startDate")}
                  </label>
                  <div className="input-group createevent-datepicker">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                    <div
                      className="mt-sm-1 mt-md-0"
                      style={{ width: "calc(100% - 60px)" }}
                    >
                      <DatePicker
                        value={startDate}
                        showTimeSelect
                        minDate={new Date()}
                        onChange={(value) => setStartDate(value)}
                        placeholderText={t("components:datepicker.startDate")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-3">
                  <label>Nome do endereço</label>
                  <input
                    type="text"
                    placeholder="Nome do endereço"
                    className="form-control"
                    name="locationName"
                    value={location.locationName || ""}
                    onChange={address}
                  />
                </div>
                <div className="col-md-5">
                  <label>Endereço</label>
                  {gmapsLoaded && (
                    <GooglePlacesAutocomplete
                      initialValue={location.address}
                      required={true}
                      inputClassName="form-control"
                      placeholder="Buscar endereço"
                      onSelect={({ description }) => {
                        setLocation({
                          ...location,
                          address: description,
                        });
                      }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["br"],
                        },
                      }}
                    />
                  )}
                </div>
                <div className="col-md-2">
                  <label>Número</label>
                  <input
                    type="text"
                    placeholder="Número"
                    className="form-control"
                    name="number"
                    value={location.number || ""}
                    onChange={address}
                  />
                </div>
                <div className="col-md-2">
                  <label>Complemento</label>
                  <input
                    type="text"
                    placeholder="Complemento"
                    className="form-control"
                    name="complement"
                    value={location.complement || ""}
                    onChange={address}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              <h4 className="card-header-title">
                2. Formas de pagamento
                <span className="tips">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <div className="tips-content">
                    Formas de pagamento padrão.
                  </div>
                </span>
              </h4>
            </div>

            <div className="card-body">
              <div className="custom-input custom-checkbox row mb-2">
                {posTypePayments.map((item, i) => (
                  <div
                    key={item.id}
                    className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2"
                  >
                    <div
                      className={
                        posTypes.includes(item.id)
                          ? "btnClass active"
                          : "btnClass"
                      }
                      onClick={() => handlePaymentType(item.id, "pos")}
                    >
                      <PaymentButtons
                        method={item.raw_method}
                        name={item.name}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <hr></hr>
              <h4 className="card-header-title">
                Formas de pagamento personalizadas
              </h4>

              <div className="custom-input custom-checkbox row mt-4 mb-2">
                {posTypePaymentsUser.map((item) => (
                  <div
                    key={item.id}
                    className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2 btnGroup"
                  >
                    <div
                      className={
                        posTypes.includes(item.id)
                          ? "btnClass active"
                          : "btnClass"
                      }
                      onClick={() => handlePaymentType(item.id, "pos")}
                    >
                      <PaymentButtons
                        method={item.raw_method}
                        name={item.name + " (dinheiro) "}
                      />
                    </div>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="payment-delete"
                      onClick={() => deletePaymentUser(item.id)}
                    />
                  </div>
                ))}
              </div>

              <hr></hr>
              <h4 className="card-header-title">
                Cadastrar forma de pagamento personalizada
              </h4>

              <div className="row mt-4">
                <div className="col-md-8 mb-2">
                  <input
                    type="text"
                    placeholder="Insira o nome da sua forma de pagamento"
                    className="form-control"
                    name="UserPay"
                    value={insertPaymentUser || ""}
                    onChange={(event) =>
                      setInsertPaymentUser(event.target.value)
                    }
                    disabled={click}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inserirFormaPagamentoUsurario();
                      }
                    }}
                  />
                </div>

                <div className="col-md-4 mb-2">
                  <button
                    disabled={click}
                    className="btn btn-secondary"
                    onClick={inserirFormaPagamentoUsurario}
                  >
                    cadastrar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">3. Configurações A/B</h4>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Tipo</label>
                  <select
                    required
                    className="form-control"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">Escolha tipo</option>
                    <option value="ticket">Ticket</option>
                    <option value="cashless-pre">Cashless Pré-Pago</option>
                    <option value="cashless-pos">Cashless Pós-Pago</option>
                  </select>
                </div>

                <div className="col-md-6 mb-2">
                  <ImageFileUpload
                    id="printImage"
                    image={printImage}
                    label="Logo de impressão (preto e branco)"
                    loading={loadingPrintImage}
                    onChange={(image, base64, file) => {
                      handleUploadFile(file);
                    }}
                    placeholder="Selecione uma imagem"
                  />
                  <span className="upload-desc text-small">
                    A dimensão recomendada é de 240 x 240
                  </span>
                </div>
              </div>
              {type === "" ? (
                ""
              ) : type === "ticket" ? (
                <div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>Imprimir QRCode no ticket?</label>
                      {enableQrCode !== undefined && (
                        <div className="custom-control custom-switch col-md-6">
                          <input
                            id="enableQrCode"
                            type="checkbox"
                            className="custom-control-input"
                            name="enableQrCode"
                            defaultChecked={enableQrCode}
                            onChange={() =>
                              setEnableQrCode(enableQrCode ? 0 : 1)
                            }
                          ></input>
                          <label
                            className="custom-control-label"
                            htmlFor="enableQrCode"
                          ></label>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Tipo de impressão do ticket</label>
                      <select
                        required={type === "ticket"}
                        className="form-control"
                        value={printTicketType || ""}
                        onChange={(e) => setPrintTicketType(e.target.value)}
                      >
                        <option value="">Escolha tipo de impressão</option>
                        <option value="simple">Simples</option>
                        <option value="grouped">Agrupada</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>Habilitar gorjeta?</label>
                      {enableTip !== undefined && (
                        <div className="custom-control custom-switch col-md-6">
                          <input
                            id="enableTip"
                            type="checkbox"
                            className="custom-control-input"
                            name="enableTip"
                            defaultChecked={enableTip}
                            onChange={() => setEnableTip(enableTip ? 0 : 1)}
                          ></input>
                          <label
                            className="custom-control-label"
                            htmlFor="enableTip"
                          ></label>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>Imposto sobre o pedido</label>

                      <NumberFormat
                        suffix="%"
                        decimalScale={1}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        value={tax}
                        className="form-control"
                        onValueChange={({ floatValue }) => setTax(floatValue)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Imprimir recibo cashless?</label>
                        {printCashlessReceipt !== undefined && (
                          <div className="custom-control custom-switch">
                            <input
                              id="printCashlessReceipt"
                              type="checkbox"
                              className="custom-control-input"
                              name="printCashlessReceipt"
                              defaultChecked={printCashlessReceipt}
                              onChange={() =>
                                setPrintCashlessReceipt(
                                  printCashlessReceipt ? 0 : 1
                                )
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="printCashlessReceipt"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <span>Informações solicitadas no cartão</span>
                        <div className="row mb-2">
                          <div
                            className={`col mt-1 btnClass ${activeCheck.name}`}
                            onClick={() => {
                              selectedInfosCard("Nome");
                              if (activeCheck.name === "active") {
                                SetActiveCheck({
                                  ...activeCheck,
                                  name: "",
                                });
                              } else {
                                SetActiveCheck({
                                  ...activeCheck,
                                  name: "active",
                                });
                              }
                            }}
                          >
                            <PaymentButtons method="Nome" name="Nome" />
                          </div>
                          <div
                            className={`col mt-1 btnClass ${activeCheck.document}`}
                            onClick={() => {
                              selectedInfosCard("CPF");
                              if (activeCheck.document === "active") {
                                SetActiveCheck({
                                  ...activeCheck,
                                  document: "",
                                });
                              } else {
                                SetActiveCheck({
                                  ...activeCheck,
                                  document: "active",
                                });
                              }
                            }}
                          >
                            <PaymentButtons method="CPF" name="CPF" />
                          </div>
                          <div
                            className={`col mt-1 btnClass ${activeCheck.telephone}`}
                            onClick={() => {
                              selectedInfosCard("Telefone");
                              if (activeCheck.telephone === "active") {
                                SetActiveCheck({
                                  ...activeCheck,
                                  telephone: "",
                                });
                              } else {
                                SetActiveCheck({
                                  ...activeCheck,
                                  telephone: "active",
                                });
                              }
                            }}
                          >
                            <PaymentButtons method="Telefone" name="Telefone" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Custo Cartão Cashless</label>
                    <input
                      value={card_value || ""}
                      name="cost"
                      placeholder={currencyFormatter(0)}
                      className="form-control"
                      onChange={(e) => updateCurrency(e)}
                    />
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="form-group col-md-4">
                  <label>Organização</label>
                  <OrganizationsInput
                    callBack={organizationSelected}
                    menuPlacement="top"
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label>Parcelamento máximo (cartão de crédito)</label>
                  <NumberFormat
                    allowNegative={false}
                    value={maxInstallments}
                    className="form-control"
                    onValueChange={({ floatValue }) =>
                      setMaxInstallments(floatValue)
                    }
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <label>Taxa de juros do parcelamento (a.m.)</label>
                  <NumberFormat
                    suffix="%"
                    decimalScale={1}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    value={interestRate}
                    className="form-control"
                    onValueChange={({ floatValue }) =>
                      setInterestRate(floatValue)
                    }
                    disabled={user.is_admin ? false : true}
                  />
                </div>
              </div>
              <div className="row mt-3"></div>
            </div>
          </div>
        </div>
        <div className="box-button">
          <button
            className="btn btn-primary col-md-3 c3"
            onClick={registerEsta}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </>
  );
};

export default EventBar;
