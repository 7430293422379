import React, { useEffect, useRef, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

import { useOnClickOutside } from "App";
import { DatePicker } from "components/datepicker";
import { addMinutes, endOfDay, isSameDay, startOfDay } from "date-fns";
import SuperCheckbox from "components/super-checkbox";
import SuperRadioButton from "components/super-radiobutton";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { numberFloatMask } from "mask";
import { useTranslation } from "react-i18next";
import api from "services/api";


let container;

const ModalCode = (props) => {
  const { currencySymbol } = useCurrencyEventContext();
  const { t } = useTranslation();
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      closeModal();
    }
  });

  const [types, setTypes] = useState({
    values: [],
    options: [],
  });

  const [clicked, setClicked] = useState(false);

  const [code, setCode] = useState({
    id: null,
    event_id: props.event_id,
    code: "",
    value: 0,
    type: 1,
    start_date: new Date(),
    end_date: addMinutes(new Date(), 10),
    amount: null,
    unlimited: 0,
    all: false,
    types: [],
    discount_form: "unitario",
  });

  const updateCode = (e) => {
    setCode({
      ...code,
      [e.target.name]:
        typeof e.target.value === "string"
          ? e.target.value.trim().replace(/\s+/g, "") //prevent from typing blanks
          : e.target.value,
    });
  };

  function closeModal() {
    props.callback("modal-hidden");

    setCode({
      id: null,
      event_id: props.event_id,
      code: "",
      value: 0,
      type: 1,
      start_date: new Date(),
      end_date: addMinutes(new Date(), 10),
      amount: null,
      unlimited: 0,
      all: false,
      types: [],
      discount_form: "unitario",
    });
  }
  const savediscount = () => {
    const data = {
      discount: { ...code, code: code.code.trim() },
      types: code.types,
    };

    data.types = data.types.map((type) => type.value);

    if (!data.discount.id) {
      delete data.discount["id"];
    }

    delete data.discount["types"];

    if (data.discount.unlimited === 1) {
      data.discount.amount = null;
    }

    delete data.discount["unlimited"];
    delete data.discount["all"];

    if (code.code.length === 0) {
      container.error(t("tickets:promotionalCode.modalCode.insertCode"));
    } else if (!/(.*[a-zA-Z0-9]){2}/.test(code.code)) {
      container.error(
        t("tickets:promotionalCode.modalCode.insertCodeWithChars")
      );
    } else if (
      (code.amount === "0" || code.amount === null || code.amount === "") &&
      code.unlimited === 0
    ) {
      container.error(
        t("tickets:promotionalCode.modalCode.insertCouponsQuantity")
      );
    } else if (code.value === 0 || code.value === "") {
      container.error(
        t("tickets:promotionalCode.modalCode.insertDiscountValue")
      );
    } else if (code.types.length === 0) {
      container.error(
        t(
          "tickets:promotionalCode.modalCode.selectAtLeastOneTicketToApplyDiscount"
        )
      );
    } else if (
      code.start_date === null || code.start_date === undefined ||
      code.start_date === "0000-00-00 00:00:00"
    ) {
      container.error(
        t(
          "tickets:promotionalCode.modalCode.startDateError"
        )
      );
    } else if (
      code.end_date === null || code.end_date === undefined ||
      code.end_date === "0000-00-00 00:00:00" 
    ) {
      container.error(
        t(
          "tickets:promotionalCode.modalCode.endDateError"
        )
      );
    } else if (
      code.end_date < code.start_date
    ) {
      container.error(
        t(
          "tickets:promotionalCode.modalCode.errorLowerEndDate"
        )
      )
    } else {
      api
        .post("discount/create-or-update", data)
        .then(() => {
          container.success(t("tickets:promotionalCode.modalCode.saveSuccess"));
          closeModal();
        })
        .catch((error) => {
          const { code, message } = error.response.data;
          let errorMessage = "";

          if (code) {
            switch (code) {
              case "ERR_SAME_CODE":
                errorMessage = message;
                break;
              default:
                errorMessage = t("tickets:promotionalCode.modalCode.saveError");
            }
          }
          container.error(errorMessage);
        });
    }
    setClicked(false);
  };

  useEffect(() => {
    api
      .get(`event/ticket-types/${props.event_id}`)
      .then((res) => {
        let tipo = res.data.tickets.map((x) => {
          x.active = x.active ? true : false;
          return x;
        });

        let op = tipo.map((ticket) => {
          return {
            label: `[${ticket.lot}] - ${ticket.sector} ${ticket.type}`,
            value: ticket.id,
          };
        });

        tipo = tipo.map((ticket) => {
          return {
            value: ticket.id,
            text: `[${ticket.lot}] - ${ticket.sector} ${ticket.type}`,
          };
        });
        setTypes({
          options: op,
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("tickets:promotionalCode.modalCode.internalError"),
          confirmButtonText: t(
            "tickets:promotionalCode.modalCode.confirmButtonText"
          ),
        });
      });

    if (props.event_id && props.discount_id) {
      api
        .get(`discount/event/${props.event_id}/${props.discount_id}`)
        .then((res) => {
          let aux = [];
          if (res.data.types?.length > 0) {
            types.options.forEach((item) => {
              res.data.types.forEach((values) => {
                if (item.value === values) {
                  aux.push(item);
                }
              });
            });
          }
          let start = new Date(res.data.start_date);
          let end = new Date(res.data.end_date);
          setCode({
            id: res.data.id,
            code: res.data.code,
            event_id: res.data.event_id,
            amount: res.data.amount,
            value: res.data.value,
            discount_form: res.data.discount_form,
            unlimited:
              res.data.amount ===
                t("tickets:promotionalCode.modalCode.unlimited")
                ? 1
                : 0,
            start_date: new Date(start),
            end_date: new Date(end),
            type: res.data.type,
            types: aux,
            all: res.data.types?.length === types.options.length ? true : false,
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: t("tickets:promotionalCode.modalCode.internalError"),
            confirmButtonText: t(
              "tickets:promotionalCode.modalCode.confirmButtonText"
            ),
          });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.event_id, props.discount_id]);

  return (
    <>
      <div
        className={"shadow side-modal " + props.status}
        ref={ref}
        role="dialog"
      >
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="card-header">
          <h4 className="card-header-title">
            {props.discount_id && props.event_id
              ? t("tickets:promotionalCode.modalCode.editPromoCode")
              : t("tickets:promotionalCode.modalCode.registerPromoCode")}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body ">
          <div className="row mt-2">
            <div className="col-md-4">
              <label htmlFor="code" className="form-label">
                {t("tickets:promotionalCode.modalCode.couponCode")}
              </label>
              <input
                className="dados form-control"
                id="code"
                name="code"
                type="text"
                value={code.code}
                onChange={updateCode}
                placeholder={t(
                  "tickets:promotionalCode.modalCode.insertCouponCode"
                )}
              />
            </div>
            <div className="col-md-4">
              <DatePicker
                label={t("tickets:promotionalCode.modalCode.startDate")}
                name="start_date"
                value={code.start_date}
                minDate={new Date()}
                minTime={
                  isSameDay(code.start_date, new Date())
                    ? new Date()
                    : startOfDay(new Date())
                }
                maxTime={endOfDay(new Date())}
                showTimeSelect
                onChange={(date) =>
                  setCode({
                    ...code,
                    start_date: date,
                    end_date: date > code.end_date ? addMinutes(date, 10) : code.end_date
                  })



                }
                placeholderText={t(
                  "tickets:promotionalCode.modalCode.searchByDate"
                )}
                required
                marginLeft={[0, "-5rem", 0]}
              />
            </div>
            <div className="col-md-4">
              <DatePicker
                label={t("tickets:promotionalCode.modalCode.endDate")}
                name="end_date"
                value={code.end_date}
                showTimeSelect
                minDate={code.start_date ? new Date(code.start_date) : new Date()}
                minTime={
                  isSameDay(code.start_date, code.end_date) || !code.end_date
                    ? code.start_date
                    : startOfDay(new Date())
                }
                maxTime={endOfDay(new Date())}
                onChange={(date) =>
                  setCode({
                    ...code,
                    end_date: date,
                  })
                }
                placeholderText={t(
                  "tickets:promotionalCode.modalCode.searchByDate"
                )}
                required
                marginLeft={["-6rem", "-11.5rem", "-5rem"]}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <label htmlFor="quantity" className="form-label">
                {t("tickets:promotionalCode.modalCode.quantity")}
              </label>
              <input
                id="quantity"
                name="amount"
                className="dados2 form-control"
                type="number"
                disabled={code.unlimited}
                value={code.amount || ""}
                onChange={updateCode}
                placeholder={t(
                  "tickets:promotionalCode.modalCode.insertQuantity"
                )}
              />
            </div>
            <div className="col-md-4">
              <SuperCheckbox
                value={code.unlimited}
                label={t("tickets:promotionalCode.modalCode.unlimited")}
                callback={(value) =>
                  setCode({
                    ...code,
                    amount: null,
                    unlimited: value,
                  })
                }
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <label htmlFor="discount" className="form-label">
                {t("tickets:promotionalCode.modalCode.discount")}
              </label>
              <div className="input-group mb-2">
                {code.type === 1 ? (
                  <>
                    <div className="input-group-prepend">
                      <div className="input-group-text">%</div>
                    </div>
                    <input
                      id="discount"
                      name="value"
                      className="dados form-control"
                      type="text"
                      maxLength={5}
                      value={code.value}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val < 0) {
                          e.target.value = 0;
                        }
                        if (val > 100) {
                          e.target.value = 100;
                        }
                        setCode({
                          ...code,
                          value: numberFloatMask(e.target.value),
                        });
                      }}
                      placeholder={t(
                        "tickets:promotionalCode.modalCode.insertValue"
                      )}
                    />
                  </>
                ) : (
                  <>
                    <div className="input-group-prepend">
                      <div className="input-group-text">{currencySymbol}</div>
                    </div>
                    <input
                      id="discount"
                      name="value"
                      className="dados form-control"
                      type="text"
                      value={code.value}
                      onChange={updateCode}
                      placeholder={t(
                        "tickets:promotionalCode.modalCode.insertValue"
                      )}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <SuperRadioButton
                value={code.type}
                requiredValue={1}
                label={t("tickets:promotionalCode.modalCode.percentage")}
                callback={(value) => {
                  setCode({
                    ...code,
                    type: value,
                  });
                }}
              />
            </div>
            <div className="col-md-4">
              <SuperRadioButton
                value={code.type}
                requiredValue={0}
                label={t("tickets:promotionalCode.modalCode.fixed")}
                callback={(value) => {
                  setCode({
                    ...code,
                    type: value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-4 mt-2">
              <label htmlFor="types" className="form-label">
                {t(
                  "tickets:promotionalCode.modalCode.wichTicketsApplyDiscount"
                )}
              </label>

              <ReactMultiSelectCheckboxes
                width="100%"
                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => {
                  if (!value?.length) {
                    return t("tickets:promotionalCode.modalCode.chooseTickets");
                  } else if (value?.length === 1) {
                    return value[0].label;
                  } else {
                    return `${value?.length} ${t(
                      "tickets:promotionalCode.modalCode.selected"
                    )}`;
                  }
                }}
                placeholder={`${t(
                  "tickets:promotionalCode.modalCode.search"
                )}..`}
                value={code.types}
                onChange={(values) => {
                  code.types = values;
                  setCode({ ...code });
                }}
                options={types.options}
                name="types"
              />
            </div>
            <div className="col-md-4">
              <SuperCheckbox
                value={code.all}
                label={t("tickets:promotionalCode.modalCode.all")}
                callback={(value) => {
                  if (!code.all) {
                    setCode({
                      ...code,
                      all: true,
                      types: types.options,
                    });
                  } else {
                    setCode({
                      ...code,
                      all: false,
                      types: [],
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="box-button">
            <button
              disabled={clicked}
              className="btn btn-primary col-md-4 c3 mr-3 "
              onClick={() => {
                setClicked(true);
                savediscount();
              }}
            >
              {props.discount_id && props.event_id
                ? t("tickets:promotionalCode.modalCode.save")
                : t("tickets:promotionalCode.modalCode.register")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCode;
