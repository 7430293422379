import React from "react";
import "./styles.css";

const NavItem = ({ isActive, label, onSetActive, id }) => (
  <li className="nav-item">
    <div
      className={`nav-link text-nowrap subheader-item ${isActive && "active"}`}
      onClick={onSetActive}
      id={id}
    >
      {label}
    </div>
  </li>
);

const Subheader = ({ position, changePosition, t }) => (
  <div className="col" id="subheader">
    <ul className="nav nav-tabs nav-overflow header-tabs">
      <NavItem
        isActive={position === 0}
        label={t("nominalTransfers.subheader.transfers")}
        onSetActive={() => changePosition(0)}
        id="transfersTab"
      />
      <NavItem
        isActive={position === 1}
        label={t("nominalTransfers.subheader.configuration")}
        onSetActive={() => changePosition(1)}
        id="configurationTab"
      />
    </ul>
  </div>
);

export default Subheader;
