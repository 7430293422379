import React from "react";
import { DatePicker } from "components/datepicker";
import HeaderCadastro from "../headercadastro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
export default class Cadastroevento2 extends React.Component {
  state = {
    dateLocations: this.props.dateLocations,
    isVisivel: true,
    isVisivelTipo: false,
  };

  render() {
    return (
      <>
        <HeaderCadastro
          title="4. Preços e quantidades"
          text="configure os valores e quantidades dos seus ingressos. <a href='/evento-help' target='_blank'>Saiba mais como configurar.</a>"
        ></HeaderCadastro>

        <div className="mt-4 event-step">
          <div className="col-12 pl-0">
            <div className="card mt-0">
              <div className="card-body">
                <b>Informações detalhadas</b>
                {this.state.dateLocations.map((date, i) => (
                  <form key={i}>
                    <div className="form-row">
                      <div className="col-md-12 mb-3 evento-name">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        {date.title}{" "}
                        {date.location
                          ? `- ${
                              date.location.name
                                ? date.location.name
                                : date.location
                            }`
                          : ""}
                      </div>
                    </div>

                    <div className="card card-datatable">
                      {date.sectors.map((sector, sector_i) => (
                        <div key={`sector-${sector_i}`}>
                          <div className="card-header">
                            <div className="row">
                              <div className="col-md-2 text-center">Lote</div>
                              <div className="col-md-2 text-center">Tipo</div>
                              <div className="col-md-2 text-center">Valor</div>
                              <div className="col-md-2 text-center">Qtd</div>
                              <div className="col-md-2 text-center">
                                Data Expiração
                              </div>
                              <div className="col-md-2 text-center">
                                Canais de vendas
                              </div>
                            </div>
                          </div>
                          <div className="event-sector">
                            <div className="col-md-12">
                              <span>Setor: {sector.name} </span>
                            </div>
                          </div>

                          {sector.lots.map((lot, lot_i) => (
                            <div className="event-lot-group">
                              <div className="event-lot form-row">
                                <div className="col-md-2">
                                  <div className="text-right">{lot.name}</div>
                                </div>
                                <div className="col-md-2">
                                  <div className="evento-traco-horizontal-total"></div>
                                </div>
                                <div className="col-md-2">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">R$</div>
                                    </div>
                                    <input
                                      className="form-control text-center"
                                      type="number"
                                      value={lot.price}
                                      onChange={(e) => {
                                        let price = Number(e.target.value);
                                        price = price < 0 ? 0 : price;
                                        lot.price = price;
                                        this.setState({});
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <input
                                    className="form-control text-center"
                                    type="number"
                                    value={lot.qty}
                                    onChange={(e) => {
                                      let qty = Number(e.target.value);
                                      qty = qty < 0 ? 0 : qty;
                                      lot.qty = qty;
                                      this.setState({});
                                    }}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <DatePicker
                                    onChange={(value) => {
                                      lot.expiration = value;
                                      this.setState({});
                                    }}
                                    placeholderText="Data de Expiração"
                                  />
                                </div>
                                <div className="col-md-2 text-center">
                                  <div className="item-score badge bg-soft-success">
                                    todos
                                  </div>
                                </div>
                              </div>

                              {/* {lot.types.map((type, i) => (
                                  <>
                                    <div
                                      className="form-row event-ticket-type"
                                      key={`lot-${lot_i}-type-${i}`}
                                    >
                                      <div className="col-md-2">
                                      </div>
                                      <div className="col-md-2 text-center">
                                        <div className="text-center">
                                          {type.name}
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="borda-arredondada-sub">
                                          {(
                                            lot.price -
                                            (lot.price / 100) * type.discount
                                          ).toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          })}
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <input
                                          className="form-control"
                                          type="number"
                                          value={type.qty}
                                          onChange={(e) => {
                                            let qty = Number(e.target.value);
                                            qty = qty < 0 ? 0 : qty;
                                            type.qty = qty > lot.qty ? lot.qty : qty;
                                            this.setState({});
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-4"></div>
                                    </div>
                                  </>
                                ))} */}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </form>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
