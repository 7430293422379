import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faFileAlt,
  faChartPie,
  faListOl,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function BottomEstablishment() {
  const [event, setEvent] = useState({
    event_id: null,
  });

  const LinkWhatsapp = process.env.REACT_APP_WHATSAPP;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const event_id = urlParams.get("event_id");
    setEvent({ event_id: event_id });
  }, []);

  return (
    <>
      <div id="bottom-bar">
        <div className="container">
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/bar/establishments-sold?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-icon">
              <FontAwesomeIcon icon={faCreditCard} />
              <span>Transações</span>
            </div>
          </NavLink>
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/bar/event-report?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-icon">
              <FontAwesomeIcon icon={faFileAlt} />
              <span>Relatórios</span>
            </div>
          </NavLink>
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/bar/dashboard?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-mainicon shadow">
              <FontAwesomeIcon icon={faChartPie} />
            </div>
          </NavLink>
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/bar/menu-event-new?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-icon">
              <FontAwesomeIcon
                icon={faListOl}
              />
              <span>Cardápio</span>
            </div>
          </NavLink>
          {LinkWhatsapp && (
            <button
              className="buttonWhatsapp"
              onClick={() => window.open(LinkWhatsapp)}
            >
              <div className="bottom-bar-icon">
                <FontAwesomeIcon icon={faWhatsapp} />
                <span>Whatsapp</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
