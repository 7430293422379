import { GroupHeader } from "pages/common-area/events/event-create/event-create-steps/event-create-step-1/groupHeader";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Landscape } from "../../assets/icons/landscapeIcon.svg";
import { ReactComponent as TrashBin } from "../../assets/icons/trashBin.svg";
import "./styles.css";

export function MyDropzone({
  title,
  description,
  callback,
  preview,
  deleteCallback,
}) {
  // eslint-disable-next-line
  const [opacity, setOpacity] = useState(1);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => {};
      reader.onerror = () => {};
      reader.onload = () => {
        const binaryStr = reader.result;
        callback(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    maxFiles: 1,
    onDrop,
  });

  return (
    <div
      onMouseLeave={() => setOpacity(1)}
      onMouseEnter={() => setOpacity(0.1)}
      className={`dropzone ${preview && "w-preview-group"}`}
    >
      <div
        onMouseEnter={() => setOpacity(0.1)}
        className={`${
          title.text === "Banner" ? "trash-banner" : "trash-thumb"
        } w-preview trash`}
      >
        <TrashBin
          onClick={() => {
            const deleteOrigin = title.text === "Banner" ? "banner" : "thumb";
            if (!preview) {
              return;
            } else {
              deleteCallback(deleteOrigin);
            }
          }}
          fill="red"
        />
      </div>
      <div
        className={`${
          title.text === "Banner" ? "dropzone-banner" : "dropzone-thumb"
        } img-group position-relative`}
        {...getRootProps()}
      >
        {preview && (
          <img
            style={{ opacity }}
            className="img-preview"
            src={preview}
            alt="Component preview"
          />
        )}
        <input {...getInputProps()} />
        <div
          onMouseEnter={() => setOpacity(0.1)}
          className={`${title.text === "Banner" ? "banner" : "thumb"} ${
            preview && "w-preview"
          }`}
        >
          <div className="col-auto pr-0 justify-content-center d-flex">
            <Landscape />
          </div>
          <div className="col-10 pr-0">
            <GroupHeader
              title={title.text}
              tclass={title.class}
              description={description.text}
              dclass={description.class}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
