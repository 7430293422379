import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";

Pagination.propTypes = {
  pageConfig: PropTypes.shape({
    page: PropTypes.number,
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
    amount: PropTypes.number,
    perPage: PropTypes.number,
    buttonType: PropTypes.bool,
  }),
  parentFunction: PropTypes.func,
};

const INITIAL_PAGE = 1;

export default function Pagination(props) {
  const { t } = useTranslation();
  const { pageConfig, parentFunction, filters } = props;
  const [refresh, setRefresh] = useState(false);
  const [paginationpage, setPagination] = useState({
    page: pageConfig.page,
    perPage: pageConfig.perPage,
    currentPage: pageConfig.currentPage,
    lastPage: pageConfig.lastPage,
    amount: pageConfig.amount,
    buttonType: pageConfig.buttonType,
  });
  const verifyAmount =
    pageConfig.perPage * pageConfig.currentPage >= pageConfig.amount;

  const pagination = (typePage, numberPage, perPage) => {
    let number = numberPage;
    switch (typePage) {
      case "Initial":
        setPagination({
          page: numberPage,
          perPage,
        });
        break;
      case "left":
        number--;
        if (numberPage <= 0) {
          setPagination({
            page: 1,
            perPage,
          });
        } else {
          setPagination({
            page: number,
            perPage,
          });
        }
        break;
      case "right":
        if (numberPage >= paginationpage.lastPage) {
          setPagination({
            page: numberPage,
            perPage,
          });
        } else {
          number++;
          setPagination({
            page: number,
            perPage,
          });
        }
        break;

      case "lastPage":
        setPagination({
          page: numberPage,
          perPage,
        });
        break;
      default:
        setPagination({
          page: 1,
          perPage: 25,
        });
        break;
    }
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      parentFunction(
        paginationpage.page > 0 ? paginationpage.page : INITIAL_PAGE,
        paginationpage.perPage,
        filters
      );
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    setPagination({
      page: pageConfig.page,
      perPage: pageConfig.perPage,
      currentPage: pageConfig.currentPage,
      lastPage: pageConfig.lastPage,
      amount: pageConfig.amount,
    });
  }, [pageConfig]);

  return (
    <div className="row d-flex justify-content-between mb-3 datatable-footer">
      <div className="col-md-6 d-flex align-items-center">
        <span className="mr-2">{t("components:pagination.results")}</span>
        <div>
          <select
            name="SelecaoPorPagina"
            onChange={(e) => {
              pagination(
                "Initial",
                paginationpage.page,
                (paginationpage.perPage = e.target.value)
              );
            }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
        <span>
          {t("components:pagination.displaying", {
            currentPageTotal: verifyAmount
              ? pageConfig.amount
              : pageConfig.perPage * pageConfig.currentPage,
            total: pageConfig.amount,
          })}
        </span>
        <button
          type={paginationpage.buttonType ? "submit" : "button"}
          onClick={() => {
            pagination("Initial", 1, paginationpage.perPage);
          }}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </button>
        <button
          type={paginationpage.buttonType ? "submit" : "button"}
          onClick={() => {
            pagination(
              "left",
              paginationpage.currentPage,
              paginationpage.perPage
            );
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <span>
          {t("components:pagination.page", {
            currentPage: paginationpage.currentPage || pageConfig.currentPage,
            totalPages: paginationpage.lastPage || pageConfig.lastPage,
          })}
        </span>
        <button
          type={paginationpage.buttonType ? "submit" : "button"}
          onClick={() => {
            pagination(
              "right",
              paginationpage.currentPage,
              paginationpage.perPage
            );
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

        <button
          type={paginationpage.buttonType ? "submit" : "button"}
          onClick={() => {
            pagination(
              "lastPage",
              paginationpage.lastPage,
              paginationpage.perPage
            );
          }}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>
      </div>
    </div>
  );
}
