import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import api from "services/api";
import styled from "styled-components";
import { COLORS } from "services/constants";
import { BiLoaderAlt } from "react-icons/bi";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

UploadImage.propTypes = {
  disabled: PropTypes.bool,
  uploadType: PropTypes.string,
  size: PropTypes.string,
  generatedUrl: PropTypes.func,
  defaultValue: PropTypes.string,
  uploadError: PropTypes.func,
};

export default function UploadImage(props) {
  const {
    uploadType,
    size,
    generatedUrl,
    defaultValue,
    disabled,
    uploadError,
  } = props;
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "upload-image",
  });

  const upload = (event) => {
    setIsLoading(true);
    const form_data = new FormData();
    const file_data = event.target.files[0];
    form_data.append("image", file_data);

    api
      .post("uploads?local=organizacoes", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUrl(res.data.url);
        generatedUrl(res.data.url, uploadType);
      })
      .catch(() => {
        uploadError();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (defaultValue) {
      setUrl(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="card card-img-upload">
      <div className="card-body row">
        <div className="col-md-8">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faImage} /> {uploadType}
              </span>
            </div>
            <div className="custom-file">
              <input
                type="file"
                accept="image/png,image/jpeg"
                className="custom-file-input"
                name={uploadType}
                disabled={disabled}
                id={`org-${uploadType}`}
                aria-describedby="inputGroupFileAddon01"
                onChange={(e) => upload(e)}
              />
              <label
                className="custom-file-label"
                htmlFor={`org-${uploadType}`}
                data-content={t("search")}
              >
                {t("placeholder")}
              </label>
            </div>
          </div>
          <span className="upload-desc text-small">
            {t("description")} <b>{size}</b>
          </span>
        </div>
        <div className="col-md-4">
          {url && (
            <div
              className={`${
                uploadType === "thumb" ? "img" : uploadType
              }-uploaded`}
              style={{ backgroundImage: `url(${url})` }}
            />
          )}
          {isLoading && (
            <LoadingContainer>
              <LoadingIcon color={COLORS.darkBlue} size={50} />
            </LoadingContainer>
          )}
        </div>
      </div>
    </div>
  );
}

const LoadingContainer = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
`;

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
