import styled from "styled-components";

export const ModalContent = styled.div`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
		padding-top: 0.4rem;
    h1 {
      font: "Lato", sans-serif;
      font-size: 1rem;
      line-height: 24px;
      color: #000;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .option {
      height: 3.5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
			align-items: center;
			border-top: 1px solid #D3D2D9;
			cursor: pointer;
			padding: 0 8px 0 2.65px;
			&:hover {
				background: #eae9ed;
			}
      .lang-content {
        display: flex;
        gap: 0.5rem;
				span {
					font-weight: 600;
					font-size: 14px;
					line-height: 21px;
					color: #000;
				}
      }
    }
  }
`;
