import React from "react";
import HeaderCadastro from "../headercadastro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { percentageMask } from "mask";

export default class SetorLoteTipo extends React.Component {
  state = {
    sector: this.props.sector,
    sectors: this.props.sectors,
    lot: this.props.lot,
    lots: this.props.lots,
    ticketTypes: this.props.ticketTypes,
  };
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    let { ticketTypes } = this.state;
    // if (ticketTypes.length === 1) {
    //   ticketTypes.push({ name: "Meia", discount: 50 });
    // }
    this.setState({ ticketTypes });
  }

  remove(index) {
    let { ticketTypes } = this.state;
    ticketTypes.splice(index, 1);
    this.setState({ ticketTypes });
  }

  addSector() {
    let { sector, sectors } = this.state;
    let name = sector.name.trim();

    if (name) {
      // Verifica se existe setor com msm nome
      let sectorExists =
        sectors?.findIndex((sec) => {
          return sec.name === name;
        }) > -1;

      // Adiciona ao array de locations
      // Object.assign é utilizado para n pegar a referencia de this.location
      if (!sectorExists) {
        sectors.push(Object.assign({}, { name }));
        sector.name = "";
        this.setState({ sector, sectors });
      } else {
        Swal.fire({
          title: "Atenção",
          icon: "warning",
          text: "Já existe um setor com este nome",
        });
      }
    }
  }

  // removeSector(nome) {
  //   let { sectors } = this.state;
  //   for (let index = 0; index < sectors.length; index++) {
  //     const elementNome = sectors[index];
  //     if (nome == elementNome) {
  //       sectors.splice(index, 1);
  //     }
  //   }
  //   this.setState({ sectors });
  // }

  addLot() {
    let { lot, lots } = this.state;
    if (lot.name) {
      let lotExists =
        lots.findIndex((val) => {
          return lot.name === val.name;
        }) > -1;

      if (!lotExists) {
        lots.push(Object.assign({}, lot));
        lot.name = "";

        this.setState({ lot, lots });
      } else {
        Swal.fire("Já existe um lote com este nome", "", "warning");
        // document.getElementById("lote-repetido").hidden = false;
      }
    }
  }

  render() {
    return (
      <>
        <HeaderCadastro
          title="2. Setores, lotes e tipos"
          text="configure os tipos de ingresso. <a href='/evento-help' target='_blank'>Saiba mais como configurar.</a>"
        ></HeaderCadastro>

        <div className="mt-4 event-step">
          <div className="col-12 pl-0">
            <div className="card mt-0">
              <div className="card-body" id="create-event-2">
                <div>
                  <b>2. Ingressos</b>
                  <span className="titulo-info">
                    (Setores, Lotes e Tipos de ingresso)
                  </span>
                </div>
                <b>2.1 Setores</b>
                <div className="form-row">
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      value={this.state.sector?.name}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.addSector();
                        }
                      }}
                      onChange={(e) => {
                        this.setState({ sector: { name: e.target.value } });
                      }}
                      placeholder="Nome do setor...ex: Open Bar, Pista, Camarote"
                    />
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn c1"
                      style={{ width: "100%" }}
                      onClick={(e) => {
                        this.addSector();
                      }}
                      type="submit"
                    >
                      Adicionar setor
                    </button>
                  </div>
                </div>
                <div className="form-row espacamento-top2 mb-3">
                  <div className="col-md-12">
                    <div className="input-group">
                      <div className="event-tags">
                        <span className="inputs mr-2" id="inputTags">
                          Setores criados:
                        </span>
                        {this.state.sectors?.length > 0 &&
                          this.state.sectors.map((sec, i) => (
                            <span
                              id={`sec-${i}`}
                              key={`sec-${i}`}
                              className="event-tag"
                              style={{ borderRadius: 10 + "rem" }}
                            >
                              {sec.name}
                              <FontAwesomeIcon
                                className="ml-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.state.sectors.splice(i, 1);
                                  this.setState({});
                                }}
                                icon={faTimesCircle}
                              />
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <b>2.2 Lotes </b>
                <span className="titulo-info">
                  (lembre-se que o lote de ordem 1 será o primeiro a ser
                  colocado a venda, e os outros entrarão após)
                </span>
                <div className="form-row">
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      value={this.state.lot?.name}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.addLot();
                        }
                      }}
                      onChange={(e) => {
                        this.setState({ lot: { name: e.target.value } });
                      }}
                      placeholder="Nome do lote...ex: Promocional, 1º, etc.."
                    />
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn c1"
                      style={{ width: "100%" }}
                      onClick={(e) => {
                        this.addLot();
                      }}
                    >
                      Adicionar lote
                    </button>
                  </div>
                </div>
                <div className="form-row espacamento-top2 mb-3">
                  <div className="col-md-12 event-tags">
                    <span className="inputs" id="inputTags">
                      Lotes criados:
                    </span>
                    {this.state.lots?.map((lot, i) => (
                      <span
                        id={`lot-${i}`}
                        key={`lot-${i}`}
                        className="event-tag"
                        style={{ borderRadius: 10 + "rem" }}
                      >
                        ordem {i + 1}: <b>{lot.name}</b>
                        <FontAwesomeIcon
                          className="ml-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.state.lots.splice(i, 1);
                            this.setState({});
                          }}
                          icon={faTimesCircle}
                        />
                      </span>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="form-row mb-2">
                  <div className="col-md-9">
                    <b>2.3 Tipo de Ingressos</b>
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn btn-blue-border"
                      onClick={(e) => {
                        this.state.ticketTypes.push({
                          name: "",
                          discount: null,
                        });
                        this.setState({});
                      }}
                      style={{ width: "100%" }}
                    >
                      Adicionar novo tipo
                    </button>
                  </div>
                </div>

                {this.state.ticketTypes.length > 0 &&
                  this.state.ticketTypes.map((ticketType, i) => (
                    <div key={i} className="form-row create-ticket-type">
                      <div
                        className={i === 0 ? "col-md-9 mb-3" : "col-md-8 mb-3"}
                      >
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text inputs"
                              id="inputTags"
                            >
                              Nome
                            </span>
                          </div>
                          <input
                            className="form-control"
                            value={ticketType.name}
                            onChange={(e) => {
                              ticketType.name = e.target.value;
                              this.setState({});
                            }}
                            placeholder="ex: Inteira, Meia.."
                          />
                        </div>
                      </div>

                      <div className="col-md-3 mb-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text inputs"
                              id="inputTags"
                            >
                              %
                            </span>
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            value={ticketType.discount}
                            max="100"
                            min="0"
                            onChange={(e) => {
                              ticketType.discount = Number(
                                percentageMask(e.target.value)
                              );
                              if (Number(e.target.value) > 100) {
                                ticketType.discount = 100;
                              }
                              this.setState({});
                            }}
                            placeholder="Desconto (porcentagem) ex: 10"
                            disabled={i === 0}
                          />
                        </div>
                      </div>
                      {i > 0 && (
                        <div className="col-md-1 mb-3 text-center">
                          <FontAwesomeIcon
                            onClick={() => this.remove(i)}
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              margin: "10px 0px 0px",
                              color: "red",
                            }}
                            icon={faTrashAlt}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
