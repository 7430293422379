import Loading from "components/loading";
import PageHeader from "components/page-header";
import React, { Fragment, useEffect, useState } from "react";
import New_api from "services/new-api";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function AuditDetailedSales() {
  const isWhitelabel = Number(process.env.REACT_APP_IS_WHITELABEL);
  const {t} = useTranslation()

  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const params = {
      resource: { dashboard: 482 },
      params: {},
    };

    New_api.post(`/embeded/generate-url`, params).then((res) => {
      setLoading(false);
      setUrl(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!isWhitelabel && (
        <Fragment>
          <PageHeader
            title="Vendas"
            text="Esta é uma página para análise das vendas dos eventos."
          />
          {loading ? (
            <Loading title={t("global:loadingText")} />
          ) : (
            <Fragment>
              <div className="container-fluid mt-3">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <div className="w-100">
                        <iframe
                          title="salesDetails"
                          id="salesDetails"
                          src={url}
                          style={{
                            border: 0,
                            width: "100%",
                            height: "calc(100vh - 162px)",
                          }}
                          allowFullScreen
                          allowTransparency
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <div className="fixed-bottom">
            <div className="remove-metabase-bottom"></div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
