import PropTypes from "prop-types";
import React from "react";

import "./styles.css";

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function RadioButton({
  id,
  text,
  checked,
  name,
  value,
  onChange,
}) {
  return (
    <label htmlFor={id}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        className="radiobutton-input"
      />
      <span className="custom-radiobutton">
        <div className="custom-radiobutton-circle">
          <div
            className={`custom-radiobutton-circle${checked ? "-active" : ""}`}
          ></div>
        </div>
        {text}
      </span>
    </label>
  );
}
