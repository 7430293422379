import React, { useEffect, useState } from "react";
import api from "services/api";
import PageHeader from "components/page-header";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Modal from "react-modal";
import EmptyData from "components/empty-data";
import { useLanguageSettings } from "hooks/language-settings";

export default function DetalhesCancelamentoEvento(props) {
  const { currencyFormatter } = useLanguageSettings();
  const [pagamentos, setPagamentos] = useState([]);
  const [lista, setLista] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const colunas = [
    {
      name: "ID Pagamento",
      selector: "token",
      sortable: true,
    },
    {
      name: "Comprador",
      selector: "comprador",
      sortable: true,
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: true,
    },
    {
      name: "Pagamento",
      selector: "formaPagamento",
      sortable: true,
    },
    {
      name: "Status Pagamento",
      selector: "situacaoOrdem",
      sortable: true,
    },
    {
      name: "Status Estorno",
      selector: "situacaoEstorno",
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {row.transferencias.length > 0 ? (
            <button
              className="btn btn-sm c3"
              onClick={(e) => mostraTranferencias(row.transferencias)}
            >
              Detalhes
            </button>
          ) : (
            ""
          )}{" "}
          {row.situacaoOrdem === "finalizado" &&
          row.situacaoEstorno === "número máximo de tentantvas realizadas." ? (
            <button
              onClick={(e) => resetTentativas(row.token)}
              className="btn btn-sm c3"
            >
              Resetar tentativas
            </button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  const mostraTranferencias = (transferencias) => {
    setModalIsOpen(true);
    setTransfers(transferencias);
  };
  function closeModal() {
    setModalIsOpen(false);
  }
  const carregaInformacoes = (id) => {
    let usuarioLogado = JSON.parse(localStorage.getItem("user"));

    if (usuarioLogado.type !== "GOD") {
      window.location.href = "/eventos";
    } else {
      api
        .get(`detalhamento/cancelamentos/evento/${id}`)
        .then((res) => {
          setPagamentos(res.data.pagamentos);
          setLista(res.data.pagamentos);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  };
  const resetTentativas = (token) => {
    api
      .get(`reinicia/system/token/${token}`)
      .then((res) => {
        const id = props.match.params.event_id;
        carregaInformacoes(id);
      })
      .catch((err) => {
        Swal.fire("Erro", err.response.data.message, "error");
      });
  };
  useEffect(() => {
    const id = props.match.params.event_id;
    carregaInformacoes(id);
  }, [props.match.params.event_id]);

  const filtraPorNome = (event) => {
    let ar = [];
    pagamentos.forEach((item) => {
      if (
        item.comprador.toLowerCase().includes(event.target.value.toLowerCase())
      ) {
        ar.push(item);
      }
    });
    setLista(ar);
  };

  const filtraPorEmail = (event) => {
    let ar = [];
    pagamentos.forEach((item) => {
      if (item.email.toLowerCase().includes(event.target.value.toLowerCase())) {
        ar.push(item);
      }
    });
    setLista(ar);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };

  const formataDataHora = (data) => {
    let date = new Date(data);
    return (
      ("00" + date.getDate()).slice(-2) +
      "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " - " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2)
    );
  };
  return (
    <>
      <PageHeader
        title="Vendas"
        text="Listagem detalhada de compras no evento estornado ou remarcado."
      ></PageHeader>
      <div id="transfer-cancel" className="mt-4 container-fluid">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Detalhes da transferência"
        >
          <div className="receipt-transfer">
            <div
              onClick={(e) => closeModal()}
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                cursor: "pointer",
              }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {transfers.map((transferencia) => (
              <>
                <div className="receipt-header">
                  <h4>Comprovante de Pagamento</h4>
                  <span>{formataDataHora(transferencia?.created_at)}</span>
                  <hr></hr>
                  <span>Valor pago</span>
                  <h5>{currencyFormatter(transferencia?.amount)}</h5>
                  <hr></hr>
                  <span>Status</span>
                  <h5>{transferencia?.status.toUpperCase()}</h5>
                  <hr></hr>
                </div>
                <div className="receipt-body">
                  <h4>Dados do recebedor</h4>
                  <div>
                    <span>Recebedor: </span>
                    <span>{transferencia?.name}</span>
                  </div>
                  <div>
                    <span>Agência / Conta: </span>
                    <span>
                      {transferencia?.agency + "/ CC " + transferencia?.account}
                    </span>
                  </div>
                  <div>
                    <span>Documento: </span>
                    <span>{transferencia?.document}</span>
                  </div>
                  <div>
                    <span>Instituição: </span>
                    <span>{transferencia?.bank?.name}</span>
                  </div>
                  <hr></hr>
                  <div>
                    <span>Data e hora: </span>
                    <span>{formataDataHora(transferencia?.created_at)}</span>
                  </div>
                  <div>
                    <span>ID/Transação: </span>
                    <span>{transferencia?.transaction_id}</span>
                  </div>
                  <div>
                    <span>Código de autenticação: </span>
                    <span>{transferencia?.external_transfer_id}</span>
                  </div>
                  {transferencia?.status === "recusado" && (
                    <>
                      <hr></hr>
                      <div>
                        <span>Motivo do erro: </span>
                        <span>{transferencia?.occurrences[0].detail}</span>
                      </div>
                    </>
                  )}
                </div>
              </>
            ))}
          </div>
        </Modal>
        <div className="col-12">
          <div className="card">
            <div className="content card-header">
              <div className="row">
                <div className="col-md-6">
                  <div className="card-search">
                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                    <input
                      type="text"
                      className="input-custom"
                      name="code"
                      placeholder="Pesquise pelo nome do comprador"
                      onChange={(e) => filtraPorNome(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-search">
                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                    <input
                      type="text"
                      className="input-custom"
                      name="code"
                      placeholder="Pesquise pelo e-mail do comprador"
                      onChange={(e) => filtraPorEmail(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <DataTable
                data={lista}
                columns={colunas}
                pagination={true}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Vendas por página:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                noHeader={true}
                noDataComponent={
                  <EmptyData title="Nenhum resultado disponível." />
                }
                className="table table-sm table-nowrap card-table"
              ></DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
