import React from 'react';
import PropTypes from 'prop-types';

CustomButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.string,
	children: PropTypes.node,
	buttonType: PropTypes.bool,
	disabled: PropTypes.bool
};

export default function CustomButton(props) {
	const { className, onClick, text, children, buttonType, disabled } = props;
	return (
		<button
			type={buttonType ? 'submit' : 'button'}
			className={className}
			onClick={onClick}
			disabled={disabled}
		>
			{text}
			{children}
		</button>
	);
}
