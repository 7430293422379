import JoditEditor from "jodit-react";
import React, { useEffect, useState, useRef } from "react";
import api from "services/api";

export default function ModalJodit(props) {
  const [status, setStatus] = useState("hide");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [evento, setEvento] = useState("");
  const closeModal = () => {
    setStatus("");
    setContent("");
    editor.current.value = "";
    setEvento("");
    props.onRequestClose(false);
  };
  const config = {
    readonly: false,
    height: 320,
    toolbarButtonSize: "small",
    buttons: [
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "source",
    ],
    buttonsMD: [
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "source",
    ],
    buttonsSM: [
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "source",
    ],
    buttonsXS: [
      "bold",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "source",
    ],
  };
  useEffect(() => {
    if (props.isOpen) {
      setStatus("show");
      setEvento(props.event);
    }
  }, [props.isOpen,props.event]);

  const enviaComunicado = (e) => {
    e.preventDefault();
    let data = { event_id: evento.id, mensagem: content };
    let url =
      props.tipo === "comunicado"
        ? "envia/comunicado/event/"
        : "envia/remarcacao/event/";
    let avisoSucesso =
      props.tipo === "comunicado"
        ? "Comunicado enviado aos participantes."
        : "Remarcação enviada aos participantes.";
    let avisoErro =
      props.tipo === "comunicado"
        ? "Erro ao tentar enviar comunicado."
        : "Erro ao tentar enviar remarcação.";
    api
      .post(url, data)
      .then((res) => {
        closeModal();
        props.retorno("sucesso", avisoSucesso);
      })
      .catch((err) => {
        props.retorno("erro", avisoErro);
      });
  };
  return (
    <>
      <div id="venda-detalhe" className={"modal fade " + status} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{props.titulo}</h4>
            </div>
            <div
              id="DetalheVenda"
              className="modal-body"
              style={{ height: "350px", overflowY: "auto" }}
            >
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                // tabIndex={1}
                onBlur={
                  (newContent) => setContent(newContent.srcElement.innerHTML)
                }
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn c3"
                onClick={(e) => enviaComunicado(e)}
              >
                Enviar comunicado
              </button>

              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => closeModal()}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
