import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close-black.svg";

const ToastContainer = styled.div`
  gap: 1rem;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  display: inline-flex;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  gap: 1rem;
  div {
    height: 3rem;
    display: flex;
    cursor: pointer;
    align-items: start;
  }
`;

const Toast = ({ message, duration, isOpen, setToastOpen }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        setToastOpen(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, isOpen, setToastOpen]);

  return open ? (
    <ToastContainer>
      {message}
      <div>
        <CloseIcon
          onClick={() => {
            setOpen(false);
            setToastOpen(false);
          }}
        />
      </div>
    </ToastContainer>
  ) : null;
};

export default Toast;
