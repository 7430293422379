import { faEllipsisV, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastr";
import styled from "styled-components";

import Card from "components/card";
import CustomButton from "components/custom-button";

import ModalBank from "./modalBank";

import "../style.css";

AccountBank.propTypes = {
  readOnly: PropTypes.bool,
  organization: PropTypes.shape({
    organization: PropTypes.object,
    setOrganization: PropTypes.func,
  }),
  accountsBank: PropTypes.array,
  stepId: PropTypes.number,
  nextStepId: PropTypes.number,
  setSteps: PropTypes.func,
  org_id: PropTypes.number,
  callBackEdit: PropTypes.func,
};

let container;

export default function AccountBank(props) {
  const {
    readOnly,
    organization: { organization, setOrganization },
    accountsBank,
    stepId,
    org_id,
    nextStepId,
    setSteps,
    callBackEdit,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [bankEdit, setBankEdit] = useState({
    holder_name: "",
    holder_document: "",
    transfer_bank_id: "",
    account_type: "",
    agency: "",
    account: "",
    account_digit: "",
    pix_key: "",
    pix_value: "",
    agency_digit: "",
    active: "",
  });
  const [accountBanks, setAccountBanks] = useState([]);
  const user = JSON.parse(localStorage.getItem("user") || null);
  const { t } = useTranslation("organizations", {
    keyPrefix: "configuration.second-step",
  });

  const openModal = (bank, index) => {
    if (bank) {
      setBankEdit({
        index,
        id:bank.id,
        holder_name: bank.holder_name,
        holder_document: bank.holder_document,
        transfer_bank_id: bank.transfer_bank_id,
        account_type: bank.account_type,
        agency: bank.agency,
        account: bank.account,
        account_digit: bank.account_digit,
        pix_key: bank.pix_key,
        pix_value: bank.pix_value,
        active: bank.active,
      });
    }
    setIsOpen(true);
  };

  const callBack = (data, indexEdit) => {
    let receivedData = data;
    if (indexEdit || indexEdit === 0) {
      // Removing keys that are only used in the frontend
      delete receivedData.checked;
      delete receivedData.activeModal;
      accountBanks[indexEdit] = {
        ...receivedData,
        id: accountBanks[indexEdit].id,
      };
    } else {
      const arrayBank = accountBanks.map((item) => {
        return { ...item, active: false };
      });
      // All bank accounts created have been checked!
      receivedData = {
        ...receivedData,
        active: true,
      };
      arrayBank.push(receivedData);
      setAccountBanks(arrayBank);
      setOrganization({ ...organization, accountsBank: arrayBank });
    }
  };

  const chooseActiveBank = (indexBank) => {
    const newArrayBanks = [];
    let newBankData;
    for (let index = 0; index < accountBanks.length; index++) {
      const bank = accountBanks[index];
      if (index === indexBank) {
        newBankData = {
          ...bank,
          active: true,
        };
      } else {
        newBankData = {
          ...bank,
          active: false,
        };
      }
      newArrayBanks.push(newBankData);
    }
    setAccountBanks(newArrayBanks);
    setOrganization({ ...organization, accountsBank: newArrayBanks });
  };

  const nextStep = () => {
    if (accountBanks.length === 0) {
      return container.error(t("toast.error"));
    }

    setOrganization({
      ...organization,
      accountsBank: accountBanks,
    });

    if (!org_id) {
      setSteps((prevState) => {
        const newState = prevState.map((obj) => {
          if (stepId === Number(obj.id)) {
            return {
              ...obj,
              complete: true,
              next: true,
              currentPosition: nextStepId,
            };
          } else if (nextStepId === Number(obj.id)) {
            return {
              ...obj,
              complete: false,
              next: true,
              currentPosition: nextStepId,
            };
          } else {
            return { ...obj, currentPosition: nextStepId };
          }
        });
        return newState;
      });
    } else {
      const updateBanks = {
        ...organization,
        accountsBank: accountBanks,
      };
      callBackEdit(updateBanks);
    }
  };

  const hasAccountBankPermission = () => {
    return !!(user?.is_admin || !org_id);
  };

  useEffect(() => {
    // The "accountsBanks" is coming from the parent
    if (accountsBank && accountsBank.length > 0) {
      setAccountBanks(accountsBank);
    }
  }, [accountsBank]);

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <Row>
        <Col className="header-container">
          <Col>
            <h1 className="header-title">{t("header.title")}</h1>
            <p className="header-postitle">{t("header.text")}</p>
          </Col>
        </Col>
      </Row>
      <Card header={t("info")} className="mt-3">
        {accountBanks.length > 0 ? (
          accountBanks.map((item, i) => (
            <Row
              key={i}
              style={{
                marginLeft: "2px",
                marginRight: "2px",
                border: " 2px solid",
                borderColor: item.active ? "#2075F4" : "#98AFBC",
                marginBottom: "20px",
                borderRadius: "10px",
                paddingTop: "11px",
                paddingBottom: "5px",
                minHeight: "50px",
              }}
            >
              <Col xl="1" md="2" sm="2" xs="2" style={{ display: "flex" }}>
                {!readOnly && (
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <InputRadio
                      name="inputRadio"
                      onClick={() => chooseActiveBank(i)}
                      defaultChecked={item.active}
                    />
                  </Col>
                )}
              </Col>
              <Col xl="10" md="8" sm="8" xs="8">
                <TextH3>{item.holder_name}</TextH3>
                {!readOnly && (
                  <TextH4>{`Conta ${item.account}-${item.account_digit}`}</TextH4>
                )}
              </Col>
              {!readOnly && (
                <Col
                  xl="1"
                  md="2"
                  sm="2"
                  xs="2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DotButton onClick={() => openModal(item, i)}>
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </DotButton>
                </Col>
              )}
            </Row>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "2px solid #98AFBC",
              borderRadius: "10px",
              padding: "23px 0 20px 38px",
            }}
          >
            <TextH3>{t("card.empty")}</TextH3>
          </div>
        )}
        <Col
          style={{
            marginBottom: "7px",
            marginTop: "8px",
            paddingLeft: "6px",
          }}
        >
          {accountBanks.length <= 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextH4>{t("card.add-account")}</TextH4>
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                padding: "0 6px 0 0",
                color: "#2075F4",
                marginTop: "-4px",
              }}
            >
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div>
              <TextH4>{t("card.safe-data")}</TextH4>
            </div>
          </div>
        </Col>
        {!readOnly && (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
            }}
          >
            {hasAccountBankPermission() ? (
              <Fragment>
                <CustomButton
                  onClick={openModal}
                  className="btn btn-secondary col-md-4 col-12"
                  text={t("card.button.add-account")}
                />
              </Fragment>
            ) : (
              <Fragment>
                <p>
                  Para trocar ou adicionar novas contas, por favor, entre em
                  contato com o nosso suporte ou seu representante comercial.
                </p>
              </Fragment>
            )}
          </Col>
        )}
      </Card>
      {hasAccountBankPermission() && (
        <Row className="justify-content-end">
          <Col md="3" sm="12" className="mb-2">
            <CustomButton
              className="btn btn-primary col-12"
              text={t(org_id ? "card.button.save" : "card.button.next")}
              onClick={nextStep}
            />
          </Col>
        </Row>
      )}
      <ModalBank
        toggleFunction={{ isOpen, setIsOpen }}
        organizationName={organization.organizationInfo.name}
        orgId={org_id}
        callBack={callBack}
        bankEdit={bankEdit}
      />
    </>
  );
}

//////////////////// COMPONENTs ////////////////////

const DotButton = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 7px;
  color: #95aac9;
  background: transparent;
  border: none;
  cursor: pointer;
`;
const InputRadio = styled.input.attrs({
  type: "radio",
})`
  transform: scale(1.15);
  cursor: pointer;
`;
const TextH3 = styled.h3`
  font-size: 1rem;
  color: #4f6c7c;
  font-weight: normal;
`;
const TextH4 = styled.h4`
  font-size: 0.85rem;
  color: #8fa5b2;
  font-weight: normal;
`;
