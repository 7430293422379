import React from 'react';
import { ReactComponent as Dot } from '../../assets/icons/dot.svg'
import { ReactComponent as SelectedDot } from '../../assets/icons/dotSelected.svg'
import { NavButton, NavigationContainer } from './styles';

export default function DotNavigation({elements, onClick, selectedIndex}){
  return(
    <NavigationContainer>
      {elements?.map((element, index) => (
        <NavButton 
          key={index} 
          onClick={(e)=>onClick(e, index)} 
          onTouchEnd={(e)=>onClick(e, index)}
        >
          {index === selectedIndex ? <SelectedDot stroke='#0050C3'/> : <Dot fill='#D9D9D9'/>}
        </NavButton>
      ))}
    </NavigationContainer>
  )
}