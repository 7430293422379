import React, { Fragment, useEffect, useState } from "react";
import RecycleBin from "../../assets/icons/RecycleBin.svg";
import "react-google-places-autocomplete/dist/assets/index.css";
import { Button } from "react-bootstrap";
import AddCircle from "../../assets/icons/addCircle.svg";
import { ToastContainer } from "react-toastr";
import ButtonDashed from "components/buttonDashed";
import AsyncCategoryInput from "components/asyncCategoryInput";
import PropTypes from "prop-types";

EditCategorySelector.propTypes = {
  categories: PropTypes.array,
  callback: PropTypes.func,
  edit: PropTypes.bool,
};

export default function EditCategorySelector(props) {
  const [newCategory, setNewCategory] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [newCategoryId, setNewCategoryId] = useState(null);
  const [openOnClick, setOpenOnClick] = useState(true);
  const [openSecondClick, setOpenSecondClick] = useState(true);

  // Extra
  let container;

  // Arrays
  const [firstSubcategory, setFirstSubcategory] = useState([]);
  const [secondSubcategory, setSecondSubcategory] = useState([]);

  const { categories, callback } = props;

  useEffect(() => {
    if (categories[0].mainCategory.value) {
      const categoriesCount = categories.filter(
        (el) => el.mainCategory.value !== 0
      ).length;
      setSelectedCategories(categoriesCount);
      if (categoriesCount > 1) {
        setNewCategory(true);
      }
      for (const category of categories) {
        if (category.mainCategory !== 0) {
          if (category.position === 1) {
            setCategoryId(category?.mainCategory);
            setFirstSubcategory(category.sub);
          } else {
            setNewCategoryId(category?.mainCategory);
            setSecondSubcategory(category.sub);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [categories, categoryId, newCategoryId]);

  const categorySelect = (category_id, position) => {
    if (position === 1) {
      setCategoryId(category_id);
    } else {
      setNewCategoryId(category_id);
    }

    const filteredStepData = categories.map((el) => {
      if (el.position === position) {
        el.mainCategory = category_id;
        el.sub = [];
      }
      return el;
    });
    if (
      (newCategory && selectedCategories < 2) ||
      (!newCategory && selectedCategories === 0)
    ) {
      setSelectedCategories(selectedCategories + 1);
    }

    callback(filteredStepData);
  };

  const selectSubcategory = (subcategory, position) => {
      if (position === 1) {
        setFirstSubcategory(subcategory);
      } else {
        setSecondSubcategory(subcategory);
      }

      if (firstSubcategory.length < 3) {
        setOpenOnClick(true);
      }

      if (secondSubcategory.length < 3) {
        setOpenSecondClick(true);
      }

      const filteredStepData = categories.map((el) => {
        if (el.position === position) {
          el.sub = subcategory
        }
        return el;
      });

      callback(filteredStepData);
  };

  const removeCategory = () => {
    setNewCategory(false);
    if (selectedCategories > 0) {
      setSelectedCategories(selectedCategories - 1);
    }

    const categoriesUpdated = [
      {
        position: 1,
        mainCategory: categoryId,
        sub: firstSubcategory,
      },
      { position: 2, mainCategory: {label: '', value: 0}, sub: [] },
    ];

    setSecondSubcategory([])
    setNewCategoryId(false)
    callback(categoriesUpdated);
  };

  const handleAddNewCategory = () => {
    if (!categoryId || firstSubcategory.length === 0) {
      container.error("Favor adicionar uma categoria com subcategoria antes de criar outra.");
      return false;
    }
    setNewCategory(true);
  };


  return (
    <Fragment>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      
      <div className="form-row">
        <div className="form-group col-md-6 d-flex justify-content-end">
          <label className="category-counter">
            {selectedCategories}/2 Categorias
          </label>
        </div>
        <div className="form-group col-md-6 d-flex justify-content-end">
          <label className="category-counter">
            {categories[0].sub.length + categories[1].sub.length || 0}/
            {!newCategory ? "3" : "6"} Subcategorias
          </label>
        </div>
        <div className="form-group col-md-6">
          <label className="form-label">Categoria</label>
          <AsyncCategoryInput
            defaultValue={categories[0].mainCategory.value !== 0 && categories[0].mainCategory}
            callBack={(params) => categorySelect(params, 1)}
            category={categoryId}
            otherCategory={newCategoryId}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="form-label">Subcategoria</label>
          <AsyncCategoryInput
            isMulti
            isSubcategory
            onEventEditOrCreate
            defaultValue={categories[0].sub.length > 0 && categories[0].sub}
            isDisabled={!categoryId}
            isClearable={false}
            openMenuOnClick={openOnClick}
            callBack={(params) => selectSubcategory(params, 1)}
            category={categoryId}
          />
        </div>

        {!newCategory ? (
          <Fragment>
            <ButtonDashed
              onClick={() => handleAddNewCategory()}
              svg={AddCircle}
              text="Adicionar nova categoria"
            />
          </Fragment>
        ) : (
          <Fragment>
            <div className="form-group col-md-6">
              <label className="form-label">Categoria</label>
              <AsyncCategoryInput
                defaultValue={categories[1].mainCategory.value !== 0 && categories[1].mainCategory}
                callBack={(params) => categorySelect(params, 2)}
                category={categoryId}
                otherCategory={categoryId}
              />
            </div>
            <div className="form-group col-md-5">
              <label className="form-label">Subcategoria</label>
              <AsyncCategoryInput
                isMulti
                onEventEditOrCreate
                isSubcategory
                isClearable={false}
                defaultValue={categories[1].sub.length > 0 && categories[1].sub}
                openMenuOnClick={openSecondClick}
                callBack={(params) => selectSubcategory(params, 2)}
                category={newCategoryId}
                isDisabled={newCategoryId.value === 0}
              />
            </div>
            <div className="form-group col-md-1">
              <label className="form-label remove">Remover</label>
              <Button
                variant="outline-primary trash-icon"
                block
                onClick={() => removeCategory()}
              >
                <img
                  src={RecycleBin}
                  alt="Delete category icon"
                  className="align-items-center justify-content-center recycle-bin"
                ></img>
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
