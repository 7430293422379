import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

DataTip.propTypes = {
  tipContent: PropTypes.string.isRequired,
  tipContentClass: PropTypes.string,
  tipContainerClass: PropTypes.string,
  icon: PropTypes.node,
};

export default function DataTip({
  tipContent,
  tipContentClass,
  tipContainerClass,
  icon,
}) {
  return (
    <span className={`tips-component ${tipContainerClass}`}>
      {icon || <FontAwesomeIcon icon={faInfoCircle} />}
      <div className={tipContentClass || "tips-content"}>{tipContent}</div>
    </span>
  );
}
