// import Loading from 'components/loading';
import React, { useState, useCallback, useEffect } from "react";
import { ToastContainer } from "react-toastr";
import api from "services/api";
import Swal from "sweetalert2";

// Components
import EventCreateSide from "./event-create-side";

// Components-steps
import EventCreateStep1, {
  validateStep1,
} from "./event-create-steps/event-create-step-1";
import EventCreateStep2, {
  validateStep2,
} from "./event-create-steps/event-create-step-2";
import EventCreateStep3 from "./event-create-steps/event-create-step-3";
import EventCreateStep4, {
  validateStep4,
} from "./event-create-steps/event-create-step-4";
import Loading from "components/loading";
import PublishedEvent from "./event-create-steps/event-create-published";

let container;

const EventCreate = () => {
  const [step, setStep] = useState(1);

  const [eventId, setEventId] = useState(null);

  const [stepBooleans, setStepBooleans] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const [data, setData] = useState({
    accept: false,
    categories: [],
    dates: {
      start_date: "",
      end_date: "",
      sails_limit: "",
      start_hour: "",
      end_hour: "",
      sails_limit_hour: "",
    },
    datesArr: [],
    sectors: [{ name: "Geral" }],
    lots: [{ name: "1º Lote" }],
    location: {
      name: "",
      street: "",
      neighborhood: "",
      number: "",
      city: "",
      state: "",
      zip_code: "",
      complement: "",
      formatted_address: "",
    },
    locations: [],
    dateLocations: [],
    ticketTypes: [
      { name: "Inteira", discount: 0 },
      { name: "Meia", discount: 50 },
    ],
    event: {
      hasTickets: 1,
      organization_id: null,
      categories: [
        { position: 1, mainCategory: 0, sub: [] },
        { position: 2, mainCategory: 0, sub: [] },
      ],
      age_range: 18,
      name: "",
      description: "",
      banner: "",
      thumb: "",
      tags: [],
      taxes: "Repassar",
      is_public: 1,
      is_online: 0,
      slug: "",
      ticket_nomenclature: "Ingresso",
      user_id: "",
      link_other: "",
      communication_channel_type: "",
      communication_channel: "",
      currency: "",
      timezone: "",
    },
    sponsors: [],
  });

  const step1Callback = useCallback(
    (callbackData, agreeNoImage) => {
      setStepBooleans({
        ...stepBooleans,
        step1: false,
      });
      if (!validateStep1(callbackData, agreeNoImage)) {
        return;
      }
      checkSlug(callbackData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const checkSlug = (callbackData) => {
    api
      .get(`check/event/slug/${callbackData.event.slug}`)
      .then(() => {
        const arr = [];
        arr.push(callbackData.location);

        setData({
          ...data,
          accept: true,
          event: {
            ...callbackData.event,
          },
          location: callbackData.location,
          dates: callbackData.dates,
          sponsors: callbackData.sponsors,
          locations: arr,
        });
        setStep(2);
      })
      .catch(() => {
        container.error("Nome do evento já utilizado!");
      });
  };

  function step2Callback(callbackData) {
    setStepBooleans({
      ...stepBooleans,
      step2: false,
    });

    if (!validateStep2(callbackData)) {
      return;
    }

    const sectors = callbackData.sectors.filter((sec) => {
      return sec.name && sec.name.trim().length;
    });

    const lots = callbackData.lots.filter((lot) => {
      return lot.name && lot.name.trim().length;
    });

    const dates = (callbackData.sectors = sectors.map((sector) => {
      sector.active = true;
      sector.ticketTypes = callbackData.ticketTypes.map((type) => {
        type.active = true;
        return Object.assign({}, type);
      });
      sector.lots = lots.map((lot) => {
        lot.active = true;
        return Object.assign({}, lot);
      });
      return Object.assign({}, sector);
    }));

    setData({
      ...data,
      sectors: callbackData.sectors,
      lots: callbackData.lots,
      ticketTypes: callbackData.ticketTypes,
      datesArr: dates,
    });

    // alert(JSON.stringify(callbackData));
    if (callbackData.sectors.length === 1) {
      // setLoading(true);
      configureTickets(dates);
      setStep(4);
      return;
    }
    setStep(3);
  }

  function step3Callback(callbackData) {
    setStepBooleans({
      ...stepBooleans,
      step3: false,
    });

    setData({
      ...data,
      datesArr: callbackData,
    });

    configureTickets(callbackData);
  }

  function step4Callback(dateCallbackData) {
    setStepBooleans({
      ...stepBooleans,
      step4: false,
    });

    if (!validateStep4(dateCallbackData)) {
      return;
    }

    setData({
      ...data,
      dateLocations: dateCallbackData,
    });
  }

  function step4DataCallback(eventCallbackData) {
    setData({
      ...data,
      event: eventCallbackData,
    });
  }

  function nextStep() {
    switch (step) {
      case 1:
        setStepBooleans({
          ...stepBooleans,
          step1: true,
        });
        break;
      case 2:
        setStepBooleans({
          ...stepBooleans,
          step2: true,
        });
        break;
      case 3:
        setStepBooleans({
          ...stepBooleans,
          step3: true,
        });
        break;
      case 4:
        setStepBooleans({
          ...stepBooleans,
          step4: true,
        });
        break;
      default:
        break;
    }
  }

  function backStep() {
    if (step === 4 && data.sectors.length === 1) {
      setStep(2);
      return;
    }
    if (step >= 2 || step <= 4) {
      setStep(step - 1);
      return;
    }
  }

  function escape() {
    window.location.href = "/eventos";
  }

  function navigateStep(value) {
    if (data.locations.length && data.accept) {
      setStep(value);
    }
  }

  function configureTickets(callbackData) {
    return new Promise((resolve) => {
      let dateLocations = [];
      let { end_date, start_date, sails_limit } = data.dates;
      callbackData.forEach((date) => {
        let dateLoc = {
          end_date,
          start_date,
          sails_limit,
          location: data.location,
          name: "Data",
          title: "Data",
          sectors: [],
        };
        if (date.active) {
          let sec = { name: date.name, lots: [] };
          date.lots.forEach((lot) => {
            if (lot.active) {
              let new_lot = { name: lot.name, qty: 0, price: 0, types: [] };
              date.ticketTypes.forEach((type) => {
                if (type.active) {
                  let { name, discount } = type;
                  new_lot.types.push({ name, discount, qty: null });
                }
              });
              sec.lots.push(new_lot);
            }
          });
          dateLoc.sectors.push(sec);
        }
        dateLocations.push(dateLoc);
      });
      setData({
        ...data,
        dateLocations: dateLocations,
      });
      resolve(true);
      setStep(4);
    });
  }

  function saveEvent() {
    document.getElementById("loading").classList.remove("esconde");
    const { sponsors } = data;
    let tagsstring = "";
    if (data.event.tags.length > 0) {
      data.event.tags.forEach((t) => {
        tagsstring += t + ",";
      });
      tagsstring = tagsstring.substring(0, tagsstring.length - 1);
    }

    let event = Object.assign({}, data.event);
    event.tags = tagsstring;
    let { categories } = event;
    delete event["categories"];
    categories = [categories];
    let { dateLocations, sectors, lots, locations, sectorLots } = data;

    if (!dateLocations.length) {
      dateLocations = data.dates.map((date) => date);
    } else {
      for (let date of dateLocations) {
        date.sectors.forEach((sec) => {
          sec.lots.forEach((lot) => {
            for (let type of lot.types) {
              type.qty = !Number(type.qty) ? lot.qty : type.qty;
            }
          });
        });
      }
    }

    api
      .post("users/events", {
        event,
        categories,
        sectors,
        lots,
        locations,
        sectorLots,
        dateLocations,
        sponsors,
      })
      .then((res) => {
        document.getElementById("loading").classList.add("esconde");
        setStep(5);
        setEventId(res.data);
      })
      .catch(() => {
        document.getElementById("loading").classList.add("esconde");
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Ocorreu um erro, por favor tente novamente",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      });
  }

  useEffect(() => {
    document.getElementById("loading").classList.add("esconde");
  }, []);

  const handleAccept = (e) => {
    setData({
      ...data,
      accept: e.target.checked,
    });
  };

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <Loading title="Aguarde..." />
      {step !== 5 && (
        <div className="row" style={{ marginLeft: "0px", marginRigh: "0px" }}>
          <EventCreateSide
            step={step}
            navigateStep={navigateStep}
            datesArr={data.datesArr}
            sectors={data.sectors}
            dateLocations={data.dateLocations}
            locationArr={data.locations}
          />
          <div
            className={
              step === 5
                ? "col-md-12 col-lg-10 d-flex align-items-center"
                : "col-md-12 col-lg-10"
            }
            style={{ paddingBottom: "80px" }}
          >
            {step === 1 && (
              <EventCreateStep1
                event={data.event}
                location={data.location}
                dates={data.dates}
                sponsors={data.sponsors}
                callBack={step1Callback}
                stepCheck={stepBooleans.step1}
                accept={data.accept}
                setAccept={handleAccept}
              />
            )}
            {step === 2 && (
              <EventCreateStep2
                sectors={data.sectors}
                lots={data.lots}
                ticketTypes={data.ticketTypes}
                callBack={step2Callback}
                stepCheck={stepBooleans.step2}
              />
            )}
            {step === 3 && (
              <EventCreateStep3
                dates={data.datesArr}
                callBack={step3Callback}
                stepCheck={stepBooleans.step3}
              />
            )}
            {step === 4 && (
              <EventCreateStep4
                sectors={data.sectors}
                datesLocations={data.dateLocations}
                callBack={step4Callback}
                event={data.event}
                stepCheck={stepBooleans.step4}
                dataEventCallback={step4DataCallback}
              />
            )}
          </div>
        </div>
      )}
      {step === 5 && (
        <>
          <PublishedEvent event={data.event} id={eventId} />
        </>
      )}
      {/* Finalização do evento / Barra de termos de uso */}
      {step !== 5 && (
        <div className="content mt-3" id="event-accept">
          <div className="form-row justify-content-between align-items-center">
            {step === 1 ? (
              <div className="col-md-3">
                <button
                  className="btn btn-primary float-right btn-white-border"
                  style={{ width: "100%" }}
                  onClick={escape}
                >
                  Sair
                </button>
              </div>
            ) : (
              <div className="col-md-3">
                <button
                  className="btn btn-primary float-right btn-white-border"
                  style={{ width: "100%" }}
                  onClick={backStep}
                >
                  Voltar
                </button>
              </div>
            )}
            <div className="col-md-3">
              {step !== 4 ? (
                <button
                  className="btn btn-primary float-right btn-white"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={!data.accept}
                  onClick={() => {
                    nextStep();
                  }}
                >
                  Prosseguir
                </button>
              ) : (
                <button
                  className="btn btn-primary float-right btn-white"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={!data.accept}
                  onClick={() => {
                    saveEvent();
                  }}
                >
                  Publicar evento
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCreate;
