import React from "react";
import "./style.css";

export default class Assentos extends React.Component {
  rowRender = (seatRow, key) => {
    return (
      <div key={`row-${key}`} className="row">
        {seatRow.seats &&
          seatRow.seats.map((seat, i) => {
            return this.seatRender(seat, seatRow.label, i);
          })}
      </div>
    );
  };

  seatRender = (seat, label, key) => {
    let className = seat.isReserved
      ? "btn-sm m-1 seat seat-disabled"
      : !seat.isSelected
      ? `btn btn-sm m-1 seat`
      : `btn btn-sm m-1 seat seat-selected`;
    let disabled = seat.isReserved ? true : false;

    let onClick = (e) => {
      seat.isSelected = !seat.isSelected;
      this.props.updateState();
    };
    return (
      <button
        key={`seat-${key}`}
        className={className}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        {label}
        {seat.number}
      </button>
    );
  };

  render() {
    return (
      <div className="m-3">
        {this.props.seatRows &&
          this.props.seatRows.map((seatRow, i) => {
            return this.rowRender(seatRow, i);
          })}
      </div>
    );
  }
}
