import React, { useState, useEffect, Fragment } from "react";
import { ToastContainer } from "react-toastr";
import Api from "../../../../../services/api";
import { useTranslation } from "react-i18next";
import { capitalizeFirstWord } from "utils/str-format";

let container;

export default function ModalCodeComissarios(props) {
  const { t } = useTranslation("tickets");
  const [comissarios, setComissarios] = useState([]);
  const [vinculacoes, setVinculacoes] = useState([]);
  const closeModal = () => {
    setVinculacoes([]);
    setComissarios([]);
    props.callback("modal-hidden");
  };

  useEffect(() => {
    const carregaComissarios = () => {
      Api.get(`list/stewards/event/${props.event_id}`).then((res) => {
        setComissarios(res.data);
        const links = res.data.map((steward) => {
          return {
            comissario_id: steward.id,
            discount_id: steward.discount_id,
          };
        });
        setVinculacoes(links);
      });
    };

    if (props.status === "modal-show") {
      carregaComissarios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  const salvaVinculacao = (comissarioId) => {
    const updatedStewards = vinculacoes.map((steward) => {
      if (steward.comissario_id === Number(comissarioId)) {
        let discountId;

        if (steward.discount_id) {
          discountId =
            Number(props.discount_id) !== Number(steward.discount_id)
              ? props.discount_id
              : null;
        } else {
          discountId = props.discount_id;
        }
        return {
          ...steward,
          discount_id: discountId,
        };
      }
      return steward;
    });
    setVinculacoes(updatedStewards);
  };

  const vincular = () => {
    Api.post("steward/connect", {
      event_id: props.event_id,
      vinculacoes: vinculacoes,
    }).then((res) => {
      container.success(t("promotionalCode.modalCodeComissarios.success"));
      closeModal();
    });
  };

  const vincularTodos = () => {
    let tempV = [];

    comissarios.forEach((comissario) => {
      tempV.push({
        comissario_id: comissario.id,
        discount_id: props.discount_id,
      });
    });

    Api.post("steward/connect", {
      event_id: props.event_id,
      vinculacoes: tempV,
    }).then(() => {
      container.success(t("promotionalCode.modalCodeComissarios.linkSuccess"));
      closeModal();
    });
  };

  return (
    <Fragment>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className={"shadow side-modal " + props.status} role="dialog">
        <div className="card-header">
          <h4 className="card-header-title">
            {t("promotionalCode.modalCodeComissarios.linkDiscount")}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => closeModal(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div
            id="DetalheVenda"
            className="modal-body"
            style={{ height: "350px", overflowY: "auto" }}
          >
            <table className="table">
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <td>{capitalizeFirstWord(t("global:name"))}</td>
                  <td style={{ textAlign: "center" }}>
                    {capitalizeFirstWord(t("global:select"))}
                  </td>
                </tr>
              </thead>
              <tbody>
                {comissarios.map((comissario) => (
                  <tr key={comissario.id}>
                    <td>
                      {comissario.user.first_name} {comissario.user.last_name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name={comissario.user.first_name}
                        value={comissario.id}
                        defaultChecked={
                          comissario.discount_id === props.discount_id
                        }
                        onChange={(e) => {
                          salvaVinculacao(e.target.value);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="box-button">
            <button
              type="button"
              className="btn btn-success mr-2 c3 col-md-3 mb-2"
              onClick={() => vincularTodos()}
            >
              {t("promotionalCode.modalCodeComissarios.linkAll")}
            </button>
            <button
              type="button"
              className="btn btn-danger mr-2 col-md-3 mb-2"
              onClick={() => closeModal()}
            >
              {capitalizeFirstWord(t("global:cancel"))}
            </button>
            <button
              type="button"
              className="btn btn-success c3 col-md-3 mb-2"
              onClick={() => vincular()}
            >
              {t("promotionalCode.modalCodeComissarios.link")}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
