import React from "react";

const ReturnStatus = ({ status, t }) => {
  let style, content;

  switch (status) {
    case "completed":
      style = { color: "#00422A", backgroundColor: "#CEF2E5" };
      content = t("nominalTransfers.status.completed");
      break;
    case "waiting":
      style = { color: "#694C00", backgroundColor: "#FFE1D7" };
      content = t("nominalTransfers.status.waiting");
      break;
    case "canceled":
      style = { color: "#730A46", backgroundColor: "#FFE6E6" };
      content = t("nominalTransfers.status.canceled");
      break;
    case "expired":
      style = { color: "#171719", backgroundColor: "#FFEAB5" };
      content = t("nominalTransfers.status.expired");
      break;
    default:
      style = { color: "#00422A", backgroundColor: "#CEF2E5" };
      content = status;
  }

  return (
    <div className="status-badge">
      <span
        style={{
          ...style,
          padding: "0.25rem",
          borderRadius: "100px",
          fontWeight: "bold",
        }}
      >
        {content}
      </span>
    </div>
  );
};

export default ReturnStatus;
