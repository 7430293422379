import React, { useEffect, useState } from "react";
import Api from "services/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import "./styles.css";
import Register from "../register";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "assets/icons/ArrowLeft.svg";
import Select, { components } from "react-select";
import { CircleFlag } from "react-circle-flags";
import { getStorageLocale, useLanguageSettings } from "hooks/language-settings";
import { ReactComponent as MailIcon } from "assets/icons/MailIcon.svg";
import { ReactComponent as LockIcon } from "assets/icons/LockIcon.svg";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Login = () => {
  //checagem para mudar os forms

  const [login, setLogin] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    confirmPassword: "",
    telephone: "",
    birthdate: "",
  });
  const [esqueceu, setEsqueceu] = useState(false);
  const [register, setRegister] = useState(false);

  const [errorDisplay, setErrorDisplay] = useState({
    user_not_found: "none",
    password_mismatch: "none",
  });
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [chosenLanguage, setChosenLanguage] = useState("pt-BR");

  const { setLanguage } = useLanguageSettings();

  const { SingleValue } = components;

  const { t, i18n } = useTranslation(["authentication", "global"]);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const CustomSingleValue = (props) => {
    const clonedIcon = React.cloneElement(props.data.icon, {
      style: { marginRight: "8px" },
    });
    return (
      <SingleValue {...props}>
        {clonedIcon}
        {props.data.label}
      </SingleValue>
    );
  };

  const updateField = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const authenticateRequest = (email, password) => {
    Api.post("authenticate", { email, password })
      .then((res) => {
        localStorage.setItem("refresh_token", res.data.refreshToken);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("user_id", res.data.user.id);
        localStorage.setItem("user_admin", res.data.user.is_admin);
        localStorage.setItem("email", res.data.user.email);
        const locale = getStorageLocale();
        const userLanguage = res.data.user.chosen_language || chosenLanguage;
        Cookies.set(
          "locale",
          JSON.stringify({
            ...locale,
            backofficeLanguage: userLanguage || locale.backofficeLanguage,
          })
        );

        if (res.data.user.is_admin === 1) {
          window.location.href = "/eventos";
        } else {
          // window.location.href = "/eventos";
          window.location.href = "/eventos";
          if (res.data.user.complete_register) {
            window.location.href = "/eventos";
          } else {
            window.location.href = "/complete/login";
          }
        }
      })
      .catch((err) => {
        if (err.response.data.erro === "E_USER_NOT_FOUND") {
          setErrorDisplay({
            ...errorDisplay,
            user_not_found: "block",
          });
        }

        if (err.response.data.erro === "E_PASSWORD_MISMATCH") {
          setErrorDisplay({
            ...errorDisplay,
            password_mismatch: "block",
          });
        }
      });
  };

  const handleSignUp = (e) => {
    setErrorDisplay({
      user_not_found: "none",
      password_mismatch: "none",
    });
    e.preventDefault();
    authenticateRequest(login.email, login.password);
  };

  // FUNÇÃO FECHAR ALERT
  function CloseElement(value) {
    let alert = document.getElementById("alert-" + value);
    if (alert.classList.contains("showelement")) {
      alert.classList.remove("showelement");
    }
  }

  const solicitaAcesso = (e) => {
    e.preventDefault();
    Api.post("recovery", { email: login.email })
      .then(() => {
        let alert2 = document.getElementById("alert-2");
        alert2.classList.add("showelement");
      })
      .catch(() => {
        let alert1 = document.getElementById("alert-1");
        alert1.classList.add("showelement");
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      });
  };

  const logaounao = (e) => {
    e.preventDefault();
    let esquece = !esqueceu;

    setEsqueceu(esquece);
  };

  const noRegister = (e) => {
    e.preventDefault();
    let nouser = !register;
    setRegister(nouser);
  };

  // FUNÇÃO EXIBE SENHA
  function handleEvent(event) {
    var senha = document.getElementById("password-input");
    if (event === "mousedown") {
      senha.setAttribute("type", "text");
    }
    if (event === "mouseup") {
      senha.setAttribute("type", "password");
    }
  }

  const languageOptions = [
    {
      label: "PT-BR",
      value: "pt-BR",
      icon: <CircleFlag countryCode="br" height="20px" />,
    },
    {
      label: "EN",
      value: "en-US",
      icon: <CircleFlag countryCode="us" height="20px" />,
    },
    {
      label: "ES",
      value: "es-ES",
      icon: <CircleFlag countryCode="es" height="20px" />,
    },
  ];

  const handleSelectLanguage = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
  };

  useEffect(() => {
    setLanguage(selectedLanguage, chosenLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, chosenLanguage]);

  useEffect(() => {
    const emailField = document.getElementById("emailField");

    if (emailField) {
      const setCustomValidityMessage = () => {
        const customMessage = t("login.emailValidation", {
          email: emailField.value,
        });
        emailField.setCustomValidity(customMessage);
      };

      emailField.oninput = () => {
        emailField.setCustomValidity("");
      };

      emailField.oninvalid = setCustomValidityMessage;

      return () => {
        emailField.oninput = null;
        emailField.oninvalid = null;
      };
    }
  }, [t]);

  return (
    <div className="container-fluid" id="page-login">
      <div className="row">
        <div
          id="login-bg"
          className="bg-cover col-12 col-md-12 col-lg-6 col-xl-6"
        />
        <div
          id="login-content"
          className="row col-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div id="login-container">
            {!register && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "8rem",
                  alignItems: "center",
                }}
              >
                <Select
                  options={languageOptions}
                  value={languageOptions.filter(
                    (option) => option.value === selectedLanguage
                  )}
                  onChange={handleSelectLanguage}
                  components={{
                    IndicatorSeparator: () => null,
                    SingleValue: CustomSingleValue,
                  }}
                  isSearchable={false}
                />
              </div>
            )}
            <div className="content-header">
              {register && (
                <Link
                  to="/"
                  id="password-recover"
                  className="nav-link"
                  style={{ marginRight: "auto", width: "9rem" }}
                >
                  <ArrowLeft />
                </Link>
              )}
              <img
                style={{ width: register ? "30%" : "60%" }}
                className="page-login-img text-center"
                src="../../logo_login.svg"
                alt="Logo"
              />
              {register && (
                <div style={{ marginLeft: "auto", width: "8rem" }}>
                  <Select
                    options={languageOptions}
                    value={languageOptions.filter(
                      (option) => option.value === selectedLanguage
                    )}
                    onChange={handleSelectLanguage}
                    components={{
                      IndicatorSeparator: () => null,
                      SingleValue: CustomSingleValue,
                    }}
                    isSearchable={false}
                  />
                </div>
              )}
            </div>
            {esqueceu ? (
              <form
                id="form-login"
                className="col-12"
                onSubmit={solicitaAcesso}
              >
                <h1 className="display-4 text-center mb-3">
                  {t("login.forgot.title")}
                </h1>
                <p className="text-muted text-center mb-3">
                  {t("login.forgot.text")}
                </p>
                <div
                  id="alert-1"
                  className="alert alert-danger alert-dismissible container-fluid"
                  style={{ display: errorDisplay.user_not_found }}
                >
                  <span>{t("login.forgot.alert.notFound")}</span>
                  <div onClick={() => CloseElement(1)} className="close">
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div
                  id="alert-2"
                  className="alert alert-success alert-dismissible container-fluid"
                >
                  <span>{t("login.forgot.alert.emailSent")}</span>
                  <div onClick={() => CloseElement(2)} className="close">
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <input
                  type="email"
                  placeholder={t("login.placeholder.email")}
                  className="form-control"
                  name="email"
                  value={login.email}
                  onChange={updateField}
                />

                <button
                  type="submit"
                  className="btn btn-lg btn-block btn-primary mb-3"
                >
                  {t("login.forgot.button")}
                </button>
                <p id="password-recover">
                  <span className="nav-link" onClick={(e) => logaounao(e)}>
                    {t("login.forgot.navLink")}
                  </span>
                </p>
              </form>
            ) : !register ? (
              <form id="form-login" className="col-12" onSubmit={handleSignUp}>
                <h1 className="display-4 text-center mb-3 register-title">
                  {t("login.title")}
                </h1>
                <p className="text-muted text-center mb-3 register-text">
                  {t("login.text")}
                </p>
                <div
                  id="alert-0"
                  className="alert alert-danger alert-dismissible container-fluid"
                  style={{ display: errorDisplay.user_not_found }}
                >
                  <span>{t("login.notFound")}</span>
                  <div
                    onClick={() =>
                      setErrorDisplay({
                        ...errorDisplay,
                        user_not_found: "none",
                      })
                    }
                    className="close"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div
                  id="alert-0"
                  className="alert alert-danger alert-dismissible container-fluid"
                  style={{ display: errorDisplay.password_mismatch }}
                >
                  <span>{t("login.notFound")}</span>
                  <div
                    onClick={() =>
                      setErrorDisplay({
                        ...errorDisplay,
                        password_mismatch: "none",
                      })
                    }
                    className="close"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                <div className="input-icon-container">
                  <MailIcon className="input-icon" stroke="#12263f" />
                  <input
                    type="email"
                    id="emailField"
                    placeholder={t("login.placeholder.email")}
                    className="form-control form-control-appended"
                    name="email"
                    value={login.email}
                    onChange={updateField}
                  />
                </div>
                <div className="input-group input-group-merge password-field mb-3 input-icon-container">
                  <LockIcon className="input-icon" stroke="#12263f" />
                  <input
                    type="password"
                    placeholder={t("login.placeholder.password")}
                    id="password-input"
                    className="form-control form-control-appended"
                    name="password"
                    value={login.password}
                    onChange={updateField}
                  />
                  <div
                    className="input-group-append"
                    id="eye-icon"
                    onMouseDown={() => handleEvent("mousedown")}
                    onMouseUp={() => handleEvent("mouseup")}
                  >
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                  </div>
                  <p id="password-recover">
                    <span className="nav-link" onClick={(e) => logaounao(e)}>
                      {t("login.forgottenPassword")}
                    </span>
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn btn-lg btn-block btn-primary mb-3"
                >
                  {t("login.button")}
                </button>
                <hr />
                <span id="account-create">
                  <p className="text-muted text-center mb-3">
                    {t("login.noAccount")}
                  </p>
                  <span className="nav-link" onClick={(e) => noRegister(e)}>
                    {t("login.navLink")}
                  </span>
                </span>
              </form>
            ) : (
              ""
            )}
            {register ? (
              <Register
                callBack={authenticateRequest}
                chosenLanguage={chosenLanguage}
                setChosenLanguage={setChosenLanguage}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
