import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Card } from "react-bootstrap";
import GAnalytics from "../../assets/icons/logos_google-analytics.svg";
import GAds from "../../assets/icons/logos_google-ads.svg";
import Meta from "../../assets/icons/logos_meta-icon.svg";
import Search from "../../assets/icons/blue-search.svg";
import CustomStar from "../../assets/icons/Star.svg";
import { ReactComponent as EditPencil } from "../../assets/icons/editPencilNew.svg";
import "./styles.css";
import Badge from "components/badge";
import ToggleSwitch from "components/toggleSwitch";
import { useTranslation } from "react-i18next";

IntegrationCard.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  checked: PropTypes.bool,
  expired: PropTypes.bool,
  onSwitchToggle: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default function IntegrationCard({
  type,
  name,
  text,
  expired,
  checked,
  onSwitchToggle,
  onEditClick,
}) {
  const { t } = useTranslation("integrations");

  const integrationInfo = {
    analytics: {
      icon: GAnalytics,
      label: t("cards.analytics.title"),
      text: t("cards.analytics.description"),
    },
    ads: {
      icon: GAds,
      label: t("cards.ads.title"),
      text: t("cards.ads.description"),
    },
    meta: {
      icon: Meta,
      label: t("cards.meta.title"),
      text: t("cards.meta.description"),
    },
    publicApi: {
      icon: Search,
      label: t("cards.publicApi.title"),
      text: t("cards.publicApi.description"),
    },
    custom: { icon: CustomStar },
  };

  const badgeInfo = {
    analytics: { status: "marketing", text: t("badges.marketing") },
    ads: { status: "marketing", text: t("badges.marketing") },
    meta: { status: "marketing", text: t("badges.marketing") },
    custom: { status: "custom", text: t("badges.custom") },
    publicApi: { status: "custom", text: t("badges.general") },
  };

  return (
    <Fragment>
      <Card
        className={`integrations-card ${
          !!checked && !expired
            ? "active"
            : !!expired && type === "publicApi" && "expired"
        }`}
      >
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Title>
            <div className="d-flex align-items-center justify-content-between">
              <div className="icon-card d-flex justify-content-center align-items-center">
                <img
                  src={integrationInfo[type].icon}
                  alt="icon"
                  width={18}
                  height={18}
                />
              </div>
              <div className="col-auto d-flex justify-content-end align-items-center pr-0">
                <Badge
                  className="card-badge"
                  status={badgeInfo[type].status}
                  text={badgeInfo[type].text}
                />
              </div>
            </div>
          </Card.Title>
          <div>
            <Card.Title className="label-text">
              {!integrationInfo[type].label
                ? name
                : integrationInfo[type].label}
            </Card.Title>

            <Card.Text className="description-text">
              {!integrationInfo[type].text ? text : integrationInfo[type].text}
            </Card.Text>
          </div>

          <div className="row align-items-end">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <div className="row pl-2 align-items-center flex-nowrap">
                  <ToggleSwitch
                    className="mr-2"
                    onChange={(e) => onSwitchToggle(e)}
                    checked={checked}
                  />
                  {type !== "custom" && !checked && (
                    <span className="integration-switch-label">
                      {t("common.integrate")}
                    </span>
                  )}
                </div>
                {!!checked && (
                  <Fragment>
                    <div className="d-flex justify-content-end">
                      <Button
                        id="edit-integration-button"
                        onClick={onEditClick}
                        style={{
                          background: "transparent",
                          color: "#525059",
                          border: "none",
                          marginRight: "0px",
                          padding: "0px 0px 0px 12px",
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-end">
                          {t("common.edit")} <EditPencil className="ml-1" />
                        </div>
                      </Button>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
}
