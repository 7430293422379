import Loading from "components/loading";
import PageHeader from "components/page-header";
import useQuery from "customerHooks/useQuery";
import React, { Fragment, useEffect, useState } from "react";
import New_api from "services/new-api";
import "./styles.css";

export default function AudienceAndVisits() {
  const query = useQuery();
  const eventId = query.get("event_id");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const companyLink = process.env.REACT_APP_LINK_EMPRESA;
  const isWhitelabel = Number(process.env.REACT_APP_IS_WHITELABEL);

  useEffect(() => {
    setLoading(true);

    New_api.get(`/events/${eventId}`, { params: { fields: ["slug"] } }).then(
      ({ data: { slug } }) => {
        const params = {
          resource: { dashboard: 387 },
          params: { slug: `https://${companyLink}/eventos/${slug}` },
        };

        New_api.post(`/embeded/generate-url`, params).then((res) => {
          setLoading(false);
          setUrl(res.data);
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!isWhitelabel && (
        <Fragment>
          <PageHeader
            title="Visitas e Audiência"
            text="Visualize e acompanhe os dados de acesso e audiência da página do evento."
          />
          {loading ? (
            <Loading title="Aguarde..." />
          ) : (
            <Fragment>
              <div className="container-fluid mt-3">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <div className="w-100">
                        {eventId && (
                          <iframe
                            title="GA4"
                            id="ga4"
                            src={url}
                            style={{
                              border: 0,
                              width: "100%",
                              height: "100vh",
                            }}
                            allowFullScreen
                            allowTransparency
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <div className="fixed-bottom">
            <div className="remove-metabase-bottom"></div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
