export class UtilsHelper {
  static groupBy = (data, key) => {
    return data.reduce(function (acc, item) {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  };

  static groupByFn = (data, keyFunction) => {
    return data.reduce(function (acc, item) {
      const key = keyFunction(item);

      (acc[item[key]] = acc[item[key]] || []).push(item);

      return acc;
    }, {});
  };

  static typeChannelPlaceholderSelect = (typeChannel) => {
    switch (typeChannel) {
      case "telephone":
        return "(11) 11111-1111";
      case "email":
        return "@example.com";
      case "social_media":
        return "https://www.exemplo.com";
      default:
        return "Selecione o tipo de canal";
    }
  };
}
