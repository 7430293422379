import React from "react";
import styled from "styled-components";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { breakPoints } from "services/breakPoints";

const LinkWhatsapp = process.env.REACT_APP_WHATSAPP;
const user = JSON.parse(localStorage.getItem("user"));

export default function SupportWhatsapp() {
  return (
    <PositionIcon>
      {!user.is_admin && LinkWhatsapp && (
        <LinkEnv
          className="c1 buttonWhats"
          onClick={() => window.open(LinkWhatsapp)}
        >
          <FontAwesomeIcon
            icon={faWhatsapp}
            style={{
              top: "900px",
              left: "1723px",
              width: "29px",
              height: "42px",
              opacity: 1,
              color: "#fff",
            }}
          />
        </LinkEnv>
      )}
    </PositionIcon>
  );
}

const PositionIcon = styled.div`
  display: none;
  @media ${breakPoints.desktop720p} {
    display: flex;
  }
`;

const LinkEnv = styled.button`
  &:hover {
    position: fixed;
    width: 170px;
    font-size: 13px;
    color: #5d6494;
    line-height: 20px;
    transition: all 0.1s ease;
  }
  z-index: 100000;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: fixed;
  bottom: 10px;
  right: 57px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  align-items: center;
  border: transparent;
`;
