import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownContent, Item } from "./styles";
import { useOnClickOutside } from "App";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export const DropdownBtn = ({ options }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));

  const handleOption = () => {
    setOpen(false);
  };

  return (
    <Dropdown>
      <button
        type="button"
        className="dotsMobile"
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {open && (
        <DropdownContent isActive={open} ref={ref}>
          {options.map((option, index) => (
            <Item
              key={index}
              onClick={() => {
                option.action();
                handleOption();
              }}
            >
              {option?.leftIcon && option.leftIcon}
              <button key={index} type="button">
                {option.label}
              </button>
            </Item>
          ))}
        </DropdownContent>
      )}
    </Dropdown>
  );
};
