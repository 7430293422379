import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormField from "components/FormField/index.js";
import PhoneNumber from "components/input-phone-number/index.js";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import { ReactComponent as Check } from "../../../assets/icons/checkCircle.svg";
import { ReactComponent as Refuse } from "../../../assets/icons/DeleteCircle.svg";
import { format } from "date-fns";
import { ToastContainer } from "react-toastr";
import New_api from "services/new-api";
import { CircleFlag } from "react-circle-flags";

import "./styles.css";
import MaskedInput from "react-text-mask";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { ReactComponent as MailIcon } from "../../../assets/icons/MailIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/CalendarIcon.svg";
import ButtonContained from "components/buttonContained";
import { DatePicker } from "components/datepicker";

const CURRENT_DATE = new Date();

const YEARS = 150;

const MAX_LENGTH_CHARACTERS = 200;

const MIN_LENGTH_CHARACTERS = 1;

const MIN_LENGTH_CHARACTERS_PASSWORD = 6;

const ARRAY_MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

const NO_SPECIAL_NUMBERS_REGEX = /^[A-zÀ-ú-Z0-9\-. ]+$/;

const STRONG_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).*$/;

export const hasConsecutiveDigits = (password) => {
  const splitedPassword = password.split("");
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  let sequencialNumbers = "";

  for (let index = 0; index < splitedPassword.length - 1; index++) {
    const char = splitedPassword[index];
    const nextChar = splitedPassword[index + 1];

    if (!isNaN(Number(char))) {
      const currentValue = numbers[Number(char)];
      const nextValue = numbers[Number(nextChar)];

      if (
        currentValue !== undefined &&
        (nextValue === currentValue + 1 || nextValue === currentValue - 1)
      ) {
        sequencialNumbers += char;

        if (index === splitedPassword.length - 2) {
          sequencialNumbers += nextChar;
          if (sequencialNumbers.length > 2) {
            return true;
          }
        }
      } else {
        if (sequencialNumbers.length > 2) {
          return true;
        }
        sequencialNumbers = "";
      }
    }
  }

  return false;
};

const createYupSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t("register.validationErrors.email.invalid"))
      .required(t("register.validationErrors.email.required")),
    birthdate: yup
      .string()
      .trim()
      .required(t("register.validationErrors.birthdate")),
    telephone: yup
      .string()
      .trim()
      .required(t("register.validationErrors.phone.required"))
      .test(
        "isValidTelephone",
        t("register.validationErrors.phone.invalid"),
        (val = "") => (!val || !isValidPhoneNumber(val) ? false : true)
      ),
    firstName: yup
      .string()
      .trim()
      .max(
        MAX_LENGTH_CHARACTERS,
        t("register.validationErrors.firstName.maxLength")
      )
      .min(
        MIN_LENGTH_CHARACTERS,
        t("register.validationErrors.firstName.minLength")
      )
      .required(t("register.validationErrors.firstName.required"))
      .matches(
        NO_SPECIAL_NUMBERS_REGEX,
        t("register.validationErrors.firstName.pattern")
      ),
    lastName: yup
      .string()
      .trim()
      .required(t("register.validationErrors.lastName.required"))
      .matches(
        NO_SPECIAL_NUMBERS_REGEX,
        t("register.validationErrors.lastName.pattern")
      ),
    password: yup
      .string()
      .required(t("register.validationErrors.password.required"))
      .min(
        MIN_LENGTH_CHARACTERS_PASSWORD,
        t("register.validationErrors.password.minLength")
      )
      .matches(STRONG_PASSWORD, t("register.validationErrors.password.matches"))
      .test(
        "NoSequentialNumber",
        t("register.validationErrors.password.pattern"),
        (val) => (!val || hasConsecutiveDigits(val) ? false : true)
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        t("register.validationErrors.confirmPassword.matches")
      )
      .required(t("register.validationErrors.confirmPassword.required")),
  });
};

let container;

const Register = ({ callBack, chosenLanguage, setChosenLanguage }) => {

  const [selectedDate, setSelectedDate] = useState(null);
  const [typedDate, setTypedDate] = useState("");

  const { t, i18n } = useTranslation(["authentication", "global"]);

  const initializeRequirements = useCallback(
    () => [
      {
        isValid: false,
        message: t("register.requirements.minLength"),
        name: "password_length",
      },
      {
        isValid: false,
        message: t("register.requirements.upperCase"),
        name: "uppercase",
      },
      {
        isValid: false,
        message: t("register.requirements.lowerCase"),
        name: "lowcase",
      },
      {
        isValid: false,
        message: t("register.requirements.number"),
        name: "number",
      },
      {
        isValid: false,
        message: t("register.requirements.special"),
        name: "special_character",
      },
      {
        isValid: false,
        message: t("register.requirements.sequence"),
        name: "no_sequencial_numbers",
      },
    ],
    [t]
  );

  const [requirements, setRequirements] = useState(initializeRequirements());
  const [,setDateFormat] = useState("dd/MM/yyyy");

  const [schema, setSchema] = useState(() => createYupSchema(t));

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    watch,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { SingleValue } = components;

  const CustomSingleValue = (props) => {
    const clonedIcon = React.cloneElement(props.data.icon, {
      style: { marginRight: "8px" },
    });
    return (
      <SingleValue {...props}>
        {clonedIcon}
        {props.data.label}
      </SingleValue>
    );
  };

  const handlePhoneNumberValue = (value) => {
    setValue("telephone", value, { shouldValidate: true });
  };

  const handleOnblurPhoneInput = (value) => {
    if (!value) {
      setError("telephone", {
        type: "required",
        message: t("register.validationErrors.phone.required"),
      });
    }
  };

  const handleBirthDateValue = (date) => {
    const formatToDate = date ? new Date(date) : null;
    setSelectedDate(formatToDate);
    setValue("birthdate", date ? format(formatToDate, "dd/MM/yyyy") : "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    if (!date) {
      setTypedDate("");
    }
  };

  const handleTypedDate = (event) => {
    setTypedDate(event.target.value);
  };

  const passwordVerification = (password) => {
    const rules = {
      password_length: password.length >= MIN_LENGTH_CHARACTERS_PASSWORD,
      uppercase: /[A-Z]/.test(password),
      lowcase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special_character: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      no_sequencial_numbers: !hasConsecutiveDigits(password),
    };

    if (password) {
      setRequirements((prevRequirements) =>
        prevRequirements.map((req) => ({ ...req, isValid: rules[req.name] }))
      );
    } else {
      setRequirements((prevRequirements) =>
        prevRequirements.map((req) => ({ ...req, isValid: false }))
      );
    }
  };

  const onSubmit = (form) => {
    const [day, month, year] = form.birthdate.split("/");
    const dateObject = new Date(year, month - 1, day);
    const body = {
      ...form,
      birthdate: format(dateObject, "yyyy-MM-dd"),
    };

    New_api.post("users", body)
      .then(() => {
        callBack(body.email, body.password);
      })
      .catch((err) => {
        const errorToDisplay = Object.keys(err.response.data.errors);
        const errorKey = errorToDisplay[0];
        container.error(
          t(`global:${errorKey}`).toUpperCase() +
            ": " +
            t(`global:${err.response.data.errors[errorKey][0]}`)
        );
      });
  };

  const languageOptions = [
    {
      label: "Português (BR)",
      value: "pt-BR",
      icon: <CircleFlag countryCode="br" height="20px" />,
    },
    {
      label: "Inglês (US)",
      value: "en-US",
      icon: <CircleFlag countryCode="us" height="20px" />,
    },
    {
      label: "Espanõl (ES)",
      value: "es-ES",
      icon: <CircleFlag countryCode="es" height="20px" />,
    },
  ];

  useEffect(() => {
    i18n.language === "en-US"
      ? setDateFormat("MM/dd/yyyy")
      : setDateFormat("dd/MM/yyyy");
  }, [i18n.language]);

  const handleChangeLanguage = (selectedOption) => {
    setChosenLanguage(selectedOption.value);
  };

  useEffect(() => {
    setValue("chosenLanguage", chosenLanguage);
  }, [setValue, chosenLanguage]);

  useEffect(() => {
    const handleLanguageChange = async () => {
      const formValues = watch();
      setRequirements(initializeRequirements());
      const newSchema = createYupSchema(t);
      setSchema(newSchema);
      reset();
      await new Promise((resolve) => setTimeout(resolve, 1));
      Object.entries(formValues).forEach(([key, value]) => {
        setValue(key, value);
        if (value !== undefined && value !== "") trigger(key);
      });
    };
    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n, t, initializeRequirements, reset, setValue, trigger, watch]);

  return (
    <div id="form-register" className="col-12 register-container">
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <h1 className="display-4 text-center mb-3 register-title">
        {t("register.title")}
      </h1>
      <p className="text-muted text-center mb-3 register-text">
        {t("register.text")}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <FormField
              classNameGroup="mb-0"
              errorMessage={errors.firstName?.message}
              controlId="validationFirstName"
              label={t("register.input.label.firstName")}
            >
              <Form.Control
                type="text"
                placeholder={t("register.input.placeholder.firstName")}
                maxLength={MAX_LENGTH_CHARACTERS}
                minLength={MIN_LENGTH_CHARACTERS}
                {...register("firstName")}
                isInvalid={errors.firstName}
              />
            </FormField>
          </Col>
          <Col md={6}>
            <FormField
              classNameGroup="mb-0"
              errorMessage={errors.lastName?.message}
              controlId="validationLastName"
              label={t("register.input.label.lastName")}
            >
              <Form.Control
                type="text"
                placeholder={t("register.input.placeholder.lastName")}
                {...register("lastName")}
                isInvalid={errors.lastName}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              classNameGroup="mb-0"
              errorMessage={errors.email?.message}
              controlId="validationEmail"
              label={t("register.input.label.email")}
            >
              <div className="form-icon-group">
                <MailIcon className="form-icon" stroke="#B7B6BF" />
                <Form.Control
                  name="email"
                  {...register("email")}
                  type="email"
                  placeholder={t("register.input.placeholder.email")}
                  isInvalid={errors.email?.message}
                  className="form-control-icon"
                />
              </div>
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormField
              classNameGroup="mb-0"
              controlId="validationBirthdate"
              label={t("register.input.label.birthday")}
            >
              <div className="form-icon-group">
                <DatePicker
                  leftIcon
                  leftIconChild={
                    <CalendarIcon className="form-icon" stroke="#B7B6BF" />
                  }
                  customInput={
                    <MaskedInput
                      {...register("birthdate")}
                      className="form-control form-control-icon"
                      type="text"
                      mask={ARRAY_MASK}
                      placeholder="Insira a data"
                    />
                  }
                  value={selectedDate}
                  onChange={handleBirthDateValue}
                  onBlur={() => handleBirthDateValue(selectedDate)}
                  onChangeRaw={handleTypedDate}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={YEARS}
                  placeholderText={t("register.input.placeholder.birthday")}
                />
              </div>
              {!!errors?.birthdate?.message && (
                <h6 className="mt-1 text-left">
                  <small className="text-danger">
                    {errors.birthdate.message}
                  </small>
                </h6>
              )}
              {new Date(typedDate) > CURRENT_DATE && (
                <h6 className="mt-1 text-left">
                  <small className="text-danger"></small>
                </h6>
              )}
            </FormField>
          </Col>
          <Col md={6}>
            <FormField
              classNameGroup="mb-0"
              errorMessage={errors.telephone?.message}
              controlId="validationTelephone"
              label={t("register.input.label.phone")}
            >
              <PhoneNumber
                className={errors.telephone?.message ? "phone-input-error" : ""}
                type="telephone"
                defaultCountry="BR"
                onChange={handlePhoneNumberValue}
                onBlur={(event) => handleOnblurPhoneInput(event.target.value)}
                placeholder={t("register.input.placeholder.phone")}
              />
              {!!errors?.telephone?.message && (
                <h6 className="mt-1 text-left">
                  <small className="text-danger">
                    {errors.telephone.message}
                  </small>
                </h6>
              )}
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              classNameGroup="mb-0"
              errorMessage={errors.email?.message}
              controlId="chosenLanguage"
              label={t("register.input.label.language")}
            >
              <Controller
                name="chosenLanguage"
                control={control}
                render={() => (
                  <Select
                    options={languageOptions}
                    value={languageOptions.filter(
                      (option) => option.value === chosenLanguage
                    )}
                    onChange={(e) => handleChangeLanguage(e)}
                    components={{
                      IndicatorSeparator: () => null,
                      SingleValue: CustomSingleValue,
                    }}
                    isSearchable={false}
                  />
                )}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormField
              classNameGroup="mb-0"
              errorMessage={errors.password?.message}
              controlId="validationPassword"
              label={t("register.input.label.password")}
            >
              <Form.Control
                type="password"
                placeholder={t("register.input.placeholder.password")}
                {...register("password", {
                  onChange: (event) => passwordVerification(event.target.value),
                })}
                isInvalid={errors.password}
              />
            </FormField>
          </Col>
          <Col md={6}>
            <FormField
              classNameGroup="mb-2"
              errorMessage={errors.confirmPassword?.message}
              controlId="validationConfirmPassword"
              label={t("register.input.label.confirmPassword")}
            >
              <Form.Control
                type="password"
                placeholder={t("register.input.placeholder.confirmPassword")}
                {...register("confirmPassword")}
                isInvalid={errors.confirmPassword}
              />
            </FormField>
          </Col>
        </Row>
        <Row className="p-2">
          <Col
            className="required-password-container pt-2 pb-2"
            style={{ border: "1px solid #d2ddec" }}
          >
            <span className="required-password-title pb-2"></span>

            {requirements.map((requirement, index) => (
              <span
                key={index}
                className="d-flex align-items-center required-password-content"
              >
                {requirement.isValid ? <Check /> : <Refuse />}{" "}
                {requirement.message}
              </span>
            ))}
          </Col>
        </Row>
        <div className="row mt-3 ">
          <div className="col-12">
            <ButtonContained
              type="submit"
              disabled={!isValid}
              className={`mb-3 ${!isValid ? "disabled-contained-grey " : ""}`}
              content={t("register.button.register")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
