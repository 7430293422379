import React, { useEffect, useState, useRef, Fragment } from "react";
import Api from "services/api";
import Swal from "sweetalert2";
import { useOnClickOutside } from "App";
import { useTranslation } from "react-i18next";
import { DatePicker } from "components/datepicker";
import moment from "moment";

const ModalDestaq = (props) => {
  let event_id = props.event_id;
  const { t } = useTranslation("events");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [position, setPosition] = useState("");
  const [status, setStatus] = useState("");
  const [ids, setIds] = useState("");
  const [edicao, setEdicao] = useState(false);

  let ref = useRef();

  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      closeModal();
    }
  });

  const enviaDestaque = (e) => {
    let data = { start, end, position, event_id };
    e.preventDefault();
    if (start === "" || end === "" || position === "") {
      Swal.fire({
        title: t(
          "eventList.eventHighlightModal.alerts.incompleteRegister.title"
        ),
        icon: "warning",
        text: t(
          "eventList.eventHighlightModal.alerts.incompleteRegister.subtitle"
        ),
      });
    } else {
      Api.post("event/destaque", data)
        .then(() => {
          Swal.fire({
            title: t("global:success"),
            icon: "success",
            text: t(
              "events:eventList.eventHighlightModal.alerts.highlightSuccess"
            ),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.pathname = "/eventos";
          });
        })
        .catch(() => {
          Swal.fire({
            title: t("global:error"),
            icon: "Error",
            text: t(
              "events:eventList.eventHighlightModal.alerts.highlightError"
            ),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  const autalizaDestaque = (e) => {
    e.preventDefault();
    let data = { ids, start, end, position, event_id, status }; //
    if (start === "" || end === "" || position === "" || status === "") {
      Swal.fire({
        title: t(
          "eventList.eventHighlightModal.alerts.incompleteRegister.title"
        ),
        icon: "warning",
        text: t(
          "eventList.eventHighlightModal.alerts.incompleteRegister.subtitle"
        ),
      });
    } else {
      Api.post("event/destaque/edit", data)
        .then(() => {
          Swal.fire({
            title: t("global:success"),
            icon: "success",
            text: t(
              "events:eventList.eventHighlightModal.alerts.highlightSuccess"
            ),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.pathname = "/eventos";
          });
        })
        .catch(() => {
          Swal.fire({
            title: t("global:error"),
            icon: "Error",
            text: t(
              "events:eventList.eventHighlightModal.alerts.highlightError"
            ),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  useEffect(() => {
    if (props.event_id) {
      Api.get(`destaque/evento/${props.event_id}`)
        .then((res) => {
          const {
            start: startDate,
            end: endDate,
            position,
            status,
            ids,
          } = res.data;
          if (startDate && endDate && position && status && ids) {
            const start = moment(startDate, "YYYY-MM-DD").toDate();
            const end = moment(endDate, "YYYY-MM-DD").toDate();

            setEdicao(true);
            setStart(start);
            setEnd(end);
            setPosition(position);
            setStatus(status);
            setIds(ids);
          }
        })
        .catch(() => {
          Swal.fire({
            title: t("global:error"),
            icon: "Error",
            text: t(
              "eventList.eventHighlightModal.alerts.pullInformationsError"
            ),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          });
        });
    }
    // eslint-disable-next-line
  }, [props.event_id]);

  const closeModal = () => {
    props.callback("modal-hidden");
  };

  return (
    <Fragment>
      <div
        className={"shadow side-modal " + props.status}
        ref={ref}
        role="dialog"
      >
        <div className="card-header">
          <h4 className="card-header-title">
            {t("events:eventList.eventHighlightModal.title")}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => closeModal(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-content">
          <div className="row">
            <div className="col-md-6">
              <DatePicker
                label={t(
                  "events:eventList.eventHighlightModal.inputs.initialDate.label"
                )}
                name="start"
                value={start}
                onChange={(date) => setStart(date)}
                required
                placeholder={t(
                  "eventList.eventHighlightModal.inputs.initialDate.placeholder"
                )}
                showTime
                showTimeSelect
                leftIcon
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                marginLeft={[0, "-10rem", 0]}
                leftIcon
                label={t(
                  "events:eventList.eventHighlightModal.inputs.endDate.label"
                )}
                name="end"
                value={end}
                onChange={(date) => setEnd(date)}
                required
                placeholder={t(
                  "eventList.eventHighlightModal.inputs.endDate.placeholder"
                )}
                showTime
                showTimeSelect
              />
            </div>
          </div>

          <div className="row">
            {edicao ? (
              <Fragment>
                <div className="col-md-6">
                  <label htmlFor="locais">
                    {t(
                      "eventList.eventHighlightModal.inputs.positioning.label"
                    )}
                  </label>
                  <select
                    className="form-control"
                    name="locais"
                    required
                    value={position}
                    onChange={(event) => setPosition(event.target.value)}
                  >
                    <option value="">{t("global:select")}</option>
                    <option value="0">
                      {t(
                        "events:eventList.eventHighlightModal.inputs.positioning.options.bannerOnly"
                      )}
                    </option>
                    <option value="1">
                      {t(
                        "events:eventList.eventHighlightModal.inputs.positioning.options.middleOfTheSiteOnly"
                      )}
                    </option>
                    <option value="2">
                      {t(
                        "events:eventList.eventHighlightModal.inputs.positioning.options.middleOfTheSiteAndBanner"
                      )}
                    </option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="locais">{t("global:Status")}</label>
                  <select
                    className="form-control"
                    name="locais"
                    required
                    value={status}
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    <option value="">{t("global:select")}</option>
                    <option value="ACTIVE">
                      {t("global:statusList.active")}
                    </option>
                    <option value="DESACTiVE">
                      {t("global:statusList.disabled")}
                    </option>
                  </select>
                </div>
              </Fragment>
            ) : (
              <div className="col-md-12">
                <label htmlFor="locais">
                  {t(
                    "events:eventList.eventHighlightModal.inputs.positioning.label"
                  )}
                </label>
                <select
                  className="form-control"
                  name="locais"
                  required
                  value={position}
                  onChange={(event) => setPosition(event.target.value)}
                >
                  <option value="">{t("global:select")}</option>
                  <option value="0">
                    {t(
                      "eventList.eventHighlightModal.inputs.positioning.options.bannerOnly"
                    )}
                  </option>
                  <option value="1">
                    {t(
                      "eventList.eventHighlightModal.inputs.positioning.options.middleOfTheSiteOnly"
                    )}
                  </option>
                  <option value="2">
                    {t(
                      "eventList.eventHighlightModal.inputs.positioning.options.middleOfTheSiteAndBanner"
                    )}
                  </option>
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="mt-4">
          <div className="form-group offset-8 col-4 mt-4">
            {edicao ? (
              <button onClick={autalizaDestaque} className="btn btn-primary">
                {t("global:Edit")}
              </button>
            ) : (
              <button
                onClick={enviaDestaque}
                className="btn btn-primary"
                style={{ background: "#600EA3" }}
              >
                {t("global:Save")}
              </button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModalDestaq;
