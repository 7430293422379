import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import api from "services/api";
import Swal from "sweetalert2";

export default function ProdutoFiscal(props) {
  const [status, setStatus] = useState("left-modal-hidden");
  const [data, setData] = useState({
    origin: "",
    taxation: "",
    type: "",
    ncm: "",
    cfop: "",
    cest: "",
    ean: "",
    csticms: "",
    icms: "",
    cstpis: "",
    pis: "",
    cstconfins: "",
    confins: "",
  });

  // Combo box
  const [origem, setOrigem] = useState([]);
  const [taxation, setTaxation] = useState([]);
  const [type, setType] = useState([]);
  const [cstconfins, setCstconfins] = useState([]);
  const [csticm, setCsticm] = useState([]);
  const [cstpis, setCstpis] = useState([]);
  const [ncms, setNcms] = useState([]);
  const [cfop, setCfop] = useState([]);

  useEffect(() => {
    if (data.ncm !== "") {
    }
  }, [data.ncm]);

  useEffect(() => {
    setData({
      origin: "",
      taxation: "",
      type: "",
      ncm: "",
      cfop: "",
      cest: "",
      ean: "",
      csticms: "",
      icms: "",
      cstpis: "",
      pis: "",
      cstconfins: "",
      confins: "",
    });

    if (props.status === "left-modal-show") {
      api
        .get("fiscal/origin/list")
        .then((res) => {
          setOrigem(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .get("fiscal/taxation/list")
        .then((res) => {
          setTaxation(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .get("fiscal/type/list")
        .then((res) => {
          setType(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .get("fiscal/cstconfins/list")
        .then((res) => {
          setCstconfins(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .get("fiscal/csticm/list")
        .then((res) => {
          setCsticm(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      api
        .get("fiscal/cstpis/list")
        .then((res) => {
          setCstpis(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }

    if (props.product_id) {
      api
        .get(`fiscal/menuproduct/${props.product_id}`)
        .then((res) => {
          let query = {
            page: 1,
            name: res.data.cfop,
          };

          api
            .post("fiscal/cfop/list", query)
            .then((res) => {
              let aux = [];
              res.data.data.forEach((item) => {
                aux.push({ name: item.name, value: String(item.id) });
              });
              setCfop(aux);
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: "Ocorreu um erro interno, por favor tente novamente",
                confirmButtonText: "Ok",
              });
            });

          query = {
            page: 1,
            description: res.data.ncm_description,
          };

          api
            .post(`fiscal/ncm/list`, query)
            .then((res) => {
              let aux = [];
              res.data.data.forEach((item) => {
                aux.push({ name: item.description, value: String(item.id) });
              });
              setNcms(aux);
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: "Ocorreu um erro interno, por favor tente novamente",
                confirmButtonText: "Ok",
              });
            });

          setData({
            ...data,
            origin: res.data.product_origin_id,
            taxation: res.data.product_taxation_id,
            type: res.data.product_type_id,
            ncm: String(res.data.ncm_id),
            cfop: String(res.data.cfop_id),
            cest: res.data.cest,
            ean: res.data.ean,
            csticms: res.data.cst_icms_id,
            icms: res.data.icms,
            cstpis: res.data.cst_pis_id,
            pis: res.data.pis,
            cstconfins: res.data.cst_cofins_id,
            confins: res.data.cofins,
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }

    setStatus(props.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  const fechaModal = () => {
    setStatus("left-modal-hidden");

    setData({
      origin: "",
      taxation: "",
      type: "",
      ncm: "",
      cfop: "",
      cest: "",
      ean: "",
      csticms: "",
      icms: "",
      cstpis: "",
      pis: "",
      cstconfins: "",
      confins: "",
    });

    props.retorno("left-modal-hidden");
  };

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  function salvar() {
    setStatus("left-modal-hidden");
    props.retorno("left-modal-hidden");
    props.valores(data);
  }

  return (
    <div id="alert-messages" className={"shadow left-side-modal " + status}>
      <div className="card-header" style={{ display: "flex" }}>
        <h4 className="card-header-title"> Informações Fiscais</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => fechaModal(e)}
        >
          <span aria-hidden="true">×</span>
        </button>
        {/* <button onClick={() => fechaModal()} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button> */}
      </div>
      <div className="col-12 mt-4">
        <div className="form-row">
          <div className="form-group col-lg-6 col-xl-6">
            <label>Origem</label>
            <select
              className="form-control"
              placeholder="Selecione"
              name="origin"
              onChange={updateData}
              value={data.origin}
            >
              <option key={"origin" - 0} value={""}>
                Selecione
              </option>
              {origem.map((item) => (
                <option key={"origin-" + item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-3 col-xl-3">
            <label>Tributação</label>
            <select
              className="form-control"
              placeholder="Selecione"
              name="taxation"
              onChange={updateData}
              value={data.taxation}
            >
              <option key={"taxation" - 0} value={""}>
                Selecione
              </option>
              {taxation.map((item) => (
                <option key={"taxation-" + item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-lg-3 col-xl-3">
            <label>Tipo de produção</label>
            <select
              className="form-control"
              placeholder="Selecione"
              name="type"
              onChange={updateData}
              value={data.type}
            >
              <option key={"type" - 0} value={""}>
                Selecione
              </option>
              {type.map((item) => (
                <option key={"type-" + item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row">
          {/* <div className="form-group col-lg-3 col-xl-3">
          <label>Estado</label>
          <select className="form-control">
            <option>Selecione</option>
          </select>
        </div> */}
          <div className="form-group col-lg-12 col-xl-12">
            <label>NCM</label>
            <SelectSearch
              options={ncms}
              search={true}
              name="ncm"
              placeholder="Digite um código NCM para busca"
              value={data.ncm}
              getOptions={(query) => {
                let data = {
                  page: 1,
                  description: query,
                };
                api.post("fiscal/ncm/list", data).then((res) => {
                  let aux = [];
                  res.data.data.forEach((item) => {
                    aux.push({
                      name: item.description,
                      value: String(item.id),
                    });
                  });
                  setNcms(aux);
                });
              }}
              emptyMessage="Digite um código NCM."
              onChange={(e) =>
                setData({
                  ...data,
                  ncm: e,
                })
              }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-6 col-xl-6">
            <label>CFOP</label>
            <SelectSearch
              options={cfop}
              search={true}
              name="cfop"
              placeholder="Digite um código cfop para busca"
              value={data.cfop}
              getOptions={(query) => {
                let data = {
                  page: 1,
                  name: query,
                };
                api
                  .post("fiscal/cfop/list", data)
                  .then((res) => {
                    let aux = [];
                    res.data.data.forEach((item) => {
                      aux.push({ name: item.name, value: String(item.id) });
                    });
                    setCfop(aux);
                  })
                  .catch(() => {
                    Swal.fire({
                      title: "Ops!",
                      icon: "error",
                      text: "Ocorreu um erro interno, por favor tente novamente",
                      confirmButtonText: "Ok",
                    });
                  });
              }}
              emptyMessage="Digite um código NCM."
              onChange={(e) =>
                setData({
                  ...data,
                  cfop: e,
                })
              }
            />
          </div>
          <div className="form-group col-lg-3 col-xl-3">
            <label>CEST</label>
            <input
              className="form-control"
              type="text"
              name="cest"
              value={data.cest}
              onChange={updateData}
            />
          </div>
          <div className="form-group col-lg-3 col-xl-3">
            <label>EAN</label>
            <input
              className="form-control"
              type="text"
              name="ean"
              value={data.ean}
              onChange={updateData}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-8 col-xl-8">
            <label>CST ICMS</label>
            <select
              className="form-control"
              name="csticms"
              onChange={updateData}
              value={data.csticms}
            >
              <option key={"csticms" - 0} value={""}>
                Selecione
              </option>
              {csticm.map((item) => (
                <option key={"csticms-" + item.id} value={item.id}>
                  {item.description}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-4 col-lg-4 col-xl-4">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>ICMS</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text inputs" id="inputTags">
                  %
                </span>
              </div>
              <input
                value={data.icms}
                name="icms"
                onChange={updateData}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-8 col-xl-8">
            <label>CST PIS</label>
            <select
              className="form-control"
              name="cstpis"
              onChange={updateData}
              value={data.cstpis}
            >
              <option key={"cstpis" - 0} value={""}>
                Selecione
              </option>
              {cstpis.map((item) => (
                <option key={"cstpis-" + item.id} value={item.id}>
                  {item.description}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-4 col-lg-4 col-xl-4">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>PIS</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text inputs" id="inputTags">
                  %
                </span>
              </div>
              <input
                value={data.pis}
                name="pis"
                onChange={updateData}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-8 col-xl-8">
            <label>CST Cofins</label>
            <select
              className="form-control"
              name="cstconfins"
              onChange={updateData}
              value={data.cstconfins}
            >
              <option value={0} key={"cstcongins-" + 0}>
                Selecione
              </option>
              {cstconfins.map((item) => (
                <option key={"cstconfins-" + item.id} value={item.id}>
                  {item.description}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-2 col-lg-4 col-xl-4">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Cofins</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text inputs" id="inputTags">
                  %
                </span>
              </div>
              <input
                value={data.confins}
                name="confins"
                onChange={updateData}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <button className="btn btn-primary c3" onClick={salvar}>
            Vincular Tributação ao produto
          </button>
        </div>
      </div>
    </div>
  );
}
