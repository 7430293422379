import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { useTranslation } from "react-i18next";

StepByStep.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      info: PropTypes.string,
      complete: PropTypes.bool,
      currentPosition: PropTypes.number,
    })
  ),
  setSteps: PropTypes.func,
};

export default function StepByStep(props) {
  const { steps, setSteps } = props;
  const lastStep = steps.at(-1);
  const firstStep = steps[0];
  const { t } = useTranslation("organizations", {
    keyPrefix: "configuration",
  });

  const navigationSteps = (stepId) => {
    const newState = steps.map((obj) => {
      let newObj;
      if (Number(stepId) === obj.id) {
        newObj = { ...obj, currentPosition: obj.id };
      } else {
        newObj = { ...obj, currentPosition: stepId };
      }
      return newObj;
    });
    setSteps(newState);
  };
  return (
    <div className="card-step">
      <div className="step-container">
        {steps &&
          steps.length > 0 &&
          steps.map((item) => (
            <div className="step-content" key={item.id}>
              <div className="step-button-container">
                <div className="step-button-content">
                  <div className="step-button-row">
                    <div className="bar-left-side-container">
                      {firstStep.id !== item.id && (
                        <div
                          className={`bar-left-side${
                            item.complete || item.next ? "-complete" : ""
                          }`}
                        />
                      )}
                    </div>
                    <div
                      className={`step-button${
                        item.complete ||
                        item.currentPosition === item.id ||
                        item.next
                          ? "-complete"
                          : ""
                      }`}
                    >
                      <button
                        value={item.id}
                        onClick={(e) => navigationSteps(e.target.value)}
                        disabled={!item.next && !item.complete ? true : false}
                        type="button"
                      />
                    </div>
                  </div>
                </div>
                <div className="step-bar">
                  {lastStep.id !== item.id && (
                    <div
                      className={`bar-right-side${
                        item.complete ? "-complete" : ""
                      }`}
                    />
                  )}
                </div>
              </div>
              <div className={`step-info${item.next ? "-complete" : ""}`}>
                {t(item.stepInfo)}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
