import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import Api from "../../../../services/api.js";
import { DatePicker } from "components/datepicker";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import Loading from "components/loading/index.js";
import { NavLink } from "react-router-dom";
import CustomButtonSpinner from "components/buttonSpinner/index.js";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings.js";
import New_api from "services/new-api.js";

const EventReport = () => {
  const { currencyFormatter } = useLanguageSettings();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [fat, setFat] = useState();
  const [cancel, setCancel] = useState();
  const [lucro, setLucro] = useState();
  const [mostSellers, setMostSellers] = useState([]);
  const [sellersCategory, setSellersCategory] = useState([]);
  const [amountType, setAmountType] = useState([]);
  const [dataInit, setDataInit] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [search, setSearch] = useState(true);
  const [initialType, setInitialType] = useState(true);
  const [changeCard, setChange] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTransaction, setDataTransaction] = useState({
    consumo: {
      total_amount: 0,
      total_transactions: 0,
    },
    ativacao: {
      total_amount: 0,
      total_transactions: 0,
    },
    devolucao: {
      total_amount: 0,
      total_transactions: 0,
    },
    desativacao: {
      total_amount: 0,
      total_transactions: 0,
    },
    estornoConsumo: {
      total_amount: 0,
      total_transactions: 0,
    },
    estornoticket: {
      total_amount: 0,
      total_transactions: 0,
    },
    estornoRecarga: {
      total_amount: 0,
      total_transactions: 0,
    },
    recarga: {
      total_amount: 0,
      total_transactions: 0,
    },
  });

  const generateReport = () => {
    setIsLoading(true);
    let payload = {
      dataInit: dataInit
        ? moment(dataInit).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
        : null,
      dataFim: dataFim
        ? moment(dataFim).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
        : null,
      event_id: event_id,
      type: changeCard === 1 ? "ticket" : "cashless",
    };
    Api.post(`/generatorxlsx/xlsx`, payload, {
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"transações"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    document.getElementById("loading").classList.remove("esconde");

    if (search && changeCard !== 0) {
      async function fetchData() {
        //Faturamento dos produtos
        const getFat = await Api.post(`dash/getFat/${event_id}`, {
          dataInit,
          dataFim,
          type: changeCard === 1 ? "ticket" : "cashless",
        });
        if (changeCard === 1) {
          //Cancelamentos
          const getCancel = await Api.post(`dash/getCancel/${event_id}`, {
            dataInit,
            dataFim,
          });
          setCancel(getCancel.data.total);

          setLucro(getFat.data.total - getCancel.data.total);
        } else {
          Api.post(`dash/getTransactionsType/${event_id}`, {
            dataInit: dataInit,
            dataFim: dataFim,
          })
            .then((res) => {
              setDataTransaction({
                ativacao: {
                  total_amount:
                    res.data?.objectTypes?.ativacao?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.ativacao?.total_transactions || 0,
                },
                consumo: {
                  total_amount:
                    res.data?.objectTypes?.consumo?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.consumo?.total_transactions || 0,
                },
                devolucao: {
                  total_amount:
                    res.data?.objectTypes?.devolucao?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.devolucao?.total_transactions || 0,
                },
                desativacao: {
                  total_amount:
                    res.data?.objectTypes?.desativacao?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.desativacao?.total_transactions || 0,
                },
                estornoConsumo: {
                  total_amount:
                    res.data?.objectTypes?.estornoconsumo?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.estornoconsumo?.total_transactions ||
                    0,
                },
                estornoRecarga: {
                  total_amount:
                    res.data?.objectTypes?.estornorecarga?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.estornorecarga?.total_transactions ||
                    0,
                },
                recarga: {
                  total_amount:
                    res.data?.objectTypes?.recarga?.total_amount || 0,
                  total_transactions:
                    res.data?.objectTypes?.recarga?.total_transactions || 0,
                },
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: "Ocorreu um erro interno, por favor tente novamente",
                confirmButtonText: "Ok",
              });
            });
          setLucro(getFat.data.total);
        }
        //Tipo de pagamentpo
        const getAmountType = await Api.post(
          `dash/getPaymentAmount/${event_id}`,
          {
            dataInit: dataInit
              ? moment(dataInit)
                  .tz("America/Sao_Paulo")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            dataFim: dataFim
              ? moment(dataFim)
                  .tz("America/Sao_Paulo")
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            type: changeCard === 1 ? "ticket" : "cashless",
          }
        );
        setAmountType(getAmountType.data);

        let auxLucro = getFat.data.total;
        setFat(auxLucro);
        document.getElementById("loading").classList.add("esconde");
      }

      const fetchProduct = () => {
        let data = {
          dataInit: moment(dataInit)
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD HH:mm:ss"),
          dataFim: moment(dataFim)
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD HH:mm:ss"),
          type: changeCard === 1 ? "ticket" : "cashless",
        };
        // setar header da planilha

        Api.post(`/dash/commands/products/establishment/${event_id}`, data)
          .then((res) => {
            setMostSellers(res.data.listProducts);
            setSellersCategory(res.data.listPerCategory);
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      };

      fetchProduct();
      fetchData();
      selectedButton(changeCard);
      setSearch(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, changeCard]);

  useEffect(() => {
    if (initialType) {
      New_api.get(`/events/${event_id}`, { params: { fields: ["type"] } })
        .then(({ data: { type } }) => {
          setChange(type === "ticket" ? 1 : 2);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
    setInitialType(false);
  }, [initialType, event_id]);

  const selectedButton = (card) => {
    var ActiveBtns = document.getElementsByClassName("nav-link");
    for (let index = 0; index < ActiveBtns.length; index++) {
      const element = ActiveBtns[index];
      element.classList.remove("active");
    }
    var ActiveBtns2 = document.getElementsByClassName("nav-item");
    for (let index = 0; index < ActiveBtns2.length; index++) {
      const element = ActiveBtns2[index];
      element.classList.remove("active");
    }
    document.getElementById("type-nav-" + card).classList.add("active");
  };

  useEffect(() => {
    setFat();
    setCancel();
    setLucro();
    setMostSellers([]);
    setSellersCategory([]);
    setAmountType([]);
  }, [changeCard]);

  return (
    <>
      <PageHeader
        title="Relatório geral"
        text="Aqui você encontra o relatório geral do seu evento de forma resumida."
      ></PageHeader>
      <ReactTooltip place="top" className="tooltip-custom" effect="solid" />
      <Loading title={t("global:loadingText")} />
      <div className="container-fluid">
        <div className="menu col-12 mt-4">
          <div className="nav justify-content-center row">
            <div
              id="type-nav-1"
              className="nav-item active col-6 type-nav col-lg-3 mr-4"
              onClick={(e) => {
                setChange(1);
                setSearch(true);
              }}
            >
              <button id="buttonCard-1" aria-current="page" data-toggle="pill">
                Ticket
              </button>
            </div>
            <div
              id="type-nav-2"
              className="nav-item col-6 type-nav col-lg-3 ml-2 mr-2"
              onClick={(e) => {
                setChange(2);
                setSearch(true);
              }}
            >
              <button id="buttonCard-2" data-toggle="pill">
                Cashless
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="event-report" className="mt-4 container-fluid">
        <div className="row mt-4">
          <div className="col-md mb-3">
            <div className="card-one cards">
              <p
                className="card-span"
                data-tip="Total de vendas - Total de estornos"
              >
                {currencyFormatter(fat, false)}
              </p>
              <p className="card-subspan">Recargas</p>
            </div>
          </div>
          {changeCard === 1 && (
            <>
              <div className="col-md mb-3">
                <div className="card-five cards">
                  <p className="card-span">
                    - {currencyFormatter(cancel, false)}
                  </p>
                  <p className="card-subspan">Cancelamento</p>
                </div>
              </div>

              <div className="col-md mb-3">
                <div className="card-four cards">
                  <p className="card-span">{currencyFormatter(lucro, false)}</p>
                  <p className="card-subspan">Total Bruto</p>
                </div>
              </div>
            </>
          )}
          {changeCard === 2 && (
            <>
              <div className="col-md mb-3">
                <div className="card-two cards">
                  <p className="card-span">
                    {currencyFormatter(
                      dataTransaction.ativacao?.total_amount -
                        dataTransaction.desativacao?.total_amount,
                      false
                    )}
                  </p>
                  <p className="card-subspan">Ativações</p>
                </div>
              </div>

              <div className="col-md mb-3">
                <div className="card-five cards">
                  <p className="card-span">
                    {`- ${currencyFormatter(
                      dataTransaction.consumo?.total_amount,
                      false
                    )}`}
                  </p>
                  <p className="card-subspan">Consumos realizados</p>
                </div>
              </div>

              <div className="col-md mb-3">
                <div className="card-five cards">
                  <p className="card-span">
                    {`- ${currencyFormatter(
                      dataTransaction.devolucao?.total_amount,
                      false
                    )}`}
                  </p>
                  <p className="card-subspan">Devoluções</p>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-10 col-12">
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="col-sm mb-2 filter-sale input-group"
                      style={{ padding: "0px" }}
                    >
                      <DatePicker
                        name="data-ini"
                        showTimeSelect
                        value={dataInit}
                        onChange={(date) => setDataInit(date)}
                        placeholderText={t("components:datepicker.startDate2")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="col-sm mb-2 filter-sale input-group"
                      style={{ padding: "0px" }}
                    >
                      <DatePicker
                        name="data-fin"
                        showTimeSelect
                        value={dataFim}
                        onChange={(date) => setDataFim(date)}
                        placeholderText={t("components:datepicker.endDate2")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-12">
                <button
                  className="btn btn-secondary"
                  onClick={(e) => setSearch(true)}
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
        </div>
        {lucro > 0 && (
          <>
            <div className="card mt-3">
              <div className="card-body">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="row d-flex">
                      <div className="col-md-6 subtitle">
                        Recargas por forma de pagamento
                      </div>
                      <div className="col-md-5 mr-2 d-flex justify-content-end">
                        <CustomButtonSpinner
                          customStyle={"btn btn-outline-primary"}
                          triggerOnClick={generateReport}
                          isLoading={isLoading}
                          text={"Exportar relatório geral"}
                        >
                          <FontAwesomeIcon icon={faFileExcel} />
                        </CustomButtonSpinner>
                      </div>
                    </div>
                  </div>
                  <div className="table table-responsive">
                    <table
                      id="table-totalsales"
                      className="table table-sm mt-4 datatable-custom"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">FORMA DE PAGAMENTO</th>
                          <th scope="col" className="text-center">
                            QUANTIDADE DE VENDAS
                          </th>
                          <th scope="col" className="text-center">
                            QUANTIDADE DE CANCELAMENTOS
                          </th>
                          <th scope="col" className="text-right">
                            VALOR TOTAL
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {amountType.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.raw_method !== "cash" ||
                              item.name === "Dinheiro"
                                ? item.name
                                : `${item.name} (dinheiro)`}
                            </td>
                            <td className="text-center">
                              {currencyFormatter(item.sold, false)}
                            </td>
                            <td className="text-center">
                              {currencyFormatter(-item.canceled, false)}
                            </td>
                            <td className="text-right">
                              {currencyFormatter(
                                item.sold - item.canceled,
                                false
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <div className="col-md-12">
                  <span className="subtitle">Resumo por categorias</span>

                  <div className="table table-responsive">
                    <table
                      id="table-totalsales"
                      className="table table-sm mt-4 datatable-custom"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">CATEGORIA</th>
                          <th scope="col" className="text-center">
                            VENDIDOS
                          </th>
                          <th scope="col" className="text-center">
                            CANCELADOS
                          </th>
                          <th scope="col" className="text-center">
                            TOTAL
                          </th>
                          <th scope="col" className="text-right">
                            VALOR TOTAL
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sellersCategory.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <NavLink
                                to={`/bar/event-report-category?category_id=${
                                  item.id
                                }&event_id=${event_id}${
                                  dataInit && dataFim
                                    ? `&dataInit=${moment(
                                        dataInit
                                      ).toDate()}&dataFim=${moment(
                                        dataFim
                                      ).toDate()}`
                                    : ""
                                }`}
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkAlt}
                                  className="mr-2"
                                ></FontAwesomeIcon>
                                {item.category}
                              </NavLink>
                            </td>
                            <td className="text-center">{item.quantitty}</td>
                            <td className="text-center">{`${
                              item.cancellation > 0 ? "-" : ""
                            } ${item.cancellation}`}</td>
                            <td className="text-center">
                              {Math.abs(item.cancellation - item.quantitty)}
                            </td>
                            <td className="text-right">
                              {currencyFormatter(item.valorTotal, false)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <div className="col-md-12">
                  <div className="subtitle">Extrato de produtos</div>
                  <div className="table table-responsive">
                    <table
                      id="table-totalsales"
                      className="table table-sm mt-4 datatable-custom"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">NOME</th>
                          <th scope="col" className="text-center">
                            VENDIDOS
                          </th>
                          <th scope="col" className="text-center">
                            CANCELADOS
                          </th>
                          <th scope="col" className="text-center">
                            TOTAL
                          </th>
                          <th scope="col" className="text-center">
                            VALOR UNITÁRIO
                          </th>
                          <th scope="col" className="text-right">
                            VALOR TOTAL
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostSellers.map((item, i) => (
                          <tr key={i}>
                            <td>{item.product}</td>
                            <td className="text-center">{item.quantity}</td>
                            <td className="text-center">{`${
                              item.cancellation > 0 ? "-" : ""
                            } ${item.cancellation}`}</td>
                            <td className="text-center">
                              {item.quantity - item.cancellation}
                            </td>
                            <td className="text-center">
                              {currencyFormatter(item.unique_price, false)}
                            </td>
                            <td className="text-right">
                              {currencyFormatter(item.total, false)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EventReport;
