import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import DataTable, { createTheme } from "react-data-table-component";
import Api from "services/api.js";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import EmptyData from "components/empty-data";
import ModalRegisterType from "./modalRegisterType/index.js";

const EquipmentsModels = () => {

  const [selectableRows] = useState(false);
  const [noSelectAll] = useState(false);
  const [selectableRowsHighlight] = useState(false);
  const [expandableRows] = useState(false);
  const [expandOnRowClick] = useState(false);
  const [pagination] = useState(true);
  const [highlight] = useState(false);
  const [striped] = useState(false);
  const [pointer] = useState(false);
  const [dense] = useState(false);
  const [persist] = useState(false);
  const [tableHead] = useState(false);
  const [loading] = useState(false);
  const [noHeader] = useState(true);
  const [subHeader] = useState(false);
  const [subHeaderAlign] = useState("right");
  const [fixedHeader] = useState(false);
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [typeID, setTypeID] = useState('');
  const [statusModal, setStatusModal] = useState('modal-hidden');


  const fetchData = async () => {
    const result = await Api.get("equipments/categories");
    result.data.data.map(item => {
      item.default_price = item.default_price / 100
      return true;
    });
    setList(result.data.data);
    setListFilter(result.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [filter, setFilter] = useState({
    name: ""
  });

  const updateFilter = e => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  const apllyFilter = e => {
    searchName(e.target.value);
  };

  const searchName = value => {
    let ar = [];
    listFilter.forEach(item => {
      if (item.name.toLowerCase().includes(value.toLowerCase())) {
        ar.push(item);
      }
    });
    setList(ar);
    if (filter.name === "") {
      setList(listFilter);
    }
  };

  const HandleClick = id => {
    Swal.fire({
      title: "Deletar",
      icon: "warning",
      text: "Deseja realmente deletar esta categoria?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        Api.post(`equipments/deleteCategorie/${id}`)
          .then(res => {
            Swal.fire("Deletado", "Categoria deletada com sucesso", "success");
            fetchData();
          })
          .catch(err => {
            Swal.fire("Deletar", "Categoria não deletada", "error");
          });
      }
    });
  };

  createTheme("ticket", {
    text: {
      primary: "#4F6C7C",
      secondary: "#CBC1BF",
      font: "roboto"
    },
    headertext: {
      primary: "#2F81DA"
    },
    divider: {
      default: "#e9f2f2"
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
      divider: {
        default: "#3F25A8"
      }
    },
    button: {
      default: "#3F25A8",
      focus: "#00A7E1",
      hover: "#20C4F4",
      disabled: "rgba(0, 0, 0, 0.12)"
    }
  });


  const columns = [
    {
      name: "Nome",
      selector: "name",
      sortable: true
    },
    {
      name: "Preço",
      // selector: "default_price",
      // sortable: true
      cell: row => (
        <span>
          {
            row.default_price.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
        </span>
      )
    },
    {
      name: "Descrição",
      selector: "description",
      sortable: false
    },
    {
      right: true,
      cell: row => (
        <>
          <ReactTooltip className="tooltip-custom" effect="solid" place="top" />
          <button
            className="btn-table"
            onClick={() => openModal(row.id)}
            style={{ marginRight: "10px" }}
            data-tip="Editar"
          >
            <FontAwesomeIcon icon={faPen} />
          </button>

          <button
            className="btn-table"
            onClick={() => HandleClick(row.id)}
            data-tip="Deletar"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </>
      )
    }
  ];

  function openModal(id) {
    setTypeID(id);
    setStatusModal('modal-show')
  }

  function closeModal(e) {
    setTypeID('');
    setStatusModal('modal-hidden');
    fetchData();
  }

  return (
    <>
      <PageHeader
        title="Categorias de Equipamentos"
        text="Gerencie as categorias de equipamentos para organizar melhor o estoque."
        btnAction={() => openModal(null)}
        btnText="criar nova categoria"
      >
      </PageHeader>

      <div className="container-fluid mt-4">
          <div className="col-12">
            <div className="card-search">
              <FontAwesomeIcon icon={faSearch} className="icon-search" />
              <input
                type="text"
                className="input-custom"
                name="name"
                placeholder="Pesquise pelo nome do modelo"
                value={filter.name}
                onChange={updateFilter}
                onKeyUp={apllyFilter}
              />
            </div>
          </div>

        <div className="col-12">
          <DataTable
            columns={columns}
            data={list}
            defaultSortField="title"
            selectableRows={selectableRows}
            selectableRowsNoSelectAll={noSelectAll}
            selectableRowsHighlight={selectableRowsHighlight}
            expandableRows={expandableRows}
            expandOnRowClicked={expandOnRowClick}
            pagination={pagination}
            paginationComponentOptions={{ rowsPerPageText: 'Resultados por páginas:', rangeSeparatorText: 'de', noRowsPerPage: false }}
            highlightOnHover={highlight}
            striped={striped}
            pointerOnHover={pointer}
            dense={dense}
            noTableHead={tableHead}
            persistTableHead={persist}
            progressPending={loading}
            noHeader={noHeader}
            noDataComponent={
              <EmptyData title="Nenhum resultado disponível."/>
            }
            subHeader={subHeader}
            theme="ticket"
            subHeaderAlign={subHeaderAlign}
            // subHeaderAlign={subHeaderAlign}
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight="300px"
          />
        </div>
      </div>
      <ModalRegisterType status={statusModal} id={typeID} callback={closeModal}/>
    </>
  );
};

export default EquipmentsModels;
