import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfDay, isSameDay, startOfDay } from "date-fns";
import JoditEditor from "jodit-react";
import React, { Fragment, useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import Select from "react-select";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

import ButtonDashed from "components/buttonDashed";
import CreateCategorySelector from "components/categorySelector";
import { MyDropzone } from "components/dropzone";
import PhoneNumber from "components/input-phone-number";
import OrganizationsInput from "components/organizations-input";
import SponsorCard from "components/sponsorCard";
// Providers
import api from "services/api";
import { channels, channelSelectStyles } from "services/constants";
import { currencies } from "services/constants";
import New_api from "services/new_api";
import { UtilsHelper } from "services/utils";
import { ValidationsHelper } from "services/validations";
import { getAllTimezones } from "utils";

import AddCircle from "../../../../../../assets/icons/addCircle.svg";
import { ReactComponent as DollarIcon } from "../../../../../../assets/icons/dollar-coin-solid.svg";
import { ReactComponent as TimezoneIcon } from "../../../../../../assets/icons/timezoneGlobal.svg";
// Components
import EventCreateHeader from "../../event-create-header";
import { GroupHeader } from "./groupHeader";
import SponsorsModal from "./sponsorsModal";

import "./styles.css";
import "react-google-places-autocomplete/dist/assets/index.css";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import EventAddress from "components/event-location";

// Extra
let container;

const currenciesOptions = currencies.map(({ formatted, value }, index) => ({
  id: index,
  label: formatted,
  value,
}));

const timezoneOptions = getAllTimezones().map(
  ({ formatted, timezone }, index) => ({
    id: index,
    label: formatted,
    value: timezone,
  })
);

const config = {
  statusbar: false,
  placeholder: "Digite aqui a descrição do evento...",
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
  ],
};

export function validateStep1(data, agreeNoImage) {
  const validContact = "Preencha Contato de suporte ao cliente válido";
  if (!agreeNoImage) {
    return false;
  }
  if (data.event.name.length <= 0) {
    container.error("Digite um nome para o evento.");
    return false;
  }

  if (!data.event.organization_id) {
    container.error("Favor escolher uma organização.");
    return false;
  }
  if (data.event.categories[0].mainCategory === 0) {
    container.error("Selecione uma categoria");
    return false;
  }

  if (
    (data.event.categories[0].mainCategory !== 0 &&
      data.event.categories[0].sub.length === 0) ||
    (data.event.categories[1].mainCategory !== 0 &&
      data.event.categories[1].sub.length === 0)
  ) {
    container.error("Selecione uma subcategoria");
    return false;
  }

  if (data.location.formatted_address === "" && data.event.is_online === 0) {
    container.error("Selecione um local.");
    return false;
  }
  if (!data.event.link_other && data.event.is_online === 1) {
    container.error("Favor informar o link de transmissão.");
    return false;
  }
  if (
    data.event.link_other &&
    !ValidationsHelper.isWebLinkValid(data.event.link_other)
  ) {
    container.error("Favor informar um link de transmissão válido.");
    return false;
  }

  if (!data.dates.start_date || !data.dates.end_date) {
    container.error("Selecione as datas.");
    return false;
  }

  if (!data.dates.start_hour || !data.dates.end_hour) {
    container.error("Favor selecionar os horários de início e fim do evento.");
    return false;
  }
  if (!data.dates.sails_limit) {
    container.error("Favor informar a data limite para vendas.");
    return false;
  }
  if (!data.dates.sails_limit_hour) {
    container.error(
      "Favor selecionar o horário de encerramento das vendas do evento."
    );
    return false;
  }

  if (!data.event.communication_channel_type) {
    container.error("Favor selecionar o tipo de contato.");
    return false;
  }
  if (!data.event.communication_channel) {
    container.error("Preencha Contato de suporte ao cliente.");
    return false;
  }

  if (
    data.event.communication_channel_type === "telephone" &&
    !isValidPhoneNumber(data.event.communication_channel)
  ) {
    container.error(validContact);
    return false;
  }
  if (
    data.event.communication_channel_type === "email" &&
    !ValidationsHelper.isEmailValid(data.event.communication_channel)
  ) {
    container.error(validContact);
    return false;
  }
  if (
    data.event.communication_channel_type === "social_media" &&
    !ValidationsHelper.isWebLinkValid(data.event.communication_channel)
  ) {
    container.error(validContact);
    return false;
  }

  if (!data.event.categories) {
    container.error("Favor inserir uma categoria");
    return false;
  }

  if (
    data.event.categories[0].mainCategory ===
    data.event.categories[1].mainCategory
  ) {
    container.error("Favor inserir duas categorias diferentes");
    return false;
  }

  return true;
}

const EventCreateStep1 = (props) => {
  const { t } = useTranslation();
  const [stepData, setStepData] = useState({
    event: props.event,
    location: props.location,
    dates: props.dates,
    sponsors: props.sponsors,
  });
  const [status, setStatus] = useState("modal-hidden");
  const [sponsors, setSponsors] = useState([]);

  const switchStyles2 = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor: "#ced4da",
      borderRadius: "0 0.375rem 0.375rem 0",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  const [selectedFile, setSelectedFile] = useState();
  const [previewBanner, setPreviewBanner] = useState();

  const [selectedThumb, setSelectedThumb] = useState();
  const [previewThumb, setPreviewThumb] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreviewBanner(undefined);
      return;
    }

    const blob = new Blob([selectedFile]);
    const objectUrl = URL.createObjectURL(blob);
    setPreviewBanner(objectUrl);
    uploadImage(blob, true);
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    const { street, number, neighborhood, city, state, zip_code } =
      stepData.location;
    if (street && neighborhood && city && state && zip_code) {
      const formatted_address = `${street}, ${number} - ${neighborhood}, ${city}, ${state} - ${zip_code}`;
      setStepData((prevState) => ({
        ...prevState,
        location: {
          ...prevState.location,
          formatted_address,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stepData.location.street,
    stepData.location.number,
    stepData.location.neighborhood,
    stepData.location.city,
    stepData.location.state,
    stepData.location.zip_code,
  ]);

  const handleDeletePic = (origin) => {
    if (origin === "banner") {
      onSelectFile();
      setStepData({
        ...stepData,
        event: {
          ...stepData.event,
          banner: "",
        },
      });
    } else {
      onSelectThumb();
      setStepData({
        ...stepData,
        event: {
          ...stepData.event,
          thumb: "",
        },
      });
    }
  };

  const onSelectFile = (e) => {
    if (!e) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e);
  };

  useEffect(() => {
    if (!selectedThumb) {
      setPreviewThumb(undefined);
      return;
    }

    const blob = new Blob([selectedThumb]);
    const objectUrl = URL.createObjectURL(blob);
    setPreviewThumb(objectUrl);
    uploadImage(blob);
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThumb]);

  const onSelectThumb = (e) => {
    if (!e) {
      setSelectedThumb(undefined);
      return;
    }

    setSelectedThumb(e);
  };

  useEffect(() => {
    New_api.get("settings/locale").then(({ data: { timezone, currency } }) => {
      setStepData((prevState) => ({
        ...prevState,
        event: {
          ...prevState.event,
          timezone,
          currency,
        },
      }));
    });
  }, []);

  const updateStepDataEvent = (e) => {
    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        [e.target.name]: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (!props.stepCheck) {
      return;
    } else if (props.stepCheck && (!selectedFile || !selectedThumb)) {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        showCancelButton: true,
        animation: false,
        text: "Seu evento esta sem imagem de divulgação!",
        confirmButtonText: "Inserir mais tarde",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          props.callBack(stepData, true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          props.callBack(stepData, false);
        }

        props.callBack(stepData, false);
      });
    } else {
      props.callBack(stepData, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stepCheck]);

  function slugify(text, ampersand = "e") {
    const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
    const b = "aaaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
    const p = new RegExp(a.split("").join("|"), "g");

    text = text
      .toString()
      .toLowerCase()
      .replace(/[\s_]+/g, "-")
      .replace(p, (c) => b.charAt(a.indexOf(c)))
      .replace(/&/g, `-${ampersand}-`)
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+|-+$/g, "");

    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        slug: text,
      },
    });
  }

  // Listener para adição de tags
  const pushTag = (e) => {
    if (e.key === "Enter") {
      let tags = stepData.event.tags;
      let currentTag = e.target.value.trim().toLowerCase();
      let index = stepData.event.tags.indexOf(currentTag);
      // Caso a tag seja repetida acende a tag já existente por 2seg
      if (index > -1) {
        let tag = document.getElementById(`tag-${index}`);
        tag.className = "event-tag c3";
        setTimeout(() => {
          tag.className = "event-tag";
        }, 2000);
      } else {
        // Caso contrário adiciona à lista de tags e reseta o input
        if (currentTag.length) {
          tags.push(currentTag);
          setStepData({
            ...stepData,
            event: {
              ...stepData.event,
              tags: tags,
            },
          });
        }
        e.target.value = "";
      }
    }
  };

  function uploadImage(selected, banner) {
    var form_data = new FormData();
    let file_data = selected;

    form_data.append("image", file_data);

    api
      .post("uploads?local=eventos", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (banner) {
          setStepData({
            ...stepData,
            event: {
              ...stepData.event,
              banner: res.data.url,
            },
          });
        } else {
          setStepData({
            ...stepData,
            event: {
              ...stepData.event,
              thumb: res.data.url,
            },
          });
        }
      })
      .catch(() => {
        container.error("Erro ao fazer upload da imagem!");
      });
  }

  const organizationSelected = (organization_id, timezone, currency) => {
    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        organization_id: organization_id,
        ...(currency &&
          timezone && {
            currency,
            timezone,
          }),
      },
    });
  };

  const updateCategories = (filteredStepData) => {
    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        categories: filteredStepData,
      },
    });
  };

  const handleTimeEvent = (value, periodDate, periodTime) => {
    setStepData({
      ...stepData,
      dates: {
        ...stepData.dates,
        [periodDate]: new Date(
          stepData.dates[periodDate].setHours(
            value.getHours(),
            value.getMinutes(),
            value.getSeconds()
          )
        ),
        [periodTime]: value,
      },
    });
  };
  const updateCommunicationChannelState = (communication_channel = "") => {
    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        communication_channel,
      },
    });
  };
  const updateCommunicationChannelTypeState = (event) => {
    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        communication_channel_type: event.value,
        communication_channel: "",
      },
    });
  };

  const updateTypeEvent = (type) => {
    setStepData((stepData) => ({
      ...stepData,
      event: {
        ...stepData.event,
        is_online: type === "online" ? 1 : 0,
        link_other: "",
      },
    }));
  };

  const handleSponsorsModal = (status) => {
    if (sponsors.length < 10) {
      setStatus(status);
    } else {
      Swal.fire({
        title: "Limite de parceiros",
        text: "Existe limite de 10 parceiro. Para adicionar novo parceiro terá que excluir um da listagem.",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#5B4299",
        icon: "warning",
      });
    }
  };

  const sponsorAdd = (newSponsor) => {
    const updateSponsors = [
      ...sponsors,
      { order: sponsors.length + 1, ...newSponsor },
    ];
    setSponsors(updateSponsors);
    updateStepDataSponsors(updateSponsors);
  };

  const sponsorRemove = (_, sponsorName) => {
    Swal.fire({
      title: "Atenção",
      text: `Você tem certeza que deseja remover ${sponsorName} da lista de parceiros?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#5B4299",
      confirmButtonText: "Excluir",
      cancelButtonText: "Não",
      customClass: {
        confirmButton: "custom-confirm-button-outline",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const updateSponsors = sponsors.filter(
          (sponsor) => sponsor.name !== sponsorName
        );
        setSponsors(updateSponsors);
        updateStepDataSponsors(updateSponsors);
        Swal.fire({
          title: "Excluido",
          text: "Exclusão feita com sucesso!",
          confirmButtonText: "Fechar",
          confirmButtonColor: "#5B4299",
          icon: "success",
        });
      }
    });
  };

  const updateStepDataSponsors = (updateSponsors) => {
    setStepData((stepData) => ({
      ...stepData,
      sponsors: updateSponsors,
    }));
  };

  const handleIsPublic = (e) => {
    setStepData({
      ...stepData,
      event: {
        ...stepData.event,
        is_public: e.target.checked ? 0 : 1,
      },
    });
  };

  const currencyWarning = (value) => {
    Swal.fire({
      title: "Após a primeira venda, você não poderá alterar a moeda de venda",
      text: "Deseja confirmar a alteração?",
      confirmButtonText: "Alterar moeda",
      cancelButtonText: "Voltar",
      confirmButtonColor: "#0050C3",
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      customClass: {
        cancelButton: "back-currency-class",
      },
    }).then((result) => {
      if (result.value) {
        handleTimezoneAndCurrency("currency", value);
      }
    });
  };

  const handleTimezoneAndCurrency = (name, { value }) => {
    setStepData((prevState) => ({
      ...prevState,
      event: {
        ...prevState.event,
        [name]: value,
      },
    }));
  };

  return (
    <Fragment>
      <EventCreateHeader
        title={t("events:createEvent:basicData")}
        text={t("events:createEvent:basicDataDescription")}
      />

      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <div className="mt-4 event-step" id="create-event-1">
        <div className="col-12 pl-0">
          <div className="card mt-0">
            <div className="card-body">
              <GroupHeader
                title={t("events:createEvent:basicInformation")}
                tclass="t t-bold"
                description={t(
                  "events:createEvent:basicInformationDescription"
                )}
                dclass="d d-txt"
                hr
              />
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="form-label">
                    {t("events:createEvent:eventName")}
                  </label>
                  <input
                    className="form-control inputs"
                    name="name"
                    placeholder={t("events:createEvent:eventName")}
                    value={stepData.event.name}
                    onChange={updateStepDataEvent}
                    onKeyUp={(e) => slugify(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">Slug</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      {t("events:createEvent:salesPage")}
                    </div>
                  </span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text inputs text-small"
                        id="inputTags"
                      >
                        {process.env.REACT_APP_LINK_EMPRESA}/eventos/
                      </span>
                    </div>
                    <input
                      className="form-control inputs"
                      name="slug"
                      value={stepData.event.slug}
                      placeholder={t("events:createEvent:eventSlug")}
                      onChange={updateStepDataEvent}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4 col-12">
                  <div>
                    <label className="form-label">
                      {t("events:createEvent:vinculatedOrg")}
                    </label>
                    <div style={{ zIndex: 2 }} className="position-relative">
                      <OrganizationsInput callBack={organizationSelected} />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4 col-12">
                  <div>
                    <label className="form-label">
                      {t("events:createEvent:ageGroup")}
                    </label>
                    <input
                      className="form-control"
                      name="age_range"
                      value={stepData.event.age_range}
                      onChange={updateStepDataEvent}
                    />
                  </div>
                </div>
                <div className="form-group col-md-4 col-12">
                  <label className="form-label">
                    {t("events:createEvent:coin")}
                  </label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <DollarIcon />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Select
                      styles={switchStyles2}
                      className="col p-0"
                      isSearchable={false}
                      value={
                        stepData?.event?.currency
                          ? currenciesOptions.filter(
                              (option) =>
                                option.value === stepData?.event?.currency
                            )
                          : ""
                      }
                      onChange={(value) => currencyWarning(value)}
                      options={currenciesOptions}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="form-row mt-2">
                <div className="d-flex flex-column form-group col">
                  <GroupHeader
                    title={t("events:createEvent:eventDetails")}
                    tclass="t t-sm"
                    description={t(
                      "events:createEvent:eventDetailsDescription"
                    )}
                    dclass="d d-txt"
                    hr
                  />
                  <JoditEditor
                    value={stepData.event.description}
                    config={config}
                    onChange={(e) =>
                      setStepData({
                        ...stepData,
                        event: {
                          ...stepData.event,
                          description: e,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-row mt-4">
                <div className="form-group col">
                  <GroupHeader
                    title={t("events:createEvent:categorization")}
                    tclass="t t-mid"
                    description={t(
                      "events:createEvent:categorizationDescription"
                    )}
                    dclass="d d-txt"
                    hr
                  />
                  <CreateCategorySelector
                    categories={stepData.event.categories}
                    callback={updateCategories}
                  />
                </div>
              </div>
              <div className="form-row mt-4">
                <div className="col">
                  <GroupHeader
                    title={t("events:createEvent:eventImages")}
                    tclass="t t-mid"
                    description={t("events:createEvent:eventImagesDescription")}
                    dclass="d d-txt"
                    hr
                  />

                  <div className="form-row dropzone">
                    <div className="col-12 col-md-8">
                      <MyDropzone
                        callback={onSelectFile}
                        aria-describedby="inputGroupFileAddon01"
                        id="event-banner"
                        deleteCallback={handleDeletePic}
                        title={{ text: "Banner", class: "t t-img" }}
                        preview={onSelectFile && previewBanner}
                        description={{
                          text: t("events:createEvent:bannerDescription"),
                          class: "d d-img",
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      <MyDropzone
                        callback={onSelectThumb}
                        aria-describedby="inputGroupFileAddon01"
                        title={{ text: "Thumb", class: "t t-img" }}
                        id="event-thumb"
                        deleteCallback={handleDeletePic}
                        description={{
                          text: t("events:createEvent:thumbDescription"),
                          class: "d d-img",
                        }}
                        preview={onSelectThumb && previewThumb}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-none form-row">
                <div className="col-md-12 mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputs" id="inputTags">
                        Tags
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder={t("events:createEvent:tagsDescription")}
                      className="form-control inputs"
                      id="tags"
                      onKeyUp={pushTag}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-row event-tags">
                {stepData.event.tags.length > 0 &&
                  stepData.event.tags.map((tag, i) => (
                    <span
                      id={`tag-${i}`}
                      key={`tag-${i}`}
                      className="event-tag"
                    >
                      {tag}{" "}
                      <FontAwesomeIcon
                        className="ml-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let tags = stepData.event.tags;
                          tags.splice(i, 1);
                          setStepData({
                            ...stepData,
                            event: {
                              ...stepData.event,
                              tags: tags,
                            },
                          });
                        }}
                        icon={faTimesCircle}
                      />
                    </span>
                  ))}
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <GroupHeader
                title={t("events:createEvent:eventLocation")}
                tclass="t t-bold"
                description={t("events:createEvent:eventLocationDescription")}
                dclass="d d-txt"
                hr
              />

              <div className="form-row col">
                <GroupHeader
                  description={t("events:createEvent:eventModalityDescription")}
                  dclass="d d-txt"
                />
              </div>

              <div className="form-row col">
                <div className="form-group form-check form-check-inline">
                  <div class="form-check form-check-inline">
                    <input
                      onClick={() => updateTypeEvent("local")}
                      checked={!stepData.event.is_online}
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                    ></input>
                    <label class="form-check-label" htmlFor="inlineRadio1">
                      {t("events:createEvent:inPerson")}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      onClick={() => updateTypeEvent("online")}
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                    ></input>
                    <label class="form-check-label" htmlFor="inlineRadio2">
                      Online
                    </label>
                  </div>
                </div>
              </div>

              <hr className="form-row"></hr>

              {stepData.event.is_online === 1 ? (
                <div className="form-row col pb-2">
                  <div className="form-group col-md-6">
                    <label className="form-label">
                      {t("events:createEvent:transmissionLink")}
                    </label>
                    <input
                      className="form-control inputs"
                      name="link_other"
                      placeholder="https://www.exemplo.com"
                      value={stepData.event.link_other}
                      onChange={updateStepDataEvent}
                      onKeyUp={() =>
                        setStepData({
                          ...stepData,
                          location: {
                            number: ".",
                            street: ".",
                            neighborhood: ".",
                            city: ".",
                            state: ".",
                            zip_code: ".",
                            complement: ".",
                            lat: ".",
                            lng: ".",
                          },
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <EventAddress stepData={stepData} setStepData={setStepData} />
              )}
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <GroupHeader
                title={t("events:createEvent:dateAndTime")}
                tclass="t t-bold"
                description={t("events:createEvent:dateAndTimeDescription")}
                dclass="d d-txt"
                hr
              />
              <div className="form-row">
                <div className="col-md-3 mb-3">
                  <DatePicker
                    leftIcon
                    label={t("events:createEvent:startDateLabel")}
                    placeholder={t("events:createEvent:startDatePlaceholder")}
                    value={stepData.dates.start_date || null}
                    minDate={new Date()}
                    maxDate={stepData.dates.end_date || null}
                    onChange={(value) => {
                      setStepData({
                        ...stepData,
                        dates: {
                          ...stepData.dates,
                          start_date: value,
                        },
                      });
                    }}
                    placeholderText={t("events:createEvent:startDate")}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <DatePicker
                    label={t("events:createEvent:startTimeLabel")}
                    leftIcon
                    iconType="clock"
                    value={stepData.dates.start_hour || null}
                    disabled={!stepData.dates.start_date}
                    showTimeSelect
                    onlyTime
                    minTime={
                      isSameDay(stepData.dates.start_date, new Date())
                        ? new Date()
                        : startOfDay(new Date())
                    }
                    maxTime={endOfDay(new Date())}
                    onChange={(value) =>
                      handleTimeEvent(value, "start_date", "start_hour")
                    }
                    placeholder={t("events:createEvent.startTimePlaceholder")}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <DatePicker
                    label={t("events:createEvent:endDateLabel")}
                    leftIcon
                    value={stepData.dates.end_date || null}
                    minDate={
                      stepData.dates.start_date
                        ? new Date(stepData.dates.start_date)
                        : new Date()
                    }
                    onChange={(value) => {
                      setStepData({
                        ...stepData,
                        dates: {
                          ...stepData.dates,
                          end_date: value,
                        },
                      });
                    }}
                    placeholder={t("events:createEvent.endDatePlaceholder")}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <DatePicker
                    label={t("events:createEvent:endTimeLabel")}
                    leftIcon
                    iconType="clock"
                    value={stepData.dates.end_hour || null}
                    disabled={!stepData.dates.end_date}
                    showTimeSelect
                    onlyTime
                    onChange={(value) =>
                      handleTimeEvent(value, "end_date", "end_hour")
                    }
                    minTime={
                      isSameDay(
                        stepData.dates.start_date,
                        stepData.dates.end_date
                      )
                        ? stepData.dates.start_date
                        : startOfDay(new Date())
                    }
                    maxTime={endOfDay(new Date())}
                    placeholder={t("events:createEvent.endTimePlaceholder")}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-3 mb-3">
                  <DatePicker
                    leftIcon
                    label={t("events:createEvent:salesLimitLabel")}
                    value={stepData.dates.sails_limit || null}
                    disabled={
                      !stepData.dates.end_date ||
                      !stepData.dates.start_date ||
                      !stepData.dates.start_hour ||
                      !stepData.dates.end_hour
                    }
                    minDate={stepData.dates.start_date}
                    maxDate={stepData.dates.end_date}
                    onChange={(value) => {
                      setStepData({
                        ...stepData,
                        dates: {
                          ...stepData.dates,
                          sails_limit: value,
                        },
                      });
                    }}
                    placeholder={t("events:createEvent:salesLimitPlaceholder")}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <DatePicker
                    leftIcon
                    iconType="clock"
                    label={t("events:createEvent:salesLimitHourLabel")}
                    placeholder={t(
                      "events:createEvent.salesLimitHourPlaceholder"
                    )}
                    value={stepData.dates.sails_limit_hour || null}
                    disabled={
                      !stepData.dates.end_date ||
                      !stepData.dates.start_date ||
                      !stepData.dates.start_hour ||
                      !stepData.dates.end_hour
                    }
                    showTimeSelect
                    onlyTime
                    minTime={
                      isSameDay(
                        stepData.dates.sails_limit,
                        stepData.dates.start_date
                      )
                        ? stepData.dates.start_date
                        : startOfDay(new Date())
                    }
                    maxTime={
                      isSameDay(
                        stepData.dates.sails_limit,
                        stepData.dates.end_date
                      )
                        ? stepData.dates.end_date
                        : endOfDay(new Date())
                    }
                    onChange={(value) =>
                      handleTimeEvent(value, "sails_limit", "sails_limit_hour")
                    }
                  />
                </div>
                <div className="col-md-3 col-12 mb-3">
                  <label className="form-label">
                    {t("events:createEvent:timezone")}
                  </label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <TimezoneIcon />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Select
                      styles={switchStyles2}
                      placeholder={t("events:createEvent:select")}
                      className="col p-0"
                      value={
                        stepData?.event?.timezone
                          ? timezoneOptions.filter(
                              (option) =>
                                option.value === stepData?.event?.timezone
                            )
                          : ""
                      }
                      onChange={(value) =>
                        handleTimezoneAndCurrency("timezone", value)
                      }
                      options={timezoneOptions}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </InputGroup>
                </div>
              </div>

              <div>
                <GroupHeader
                  description={t("events:createEvent:privateBoxDescription")}
                  dclass="d d-txt"
                />

                <div className="form-check form-check-inline">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="public"
                      defaultChecked={false}
                      onChange={(e) => {
                        handleIsPublic(e);
                      }}
                    />
                    <label className="form-check-label" htmlFor="public">
                      {t(
                        "events:eventEdit:salesSettings:visibility:privateText"
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <GroupHeader
                title={t("events:createEvent:clientSupport")}
                tclass="t t-bold"
                description={t("events:createEvent:clientSupportDescription")}
                dclass="d d-txt"
                hr
              />
              <div className="row pb-4">
                <div className="col d-flex align-items-end form-row">
                  <div className="col">
                    <label className="form-label">
                      {t("events:createEvent:contactType")}
                    </label>
                    <Select
                      name="communication_channel_type"
                      placeholder={t("events:createEvent:select")}
                      styles={channelSelectStyles}
                      className="col-12 p-0"
                      value={
                        stepData.event.communication_channel_type
                          ? channels.filter(
                              (option) =>
                                option.value ===
                                stepData.event.communication_channel_type
                            )
                          : ""
                      }
                      onChange={updateCommunicationChannelTypeState}
                      options={channels}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col d-flex align-items-end pt-2">
                    <label className="form-label"></label>
                    {stepData.event.communication_channel_type ===
                    "telephone" ? (
                      <PhoneNumber
                        className="col-sm-12"
                        defaultCountry="BR"
                        placeholder={t("events:createEvent:telephoneNumber")}
                        value={stepData.event.communication_channel}
                        onChange={updateCommunicationChannelState}
                      />
                    ) : (
                      <input
                        className="form-control inputs"
                        name="communication_channel"
                        style={{ marginBottom: 0 }}
                        disabled={!stepData.event.communication_channel_type}
                        placeholder={UtilsHelper.typeChannelPlaceholderSelect(
                          stepData.event.communication_channel_type
                        )}
                        value={stepData.event.communication_channel}
                        onChange={(e) =>
                          updateCommunicationChannelState(e.target.value)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-3 p-4">
            <GroupHeader
              title={t("events:createEvent:partners")}
              tclass="t t-bold"
              description={t("events:createEvent:partnersDescription")}
              dclass="d d-txt"
              hr
            />
            <div className="button-sponsors-container">
              <ButtonDashed
                onClick={() => handleSponsorsModal("modal-show")}
                svg={AddCircle}
                text={t("events:createEvent:addPartners")}
              />
              <div className="sponsor-list-container">
                {sponsors.map((sponsor) => (
                  <div key={sponsor.id} className="pt-4 px-3">
                    <SponsorCard
                      hasTrashCan
                      sponsor={sponsor}
                      onClickTrash={sponsorRemove}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="card p-4">
            <div className="form-row col">
              <div className="form-check form-check-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="accept"
                    defaultChecked={props.accept}
                    onChange={(e) => {
                      props.setAccept(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="accept">
                    {t("events:createEvent:termsOfUse:part1")}
                    <span onClick={() => window.open("/termos-de-uso")}>
                      <u style={{ color: "#0050C3", cursor: "pointer" }}>
                        {t("events:createEvent:termsOfUse:part2")}
                      </u>
                    </span>
                    {t("events:createEvent:termsOfUse:part3")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SponsorsModal
        status={status}
        sponsorsList={sponsors}
        onClose={handleSponsorsModal}
        callback={sponsorAdd}
      />
    </Fragment>
  );
};

export default EventCreateStep1;
