import React from 'react';
import PropTypes from 'prop-types';

DivFormGroup.propTypes = {
	adjutsCss: PropTypes.string,
	classNameLabel: PropTypes.string,
	label: PropTypes.node,
	children: PropTypes.node
};

export default function DivFormGroup(props) {
	const { adjutsCss, classNameLabel, label, children } = props;
	return (
		<div className={`form-group ${adjutsCss}`}>
			<label htmlFor={classNameLabel} className={classNameLabel}>
				{label}
			</label>
			{children}
		</div>
	);
}
