import { useOnClickOutside } from "App";
import ImageFileUpload from "components/image-file-upload/index.js";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastr";
import api from "services/api";
import Swal from "sweetalert2";
import Select from "react-select";

let container;

export default function ModalProduct(props) {
  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close();
    }
  });

  const [product, setProduct] = useState({
    id: "",
    id_user: "",
    name: "",
    category: "",
    composition: 0,
    status: 1,
    picture: "",
  });

  const [cat, setCat] = useState([]);
  const [click, setClick] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [dataClear, setDataClear] = useState(false);

  const updateProduct = (e) => {
    setClick(false);
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const updateCategory = e => {
    setProduct({
        ...product,
        category: e.value
    })
  }

  function salvar() {
    setClick(true);
    if (localStorage.getItem("user_admin") === 0) {
      setProduct({
        ...product,
        id_user: localStorage.getItem("user_id"),
      });
    }

    if (product.name === "" || product.category === 0) {
      setClick(false);
      container.error("Nome e catégoria são obrigatórios!");
      return;
    }

    if (!product.picture) {
      setClick(false);
      container.error("A imagem do produto é obrigatória!");
      return;
    }

    if (props.product_id) {
      api
        .post("products/edit", product)
        .then((res) => {
          setClick(false);
          container.success("Produto editado com sucesso!");
          close();
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    } else {
      api
        .post("products/create", product)
        .then((res) => {
          setClick(false);
          container.success("Produto criado com sucesso!");
          close();
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    }
  }

  function close() {
    setProduct({
      id: "",
      id_user: "",
      name: "",
      category: "",
      composition: 0,
      status: 1,
      picture: "",
    });
    setDataClear(true);
    props.callback("modal-hidden");
  }

  function handleUploadFile(fileData) {
    setClick(true);
    setLoadingImage(true);

    let formData = new FormData();
    formData.append("image", fileData);

    api
      .post("uploads?local=produtos", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        setClick(false);
        setProduct({
          ...product,
          picture: res.data.url,
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      })
      .finally(() => setLoadingImage(false));
  }

  useEffect(() => {
    setDataClear(false);
    api.get("products/showCategories").then((res) => {
      setCat(res.data);
    }).catch(() => {
      Swal.fire({
        title: 'Ops!',
        icon: 'error',
        text: 'Ocorreu um erro interno, por favor tente novamente',
        confirmButtonText: 'Ok'
      })
    });

    if (props.product_id) {
      api.get(`products/get/${props.product_id}`).then((res) => {
        setProduct({
          ...product,
          id: res.data.data.id,
          id_user: res.data.data.id_user,
          name: res.data.data.name,
          category: res.data.data.category,
          composition: res.data.data.composition,
          status: res.data.data.status,
          picture: res.data.data.picture,
        });
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      });
    } else {
      setProduct({
        ...product,
        id: "",
        id_user: "",
        name: "",
        category: "",
        composition: 0,
        status: 1,
        picture: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.product_id, props.status]);

  return (
    <>
      <div
        id="produto-cadastrar"
        ref={ref}
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="card-header">
          <h4 className="card-header-title">
            {props.product_id ? "Editar Produto" : "Cadastrar Produto"}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="name">Nome do Produto</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Nome do Produto"
                value={product.name}
                onChange={updateProduct}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="composition">Composição</label>
              <select
                className="form-control"
                name="composition"
                value={product.composition}
                onChange={updateProduct}
              >
                <option value="0">Simples</option>
                <option value="1">Composto</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label htmlFor="status">Situação</label>
              <select
                className="form-control"
                name="status"
                value={product.status}
                onChange={updateProduct}
              >
                <option value="1">Habilitado</option>
                <option value="0">Desabilitado</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="category">Categoria</label>
            <Select
              value={product.category ? { value: product.category, label: '' } : null}
              options={cat.map((item) => ({ value: item.id, label: item.name }))}
              name="category"
              onChange={updateCategory}
              getOptionLabel={(option) => cat.find((item) => item.id === JSON.parse(option.value))?.name}
              placeholder="Categoria"
            />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              <ImageFileUpload
                id="image"
                image={product.picture}
                dataClear={dataClear}
                label="Imagem"
                loading={loadingImage}
                onChange={(image, base64, file) => {
                  handleUploadFile(file);
                }}
                placeholder="Procurar..."
              />
            </div>
          </div>
          <div className="box-button mt-3">
            <button
              className="btn btn-primary c3 col-md-6"
              disabled={click}
              onClick={() => salvar()}
            >
              {props.product_id ? "Editar" : "Cadastrar"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
