import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SuperCheckbox = (props) => {

  function callback(newValue) {
    props.callback(newValue);
  }

  return (
    <>
      {!props.space && <label className="form-label">&nbsp;</label>}
      <div name={props.name} value={props.value} className={`custom-input-option ${props.value ? 'active' : ''}`} onClick={e => {
        !props.value ? callback(1) : callback(0)
      }}>
        {props.value ?
          <FontAwesomeIcon icon={faCheckSquare} />
          :
          <FontAwesomeIcon icon={faSquare} />
        } {props.label}
      </div>
    </>
  )
}

export default SuperCheckbox;
