import moment from "moment";
import "moment-timezone";
const originalTimeZone = process.env.REACT_APP_DEFAULT_TIMEZONE;
const defaultOptions = {
  locale: "pt-BR",
  shouldShowTime: false,
  timezone: "America/Sao_Paulo",
};
/**
 * @param {Date} date
 * @param {object} options
 * @param {'pt-BR' | 'en-US' | 'es-ES'} options.locale
 * @param {string} options.timezone
 * @param {boolean} options.shouldShowTime
 * **/
export const dateFormatter = (date, options) => {
  const { locale, shouldShowTime, timezone } = {
    ...defaultOptions,
    ...options,
  };
  const originalDateTime = moment.tz(date, originalTimeZone);
  const convertedDateTime = originalDateTime.clone().tz(timezone);
  const hourFormat = locale === "en-US" ? "h:mm A" : "HH:mm";
  const dateFormat = locale === "en-US" ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const format = shouldShowTime
    ? `${dateFormat} ${hourFormat}`
    : `${dateFormat}`;
  return moment(convertedDateTime).locale(locale).format(format);
};
