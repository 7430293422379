import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWineBottle, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./styles.css";

const EventsHeader = ({
  position,
  totalEvents,
  totalEventTipoIngresso,
  totalEventTipoAeB,
  filterFunction,
}) => {
  const { t } = useTranslation();
  function changeState(value) {
    var item = document.getElementsByClassName("subheader-item");
    for (let i = 0; i < item.length; i++) {
      item[i].classList.remove("active");
    }
    if (value === "all") {
      item[0].classList.add("active");
    } else if (value === 0) {
      item[2].classList.add("active");
    } else {
      item[1].classList.add("active");
    }
    filterFunction(value);
    // return value;
  }

  useEffect(() => {
    changeState(position);
    // eslint-disable-next-line
  }, [position]);

  return (
    <div className="col mb-3" id="subheader">
      <ul className="nav nav-tabs nav-overflow header-tabs">
        <li className="nav-item">
          <button
            className="nav-link text-nowrap subheader-item menu-events"
            onClick={(e) => changeState("all")}
          >
            {t("events:eventList.navigationTabs.allEvents")}
            <span className="amount-menu">{totalEvents}</span>
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link text-nowrap subheader-item menu-events"
            onClick={(e) => changeState(1)}
          >
            <FontAwesomeIcon icon={faTicketAlt} className="icon-menu mr-2" />
            {t("events:eventList.navigationTabs.ticketOnly")}
            <span className="amount-menu">{totalEventTipoIngresso}</span>
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link text-nowrap subheader-item menu-events"
            onClick={(e) => changeState(0)}
          >
            <FontAwesomeIcon icon={faWineBottle} className="mr-2" />
            {t("events:eventList.navigationTabs.a&bOnly")}
            <span className="amount-menu">{totalEventTipoAeB}</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
export default EventsHeader;
