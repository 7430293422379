import React, { useEffect } from "react";
import New_api from "services/new_api";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import Modal from "react-modal";
import { DatePicker } from "components/datepicker";
import moment from "moment-timezone";
import Select from "react-select";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  name: yup.string().required("É obrigatório inserior um nome"),
  chart_id: yup.string().required("É obrigatório selecionar um mapa"),
  start_date: yup.date().required("É obrigatório inserir uma data"),
});

export function ModalAssociateMap(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const mapOptions = props.mapList?.map((map) => ({
    value: map.key,
    label: map.name,
  }));

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("name", props.eventInfos.name);
    setValue("start_date", new Date(props.eventInfos.eventDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAssociateOpen]);

  const onSubmit = (data) => {
    const params = {
      name: data.name,
      start_date: data.start_date
        ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss")
        : null,
      chart_id: data.chart_id,
    };

    New_api.post(`/events/${props.id}/seats/associate`, params)
      .then((res) => {
        Swal.fire({
          title: "Sucesso!",
          icon: "success",
          html: "O mapa foi associado com sucesso.</br>Agora é necessário vincular os setores do ingresso as categorias do mapa!",
          confirmButtonText: "Ok",
        }).then(() => {
          history.push(`/tickets/ingressos/evento?event_id=${props.id}`);
        });
        props.closeModal();
        props.handleEventKey(res.data.seats_integration_id);
        props.handleAssociationStatus(true);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro ao realizar a associação do lugar marcado. Por favor, tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <Modal
      isOpen={props.isAssociateOpen}
      onRequestClose={props.closeModal}
      className="shadow side-modal"
    >
      <Container fluid>
        <div className="card-header">
          <h4 className="card-header-title">Associar mapa</h4>
          <button type="button" className="close" onClick={props.closeModal}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: "5px" }}>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group controlId="name">
                <Form.Label>Nome do evento</Form.Label>
                <Form.Control type="text" {...register("name")} readOnly />
                {errors.name && (
                  <Form.Text className="text-danger">
                    {errors.name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="start_date">
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label={t("tickets:seatsmap.eventDate")}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      showTimeSelect
                      readOnly
                    />
                  )}
                />
                {errors.start_date && (
                  <Form.Text className="text-danger">
                    {errors.start_date.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group controlId="chart_id">
                <Form.Label>Mapa</Form.Label>
                <Controller
                  name="chart_id"
                  control={control}
                  render={() => (
                    <Select
                      placeholder="Selecione um mapa"
                      onChange={(selectedOption) => {
                        setValue("chart_id", selectedOption.value);
                      }}
                      options={mapOptions}
                    />
                  )}
                />
                {errors.chart_id && (
                  <Form.Text className="text-danger">
                    {errors.chart_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            Associar
          </Button>
        </Form>
      </Container>
    </Modal>
  );
}
