/* eslint-disable */
import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";
import ModalTransaction from "pages/bar-area/establishments-sold/modal-sales";
import Loading from "components/loading";
import moment from "moment-timezone";
import Api from "services/api.js";
import Swal from "sweetalert2";

import "./styles.css";
import ModalFilter from "./modal-filter";
import { useLanguageSettings } from "hooks/language-settings";

export default function EstablishmentSold() {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [paginate, setPaginate] = useState(false);
  const [establishment, setEstablishment] = useState();
  const [dataFilter, setDataFilter] = useState([]);
  const [paymenttypes, setPayments] = useState([]);
  const [caixas, setCaixas] = useState([]);
  const [types, setTypes] = useState([]);
  const [sold, setSold] = useState();
  const [rever, setRever] = useState(false);
  const [statusModalTransaction, setStatusModalTransaction] =
    useState("modal-hidden");
  const [statusModalFilter, setStatusModalFilter] = useState("modal-hidden");
  const [dataInit, setDataInit] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [allFilter, setAllFilter] = useState(false);
  const [money, setMoney] = useState(0);
  const [type, setType] = useState("");
  const [statusTransaction, setStatusTransaction] = useState("");
  const [infoSolds, setInfoSolds] = useState({
    type: null,
    caixa: null,
    payments_type: null,
    raw_method: null,
    command_identify: "",
  });
  const [paginationpage, setPagination] = useState({
    page: 1,
    perPage: 25,
  });

  const pagination = (typePage, numberPage, perPage) => {
    switch (typePage) {
      case "Initial":
        setPagination({
          page: numberPage,
          perPage: perPage,
        });
        break;
      case "left":
        numberPage--;
        if (numberPage <= 0) {
          setPagination({
            page: 1,
            perPage: perPage,
          });
        } else {
          setPagination({
            page: numberPage,
            perPage: perPage,
          });
        }
        break;
      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          setPagination({
            page: numberPage,
            perPage: perPage,
          });
        } else {
          numberPage++;
          setPagination({
            page: numberPage,
            perPage: perPage,
          });
        }
        break;

      case "lastPage":
        setPagination({
          page: numberPage,
          perPage: perPage,
        });
        break;
      default:
        setPagination({
          page: 1,
          perPage: 25,
        });
        break;
    }
    setPaginate(true);
  };

  const modalFilter = () => {
    setAllFilter(true);
    setStatusModalFilter("modal-show");
  };

  const clearFilter = () => {
    setMoney(null);
    setInfoSolds({
      type: null,
      caixa: null,
      payments_type: null,
    });
    setPagination({
      page: 1,
      perPage: 25,
    });
    setDataInit(null);
    setDataFim(null);
    setPaginate(true);
  };

  const filter = (infoSold, pagination, payload, raw_method) => {
    setInfoSolds({
      type: infoSold.type,
      caixa: infoSold.caixa,
      payments_type: infoSold.payments_type,
      command_identify: infoSold.command_identify,
    });

    setMoney(infoSold.total_value);
    setDataInit(payload.start);
    setDataFim(payload.end);

    document.getElementById("loading").classList.remove("esconde");
    Api.post(`solds/establishment/${event_id}`, {
      infoSold,
      pagination,
      payload,
      raw_method,
    })
      .then((res) => {
        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
        setDataFilter(res.data.data);
        //Encerra loading
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      })
      .finally(setStatusModalFilter("modal-hidden"));
  };
  useEffect(() => {
    const infoSold = {
      caixa: infoSolds.caixa ? infoSolds.caixa : "",
      type: infoSolds.type ? infoSolds.type : "",
      payments_type: infoSolds.payments_type ? infoSolds.payments_type : "",
      command_identify: infoSolds.command_identify
        ? infoSolds.command_identify
        : "",
      total_value: money
        ? money.replace(".", "").replace(",", "").replace("R$", "")
        : "",
    };

    const raw_method = infoSolds.raw_method ? infoSolds.raw_method : "";

    const pagination = {
      page: paginationpage.page,
      perPage: paginationpage.perPage,
    };

    const payload = {
      start: moment(dataInit).format("YYYY-MM-DD"),
      end: moment(dataFim).format("YYYY-MM-DD"),
    };

    Api.get("/getEvent/" + event_id)
      .then((res) => setEstablishment(res.data))
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    Api.post(`solds/establishment/${event_id}`, {
      infoSold,
      pagination,
      payload,
      raw_method,
    })
      .then((res) => {
        // setSolds(res.data);
        setDataFilter(res.data.data);
        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
        //Encerra loading
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    Api.get(`establishments/payment/type/${event_id}`)
      .then((res) => {
        setPayments(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    Api.get("caixas/event/" + event_id)
      .then((res) => {
        const newData = res.data.map((item) => ({
          ...item,
          value: item.caixa,
          caixa: "Caixa " + item.caixa,
        }));
        setCaixas(newData);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    Api.get(`types/event/${event_id}`)
      .then((res) => {
        setTypes(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    if (paginate) {
      setPaginate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id, paginate]);

  const carregaVenda = (sold_id, estorno, type, statusTransaction) => {
    Api.get("sold/" + sold_id)
      .then((res) => openModal(res.data, estorno, type, statusTransaction))
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const openModal = (dados, estorno, type, statusTransactionOpen) => {
    setSold(dados);
    setStatusModalTransaction("modal-show");
    setRever(estorno);
    setType(type);
    setStatusTransaction(statusTransactionOpen);
  };

  const closeModalTransaction = () => {
    setStatusModalTransaction("modal-hidden");
  };

  const closeModalFilter = () => {
    setStatusModalFilter("modal-hidden");
    setAllFilter(false);
  };
  const { currencyFormatter } = useLanguageSettings();

  return (
    <>
      <Loading title="aguarde..." />

      <PageHeader title="Transações" text={establishment?.name} />

      <div className="container-fluid mt-4" style={{ paddingBottom: "60px" }}>
        <div className="col-md-12">
          <div className="row de-flex justify-content-end">
            <div className="col-6 col-md-3">
              <div
                className="filter-button text-center"
                id="cleanFilters"
                onClick={clearFilter}
              >
                <span>
                  <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon> Limpar
                  filtros
                </span>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div
                className="filter-button text-center"
                id="filter-button-sales"
                onClick={modalFilter}
              >
                <span>
                  <FontAwesomeIcon icon={faFilter} /> Filtros
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">
          <div className="card">
            <div id="table-sales" className="table-responsive">
              <table className="table table-sm card-table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-left">
                      Data
                    </th>
                    <th scope="col" className="text-center">
                      Valor
                    </th>
                    <th scope="col" className="text-center">
                      Tipo
                    </th>
                    <th scope="col" className="text-center">
                      Origem
                    </th>
                    <th scope="col" className="text-center">
                      Pagamento
                    </th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Detalhes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataFilter.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {moment(item.date).format("DD/MM/YYYY") +
                          "-" +
                          item.time}
                      </td>
                      <td className="text-center">
                        {currencyFormatter(item.total_value)}
                      </td>
                      <td className="text-center">
                        <span className="badge badge-pill bg-dark-soft">
                          {item.type}
                        </span>
                      </td>
                      <td className="text-center">
                        {item.caixa ? "Caixa 00" + item.caixa : ""}
                      </td>
                      <td className="text-center">
                        {item.payments_type &&
                        item.checkMultiple !== "Múltiplo" ? (
                          <span>{item.payments_type}</span>
                        ) : item.checkMultiple === "Múltiplo" ? (
                          <span>{item.checkMultiple}</span>
                        ) : (
                          <span className="payment-none">sem pagamento</span>
                        )}
                      </td>
                      <td className="text-center">
                        <span
                          className="text-center"
                          style={!item.ativo ? { color: "red" } : {}}
                        >
                          {item.status === "confirmada" ? (
                            <span className="badge badge-pill bg-success">
                              confirmada
                            </span>
                          ) : (
                            <span className="badge badge-pill bg-danger">
                              estornada
                            </span>
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-table"
                          style={{ marginRight: 35, marginLeft: "auto" }}
                          onClick={() =>
                            carregaVenda(
                              item.type !== "estorno-consumo" &&
                                item.type !== "estorno-ticket" &&
                                item.type !== "estorno-recarga"
                                ? item.uuid
                                : item.parent_transaction_id,
                              null,
                              item.type,
                              item.status
                            )
                          }
                        >
                          <img
                            alt=""
                            src="../../icons/icon-plus.png"
                            className="icon-button fa-w-18 "
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="col-12">
                <div className="row d-flex justify-content-between datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2">Resultados por Página:</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) =>
                          pagination(
                            "Initial",
                            paginationpage.page,
                            (paginationpage.perPage = e.target.value)
                          )
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    {paginationpage.perPage * atualizaPaginaAtual >=
                    atualizaQuantPesquisa ? (
                      <span>
                        {" "}
                        Exibindo {atualizaQuantPesquisa} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        Exibindo {paginationpage.perPage *
                          atualizaPaginaAtual}{" "}
                        de {atualizaQuantPesquisa}{" "}
                      </span>
                    )}

                    <button
                      onClick={() =>
                        pagination("Initial", 1, paginationpage.perPage)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        pagination(
                          "left",
                          atualizaPaginaAtual,
                          paginationpage.perPage
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                    </span>

                    <button
                      onClick={() =>
                        pagination(
                          "right",
                          atualizaPaginaAtual,
                          paginationpage.perPage
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        pagination(
                          "lastPage",
                          atualizaUltimaPagina,
                          paginationpage.perPage
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalTransaction
        dados={sold}
        status={statusModalTransaction}
        reversed={rever}
        statusTransaction={statusTransaction}
        event_id={event_id}
        callback={closeModalTransaction}
      />

      <ModalFilter
        infoSolds={infoSolds}
        status={statusModalFilter}
        callback={closeModalFilter}
        function={filter}
        types={types}
        paymenttypes={paymenttypes}
        caixas={caixas}
        dataInit={dataInit}
        dataFim={dataFim}
        paginate={paginate}
        money={money}
        type={type}
        filter={allFilter}
      />
    </>
  );
}
