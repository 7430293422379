export const capitalizeEachFirstWord = (str, wordsToNotChange = []) => {
  if (wordsToNotChange.includes(str)) return str;
  const words = str.split(" ");
  return words
    .map((word) => {
      if (word === "de") return word;
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const capitalizeFirstWord = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
