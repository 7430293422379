import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

export default function CreateEventHelp() {
    return (
        <div className="content">
            <div className="wiki-body">
                <NavLink className="nav-link text-muted text-small" to="./docs/changelog.html">
                    <FontAwesomeIcon className="sidebar-icons" icon={faFileAlt} /> <span>Tutoriais e guias</span> <span className="badge badge-primary ml-auto">versão cliente</span>
                </NavLink>
                <hr></hr>
                <div className="wiki-title">Como criar um evento do zero?</div>

                <p>
                    <h2>1. Informações básicas</h2>
                    - Preencha as informações básicas do evento, como nome e banners.<br></br>
                    - Preencha a localização do evento, inserindo o logradouro (rua, avenida...) ou nome do local, o campo encontrará o endereço para você. Insira obrigatoriamente o número (ou "sem número", caso não haja número e o nome fantasia do local (apelido que aparecerá para os participantes), após isso clique em "Adicionar local".<br></br>
                    - Adicione a data de início e término do evento, e clique em "Adicionar data".
                    <br></br><br></br>
                    <b>INFORMAÇÕES IMPORTANTES:</b>
                    <br></br>
                    <b>Slug do evento:</b>Link que aparecera para as pessoas comprarem.<br></br>
                    <b>Visibilidade:</b>"Público" será visível para todos na página de compra, "Privado" será visível apenas para pessoas que entrarem pelo link do evento<br></br>
                    <b>Política de taxas:</b> "Repassar" configura a taxa para o comprador pagar, "Absorver" configura a taxa para o organizador pagar (fica embutida no preço do ingresso e o comprador não paga)
                    <hr></hr>
                </p>
                <p>
                    <h2>2. Setores, lotes e tipos</h2>
                    - Defina os setores: Setores são as divisões do evento, como pista, camarote ou open bar. Caso seu evento não tenha divisões de área, você pode cadastrar apenas um nome ou então algo como "setor único".<br></br>
                    - Defina os lotes: Nessa etapa você deve digitar e adicionar lotes, como por exemplo "promocional", "1º lote" e etc...Basta digita o nome e clicar na tecla enter de seu teclado ou em "adicionar lote".<br></br>
                    - Defina os tipos de ingresso: Por padrão já deixamos criados meia e inteira, mas você pode remover a opção de meia entrada ou até mesmo adicionar uma nova opção de desconto, basta inserir o nome e o percentual de desconto (ex: 50 = 50% de desconto)
                    <hr></hr>
                </p>
                <p>
                    <h2>3. Configurações gerais</h2>
                    Nessa etapa você pode desabilitar algum lote, categoria ou divisão de evento, caso tenha criado algo errado. Da mesma forma para eventos com mais de um setor, você pode por exemplo desabilitar a "meia entrada" para o "open bar" e deixar habilitada para a área de "pista".
                    <hr></hr>
                </p>
                <p>
                    <h2>4. Preços e quantidades</h2>
                    Nessa etapa você deverá obrigatoriamente inserir quantidades e valores para cada lote e setor, basta digitar a quantidade. Lembre-se que você pode alterar posteriormente esses valores em seu painel!
                </p>
            </div>
        </div>
    )
}