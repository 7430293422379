import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
// import MenuProdutor from "../../../components/headbarfinanceiroprodutor";
import Autocomplete from "../../../components/inputautocomplete";
import Api from "../../../services/api.js";
import Loading from "../../../components/loading";
import "./styles.css";
import Swal from "sweetalert2";



export default function FinanceiroProdutor(props) {
  const [eventos, setEventos] = useState([]);
  const [detalheEvento, setDetalheEvento] = useState({});
  const [tiraPesquisa, setTiraPesquisa] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("event_id");

  useEffect(() => {
    // let id = props.match.params.id;

    if (!id) {
      Api.get("users/events")
        .then(res => {
          setEventos(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })
        })
    } else {
      setTiraPesquisa(true);
      eventselect(id);
    }

    //Encerra loading
    document.getElementById("loading").classList.add("esconde");

  }, [id]);
  const eventselect = e => {
    document.getElementById("loading").classList.remove("esconde");
    Api.get("financial/balance/event/" + e)
      .then(res => {
        setDetalheEvento(res.data);
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(err => {
          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })

        document.getElementById("loading").classList.add("esconde");
      });
  };

  return (
    <>
      <Loading title="aguarde..." />
      <PageHeader
        title="Painel financeiro"
        text="Aqui você acompanha o resumo financeiro dos eventos."
      >
      </PageHeader>
      <div id="vgprod">
        {/* <MenuProdutor /> */}
        <div id="vgprodutor" className="mt-4 container-fluid">

          {!tiraPesquisa && 
          <div className="col-md-12 mb-4">
            <div className="card-search">
              <img src="./icons/link.svg" alt="" className="icon-search" />
              <Autocomplete suggestions={eventos} onSelectEvent={eventselect} placeholder={"Digite o evento aqui para pesquisar.."} />
            </div>
          </div>
          }
          {detalheEvento ? (
            detalheEvento.valor_conta ? (
              <div className="col-md-12">
                <div id="card" className="row">
                  <div className="col-lg-6 col-xl-3">
                    <div className="card valorconta card-one cards">
                      <p className="card-span">{detalheEvento?.valor_conta}</p>
                      <p className="card-subspan">
                        Valor em Conta
                        <span className="tips">
                          <img
                            src="./icons/information.svg"
                            alt=""
                          />
                          <span className="tips-content">
                            Valor total em vendas finalizadas.
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-3 mb-2">
                    <div className="card card-default">
                      <p className="card-span">{detalheEvento?.saldo_disponivel}</p>
                      <p className="card-subspan">
                        Saldo Diponível
                        <span className="tips">
                          <img
                            src="./icons/question.svg"
                            alt=""
                          />
                          <span className="tips-content">
                            Valor disponível para saque, conforme plano de recebimento.
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-3 mb-2">
                    <div className="card card-default">
                      <p className="card-span">{detalheEvento?.lancamentos_futuros}</p>
                      <p className="card-subspan">
                        Lançamentos futuros
                        <span className="tips">
                          <img
                            src="./icons/question.svg"
                            alt=""
                          />
                          <span className="tips-content">
                            Valor a compensar futuramente, conforme plano de recebimento.
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-3 mb-2">
                    <div className="card card-default">
                      <p className="card-span">{detalheEvento?.saldo_bloqueado}</p>
                      <p className="card-subspan">
                        Saldo bloqueado
                        <span className="tips">
                          <img
                            src="./icons/question.svg"
                            alt=""
                          />
                          <span className="tips-content">
                            Valor bloqueado em conta. Esse valor é composto por custo de equipamentos não devolvidos, valores ainda retidos e outros.
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="cards financeiro-btn row">
                  <div className="col-md-3 mb-2"></div>
                  <div className="col-md-3 mb-2">
                    <button className="btn c1 dev-feature" type="button">
                      solicitar transferência
                    </button>
                  </div>
                  <div className="col-md-3 mb-2">
                    <button className="btn c3 dev-feature" type="button">
                      solicitar antecipação
                    </button>
                  </div>
                  <div className="col-md-3 mb-2">
                    <button className="btn c3 dev-feature" type="button">
                      ver detalhes
                    </button>
                  </div>
                </div>

                <h6 className="tituloprod">
                  Resumo financeiro
                  <span className="tips">
                    <img
                      src="./icons/question.svg"
                      alt=""
                      className="fluid imagem-quest"
                    />
                    <div className="tips-content">
                      Resumo financeiro do evento, com recebimentos, repasses e custos.
                    </div>
                  </span>
                </h6>
                <div className="conteudovgprod">
                  <div className="intable">
                    <h6>
                      Vendas online
                      <span className="tips">
                        <img
                          src="./icons/question.svg"
                          alt=""
                          className="fluid imagem-quest"
                        />
                        <div className="tips-content">
                          Vendas realizadas pela internet através do painel da tiqueteira.
                        </div>
                      </span>
                    </h6>
                    <h5>{detalheEvento?.vendas_online?.value}</h5>
                  </div>
                  <div className="intable-values">
                    <p className="canetazul coluna1">
                      {detalheEvento?.vendas_online?.type[0]?.nome}
                    </p>
                    <p className="azulcaneta coluna2">
                      {detalheEvento?.vendas_online?.type[0]?.qty}
                    </p>
                    <p className="canetazul coluna3">
                      {detalheEvento?.vendas_online?.type[0]?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.vendas_online?.type[0]?.type[0]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.vendas_online?.type[0]?.type[0]?.qty}
                    </p>
                    <p className="coluna3">
                      {detalheEvento?.vendas_online?.type[0]?.type[0]?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.vendas_online?.type[0]?.type[1]?.nome}
                    </p>
                    <p className="coluna2">
                      {" "}
                      {detalheEvento?.vendas_online?.type[0]?.type[1]?.qty}
                    </p>
                    <p className="coluna3">
                      {detalheEvento?.vendas_online?.type[0]?.type[1]?.value}
                    </p>
                  </div>
                  <hr style={{ marginLeft: "15px", marginRight: "15px" }} />
                  <div className="intable-values">
                    <p className="canetazul coluna1">
                      {detalheEvento?.vendas_online?.type[1]?.nome}
                    </p>
                    <p className="azulcaneta coluna2">
                      {detalheEvento?.vendas_online?.type[1]?.qty}
                    </p>
                    <p className="canetazul coluna3">
                      {detalheEvento?.vendas_online?.type[1]?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.vendas_online?.type[1]?.type[0]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.vendas_online?.type[1]?.type[0]?.qty}
                    </p>
                    <p className="coluna3">
                      {detalheEvento?.vendas_online?.type[1]?.type[0]?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.vendas_online?.type[1]?.type[1]?.nome}
                    </p>
                    <p className="coluna2">
                      {" "}
                      {detalheEvento?.vendas_online?.type[1]?.type[1]?.qty}
                    </p>
                    <p className="coluna3">
                      {detalheEvento?.vendas_online?.type[1]?.type[1]?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.vendas_online?.type[1]?.type[2]?.nome}
                    </p>
                    <p className="coluna2">
                      {" "}
                      {detalheEvento?.vendas_online?.type[1]?.type[2]?.qty}
                    </p>
                    <p className="coluna3">
                      {detalheEvento?.vendas_online?.type[1]?.type[2]?.value}
                    </p>
                  </div>

                  <div className="intable">
                    <h6>
                      Vendas físicas
                      <span className="tips">
                        <img
                          src="./icons/question.svg"
                          alt=""
                          className="fluid imagem-quest"
                        />
                        <div className="tips-content">
                          Vendas impressas realizadas em pontos de venda da tiqueteira ou em pontos de venda do produtor.
                        </div>
                      </span>
                    </h6>
                    <h5>{detalheEvento?.vendas_fisicas?.value}</h5>
                  </div>
                  <div className="intable-values">
                    <p
                      className="canetazul coluna1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {detalheEvento?.vendas_fisicas?.type[0]?.receita?.nome}
                    </p>
                    <p className="azulcaneta coluna2">
                      {detalheEvento?.vendas_fisicas?.type[0]?.receita?.qty}
                    </p>
                    <p className="canetazul coluna3">
                      {detalheEvento?.vendas_fisicas?.type[0]?.receita?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[0]
                          ?.nome
                      }
                    </p>
                    <p className="coluna2">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[0]
                          ?.qty
                      }
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[0]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[1]
                          ?.nome
                      }
                    </p>
                    <p className="coluna2">
                      {" "}
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[1]
                          ?.qty
                      }
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[1]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[2]
                          ?.nome
                      }
                    </p>
                    <p className="coluna2">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[2]
                          ?.qty
                      }
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.receita?.type[2]
                          ?.value
                      }
                    </p>
                  </div>
                  <hr style={{ marginLeft: "15px", marginRight: "15px" }} />
                  <div className="intable-values">
                    <p
                      className="bermelho coluna1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {detalheEvento?.vendas_fisicas?.type[0]?.despesa?.nome}
                    </p>
                    <p
                      className="azulcaneta coluna2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {detalheEvento?.vendas_fisicas?.type[0]?.despesa?.qty}
                    </p>
                    <p className="bermelho coluna3">
                      {detalheEvento?.vendas_fisicas?.type[0]?.despesa?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[0]
                          ?.nome
                      }
                    </p>
                    <p className="coluna2">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[0]
                          ?.qty
                      }
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[0]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[1]
                          ?.nome
                      }
                    </p>
                    <p className="coluna2">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[1]
                          ?.qty
                      }
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[1]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[2]
                          ?.nome
                      }
                    </p>
                    <p className="coluna2">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[2]
                          ?.qty
                      }
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.vendas_fisicas?.type[0]?.despesa?.type[2]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable">
                    <h6>
                      Bilheteria
                      <span className="tips">
                        <img
                          src="./icons/question.svg"
                          alt=""
                          className="fluid imagem-quest"
                        />
                        <div className="tips-content">
                          Valor em vendas na bilheteria do evento.
                        </div>
                      </span>
                    </h6>
                    <h5>{detalheEvento?.bilheteria?.value}</h5>
                  </div>
                  <div className="intable-values">
                    <p
                      className="canetazul coluna1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {detalheEvento?.bilheteria?.type[0]?.receita?.nome}
                    </p>
                    <p className="azulcaneta coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.qty}
                    </p>
                    <p className="canetazul coluna3">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.type[0]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.type[0]?.qty}
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.bilheteria?.type[0]?.receita?.type[0]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.type[1]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.type[1]?.qty}
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.bilheteria?.type[0]?.receita?.type[1]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.type[2]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.receita?.type[2]?.qty}
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.bilheteria?.type[0]?.receita?.type[2]
                          ?.value
                      }
                    </p>
                  </div>
                  <hr style={{ marginLeft: "15px", marginRight: "15px" }} />
                  <div className="intable-values">
                    <p
                      className="bermelho coluna1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.nome}
                    </p>
                    <p
                      className="azulcaneta coluna2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.qty}
                    </p>
                    <p className="bermelho coluna3">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.value}
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.type[0]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.type[0]?.qty}
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.bilheteria?.type[0]?.despesa?.type[0]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.type[1]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.type[1]?.qty}
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.bilheteria?.type[0]?.despesa?.type[1]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable-values2">
                    <p className="coluna11">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.type[2]?.nome}
                    </p>
                    <p className="coluna2">
                      {detalheEvento?.bilheteria?.type[0]?.despesa?.type[2]?.qty}
                    </p>
                    <p className="coluna3">
                      {
                        detalheEvento?.bilheteria?.type[0]?.despesa?.type[2]
                          ?.value
                      }
                    </p>
                  </div>
                  <div className="intable">
                    <h6>(=) Receita líquida total</h6>
                    <h5>{detalheEvento?.receita_liquida?.value}</h5>
                  </div>
                  <div className="intable bermelho">
                    <h6>
                      (-) Repasses realizados
                      <span className="tips">
                        <img
                          src="./icons/question.svg"
                          alt=""
                          className="fluid imagem-quest"
                        />
                        <div className="tips-content">
                          Transferências realizadas e que são abatidas dos recursos do evento.
                        </div>
                      </span>
                    </h6>
                    <h5>{detalheEvento?.repasses?.value}</h5>
                  </div>
                  <div className="intable-values">
                    <p
                      className="azulcaneta coluna1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Favorecido
                    </p>
                    <p className="azulcaneta coluna2">Data</p>
                    <p className="azulcaneta coluna2">Valor</p>
                    <p className="azulcaneta coluna2">Antecipação</p>
                    <p className="azulcaneta coluna2">TED</p>
                    <p className="azulcaneta coluna3">Valor</p>
                  </div>

                  {detalheEvento?.repasses?.favorecidos.map((favorecido, i) => (
                    <div key={i} className="intable-values2">
                      <p className="coluna11">
                        <img
                          src="./icons/file.png"
                          alt=""
                          style={{ width: "10px" }}
                        />{" "}
                        {favorecido.nome}
                      </p>
                      <p className="coluna2">{favorecido.data}</p>
                      <p className="coluna3">{favorecido.valor}</p>
                      <p className="coluna3">{favorecido.antecipacao}</p>
                      <p className="coluna3">{favorecido.ted}</p>
                      <p className="coluna3">{favorecido.valor}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
