import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import React from "react";
import { finalityKeys } from "services/constants";
import { formatCurrency } from "utils";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "utils/date-formatter";
import { DropdownBtn } from "./components/dropdown";

export const useBorderoHelpers = ({ currency, handleEdit, handleDelete }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");
  const formatDate = (date) =>
    dateFormatter(new Date(date), { locale: language });
  const centsCurrencyFormatter = (value) =>
    formatCurrency(value, {
      isCents: true,
      currency: currency,
    });
  const deducationsHeaderTranslation = {
    costsTotal: t("financialStatement.costsTotal"),
    otherExpensesTotal: t("financialStatement.otherExpenses"),
    deductionsTotal: t("financialStatement.totalDeductions"),
  };
  const deductionsTooltips  = {
    "Online para terceiros": t("financialStatement.onlineWithdrawTooltip"),
    "PDV para terceiros": t("financialStatement.POSWithdrawTooltip"),
    "Cortesia Online": t("financialStatement.onlineCourtesyTooltip"),
    "Crédito (POS)": t("financialStatement.posCreditTaxTooltip"),
    "Débito (POS)": t("financialStatement.posDebitTaxTooltip"),
    "PIX (POS)": t("financialStatement.posPixTaxTooltip"),
    "Impressão de Ingresso": t("financialStatement.ticketPrintTooltip"),
    "Impressão de Cortesia": t("financialStatement.courtesyPrintTooltip"),
    "Cancelamento de Ingresso Impresso": t("financialStatement.canceledTicketPrintTooltip"),
    "Antecipação (POS)": t("financialStatement.posAnticipationTooltip"),
    "Antecipação (Online)": t("financialStatement.onlineAnticipationTooltip"),
    "Ingresso online em dinheiro (comissários)": t("financialStatement.onlineCashTooltip"),
    costsTotal: t("financialStatement.costsTotalTooltip"),
    deductionsTotal: t("financialStatement.totalDeductionsTooltip"),
  }

  const deductionRowsTranslations = {
    "Online para terceiros": t("financialStatement.forThirdPartyOnline"),
    "PDV para terceiros": t("financialStatement.forThirdPartyPDV"),
    "Cortesia Online": t("financialStatement.onlineCourtesy"),
    "Crédito (POS)": t("financialStatement.posCredit"),
    "Débito (POS)": t("financialStatement.posDebit"),
    "PIX (POS)": t("financialStatement.posPix"),
    "Impressão de Ingresso": t("financialStatement.ticketPrinting"),
    "Impressão de Cortesia": t("financialStatement.courtesyPrinting"),
    "Cancelamento de Ingresso Impresso": t("financialStatement.canceledTicketPrinting"),
    "Antecipação (POS)": t("financialStatement.posAnticipation"),
    "Antecipação (Online)": t("financialStatement.onlineAnticipation"),
    "Ingresso online em dinheiro (comissários)": t("financialStatement.onlineCashTicketSale"),
    "Taxas de Pagamento": t("financialStatement.paymentTax"),
    "Emissão de Ingressos": t("financialStatement.ticketEmission"),
    "Saque": t("financialStatement.withdraw"),
    "Total": t("financialStatement.total"),
  }

  const deductionsHelpers = {
    mapHeader: (data) =>
      Object.entries(data).map(([key, value]) => {
        return {
          id: key,
          amount: centsCurrencyFormatter(value),
          description: deducationsHeaderTranslation[key],
          tooltip: deductionsTooltips[key],
        };
      }),
    
    mapOtherCosts: (apiData) => {
        const { costs, totals } = apiData;
        const rows = costs.reduce((acc, cost) => {
          acc.push({
            name: deductionRowsTranslations[cost.cost] || cost.cost,
            quantity: cost.qty,
            tax: cost.tax,
            total: centsCurrencyFormatter(cost.total),
            isHeader: true,
          });
  
          acc.push(
            ...cost.lines.map(
              ({
                name,
                qty,
                tax,
                total,
                parsedTax
              }) => {
                return {
                  name: deductionRowsTranslations[name],
                  quantity: qty,
                  tax: parsedTax || tax,
                  total: centsCurrencyFormatter(total),
                  tooltip: deductionsTooltips[name]
                }
              }
            )
          );
          return acc;
        }, []);
  
        return {
          columns: [
            {
              title: t("financialStatement.otherCosts"),
              colSpan: 4,
              align: "left",
            },
            {
              title: t("financialStatement.quantity"),
              colSpan: 1,
              align: "right",
            },
            {
              title: t("financialStatement.taxOrCost"),
              colSpan: 1,
              align: "right",
            },
            {
              title: t("financialStatement.totalDiscount"),
              colSpan: 1,
              align: "right",
            },
          ],
          rows,
          total: {
            quantity: totals.quantity,
            tax: "-",
            total: centsCurrencyFormatter(totals.total),
        },
      };
    },

    mapTables: (data) => {
      const otherExpensesRows = data.otherExpenses.expenses.map((expense) => {
        return {
          name: expense.name,
          quantity: expense.quantity,
          unitValue: centsCurrencyFormatter(expense.value),
          total: centsCurrencyFormatter(expense.totalValue),
          options: () => (
            <DropdownBtn
              options={[
                {
                  label: t("financialStatement.edit"),
                  action: () => handleEdit(expense),
                  leftIcon: <Edit />,
                },
                {
                  label: t("financialStatement.delete"),
                  action: () => handleDelete(expense.id),
                  leftIcon: <Delete />,
                },
              ]}
            />
          ),
        };
      });

      const anticipationRows = data.negociationCosts.anticipations.map((anticipation) => {
        return {
          name: deductionRowsTranslations[anticipation.name],
          totalValue: centsCurrencyFormatter(anticipation.total),
          cost: anticipation.parsedTax,
          totalDiscount: centsCurrencyFormatter(anticipation.value),
          tooltip: deductionsTooltips[anticipation.name]
        };
      })

      const otherExpensesTable = {
        columns: [
          {
            title: t("financialStatement.eventExpenses"),
            colSpan: 8,
            align: "left",
          },
          {
            title: t("financialStatement.quantity"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.unitValue"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 3,
            align: "right",
          },
          {
            title: "",
            colSpan: 1,
            align: "center",
          },
        ],
        rows: otherExpensesRows,
        total: {
          totalQuantity: data.otherExpenses.total.totalQuantity,
          totalUnitValue: "-",
          totalValue: centsCurrencyFormatter(
            data.otherExpenses.total.totalValue
          ),
          btn: "",
        },
      };

      const anticipationtable = {
        columns: [
          {
            title: t("financialStatement.anticipationCost"),
            colSpan: 8,
            align: "left",
          },
          {
            title: t("financialStatement.anticipationValue"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.taxOrCost"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.totalDiscount"),
            colSpan: 3,
            align: "right",
          },
        ],
        rows: anticipationRows,
        total: {
          totalValue: centsCurrencyFormatter(
            data.negociationCosts.totals.total
          ),
          totalTax: "-",
          totalDiscount: centsCurrencyFormatter(
            data.negociationCosts.totals.value
          ),
        },
      };

      const otherCosts = deductionsHelpers.mapOtherCosts(data.otherCosts);

      return {
        tables: [
          anticipationtable,
          otherCosts, 
          otherExpensesTable,
        ],
      };
    },
  };

  const transferHeaderTranslation = {
    totalValueTransfersCompleted: t(
      "financialStatement.totalValueTransfersCompleted"
    ),
    totalValueTransfersPending: t(
      "financialStatement.totalValueTransfersPending"
    ),
    totalWithdrawalAvailable: t("financialStatement.totalWithdrawalAvailable"),
  };

  const transferHelpers = {
    mapHeader: (data) =>
      Object.entries(data).map(([key, value]) => {
        return {
          id: key,
          amount: centsCurrencyFormatter(value),
          description: transferHeaderTranslation[key],
        };
      }),
    mapTables: (data, total) => {
      const rows = data.map((transfer) => {
        return {
          type: finalityKeys[transfer.finality],
          requester: transfer.requester,
          beneficiary: transfer.recipient,
          requestDate: formatDate(new Date(transfer.requestDate)),
          paymentDate: formatDate(new Date(transfer.paymentDate)),
          value: centsCurrencyFormatter(transfer.amount),
        };
      });
      return {
        tables: [
          {
            columns: [
              {
                title: t("financialStatement.identification"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.requester"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.beneficiary"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.paymentDate"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.requestDate"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.receivedValue"),
                colSpan: 1,
                align: "right",
              },
            ],
            rows,
            total: {
              empty: "",
              emptyOne: "",
              emptyTwo: "",
              emptyThree: "",
              total: centsCurrencyFormatter(total),
            },
          },
        ],
      };
    },
  };

  const salesHeaderTranslation = {
    totalSalesRevenue: t("financialStatement.totalSalesRevenue"),
    totalParticipants: t("financialStatement.ticketsEmitted"),
    checkin: "%" + t("financialStatement.checkin"),
    tkm: t("financialStatement.tkm"),
    totalOrders: t("financialStatement.totalOrders"),
  };
  const salesFormatter = {
    totalSalesRevenue: (val) => centsCurrencyFormatter(val),
    totalParticipants: (val) => val.toLocaleString(language),
    checkin: (val, checkInParticipation) => `${val} (${checkInParticipation}%)`,
    tkm: (val) => centsCurrencyFormatter(val),
    totalOrders: (val) => val.toLocaleString(language),
  };

  const salesTooltip = {
    totalSalesRevenue: t("financialStatement.totalValueOfTicketsSold"),
    totalParticipants: t("financialStatement.totalNumberOfTicketsSoldToolTip"),
    checkin: t("financialStatement.checkinToolTip"),
    tkm: t("financialStatement.tkmToolTip"),
    totalOrders: t("financialStatement.totalOrdersToolTip"),
    serviceFeeRebateToolTip: t("financialStatement.serviceFeeRebateToolTip"),
    processingFeeRebateToolTip: t("financialStatement.processingFeeRebateToolTip"),
  };

  const salesHelper = {
    mapHeader: (data) => {
      const mapOrder = {
        totalSalesRevenue: data.totalSalesRevenue,
        totalParticipants: data.totalParticipants,
        checkin: data.checkin,
        tkm: data.tkm,
        totalOrders: data.totalOrders,
        checkinParticipation: data.checkinParticipation,
      };
      return Object.entries(mapOrder)
        .map(([key, value]) => {
          if (key === "checkinParticipation") return undefined;
          const formatter = salesFormatter[key];
          const checkInParticipation = mapOrder.checkinParticipation
            ? mapOrder.checkinParticipation.toFixed(2)
            : 0;
          const amount = formatter
            ? formatter(value, checkInParticipation)
            : value;
          return {
            id: key,
            amount,
            description: salesHeaderTranslation[key],
            tooltip: salesTooltip[key],
          };
        })

        .filter(Boolean);
    },
    mapTables: (data) => {
      const byLotTable = salesHelper.mapByLot(data.salesDataByLot);
      const bySectorTable = salesHelper.mapBySector(data.salesDataBySector);
      const byPaymentMethods = salesHelper.mapByPaymentMethods(
        data.salesDataByPaymentMethods
      );
      const byPaymentMethodsOrigin = salesHelper.mapByPaymentMethodsOrigin(
        data.salesDataByPaymentOrigin
      );      
      const otherRevenues = {
        columns: [
          {
            title: t("financialStatement.identification"),
            colSpan: 10,
            align: "left",
          },
          {
            title: t("financialStatement.receivedValue"),
            colSpan: 2,
            align: "right",
          },
        ],
        rows: [
          {
            name: t("financialStatement.serviceFeeRebate"),
            value: centsCurrencyFormatter(data.otherRevenues.serviceFeeRebait),
            tooltip: salesTooltip["serviceFeeRebateToolTip"],
          },
          {
            name: t("financialStatement.processingFeeRebate"),
            value: centsCurrencyFormatter(data.otherRevenues.processingFeeRebait),
            tooltip: salesTooltip["processingFeeRebateToolTip"],
          },
        ],
        total: {
          total: centsCurrencyFormatter(data.otherRevenues.totals),
        },
      };
      return {
        tables: [
          byPaymentMethodsOrigin,
          byPaymentMethods,
          byLotTable,
          bySectorTable,
          otherRevenues,
        ],
      };
    },
    mapByLot: (data) => {
      const rows = data.lots.map((lot) => {
        return {
          lot: lot.name,
          total: centsCurrencyFormatter(lot.total),
          quantity: lot.qty.toLocaleString("pt-BR"),
          sold: lot.soldQty.toLocaleString("pt-BR"),
          participation: `${lot.participation.toFixed(2)}%`,
          checkIn: `${lot.checkin} (${lot.checkinPercentage.toFixed(2)}%)`,
        };
      });
      return {
        columns: [
          {
            title: t("financialStatement.lot"),
            colSpan: 2,
            align: "left",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "center",
          },
          {
            title: t("financialStatement.qty"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.sold"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participation"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.checkin").toUpperCase(),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          total: centsCurrencyFormatter(data.total.total),
          quantidade: data.total.qty.toLocaleString(language),
          sold: data.total.soldQty.toLocaleString(language),
          participation: `${data.total.participation.toFixed(2)}%`,
          checkIn: `${
            data.total.checkin
          } (${data.total.checkinPercentage.toFixed(2)}%)`,
        },
      };
    },
    mapBySector: (data) => {
      const rows = data.sectors.map((sector) => {
        return {
          sector: sector.name,
          total: centsCurrencyFormatter(sector.total),
          quantity: sector.totalSold.toLocaleString(language),
          courtesies: sector.totalCourtesies.toLocaleString(language),
          participation: `${sector.participation.toFixed(2)}%`,
          checkIn: `${sector.checkin} (${sector.checkinPercentage.toFixed(
            2
          )}%)`,
        };
      });

      return {
        columns: [
          {
            title: t("financialStatement.sector"),
            colSpan: 2,
            align: "left",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "center",
          },
          {
            title: t("financialStatement.sold"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.courtesies"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participation"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.checkin").toUpperCase(),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          total: centsCurrencyFormatter(data.total.total),
          quantity: data.total.totalSold.toLocaleString(language),
          courtesies: data.total.totalCourtesies.toLocaleString(language),
          participation: `${data.total.participation.toFixed(2)}%`,
          checkIn: `${
            data.total.checkin
          } (${data.total.checkinPercentage.toFixed(2)}%)`,
        },
      };
    },

    mapByPaymentMethods: (data) => {
      const rows = data.paymentMethods.map((paymentMethod) => {
        return {
          paymentMethod: paymentMethod.paymentType,
          total: centsCurrencyFormatter(paymentMethod.totalValue),
          quantity: paymentMethod.amount.toLocaleString(language),
          participation: `${paymentMethod.participation.toFixed(2)}%`,
          checkIn: `${
            paymentMethod.checkIn
          } (${paymentMethod.checkInPercentage.toFixed(2)}%)`,
        };
      });

      return {
        columns: [
          {
            title: t("financialStatement.paymentMethod"),
            colSpan: 4,
            align: "left",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.qty"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participation"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.checkin").toUpperCase(),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          total: centsCurrencyFormatter(data.total.totalValue),
          quantity: data.total.amount.toLocaleString(language),
          participation: `${data.total.participation.toFixed(2)}%`,
          checkIn: `${
            data.total.checkIn
          } (${data.total.checkInPercentage.toFixed(2)}%)`,
        },
      };
    },

    mapByPaymentMethodsOrigin: (apiData) => {
      const { origins, totals } = apiData;
      const rows = origins.reduce((acc, origin) => {
        acc.push({
          name: origin.origin,
          total: centsCurrencyFormatter(origin.total),
          amount: origin.amount,
          participation: `${origin.participation?.toFixed(2)}%`,
          checkIn: `${origin.checkin} (${origin.checkinPercentage?.toFixed(
            2
          )}%)`,
          isHeader: true,
        });

        acc.push(
          ...origin.lines.map(
            ({
              paymentType,
              totalValue,
              amount,
              participation,
              checkin,
              checkinPercentage,
            }) => ({
              name: paymentType,
              total: centsCurrencyFormatter(totalValue),
              amount,
              participation: `${participation.toFixed(2)}%`,
              checkIn: `${checkin} (${checkinPercentage.toFixed(2)}%)`,
            })
          )
        );
        return acc;
      }, []);

      return {
        columns: [
          {
            title: t("financialStatement.paymentMethodOrigin"),
            colSpan: 4,
            align: "left",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.qty"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participation"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.checkin"),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          total: centsCurrencyFormatter(totals.total),
          amount: totals.amount,
          participation: `${totals.participation.toFixed(2)}%`,
          checkIn: `${totals.checkin} (${totals.checkinPercentage.toFixed(
            2
          )}%)`,
        },
      };
    },
  };

  return {
    deductionsHelpers,
    transferHelpers,
    salesHelper,
  };
};
