import React from "react";
import "./styles.css";
import Lottie from "react-lottie";
import animationData from "../lotties/load.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loading = ({ title }) => (
  <div id="loading" style={{ height: "100vh", width: "100vw" }}>
    <div id="loading-symbol">
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
    <p className="loading-title">{title}</p>
  </div>
);

export default Loading;
