import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-cycle
import Routes from "./routes";
import Sidebar from "./components/sidebar";
import Headerbar from "./components/headerbar";
import Bottombar from "./components/bottombar";
import BottomEstablishment from "components/bottomEstablishment";
import SupportWhatsapp from "components/supportWhatsapp";

import "./App.css";
// WHITELABEL CUSTOM
import "./style-whitelabel.css";
import {
  barSharedPaths,
  commonSharedPaths,
  ticketsSharedPaths,
} from "components/sidebar/sidebarItem";
// import "./style-master.css";
import { useLanguageSettings } from "hooks/language-settings";
import { CurrencyEventProvider } from "contexts/currency-event-context";

// eslint-disable-next-line no-undef
let { pathname } = window.location;
// eslint-disable-next-line no-undef
const { location } = window;
// eslint-disable-next-line no-undef
const { addEventListener, removeEventListener, getElementById } = document;
let classDiv = "wrapper";
let sideLog = 0;

// Hook
export function useOnClickOutside(ref, handler) {
  React.useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    addEventListener("mousedown", listener);
    addEventListener("touchstart", listener);
    return () => {
      removeEventListener("mousedown", listener);
      removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export function verifyPath() {
  // primeira verificação
  setTimeout(() => {
    pathname = location.pathname;

    if (
      pathname !== "/" &&
      pathname !== "/register" &&
      pathname !== "/validatoken"
    ) {
      if (pathname.includes("/ingressos/dashboard")) {
        if (sideLog === 1) {
          // sideevent = true;
          ReactDOM.render(<App />, getElementById("root"));
          sideLog = 2;
        }
      } else {
        // sideevent = false;
      }
      classDiv = "wrapper-light";
      if (sideLog === 0) {
        ReactDOM.render(<App />, getElementById("root"));
        sideLog++;
      }
    } else {
      classDiv = "wrapper";
      sideLog = 0;
    }
    // }
  }, "100");
}

function getSupportWhatsapp(path) {
  const user = JSON.parse(localStorage.getItem("user") || null);
  const userIsComplete = user && user.complete_register === 1;

  // PÁGINAS COM EXCEÇÃO = NÃO EXIBE SUPORTE
  if (
    // CASO ENTRE NO INDEX E NÃO ESTEJA LOGADO
    // eslint-disable-next-line no-undef
    (path === "/" && !user) ||
    // CASO ESTEJA EM UMA DAS OUTRAS PÁGINAS SEM SUPORTE
    path === "/complete/login" ||
    path === "/register" ||
    path === "/404" ||
    path === "/termos-de-uso"
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  // CASO NÃO SEJA NENHUMA DELAS E ESTEJA LOGADO = EXIBE SUPORTE
  // eslint-disable-next-line no-undef
  if (userIsComplete) {
    return <SupportWhatsapp />;
  }
}

function App() {
  const { updateDefaultSettings } = useLanguageSettings();
  useEffect(() => {
    updateDefaultSettings().then((res) => res);
    //eslint-disable-next-line
  }, []);
  const [path, setPath] = React.useState(location.pathname);
  const [sidebarClassName, setSidebarClassName] = useState("");
  const urlContainsCourtesies = window.location.href.includes("courtesies");
  const urlContainsSeatsMapsList =
    window.location.href.includes("seatsmapslist");
  const urlContainsLoggedUrls = [
    ...commonSharedPaths,
    ...ticketsSharedPaths,
    ...barSharedPaths,
  ].find(
    (path) =>
      (typeof path === "string" && window.location.pathname === path) ||
      (path instanceof RegExp && window.location.pathname.match(path))
  );

  // TODO: Procurar soluçao melhor para atualizar
  setInterval(() => {
    const currentPathName = location.pathname;
    if (path !== currentPathName) setPath(currentPathName);
  }, 1000);

  // EXIBIÇÃO CONDICIONAL DE SIDEBAR
  // eslint-disable-next-line consistent-return
  function getSidebar(path) {
    const user = JSON.parse(localStorage.getItem("user") || null);
    const userIsComplete = user && user.complete_register === 1;

    // PÁGINAS COM EXCEÇÃO = NÃO EXIBE SIDEBAR
    if (
      // CASO ENTRE NO INDEX E NÃO ESTEJA LOGADO
      // eslint-disable-next-line no-undef
      (path === "/" && !user) ||
      // CASO ESTEJA EM UMA DAS OUTRAS PÁGINAS SEM SIDEBAR
      path.includes("/participantes/checkin") ||
      path.includes("/evento/ingressos") ||
      path === "/complete/login" ||
      path === "/register" ||
      path === "/404" ||
      path === "/novo-evento" ||
      path === "/termos-de-uso" ||
      path === "/evento-help" ||
      path === "/validatoken" ||
      path === "/event/create" ||
      path === "/sign-in"
    ) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    // CASO NÃO SEJA NENHUMA DELAS E ESTEJA LOGADO = EXIBE SIDEBAR
    // eslint-disable-next-line no-undef
    if (userIsComplete || !!user?.is_admin) {
      return (
        <Sidebar
          setSidebarClassName={setSidebarClassName}
          sidebarClassName={sidebarClassName}
        />
      );
    }
  }

  return (
    <div className={classDiv}>
      <BrowserRouter>
        {getSidebar(path)}
        <CurrencyEventProvider>
          <div
            className={`container-index ${
              urlContainsCourtesies || urlContainsSeatsMapsList
                ? "desktop-table-overflow"
                : ""
            } ${urlContainsLoggedUrls ? "logged" : ""}`}
          >
            {location.pathname !== "/sign-in" &&
              location.pathname !== "/complete/login" &&
              location.pathname !== "/register" &&
              location.pathname.includes("/participantes/checkin") === false &&
              location.pathname !== "/404" &&
              location.pathname !== "/novo-evento" &&
              location.pathname.includes("/evento/ingressos") === false &&
              location.pathname !== "/termos-de-uso" &&
              location.pathname !== "/evento-help" &&
              location.pathname !== "/validatoken" &&
              location.pathname !== "/event/create" && (
                <Headerbar
                  path={path}
                  activeSide={() => setSidebarClassName("sidebar-call")}
                />
              )}
            <Routes />
          </div>
        </CurrencyEventProvider>

        {/* EXIBIÇÃO CONDICIONAL DO FOOTER = APENAS PARA PÁGINGAS DO DASHBOARD DE INGRESSO */}
        {location.pathname.includes("/tickets/dashboard") ||
        location.pathname.includes("/ingressos/") ||
        location.pathname.includes("/tickets/event-sales-date") ||
        location.pathname.includes("/comissarios/") ||
        location.pathname.includes("/tickets/checkin") ||
        location.pathname.includes("/tickets/seatsmapslist") ||
        location.pathname.includes("/tickets/financeiro-dashboard") ? (
          <Bottombar />
        ) : null}

        {/* EXIBIÇÃO CONDICIONAL DO FOOTER = APENAS PARA PÁGINGAS DO DASHBOARD DE ESTABELECIMENTO */}
        {location.pathname.includes("/bar/dashboard") ||
        location.pathname.includes("/bar/establishments-sold") ||
        location.pathname.includes("/bar/event-commands") ||
        location.pathname.includes("/bar/menu-event-new") ||
        location.pathname.includes("/bar/event-report") ||
        location.pathname.includes("/bar/filterCaixa") ? (
          <BottomEstablishment />
        ) : null}
      </BrowserRouter>

      <style>
        {`.react-datepicker {
          width: max-content;
        }`}
      </style>

      {getSupportWhatsapp(path)}
    </div>
  );
}

export default App;
