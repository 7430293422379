import React from "react";
import PropTypes from "prop-types";
import { FileDropzone } from "components/fileDropzone";
import styled from "styled-components";
import { ModalFooter } from "../modalFooter";
import { ReactComponent as FileArrowDown } from "../../../../../../assets/icons/fileArrowDown.svg";
import { useTranslation } from "react-i18next";

CourtesyUploader.propTypes = {
  handleDownloadTemplateClick: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({
    path: PropTypes.string,
  }),
  handleDownloadFileClick: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

const TextSection = ({ title, content }) => {
  return (
    <div className="pt-3">
      <SectionTitle>{title}</SectionTitle>
      <SectionContent>{content}</SectionContent>
    </div>
  );
};

export function CourtesyUploader({
  handleDownloadTemplateClick,
  onSelectFile,
  selectedFile,
  handleDownloadFileClick,
  handleSubmit,
}) {
  const { t } = useTranslation("tickets", {
    keyPrefix: "courtesies.courtesyUploader",
  });
  return (
    <Container>
      <div>
        <TextSection
          title={t("section.first.title")}
          content={t("section.first.text")}
        />
        <DownloadButton onClick={handleDownloadTemplateClick}>
          {t("downloadButton")}
          <FileArrowDown stroke="#0050C3" />
        </DownloadButton>
        <TextSection
          title={t("section.second.title")}
          content={t("section.second.text")}
        />
        <FileDropzone
          callback={onSelectFile}
          aria-describedby="inputGroupFileAddon01"
          id="event-banner"
          title={t("dropzoneTitle")}
          selectedFile={selectedFile}
          handleDownloadFileClick={handleDownloadFileClick}
          acceptedFileTypes={{
            "text/csv": [".csv"],
          }}
        />
      </div>
      <ModalFooter
        handleClick={handleSubmit}
        buttonText={t("submitButton")}
        isValid={!!selectedFile}
      />
    </Container>
  );
}

const SectionTitle = styled.p`
  font-size: 1.125rem;
  font-weight: bolder;
  color: #2f2e33;
`;

const SectionContent = styled.p`
  font-size: 1rem;
  color: #525059;
`;

const DownloadButton = styled.button`
  border: 1px solid #0050c3;
  :hover {
    background: #e6eef9;
  }
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #0050c3;
  background-color: white;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;
