import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons";
import { faTicketAlt, faReceipt } from "@fortawesome/free-solid-svg-icons";

function PaymentButtons(props) {
  const setIcon = (value) => {
    switch (value) {
      case "credit_card":
        return <FontAwesomeIcon icon={faCreditCard} className="mr-2" />;
      case "pix":
        return <img alt="pix-form" src="/cartoes/pixx.svg" />;
      case "debit_card":
        return <FontAwesomeIcon icon={faCreditCard} className="mr-2" />;
      case "cash":
        return <FontAwesomeIcon icon={faMoneyBillAlt} className="mr-2" />;
      case "voucher":
        return <FontAwesomeIcon icon={faTicketAlt} className="mr-2" />;
      case "picpay":
        return <img alt="picpay-form" src="/cartoes/picpay.svg" />;
      case "boleto":
        return <FontAwesomeIcon icon={faReceipt} className="mr-2" />;
      default:
        return "";
    }
  };

  return (
    <button id={`div-${props.method}`} className="btn-type">
      {setIcon(props.method)}
      {props.name}
    </button>
  );
}

export default PaymentButtons;
