import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import "../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faCheck, faCamera } from "@fortawesome/free-solid-svg-icons";
import StatusCheckin from "../statusCheckin";
import API from "services/api";
import moment from "moment";
import Modal from "react-modal";
import Loading from "components/loading";
import Swal from "sweetalert2";

const CameraCheckin = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [scanOpen, setScanOpen] = useState(null);
  const [answer, setAnswer] = useState("");
  const [typeError, setTypeError] = useState("");
  const [hour, setHour] = useState("");
  const [code, setCode] = useState("");
  const [array, setArray] = useState();
  const [modalIsOpen, setIsOpen] = useState(true);
  const [sector, setSector] = useState();
  const [ids, SetIds] = useState();
  const [newCode, setNewCode] = useState();
  const [scanMode, setScanMode] = useState("camera");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  const openCam = () => {
    if (!scanOpen) {
      setScanOpen(true);
      closeModal();
    } else {
      setScanOpen(null);
      setArray();
      setIsOpen(true);
    }
  };

  const errorScan = () => {
    Swal.fire({
      title: "Ops!",
      icon: "error",
      text: "Ocorreu um erro interno, por favor tente novamente",
      confirmButtonText: "Ok",
    });
  };
  const scan = (result) => {
    if (result !== null) {
      realizarCheckin(result);
    }
  };

  const nextCheckin = () => {
    setScanOpen(true);
    setAnswer("");
  };

  const realizarCheckin = (codigo) => {
    // document.getElementById("loading").classList.remove("esconde");

    // if (scanOpen) {
    // 	setScanOpen(null)
    // 	setIsOpen(false)
    // }

    API.post("checkin/event", {
      event_id: event_id,
      code: codigo,
      array: array ? array : "Todos",
    })
      .then((res) => {
        setCode(codigo);
        setAnswer("sucesso");
        setHour(
          `${moment(res.data.data).format("DD/MM/YYYY")} às ${res.data.hora}`
        );
      })
      .catch((err) => {
        setTypeError(err.response.data.message);
        setAnswer("error");
      });
    setScanOpen(null);
    // document.getElementById("loading").classList.add("esconde");
  };

  // Captura informações do setor selecionado
  const convertArray = (sector) => {
    if (ids[sector]) {
      let convert = ids[sector].map((a) => a.idTicketType);
      setArray(convert);
    }
  };

  useEffect(() => {
    API.get(`checkin/event/checkSector/${event_id}`)
      .then((res) => {
        setSector(res.data.sectors);
        SetIds(res.data.group);
        setIsOpen(true);
        document.getElementById("loading").classList.add("esconde");
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
        document.getElementById("loading").classList.add("esconde");
      });
  }, [event_id]);

  useEffect(() => {
    if (array) {
      openCam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array]);

  return (
    <>
      <Loading title="aguarde..." />
      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={(e) =>
            (window.location.href = `/tickets/participantes/evento?event_id=${event_id}`)
          }
          style={customStyles}
          ariaHideApp={false}
          // contentLabel="Detalhes da transferência"
          id="modal-sectors"
        >
          <button
            onClick={(e) =>
              (window.location.href = `/tickets/participantes/evento?event_id=${event_id}`)
            }
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>

          <div id="receipt-content">
            <h4>Selecione o setor:</h4>
            <div className="row justify-content-center select-sector mt-4">
              {sector &&
                sector.map((item, i) => (
                  <div
                    onClick={(e) => convertArray(item.name)}
                    className="sector-item col-12"
                    key={i}
                  >
                    {item.name}
                  </div>
                ))}
              <div
                onClick={(e) => setArray("Todos")}
                className="sector-item col-12"
              >
                Todos
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        answer && (
          <StatusCheckin
            code={code}
            answer={answer}
            nextCheckin={nextCheckin}
            typeError={typeError}
            hour={hour}
          />
        )
      )}

      {scanOpen && (
        <button
          onClick={(e) =>
            (window.location.href = `/tickets/participantes/evento?event_id=${event_id}`)
          }
          id="btn-scanclose"
          className="btn"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}

      {scanOpen && scanMode === "camera" ? (
        <>
          <button
            onClick={(e) => setScanMode("input")}
            id="btn-scanmode"
            className="btn"
          >
            <FontAwesomeIcon icon={faKeyboard} /> validação por coletor
          </button>
          <div id="validacao_header">
            <h2>leitura qr code</h2>
            <p>Faça a leitura do QR Code inserido no ingresso.</p>
          </div>

          <div id="camera_bg">
            <div id="qr_mask">
              <div id="qrmask-frame"></div>
              <div className="qrmask-bg" id="qrmask-top"></div>
              <div className="qrmask-bg" id="qrmask-right"></div>
              <div className="qrmask-bg" id="qrmask-left"></div>
              <div className="qrmask-bg" id="qrmask-bottom"></div>
            </div>
            <QrReader
              delay={300}
              onError={errorScan}
              onScan={scan}
              className="qr_reader"
            />
          </div>
        </>
      ) : (
        ""
      )}
      {scanOpen && scanMode === "input" ? (
        <>
          <button
            onClick={(e) => setScanMode("camera")}
            id="btn-scanmode"
            className="btn"
          >
            <FontAwesomeIcon icon={faCamera} /> validação por câmera
          </button>
          <div id="validacao_header">
            <h2>leitura qr code</h2>
            <p>
              Faça a leitura com o coletor ou digite manualmente o código do
              ingresso.
            </p>
          </div>
          <div id="input_bg">
            <div className="container">
              <div className="row">
                <div id="btn-inputmode" className="form-group">
                  <input
                    className="form-control col-12"
                    type="text"
                    placeholder="digite o código"
                    onChange={(e) => setNewCode(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        scan(newCode);
                      }
                    }}
                  ></input>
                  <button
                    class="btn btn-primary"
                    onClick={(e) => scan(newCode)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default CameraCheckin;
