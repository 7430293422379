import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

import { useOnClickOutside } from "App";
import api from "services/api";
import { useTranslation } from "react-i18next";

let container;

const ModalComissarios = (props) => {
  const { t } = useTranslation("tickets");

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close(false);
    }
  });

  const [data, setData] = useState({
    cash_sale: 0,
    cash_sale_qty: "",
    code: "",
    discount: "",
    discount_id: "",
    email: "",
    event_id: props.event_id,
    first_name: "",
    id: "",
    last_name: "",
    sales: "",
    visualitions: "",
  });

  const [discountOptions, setDiscountOptions] = useState([]);
  const [unlimited, setUnlimited] = useState(false);

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const updateDiscount = (e) => {
    setData({
      ...data,
      discount_id: e.value,
    });
  };

  useEffect(() => {
    if (props.comissario) {
      setData(props.comissario);
      if (
        props.comissario.cash_sale === 1 &&
        props.comissario.cash_sale_qty === 0
      ) {
        setUnlimited(true);
      }
    }

    if (props.status === "modal-show") {
      api
        .get(`discount/get-by-event/${props.event_id}`)
        .then((res) => {
          let { descontos } = res.data;
          let discountOptions = [
            {
              text: t("promoters.noDiscountApplied"),
              value: "",
            },
          ];
          descontos.forEach((val) => {
            let discount = {};
            discount.text = `${val.code} [${val.type ? "" : "R$"}${val.value}${
              val.type ? "%" : ""
            }]`;
            discount.value = val.id;
            discountOptions.push(discount);
          });
          setDiscountOptions(discountOptions);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: t("global:errors.internalServerError"),
            confirmButtonText: t("global:confirmButtonText"),
          });
        });
    }
    // eslint-disable-next-line
  }, [props.comissario, props.status, props.event_id]);

  function close(reload) {
    props.callback("modal-hidden", reload);
    setTimeout(() => {
      setUnlimited(false);
      setData({
        cash_sale: 0,
        cash_sale_qty: "",
        code: "",
        discount: "",
        discount_id: "",
        email: "",
        event_id: props.event_id,
        first_name: "",
        id: "",
        last_name: "",
        sales: "",
        visualitions: "",
      });
    }, 1000);
  }

  function saveData() {
    let steward = Object.assign({}, data);

    if (!steward.id) {
      delete steward["id"];
    }
    if (unlimited) {
      steward.cash_sale_qty = 0;
    }

    if (steward.cash_sale === 0) {
      steward.cash_sale = false;
    } else {
      steward.cash_sale = true;
    }

    if (steward.discount_id === "") {
      steward.discount_id = null;
    }

    delete steward["sales"];
    delete steward["visualitions"];
    delete steward["discount"];

    let values = { steward };
    if (data.code.length !== 0) {
      api
        .post("steward/create", values)
        .then(() => {
          container.success(t("promoters.registeredCommissioner"));
          close(true);
        })
        .catch((err) => {
          container.error(
            t(`backend:${err.response.data.code}`, { param: "$t(backend:promoter)" })
          );
        });
    } else {
      container.error(t("promoters.insertCode"));
    }
  }

  // Função que converte nome do comissario em slug
  function slugify(text, ampersand = "e") {
    let originalText = text;
    const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
    const b = "aaaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
    const p = new RegExp(a.split("").join("|"), "g");

    text = text
      .toString()
      .toLowerCase()
      .replace(/[\s_]+/g, "-")
      .replace(p, (c) => b.charAt(a.indexOf(c)))
      .replace(/&/g, `-${ampersand}-`)
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+|-+$/g, "");

    setData({
      ...data,
      code: text,
      first_name: originalText,
    });
  }

  function onlineSale() {
    if (data.cash_sale === 0) {
      setData({
        ...data,
        cash_sale: 1,
      });
    } else {
      setData({
        ...data,
        cash_sale: 0,
      });
    }
  }

  return (
    <>
      <div
        ref={ref}
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="card-header">
          <h4 className="card-header-title">
            {props.comissario ? t("promoters.edit") : t("promoters.register")}{" "}
            {t("promoters.promoterAdvertiser")}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => close(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-md-6">
              <label>{t("promoters.name")}</label>
              <input
                className="form-control"
                name="first_name"
                placeholder={t("promoters.name")}
                value={data.first_name}
                onChange={(e) => {
                  slugify(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <label>{t("promoters.email")}</label>
              <input
                className="form-control"
                type="email"
                placeholder={t("promoters.exampleMail")}
                name="email"
                value={data.email}
                onChange={updateData}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <label>{t("promoters.code")}</label>
              <input
                className="form-control"
                name="code"
                placeholder={t("promoters.name")}
                value={data.code}
                onChange={updateData}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="discount">{t("promoters.discounts")}</label>
              <Select
                name="discount"
                value={
                  data.discount_id
                    ? { value: data.discount_id, label: "" }
                    : null
                }
                options={
                  discountOptions.length > 0 &&
                  discountOptions.map((item) => ({
                    value: item.value,
                    label: item.text,
                  }))
                }
                onChange={updateDiscount}
                getOptionLabel={(option) =>
                  discountOptions.find((item) => item.value === option.value)
                    ?.text
                }
                placeholder={t("promoters.discount")}
              />
            </div>
          </div>
          <div
            className={`custom-input-option ${
              data.cash_sale === 1 ? "active" : ""
            } mt-4 `}
            onClick={onlineSale}
          >
            {data.cash_sale === 1 ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon icon={faCircle} />
            )}
            &nbsp;{t("promoters.cashSale")}
          </div>
          {data.cash_sale === 1 && (
            <div className="fadeIn">
              <div className="row">
                <div className="col-md-6">
                  <label>{t("promoters.availableQuantity")}</label>
                  <input
                    className="form-control"
                    name="cash_sale_qty"
                    value={data.cash_sale_qty}
                    placeholder="Ex: 200"
                    onChange={updateData}
                    disabled={unlimited}
                  />
                </div>
                <div className="col-md-6 pt-4">
                  <div
                    className={`custom-input-option ${
                      unlimited ? "active" : ""
                    }`}
                    onClick={(e) => {
                      if (unlimited) {
                        setUnlimited(false);
                      } else {
                        setUnlimited(true);
                      }
                    }}
                  >
                    {unlimited ? (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    ) : (
                      <FontAwesomeIcon icon={faCircle} />
                    )}{" "}
                    {t("promoters.unlimited")}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="box-button">
            <button
              className="btn btn-primary col-md-4 c3 mr-3 "
              onClick={() => {
                saveData();
              }}
            >
              {props.comissario && props.event_id
                ? t("promoters.save")
                : t("promoters.register")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalComissarios;
