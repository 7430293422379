import { useOnClickOutside } from "App";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastr";
import api from "services/api";
import Swal from "sweetalert2";

let container;

export default function ModalMenu(props) {
  const dataProducts = props;
  // Click outside
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (dataProducts.status === "modal-show") {
      close();
    }
  });
  const [sectors, setSectors] = useState([]);
  const [unique, setUnique] = useState();
  const [data, setData] = useState({
    id: "",
    id_event: "",
    name: "",
    description: "",
    status: false,
    sector_id: null,
  });

  const [click, setClick] = useState(false);
  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    api.get(`listSectors/${dataProducts.eventID}`).then((res) => {
      setSectors(res.data);
      if (res.data[0]) {
        setUnique(res.data[0].id);
      }
    });
    if (dataProducts.activeMenu.id) {
      api.get(`menu/get/${dataProducts.activeMenu.id}`).then((res) => {
        setData({
          id: dataProducts.activeMenu.id,
          id_event: dataProducts.activeMenu.eventID,
          name: res.data.menuName,
          description: res.data.description,
          status: res.data.status,
          sector_id: res.data.sector_id,
          sectorName: res.data.sectorName,
        });
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      });
    } else {
      setData({
        id: "",
        id_event: dataProducts.eventID,
        name: "",
        description: "",
        sector_id: unique,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProducts.status, dataProducts.activeMenu.id]);

  // eslint-disable-next-line consistent-return
  const salvar = () => {
    setClick(true);
    if (data.name === "") {
      setClick(false);
      return container.error("Erro ao editar! Favor verificar os campos!");
    }
    if (dataProducts.activeMenu.id) {
      api
        .post(`menu/edit`, data)
        .then(() => {
          container.success("Editado com Sucesso!");
          close();
        })
        .catch(() => {
          setClick(false);
          container.error("Erro ao editar! Favor verificar os campos!");
        });
    } else {
      api
        .post(`menu/create`, data)
        .then(() => {
          container.success("Cardápio criado com sucesso!");
          close();
        })
        .catch(() => {
          setClick(false);
          container.error("Erro ao cadastrar! Favor verificar os campos!");
        });
    }
  };

  function close() {
    setClick(false);
    setData({
      id: "",
      id_event: "",
      name: "",
      description: "",
      status: false,
    });

    dataProducts.retorno("modal-hidden");
  }

  return (
    <div
      id="cardapio-cadastrar"
      ref={ref}
      className={`shadow side-modal ${dataProducts.status}`}
      role="dialog"
    >
      <ToastContainer
        ref={(reff) => {
          container = reff;
        }}
        className="toast-top-right"
      />
      <div className="card-header">
        <h4 className="card-header-title">
          {dataProducts.activeMenu.id
            ? "Editar Cardápio"
            : "Cadastrar Cardápio"}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => close(e)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          <div className={sectors.length > 1 ? "col-md-6" : "col-md-12"}>
            <span>Nome</span>
            <input
              required
              className="form-control"
              value={data.name || ""}
              name="name"
              onChange={updateData}
            />
          </div>
          {sectors.length > 1 && (
            <div className="col-md-6">
              <span>Setor</span>
              <select
                name="type"
                className="form-control"
                onChange={(e) =>
                  setData({
                    ...data,
                    sector_id: e.target.value,
                  })
                }
              >
                <option value={0} disabled>
                  Setores
                </option>
                {sectors.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <span>Descrição</span>
            <textarea
              required
              className="form-control"
              value={data.description || ""}
              name="description"
              onChange={updateData}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-3">
            {data.id && dataProducts.status === "modal-show" && (
              <>
                <span>Habilitado?</span>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="status"
                    defaultChecked={data.status}
                    onChange={() =>
                      setData({ ...data, status: data.status ? 0 : 1 })
                    }
                  />
                  <label
                    id="statusSwitch"
                    className="custom-control-label"
                    htmlFor="status"
                  ></label>
                </div>
              </>
            )}
          </div>
          <div className="box-button col-md-9">
            <button
              type="button"
              className="btn btn-primary c3"
              disabled={click}
              onClick={() => salvar()}
            >
              {dataProducts.activeMenu.id
                ? "salvar alterações"
                : "criar cardápio"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
