import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/loading";
import NewPageHeader from "components/newPageHeader";
import New_api from "services/new-api";

import "./styles.css";

const interfaceSettings = {
  settings: {
    layoutType: models.LayoutType.Custom,
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    customLayout: {
      displayOption: models.DisplayOption.FitToWidth,
      pageSize: {
        type: models.PageSizeType.Widescreen,
      },
    },
    panes: {
      pageNavigation: {
        visible: false,
      },
    },
  },
};

export default function DataInsight() {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const { t } = useTranslation();

  const [coreConfigs, setCoreConfigs] = useState({
    EmbedToken: "",
    EmbedUrl: "",
    ResourceId: "",
    organizationId: "",
  });

  const [loading, setLoading] = useState(true);

  const fetchToken = async () => {
    const { data } = await New_api.get(`event/${event_id}/get-insights`)
      .catch((err) => {
        console.error("Erro ao obter o token de embed:", err);
        setLoading(false);
      })
      .finally(() => setLoading(false));
    setCoreConfigs(data);
  };

  useEffect(() => {
    fetchToken();
    // eslint-disable-next-line
  }, []);

  const createFilter = (table, column, values) => {
    return {
      $schema: "http://powerbi.com/product/schema#basic",
      filterType: models.FilterType.Basic,
      operator: "In",
      target: { table, column },
      values,
    };
  };

  const originalOrganizationFilter = createFilter(
    "dim_orgs",
    "organization_id",
    [coreConfigs.organizationId]
  );

  const config = {
    id: coreConfigs.ResourceId,
    embedUrl: coreConfigs.EmbedUrl,
    accessToken: coreConfigs.EmbedToken,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.Read,
    type: "report",
    filters: [originalOrganizationFilter],
    ...interfaceSettings,
  };

  return (
    <Fragment>
      <NewPageHeader
        title={t("events:dataInsights.title")}
        subtitle={t("events:dataInsights.subtitle")}
      />
      <div className="container-fluid" id="powerbi-container">
        {loading && <Loading title={t("components:buttonSpinner.loading")} />}
        {coreConfigs.EmbedToken && (
          <PowerBIEmbed cssClassName="powerbi-content" embedConfig={config} />
        )}
      </div>
    </Fragment>
  );
}
