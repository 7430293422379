import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.css";
import { ReactComponent as More } from "../../assets/icons/moreVertical.svg";
import { ReactComponent as TrashCan } from "../../assets/icons/trashCan.svg";
import { ReactComponent as EditPencil } from "../../assets/icons/editPencil.svg";
import { ReactComponent as Drag } from "../../assets/icons/drag.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SponsorCategories } from "services/constants";

SponsorCard.propTypes = {
  sponsor: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    logo_url: PropTypes.string,
    category: PropTypes.string,
    order: PropTypes.number,
  }),
  drag: PropTypes.bool,
  hasTrashCan: PropTypes.bool,
  onClickTrash: PropTypes.func,
  onClickEdit: PropTypes.func,
};

export default function SponsorCard({
  sponsor,
  drag,
  hasTrashCan,
  onClickTrash,
  onClickEdit,
}) {
  const { id, logo_url, name, url, category } = sponsor;
  const [dragColor, setDragColor] = useState("#767380");

  const setDragColorChange = () => {
    if (drag) {
      if (dragColor === "#767380") {
        setDragColor("#0050C3");
      } else {
        setDragColor("#767380");
      }
    }
  };

  const popoverBottom = (
    <Popover className="d-flex flex-column pop" id="popover-positioned-bottom">
      <div
        onClick={() => onClickEdit(sponsor)}
        className="d-flex align-items-center align-self-center tooltip-group"
      >
        <EditPencil className="pencil" />
        <span className="edit-text">Editar</span>
      </div>
      <div
        onClick={() => onClickTrash(id, name)}
        className="d-flex align-items-center align-self-center tooltip-group"
      >
        <TrashCan width="1.5rem" className="trash" stroke="#E73737"/>
        <span className="delete-text">Excluir</span>
      </div>
    </Popover>
  );

  return (
    <div
      className={`row d-flex justify-content-between align-self-stretch align-items-center sponsor-card ${
        drag && "draggable"
      }`}
      onMouseOver={() => setDragColorChange()}
      onMouseOut={() => setDragColorChange()}
    >
      <div className="d-flex align-items-center sponsor-group">
        {drag && <Drag stroke={dragColor} className="drag" />}

        <div
          className="sponsor-logo"
          style={{ backgroundImage: `url(${logo_url})` }}
          alt={`${name} logo`}
        />
      </div>
      <div className="sponsor-texts d-flex flex-wrap align-items-center justify-content-between flex-grow-1">
        <div className="sponsor-text">{name}</div>
        <div className="sponsor-text-link">{url}</div>
        <div className="sponsor-text">{SponsorCategories.find((cat) => cat.value === category).label}</div>
      </div>
      <div className="d-flex align-items-center sponsor-group">
        {hasTrashCan ? (
          <TrashCan className="trash" stroke="#E73737" onClick={() => onClickTrash(id, name)} />
        ) : (
          <>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={popoverBottom}
            >
              <More
                className="more dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
            </OverlayTrigger>
          </>
        )}
      </div>
    </div>
  );
}
