import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import { NavLink } from "react-router-dom";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "services/api";
import NewApi from "services/new-api";
import { debounce } from "lodash";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";
import "toastr.css";
import Loading from "components/loading";
import { ImageHandlerService } from "services/image-handler";
import {
  faPen,
  faSearch,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import CustomButtonSpinner from "components/buttonSpinner";
import { useTranslation } from "react-i18next";
import NewSelect from "components/newSelect";

let container;

const user = JSON.parse(localStorage.getItem("user"));

export default function Organizacoes() {
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationList, setOrganizationList] = useState(organizations);
  const [porPagina, setPorPagina] = useState(25);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Todos");
  const { t } = useTranslation("organizations");
  const { t: tg } = useTranslation("global");
  const categories = [
    { value: "Todos", label: t("search.filter.all") },
    { value: "Produtor Autônomo", label: t("search.filter.producer") },
    { value: "Empresa de eventos", label: t("search.filter.company") },
    { value: "Estabelecimento", label: t("search.filter.establishment") },
  ];
  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });

  const debouncedSearch = debounce((searchTerm) => {
    paginationOrganizations("initial", 1, porPagina, searchTerm);
  });

  useEffect(() => {
    if (selectedCategory === "Todos") {
      paginationOrganizations("initial", 1, porPagina, pesquisa.pesquisa);
    }
    debouncedSearch(pesquisa.pesquisa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisa, selectedCategory]);

  const toggleOrganizationStatus = (item) => {
    item.active = Number(!item.active);
    api
      .get(`organizations/${item.id}/activation/${item.active}`)
      .then(() => {
        const resultMessage =
          item.active === 1
            ? t("toast.success.active")
            : t("toast.success.inactive");

        const updatedOrgList = organizationList.map((organization) =>
          organization.id === item.id
            ? { ...organization, active: !organization.active }
            : organization
        );
        setOrganizationList(updatedOrgList);
        container.success(resultMessage);
      })
      .catch(() => {
        Swal.fire({
          title: tg("errors.ops"),
          icon: "error",
          text: tg("errors.internalServerError"),
          confirmButtonText: tg("confirmButtonText"),
        });
      });
  };

  const formatDocument = (value) => {
    if (value.length === 11) {
      value = value
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      value = value
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    }
    return value;
  };

  const paginationOrganizations = (
    typePage,
    numberPage,
    perPage,
    search,
    category = selectedCategory
  ) => {
    setIsLoading(true);
    let pageConsult = {
      page: numberPage,
      perPage: perPage,
      search: search,
      category: category,
    };

    switch (typePage) {
      case "Initial":
        pageConsult = {
          page: 1,
          perPage: perPage,
          search: pesquisa.pesquisa,
          category: category,
        };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
            category: category,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
            category: category,
          };
        }

        break;

      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
          category: category,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
            category: category,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
          category: category,
        };
        break;
      default:
        pageConsult = {
          page: 1,
          perPage: porPagina,
          search: pesquisa.pesquisa,
          category: category,
        };
    }

    if (/^\d+$/.test(search)) {
      if (search.length === 11 || search.length === 14) {
        pageConsult.search = formatDocument(search);
      }
    }

    const queryString = new URLSearchParams(pageConsult).toString();

    NewApi.get(`organizations/paginated-list?${queryString}`)
      .then((res) => {
        setOrganizations(res.data.data);
        setAtualizaPaginaAtual(res.data.meta.current_page);
        setAtualizaUltimaPagina(res.data.meta.last_page);
        setAtualizaQuantPesquisa(res.data.meta.total);
        setIsLoading(false);
        document.getElementById("loading").classList.add("esconde");
      })
      .catch((err) => {
        if (err.response.data.error.status === 500) {
          container.error(t("toast.error.route"));
        } else {
          container.error(err.response.data.error.message);
        }
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      });
  };

  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  const filterByCategory = (e) => {
    if (e !== null) {
      setSelectedCategory(e.value);
      paginationOrganizations(
        "initial",
        1,
        porPagina,
        pesquisa.pesquisa,
        e.value
      );
    } else {
      setSelectedCategory("Todos");
    }
  };

  const currentPageTotal = Math.min(
    porPagina * atualizaPaginaAtual,
    atualizaQuantPesquisa
  );

  return (
    <>
      <PageHeader
        title={t("header.title")}
        text={t("header.text")}
        btnLink="/organizacao"
        btnText={t("header.button-text")}
      ></PageHeader>

      <Loading title={t("loading.title")} text={t("loading.text")} />

      <div id="page-organizations" className="container-fluid mt-4">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="col-12">
          <div className="row mt-2">
            <div className="col-md-5 mb-2">
              <div className="card-search d-flex">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  className="input-custom"
                  name="pesquisa"
                  value={pesquisa.pesquisa || ""}
                  onChange={updateData}
                  autoComplete="off"
                  placeholder={t("search.placeholder")}
                />
              </div>
            </div>
            <div className="col-md-5 mb-2">
              <NewSelect
                className="basic-single category-filter w-50"
                classNamePrefix="select"
                placeholder={t("search.filter.title")}
                isClearable={true}
                options={categories}
                onChange={filterByCategory}
              />
            </div>
            <div className="col-md-2 mb-2">
              <CustomButtonSpinner
                customStyle={"btn btn-outline-secondary btn-height"}
                value={pesquisa.pesquisa || ""}
                triggerOnClick={paginationOrganizations}
                isLoading={isLoading}
                text={t("search.button-text")}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-sm card-table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col-3">{t("table.header.name")}</th>
                    <th scope="col-2" className="hidden-mobile">
                      {t("table.header.slug")}
                    </th>
                    <th scope="col-3" className="hidden-mobile">
                      {t("table.header.category")}
                    </th>
                    <th scope="col-2" className="hidden-mobile">
                      {t("table.header.logo")}
                    </th>
                    <th scope="col-2" className="text-right">
                      {t("table.header.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {organizations.length > 0 ? (
                    organizations.map((organization, i) => (
                      <tr key={i}>
                        <td>{organization.name}</td>
                        <td className="hidden-mobile">{organization.slug}</td>
                        <td className="hidden-mobile">
                          {organization.category}
                        </td>
                        <td className="hidden-mobile">
                          <div
                            className="event-img"
                            style={{
                              backgroundImage: `url(${ImageHandlerService.getImageFromUrl(
                                organization.logo,
                                {
                                  format: "webp",
                                  quality: 50,
                                  resize: { width: 100, fit: "contain" },
                                },
                                "/logo-pocket.jpg"
                              )}`,
                            }}
                          ></div>
                        </td>
                        <td>
                          <div className="card-table-actions">
                            <ReactTooltip
                              className="tooltip-custom"
                              effect="solid"
                              place="top"
                            />
                            <span className="mr-3 hidden-mobile">
                              {organization.active
                                ? t("table.body.active")
                                : t("table.body.inactive")}
                            </span>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={organization.id}
                                name={organization.id}
                                defaultChecked={organization.active}
                                onChange={() =>
                                  toggleOrganizationStatus(organization)
                                }
                              ></input>
                              <label
                                className="custom-control-label"
                                htmlFor={organization.id}
                              ></label>
                            </div>
                            <NavLink
                              to={`/organizacao?org_id=${organization.id}`}
                              className="btn-table ml-2"
                              data-tip={t("table.body.tip.edit")}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </NavLink>
                            {!!user.is_admin && (
                              <NavLink
                                target="_blank"
                                to={`/eventos?org_id=${organization.id}`}
                                className="btn-table ml-2"
                                data-tip={t("table.body.tip.list")}
                              >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </NavLink>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      style={{
                        textAlign: "center",
                        height: "5.8rem",
                      }}
                    >
                      <td colSpan="6">Nenhum resultado encontrado.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="col-12">
                <div className="row d-flex justify-content-between datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2">{t("pagination.results")}</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) => {
                          paginationOrganizations("initial", 1, e.target.value);
                          setPorPagina(e.target.value);
                        }}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    <span>
                      {t("pagination.displaying", {
                        currentPageTotal,
                        total: atualizaQuantPesquisa,
                      })}
                    </span>
                    <button
                      onClick={() =>
                        paginationOrganizations("initial", 1, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        paginationOrganizations(
                          "left",
                          atualizaPaginaAtual,
                          porPagina
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      {t("pagination.page", {
                        currentPage: atualizaPaginaAtual,
                        totalPages: atualizaUltimaPagina,
                      })}
                    </span>

                    <button
                      onClick={() =>
                        paginationOrganizations(
                          "right",
                          atualizaPaginaAtual,
                          porPagina
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        paginationOrganizations(
                          "lastPage",
                          atualizaUltimaPagina,
                          porPagina
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
