import { DatePicker } from "components/datepicker";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircleOutline, IoMdArrowBack } from "react-icons/io";
import Modal from "react-modal";
import Select from "react-select";
import Switch from "react-switch";
import { breakPoints } from "services/breakPoints";
import { COLORS, SPACINGS } from "services/constants";
import styled from "styled-components";
import Swal from "sweetalert2";
import api from "../../services/api";

Modal.setAppElement("#root");
ModalEditLot.propTypes = {
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
  }),
  isPassport: PropTypes.bool,
  ticketState: PropTypes.shape({
    ticketDataToModal: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      expiration: PropTypes.string,
      min_purchase: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
      max_purchase: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
      cpf_max_purchase: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
      ]),
      cpf_limit: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
      description: PropTypes.string,
      value: PropTypes.number,
      qty: PropTypes.number,
      price: PropTypes.number,
    }),
    setTicketDataToModal: PropTypes.func,
  }),
  loteDataState: PropTypes.shape({
    loteData: PropTypes.shape({
      id: PropTypes.string,
      lot: PropTypes.shape({
        name: PropTypes.string,
        expiration: PropTypes.string,
      }),
      status: PropTypes.number,
      qty: PropTypes.number,
      total_available: PropTypes.number,
      total_sold: PropTypes.number,
      value: PropTypes.number,
      types: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          value: PropTypes.number,
          sold: PropTypes.number,
          qty: PropTypes.number,
          active: PropTypes.number,
          is_public: PropTypes.number,
          available: PropTypes.number,
          type: PropTypes.string,
        })
      ),
    }),
    setLoteData: PropTypes.func,
  }),
  loteNames: PropTypes.arrayOf(PropTypes.string),
  setRefetch: PropTypes.func,
  isAutomaticTurn: PropTypes.bool,
};

export default function ModalEditLot(props) {
  const [blockSubmit, setBlockSubmit] = useState(false);
  // eslint-disable-next-line no-undef
  const windowJS = window;
  const urlParams = new URLSearchParams(windowJS.location.search);
  const eventId = urlParams.get("event_id");
  // const pontosDeVendaMock = mockPontos;
  const [error, setError] = useState(false);
  const [saleChannels, setSaleChannels] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modal-lot",
  });
  const { currencySymbol } = useCurrencyEventContext();

  const {
    toggleFunction: { isOpen, setIsOpen },
    loteDataState,
    ticketState,
    loteNames,
    isPassport,
    setRefetch,
  } = props;
  let { ticketDataToModal } = ticketState;
  const { setLoteData, loteData } = loteDataState;

  const { setTicketDataToModal } = ticketState;

  ticketDataToModal = JSON.parse(
    JSON.stringify(ticketDataToModal, 2, null).replace(/null/g, '""')
  );

  function chooseNumberLimit(value, min, max) {
    if (typeof value !== "number" || (value >= min && value <= max)) {
      return value;
    }

    if (value < min) {
      return min;
    }

    return max;
  }

  function setIntOrNull(value) {
    if (value) {
      return parseInt(value, 10);
    }
    return "";
  }

  function updateData(event, value) {
    const finalValue = value !== undefined ? value : event.target.value;

    if (event.target.name === "price") {
      setTicketDataToModal({
        ...ticketDataToModal,
        [event.target.name]: Number(`${Math.round(`${finalValue}e2`)}e-2`),
      });

      return;
    }
    setTicketDataToModal({
      ...ticketDataToModal,
      [event.target.name]: finalValue,
    });
  }

  // eslint-disable-next-line no-undef
  document.onkeydown = async function submit(evt) {
    if (evt.key === "Enter") {
      if (blockSubmit) {
        return;
      }
      if (isOpen) {
        setBlockSubmit(true);
        salvar();
        // eslint-disable-next-line no-magic-numbers
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setBlockSubmit(false);
      }
    }
  };

  function updateCPFLimit() {
    setTicketDataToModal({
      ...ticketDataToModal,
      cpf_limit: ticketDataToModal.cpf_limit === 1 ? 0 : 1,
    });
  }

  const updateDate = (value) => {
    const timezoneDate = moment.tz(value, "America/Sao_Paulo");
    const formattedDate = timezoneDate.format();
    setTicketDataToModal({
      ...ticketDataToModal,
      expiration: formattedDate,
    });
  };

  function loadSaleChannels() {
    api
      .get("/ticket_sale_channels", {
        params: { page: 1, per_page: Number.MAX_SAFE_INTEGER },
      })
      .then((res) => setSaleChannels(res.data.data))
      .catch(() => {
        Swal.fire(t("alert.error.load-channels"), "", "warning");
      });
  }

  React.useEffect(() => {
    if (!isOpen) {
      setError(false);
      setTicketDataToModal({
        id: 0,
        name: "",
        expiration: "",
        min_purchase: 0,
        max_purchase: 0,
        cpf_max_purchase: 0,
        cpf_limit: 0,
        description: "",
        value: 0,
        qty: 0,
        price: 0,
        ticket_sale_channel_ids: [],
      });
    } else {
      loadSaleChannels();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  function editLote(newLotInfo) {
    return api
      .post("/lot/alter/name", newLotInfo)
      .then(() => {
        setLoteData({
          id: loteData.id,
          lot: {
            name: newLotInfo.name,
            expiration: newLotInfo.expiration,
          },
          status: loteData.status,
          qty: loteData.qty,
          total_available: loteData.total_available,
          total_sold: loteData.total_sold,
          value: loteData.value,
          types: loteData.types,
        });
        setRefetch(true);

        return true;
      })
      .catch(() => {
        return false;
      });
  }

  function editIngresso(newIngressoInfo) {
    return api
      .post("/event/sector/lot", newIngressoInfo)
      .then(() => {
        setRefetch();
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  async function salvar() {
    setError(false);
    if (loteNames.includes(ticketDataToModal.name)) {
      setError(t("error.name-unique"));
      return;
    }
    if (ticketDataToModal.name === "") {
      setError(t("error.name-required"));
      return;
    }

    if (isPassport) {
      await savePassLot();
    } else {
      await saveCommonLot();
    }
  }

  async function savePassLot() {
    setIsSubmitting(true);

    const passportInfo = {
      qty: ticketDataToModal.qty,
      name: ticketDataToModal.name,
      price: ticketDataToModal.price,
      cpf_limit: ticketDataToModal.cpf_limit,
      expiration: ticketDataToModal.expiration,
      description: ticketDataToModal.description,
      min_purchase: ticketDataToModal.min_purchase,
      max_purchase: ticketDataToModal.max_purchase,
      cpf_max_purchase: ticketDataToModal.cpf_max_purchase,
      ticket_sale_channel_ids: ticketDataToModal.ticket_sale_channel_ids.map(
        (item) => item.value
      ),
    };

    try {
      await api.patch(
        "/passport-sector-lots/" + ticketDataToModal.id,
        passportInfo
      );

      setRefetch(true);

      Swal.fire({
        title: t("alert.patch"),
        icon: "success",
        confirmButtonColor: "#23b936",
        cancelButtonColor: "#188125",
        text: t("alert.text"),
        confirmButtonText: t("alert.button-text"),
      });

      setTimeout(() => {
        setIsOpen(false);
        setIsSubmitting(false);
        // eslint-disable-next-line no-magic-numbers
      }, 1000);
    } catch (error) {
      setIsSubmitting(false);
      setError(t("error.patch"));
    }
  }

  async function saveCommonLot() {
    setIsSubmitting(true);

    const newLotInfo = {
      event_id: eventId,
      esl_id: ticketDataToModal.id,
      name: ticketDataToModal.name,
    };
    const newIngressoInfo = {
      cpf_max_purchase: ticketDataToModal.cpf_max_purchase,
      cpf_limit: ticketDataToModal.cpf_limit,
      description: ticketDataToModal.description,
      id: ticketDataToModal.id,
      max_purchase: ticketDataToModal.max_purchase,
      min_purchase: ticketDataToModal.min_purchase,
      price: ticketDataToModal.price,
      qty: ticketDataToModal.qty,
      value: ticketDataToModal.value,
      expiration: ticketDataToModal.expiration,
      ticket_sale_channel_ids: ticketDataToModal.ticket_sale_channel_ids.map(
        (item) => item.value
      ),
    };
    const requestStatus = { editLote: false, editIngresso: false };
    requestStatus.editLote = await editLote(newLotInfo);
    requestStatus.editIngresso = await editIngresso(newIngressoInfo);

    if (!requestStatus.editIngresso || !requestStatus.editLote) {
      setIsSubmitting(false);

      if (!requestStatus.editIngresso && !requestStatus.editLote) {
        setError(t("error.lot-and-ticket"));
        return;
      }
      if (!requestStatus.editLote) {
        setError(t("error.lot"));
        return;
      }
      setError(t("error.ticket"));
      return;
    }
    Swal.fire({
      title: t("alert.update"),
      icon: "success",
      confirmButtonColor: "#23b936",
      cancelButtonColor: "#188125",
      text: t("alert.text"),
      confirmButtonText: t("alert.button-text"),
    });
    setTimeout(() => {
      setIsOpen(false);
      setIsSubmitting(false);
      // eslint-disable-next-line no-magic-numbers
    }, 1000);
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      <ModalContainer>
        <HeaderContainer>
          <ArrowTextContainer>
            <BackArrowIcon size={23} onClick={() => setIsOpen(false)} />
            <HeaderText>{t("header.edit")}</HeaderText>
          </ArrowTextContainer>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon size={25} />
          </CloseButton>
        </HeaderContainer>
        <InputContainer>
          <InfoLoteContainer>
            <div style={{ gridArea: "nomeInput" }}>
              <DescriptionInputText>{t("label.name")}</DescriptionInputText>
              <CustomInput
                type="text"
                name="name"
                value={ticketDataToModal.name}
                onChange={updateData}
                className="form-control"
              />
            </div>
            <div style={{ gridArea: "dateInput" }}>
              <DescriptionInputText>{t("label.expire")}</DescriptionInputText>
              <div>
                <DatePicker
                  marginLeft={[0, "-5rem"]}
                  leftIcon
                  required
                  showTime
                  value={
                    ticketDataToModal.expiration
                      ? new Date(ticketDataToModal.expiration)
                      : ""
                  }
                  showTimeSelect
                  name="expiration"
                  onChange={updateDate}
                  onKeyDown={(e) => e.preventDefault()}
                  placeholderText={t("placeholder.expiration")}
                />
              </div>
            </div>
            <div style={{ gridArea: "qtyInput" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.available")}
                </DescriptionInputText>
              </RowContainer>
              <EditarIngressoInput
                required
                min={0}
                type="number"
                name="qty"
                value={chooseNumberLimit(
                  setIntOrNull(ticketDataToModal.qty),
                  0,
                  Number.MAX_SAFE_INTEGER
                )}
                onChange={(e) =>
                  updateData(
                    e,
                    chooseNumberLimit(
                      setIntOrNull(e.target.value),
                      0,
                      Number.MAX_SAFE_INTEGER
                    )
                  )
                }
                className="form-control"
              />
            </div>
            <div style={{ gridArea: "priceInput" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.sell-value")}
                </DescriptionInputText>
              </RowContainer>
              <ValueInputContainer className="d-flex flex-row form-control p-0">
                <span
                  style={{ padding: "0 5px 0 10px" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {currencySymbol}
                </span>
                <EditarIngressoInput
                  style={{ outline: 0, border: "none" }}
                  required
                  type="number"
                  name="price"
                  value={ticketDataToModal.price}
                  onChange={updateData}
                />
              </ValueInputContainer>
            </div>
            <div style={{ gridArea: "minBuy" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.min-buy")}
                </DescriptionInputText>
              </RowContainer>
              <EditarIngressoInput
                required
                min={1}
                type="number"
                name="min_purchase"
                value={chooseNumberLimit(
                  setIntOrNull(ticketDataToModal.min_purchase || 1),
                  1,
                  Number.MAX_SAFE_INTEGER
                )}
                onChange={(e) =>
                  updateData(
                    e,
                    chooseNumberLimit(
                      setIntOrNull(e.target.value),
                      1,
                      Number.MAX_SAFE_INTEGER
                    )
                  )
                }
                className="form-control"
              />
            </div>
            <div style={{ gridArea: "maxBuy" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.max-buy")}
                </DescriptionInputText>
              </RowContainer>
              <EditarIngressoInput
                required
                min={1}
                type="number"
                name="max_purchase"
                value={chooseNumberLimit(
                  setIntOrNull(ticketDataToModal.max_purchase || 5),
                  1,
                  Number.MAX_SAFE_INTEGER
                )}
                onChange={(e) =>
                  updateData(
                    e,
                    chooseNumberLimit(
                      setIntOrNull(e.target.value),
                      1,
                      Number.MAX_SAFE_INTEGER
                    )
                  )
                }
                className="form-control"
              />
            </div>
            <div style={{ gridArea: "selectPonto" }}>
              <DescriptionInputText>{t("label.channels")}</DescriptionInputText>
              <PontoSelectContainer
                name="ticket_sale_channel_ids"
                value={ticketDataToModal.ticket_sale_channel_ids}
                isMulti={true}
                placeholder={t("placeholder.channels")}
                allowSelectAll={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(event) =>
                  updateData(
                    { target: { name: "ticket_sale_channel_ids" } },
                    event
                  )
                }
                options={saleChannels.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </div>
            <div style={{ gridArea: "switchCPF" }}>
              <DescriptionInputText style={{ paddingBottom: SPACINGS.space2 }}>
                {t("label.limit")}
              </DescriptionInputText>
              <SwitchContainer>
                <Switch
                  handleDiameter={16}
                  onColor="#75d1f2"
                  onHandleColor={COLORS.middleBlue}
                  offColor={COLORS.weakGray}
                  offHandleColor={COLORS.gray}
                  height={12}
                  width={28}
                  name="cpf_limit"
                  onChange={updateCPFLimit}
                  checked={!!ticketDataToModal.cpf_limit}
                  uncheckedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  checkedIcon={false}
                />
                <LimitText
                  style={{
                    color: ticketDataToModal.cpf_limit
                      ? COLORS.middleBlue
                      : COLORS.darkGray,
                  }}
                >
                  {t(ticketDataToModal.cpf_limit ? "limited" : "unlimited")}
                </LimitText>
              </SwitchContainer>
            </div>
            <div style={{ gridArea: "maxCPF" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.limit-quantity")}
                </DescriptionInputText>
              </RowContainer>
              <CustomInput
                disabled={!ticketDataToModal.cpf_limit}
                required
                min={1}
                type="number"
                name="cpf_max_purchase"
                value={chooseNumberLimit(
                  setIntOrNull(ticketDataToModal.cpf_max_purchase),
                  1,
                  Number.MAX_SAFE_INTEGER
                )}
                onChange={(e) =>
                  updateData(
                    e,
                    chooseNumberLimit(
                      setIntOrNull(e.target.value),
                      1,
                      Number.MAX_SAFE_INTEGER
                    )
                  )
                }
                className="form-control"
              />
            </div>
            <div style={{ gridArea: "description" }}>
              <DescriptionInputText>
                {t("label.description")}
              </DescriptionInputText>

              <CustomInput
                type="text"
                name="description"
                value={ticketDataToModal.description}
                onChange={updateData}
                className="form-control"
              />
            </div>
            <ErroText
              style={{
                gridArea: "erroText",
              }}
            >
              {error && error}
            </ErroText>
            <SubmitButtonContainer style={{ gridArea: "submitButton" }}>
              <SubmitButton
                disabled={
                  isSubmitting ||
                  !ticketDataToModal.name ||
                  !ticketDataToModal.qty
                }
                className="btn btn-primary c3 btn-block"
                onClick={salvar}
                type="button"
              >
                <SubmitButtonText>{t("button.save")}</SubmitButtonText>
              </SubmitButton>
            </SubmitButtonContainer>
          </InfoLoteContainer>
        </InputContainer>
      </ModalContainer>
    </ModalComponent>
  );
}

// /////////////////////////////////// CONTAINERs /////////////////////////

const SubmitButtonContainer = styled.div`
  padding-bottom: ${SPACINGS.space4};
`;

const ValueInputContainer = styled.div`
  border: 1px solid ${COLORS.gray};
  height: 34px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
`;
const InputContainer = styled.div`
  padding: 0 ${SPACINGS.space0} 0 ${SPACINGS.space0};
  @media ${breakPoints.tablet} {
    padding: 0 ${SPACINGS.space4} 0 ${SPACINGS.space4};
  }
`;
const HeaderContainer = styled.div`
  padding: ${SPACINGS.space4};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 2px ${COLORS.gray} solid;
`;

const InfoLoteContainer = styled.div`
  display: grid;
  column-gap: 15px;
  padding-top: ${SPACINGS.space3};
  height: 300px;
  row-gap: 5px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto;
  grid-template-areas:
    "loteInfoText loteInfoText"
    "nomeInput nomeInput"
    "dateInput dateInput"
    "editText editText"
    "qtyInput priceInput"
    "minBuy  maxBuy"
    "selectPonto selectPonto"
    "switchCPF maxCPF"
    "description description"
    "submitButton submitButton"
    "erroText erroText";

  @media ${breakPoints.tablet} {
    row-gap: 15px;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas:
      "loteInfoText  loteInfoText loteInfoText loteInfoText"
      "nomeInput nomeInput dateInput dateInput"
      "editText editText editText editText"
      "qtyInput priceInput minBuy  maxBuy"
      "selectPonto selectPonto switchCPF maxCPF"
      "description description description description"
      "erroText erroText submitButton submitButton";
  }
`;

const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: visible;
`;

const PontoSelectContainer = styled(Select)`
  border-radius: 5px;
  width: 100%;
`;

// /////////////////////////////////// TEXTs //////////////////////////////

const ArrowTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButtonText = styled.span`
  color: ${COLORS.white};
  font-size: 18px;
  height: 38px;
  white-space: nowrap;
`;
const DescriptionInputText = styled.span`
  font-size: 12px;
  color: ${COLORS.darkGray};
  padding-right: ${SPACINGS.space0};
  white-space: nowrap;
`;
const HeaderText = styled.span`
  padding-left: ${SPACINGS.space2};
  color: ${COLORS.darkGray};
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
`;
const ErroText = styled.span`
  padding-left: 10px;
  color: red;
  height: 38px;
`;
const LimitText = styled.span`
  padding-left: ${SPACINGS.space2};
  font-size: 14px;
  color: ${COLORS.darkGray};
`;
// /////////////////////////////////// INPUTs /////////////////////////////

const CustomInput = styled.input`
  border: 1px solid ${COLORS.gray};
  border-radius: 5px;
  width: 100%;
  height: 38px;
`;
const EditarIngressoInput = styled.input`
  border: 1px solid ${COLORS.gray};
  border-radius: 5px;
  width: 120px;
  height: 34px;
  background: none;
  /* &:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
		-webkit-transition-delay: 9999s;
	} */
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;
// /////////////////////////////////// BUTTONs ////////////////////////////

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
const SubmitButton = styled.button`
  border: none;
  background-color: ${COLORS.middleBlue};
  border-radius: 10px;
  height: 38px;
  width: 100%;
  &:hover {
    background-color: ${COLORS.darkBlue};
  }
`;
// /////////////////////////////////// ICONs //////////////////////////////

const BackArrowIcon = styled(IoMdArrowBack)`
  color: ${COLORS.gray};
  cursor: pointer;
  font-size: 15px;
`;
const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 15px;
`;
const ModalComponent = styled(Modal)`
  width: 100%;
  @media ${breakPoints.sideBarClosed} {
    width: 55%;
  }
`;
