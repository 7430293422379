import styled from "styled-components";

export const TableContainer = styled.div`
  margin: 1rem;
  border-radius: 10px;
  background-color: white;
  border: 2px solid #eae9ed;

  .table-wrapper {
    overflow-x: auto;
    width: 100%;
  }

  table {
    min-width: 100%;
    width: 100%;
    border-collapse: collapse;
    font-family: "Lato", sans-serif;
    table-layout: auto;

    th,
    td {
      padding: 1rem;
      overflow: hidden;
    }
    .breakable {
      word-break: break-word;
    }

    th {
      background-color: #cae5f9;
      font-size: 12px;
      line-height: 14.4px;
      font-weight: 700;
      color: #002d8c;
    }
    td {
      font-size: 14px;
      font-weight: 700;
      color: #171719;
      border-bottom: 1px solid #eae9ed;
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    .empty-row {
      text-align: center;
      td {
        font-weight: unset;
        color: #767380;
      }
    }
  }

  @media (max-width: 768px) {
    .table-wrapper {
      overflow-x: scroll;
    }
    table {
      min-width: 100%;
      width: auto;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #eae9ed;

  .pagination-limit {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    select {
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  }

  .navigation-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    gap: 1rem;

    button {
      background-color: transparent;
      border: solid 1px #b7b6bf;
      cursor: pointer;
      border-radius: 5px;
      padding: 0.5rem;
      min-width: 42px;
      min-height: 42px;

      &.selected {
        background-color: #0050c3;
        color: #ffffff;
      }
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

export const SearchBarElement = styled.div`
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  .card-search {
    width: 50%;
  }
  .button-contained {
    width: fit-content;
  }
  .utils {
    display: flex;
    justify-content: space-between;
    alignitems: center;
  }
  @media (max-width: 768px) {
    .card-search {
      width: 100%;
    }
    .utils {
      flex-direction: column;
      gap: 1rem;
    }
    .button-contained {
      width: 100%;
    }
  }
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  h2 {
    padding: 0 0 0 1rem;
  }

  .drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    h6 {
      color: #767380;
    }
  }

  div {
    h6 {
      color: #171719;
    }

    .card-section-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .card-section-card-content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        span {
          font-size: 14px;
          color: #767380;
          font-weight: 700;
        }
      }
    }
    &.owner-section {
      padding: 1rem;
    }
    &.card-section {
      .ticket-section-header {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          background-color: #f3ecf8;
          color: #767380;
          padding: 0.25rem;
          border-radius: 0.25rem;
          font-weight: bold;
        }
      }
    }
    &.status-badge {
      padding: 1rem;
    }
  }

  button {
    margin: 1rem;
    width: fit-content;
    align-self: end;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const OwnerDetail = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  h6 {
    font-size: 1.25rem;
  }
`;

export const OwnerLabel = styled.span`
  width: 100px;
  margin-right: 2rem;
  font-size: 1rem;
  color: #525059;
  font-weight: bold;
`;

export const OwnerValue = styled.span`
  flex-grow: 1;
  font-size: 1.125rem;
  color: #2f2e33;
`;

export const ContentContainer = styled.div`
  .detail-drawer-container {
    @media (max-width: 768px) {
      nav {
        width: 100% !important;
      }
    }
  }
  .configurations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .card {
    margin: 0;
    border: 1px solid #d3d2d9;
    width: 80%;
    padding: 1rem 2rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .card-header {
    display: none;
  }
  .card-content-info {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 70%;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .card-content-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .card-content-child {
    max-width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
    }
  }
  .enabled {
    color: #00c67f;
  }
  .page-header-subtitle {
    color: #525059;
  }
  .nav-item {
    .nav-link {
      font-weight: bold;
    }
  }
`;

export const ActionButton = styled.button`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  background-color: ${(props) => (props.cancel ? "#e63757" : "transparent")};
  color: ${(props) => (props.cancel ? "#ffffff" : "#171719")};
  border: none;
  font-weight: bold;
`;

export const ModalContent = styled.div`
  color: #171719;
  div{
    padding 1rem 0;
  }
  p {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const ModalTitle = styled.div`
  color: #171719;
  gap: 0.5rem;
  display: inline-flex;
  align-items: center;
`;
