import axios from "axios";

const New_api = axios.create({
  baseURL: process.env.REACT_APP_NEW_URL,
});

New_api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default New_api;
