import React, { useState, useEffect, Fragment } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrashAlt,
  faEyeSlash,
  faEye,
  faSearch,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Api from "services/api";
import DataTable, { createTheme } from "react-data-table-component";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";
import moment from "moment";
import { ToastContainer } from "react-toastr";
import "toastr.css";
import EmptyData from "components/empty-data";
import ModalEquipment from "./modal-equipment/index";
import Loading from "components/loading";
import useDebounce from "customerHooks/useDebounce";

let container;

const EquipmentsList = () => {
  const [equipID, setEquipID] = useState("");
  const [statusModal, setStatusModal] = useState("modal-hidden");

  const [filter, setFilter] = useState({
    name: "",
    serial: "",
    patrimony: "",
  });
  const debounceValue = useDebounce(filter, 500);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [logs, setLogs] = useState([]);

  const updateFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const fetchData = async () => {
    const result = await Api.get(
      `equipments/show?name=${filter.name}&serial=${filter.serial}&patrimony=${filter.patrimony}`
    );
    setData(result.data.data);
    setDataFilter(result.data.data);
  };

  useEffect(() => {
    fetchData();
    if (document.getElementById("loading")) {
      document.getElementById("loading").classList.add("esconde");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  const HandleClick = (id) => {
    Swal.fire({
      title: "Deletar",
      icon: "warning",
      text: "Deseja realmente deletar este equipamento?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        Api.post(`equipments/delete/${id}`)
          .then((res) => {
            container.success("Equipamento deletado com sucesso!");
            fetchData();
          })
          .catch((err) => {
            container.error(err);
          });
      }
    });
  };

  const HandleActivate = (id) => {
    Swal.fire({
      title: "Ativar",
      icon: "warning",
      text: "Deseja realmente ativar este equipamento?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        Api.post(`equipments/activate/${id}`)
          .then((res) => {
            container.success("Equipamento ativado com sucesso!");
            fetchData();
            return true;
          })
          .catch((err) => {
            container.error(err);
            return false;
          });
      }
    });
  };

  const HandleDeactivate = (id) => {
    Swal.fire({
      title: "Desativar",
      icon: "warning",
      text: "Deseja realmente desativar este Equipamento?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        Api.post(`equipments/deactivate/${id}`)
          .then((res) => {
            container.success("Equipamento desativado com sucesso!");
            fetchData();
            return true;
          })
          .catch((err) => {
            Swal.fire("Desativar", "Equipamento não desativado", "error");
            return false;
          });
      }
    });
  };

  createTheme("ticket", {
    text: {
      primary: "#4F6C7C",
      secondary: "#CBC1BF",
      font: "roboto",
    },
    headertext: {
      primary: "#2F81DA",
    },
    divider: {
      default: "#e9f2f2",
    },
    highlightOnHover: {
      default: "#EEEEEE",
      text: "rgba(0, 0, 0, 0.87)",
      divider: {
        default: "#3F25A8",
      },
    },
    button: {
      default: "#3F25A8",
      focus: "#00A7E1",
      hover: "#20C4F4",
      disabled: "rgba(0, 0, 0, 0.12)",
    },
  });

  const columns = [
    {
      name: "Nome",
      selector: "name",
      sortable: true,
    },
    {
      name: "Serial",
      selector: "serial",
      sortable: true,
      filter: true,
    },
    {
      name: "Patrimônio",
      selector: "patrimony",
      sortable: true,
    },
    {
      name: "Status",
      sortable: false,
      cell: (row) =>
        row.status === 1 ? (
          <div className="badge badge-success">Ativo</div>
        ) : (
          <div className="badge badge-danger">Desativado</div>
        ),
    },
    {
      right: true,
      cell: (row) => (
        <>
          <ReactTooltip className="tooltip-custom" effect="solid" place="top" />
          <button
            className="btn-table"
            onClick={() => openModal(row.id)}
            style={{ marginRight: "1px" }}
            data-tip="Editar"
          >
            <FontAwesomeIcon icon={faPen} />
          </button>

          <button
            className="btn-table"
            style={{ marginRight: "1px" }}
            onClick={() => HandleClick(row.id)}
            data-tip="Deletar"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>

          {row.status === 1 ? (
            <button
              className="btn-table"
              onClick={() => HandleDeactivate(row.id)}
              data-tip="Desativar"
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </button>
          ) : (
            <button
              className="btn-table"
              onClick={() => HandleActivate(row.id)}
              data-tip="Ativar"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          )}

          <button
            className="btn-table"
            data-tip="Histórico"
            onClick={() => historic(row.id)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </>
      ),
    },
  ];

  const [selectableRows] = useState(false);
  const [noSelectAll] = useState(false);
  const [selectableRowsHighlight] = useState(false);
  const [expandableRows] = useState(true);
  const [expandOnRowClick] = useState(true);
  const [pagination] = useState(true);
  const [highlight] = useState(false);
  const [striped] = useState(false);
  const [pointer] = useState(false);
  const [dense] = useState(false);
  const [persist] = useState(false);
  const [tableHead] = useState(false);
  const [loading] = useState(false);
  const [noHeader] = useState(true);
  const [subHeader] = useState(false);
  const [subHeaderAlign] = useState("right");
  const [fixedHeader] = useState(false);

  async function historic(id) {
    const getLogs = await Api.get(`equipments/getLogs/${id}`);
    setLogs(getLogs.data);
    openModal2();
  }

  function openModal2() {
    setIsOpen(true);
  }

  function closeModal2() {
    setIsOpen(false);
  }

  function openModal(id) {
    setEquipID(id);
    setStatusModal("modal-show");
  }

  function closeModal(e) {
    setEquipID("");
    setStatusModal("modal-hidden");
    fetchData();
    if (document.getElementById("loading")) {
      document.getElementById("loading").classList.add("esconde");
    }
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-40%, -50%)",
      overflow: "scroll",
      height: "500px",
      width: "1000px",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };

  const SampleExpandedComponent = ({ data }) => (
    <div className="subRow">
      <div
        className="row margin0"
        style={{
          paddingTop: "15px",
          paddingBottom: "15px",
          fontSize: "14px",
        }}
      >
        <div className="col-md-4">
          <span className="expanded-title">Categoria: </span>
          <span className="expanded-content">
            {" "}
            {data.categories_equipments.name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="expanded-title">Preço padrão: </span>{" "}
          <span className="expanded-content">
            {data.categories_equipments.default_price}
          </span>
        </div>
      </div>
      <div
        className="row margin0"
        style={{
          paddingTop: "15px",
          paddingBottom: "15px",
          fontSize: "14px",
        }}
      >
        <div className="col-md-12">
          <span className="expanded-title">Descrição: </span>{" "}
          <span className="expanded-content">
            {" "}
            {data.categories_equipments.description}
          </span>
        </div>
      </div>
      <div
        className="row margin0"
        style={{
          paddingTop: "15px",
          paddingBottom: "15px",
          fontSize: "14px",
        }}
      >
        <div className="col-md-12">
          <span className="expanded-title">Anotações do Equipamento: </span>{" "}
          <span className="expanded-content"> {data.description}</span>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Loading title="aguarde..." />
      <PageHeader
        title="Equipamentos"
        text="Gerencia os equipamentos cadastrados no sistema."
        btnAction={() => openModal(null)}
        btnText="criar equipamento"
      ></PageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="col-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  placeholder="Pesquisar por nome"
                  className="input-custom"
                  name="name"
                  value={filter.name}
                  onChange={updateFilter}
                />
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  placeholder="Pesquisar pelo serial"
                  className="input-custom"
                  name="serial"
                  value={filter.serial}
                  onChange={updateFilter}
                />
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="card-search">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  type="text"
                  placeholder="Pesquisar pelo Patrimônio"
                  className="input-custom"
                  name="patrimony"
                  value={filter.patrimony}
                  onChange={updateFilter}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <DataTable
            columns={columns}
            data={dataFilter}
            defaultSortField="title"
            selectableRows={selectableRows}
            selectableRowsNoSelectAll={noSelectAll}
            selectableRowsHighlight={selectableRowsHighlight}
            expandableRows={expandableRows}
            expandOnRowClicked={expandOnRowClick}
            expandableRowsComponent={<SampleExpandedComponent />}
            pagination={pagination}
            paginationComponentOptions={{
              rowsPerPageText: "Resultados por páginas:",
              rangeSeparatorText: "de",
              noRowsPerPage: false,
            }}
            highlightOnHover={highlight}
            striped={striped}
            pointerOnHover={pointer}
            dense={dense}
            noTableHead={tableHead}
            persistTableHead={persist}
            progressPending={loading}
            noHeader={noHeader}
            noDataComponent={<EmptyData title="Nenhum resultado disponível." />}
            conditionalRowStyles={[
              {
                when: (row) => row.expanded,
                style: {
                  backgroundColor: "#3F25A8",
                  color: "white",
                  button: {
                    color: "white",
                  },
                  // '&:hover': {
                  //   cursor: 'pointer',
                  // },
                },
              },
            ]}
            onRowExpandToggled={(toggleState, row) => {
              row.expanded = toggleState;
            }}
            subHeader={subHeader}
            theme="ticket"
            subHeaderAlign={subHeaderAlign}
            // subHeaderAlign={subHeaderAlign}
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight="300px"
          />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          contentLabel="Adicionar produto"
        >
          <div className="row">
            <div className="col-md-12" align="right">
              <button className="btn btn-info" onClick={closeModal2}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <div>
            <div className="col-md-12 mt-2">
              <table className="table">
                <thead>
                  <tr>
                    <th>Usuário</th>
                    <th>Evento</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((item, i) => (
                    <tr key={i}>
                      <td>{item.username}</td>
                      <td>{item.name}</td>
                      <td>{moment(item.date).format("DD/MM/YYYY - HH:MM")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
        <ModalEquipment
          status={statusModal}
          id={equipID}
          callback={closeModal}
        />
      </div>
    </Fragment>
  );
};

export default EquipmentsList;
