import React from "react";
import ModernDrawer from "react-modern-drawer";
import PropTypes from "prop-types";
import "react-modern-drawer/dist/index.css";

PropTypes.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export const Drawer = ({ children, open, onClose, width }) => {
  const drawerWidth = width || "31.125rem";
  return (
    <ModernDrawer
      style={{
        width: drawerWidth,
      }}
      children={children}
      open={open}
      onClose={onClose}
      direction="right"
    />
  );
};
