import React from "react";
import "./breadcrumb.css";

const BreadcrumbItem = ({ children, isLast, ...props }) => (
  <li
    className={`breadcrumb-item ${isLast ? "breadcrumb-last-item " : ""}`}
    {...props}
  >
    {children}
  </li>
);

const BreadcrumbSeparator = ({ children, ...props }) => (
  <li className="breadcrumb-separator" {...props}>
    {children}
  </li>
);

const Breadcrumb = ({ separator = ">", ...props }) => {
  let children = React.Children.toArray(props.children);

  children = children.map((child, index) => (
    <BreadcrumbItem
      key={`breadcrumb_item${index}`}
      isLast={index === children.length - 1}
    >
      {child}
    </BreadcrumbItem>
  ));

  const lastIndex = children.length - 1;

  children = children.reduce((acc, child, index) => {
    const notLast = index < lastIndex;
    if (notLast) {
      acc.push(
        child,
        <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>
          {separator}
        </BreadcrumbSeparator>
      );
    } else {
      acc.push(child);
    }
    return acc;
  }, []);

  return <ol>{children}</ol>;
};

export default Breadcrumb;
