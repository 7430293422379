import { useOnClickOutside } from 'App';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input';
import { ToastContainer } from 'react-toastr';
import api from 'services/api';

let container;

const ModalIventoryItem = (props) => {

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => { if (props.status === 'modal-show') { close() } });

  const [data, setData] = useState(
    {
      name: '',
      cost: '',
      code: '',
      quantity: 0,
      price_location: '',
      price_lost: '',
    }
  );

  const updateData = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (props.item?.id) {
      setData(props.item);
    }
  }, [props.item]);

  function close() {

    props.callback('modal-hidden');
    setTimeout(() => {
      setData(
        {
          name: '',
          cost: '',
          code: '',
          quantity: 0,
          price_location: '',
          price_lost: '',
        }
      );
    }, 200);
  };

  function save() {
    let object = data;
    if (!props.item?.id) {
      object.code = codeMaker();
      api.post(`inventory/new`, object).then(res => {
        container.success('Item criado com suceeso!');
        close();
      }).catch(err => {
        container.err(err.response.data.message);
      });
    } else {
      api.post(`inventory/update/${object.id}`, object).then(res => {
        container.success('Item editado com suceeso!');
        close();
      }).catch(err => {
        container.err(err.response.data.message);
      });;
    }
  }

  function codeMaker() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div ref={ref} className={`shadow side-modal ${props.status}`}>
        <div className="card-header">
          <h4 className="card-header-title">{props.id ? 'Editar Iem' : 'Cadastrar Item'}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="col-md-12">
              <label>Nome do Item</label>
              <input
                className="form-control"
                value={data.name}
                onChange={updateData}
                name="name"
                placeholder="Ex: Balde"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Custo</label>
              <CurrencyInput
                value={data.cost}
                name="cost"
                className="form-control"
                prefix="R$ "
                onChangeEvent={(e, maskedvalue, floatvalue) => {
                  data.cost = floatvalue;
                  setData({ ...data });
                }}
              />
            </div>
            <div className="col-md-4">
              <label>Preço de Locação</label>
              <CurrencyInput
                value={data.price_location}
                name="price_location"
                className="form-control"
                prefix="R$ "
                onChangeEvent={(e, maskedvalue, floatvalue) => {
                  data.price_location = floatvalue;
                  setData({ ...data });
                }}
              />
            </div>
            <div className="col-md-4">
              <label>Custo de Perda</label>
              <CurrencyInput
                value={data.price_lost}
                name="price_lost"
                className="form-control"
                prefix="R$ "
                onChangeEvent={(e, maskedvalue, floatvalue) => {
                  data.price_lost = floatvalue;
                  setData({ ...data });
                }}
              />
            </div>
          </div>
          <div className="box-button">
            <button className="btn btn-primary col-md-6 c3" onClick={save}>
              Salvar informações
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalIventoryItem;
