import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import styled from "styled-components";
import { COLORS } from "services/constants";
import { breakPoints } from "services/breakPoints";
import { IoIosCloseCircleOutline, IoMdArrowBack } from "react-icons/io";

SideModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
};

export default function SideModal(props) {
  const { show, title, containerClassName, children, onClose } = props;

  return (
    <ModalComponent
      isOpen={show}
      onRequestClose={onClose}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      <ModalContainer className={`${containerClassName || ""}`}>
        <HeaderContainer>
          <ArrowTextContainer>
            <BackArrowIcon size={23} onClick={onClose} />
            <HeaderText>{title}</HeaderText>
          </ArrowTextContainer>
          <CloseButton onClick={onClose}>
            <CloseIcon size={25} />
          </CloseButton>
        </HeaderContainer>

        {children}
      </ModalContainer>
    </ModalComponent>
  );
}

//----- Styled Components ------//
const ModalComponent = styled(Modal)`
  width: 100%;
  outline: none;
  @media ${breakPoints.sideBarClosed} {
    width: 55%;
  }
`;

const ModalContainer = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  outline: none;
`;

const HeaderContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 1px #d8e2e7 solid;
`;

const ArrowTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.span`
  padding-left: 25px;
  color: #98afbc;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-weight: 500;
`;

const BackArrowIcon = styled(IoMdArrowBack)`
  color: ${COLORS.gray};
  cursor: pointer;
  font-size: 15px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;

const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 15px;
`;
