const defaultOptions = {
  locale: "pt-BR",
  format: "short",
};

/**
 * @param {Date} date
 * @param {object} options
 * @param {string} options.locale
 * @param {'long' | 'short'} options.format
 * **/
export const monthName = (date, options) => {
  const { locale, format } = { ...defaultOptions, ...options };
  return date.toLocaleString(locale, { month: format }).replace(".", "");
};
