/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React from "react";
import Switch from "react-switch";
import styled from "styled-components";

import { BiLoaderAlt, BiTrashAlt } from "react-icons/bi";
import { BsEye, BsEyeSlash, BsLink45Deg } from "react-icons/bs";

import Toast from "components/Toast/Toast";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { useTranslation } from "react-i18next";
import { IoIosSave } from "react-icons/io";
import { ToastContainer } from "react-toastr";
import api from "services/api";
import { COLORS, SPACINGS } from "services/constants";
import Swal from "sweetalert2";
import { formatCurrency } from "utils";
import ModalPublic from "./ModalPublic";
import ModalVincular from "./ModalVincular";

IngressosTable.propTypes = {
  type: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    sold: PropTypes.number,
    qty: PropTypes.number,
    active: PropTypes.number,
    is_public: PropTypes.number,
    available: PropTypes.number,
    type: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  ticketState: PropTypes.shape({
    ticketData: PropTypes.shape({
      sectorLot: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.number,
        qty: PropTypes.number,
      }),
      types: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          active: PropTypes.number,
          qty: PropTypes.number,
        })
      ),
    }),
    setTicketData: PropTypes.func,
  }),
  setRefetch: PropTypes.func,
  loteQty: PropTypes.number,
  calcPercentVendidos: PropTypes.func,
};

export default function IngressosTable(props) {
  const {
    type,
    isOpen,
    ticketState,
    calcPercentVendidos,
    loteQty,
    setRefetch,
  } = props;
  const { ticketData, setTicketData } = ticketState;
  const { currency } = useCurrencyEventContext();
  const currentTypeIndex = ticketData.types
    .map((currentType) => currentType.id)
    .indexOf(type.id);
  const [isLoading, setisLoading] = React.useState(false);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [publicIsLoading, setPublicIsLoading] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(type.is_public);
  const [modalVincularIsOpen, setModalVincularIsOpen] = React.useState(false);
  const [lastQtyValidValue, setLastQtyValidValue] = React.useState(
    ticketData.types[currentTypeIndex]?.qty
  );
  const [modalPublicIsOpen, setModalPublicIsOpen] = React.useState(false);
  const [toastConfs, setToastConfs] = React.useState({
    type: "",
    toastText: "",
    showTime: 0,
    showToast: false,
  });
  const { t } = useTranslation();

  let container;

  function checkPublicAndOpenModal() {
    if (isPublic) {
      setModalPublicIsOpen(true);
      return;
    }
    setModalVincularIsOpen(true);
  }
  function submitQty(event) {
    if (event.key === "Enter") {
      // eslint-disable-next-line no-undef
      document.getElementById("submitQty").blur();
      changeTicketQty();
    }
  }

  function updateData(event) {
    let parsedIntQty = event.target.value;
    if (event.target.value) {
      parsedIntQty = parseInt(parsedIntQty, 10);
    }
    setLastQtyValidValue(parsedIntQty);
  }
  function AlertSwalToggle() {
    if (!ticketData.sectorLot.status) {
      Swal.fire({
        icon: "info",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#95aac9",
        text: t("components:tickets.ticketsTable.activeLot"),
        confirmButtonText: t("global:confirmButtonText"),
        customClass: {
          container: "swal-wide",
        },
      });
    }
  }

  function changeType({ changeActive, changeQty, changeStatus }) {
    let { status } = ticketData.sectorLot;
    if (changeStatus !== undefined) {
      status = changeStatus;
    }

    return {
      sectorLot: {
        id: ticketData.sectorLot.id,
        status,
        qty: ticketData.sectorLot.qty,
      },
      types: ticketData.types.map((compareType) => {
        if (compareType.id === type.id) {
          let { active, qty } = compareType;
          if (changeActive !== undefined) {
            active = changeActive;
          }
          if (changeQty !== undefined) {
            qty = changeQty;
          }

          return {
            id: compareType.id,
            active,
            qty,
          };
        }
        return compareType;
      }),
    };
  }

  function togglePublic() {
    setPublicIsLoading(true);

    const changePublic = {
      id: type.id,
      isPublic: !isPublic,
    };

    api
      .post("event/ticket/type/public", changePublic)
      .then(() => {
        setIsPublic(!isPublic);
        setToastConfs({
          type: "success",
          toastText: t(
            "components:tickets.ticketsTable.ticketChangedSuccessfully"
          ),
          showTime: 3500,
          showToast: true,
        });
      })
      .catch(() => {
        setToastConfs({
          type: "error",
          toastText: t(""),
          showTime: 3500,
          showToast: true,
        });
      })
      .finally(() => {
        setPublicIsLoading(false);
      });
  }

  function sendChanges() {
    setisLoading(true);
    let newActiveNumber = 0;
    if (ticketData.types[currentTypeIndex]?.active === 0) {
      newActiveNumber = 1;
    }
    const typeDataToSend = changeType({ changeActive: newActiveNumber });
    updateType(typeDataToSend);
  }

  function changeTicketQty() {
    if (lastQtyValidValue > loteQty) {
      setToastConfs({
        type: "error",
        toastText: t(
          "components:tickets.ticketsTable.ticketQtyExceedsAvailable"
        ),
        showTime: 3500,
        showToast: true,
      });
      setLastQtyValidValue(ticketData.types[currentTypeIndex].qty);
      return;
    }
    if (lastQtyValidValue < type.sold) {
      setToastConfs({
        type: "error",
        toastText: t("components:tickets.ticketsTable.ticketQtyLessThanSold"),
        showTime: 3500,
        showToast: true,
      });
      setLastQtyValidValue(type.sold);
      return;
    }
    if (!lastQtyValidValue || lastQtyValidValue < 0) {
      setToastConfs({
        type: "error",
        toastText: t("components:tickets.ticketsTable.invalidTicketQty"),
        showTime: 3500,
        showToast: true,
      });
      setLastQtyValidValue(type.sold);
      return;
    }
    setSaveLoading(true);
    const updateTicket = changeType({ changeQty: lastQtyValidValue });
    updateType(updateTicket);
  }

  function updateType(updatedTypeData) {
    api
      .post("update/sectorlot", updatedTypeData)
      .then(() => {
        setTicketData(updatedTypeData);
        setToastConfs({
          type: "success",
          toastText: t("components:tickets.ticketsTable.changedSuccessfully"),
          showTime: 3500,
          showToast: true,
        });
      })
      .catch(() => {
        setToastConfs({
          type: "error",
          toastText: t("global:errors.internalServerError"),
          showTime: 3500,
          showToast: true,
        });
        setLastQtyValidValue(ticketData.types[currentTypeIndex].qty);
      })
      .finally(() => {
        setSaveLoading(false);
        setisLoading(false);
      });
  }

  function deleteTicketType(index) {
    const ticketTypeId = ticketData.types[index].id;

    api
      .delete(`event/ticket-type/${ticketTypeId}`)
      .then(() => {
        container.success(
          t("components.tickets.ticketsTable.deletedSuccessfully")
        );
        setRefetch(true);
      })
      .catch(() => {
        container.error(
          t("components:tickets.ticketsTable.errorDeletingTicket")
        );
        setRefetch(true);
      })
      .finally(() => {});
  }

  return (
    type.type && (
      <LoteContainer
        className="col-12 p-0"
        style={
          isOpen
            ? {
                height: "42px",
                visibility: "visible",
                transition: "all 350ms ease-in-out",
                opacity: 1,
              }
            : {
                height: "0px",
                opacity: 0,
                visibility: "hidden",
                transition: "all 350ms ease-in-out",
              }
        }
      >
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <NameLoteText
          style={
            ticketData.types[currentTypeIndex]?.active
              ? {
                  transition: "all 350ms ease-in-out",
                  opacity: 1,
                }
              : {
                  opacity: 0.4,
                  transition: "all 350ms ease-in-out",
                }
          }
          className="col-3 justify-content-start"
        >
          {type.type}
        </NameLoteText>
        <ValueLoteText
          style={
            ticketData.types[currentTypeIndex]?.active
              ? {
                  transition: "all 350ms ease-in-out",
                  opacity: 1,
                }
              : {
                  opacity: 0.4,
                  transition: "all 350ms ease-in-out",
                }
          }
          className="col-2 p-0"
        >
          {formatCurrency(type.value, { isCents: false, currency })}
        </ValueLoteText>
        <RowContainer className="col-2 justify-content-end">
          <div
            style={{
              width: "130px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <InputVendido
              style={
                ticketData.types[currentTypeIndex]?.active
                  ? {
                      transition: "all 350ms ease-in-out",
                      opacity: 1,
                    }
                  : {
                      opacity: 0.4,
                      transition: "all 350ms ease-in-out",
                    }
              }
              disabled
              name="sold"
              value={type.sold}
            />
            <InputPendente
              style={
                ticketData.types[currentTypeIndex]?.active
                  ? {
                      transition: "all 350ms ease-in-out",
                      opacity: 1,
                    }
                  : {
                      opacity: 0.4,
                      transition: "all 350ms ease-in-out",
                    }
              }
              disabled
              name="pending"
              value={Math.max(0, type.qty - (type.sold + type.available))}
            />
          </div>
        </RowContainer>
        <RowContainer className="col-2 pl-2 justify-content-between">
          <InputDisponivel
            style={
              ticketData.types[currentTypeIndex]?.active
                ? {
                    transition: "all 350ms ease-in-out",
                    opacity: 1,
                  }
                : {
                    opacity: 0.4,
                    transition: "all 350ms ease-in-out",
                  }
            }
            name="available"
            type="number"
            id="submitQty"
            onChange={updateData}
            value={lastQtyValidValue}
            onKeyDown={submitQty}
          />

          <SaveButtonContainer>
            <SaveButton
              disabled={
                lastQtyValidValue === ticketData.types[currentTypeIndex]?.qty
              }
              onClick={changeTicketQty}
            >
              {saveLoading ? (
                <LoadingIcon color={COLORS.darkBlue} size={20} />
              ) : (
                <SaveIcon
                  style={
                    lastQtyValidValue !==
                    ticketData.types[currentTypeIndex]?.qty
                      ? {
                          opacity: 1,
                          transition: "all 350ms ease-in-out",
                        }
                      : {
                          opacity: 0,
                          transition: "all 350ms ease-in-out",
                        }
                  }
                />
              )}
            </SaveButton>
          </SaveButtonContainer>

          <ProgressBarContainer>
            <ProgressBarContentContainer
              style={
                ticketData.types[currentTypeIndex]?.active
                  ? {
                      transition: "all 350ms ease-in-out",
                      opacity: 1,
                    }
                  : {
                      opacity: 0.4,
                      transition: "all 350ms ease-in-out",
                    }
              }
            >
              <ProgressPendenteBarProgressContainer
                style={{
                  width: calcPercentVendidos(
                    type.sold + type.qty - (type.sold + type.available),
                    type.qty
                  ),
                }}
              />
              <ProgressBarProgressContainer
                style={{
                  width: calcPercentVendidos(type.sold, type.qty),
                  marginLeft: -calcPercentVendidos(
                    type.sold + type.qty - (type.sold + type.available),
                    type.qty
                  ),
                }}
              />
            </ProgressBarContentContainer>
          </ProgressBarContainer>
        </RowContainer>
        <RowContainer className="col-2 justify-content-center align-itens-center p-0">
          <DisponibilidadeSpacingContainer />
          {isLoading ? (
            <LoadingContainer>
              <LoadingIcon color={COLORS.darkBlue} size={20} />
            </LoadingContainer>
          ) : (
            <LoadingContainer>
              <AlertSwalButton
                style={
                  ticketData.types[currentTypeIndex]?.active
                    ? {
                        transition: "all 350ms ease-in-out",
                        opacity: 1,
                      }
                    : {
                        opacity: 0.4,
                        transition: "all 350ms ease-in-out",
                      }
                }
                onClick={() => AlertSwalToggle()}
              >
                <Switch
                  handleDiameter={16}
                  onColor="#75d1f2"
                  onHandleColor={COLORS.middleBlue}
                  offColor={COLORS.weakGray}
                  offHandleColor={COLORS.gray}
                  height={12}
                  width={28}
                  disabled={!ticketData.sectorLot.status}
                  onChange={() => sendChanges()}
                  checked={!!ticketData.types[currentTypeIndex]?.active}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </AlertSwalButton>
            </LoadingContainer>
          )}

          <RowContainer
            style={{ height: "42px", alignItems: "center", width: "100%" }}
          >
            <DisbonibilidadeText
              style={
                ticketData.types[currentTypeIndex]?.active
                  ? {
                      color: COLORS.middleBlue,
                      transition: "all 350ms ease-in-out",
                      opacity: 1,
                    }
                  : {
                      color: COLORS.gray,
                      transition: "all 350ms ease-in-out",
                      opacity: 0.4,
                    }
              }
            >
              {ticketData.types[currentTypeIndex]?.active
                ? t("components:tickets.ticketsTable.available")
                : t("components:tickets.ticketsTable.unavailable")}
            </DisbonibilidadeText>
          </RowContainer>
        </RowContainer>
        <ActionButtonsContainer className="col-1 p-0 justify-content-end">
          <ActionButtons onClick={() => deleteTicketType(currentTypeIndex)}>
            <BiTrashAlt
              style={
                ticketData.types[currentTypeIndex]?.active
                  ? {
                      transition: "all 350ms ease-in-out",
                      opacity: 1,
                    }
                  : {
                      opacity: 0.4,
                      transition: "all 350ms ease-in-out",
                    }
              }
              color={COLORS.darkGray}
              size={14}
            />
          </ActionButtons>
          <ActionButtons onClick={() => checkPublicAndOpenModal()}>
            <BsLink45Deg
              style={
                ticketData.types[currentTypeIndex]?.active
                  ? {
                      transition: "all 350ms ease-in-out",
                      opacity: 1,
                    }
                  : {
                      opacity: 0.4,
                      transition: "all 350ms ease-in-out",
                    }
              }
              color={isPublic ? COLORS.gray : COLORS.darkGray}
              size={14}
            />
          </ActionButtons>
          <ActionButtons onClick={() => togglePublic()}>
            {publicIsLoading ? (
              <LoadingIcon color={COLORS.darkBlue} size={14} />
            ) : isPublic ? (
              <BsEye
                style={
                  ticketData.types[currentTypeIndex]?.active
                    ? {
                        transition: "all 350ms ease-in-out",
                        opacity: 1,
                      }
                    : {
                        opacity: 0.4,
                        transition: "all 350ms ease-in-out",
                      }
                }
                color={COLORS.darkGray}
                size={14}
              />
            ) : (
              <BsEyeSlash
                style={
                  ticketData.types[currentTypeIndex]?.active
                    ? {
                        transition: "all 350ms ease-in-out",
                        opacity: 1,
                      }
                    : {
                        opacity: 0.4,
                        transition: "all 350ms ease-in-out",
                      }
                }
                color={COLORS.gray}
                size={14}
              />
            )}
          </ActionButtons>
        </ActionButtonsContainer>

        <ModalVincular
          toggleFunction={{ modalVincularIsOpen, setModalVincularIsOpen }}
          typeId={ticketData.types[currentTypeIndex]?.id}
        />

        <ModalPublic
          toggleFunction={{ modalPublicIsOpen, setModalPublicIsOpen }}
          togglePublic={togglePublic}
        />

        {toastConfs.showToast && (
          <Toast
            type={toastConfs.type}
            toastText={toastConfs.toastText}
            showToast={toastConfs.showToast}
            setToastConfs={setToastConfs}
            showTime={toastConfs.showTime}
          />
        )}
      </LoteContainer>
    )
  );
}
// /////////////////////////////////// CONTAINERs /////////////////////////
const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 40px;
  width: 100%;
  padding-left: ${SPACINGS.space1};
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ProgressBarContainer = styled.div`
  display: none;
  @media (min-width: 1150px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
const DisponibilidadeSpacingContainer = styled.div`
  display: none;
  @media (min-width: 1150px) {
    height: 42px;
    align-items: center;
    width: 100%;
    display: flex;
  }
`;
const LoteContainer = styled.div`
  height: 42px;
  display: flex;
  background-color: ${COLORS.lightGray};
  border: none;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
const ProgressBarContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 6px;
  border-radius: 3px;
  width: 120px;
  background-color: ${COLORS.weakGray};
`;

const ProgressBarProgressContainer = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 30px;
  background-color: ${COLORS.middleBlue};
`;

const ProgressPendenteBarProgressContainer = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 30px;
  background-color: #44d1f4;
`;
// /////////////////////////////////// TEXTs /////////////////////////

const DisbonibilidadeText = styled.p`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: 2px 0 0 ${SPACINGS.space1};
`;

const ValueLoteText = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: center;
  height: 32px;
  font-size: 12px;
  color: ${COLORS.darkGray};
`;

const NameLoteText = styled.span`
  padding-left: calc(${SPACINGS.space1} + ${SPACINGS.space5});
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: center;
  height: 32px;
  font-size: 12px;
  color: ${COLORS.darkGray};
`;
// /////////////////////////////////// INPUTs /////////////////////////

const InputVendido = styled.input`
  outline: none;
  border: none;
  width: 65px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  filter: brightness(96%);
  background-color: ${COLORS.lightGray};
  color: ${COLORS.darkGray};
  height: 25px;
`;

const InputPendente = styled.input`
  outline: none;
  border: none;
  width: 65px;
  text-align: center;
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  filter: brightness(96%);
  background-color: ${COLORS.lightGray};
  color: ${COLORS.gray};
  height: 25px;
`;

const InputDisponivel = styled.input`
  outline: none;
  border: none;
  width: 65px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
  filter: brightness(96%);
  height: 25px;
  background-color: ${COLORS.lightGray};
  color: ${COLORS.darkGray};
  &:focus {
    border: 1px ${COLORS.darkBlue} solid;
  }
`;

// /////////////////////////////////// BUTTONs /////////////////////////

const ActionButtons = styled.button`
  border: none;
  background-color: transparent;
  padding: 0 ${SPACINGS.space2} 0 0;
`;
const SaveButton = styled.button`
  border: none;
  background-color: transparent;
`;
const AlertSwalButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
`;

// /////////////////////////////////// ICONs ////////////////////////////

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

const SaveIcon = styled(IoIosSave)`
  color: ${COLORS.darkBlue};
  font-size: 18px;
`;
