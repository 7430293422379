import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./styles.css";

export default function ImageFileUpload({
  id,
  image,
  label,
  loading,
  onChange,
  placeholder,
  resizeWidth,
  resizeHeight,
  dataClear,
}) {
  const [fileName, setFileName] = useState();
  const [uploadImage, setUploadImage] = useState();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const image = event.target.value;

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        if (resizeWidth && resizeHeight) {
          img.onload = () => {
            const elem = document.createElement("canvas");
            elem.width = resizeWidth;
            elem.height = resizeHeight;

            const ctx = elem.getContext("2d");
            ctx.fillStyle = "#FFF";
            ctx.fillRect(0, 0, resizeWidth, resizeHeight);
            ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight);

            ctx.canvas.toBlob(
              (blob) => {
                const result = new File([blob], file.name, {
                  type: "image/png",
                  lastModified: Date.now(),
                });

                onChange(image, result);
                setFileName(file.name);
                setUploadImage(reader.result);
              },
              "image/jpeg",
              1
            );
          };
        } else {
          onChange(image, reader.result, file);
          setFileName(file.name);
          setUploadImage(reader.result);
        }
      };

      reader.onerror = (error) => {};
    }
  };

  useEffect(() => {
    if (image && !uploadImage) {
      setFileName("Procurar...");
      setUploadImage(image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    if (dataClear) {
      setFileName();
      setUploadImage();
    }
  }, [dataClear]);

  return (
    <div className="row">
      <div className={uploadImage ? "col-md-9" : "col-md-12"}>
        <label>{label}</label>
        <div className="input-group" id={`${id}-image`}>
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faImage} />
              &nbsp; Imagem
            </span>
          </div>
          <div className="custom-file">
            <input
              type="file"
              accept="image/png, image/jpeg"
              className="custom-file-input"
              id={id}
              onChange={handleImageChange}
              aria-describedby="inputGroupFileAddon01"
              disabled={loading}
            ></input>
            <label
              htmlFor={id}
              className="custom-file-label"
              id={`${id}-label`}
            >
              <div
                hidden={!loading}
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              {!loading && <>{fileName || placeholder}</>}
            </label>
          </div>
        </div>
      </div>

      {uploadImage && (
        <div className="col-md-3">
          <div
            className="upload-image"
            style={{ backgroundImage: `url(${fileName ? uploadImage : ""})` }}
          ></div>
        </div>
      )}
    </div>
  );
}
