import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import "react-datepicker/dist/react-datepicker.css";
import "./i18n";
import { TranslationWorkaroundWrapper } from "components/translation-workaround-wrapper";

ReactDOM.render(
  <Suspense fallback={<></>}>
    <TranslationWorkaroundWrapper>
      <App />
    </TranslationWorkaroundWrapper>
  </Suspense>,
  document.getElementById("root")
);
