import React from "react";

import "./styles.css";
import csswelcome from "cssdinamico";

import SidebarCaadastro from "components/cadastroevento/sidebarcadastro";
import Cadastro1 from "components/cadastroevento/cadastroevento1";
import Cadastro2 from "components/cadastroevento/cadastroevento2";
import Cadastro3 from "components/cadastroevento/cadastroevento3";
import Cadastro4 from "components/cadastroevento/cadastroevento4";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import api from "services/api";

export default class CreateEvent extends React.Component {
  state = {
    processing: false,
    accept: false,
    posicao: 1,
    categories: [],
    dates: [],
    sectors: [{ name: "Geral" }],
    lots: [{ name: "1º Lote" }],
    locations: [],
    dateLocations: [],
    ticketTypes: [
      { name: "Inteira", discount: null },
      { name: "Meia", discount: 50 },
    ],
    event: {
      hasTickets: 1,
      organizations: [],
      categories: [],
      age_range: 18,
      name: "",
      description: "",
      banner: "",
      tags: [],
      taxes: "Repassar",
      is_public: 0,
      is_online: 0,
      slug: "",
      ticket_nomenclature: "Ingresso",
    },
  };

  constructor(props) {
    super(props);
    csswelcome();
  }

  acceptTerms() {
    let { event, dates } = this.state;
    event.name = event.name.trim();
    if (!event.name.length) {
      Swal.fire({
        title: "Falha",
        icon: "error",
        text: "Favor inserir um nome",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#95aac9",
        confirmButtonText: "Ok",
      });
      return false;
    }

    if (!dates.length) {
      Swal.fire({
        title: "Falha",
        icon: "error",
        text: "Favor inserir uma data",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#95aac9",
        confirmButtonText: "Ok",
      });

      return false;
    }
    return true;
  }

  configureSectorLot() {
    return new Promise((resolve) => {
      let { dates, sectors, lots, ticketTypes, event } = this.state;
      if (!sectors.length) {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Favor inserir um setor",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });

        resolve(false);
      }

      if (!lots.length) {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Favor inserir um lote",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });

        resolve(false);
      }

      if (!ticketTypes.length) {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Favor adicionar um tipo de ingresso",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });
        resolve(false);
      }
      this.setState({ processing: true });

      sectors = sectors.filter((sec) => {
        return sec.name && sec.name.trim().length;
      });

      lots = lots.filter((lot) => {
        return lot.name && lot.name.trim().length;
      });

      dates = dates.map((date) => {
        date.sectors = sectors.map((sector) => {
          sector.active = true;
          sector.ticketTypes = ticketTypes.map((type) => {
            type.active = true;
            return Object.assign({}, type);
          });
          sector.lots = lots.map((lot) => {
            lot.active = true;
            return Object.assign({}, lot);
          });
          return Object.assign({}, sector);
        });
        return Object.assign({}, date);
      });

      event.hasTickets = 1;
      this.setState({ sectors, lots, dates, event }, () => {
        resolve(true);
      });
    });
  }

  configureTickets() {
    return new Promise((resolve) => {
      this.setState({ processing: true });
      let { dates } = this.state;
      let dateLocations = [];
      dates.forEach((date) => {
        let { end_date, start_date, location, name, title } = date;
        let dateLoc = {
          end_date,
          start_date,
          location,
          name,
          title,
          sectors: [],
        };
        date.sectors.forEach((sector) => {
          if (sector.active) {
            let sec = { name: sector.name, lots: [] };
            sector.lots.forEach((lot) => {
              if (lot.active) {
                let new_lot = { name: lot.name, qty: 0, price: 0, types: [] };
                sector.ticketTypes.forEach((type) => {
                  if (type.active) {
                    let { name, discount } = type;
                    new_lot.types.push({
                      name,
                      discount: discount || null,
                      qty: null,
                    });
                  }
                });
                sec.lots.push(new_lot);
              }
            });
            dateLoc.sectors.push(sec);
          }
        });
        dateLocations.push(dateLoc);
      });
      this.setState({ dateLocations });
      resolve(true);
    });
  }

  saveEvent = () => {
    let { tags } = this.state.event;
    let tagsstring = "";
    if (tags.length > 0) {
      tags.forEach((t) => {
        tagsstring += t + ",";
      });
      tagsstring = tagsstring.substring(0, tagsstring.length - 1);
    }

    this.setState((prevState) => ({
      event: {
        ...prevState.event,
        tags: tagsstring,
      },
    }));

    let event = Object.assign({}, this.state.event);
    let { categories, organizations } = event;
    delete event["categories"];
    delete event["organizations"];
    let { dateLocations, sectors, lots, locations, sectorLots } = this.state;

    if (!dateLocations.length) {
      dateLocations = this.state.dates.map((date) => date);
    } else {
      for (let date of dateLocations) {
        date.sectors.forEach((sec) => {
          sec.lots.forEach((lot) => {
            for (let type of lot.types) {
              type.qty = !Number(type.qty) ? lot.qty : type.qty;
            }
          });
        });
      }
    }

    api
      .post("users/events", {
        event,
        categories,
        organizations,
        sectors,
        lots,
        locations,
        sectorLots,
        dateLocations,
      })
      .then((res) => {
        this.setState({ processing: false, posicao: 5, event_id: res.data });
      })
      .catch((err) => {
        this.setState({ processing: false });

        Swal.fire({
          title: "Falha",
          icon: "error",
          text: err.response.data.message,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      });
  };

  proximaEtapa = async () => {
    const { posicao, sectors } = this.state;
    let workou = true;
    switch (posicao) {
      case 1:
        workou = this.acceptTerms();
        break;
      case 2:
        workou = await this.configureSectorLot();
        break;
      case 3:
        workou = await this.configureTickets();
        break;
      case 4:
        this.saveEvent();
        return;
      //no default
    }
    if (posicao === 2 && sectors.length === 1) {
      await this.configureTickets();
      this.setState({ posicao: posicao + 2 });
    } else if (posicao <= 3 && workou) {
      this.setState({ posicao: posicao + 1 });
    }

    this.setState({ processing: false });
  };

  voltaEtapa = () => {
    let { posicao } = this.state;
    if (posicao >= 2 || posicao <= 4) {
      this.setState({ posicao: posicao - 1 });
    }
  };

  publicar = () => {
    let { event_id } = this.state;
    api
      .post("event/publish", { event_id })
      .then((res) => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: "Evento publicado com sucesso!",
          confirmButtonText: "Ok",
        }).then((result) => {
          window.location.href = "/eventos";
        });
      })
      .catch((e) => {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Houve um erro ao publicar o evento, favor tentar novamente.",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });
      });
  };

  render() {
    return (
      <>
        <div className="row">
          <SidebarCaadastro posicao={this.state.posicao}></SidebarCaadastro>
          <div className="col-md-12 col-lg-10">
            {this.state.posicao === 1 ? (
              <Cadastro1
                categories={this.state.categories}
                event={this.state.event}
                locations={this.state.locations}
                dates={this.state.dates}
              />
            ) : this.state.posicao === 2 || this.state.posicao === 3 ? (
              <Cadastro2
                sectors={this.state.sectors}
                lots={this.state.lots}
                dates={this.state.dates}
                ticketTypes={this.state.ticketTypes}
                posicao={this.state.posicao}
              />
            ) : this.state.posicao === 4 ? (
              <Cadastro3 dateLocations={this.state.dateLocations} />
            ) : (
              <Cadastro4 />
            )}
          </div>
        </div>

        {/* Finalização do evento / Barra de termos de uso */}
        <div className="content mt-3" id="event-accept">
          {this.state.posicao === 5 ? (
            <div className="form-row justify-content-between align-items-center">
              <div className="col-md-4">
                <NavLink
                  className={"btn btn-primary float-right btn-white-border"}
                  to={`/eventos`}
                >
                  ir para área administrativa
                </NavLink>
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-primary float-right btn-white"
                  disabled={!this.state.event.hasTickets}
                  onClick={(e) => {
                    this.publicar();
                  }}
                >
                  Publicar
                </button>
              </div>
            </div>
          ) : (
            <div className="form-row justify-content-between align-items-center">
              {this.state.posicao === 1 ? (
                <div className="col-md-6 mb-2">
                  <div className="form-check form-check-inline">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="form-check-input custom-control-input position-fixed"
                        type="checkbox"
                        id="accept"
                        defaultChecked={this.state.accept}
                        onChange={(e) => {
                          const accept = e.target.checked;
                          this.setState({ accept });
                        }}
                      />
                      <label
                        className="form-check-label custom-control-label"
                        htmlFor="accept"
                      >
                        Declaro que li e <u></u> com os{" "}
                        {/* <NavLink to="/termos-de-uso" target="_blank">
                          <u>termos de uso</u>
                        </NavLink>{" "} */}
                        <span
                          className="link-terms"
                          onClick={() => window.open("/termos-de-uso")}
                        >
                          <u>termos de uso</u>
                        </span>
                        e regras da plataforma.
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-3">
                  <button
                    className="btn btn-primary float-right btn-white-border"
                    style={{ width: "100%" }}
                    onClick={this.voltaEtapa}
                  >
                    Voltar
                  </button>
                </div>
              )}

              {this.state.posicao === 1 && (
                <div className="col-md-3">
                  <button
                    className="btn btn-primary float-right btn-white-border"
                    style={{ width: "100%" }}
                    type="submit"
                    disabled={!this.state.accept || this.state.processing}
                    onClick={() => {
                      let { event } = this.state;
                      event.hasTickets = 0;
                      this.setState({ event }, async () => {
                        let salvar = this.acceptTerms();
                        if (salvar) {
                          this.saveEvent();
                        }
                      });
                    }}
                  >
                    Pular criação de ingressos
                  </button>
                </div>
              )}

              <div className="col-md-3">
                <button
                  className="btn btn-primary float-right btn-white"
                  style={{ width: "100%" }}
                  type="submit"
                  id="next-step-button"
                  disabled={
                    this.state.processing ||
                    (this.state.posicao === 1 && !this.state.accept)
                  }
                  onClick={() => {
                    this.setState({ processing: true });
                    this.proximaEtapa();
                  }}
                >
                  Prosseguir
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
