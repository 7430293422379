import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "services/api";
import { AsyncPaginate } from "react-select-async-paginate";
import { useTranslation } from "react-i18next";

// Time to search
const timeDebounce = 800;

OrganizationsInput.propTypes = {
  callBack: PropTypes.func,
  defaultValue: PropTypes.object,
  menuPlacement: PropTypes.string,
};

let searchOptionsArray = [];
let hasMore;
let currentSearch;
export default function OrganizationsInput(props) {
  const { t } = useTranslation();
  const { callBack, defaultValue, menuPlacement } = props;
  const [value, setValue] = useState(defaultValue);
  async function loadOptions(search, loadedOptions, { page }) {
    currentSearch = search;
    await sleep(timeDebounce);
    if (!currentSearch) currentSearch = null;
    await api
      .post(`organizations/paginationOrganizations`, {
        page,
        perPage: 5,
        search,
      })
      .then((res) => {
        searchOptionsArray = [];
        hasMore = res.data.total > loadedOptions.length;
        if (res.data.data.length > 0) {
          res.data.data.forEach(({ name, id, timezone, currency }) => {
            searchOptionsArray.push({
              label: name,
              value: { id, currency, timezone },
            });
          });
        }
      });

    return {
      options: searchOptionsArray,
      additional: {
        page: page + 1,
      },
      hasMore,
    };
  }

  useEffect(() => {
    if (defaultValue) {
      selectedValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const selectedValue = ({
    label,
    value: { id, timezone, currency },
    ...orgSelected
  }) => {
    if (orgSelected) {
      const inputValue = {
        label: label,
        value: orgSelected,
      };
      setValue(inputValue);
      callBack(id, timezone, currency);
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  return (
    <AsyncPaginate
      value={value}
      menuPlacement={menuPlacement}
      placeholder={t(
        "events:eventList.eventFiltersModal.inputs.organizations.placeholder"
      )}
      loadOptions={loadOptions}
      onChange={(e) => selectedValue(e)}
      additional={{
        page: 1,
      }}
    />
  );
}
