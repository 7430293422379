import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function FreeTicketsByOrigin({ originSales }) {
  const countArray = [];
  const { t } = useTranslation("global");

  originSales.forEach((sale) => {
    sale.types.forEach((type) => {
      const existingType = countArray.find((item) => item.type === type.name);
      if (existingType) {
        existingType.qty += type.qty;
      } else {
        countArray.push({ type: type.name, qty: type.qty });
      }
    });
  });

  return (
    <Fragment>
      <Card>
        <Card.Header className="new-sales-card-title">
          {t("courtesiesByOrigin")}
        </Card.Header>
        <Card.Body className="new-detailed-sales">
          <div className="d-flex mb-2">
            <span
              className="new-sector-title text-start"
              style={{ flexBasis: "50%" }}
            >
              {t("onlineCourtesies")}
            </span>
            <span
              className="new-sector-quantity-information text-end"
              style={{ flexBasis: "50%", textAlign: "right" }}
            >
              {countArray[0].qty}
            </span>
          </div>
        </Card.Body>
        <Card.Body className="new-detailed-sales">
          <div>
            <div className="d-flex justify-content-between mb-2">
              <span
                className="new-sector-title text-start"
                style={{ flexBasis: "50%" }}
              >
                {t("pdvCourtesies")}
              </span>
              <span
                className="new-sector-quantity-information text-end"
                style={{ flexBasis: "50%", textAlign: "right" }}
              >
                {countArray[1].qty + countArray[2].qty}
              </span>
            </div>

            {countArray.map(
              (courtesies, i) =>
                courtesies.type !== "Online" && (
                  <div
                    key={i}
                    className="d-flex flex-row justify-content-between"
                  >
                    <span className="new-type-name">{courtesies.type}</span>
                    <span className="new-type-quantity">{courtesies.qty}</span>
                  </div>
                )
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
}
