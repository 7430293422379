import React, { Fragment, useEffect, useState, useCallback } from "react";
import Loading from "components/loading";
import PageHeader from "components/page-header";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTicketAlt,
  faDollarSign,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Card from "components/card";
import "./styles.css";
import EmptyData from "components/empty-data";
import { Bar } from "react-chartjs-2";
import api from "services/api";
import Swal from "sweetalert2";
import moment from "moment";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart as ChartJS } from "chart.js";
import { useTranslation } from "react-i18next";
import { DatePicker } from "components/datepicker";
import { useLanguageSettings } from "hooks/language-settings";
ChartJS.register(zoomPlugin);

export default function GeneralReport() {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  // States
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    new Date(moment.tz("America/Sao_Paulo").subtract(7, "days"))
  );
  const [endDate, setEndDate] = useState(
    new Date(moment.tz("America/Sao_Paulo"))
  );
  const [loading, setLoading] = useState(true);
  const [dataChart, setDataChart] = useState([]);
  const [chooseChart, setChooseChart] = useState(false);
  const [period, setPeriod] = useState(7);
  const [dataApiResponse, setDataApiResponse] = useState();
  const [totalizers, setTotalizers] = useState({
    totalValue: "",
    totalTickets: "",
  });

  //Functions

  const selectedPeriod = (time) => {
    let currentDate = new Date(moment.tz("America/Sao_Paulo"));
    let pastDate = new Date(
      moment.tz("America/Sao_Paulo").subtract(time, "days")
    );
    setPeriod(time);
    setStartDate(pastDate);
    setEndDate(currentDate);
    dataApi(pastDate, currentDate, time);
  };

  const checkedColorPeriod = (timeSelected) => {
    const ActiveBtns = document.getElementsByClassName("time-course-button");
    for (let index = 0; index < ActiveBtns.length; index++) {
      const element = ActiveBtns[index];
      element.classList.remove("active");
    }

    if (timeSelected)
      document.getElementById("period-" + timeSelected).classList.add("active");
  };

  const changeChart = () => {
    chartType(dataApiResponse, !chooseChart);
    setChooseChart(!chooseChart);
  };

  const dataApi = useCallback(async (start, end, period) => {
    setLoading(true);
    api
      .post("EventSalesDate", {
        start: moment(start).format("YYYY-MM-DD 00:00:00"),
        end: moment(end).format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((res) => {
        checkedColorPeriod(period);
        setPeriod(null);
        setDataApiResponse(res.data);
        chartType(res.data);
        setTotalizers({
          totalValue: res.data.totalizerValue,
          totalTickets: res.data.totalizerTickets,
        });
      })
      .catch(() =>
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        })
      )
      .finally(() => setLoading(false));
  }, []);

  const chartType = (dataChartType, typeChart) => {
    const dataAxis = {
      dates: [],
      totals: [],
    };
    if (!typeChart) {
      dataChartType.salesAmount.forEach((data) => {
        dataAxis.dates.push(data.date);
        dataAxis.totals.push(data.tot);
      });
    } else {
      dataChartType.ticketsAmount.forEach((data) => {
        dataAxis.dates.push(data.date);
        dataAxis.totals.push(data.qty);
      });
    }

    setDataChart({
      labels: dataAxis.dates,
      datasets: [
        {
          label: !typeChart ? "Valor diário" : "Quantidade de ingressos",
          data: dataAxis.totals,
          backgroundColor: !typeChart ? "#600EA3" : "#0050C3",
          barPercentage: 0.5,
          barThickness: 12,
          minBarLength: 7,
          borderRadius: 6,
          borderSkipped: false,
        },
      ],
    });
  };

  //Hooks
  useEffect(() => {
    dataApi(startDate, endDate, period);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Fragment>
      <PageHeader
        title="Gráfico de vendas"
        text="Essa é a página de análise de vendas dos eventos."
      />
      {loading && <Loading title={t("global:loadingText")} />}
      <Container fluid>
        <div id="body-sales" className="pagetype-tickets container-fluid mt-4">
          <div id="cards-dashboard" className="row dashboard-style">
            <div className="col-12 col-lg-3">
              <div className="card" style={{ minHeight: "86px" }}>
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted mb-2">
                        Ingressos vendidos
                      </h6>
                      <span className="h2 mb-0">{totalizers.totalTickets}</span>
                    </div>
                    <div className="col-auto">
                      <span className="h2 text-muted mb-0">
                        <FontAwesomeIcon icon={faTicketAlt} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="card" style={{ minHeight: "86px" }}>
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted mb-2">
                        Valor total
                      </h6>
                      <span className="h2 mb-0">
                        {noCentsCurrencyFormatter(totalizers.totalValue)}
                      </span>
                    </div>
                    <div className="col-auto">
                      <span className="h2 text-muted mb-0">
                        <FontAwesomeIcon icon={faDollarSign} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="row col">
                      <div className="col-6">
                        <DatePicker
                          value={startDate}
                          onChange={(valueStart) => {
                            setStartDate(valueStart);
                          }}
                          placeholderText={t("components:datepicker.startDate")}
                        />
                      </div>
                      <div className="col-6">
                        <DatePicker
                          value={endDate}
                          onChange={(valueEnd) => {
                            setEndDate(valueEnd);
                          }}
                          placeholderText={t("components:datepicker.endDate")}
                        />
                      </div>
                    </div>
                    <div className="col-auto d-flex align-items-center justify-content-center">
                      <span className="h2 text-muted mb-0">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Card header="Gráfico de vendas">
            <div className="row justify-content-end">
              <div className="col-sm-12 col-md-3">
                <div className="row justify-content-center align-items-center pb-2">
                  <div>
                    <h4 className="card-header-title">Valor total</h4>
                  </div>
                  <div
                    className="custom-control custom-switch m-0 justify-content-center align-items-center"
                    style={{ width: 0, paddingLeft: "47px" }}
                  >
                    <input
                      onClick={() => changeChart()}
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitchGraph"
                    />
                    <label
                      id="amount-tickets-label"
                      className="custom-control-label mr-5"
                      htmlFor="customSwitchGraph"
                    />
                  </div>
                  <div>
                    <h4 className="card-header-title">
                      Quantidade de ingressos
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 pb-3">
                <div className="row justify-content-center">
                  <div
                    className="time-course-button"
                    id="period-7"
                    onClick={() => selectedPeriod(7)}
                  >
                    7 dias
                  </div>
                  <div
                    className="time-course-button"
                    id="period-15"
                    onClick={() => selectedPeriod(15)}
                  >
                    15 dias
                  </div>
                  <div
                    className="time-course-button"
                    id="period-30"
                    onClick={() => selectedPeriod(30)}
                  >
                    30 dias
                  </div>
                </div>
              </div>
            </div>
            {dataChart?.labels?.length > 0 ? (
              <div>
                <Bar
                  data={dataChart}
                  options={{
                    tooltips: {
                      cornerRadius: 10,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      ...(window.innerWidth <= 968 && {
                        zoom: {
                          pan: {
                            enabled: true,
                            mode: "x",
                          },
                          zoom: {
                            pinch: {
                              enabled: true, // Enable pinch zooming
                            },
                            wheel: {
                              enabled: true, // Enable wheel zooming
                            },
                            mode: "x",
                          },
                        },
                      }),
                    },
                    scales: {
                      y: {
                        ticks: {
                          color: "#95aac9",
                          callback: function (value) {
                            if (chooseChart) {
                              return value;
                            }
                            return "R$" + value;
                          },
                        },
                        grid: {
                          borderDash: [3, 2],
                          borderColor: "#fff",
                        },
                      },
                      x: {
                        ticks: {
                          color: "#95aac9",
                        },
                        grid: {
                          display: false,
                          borderColor: "#fff",
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <EmptyData title="Nenhum resultado disponível." />
            )}
          </Card>
        </div>
      </Container>
    </Fragment>
  );
}
