import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import "./styles.css";

CustomButtonSpinner.propTypes = {
  children: PropTypes.node,
};

export default function CustomButtonSpinner({
  children,
  type,
  customStyle,
  isLoading,
  triggerOnClick,
  text,
  disabled,
}) {
  const { t } = useTranslation();

  function disableButton() {
    if (isLoading || disabled) {
      return true;
    }
  }

  return (
    <Fragment>
      <button
        id="button"
        type={type}
        className={customStyle}
        isLoading={isLoading}
        disabled={disableButton()}
        onClick={triggerOnClick}
      >
        <span>{children}</span>
        {isLoading ? (
          <Fragment>
            <span
              className="spinner-border spinner-border-sm mx-1"
              role="status"
              aria-hidden="true"
            />
            {t("global:loadingText")}
          </Fragment>
        ) : (
          <span className="button-text">{text}</span>
        )}
      </button>
    </Fragment>
  );
}
