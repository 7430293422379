import React, { useState, useEffect } from "react";
import Api from "services/api";
import PropTypes from "prop-types";
import {
  COLORS,
  SPACINGS,
  breakPoints,
  FONTS_SIZE,
} from "services/constants.js";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from "react-modal";
import QRCode from "qrcode.react";
import { BiLoaderAlt } from "react-icons/bi";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");
ModalQrcodeProducer.propTypes = {
  toggleFunction: PropTypes.shape({
    modalPublicIsOpen: PropTypes.bool,
    setModalPublicIsOpen: PropTypes.func,
  }),
  togglePublic: PropTypes.func,
  user: PropTypes.object,
};

export default function ModalQrcodeProducer(props) {
  const { t } = useTranslation();
  const { toggleFunction, user } = props;
  const { modalPublicIsOpen: isOpen, setModalPublicIsOpen: setIsOpen } =
    toggleFunction;
  const [loading, setLoading] = useState(false);
  const [qrcode, setQrcode] = useState(user.qrcode);
  // const [generatedQrcode, setGeneratedQrcode] = useState(null);
  const gerarQRCODE = () => {
    setLoading(true);
    Api.get(`/gera/qrcode/user/${user.id}`)
      .then((res) => {
        setQrcode(res.data.qrcode);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    if (qrcode) {
      setLoading(false);
    }
  }, [qrcode]);

  return (
    <ModalComponent
      className="shadow middle-modal"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>
            {t("global:User")}: {`${user.first_name} ${user.last_name}`}
          </HeaderText>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon size={32} />
          </CloseButton>
        </HeaderContainer>
        {qrcode && (
          <>
            <InfoTextBar>
              <LargeText style={{ paddingLeft: SPACINGS.space2 }}>
                {t("components:header.qrcode")}
              </LargeText>
              <InfoText style={{ color: COLORS.silver }}>
                {t("components:header.modalQrcode.text")}
              </InfoText>
            </InfoTextBar>
            <QrcodeContainer style={{ textAlign: "center" }}>
              <QRCode size={140} value={qrcode} />
            </QrcodeContainer>
          </>
        )}
        {!qrcode && !loading && (
          <div style={{ paddingTop: "40px", paddingBottom: "18px" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#98AFBC",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              {t("components:header.modalQrcode.noQrcodeText")}
            </span>
            <span
              style={{ color: "#98AFBC", textAlign: "left", fontSize: "15px" }}
            >
              {t("components:header.modalQrcode.noQrcodeText")}
            </span>
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="btn btn-primary c3" onClick={gerarQRCODE}>
                {t("components:header.modalQrcode.generateQrcode")}
              </button>
            </div>
          </div>
        )}
        {loading && (
          <LoadingContainer>
            <LoadingIcon color={COLORS.darkBlue} size={80} />
            <LoadingText>
              {t("components:header.modalQrcode.loading")}
            </LoadingText>
          </LoadingContainer>
        )}
      </ModalContainer>
    </ModalComponent>
  );
}

const ModalComponent = styled(Modal)`
  width: 90%;
  height: auto;
  left: 5%;
  position: absolute;
  top: 22%;
  right: 0;
  overflow-y: auto;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  @media ${breakPoints.mobileL} {
    width: 440px;
    height: auto;
    left: 50%;
    transform: translate(-40%, 0);
  }
  @media screen and (max-width: 768px) {
    top: 25%;
  }
`;
const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} ${SPACINGS.space5} ${SPACINGS.space2};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: visible;
`;
const HeaderContainer = styled.div`
  padding: ${SPACINGS.space3} ${SPACINGS.space3} 0 ${SPACINGS.space3};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45px;
`;
const HeaderText = styled.span`
  color: ${COLORS.dark};
  text-align: left;
  font-size: 15px;
  display: flex;
  line-height: 45px;
  align-items: center;
`;
const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 34px;
`;
const InfoTextBar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${SPACINGS.space4} 18px ${SPACINGS.space4};
  margin-top: 15px;
`;
const InfoText = styled.span`
  color: ${COLORS.silver};
  font-size: 13px;
  text-align: "center";
  @media screen and (max-width: 768px) {
    padding: 0 ${SPACINGS.space1};
  }
`;
const LargeText = styled.span`
  padding: 0 0 10px 0;
  color: ${COLORS.blue};
  font-family: rubik;
  font-size: 36px;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    text-align: center;
    padding: ${SPACINGS.space1} 0;
  }
`;
const QrcodeContainer = styled.div`
  text-align: center;
`;

const LoadingContainer = styled.div`
  flex-direction: column;
  min-width: 32px;
  height: calc(100% - 240px);
  align-items: center;
  display: flex;
  justify-content: center;
  @media ${breakPoints.tablet} {
    height: calc(100% - 180px);
  }
`;
const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
const LoadingText = styled.span`
  color: ${COLORS.gray};
  font-size: ${FONTS_SIZE.fontSize0};
  height: 42;
  width: 80%;
  @media ${breakPoints.mobileL} {
    font-size: ${FONTS_SIZE.fontSize1};
    width: auto;
  }
`;
