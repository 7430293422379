import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import Api from "../../../../services/api.js";
import Swal from "sweetalert2";

const RegisterSector = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [products, setProducts] = useState([]);
  const [data, setData] = useState({
    data: "",
    itens: ""
  });


  const checkAll = () => {
    let source = document.getElementsByName("check-all");
    let checkboxes = document.getElementsByName("check-item");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = source[0].checked;
    }
    return true;
  };

  const fetchItens = () => {
    let chec = []
    let quant = []
    let itens = { chec: chec, quant: quant };
    let checkboxes = document.getElementsByName("check-item");
    let quantproduct = document.getElementsByName("quant-product");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked) {
        itens.chec.push(checkboxes[i].value);
        itens.quant.push(quantproduct[i].value);

      }
    }



    return itens;
  };


  useEffect(() => {

    async function fetchData() {
      const getProducts = await Api.get(`bar-sector/getProducts/${event_id}`);
      setProducts(getProducts.data);
    }


    fetchData();
  }, [event_id]);

  useEffect(() => {
    if (data.itens) {
      Api.post("bar-sector/saveSector", data)
        .then(res => {
          Swal.fire({
            title: "Sucesso",
            icon: "success",
            text: "Setor criado com sucesso!",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok"
          }).then(result => {
            window.location.pathname = "/bar/sector";
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Falha",
            icon: "error",
            text: err,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok"
          });
        });
    }
  }, [data]);

  const [model, setModel] = useState({
    name: "",
    type: "0",
    id_event: event_id,
  });

  const updateModel = e => {
    setModel({
      ...model,
      [e.target.name]: e.target.value
    });
  };

  const registerModel = async e => {
    e.preventDefault();
    if (model.name === null || model.name === "") {
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        text: "O nome do setor é Obrigatório"
      });
    } else {
      let itens = fetchItens();
      setData({
        ...data,
        data: model,
        itens: itens
      });
    }
  };
  return (
    <>
      <PageHeader
        title="Setores"
        text="Registrar novo setor."
      >
      </PageHeader>
      <div id="sectors-setup" className="container-fluid mt-4">

        <div className="card">
          <div className="card-header">
            <span className="card-title">
              Campos obrigatórios
            </span>
          </div>

          <div className="card-body">
            <div className="form-row padding">
              <div className="col-md-4">
                <label htmlFor="type">Tipo</label>
                <select
                  className="form-control"
                  name="type"
                  value={model.type}
                  onChange={updateModel}
                >
                  <option value="0">Master</option>
                  <option value="1">Consumo</option>
                  <option value="2">Recarga</option>
                </select>
              </div>
              <div className="col-md-8">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nome do Setor"
                  name="name"
                  value={model.name}
                  onChange={updateModel}
                />
              </div>
            </div>
          </div>
        </div>

        {model.type === "0" || model.type === "1" ? (
          <div className="card mt-3">
            <div className="card-header">
              <span className="card-title">
                Produtos do Setor
              </span>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 table-responsive">
                  {products.length >= 1 ? (
                    <table className="table table-sector">
                      <thead className="light">
                        <tr>
                          <th>
                            <div className="custom-input custom-checkbox">
                              {" "}
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="check-all"
                                name="check-all"
                                onChange={checkAll}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check-all"
                              ></label>
                            </div>
                          </th>
                          <th></th>
                          <th>Nome</th>
                          <th>Custo</th>
                          <th>Valor</th>
                          <th>Quantidade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.length &&
                          products.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <div className="custom-input custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={item.id_product}
                                    name="check-item"
                                    value={`${item.id_product}:${item.id_menu}`}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={item.id_product}
                                  ></label>
                                </div>
                              </td>
                              <td>
                                <img
                                  className="img-product-dash"
                                  src={item.picture}
                                  alt="img"
                                ></img>
                              </td>
                              <td>{item.alias}</td>
                              <td>
                                {item.cost.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL"
                                })}
                              </td>
                              <td>
                                {item.price.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL"
                                })}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control inputs"
                                  placeholder="quantidade"
                                  name="quant-product"
                                  required
                                  id={item.id_product}
                                />


                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                      <span className="text-center">Nenhum cardápio cadastrado</span>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="box-button">
            <button onClick={registerModel} className="btn btn-primary col-md-3 c3">
              Salvar
            </button>
        </div>
      </div>
    </>
  );
};

export default RegisterSector;
