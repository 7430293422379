import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
const isDev = process.env.NODE_ENV === "development";
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pt-BR",
    debug: isDev,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    ns: [
      "organizations",
      "global",
      "sidebar",
      "tickets",
      "components",
      "backend",
      "authentication",
      "constants",
      "events",
      "commons",
      "reports",
    ],
    defaultNS: "global",
    react: { nsMode: "default" },
    backend: {
      //loadPath: futuramente será substituído por uma chamada a API
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

i18n.services.formatter.add("uppercase", (value, format, lng) =>
  value.toUpperCase()
);

i18n.services.formatter.add("uppercase", (value, format, lng) =>
  value.toUpperCase()
);

export default i18n;
