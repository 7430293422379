function cssdinamico() {
  if (window.location.pathname === "/") {
    setTimeout(() => {
      let nave = document.getElementById("nave");
      let sidebar = document.getElementById("sidebar");

      if (nave) {
        nave.classList.add("esconde");
      }
      if (sidebar) {
        sidebar.classList.add("esconde");
      }
    }, 50);
  } else {
    setTimeout(() => {
      let nave = document.getElementById("nave");
      let sidebar = document.getElementById("sidebar");

      if (nave) {
        nave.classList.remove("esconde");
      }
      if (sidebar) {
        sidebar.classList.remove("esconde");
      }
    }, 500);
  }
  if (window.location.pathname !== "/home") {
    setTimeout(() => {
      let welcome = document.getElementById("welcome");
      if (welcome) {
        welcome.classList.add("esconde");
      }
    }, 50);
  } else {
    setTimeout(() => {
      let welcome = document.getElementById("welcome");
      if (welcome) {
        welcome.classList.remove("esconde");
      }
    }, 50);
  }
}

export function csswelcome() {
  if (window.location.pathname !== "/home") {
    setTimeout(() => {
      document.getElementById("welcome").classList.add("esconde");
    }, 50);
  } else {
    setTimeout(() => {
      document.getElementById("welcome").classList.remove("esconde");
    }, 50);
  }
}

export default cssdinamico;
