import React, { useEffect, useState, useRef } from "react";
import Api from "services/api.js";
import ModalConsumo from "pages/bar-area/event-commands/modalconsumo";
import Loading from "components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSearch,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { useOnClickOutside } from "App";
import { phoneMask, cpfMask } from "mask";
import moment from "moment";
import Swal from "sweetalert2";

export default function Commands() {
  const refdrop = useRef();
  useOnClickOutside(refdrop, () => setDrop(false));
  const [drop, setDrop] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [establishment, setEstablishment] = useState();
  const [dataFilter, setDataFilter] = useState([]);
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [sold, setSold] = useState();
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [active, setActive] = useState();
  const [paginate, setPaginate] = useState(false);
  const [typeSearch, setTypeSearch] = useState("Código");
  const [filter, setFilter] = useState({
    identify: "",
    page: 1,
    perPage: 25,
  });

  const options = ["Código", "Telefone", "Documento", "Nome"];

  const updateFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]:
        typeSearch === "Telefone"
          ? phoneMask(e.target.value)
          : typeSearch === "Documento"
          ? cpfMask(e.target.value)
          : e.target.value,
    });
  };

  const pagination = (typePage, numberPage, perPage) => {
    switch (typePage) {
      case "Initial":
        setFilter({
          ...filter,
          page: numberPage,
          perPage: perPage,
        });
        break;
      case "left":
        numberPage--;
        if (numberPage <= 0) {
          setFilter({
            ...filter,
            page: 1,
            perPage: perPage,
          });
        } else {
          setFilter({
            ...filter,
            page: numberPage,
            perPage: perPage,
          });
        }
        break;
      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          setFilter({
            ...filter,
            page: numberPage,
            perPage: perPage,
          });
        } else {
          numberPage++;
          setFilter({
            ...filter,
            page: numberPage,
            perPage: perPage,
          });
        }
        break;

      case "lastPage":
        setFilter({
          ...filter,
          page: numberPage,
          perPage: perPage,
        });
        break;
      default:
        setFilter({
          ...filter,
          page: 1,
          perPage: 25,
        });
        break;
    }
    setPaginate(true);
  };

  function dropdown() {
    if (drop === "show") {
      setDrop("");
    } else {
      setDrop("show");
    }
  }

  const detail = (dados, activation) => {
    document.getElementById("loading").classList.remove("esconde");
    Api.get(`sold/commandsProducts/${dados}`)
      .then((res) => {
        setSold(res.data);
        setActive(activation);
        openModal();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
        document.getElementById("loading").classList.add("esconde");
      });
  };

  const openModal = () => {
    setStatusModal("modal-show");
    document.getElementById("loading").classList.add("esconde");
  };

  function closeModal() {
    setStatusModal("modal-hidden");
  }

  const searchCode = (e) => {
    if (e.key === "Enter") {
      setPaginate(true);
    }
  };

  useEffect(() => {
    document.getElementById("loading").classList.remove("esconde");

    Api.get("/getEvent/" + event_id)
      .then((res) => {
        setEstablishment(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });

    if (typeSearch === "Documento") {
      filter.identify = filter.identify.replace(/[^a-zA-Z0-9 ]/g, "");
    }

    Api.post(`dash/commands/establishment/${event_id}`, filter)
      .then((res) => {
        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
        setDataFilter(res.data.data);
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });

    if (typeSearch === "Documento") {
      filter.identify = cpfMask(filter.identify);
    }

    if (paginate) {
      setPaginate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id, paginate]);

  return (
    <>
      <Loading
        title="aguarde..."
        text="estamos carregando as suas comandas :)"
      />

      <div className="page-title container-fluid shadow-sm">
        <div className="col-12 page-header-infos">
          <div className="row">
            <div className="col-md-8">
              <h2>Comandas geral</h2>
              <p>{establishment?.name}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="pl-4 col-md-8 col-12">
            <div className="card-search d-flex">
              <FontAwesomeIcon icon={faSearch} className="icon-search" />
              <input
                type="text"
                className="input-custom"
                id="main-bar-search"
                name="identify"
                onKeyDown={searchCode}
                value={filter.identify}
                placeholder="Pesquise pelo código, nome, documento ou telefone"
                style={{ height: 48 }}
                onChange={updateFilter}
              />
            </div>
          </div>
          <div className="pr-4 col-md-4 col-12">
            <div
              className={`col-12 card-search drop-custom ${drop}`}
              ref={refdrop}
              onClick={dropdown}
            >
              <div className="input-custom">{typeSearch}</div>
              <FontAwesomeIcon
                icon={faSortDown}
                className="icon-search"
                id="arrowDown"
              />
              <div className="dropdown-menu">
                {options.map((item, i) => (
                  <div
                    key={i}
                    className="dropdown-item"
                    onClick={(e) => {
                      setTypeSearch(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-sm card-table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Código ID</th>
                    <th scope="col" className="text-center">
                      Hora da ativação
                    </th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-right">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataFilter.map((item, i) => (
                    <tr key={i}>
                      <td>{item.identifier}</td>
                      <td className="text-center">
                        {moment(item.activation_date).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </td>
                      <td className="text-center">
                        <span
                          className={`badge badge-pill ${item.active} ${
                            item.active === 1 ? "badge-success" : "badge-danger"
                          }`}
                        >
                          {item.active === 1
                            ? "Confirmado"
                            : "Comanda desativada"}
                        </span>
                      </td>

                      <td className="text-right">
                        <div
                          style={{
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                        >
                          {" "}
                          <span
                            onClick={() => detail(item.remote_id, item.active)}
                          >
                            <img
                              alt=""
                              src="../../icons/icon-plus.png"
                              className="icon-button fa-w-18 "
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-12">
                <div className="row d-flex justify-content-between datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2">Resultados por Página:</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) =>
                          pagination(
                            "Initial",
                            filter.page,
                            (filter.perPage = e.target.value)
                          )
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    {filter.perPage * atualizaPaginaAtual >=
                    atualizaQuantPesquisa ? (
                      <span>
                        {" "}
                        Exibindo {atualizaQuantPesquisa} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        Exibindo {filter.perPage * atualizaPaginaAtual} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    )}

                    <button
                      onClick={() => pagination("Initial", 1, filter.perPage)}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        pagination("left", atualizaPaginaAtual, filter.perPage)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                    </span>

                    <button
                      onClick={() =>
                        pagination("right", atualizaPaginaAtual, filter.perPage)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        pagination(
                          "lastPage",
                          atualizaUltimaPagina,
                          filter.perPage
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConsumo
        dados={sold}
        status={statusModal}
        callback={closeModal}
        active={active}
      />
      {/* <ModalBloqueioCard
        dados={card}
        status={showB}
        detailStateCallback={setDetailState}
        setShowB={setShowB}
      /> */}
    </>
  );
}
