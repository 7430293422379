import { IoIosCloseCircleOutline, IoMdArrowBack } from "react-icons/io";
import React, { useEffect, useState, useRef } from "react";
import { breakPoints } from "services/breakPoints";
import { COLORS } from "services/constants";
import Api from "../../../../services/api";
import Loading from "components/loading";
import EmptyData from "components/empty-data";
import { useOnClickOutside } from "App";
import styled from "styled-components";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { useLanguageSettings } from "hooks/language-settings";

ModalComissarios.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  stewardId: PropTypes.number,
  onClose: PropTypes.func,
  containerClassName: PropTypes.string,
};

export default function ModalComissarios(props) {
  const { noCentsCurrencyFormatter } = useLanguageSettings();
  const { show, title, containerClassName, onClose } = props;
  const [detalhesComissario, setDetalhesComissario] = useState({
    id: "",
    code: "",
    user: {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
    },
  });
  const [detalhesIngressos, setDetalhesIngressos] = useState([]);
  const [detalhesVendas, setDetalhesVendas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let orderTotalCount = 0;
  let orderTotalSum = 0;

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      setIsLoading(false);
      close();
    }
  });

  function close() {
    props.containerClassName("modal-hidden");
  }

  useEffect(() => {
    if (show) {
      Api.get(
        `steward/${props.stewardId}/sold/detail?event_id=${props.eventId}`
      )
        .then((res) => {
          setDetalhesComissario(res.data.steward);
          setDetalhesIngressos(res.data.tickets);
          setDetalhesVendas(res.data.order);
          setIsLoading(false);
        })
        .catch((err) => {
          return err;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  detalhesVendas.forEach((venda) => {
    orderTotalSum += venda.totalOrderSum;
    orderTotalCount += venda.totalOrderCount;
  });

  return (
    <ModalComponent
      isOpen={show}
      onRequestClose={onClose}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      {isLoading ? <Loading title="aguarde..." /> : null}

      <ModalContainer className={`${containerClassName || ""}`}>
        <HeaderContainer>
          <ArrowTextContainer>
            <BackArrowIcon
              size={23}
              onClick={() => {
                onClose();
                setIsLoading(false);
              }}
            />
            <HeaderText>{title}</HeaderText>
          </ArrowTextContainer>
          <CloseButton
            onClick={() => {
              onClose();
              setIsLoading(false);
            }}
          >
            <CloseIcon size={25} />
          </CloseButton>
        </HeaderContainer>

        <h4 className="card-header-title col-12 mt-2">
          Informações do promoter:
        </h4>
        <div id="sales-details" className="col-md-12 steward-details mt-2">
          <div className="row">
            <div className="col-md-6">
              <p>
                <b>promoter: </b>
                {`${detalhesComissario.user.first_name} ${detalhesComissario.user.last_name}`}
              </p>
              <p>
                <b>Código</b>: {detalhesComissario.code}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <b>Email:</b> {detalhesComissario.user.email}
              </p>
            </div>
          </div>
        </div>
        {detalhesIngressos.length > 0 && detalhesVendas.length > 0 ? (
          <>
            <h4 className="card-header-title col-12 mt-2">
              Informações de ingressos:
            </h4>
            <div className="col-md-12 table-responsive mt-2">
              <table className="table table-sm table-bordered">
                <thead className="">
                  <tr>
                    <th scope="col">Ingresso</th>
                    <th scope="col" className="text-center">
                      Valor unitário
                    </th>
                    <th scope="col" className="text-center">
                      Ingressos Vendidos
                    </th>
                    <th scope="col" className="text-center">
                      Total vendido
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detalhesIngressos.map((ingresso, i) => (
                    <tr key={i}>
                      <td>
                        {`${ingresso.ticket_type.eventSectorLot.sector.name} - ${ingresso.ticket_type.name} - ${ingresso.ticket_type.eventSectorLot.lot.name} `}
                      </td>
                      <td className="text-center">
                        {noCentsCurrencyFormatter(ingresso.value)}
                      </td>
                      <td className="text-center">
                        {ingresso.totalTicketCount}
                      </td>
                      <td className="text-center">
                        {noCentsCurrencyFormatter(ingresso.totalTicketSum)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <h4 className="card-header-title col-12 mt-2">
              Informações de vendas:{" "}
              <span className="tips">
                <img
                  src="../../icons/information.png"
                  alt=""
                  className="fluid imagem-quest"
                />
                <div className="tips-content">
                  <h3 className="tips-header">
                    Informações de vendas por pagamento
                  </h3>
                  Detalhamento de vendas por tipo de pagamento. Sendo o valor já
                  com taxas abatidas (valor destinado ao produtor).
                </div>
              </span>
            </h4>

            <div className="col-md-12 table-responsive mt-2 mb-5">
              <table className="table table-sm table-bordered">
                <thead className="">
                  <tr>
                    <th scope="col">Tipo de pagamento</th>
                    <th scope="col" className="text-center">
                      Vendas Realizadas
                    </th>
                    <th scope="col" className="text-center">
                      Total vendido
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detalhesVendas.map((vendas, i) => (
                    <tr key={i}>
                      <td>{vendas.payment_type}</td>
                      <td className="text-center">{vendas.totalOrderCount}</td>
                      <td className="text-center">
                        {noCentsCurrencyFormatter(vendas.totalOrderSum)}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th scope="row" className="">
                      <b>Total:</b>
                    </th>
                    <td className="text-center">
                      <b>{orderTotalCount}</b>
                    </td>
                    <td className="text-center">
                      <b>{noCentsCurrencyFormatter(orderTotalSum)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <EmptyData
            title={`O promoter não possui vendas ou ingressos atrelados à ele`}
          />
        )}
      </ModalContainer>
    </ModalComponent>
  );
}

//----- Styled Components ------//
const ModalComponent = styled(Modal)`
  width: 100%;
  outline: none;
  @media ${breakPoints.sideBarClosed} {
    width: 55%;
  }
`;

const ModalContainer = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 0.8rem;
  width: 100%;
  overflow: visible;
  outline: none;
`;

const HeaderContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 1px #d8e2e7 solid;
`;

const ArrowTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.span`
  padding-left: 25px;
  color: #98afbc;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-weight: 500;
`;

const BackArrowIcon = styled(IoMdArrowBack)`
  color: ${COLORS.gray};
  cursor: pointer;
  font-size: 15px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;

const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 15px;
`;
