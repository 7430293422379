import { useOnClickOutside } from 'App';
import EmptyData from 'components/empty-data';
import React, { useEffect, useRef, useState } from 'react'
import api from 'services/api';
import Swal from "sweetalert2";

const ModalIventoryHistoric = (props) => {

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => { if (props.status === 'modal-show') { close() } });

  function close() {
    props.callback('modal-hidden');
    setTimeout(() => {
      setData([]);
    }, 100);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    if (props.item?.id) {
      api.get(`inventory/history/${props.item.id}`).then(res => {
        setData(res.data);
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      })
    }

  }, [props.item, props.status]);

  return (
    <>
      <div ref={ref} className={`shadow side-modal ${props.status}`}>
        <div className="card-header">
          <h4 className="card-header-title">Histórico de Iventário - {props.item?.name}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body table-responsive">
          {data.length === 0 ? 
            <EmptyData title="Nenhum resultado disponível." /> :
            <table className='table table-sm table-bordered'>
              <thead>
                <tr>
                  <th>Local</th>
                  <th className='text-center'>Tipo</th>
                  <th className='text-right'>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {data.map((history, i) => {
                  var status;
                  if (history.type === "entrada"){ status = "success" } else {status = "danger"};
                  return (
                  <tr key={i}>
                    <td>{history.evento}</td>
                    <td className='text-center'>
                      <span className={'badge badge-pill badge-' + status}>
                        {history.type}
                      </span>
                    </td>
                    <td className='text-right'>{history.quantity}</td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
          }
        </div>
      </div>
    </>
  )
}

export default ModalIventoryHistoric;
