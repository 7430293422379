import React from "react";
import { SectorByFreeTickets } from "./SectorByFreeTickets";
import { FreeTicketsByOrigin } from "./FreeTicketsByOrigin";

export function FreeTicketsReports({ sectors, originSales }) {
  return (
    <div className="row">
      <div className="col-lg-12 col-xl-6">
        <SectorByFreeTickets sectors={sectors} />
      </div>
      <div className="col-lg-12 col-xl-6">
        <FreeTicketsByOrigin originSales={originSales} />
      </div>
    </div>
  );
}
