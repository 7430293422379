import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { useTranslation } from "react-i18next";

ButtonContained.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isTransfersPage: PropTypes.bool,
};

export default function ButtonContained({
  className,
  content,
  disabled,
  isTransfersPage = false,
  ...restProps
}) {
  const { t } = useTranslation("transfers");

  return (
    <div
      className={
        isTransfersPage && disabled ? "button-container" : "button-contained-3"
      }
    >
      <button
        className={`button-contained ${className}`}
        {...restProps}
        disabled={disabled}
      >
        {content}
      </button>
      {disabled && isTransfersPage && (
        <div className="tooltip-text">{t("transfers:tooltip.text")}</div>
      )}
    </div>
  );
}
