import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import New_api from "services/new-api";

AsyncCustomFieldsInput.propTypes = {
  callBack: PropTypes.func,
  eventId: PropTypes.string,
};

function AsyncCustomFieldsInput({ callBack, eventId, isInvalid, ...props }) {
  const debounceTime = 800;

  const switchStyles = {
    control: (control) => ({
      ...control,
      borderRadius: "8px",
      border: "1px solid #dc3545",
      cursor: "pointer",
      padding: "0.1rem",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#dc3545",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#dc3545",
    }),
  };
  // States

  const selectedValue = (selectedItem) => {
    callBack("shown_information", selectedItem);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const loadOptions = async (search, loadedOptions, options) => {
    let hasMore = false;
    let searchOptionsArray = [];

    await sleep(debounceTime);

    const params = {
      search: search || null,
      page: options.page,
      per_page: 15,
    };

    const customFields = await New_api.get(`event-forms/${eventId}`, {
      params,
    });

    searchOptionsArray = [
      { label: "Nome completo", value: "attendee_name" },
      { label: "E-mail", value: "attendee_email" },
    ];

    if (customFields.data.data.length > 0) {
      customFields.data.data.forEach((data) => {
        searchOptionsArray.push({
          label: data.description,
          value: Number(data.id),
        });
      });
    }

    return {
      options: searchOptionsArray,
      additional: {
        page: options.page + 1,
      },
      hasMore,
    };
  };

  return (
    <Fragment>
      <AsyncPaginate
        {...props}
        menuPlacement="bottom"
        styles={isInvalid ? switchStyles : undefined}
        placeholder="Selecione até 3 informações"
        loadOptions={loadOptions}
        onChange={selectedValue}
        additional={{
          page: 1,
        }}
      />
    </Fragment>
  );
}

export default AsyncCustomFieldsInput;
