import React, { useEffect, useState, useRef } from "react";
import PageHeader from "components/page-header";
import Api from "../../../services/api";
import JoditEditor from 'jodit-react';
import Swal from "sweetalert2";

const Faq = (props) => {
  const faqId = props.match.params.id;
  const [titulo, setTitulo] = useState();
  const [topico, setTopico] = useState();
  const [descricao, setDescricao] = useState();
  const editor = useRef(null);
  const config = {readonly:false}
  useEffect(() => {
    if (faqId) {
      Api.get(`/faq/${faqId}`).then((res) => {
        let result = res.data;
        setTitulo(result.pergunta);
        setDescricao(result.resposta);
        setTopico(result.categorias);
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      });
    }
  }, [faqId]);
  const registerFAQ = () => {
    if (faqId) {
      Api.post("/faq/edit", {
        id: faqId,
        pergunta: titulo,
        resposta: descricao,
        categorias: topico,
      }).then((res) => {
        window.location = "/faqs";
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      });
    } else {
      Api.post("/faq", {
        pergunta: titulo,
        resposta: descricao,
        categorias: topico,
      }).then((res) => {
        window.location = "/faqs";
      }).catch(() => {
        Swal.fire({
          title: 'Ops!',
          icon: 'error',
          text: 'Ocorreu um erro interno, por favor tente novamente',
          confirmButtonText: 'Ok'
        })
      });
    }
  };
  return (
    <>
      <PageHeader
        title="Faq"
        text={faqId ? "Edição" : "Cadastro"}
      >
      </PageHeader>
      <div className="container-fluid mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header container-register-user"><h4 className="card-header-title">Informações básicas</h4></div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <label>Título da Pergunta</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={titulo}
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Tópicos</label>
                </div>
                <div className="col-md-12">
                  <label
                    className="radio-inline mtmb-0 "
                    style={{ marginLeft: "2rem" }}
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="Ingresso-Cliente"
                      name="mde"
                      value="Ingresso-Cliente"
                      checked={topico === "Ingresso-Cliente"}
                      onChange={(event) => setTopico(event.target.value)}
                    />
                    <label
                      htmlFor="Ingresso-Cliente"
                      className="custom-control-label"
                    >
                      Ingresso-Cliente
                    </label>
                  </label>
                  <label
                    className="radio-inline mtmb-0"
                    style={{ marginLeft: "2rem" }}
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="Ingresso-Produtor"
                      name="mde"
                      value="Ingresso-Produtor"
                      checked={topico === "Ingresso-Produtor"}
                      onChange={(event) => setTopico(event.target.value)}
                    />
                    <label
                      htmlFor="Ingresso-Produtor"
                      className="custom-control-label"
                    >
                      Ingresso-Produtor
                    </label>
                  </label>
                  <label
                    className="radio-inline mtmb-0"
                    style={{ marginLeft: "2rem" }}
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="Delivery-Cliente"
                      name="mde"
                      value="Delivery-Cliente"
                      checked={topico === "Delivery-Cliente"}
                      onChange={(event) => setTopico(event.target.value)}
                    />
                    <label
                      htmlFor="Delivery-Cliente"
                      className="custom-control-label"
                    >
                      Delivery-Cliente
                    </label>
                  </label>
                  <label
                    className="radio-inline mtmb-0"
                    style={{ marginLeft: "2rem" }}
                  >
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="Delivery-Produtor"
                      name="mde"
                      value="Delivery-Produtor"
                      checked={topico === "Delivery-Produtor"}
                      onChange={(event) => setTopico(event.target.value)}
                    />
                    <label
                      htmlFor="Delivery-Produtor"
                      className="custom-control-label"
                    >
                      Delivery-Produtor
                    </label>
                  </label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label>Resposta</label>
                  <JoditEditor className='form-control' useRef={editor} value={descricao} config={config} tabIndex={1} onBlur={e=>setDescricao(e.target.innerHTML)} />
                  {/* <textarea
                    className="form-control"
                    value={descricao ? descricao : ""}
                    onBlur={(e) => {
                      setDescricao(e.target.value);
                    }}
                    onChange={(e) => {
                      setDescricao(e.target.value);
                    }}
                  ></textarea> */}
                </div>
              </div>
              <div className="box-button">
                <button
                  className="btn btn-primary col-md-3 c3"
                  onClick={registerFAQ}
                >
                  {faqId ? "Salvar" : "Cadastrar"}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Faq;
