//common
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {faTimesCircle} from "@fortawesome/free-regular-svg-icons";

//providers
// import Api from "../../../../services/api.js";

//components
import PageHeader from "components/page-header";
// import Loading from "../../../../components/loading";
import InputCheckbox from "../../../../components/input-checkbox/index.js";
import Swal from "sweetalert2";

//assets
import "./style.css";


export default function PdvsManager() {

  // PDVs Type Manager
  const PdvsType = [
    {text: 'PDV Tiqueteira', value: 'pdv-company'},
    {text: 'PDV Produtor', value: 'pdv-producer'},
    {text: 'Bilheteria', value: 'pdv-event'}
  ];
  const [type, setType] = useState(0);

  function changePdvType(i){
    setType(i);
  }

  // PDVs Mode Manager
  const PdvsMode = [
    {text: 'Ingresso', value: 'pdv-ticket'},
    {text: 'A&B', value: 'pdv-aeb'},
    {text: 'Ingresso + A&B', value: 'pdv-all'}
  ];
  const [mode, setMode] = useState(0);

  function changePdvMode(i){
    setMode(i);
  }

  // PDV Active Manager
  const [pdvActive, setPdvActive] = useState(1);

  // Event Delete
  function eventRemove(i){
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você está removendo o evento do PDV e não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6e84a3',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, remover o evento!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Removido!',
          'Seu evento foi removido do PDV.',
          'success'
        )
      }
    })
  }

  // User Delete
  function userRemove(i){
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você está removendo o usuário do PDV e não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6e84a3',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, remover o usuário!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Removido!',
          'Seu usuário foi removido do PDV.',
          'success'
        )
      }
    })
  }

  return (
    <>
    {/* <Loading title="aguarde..." text="estamos carregando as configurações :)"/> */}

    <PageHeader
      title="Gestão de PDVs"
      text="Aqui você pode gerenciar pontos de venda, seus usuários e eventos."
    >
    </PageHeader>

    <div id="pdvs-manager" className="mt-4 container-fluid">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title">
              1. Configurações de Ponto de Venda
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-6">
                <label>Nome do ponto de venda</label>
                <input className="form-control" type="text" name="" value="" placeholder="digite aqui o nome do ponto de venda..." />
              </div>
              <div className="form-group col-md-6">
                <label>Ponto de venda ativo?</label>
                <div className="custom-control custom-switch">
                  <input
                    id="pdvActiveCheck"
                    type="checkbox"
                    className="custom-control-input"
                    name="pdvActiveCheck"
                    defaultChecked={pdvActive}
                    onChange={() => setPdvActive(pdvActive ? 0 : 1)}
                  ></input>
                  <label className="custom-control-label" htmlFor="pdvActiveCheck"></label>
                  <label>o ponto de venda está <b><u>{pdvActive? "ativo" : "desativado"}</u></b></label>
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="row mb-2">
              <div className="form-group col-md-6">
                <label>Modo de exibição</label>
                <span className="tips">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <div className="tips-content">
                    Configure os modos de visualização disponíveis para esse PDV, você pode escolher apenas um.
                  </div>
                </span>
                <div className="row">
                  {PdvsMode.map ((modes, i) => (
                    <div
                      key={i}
                      className="col-md-4 mb-2"
                      onClick={() => changePdvMode(i)}
                    >
                      <InputCheckbox
                        active = {mode === i ? true : null}
                        value = {modes.text}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Tipo</label>
                <span className="tips">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <div className="tips-content">
                    Configure aqui o tipo de PDV, você pode escolher apenas um.
                  </div>
                </span>
                <div className="row">
                  {PdvsType.map ((types, i) => (
                    <div 
                      key={i}
                      className="col-md-4 mb-2"
                      onClick={() => changePdvType(i)}
                    >
                      <InputCheckbox
                        active = {type === i ? true : null}
                        value = {types.text}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title">
              2. Usuários do Ponto de Venda
            </h4>
          </div>
          <div className="card-body">
            <p>Adicionar novo usuário</p>
            <div className="row">
              <div className="form-group col-md-3">
                <label>E-mail</label>
                <input className="form-control" type="text" name="" value="" placeholder="Digite o e-mail do usuário.." />
              </div>
              <div className="form-group col-md-3">
                <label>Nome</label>
                <input className="form-control" type="text" name="" value="" disabled />
              </div>
              <div className="form-group col-md-3">
                <label>Sobrenome</label>
                <input className="form-control" type="text" name="" value="" disabled />
              </div>
              <div className="form-group button-group col-md-3">
                <button className="btn btn-secondary">
                  atrelar novo usuário
                </button>
              </div>
            </div>

            <hr></hr>
            
            <p>Lista de usuários</p>
            <div className="row mb-2">
              <div className="form-group col-10 col-md-5">
                <input className="form-control" type="text" name="" value="romao@gmail.com" disabled/>
              </div>
              <div className="col-2 col-md-1">
                <div className="button-delete" onClick={() => userRemove(1)}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title">
              3. Eventos atrelados
            </h4>
          </div>
          <div className="card-body">
            <p>Adicionar eventos</p>
            <div className="row">
              <div className="form-group col-md-9">
                <input className="form-control" type="text" name="" value="" placeholder="Clique para selecionar o evento desejado..." />
              </div>
              <div className="form-group button-group col-md-3">
                <button className="btn btn-secondary">
                  adicionar evento
                </button>
              </div>
            </div>

            <hr></hr>
            
            <p>Lista de eventos atuais (disponíveis para venda)</p>
            <div className="row mb-2">
              <div className="form-group col-10 col-md-5">
                <input className="form-control" type="text" name="" value="Menos é Mais - Nook" disabled/>
              </div>
              <div className="col-2 col-md-1">
                <div className="button-delete" onClick={() => eventRemove(1)}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div className="button-group col-md-3 offset-md-9 mb-4">
          <button className="btn btn-primary">
            Atualizar dados
          </button>
        </div>

      </div>
    </div>
    </>
  );
}
