import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import New_api from "services/new-api";

AsyncCategoryInput.propTypes = {
  callBack: PropTypes.func,
  category: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  onEventEditOrCreate: PropTypes.bool,
  isSubcategory: PropTypes.bool,
  otherCategory: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
};

function AsyncCategoryInput({
  callBack,
  category,
  isSubcategory,
  onEventEditOrCreate,
  otherCategory,
  ...props
}) {
  const debounceTime = 800;
  // States
  const [loadingFunction, setLoadingFunction] = useState(true);

  useEffect(() => {
    if (category) {
      setLoadingFunction(false);
    }
  }, [category]);

  useEffect(() => {
    if (!loadingFunction) {
      setLoadingFunction(true);
    }
  }, [loadingFunction]);

  const selectedValue = (selectedItem) => {
    if (selectedItem) {
      if (isSubcategory && onEventEditOrCreate && selectedItem.length > 3) {
        selectedItem.pop()
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Máximo de 3 subcategorias",
          confirmButtonText: "Ok",
        });
        return
      }
      callBack(selectedItem);
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  if (!loadingFunction) {
    return (
      <Fragment>
        <></>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <AsyncPaginate
        {...props}
        menuPlacement="bottom"
        placeholder={isSubcategory ? "Subcategorias" : "Categorias"}
        loadOptions={async (search, loadedOptions, options) => {
          let hasMore = false;
          let searchOptionsArray = [];
          const route = isSubcategory ? `/subcategories` : `/categories`;

          await sleep(debounceTime);

          const params = {
            search: search || null,
            category_id: category ? category.value : null,
            other_category: otherCategory ? otherCategory.value : null,
            page: options.page,
            per_page: 15,
          };

          const categories = await New_api.get(route, {
            params,
          });

          searchOptionsArray = [];

          hasMore = categories.data.meta.total > (loadedOptions?.length || 0);

          if (categories.data.data.length > 0) {
            categories.data.data.forEach((data) => {
              searchOptionsArray.push({
                label: data.name,
                value: data.id,
              });
            });
          }

          return {
            options: searchOptionsArray,
            additional: {
              page: options.page + 1,
            },
            hasMore,
          };
        }}
        onChange={(e) => selectedValue(e)}
        additional={{
          page: 1,
        }}
      />
    </Fragment>
  );
}

export default AsyncCategoryInput;
