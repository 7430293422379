import PropTypes from "prop-types";
/* eslint-disable operator-linebreak */
import React from "react";
import { BsArrowRight, BsEye, BsEyeSlash } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from "react-modal";
import styled from "styled-components";

import { breakPoints } from "services/breakPoints";
import { COLORS, SPACINGS } from "services/constants";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");
ModalVincular.propTypes = {
  toggleFunction: PropTypes.shape({
    modalPublicIsOpen: PropTypes.bool,
    setModalPublicIsOpen: PropTypes.func,
  }),
  togglePublic: PropTypes.func,
};

export default function ModalVincular(props) {
  const { toggleFunction, togglePublic } = props;
  const { modalPublicIsOpen: isOpen, setModalPublicIsOpen: setIsOpen } =
    toggleFunction;
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modal-public",
  });

  function setPrivateAndOpenModalVincular() {
    togglePublic();
    setIsOpen(false);
  }

  // eslint-disable-next-line no-undef
  document.onkeydown = function submit(evt) {
    if (evt.key === "Enter") {
      if (isOpen) {
        setPrivateAndOpenModalVincular();
      }
    }
  };

  return (
    <ModalComponent
      className="shadow middle-modal"
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={() => setIsOpen(false)}
    >
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>{t("header")}</HeaderText>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon size={32} />
          </CloseButton>
        </HeaderContainer>
        <InfoTextBarContainer>
          <LargeText>{t("large-text")}</LargeText>

          <InfoText style={{ color: COLORS.darkGray }}>
            {t("info-text.gray")}
            <InfoText style={{ color: COLORS.darkBlue }}>
              {t("info-text.blue")}
            </InfoText>
          </InfoText>
        </InfoTextBarContainer>
        <IconsContainer>
          <EyeIcon />
          <ArrowIcon />
          <SlashEyeIcon />
        </IconsContainer>
        <MediumText>{t("medium-text")}</MediumText>
        <ButtonContainer>
          <PublicButton
            className="btn btn-primary c3 btn-block m-0"
            onClick={() => setPrivateAndOpenModalVincular()}
          >
            {t("button.confirm")}
          </PublicButton>
          <PublicButton
            className="btn btn-primary c3 btn-block m-0"
            onClick={() => setIsOpen(false)}
          >
            {t("button.cancel")}
          </PublicButton>
        </ButtonContainer>
      </ModalContainer>
    </ModalComponent>
  );
}
// //////////////////////////////////// COMPONENTs //////////////////////

const ModalComponent = styled(Modal)`
  width: 90%;
  height: 330px;
  left: 5%;
  position: absolute;
  top: 5%;
  right: 0;
  overflow-y: auto;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  @media ${breakPoints.mobileL} {
    width: 440px;
    height: 60%;
    left: 50%;
    transform: translate(-40%, 0);
  }
`;

// /////////////////////////////////// CONTAINERs /////////////////////////

const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: visible;
`;
const IconsContainer = styled.div`
  align-items: center;
  height: 48px;
  border-radius: 5px;
  width: 150px;
  background-color: ${COLORS.weakGray};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const ButtonContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
`;
const HeaderContainer = styled.div`
  padding: ${SPACINGS.space1} ${SPACINGS.space1} 0 ${SPACINGS.space1};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  @media ${breakPoints.mobileL} {
    padding: ${SPACINGS.space3} ${SPACINGS.space3} 0 ${SPACINGS.space3};
  }
`;
const InfoTextBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${SPACINGS.space1} ${SPACINGS.space1} ${SPACINGS.space1};
  @media ${breakPoints.mobileL} {
    padding: 0 ${SPACINGS.space4} ${SPACINGS.space4} ${SPACINGS.space4};
  }
`;

// /////////////////////////////////// TEXTs //////////////////////////////

const InfoText = styled.span`
  color: ${COLORS.darkGray};
  font-size: 13px;
  text-align: "center";
  line-height: 16px;
  @media ${breakPoints.mobileL} {
    line-height: normal;
  }
`;
const LargeText = styled.span`
  padding: ${SPACINGS.space2} 0 ${SPACINGS.space4} 0;
  color: ${COLORS.darkBlue};
  font-size: 24px;
  @media ${breakPoints.mobileL} {
    font-size: 36px;
  }
`;
const MediumText = styled.span`
  padding: ${SPACINGS.space1} 0 ${SPACINGS.space1} 0;
  color: ${COLORS.darkBlue};
  font-size: 12px;
  @media ${breakPoints.mobileL} {
    font-size: 16px;
    padding: ${SPACINGS.space3} 0 ${SPACINGS.space4} 0;
  }
`;
const HeaderText = styled.span`
  color: ${COLORS.darkGray};
  text-align: center;
  font-size: 12px;
  display: flex;
  line-height: 45px;
  align-items: center;
  @media ${breakPoints.mobileL} {
    font-size: 16px;
  }
`;

// /////////////////////////////////// BUTTONs ////////////////////////////

const PublicButton = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${COLORS.darkBlue};
  color: ${COLORS.white};
  font-size: 18px;
  width: 70px;
  height: 42px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
// /////////////////////////////////// ICONs //////////////////////////////

const EyeIcon = styled(BsEye)`
  color: ${COLORS.darkGray};
  font-size: 30px;
`;
const SlashEyeIcon = styled(BsEyeSlash)`
  color: ${COLORS.darkGray};
  font-size: 30px;
`;
const ArrowIcon = styled(BsArrowRight)`
  color: ${COLORS.gray};
  font-size: 30px;
`;

const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 34px;
`;
