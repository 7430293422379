import React, { useState } from "react";
import PageHeader from "components/page-header";
import "./styles.css";
import { cpfMask, phoneMask, cepMask } from "mask.js";
import Api from "services/api";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";
import axios from "axios";
let container;

const RegisterUser = () => {
  const getCep = () => {
    axios
      .get(`https://viacep.com.br/ws/${register.zip_code}/json`)
      .then((res) => {
        setRegister({
          ...register,
          street: res.data.logradouro,
          state: res.data.uf,
          city: res.data.localidade,
          neighborhood: res.data.bairro,
          complement: res.data.complemento,
        });

        var x = document.getElementById("invalid-cep");
        if (!x.classList.contains("hide")) {
          x.classList.add("hide");
          x.classList.add("invalid-cep");
          x.classList.remove("invalid-cep-show");
        }
      })
      .catch((err) => {
        setRegister({
          ...register,
          street: "",
          state: "",
          city: "",
          neighborhood: "",
          complement: "",
        });
        var x = document.getElementById("invalid-cep");
        if (x.classList.contains("hide")) {
          x.classList.remove("hide");
          x.classList.add("invalid-cep-show");
          x.classList.remove("invalid-cep");
        }
      });
  };

  const [register, setRegister] = useState({
    email: "",
    password: "",
    confirmpassword: "",
    first_name: "",
    last_name: "",
    document: "",
    phone: "",
    zip_code: "",
    street: "",
    complement: "",
    neighborhood: "",
    number: "",
    state: "",
    city: "",
  });

  const updateField = (e) => {
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
    });
  };

  const updateCpf = (e) => {
    setRegister({
      ...register,
      [e.target.name]: cpfMask(e.target.value),
    });
  };

  const updatePhone = (e) => {
    setRegister({
      ...register,
      [e.target.name]: phoneMask(e.target.value),
    });
  };

  const updateCep = (e) => {
    setRegister({
      ...register,
      [e.target.name]: cepMask(e.target.value),
    });
  };

  const registerUser = (e) => {
    e.preventDefault();
    if (
      register.email === "" ||
      register.password === "" ||
      register.first_name === "" ||
      register.last_name === ""
    ) {
      container.error("Preencha os campos básicos obrigatórios ");
    } else if (register.password !== register.confirmpassword) {
      container.error("Os campos de senhas precisam ser iguais!");
    } else {
      Api.post("register", register)
        .then((res) => {
          Swal.fire({
            title: "Sucesso",
            icon: "success",
            text: "Usuário cadastrado com sucesso!",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          }).then((result) => {
            window.location.pathname = "/users";
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Erro",
            icon: "error",
            text: "Usuário não cadastrado, favor verificar o formulário",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  return (
    <>
      <PageHeader title="Usuários" text="Cadastrar Usuário"></PageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />

        <div className="col-12">
          <div className="card">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">1. Campos obrigatórios</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="email">E-mail</label>
                  <input
                    type="email"
                    placeholder="exemplo@seuemail.com.br"
                    className="form-control"
                    name="email"
                    value={register.email}
                    onChange={updateField}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="password">Senha</label>
                  <input
                    type="password"
                    placeholder="Senha"
                    className="form-control"
                    name="password"
                    id="password"
                    value={register.password}
                    onChange={updateField}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="confirmpassword">Confirme a Senha</label>
                  <input
                    type="password"
                    placeholder="Confirme a senha"
                    className="form-control"
                    name="confirmpassword"
                    value={register.confirmpassword}
                    onChange={updateField}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <label htmlFor="first_name">Nome</label>
                  <input
                    type="text"
                    placeholder="Nome"
                    className="form-control"
                    name="first_name"
                    value={register.first_name}
                    onChange={updateField}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="last_name">Sobrenome</label>
                  <input
                    type="text"
                    placeholder="Sobrenome"
                    className="form-control"
                    name="last_name"
                    value={register.last_name}
                    onChange={updateField}
                  />
                </div>
              </div>

              {/* <div className="row" style={{ marginTop: '5px' }}>
                      <div className="col-md-4">
                          <label htmlFor="type">Tipo</label>
                          <select
                              name="type"
                              className="form-control"
                              value={register.type}
                              onChange={updateField}
                          >
                              <option value="0">Gestor</option>
                              <option value="1">Administrador</option>
                              <option value="2">Produtor</option>
                          </select>
                      </div>
                  </div> */}
            </div>
          </div>

          <div className="card">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">2. Dados Opcionais</h4>
            </div>

            <div className="card-body">
              <div id="dados-pessoais" className="dados-pessoais">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="cpf">CPF ou CNPJ</label>
                    <input
                      type="text"
                      placeholder="000.000.000-00"
                      className="form-control"
                      name="document"
                      value={register.document}
                      onChange={updateCpf}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="phone">Telefone</label>
                    <input
                      type="text"
                      placeholder="(DDD)"
                      className="form-control"
                      name="phone"
                      value={register.phone}
                      onChange={updatePhone}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="zip_code">CEP</label>
                    <input
                      type="text"
                      placeholder="Cep"
                      className="form-control"
                      name="zip_code"
                      value={register.zip_code}
                      onChange={updateCep}
                      onBlur={getCep}
                    />
                    <div className="invalid-cep hide" id="invalid-cep">
                      <span style={{ color: "#e63757" }}>Cep Inválido.</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="street">Rua</label>
                    <input
                      type="text"
                      placeholder="Rua"
                      className="form-control"
                      name="street"
                      value={register.street}
                      onChange={updateField}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="complement">Complemento</label>
                    <input
                      type="text"
                      placeholder="Complemento"
                      className="form-control"
                      name="complement"
                      value={register.complement}
                      onChange={updateField}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="neighborhood">Bairro</label>
                    <input
                      type="text"
                      placeholder="Bairro"
                      className="form-control"
                      name="neighborhood"
                      value={register.neighborhood}
                      onChange={updateField}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="number">Número</label>
                    <input
                      type="text"
                      placeholder="Número"
                      className="form-control"
                      name="number"
                      value={register.number}
                      onChange={updateField}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="state">Estado</label>
                    <input
                      type="text"
                      placeholder="Estado"
                      className="form-control"
                      name="state"
                      value={register.state}
                      onChange={updateField}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="city">Cidade</label>
                    <input
                      type="text"
                      placeholder="Cidade"
                      className="form-control"
                      name="city"
                      value={register.city}
                      onChange={updateField}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box-button">
            <button
              className="btn btn-primary col-md-3 c3"
              onClick={registerUser}
            >
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterUser;
