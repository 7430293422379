import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  h3 {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #171719;
  }

  table {
    min-width:850px;
    border: 2px solid #D3D2D9;
    border: solid #ccc 1px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    font-family: "Lato", sans-serif;
    table-layout: fixed;

    th, td {
      white-space: nowrap;
    }

    tr th {
      font-weight: 700;
    }

    tr th:first-child {
      border-top-left-radius: 10px;
    }

    tr th:last-child {
      border-top-right-radius: 10px;
    }

    tfoot tr td:first-child {
      border-bottom-left-radius: 10px;
    }

    tfoot tr td:last-child {
      border-bottom-right-radius: 10px;
    }

    th {
      background-color: #cae5f9;
      font-size: 12px;
      line-height: 14.4px;
      font-weight: 700;
      color: #002d8c;
      height: 46px;
      padding: 1rem;
    }

    td {
      font-size: 14px;
      font-weight: 700;
      color: #171719;
      line-height: 21px;
      padding: 1rem;
      border-bottom: 1px solid #eae9ed;
    }
    .header-cell {
      background-color: #eae9ed;
      color: #171719;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }
		
    .table-footer {
      background-color: #CEF2E5;
      color: #171719;
      font-weight: 700;
      font-size: 12px;
      line-height: 14.4px;
    }

    .empty-row{
      text-align: center;
      td{
        color: #767380
      }
    }
    .table-cell{
      max-width: 15rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: #171719;
    }
    .table-cell:hover{
      word-wrap: break-word;
      overflow: visible;
      text-overflow: unset;
      white-space: unset;
    }
  }
`;


export const TableContainer = styled.div`
@media screen and (max-width: 768px) {
  max-width: 80vw;
  overflow-x: auto;
}
`
