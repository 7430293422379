import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FaRegTimesCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function RefuseButton(props) {
  const { title } = props;
  const { t } = useTranslation("transfers");

  return (
    <ActionButton block variant="danger" {...props}>
      {title ? (
        title
      ) : (
        <>
          <FaRegTimesCircle className="mr-2" /> {t("transfers:form.refuseButton")}
        </>
      )}
    </ActionButton>
  );
}

//------ Styled Components -----//
const ActionButton = styled(Button)`
  svg {
    font-size: 24px;
  }
`;
