import React, { useRef, useState } from "react";
import { ToastContainer } from "react-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCashRegister,
  faMoneyBillAlt,
  faPrint,
  faTable,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { useOnClickOutside } from "App";
import CurrencyInput from "react-currency-input";

let container;

const ModalEventCommandDaily = (props) => {
  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => close());

  function close() {
    props.callback("modal-hidden");
    setTimeout(() => {
      if (paymentMode) {
        setPaymentMode(false);
        setPayments([]);
        setAddPayment({
          value: "",
          type: "",
        });
      }
    }, 1000);
  }

  const [paymentMode, setPaymentMode] = useState(false);

  const [payments, setPayments] = useState([]);

  const [addPayment, setAddPayment] = useState({
    value: "",
    type: "",
  });

  const [totalValue, setTotalValue] = useState(100);

  const selectIcon = (item) => {
    switch (item.type) {
      case "1":
        return (
          <>
            <FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>&nbsp;
            Crédito
          </>
        );

      case "2":
        return (
          <>
            <FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>&nbsp; Débito
          </>
        );

      case "3":
        return (
          <>
            <FontAwesomeIcon icon={faMoneyBillAlt}></FontAwesomeIcon>&nbsp;
            Dinheiro
          </>
        );

      default:
        break;
    }
  };

  const deletePayment = (item, i) => {
    setTotalValue(totalValue + Number(item.value));

    let aux = payments;

    if (i > -1) {
      aux.splice(i, 1);
    }
    setPayments([...aux]);
  };

  function fetchPayment() {
    if (
      addPayment.value === "" ||
      addPayment.type === "" ||
      addPayment.type === "0"
    ) {
      container.error("Selecione o tipo de pagamento e digite a quantia!");
      return;
    }

    if (addPayment.value > totalValue) {
      container.error(
        `O valor digitado é maior do que o saldo restante de : ${Number(
          totalValue
        ).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`
      );
      return;
    }

    setTotalValue(totalValue - Number(addPayment.value));

    payments.push(addPayment);
    setAddPayment({
      value: "",
      type: "",
    });
  }

  return (
    <>
      <div
        id="produto-cadastrar"
        ref={ref}
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="card-header">
          <h4 className="card-header-title">Pagamento</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="w-100 d-flex justify-content-between pt-2 px-4 pb-2">
            <div className="d-flex flex-start" style={{ color: "#95aac9" }}>
              <FontAwesomeIcon icon={faCreditCard} size="lg"></FontAwesomeIcon>
              <span>
                &nbsp; Comanda: <b>2183128318</b>
              </span>{" "}
              &nbsp;
              <div className={"tag-sit " + props.item.sit}>
                {props.item.sit}
              </div>
            </div>
            <div
              className="d-flex flex-start"
              align="right"
              style={{ color: "#95aac9" }}
            >
              <FontAwesomeIcon icon={faUserAlt} size="lg"></FontAwesomeIcon>
              <span>
                &nbsp; Cliente: <b>223123</b>
              </span>{" "}
              &nbsp;
            </div>
          </div>
          {paymentMode ? (
            <>
              <div className="w-100 mt-4">
                <span style={{ color: "#95aac9" }}>
                  <b>VALOR TOTAL A PAGAR: R$100,00</b>
                </span>
                {payments.map((item, i) => (
                  <div
                    key={`payments-${i}`}
                    className="d-flex payments-card mt-2 p-2 justify-content-between"
                  >
                    <div className="">
                      <span>
                        {selectIcon(item)}&nbsp;-{" "}
                        {Number(item.value).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                    </div>
                    <div className="">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => deletePayment(item, i)}
                        style={{ color: "#fff" }}
                      >
                        <span aria-hidden="true">
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                          ></FontAwesomeIcon>
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row mt-4">
                  <div className="col-md-4">
                    <CurrencyInput
                      value={addPayment.value}
                      name="value"
                      className="form-control special-control"
                      prefix="R$ "
                      id={`input-cost-row}`}
                      onChangeEvent={(e, maskedvalue, floatvalue) => {
                        setAddPayment({
                          ...addPayment,
                          value: floatvalue,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-control special-control"
                      name="type"
                      value={addPayment.type}
                      placeholder="Selecione um tipo de pagamento"
                      onChange={(e) => {
                        setAddPayment({
                          ...addPayment,
                          type: e.target.value,
                        });
                      }}
                    >
                      <option key={`value-0`} value={0}>
                        Selecione a forma de pagamento
                      </option>
                      <option key={`value-1`} value={1}>
                        Crédito
                      </option>
                      <option key={`value-2`} value={2}>
                        Débito
                      </option>
                      <option key={`value-3`} value={3}>
                        Dinheiro
                      </option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => fetchPayment()}
                    >
                      Adicionar pagamento
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <span style={{ color: "#95aac9" }}>
                    <b>
                      SALDO RESTANTE:{" "}
                      {Number(totalValue).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </b>
                  </span>
                </div>

                {/* {
                    totalValue > 0
                      <div className="mt-2 w-100 d-flex justify-content-center" >
                        <div className="w-100">
                          <button className="btn btn-primary">
                            encerrar mesa
                          </button>
                        </div>
                      </div>
                  } */}

                <div className="mt-2 w-100 d-flex justify-content-center">
                  <div className="w-100">
                    <button className="btn btn-secondary">
                      confirmar pagamento
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-100 mt-4">
                <table className="table">
                  <thead>
                    <tr style={{ fontWeight: "bold" }}>
                      <td style={{ width: "60%" }}>Data</td>
                      <td>Tipo</td>
                      <td>Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {moment(new Date())
                          .tz("America/Sao_Paulo")
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>consumo</td>
                      <td>
                        {Number(200).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th style={{ fontWeight: "bold" }}> saldo</th>
                      <th>
                        {" "}
                        {Number(200).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="mt-4 w-100 d-flex justify-content-center">
                <div className="w-100">
                  <button className="btn btn-primary">
                    <FontAwesomeIcon icon={faTable}></FontAwesomeIcon>
                    &nbsp;encerrar mesa
                  </button>
                </div>
              </div>
              <div className="mt-1 w-100 d-flex justify-content-center">
                <div className="w-100">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => setPaymentMode(true)}
                  >
                    <FontAwesomeIcon icon={faCashRegister}></FontAwesomeIcon>
                    &nbsp;realizar pagamento
                  </button>
                </div>
              </div>
              <div className="mt-1 w-100 d-flex justify-content-center">
                <div className="w-100">
                  <button className="btn btn-secondary">
                    <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon>
                    &nbsp;imprimir conta
                  </button>
                </div>
              </div>
              {/* <div className="mt-4 w-100 d-flex justify-content-center">
						<div className="w-50 text-center border-bottom">
							R$ <span className="value-span">200,00</span>
						</div>

					</div>
					<div className="w-100 d-flex justify-content-center">
						<div className="w-50 text-center" style={{ color: '#95aac9' }}>
							<span><b>restam pagar: R$0,00</b></span>
						</div>
					</div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalEventCommandDaily;
