import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faDollarSign,
  faChartPie,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Swal from "sweetalert2";
import New_api from "services/new-api";

export default function Bottombar() {
  const [event, setEvent] = useState({
    event_id: null,
  });

  const [, /*isEstablishment*/ setIsEstablishment] = useState(0);
  const LinkWhatsapp = process.env.REACT_APP_WHATSAPP;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const event_id = urlParams.get("event_id");
    setEvent({ event_id: event_id });

    New_api.get("/events/" + event_id)
      .then(({ data: { is_establishment } }) => {
        setIsEstablishment(is_establishment);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }, []);

  return (
    <>
      <div id="bottom-bar">
        <div className="container">
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/tickets/participantes/evento?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-icon">
              <FontAwesomeIcon icon={faUser} />
              <span>participantes</span>
            </div>
          </NavLink>
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/tickets/vendas?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-icon">
              <FontAwesomeIcon icon={faDollarSign} />
              <span>vendas</span>
            </div>
          </NavLink>
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/tickets/dashboard?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-mainicon shadow">
              <FontAwesomeIcon icon={faChartPie} />
              {/* <span>resumo</span> */}
            </div>
          </NavLink>
          <NavLink
            className="navLink"
            activeClassName="nav-active"
            to={`/tickets/ingressos/evento?event_id=${event.event_id}`}
          >
            <div className="bottom-bar-icon">
              <FontAwesomeIcon
                icon={faTicketAlt}
                style={{ transform: "rotate(-45deg)" }}
              />
              <span>ingressos</span>
            </div>
          </NavLink>
          {LinkWhatsapp && (
            <button
              className="buttonWhatsapp"
              onClick={() => window.open(LinkWhatsapp)}
            >
              <div className="bottom-bar-icon">
                <FontAwesomeIcon icon={faWhatsapp} />
                <span>Whatsapp</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
