import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PageHeader from "components/page-header";
import CustomButton from "components/custom-button";
import ContainerFluid from "components/containers/container-fluid";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPencilAlt,
    faTrashAlt,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import api from "services/api";
import Pagination from "components/Pagination";
import Loading from "components/loading";
import { format } from "date-fns";

export default function ListTerms() {
    const [pageConfig, setPageConfig] = useState({
        page: 1,
        currentPage: 0,
        lastPage: 0,
        amount: 0,
        perPage: 25,
    });
    const [termos, setTermos] = useState([]);

    function downloadFile(url) {
        let win = window.open(url, "_blank");
        win.focus();
    }

    const getData = (page, perPage) => {
        document.getElementById("loading").classList.remove("esconde");
        api.get(
            `terms?page=${page || pageConfig.page}&per_page=${
                perPage || pageConfig.perPage
            }`
        ).then((res) => {
            setPageConfig({
                page: res.data.page,
                currentPage: res.data.page,
                lastPage: res.data.lastPage,
                amount: res.data.total,
                perPage: res.data.perPage,
            });
            setTermos(res.data.data);
        }).catch(() => {
            Swal.fire({
              title: 'Ops!',
              icon: 'error',
              text: 'Ocorreu um erro interno, por favor tente novamente',
              confirmButtonText: 'Ok'
            })
          });
        document.getElementById("loading").classList.add("esconde");
    };

    const deletarTerms = (termsId) => {
        Swal.fire({
            title: "Desativar Termo",
            icon: "warning",
            text: "Deseja realmente deletar esse termo?",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#3f25a8",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
        }).then((result) => {
            if (result.value) {
                api.delete(`/terms/${termsId}`)
                    .then(() => {
                        Swal.fire({
                            text: "Termo deletado com sucesso!",
                            icon: "success",
                            confirmButtonText: "Ok",
                        }).then(() => {
                            // eslint-disable-next-line
                            window.location.pathname = "/gestao-termo-de-uso";
                        }).catch(() => {
                            Swal.fire({
                              title: 'Ops!',
                              icon: 'error',
                              text: 'Ocorreu um erro interno, por favor tente novamente',
                              confirmButtonText: 'Ok'
                            })
                          });
                    })
                    .catch(() => {
                        Swal.fire({
                            text: "Este termo não pode ser deletado!",
                            icon: "error",
                            confirmButtonText: "Ok",
                        }).then(() => {
                            // eslint-disable-next-line
                            window.location.pathname = "/gestao-termo-de-uso";
                        }).catch(() => {
                            Swal.fire({
                              title: 'Ops!',
                              icon: 'error',
                              text: 'Ocorreu um erro interno, por favor tente novamente',
                              confirmButtonText: 'Ok'
                            })
                          });
                    });
            }
        }).catch(() => {
            Swal.fire({
              title: 'Ops!',
              icon: 'error',
              text: 'Ocorreu um erro interno, por favor tente novamente',
              confirmButtonText: 'Ok'
            })
          });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <PageHeader
                title="Versões de Termos"
                btnText="Criar termo"
                btnLink="/registerTerms"
            />
            <Loading title="aguarde..." />
            <ContainerFluid>
                <div className="table table-responsive mt-5">
                    <table
                        id="table-totalsales"
                        className="table table-sm mt-2 datatable-custom"
                    >
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">DATA DE CRIAÇÃO</th>
                                <th scope="col">TÍTULO</th>
                                <th scope="col">VERSÃO</th>
                                <th scope="col" className="text-center">
                                    STATUS
                                </th>
                                <th scope="col" className="text-right">
                                    AÇÕES
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {termos.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        {format(
                                            new Date(item.start_date),
                                            "dd/MM/yyyy HH:mm:ss"
                                        )}
                                    </td>
                                    <td>{item.title}</td>
                                    <td>{item.version}</td>
                                    <td className="text-center">
                                        {item.active === 1
                                            ? "Ativo"
                                            : "Desativado"}
                                    </td>
                                    <td className="d-flex justify-content-end">
                                        <div className="row mr-1">
                                            {item.terms_url && (
                                                <CustomButton
                                                    onClick={() =>
                                                        downloadFile(
                                                            item.terms_url
                                                        )
                                                    }
                                                    className="btn-table mt-2"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                    />
                                                </CustomButton>
                                            )}
                                            <NavLink
                                                to={`/registerTerms?term_id=${item.id}`}
                                                className="btn-table mt-2"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPencilAlt}
                                                />
                                            </NavLink>
                                            <CustomButton
                                                onClick={() =>
                                                    deletarTerms(item.id)
                                                }
                                                className="btn-table mt-2"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                />
                                            </CustomButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        pageConfig={pageConfig}
                        parentFunction={getData}
                    />
                </div>
            </ContainerFluid>
        </>
    );
}
