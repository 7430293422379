import React, { useMemo } from "react";
import { ReactComponent as QrCode } from "../../../../assets/icons/newQrCode.svg";
import { ReactComponent as Redirect } from "../../../../assets/icons/redirect.svg";
import { ReactComponent as Logout } from "../../../../assets/icons/logout.svg";
import api from "services/api";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export function AccountDetailsMenu({ fullName, email, handleOpenQrCode }) {
  const { t } = useTranslation("components", {
    keyPrefix: "header",
  });

  const accountOptions = useMemo(
    () => [
      {
        name: "modalOptions.profile",
        link: "/user-data",
      },
      {
        name: "modalOptions.userQrcode",
        action: handleOpenQrCode,
      },
      {
        name: "modalOptions.terms",
        link: "/termos-de-uso",
        target: "_blank",
      },
      {
        name: "modalOptions.support",
        icon: Redirect,
        target: "_blank",
        link: process.env.REACT_APP_WHATSAPP,
      },
    ],
    [handleOpenQrCode]
  );

  const handleLogout = (event) => {
    api
      .delete("logout")
      .catch(() => {
        Swal.fire({
          title: "Erro",
          icon: "error",
          text: "Ocorreu um erro. Tente novamente.",
          confirmButtonText: "Ok",
        });
        event.currentTarget.disabled = false;
      })
      .finally(() => {
        localStorage.clear();
        window.location.href = "/";
      });
    event.currentTarget.disabled = true;
  };

  return (
    <div id="account-details-dropdown">
      <div className="account-details-dropdown-header d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column justify-content-start">
          <span className="account-details-dropdown-name">{fullName}</span>
          <span className="account-details-dropdown-email  text-truncate">
            {email}
          </span>
        </div>
        <button
          className="account-details-show-qr-code"
          onClick={handleOpenQrCode}
        >
          <QrCode />
        </button>
      </div>

      <div className="account-details-options d-flex flex-column justify-content-start">
        {accountOptions.map(({ name, link, action, icon: Icon, target }) =>
          !action ? (
            <a
              key={name}
              href={link}
              target={target}
              className="account-details-option  d-flex justify-content-between align-items-center"
            >
              {t(name)}
              {Icon && <Icon />}
            </a>
          ) : (
            <button
              key={name}
              className="account-details-option  d-flex justify-content-between align-items-center"
              onClick={action}
            >
              {t(name)}
            </button>
          )
        )}
      </div>

      <div className="account-details-logout">
        <button
          className="account-details-logout-button"
          onClick={handleLogout}
        >
          <div className="d-flex flex-row  align-items-center account-details-logout-info">
            <Logout />
            {t("modalOptions.logout")}
          </div>
        </button>
      </div>
    </div>
  );
}
