import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastr";

// Mask
import { percentageMask } from "mask";

// Components
import EventCreateHeader from "../../event-create-header";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

// Extra
let container;

export function validateStep2(data) {
  let error = 0;

  if (data.sectors.length <= 0) {
    container.error("Crie pelo menos um setor!");
    return false;
  }

  if (data.lots.length <= 0) {
    container.error("Crie pelo menos um Lote!");
    return false;
  }

  if (data.ticketTypes.length <= 0) {
    container.error("Crie pelo menos um tipo de ingresso!");
    return false;
  }

  data.ticketTypes.forEach((item, i) => {
    if (!item.name || item.name === "") {
      container.error(
        "Todos os tipos de ingresso devem possuir uma nomeclatura!"
      );
      error++;
      return false;
    }

    if (i !== 0 && item.discount === "") {
      container.error(
        "Tipos diferentes do comum devem possuir uma quantidade de desconto!"
      );
      error++;
      return false;
    }
  });

  if (error === 0) {
    return true;
  }
}

const EventCreateStep2 = (props) => {
  const [stepData, setStepData] = useState({
    sector: { name: "" },
    sectors: props.sectors,
    lot: { name: "" },
    lots: props.lots,
    ticketTypes: props.ticketTypes,
  });

  function remove(index) {
    let arr = stepData.ticketTypes;
    arr.splice(index, 1);
    setStepData({
      ...stepData,
      ticketTypes: arr,
    });
  }

  function addSector() {
    let { sector, sectors } = stepData;
    let name = sector.name.trim();

    if (name) {
      // Verifica se existe setor com msm nome
      let sectorExists =
        sectors?.findIndex((sec) => {
          return sec.name === name;
        }) > -1;

      // Adiciona ao array de locations
      // Object.assign é utilizado para n pegar a referencia de this.location
      if (!sectorExists) {
        sectors.push(Object.assign({}, { name }));
        sector.name = "";
        setStepData({
          ...stepData,
          sector: { name: "" },
          sectors: sectors,
        });
      } else {
        container.error("Já existe um setor com esse nome.");
      }
    }
  }

  useEffect(() => {
    if (!props.stepCheck) {
      return;
    }

    props.callBack(stepData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stepCheck]);

  function addLot() {
    let { lot, lots } = stepData;
    if (lot.name) {
      let lotExists =
        lots.findIndex((val) => {
          return lot.name === val.name;
        }) > -1;

      if (!lotExists) {
        lots.push(Object.assign({}, lot));
        lot.name = "";

        setStepData({
          ...stepData,
          lot: { name: "" },
          lots: lots,
        });
      } else {
        container.error("Já existe um lote com esse nome.");
      }
    }
  }

  return (
    <>
      <EventCreateHeader
        title="2. Setores, lotes e tipos"
        text="Configure os tipos de ingresso. <a href='/evento-help' target='_blank'>Saiba mais como configurar.</a>"
      />

      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <div className="mt-4 event-step" id="create-event-1">
        <div className="col-12 pl-0">
          <div className="card mt-0">
            <div className="card-body">
              <b>Configurações de Ingressos</b>
              <hr />
              <div className="form-row">
                <div className="form-group col-md-9">
                  <label className="form-label">Setores</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      São as divisões dentro de um evento para casos onde são
                      cobrados valores diferentes para cada tipo de ingresso.
                      Exemplo: "Pista" e "Open Bar". Caso não haja divisão
                      dentro do evento, você pode deixar o setor "Geral" ou
                      qualquer outro que desejar como padrão.
                    </div>
                  </span>
                  <input
                    className="form-control"
                    value={stepData.sector.name}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        addSector();
                      }
                    }}
                    onChange={(e) => {
                      setStepData({
                        ...stepData,
                        sector: { name: e.target.value },
                      });
                    }}
                    placeholder="Nome do setor...ex: Open Bar, Pista, Camarote"
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">&nbsp;</label>
                  <button
                    className="btn c1"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      addSector();
                    }}
                  >
                    Adicionar setor
                  </button>
                </div>
              </div>
              <div className="form-row espacamento-top2 mb-3">
                <div className="col-md-12">
                  <div className="input-group">
                    <div className="event-tags">
                      <span className="inputs mr-2" id="inputTags">
                        Setores criados:
                      </span>
                      {stepData.sectors?.length > 0 &&
                        stepData.sectors.map((sec, i) => (
                          <span
                            id={`sec-${i}`}
                            key={`sec-${i}`}
                            className="event-tag"
                            style={{ borderRadius: 10 + "rem" }}
                          >
                            {sec.name}
                            <FontAwesomeIcon
                              className="ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let arr = stepData.sectors;
                                arr.splice(i, 1);
                                setStepData({
                                  ...stepData,
                                  sectors: arr,
                                });
                              }}
                              icon={faTimesCircle}
                            />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-row">
                <div className="form-group col-md-9">
                  <label className="form-label">Lotes</label>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      Os lotes definem as mudanças de valores nos ingressos de
                      um evento. Exemplo: "Lote promocional" a R$ 80,00, "1º
                      lote" a R$ 100,00. Você pode definir quantos lotes forem
                      necessários para as mudanças de valores. (lembre-se que o
                      lote de ordem 1 será o primeiro a ser colocado a venda, e
                      os outros entrarão após)
                    </div>
                  </span>
                  <input
                    className="form-control"
                    value={stepData.lot.name}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        addLot();
                      }
                    }}
                    onChange={(e) => {
                      setStepData({
                        ...stepData,
                        lot: { name: e.target.value },
                      });
                    }}
                    placeholder="Nome do setor...ex: Open Bar, Pista, Camarote"
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">&nbsp;</label>
                  <button
                    className="btn c1"
                    style={{ width: "100%" }}
                    onClick={(e) => {
                      addLot();
                    }}
                  >
                    Adicionar lote
                  </button>
                </div>
              </div>
              <div className="form-row espacamento-top2 mb-3">
                <div className="col-md-12">
                  <div className="input-group">
                    <div className="event-tags">
                      <span className="inputs mr-2" id="inputTags">
                        Lotes criados:
                      </span>
                      {stepData.lots?.length > 0 &&
                        stepData.lots.map((lot, i) => (
                          <span
                            id={`lot-${i}`}
                            key={`lot-${i}`}
                            className="event-tag"
                            style={{ borderRadius: 10 + "rem" }}
                          >
                            {lot.name}
                            <FontAwesomeIcon
                              className="ml-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let arr = stepData.lots;
                                arr.splice(i, 1);
                                setStepData({
                                  ...stepData,
                                  lots: arr,
                                });
                              }}
                              icon={faTimesCircle}
                            />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="form-row mb-2">
                <div className="col-md-9">
                  <b>Tipo de Ingressos</b>
                  <span className="tips">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <div className="tips-content">
                      São as opções de compra que um comprador pode ter, como
                      por exemplo "Inteira" e "Meia entrada", onde se definem os
                      percentuais de desconto para cada um. Exemplo: "Meia
                      entrada" tem por lei 50% de desconto em cima do valor do
                      ingresso "Inteira". Você também pode criar outros tipos
                      como "Meia solidária", "OAB" ou quaisquer outros grupos de
                      ingresso que queira oferecer.
                    </div>
                  </span>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn c1"
                    onClick={(e) => {
                      let arr = stepData.ticketTypes;
                      arr.push({ name: "", discount: null });
                      setStepData({
                        ...stepData,
                        ticketTypes: arr,
                      });
                    }}
                    style={{ width: "100%" }}
                  >
                    Adicionar novo tipo
                  </button>
                </div>
              </div>
              {stepData.ticketTypes.length > 0 &&
                stepData.ticketTypes.map((ticketType, i) => (
                  <div key={i} className="form-row create-ticket-type">
                    <div className="col-md-9 mb-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          {/* <span className="input-group-text inputs" id="inputTags">
                            Nome
                          </span> */}
                          {i > 0 ? (
                            <span
                              className="red-button input-group-text inputs"
                              id="inputTags"
                            >
                              <FontAwesomeIcon
                                onClick={() => remove(i)}
                                icon={faTimesCircle}
                              />
                            </span>
                          ) : (
                            <span
                              className="red-button input-group-text inputs"
                              id="inputTags"
                            >
                              <FontAwesomeIcon
                                className="red-button disabled"
                                icon={faTimesCircle}
                              />
                            </span>
                          )}
                        </div>
                        <input
                          className="form-control"
                          value={ticketType.name}
                          onChange={(e) => {
                            ticketType.name = e.target.value;
                            setStepData({ ...stepData });
                          }}
                          placeholder="ex: Inteira, Meia.."
                        />
                      </div>
                    </div>

                    <div className="col-md-3 mb-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text inputs"
                            id="inputTags"
                          >
                            %
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          value={
                            ticketType.discount !== 0
                              ? ticketType.discount
                              : null
                          }
                          max="100"
                          min="0"
                          onChange={(e) => {
                            if (e.target.value !== null) {
                              ticketType.discount = Number(
                                percentageMask(e.target.value)
                              );
                            }

                            if (Number(e.target.value) > 100) {
                              ticketType.discount = 100;
                            }
                            setStepData({ ...stepData });
                          }}
                          placeholder="Desconto (porcentagem) ex: 10"
                          disabled={i === 0}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCreateStep2;
