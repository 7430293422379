import React, { useEffect, useState } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "services/api";
import { 
  faCommentAlt,
  faEllipsisV, 
  faPen, 
  faSearch,
  faTimes 
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";
import ModalJodit from "components/modalJodit";
import { NavLink } from "react-router-dom";
let container;

export default function CancelamentoEventos() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [tipoModal, setTipoModal] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [paginavisivel, setPaginavisivel] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventFilter, setEventFilter] = useState([]);
  const [filter, setFilter] = useState({
    name: "",
  });
  const [evento, setEvento] = useState();
  function returnMonth(month) {
    switch (month) {
      case 0:
        return "JAN";
      case 1:
        return "FEV";
      case 2:
        return "MAR";
      case 3:
        return "ABR";
      case 4:
        return "MAI";
      case 5:
        return "JUN";
      case 6:
        return "JUL";
      case 7:
        return "AGO";
      case 8:
        return "SET";
      case 9:
        return "OUT";
      case 10:
        return "NOV";
      case 11:
        return "DEZ";
      // case 0:
      //   return "DEZ";
      //no default
    }
  }
  const updateFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const searchName = (value) => {
    let ar = [];
    eventFilter.forEach((item) => {
      if (item.name.toLowerCase().includes(value.toLowerCase())) {
        ar.push(item);
      }
    });
    setEvents(ar);
    if (filter.name === "") {
      setEvents(eventFilter);
    }
  };

  const apllyFilter = (e) => {
    searchName(e.target.value);
  };
  useEffect(() => {
    let usuarioLogado = JSON.parse(localStorage.getItem("user"));

    if (usuarioLogado.type !== "GOD") {
      window.location.href = "/eventos";
    } else {
      api
        .post("admin/allevents", { startDate: null })
        .then((res) => {
          setEventFilter(res.data.events);
          setEvents(res.data.events);
          setPaginavisivel(false);
        })
        .catch(() => {
          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })
        })
    }
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "rgba(59, 59, 59, 0.5)",
    },
  };
  const comunicarRemarcarEvento = (e, item, tipo, titulo) => {
    e.preventDefault();
    setTipoModal(tipo);
    setTituloModal(titulo);
    setEvento(item);
    setIsOpen(true);
  };

  const retornoEvento = (tipo, aviso) => {
    if (tipo === "sucesso") {
      container.success(aviso);
    } else {
      container.error(aviso);
    }
  };

  const cancelaEvento = (e, item) => {
    e.preventDefault();
    Swal.fire({
      title: "Cancelar Evento na Plataforma",
      icon: "warning",
      text:
        "Deseja realmente cancelar este evento da plataforma? (O evento será despublicado no site e toda a receita devolvida aos compradores.)",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        api
          .get(`cancel/event/${item.id}`)
          .then((res) => {
            container.success("Evento cancelado.");
          })
          .catch((err) => {
            container.error("Erro ao tentar cancelar evento.");
          });
      }
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const mostraBtn = (e, index) => {
    e.preventDefault();
    let botoes = document.getElementsByClassName('dropdown-buttons');
    let btn = botoes[index];
    if (btn.classList.contains('show-dropdown')) {
      // Remover classe ativa do botão
      btn.classList.remove('show-dropdown');
    } else {
      // Limpar botões ativos
      let botoesativos = document.getElementsByClassName('show-dropdown');
      let botaoativo = botoesativos[0];
      if (botaoativo !== undefined) {
        botaoativo.classList.remove('show-dropdown');
      }
      // Adicionar classe ativa ao botão
      btn.classList.add('show-dropdown');
    }
  }

  return paginavisivel ? (
    <> PÁGINA INEXISTENTE </>
  ) : (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <PageHeader
        title="Eventos a acontecer"
        text="Aqui você encontra uma lista dos eventos que ainda vão ser realizados, e pode cancela-los."
      ></PageHeader>
      <div className="content mt-4 container-fluid">
        <div className="row">
          <div id="event-filters" className="col-12">
            <div className="card-search d-flex">
              <FontAwesomeIcon icon={faSearch} className="icon-search" />
              <input
                className="input-custom"
                value={filter.name}
                name="name"
                onChange={updateFilter}
                onKeyUp={apllyFilter}
                autoComplete="off"
                placeholder="Pesquisar por nome do evento"
              />
            </div>
          </div>
          <div id="list-events" className="col-12">
            {events.length ? (
              events.map((item, i) => (
                <div key={i} className="card-event card-cancel shadow-sm">
                  <div className="card-event-main justify-content-between col-12">
                    <div className="card-event-infos">
                      <div className="card-date text-center">
                        <span className="date-day">
                          {new Date(item.start_date).getDate()}
                        </span>
                        <p className="date-month">
                          {returnMonth(new Date(item.start_date).getMonth())}
                        </p>
                      </div>

                      <div
                        className="event-img hidden-mobile"
                        style={{ backgroundImage: `url(${item.thumb})` }}
                      ></div>

                      <div className="card-event-name d-inline-block text-truncate">
                        <span className="event-name">{item.name}</span>
                        <p className="event-location hidden-mobile">
                          {item.location}
                        </p>
                      </div>
                    </div>


                    <div className='dotsMobile' onClick={e => mostraBtn(e, i)}>
                      <FontAwesomeIcon icon={faEllipsisV} style={{ marginLeft: 10 }} />
                    </div>
                    <div className="dropdown-buttons">


                      {/* BUTTONS */}
                      <NavLink className="dropdown-item" to={'/detalhe/cancelamento/evento/' + item.id}>
                      <FontAwesomeIcon icon={faSearch} />
                        Detalhamento
                        </NavLink>
                      <button
                        className="dropdown-item"
                        onClick={(e) => cancelaEvento(e, item)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                        Cancelar
                      </button>

                      <button
                        className="dropdown-item"
                        onClick={(e) =>
                          comunicarRemarcarEvento(
                            e,
                            item,
                            "remarcacao",
                            "Escrever mensagem de remarcação"
                          )
                        }
                      >
                         <FontAwesomeIcon icon={faPen} />
                        Remarcar
                      </button>

                      <button
                        className="dropdown-item"
                        onClick={(e) =>
                          comunicarRemarcarEvento(
                            e,
                            item,
                            "comunicado",
                            "Escrever mensagem de comunicado"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faCommentAlt} />
                        Comunicar
                      </button>

                    </div>
                    {/* <div className="mobile-buttons d-flex">
                        <NavLink className="btn c3 mr-2" to={'/detalhe/cancelamento/evento/' + item.id}>Detalhamento</NavLink>
                       
                       
                        
                      </div> */}

                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                <span className="subtitle"> Nenhum evento registrado</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalJodit
        isOpen={modalIsOpen}
        tipo={tipoModal}
        titulo={tituloModal}
        onRequestClose={closeModal}
        retorno={retornoEvento}
        event={evento}
        style={customStyles}
        ariaHideApp={false}
      />
    </>
  );
}
