import React, { Fragment } from "react";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import PropTypes from "prop-types";

import "./styles.css";

NewAlert.propTypes = {
  variant: PropTypes.oneOf(["warning"]).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const alertVariants = {
  warning: {
    icon: <Warning />,
  },
};

export default function NewAlert({ variant, className, children, ...props }) {
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={`d-flex flex-row new-alert new-alert-${variant} ${
            className ? className : ""
          }`}
          {...props}
        >
          <div className="d-flex justify-content-start mr-3">
            {alertVariants[variant].icon}
          </div>
          {children}
        </div>
      </div>
    </Fragment>
  );
}
