/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import Api from "../../../../services/api.js";
import Pdf from "react-to-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { cpfMask } from "mask.js";
import moment from "moment-timezone";
import { ToastContainer } from "react-toastr";
import EmptyData from "components/empty-data/index.js";
import Swal from "sweetalert2";

let container;

const ModalTransaction = (props) => {
  const [total, setTotal] = useState(0);
  const [infos, setInfos] = useState([]);

  const closeModal = () => {
    props.callback("modal-hidden");
  };

  const estornarVenda = (id) => {
    Api.get("/revertsold/" + id)
      .then(() => {
        container.success("Transação estornada com sucesso!");
        closeModal();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
        closeModal();
      });
  };

  // PRINT TO PDF CREATE VAR
  const ref = React.createRef();
  const options = {
    unit: "px",
    format: [670, 670],
    putOnlyUsedFonts: true,
    compress: false,
  };

  useEffect(() => {
    let tot = 0;
    if (props.dados) {
      // setStatus(props.status);
      props?.dados?.itens?.forEach((item) => {
        let valor = item.unit * item.unique_price;
        if (item.variations !== "[]") {
          item.variacoes = JSON.parse(item.variations);
        }
        tot += valor;
      });

      setTotal(tot);

      if (props.dados.command_identify) {
        Api.get(
          `events/${props.event_id}/soldDetails/${props.dados.command_identify}`
        )
          .then((res) => {
            setInfos(res.data);
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    }
  }, [props.dados]);

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div
        id="venda-detalhe"
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <div className="w-100 mt2">
          <div className="card-header">
            <h4 className="card-header-title">{props.dados?.type}</h4>
            <button
              type="button"
              className="close"
              onClick={(e) => closeModal(e)}
            >
              &times;
            </button>
          </div>
          {props.dados ? (
            <>
              <div className="col-md-12 mt-2 d-flex flex-column">
                {props.type !== "ticket" && (
                  <span>Comanda: {props.dados?.command_identify}</span>
                )}
                <span>Caixa: {props.dados?.caixa}</span>
                <span>Operador: {props.dados?.operator}</span>
                <span>Número do Serial: {props.dados?.serial_number}</span>
                {infos?.field && (
                  <span>
                    {`${infos?.field} :`}{" "}
                    {infos?.value ? cpfMask(infos?.value) : ""}
                  </span>
                )}
                {props.type !== "ticket" && (
                  <Fragment>
                    <span>Ativações do cartão:</span>
                    {infos?.length > 0 && (
                      <ol>
                        {infos.map((item) => (
                          <li key={item.id}>
                            {moment(item.activation_date)
                              .tz("America/Sao_Paulo")
                              .format("DD/MM/YYYY HH:mm:ss")}
                          </li>
                        ))}
                      </ol>
                    )}
                  </Fragment>
                )}
              </div>
              {props?.dados?.itens[0] && (
                <div className="col-md-12 mt-5">
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Nome</td>
                        <td>Quantidade</td>
                        <td>Valor Unitário</td>
                        <td>Valor</td>
                      </tr>
                    </thead>
                    <tbody>
                      {props.dados?.itens.map((item) => (
                        <React.Fragment key={item.id}>
                          <tr>
                            <td>{item.menuproduct?.alias}</td>
                            <td>{item.unit}</td>
                            <td>
                              {(item.unique_price / 100).toLocaleString(
                                "pt-BR",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}
                            </td>
                            <td>
                              {(
                                (item.unit * item.unique_price) /
                                100
                              ).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>
                          </tr>
                          {item.variacoes &&
                            item.variacoes.map((vari) => (
                              <tr>
                                <td colSpan={4}>
                                  <b>{vari.variation_name}: </b>
                                  {vari.opcao?.option_name}
                                  {vari.opcao?.option_qty > 0 &&
                                    " - quantidade:" + vari.opcao?.option_qty}
                                </td>
                              </tr>
                            ))}

                          {item.observation && (
                            <tr>
                              <td colSpan={4}>
                                <b>Observações: </b>
                                {item.observation}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </td>
                        <td>
                          {(total / 100).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="modal-footer2">
                    {props.statusTransaction !== "estornada" && (
                      <button
                        type="button"
                        className="btn btn-danger col-md-4 mr-3"
                        onClick={() => estornarVenda(props.dados.id)}
                      >
                        Estornar
                      </button>
                    )}
                    <Pdf
                      targetRef={ref}
                      filename={"venda - " + props.dados?.id + ".pdf"}
                      options={options}
                    >
                      {({ toPdf }) => (
                        <button
                          onClick={toPdf}
                          className="btn btn-primary c3 col-md-4"
                        >
                          <FontAwesomeIcon icon={faPrint} /> imprimir venda
                        </button>
                      )}
                    </Pdf>
                  </div>
                </div>
              )}
            </>
          ) : (
            <EmptyData title="Nenhum resultado disponível." />
          )}
        </div>
      </div>
    </>
  );
};

export default ModalTransaction;
