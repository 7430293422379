import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SPACINGS } from "services/constants";
import ToggleSwitch from "components/toggleSwitch";
import Swal from "sweetalert2";
import NewApi from "services/new-api.js";
import { breakPoints } from "services/breakPoints";

EventAutomaticallyLotCheck.propTypes = {
  checked: PropTypes.bool,
  eventId: PropTypes.string,
  onChange: PropTypes.func,
};

export default function EventAutomaticallyLotCheck({
  checked,
  eventId,
  onChange,
}) {
  const [check, setCheck] = useState(checked || false);

  const handleSwitchToggle = () => {
    const newState = !check;

    setCheck(newState);

    NewApi.put(`/events/${eventId}`, {
      automatically_turns_lots: newState ? 1 : 0,
    })
      .then(() => onChange(newState))
      .catch(() => {
        setCheck(check);

        Swal.fire({
          title: "Atenção!",
          icon: "error",
          text: "Não foi possível atualizar a configuração de lotes, tente novamente.",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <LotCheckContainer className="container-fluid">
      <ToggleSwitch
        checked={check}
        onColor="#1C70E9"
        onChange={handleSwitchToggle}
      />

      <LotCheckLabel className="ml-2">
        Lotes viram automaticamente
      </LotCheckLabel>
    </LotCheckContainer>
  );
}

const LotCheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACINGS.space3} 0 ${SPACINGS.space3} 0;
  width: 100%;

  @media ${breakPoints.desktop720p} {
    justify-content: flex-start;
  }
`;

const LotCheckLabel = styled.span`
  font-family: "Lato";
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.313rem;
  letter-spacing: 0px;
`;
