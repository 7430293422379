import parse from "url-parse";
import { Buffer } from "buffer";
export class ImageHandlerService {
  static getImageFromUrl(url, params = null, fallback = "") {
    try {
      if (!url || url.length === 0) {
        return fallback;
      }

      const request = {
        bucket: process.env.REACT_APP_ASSETS_BUCKET,
        key: parse(url).pathname.substring(1),
        edits: params,
      };

      if (params === null || Object.keys(params).length === 0) {
        delete request.edits;
      }

      const encoded = Buffer.from(JSON.stringify(request)).toString("base64");

      return process.env.REACT_APP_ASSETS_BASE_URL + "/" + encoded;
    } catch (err) {}

    return url;
  }
}
