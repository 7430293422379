import PropTypes from "prop-types";
import React, { useState } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import Select from "react-select";
import { SeatsioEventManager } from "@seatsio/seatsio-react";
import api from "services/api";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SEATSIOKEY = process.env.REACT_APP_SEATSIO_KEY;

Modal.setAppElement("#root");

ModalPassportSector.propTypes = {
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
  }),
  sector: PropTypes.object,
  eventId: PropTypes.string,
  setRefetch: PropTypes.func,
  seatsCategoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  seatsEventKey: PropTypes.string,
};

export default function ModalPassportSector(props) {
  const {
    toggleFunction,
    sector,
    eventId,
    setRefetch,
    seatsCategoriesList,
    seatsEventKey,
  } = props;
  const { isOpen, setIsOpen } = toggleFunction;
  const [name, setName] = useState("");
  const [erroSector, setErroSector] = useState(false);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [seatCategory, setSeatCategory] = useState();
  const [isManagerOpen, setIsManagerOpen] = useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modalPassportSector",
  });

  // eslint-disable-next-line no-undef
  document.onkeydown = async function submit(evt) {
    if (evt.key === "Enter") {
      if (blockSubmit) {
        return;
      }
      if (isOpen) {
        setBlockSubmit(true);
        salvar();
        // eslint-disable-next-line no-magic-numbers
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setBlockSubmit(false);
      }
    }
  };

  function salvar() {
    if (name === "") {
      setErroSector(t("nameRequired"));
      return;
    }

    const data = { name, ...(seatsEventKey && { category: seatCategory }) };

    if (sector) {
      var promise = api.patch("/passports/" + sector.id, data);
    } else {
      promise = api.post("/events/" + eventId + "/passports", data);
    }

    promise
      .then(() => {
        setErroSector(false);
        setRefetch(true);

        Swal.fire({
          title: t("alert.success.title"),
          icon: "success",
          text: t("alert.success.text"),
        });

        closeModal();
      })
      .catch((error) => {
        Swal.fire({
          title: t("alert.error.title"),
          icon: "error",
          text: error.response?.data?.message || t("alert.error.text"),
        });
      })
      .finally(() => setRefetch(false));
  }

  function closeModal() {
    setIsOpen(false);
    setErroSector("");
    setName("");
  }

  React.useEffect(() => {
    if (sector) {
      setName(sector.name);

      if (!!sector.seats_category_id) {
        const selectedCategory = seatsCategoriesList.find(
          ({ id }) => id.toString() === sector.seats_category_id.toString()
        );

        setSeatCategory(selectedCategory);
      } else {
        setSeatCategory(null);
      }
    }
  }, [sector, seatsCategoriesList]);

  const sectorType = sector ? "Editar" : "Criar";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      contentLabel="SectorModal"
      className="shadow side-modal"
    >
      <StyledModal
        isOpen={isManagerOpen}
        onRequestClose={() => setIsManagerOpen(false)}
      >
        <div
          style={{
            borderBottom: "1px solid lightgray",
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px",
          }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsManagerOpen(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div style={{ height: "532px", width: "100%" }}>
          <SeatsioEventManager
            secretKey={SEATSIOKEY}
            event={seatsEventKey}
            mode="static"
            region={process.env.REACT_APP_SEATSIO_REGION}
            language="pt"
            showFullScreenButton={false}
          />
        </div>
      </StyledModal>

      <div className="card-header">
        <h4 className="card-header-title">
          {t("header", { type: sectorType })}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <div className="form-group col-12">
                <span>{t("label.name")}</span>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                />
                {erroSector && <p style={{ color: "red" }}>{erroSector}</p>}
              </div>

              {seatsEventKey && (
                <>
                  <div className="form-group col-lg-8 sm-12">
                    <label htmlFor="seats_categories">
                      {t("label.category")}
                    </label>
                    <Select
                      name="seats_categories"
                      isClearable={true}
                      backspaceRemovesValue={true}
                      value={
                        seatCategory
                          ? {
                              value: seatCategory,
                              label: (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      backgroundColor: seatCategory.color,
                                      marginRight: "5px",
                                    }}
                                  />
                                  <span>{seatCategory.name}</span>
                                </div>
                              ),
                            }
                          : null
                      }
                      onChange={(value) => setSeatCategory(value?.value)}
                      placeholder={t("placeholder")}
                      options={seatsCategoriesList.map((category) => ({
                        value: category,
                        label: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: category.color,
                                marginRight: "5px",
                              }}
                            />
                            <span>{category.name}</span>
                          </div>
                        ),
                      }))}
                    />
                  </div>
                  <div
                    className="form-group col-lg-4 sm-12"
                    style={{ display: "flex", alignItems: "end" }}
                  >
                    <button
                      style={{ width: "100%" }}
                      className="btn btn-primary"
                      onClick={() => setIsManagerOpen(true)}
                    >
                      {t("button.seatsMap")}
                    </button>
                  </div>
                </>
              )}

              <div
                style={{
                  paddingBottom: "40px",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
                className="form-group offset-8 col-4 mt-4"
              >
                <button
                  style={{ width: "130px" }}
                  type="button"
                  onClick={() => salvar()}
                  className="btn btn-primary c3 "
                >
                  {t("button.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

//////////////////////////////////////// MODAL /////////////////////////////

const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: auto;
  background-color: white;
  overflow: auto;
  border: 1px solid lightgray;
  @media (max-width: 768px) {
    width: 100vw;
  }
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;
