import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useLanguageSettings } from "hooks/language-settings";

export default function ModalConsumo(props) {
  const [infoCommands, setInfoCommands] = useState([]);
  const { currencyFormatter } = useLanguageSettings();
  const closeModal = () => {
    props.callback("modal-hidden");
  };

  const typeInfosCommands = () => {
    if (props.dados?.commandsInfos?.length > 0) {
      setInfoCommands(props.dados?.commandsInfos);
    }
  };

  useEffect(() => {
    if (props.status === "modal-show") {
      typeInfosCommands();
    }
    // eslint-disable-next-line
  }, [props.status]);

  const Consumption = (item) => {
    switch (item) {
      case "consumo":
        return "Consumo";
      case "ativacao":
        return "Ativação";
      case "recarga":
        return "Pagamento";
      case "desativacao":
        return "Desativação";
      case "estorno-consumo":
        return "Estorno-Consumo";
      case "estorno-recarga":
        return "Estorno-Recarga";
      case "devolucao":
        return "Devolução";
      // no default
    }
  };

  return (
    <>
      <div
        id="venda-detalhe"
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <div className="w-100 mt-2">
          <div className="card-header">
            <h4 className="card-header-title">Dados da comanda</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => closeModal(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="col-md-12">
            {infoCommands &&
              infoCommands.length > 0 &&
              infoCommands.map((item, i) => (
                <div
                  key={i}
                  style={{
                    padding: "5px 5px",
                    color: "#95aac9",
                    fontFamily: '"Roboto", sans-serif',
                  }}
                >{`${item.field}: ${item.value}`}</div>
              ))}
          </div>

          <div className="col-md-12 table-responsive">
            <table className="table table-sm mt-4 datatable-custom">
              <thead>
                <tr>
                  <th>Data</th>
                  <th className="text-center">Tipo</th>
                  <th className="text-right">Valor</th>
                </tr>
              </thead>
              <tbody>
                {props.dados?.transactions.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {moment(item.date)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY") +
                        " " +
                        item.time}
                    </td>
                    <td className="text-center">
                      <span
                        className={
                          "badge badge-pill " +
                          (item.type === "consumo"
                            ? "bg-danger"
                            : item.type === "recarga"
                            ? "bg-success"
                            : "bg-dark-soft")
                        }
                      >
                        {Consumption(item.type)}
                      </span>
                    </td>
                    <td
                      className={
                        "text-right " +
                        (item.type === "consumo" ? "text-red" : null)
                      }
                    >
                      {item.type === "consumo" ? "-" : null}{" "}
                      {currencyFormatter(item.total_value)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>Total Recarga</th>
                  <td></td>
                  <td className="text-right">
                    {currencyFormatter(props.dados?.totalRecharge)}
                  </td>
                </tr>
                <tr>
                  <th className="table-danger">Total Consumo</th>
                  <td></td>
                  <td className="text-right">
                    - {currencyFormatter(props.dados?.totalConsumption)}
                  </td>
                </tr>

                <tr>
                  <th>Saldo Total:</th>
                  <td></td>
                  <td className="text-right">
                    {currencyFormatter(props.dados?.total)}
                  </td>
                </tr>
                <tr>
                  <th className="table-primary">Status</th>
                  <td></td>
                  <td
                    className={
                      props.active === 1 ? "table-success" : "table-danger"
                    }
                  >
                    {props.active === 1 ? "Ativo" : "Desativado"}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="ml-3">
            <p style={{ fontSize: "12px", color: "#4f6c7c" }}>
              Este é um extrato resumido dessa comanda, para maiores detalhes de
              consumo, acesse a tela de{" "}
              <span style={{ color: "#00A7E5" }}>transações</span>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
