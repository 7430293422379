import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import Input from "components/input/index.js";
import moment from "moment-timezone";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";

export default function ModalFilter(props) {
  const { currencyInputFormatter } = useLanguageSettings();
  const { t } = useTranslation();
  const [dataInit, setDataInit] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [money, setMoney] = useState(0);
  const [infoSolds, setInfoSolds] = useState({
    type: props.infoSolds.type,
    caixa: props.infoSolds.caixa,
    payments_type: props.infoSolds.payments_type,
    raw_method: props.infoSolds.raw_method,
    command_identify: props.infoSolds.command_identify,
  });

  const apllyFilters = () => {
    const infoSold = {
      caixa: infoSolds.caixa ? infoSolds.caixa : "",
      type: infoSolds.type ? infoSolds.type : "",
      payments_type: infoSolds.payments_type ? infoSolds.payments_type : "",
      command_identify: infoSolds.command_identify
        ? infoSolds.command_identify
        : "",
      total_value: money
        ? money.replace(".", "").replace(",", "").replace("R$", "")
        : "",
    };

    const raw_method = infoSolds.raw_method ? infoSolds.raw_method : "";

    const pagination = {
      page: 1,
      perPage: 25,
    };

    const payload = {
      start: moment(dataInit).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(dataFim).format("YYYY-MM-DD HH:mm:ss"),
    };

    props.function(infoSold, pagination, payload, raw_method);
  };

  useEffect(() => {
    if (!props.dataInit && !props.dataFim) {
      setDataInit(null);
      setDataFim(null);
    }
    setMoney(props.money);
    setInfoSolds({
      type: props.infoSolds.type,
      caixa: props.infoSolds.caixa,
      payments_type: props.infoSolds.payments_type,
      command_identify: props.infoSolds.command_identify,
      raw_method: props.infoSolds.raw_method,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paginate]);

  const updateInfo = (e) => {
    if (e.target.name === "payments_type") {
      const data = e.target.value.split(",");
      setInfoSolds({
        ...infoSolds,
        [e.target.name]: data[0],
        raw_method: data[1],
      });
    } else {
      setInfoSolds({
        ...infoSolds,
        [e.target.name]: e.target.value,
      });
    }
  };

  const closeModal = () => {
    props.callback("modal-hidden");
  };
  return (
    <div
      id="venda-detalhe"
      className={"shadow side-modal " + props.status}
      role="dialog"
    >
      <div className="card-header">
        <h4 className="card-header-title">Filtros</h4>
        <button type="button" className="close" onClick={(e) => closeModal(e)}>
          &times;
        </button>
      </div>
      <div className="col-md-12 mb-3 mt-4">
        <Input
          name="command_identify"
          className="form-control mb-2"
          placeholder="Pesquisa por comanda"
          onChange={updateInfo}
        />
        <div className="row">
          <div className="col-sm mb-2 filter-sale">
            <DatePicker
              value={dataInit}
              showTimeSelect
              onChange={(value) => setDataInit(value)}
              placeholderText={t("components:datepicker.startDate")}
            />
          </div>
          <div className="col-sm mb-2 filter-sale">
            <DatePicker
              value={dataFim}
              showTimeSelect
              onChange={(value) => setDataFim(value)}
              placeholderText={t("components:datepicker.endDate")}
              marginLeft={[0, "-6.5rem"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm mb-2">
            <input
              name="total_value"
              type="text"
              value={currencyInputFormatter(money || 0)}
              className="form-control"
              id="main-bar-search2"
              placeholder="R$ digite o valor"
              onChange={(e) => setMoney(e.target.value)}
            />
          </div>
          <div className="col-sm mb-2">
            <select
              name="type"
              className="form-control"
              onChange={(event) => updateInfo(event)}
            >
              <option value="">
                {infoSolds.type ? infoSolds.type : "Tipo"}
              </option>
              {props.types.map((type, i) => (
                <option key={i} value={type.type}>
                  {type.type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-sm mb-2">
            <select
              name="caixa"
              className="form-control"
              onChange={(event) => updateInfo(event)}
            >
              <option value="">
                {infoSolds.caixa ? infoSolds.caixa : "Origem"}
              </option>
              {props.caixas.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.caixa}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm mb-2">
            <select
              name="payments_type"
              className="form-control"
              onChange={(event) => {
                updateInfo(event);
              }}
            >
              <option value="">Pagamento</option>
              {props.paymenttypes.map((payt) => (
                <option key={payt.id} value={`${payt.name},${payt.raw_method}`}>
                  {payt.raw_method !== "cash" || payt.name === "Dinheiro"
                    ? payt.name
                    : `${payt.name} (dinheiro)`}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row d-flex justify-content-end">
          <div className="col-md-4 col-sm-12">
            <div
              className="filter-button text-center"
              id="filter-button-sales"
              onClick={apllyFilters}
            >
              <span>
                <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                Aplicar filtros
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
