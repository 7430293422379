import Card from "components/card";
import PageHeader from "components/page-header";
import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormField from "components/FormField";
import Select from "react-select";
import UploadImage from "components/uploadImage";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete/dist/GooglePlacesAutocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useParams } from "react-router-dom";
import api from "services/api";
import Loading from "components/loading";
import CustomButton from "components/custom-button";
import { slugify } from "mask";
import { parseISO } from "date-fns";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";
import New_api from "services/new-api";

const linkEmpresa = process.env.REACT_APP_LINK_EMPRESA;

const formSchema = yup.object({
  name: yup.string().trim().required("O nome é obrigatório."),
  slug: yup.string().trim().required("O slug é obrigatório."),
  category: yup.string().trim().required("A categoria é obrigatória."),
  adressName: yup.string().trim().required("O nome do endereço é obrigatório."),
  sails_limit: yup.string().trim().required("A data limite é obrigatória."),
  formatted_address: yup.string().trim().required("O endereço é obrigatório."),
  adressNumber: yup
    .number()
    .typeError("O número do endereço é obrigatório.")
    .required("O número do endereço é obrigatório."),
});

const CONFIG = {
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
  ],
};

export default function TicketEventTransform() {
  const { t } = useTranslation();
  const { event_id } = useParams();
  const [startDate, setStartDate] = useState();
  const [sailsLimitRender, setSailsLimitRender] = useState();
  const [gmapsLoaded, setGmapsLoaded] = useState(true);
  const [categories, setCategories] = useState([]);
  const [dataEvent, setDataEvent] = useState();
  const [renderThumb, setRenderThumb] = useState();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(formSchema),
  });
  const slug = watch("slug");
  const selectedCategory = (e) => setValue("category", e.value);

  const selectedDate = (date) => {
    setSailsLimitRender(date);
    setValue("sails_limit", date);
  };

  const switchStyles = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor: errors.category?.message ? "#dc3545" : "#ced4da",
      borderRadius: "0.375rem",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  const uploadError = () => {
    Swal.fire({
      title: "Ops!",
      icon: "error",
      text: "Ocorreu um erro durante o uploud. Por favor tente novamente.",
      confirmButtonText: "Ok",
    });
  };

  const generatedUrl = (url, uploadType) => {
    uploadType === "banner" ? setValue("banner", url) : setValue("thumb", url);
  };

  const descriptionWriting = (text) => {
    setValue("description", text);
  };

  const showEventData = (eventId) => {
    document.getElementById("loading").classList.remove("esconde");
    window.initMap = initMap();
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGpw6QCOspt7qdLnpwWV9Aw3lypy246uU&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);

    New_api.get(`/events/${eventId}`, {
      params: {
        includes: ["paymentMethods", "location", "organization.contracts"],
      },
    })
      .then(({ data }) => {
        setDataEvent(data);
        setValue("name", data.name);
        setValue("thumb", data.thumb);
        setValue("slug", slugify(data.name));
        setStartDate(data.start_date);
        setRenderThumb(data.thumb);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      })
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
    api
      .get("users/events/categories-and-organizations")
      .then((res) => {
        setCategories(
          res.data.categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        );
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };
  const onSubmit = (form) => {
    const updatedEvent = {
      event: {
        id: event_id,
        name: form.name,
        slug: form.slug,
        banner: form.banner,
        thumb: form.thumb || dataEvent.thumb,
        description: form.description,
        hasTickets: 1,
        taxes: "Repassar",
        age_range: 18,
        is_public: 0,
        is_online: 0,
      },
      dates: {
        start_date: dataEvent.start_date,
        end_date: dataEvent.end_date,
        sails_limit: form.sails_limit,
      },
      categories: [+form.category],
      organizations: dataEvent.organization,
      type_payments: dataEvent.type_payments,
      location: dataEvent.location,
    };
    api
      .post("event/update", updatedEvent)
      .then(() => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: "Ingresso habilitado com sucesso. Agora para finalizar esta ação, configure seus ingressos.",
          confirmButtonText: "Ok",
        }).then(() => {
          window.location = `/tickets/ingressos/evento?event_id=${event_id}`;
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  const addressFound = (address, lat, lng) => {
    let builtLocation = {
      name: "",
      zip_code: "",
      city: "",
      complement: "",
      formatted_address: "",
      neighborhood: "",
      number: "",
      state: "",
      street: "",
      lat,
      lng,
    };
    address[0].address_components.forEach((item) => {
      if (item.types.indexOf("street_number") > -1) {
        builtLocation.number = item.short_name;
      }
      if (item.types.indexOf("route") > -1) {
        builtLocation.street = item.short_name;
      }
      if (item.types.indexOf("sublocality_level_1") > -1) {
        builtLocation.neighborhood = item.short_name;
      }
      if (item.types.indexOf("administrative_area_level_2") > -1) {
        builtLocation.city = item.short_name;
      }
      if (item.types.indexOf("administrative_area_level_1") > -1) {
        builtLocation.state = item.short_name;
      }
    });
    setValue("formatted_address", address[0].formatted_address);
    setDataEvent({
      ...dataEvent,
      location: {
        ...builtLocation,
        id: dataEvent?.locations[0]?.id,
        formatted_address: address[0].formatted_address,
      },
    });
  };

  const initMap = () => {
    setGmapsLoaded({
      gmapsLoaded: true,
    });
  };

  const checkSlug = (receivedSlug) => {
    api
      .get(`check/event/slug/${receivedSlug}`)
      .then(() => {})
      .catch(() => {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Já existe um evento com este nome, por favor tente outro.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    if (event_id) {
      showEventData(event_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id]);

  return (
    <Fragment>
      <PageHeader
        title="Habilitar Painel de ingressos"
        text="Adicione as informações para habilitar o painel de ingressos."
      />
      <Loading title="aguarde..." />
      <Container fluid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card header="Informações básicas">
            <Row>
              <Col xs={12} md={6}>
                <FormField
                  label="Nome"
                  errorMessage={errors.name?.message}
                  controlId="validationName"
                >
                  <Form.Control
                    {...register("name")}
                    name="name"
                    autoComplete="nope"
                    onBlur={() => checkSlug(slug)}
                    onChange={(e) => setValue("slug", slugify(e.target.value))}
                    type="text"
                    placeholder="Nome do evento"
                    isInvalid={errors.name?.message}
                  />
                </FormField>
              </Col>
              <Col xs={12} md={6}>
                <FormField
                  label="Slug"
                  errorMessage={errors.slug?.message}
                  controlId="validationslug"
                >
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text inputs text-small"
                        id="inputTags"
                      >
                        {linkEmpresa}/eventos/
                      </span>
                      <span />
                    </div>
                    <input
                      {...register("slug")}
                      className="form-control inputs"
                      disabled
                      name="slug"
                      placeholder="Slug do evento (link)"
                    />
                  </div>
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormField
                  label="Categoria"
                  errorMessage={errors.category?.message}
                  controlId="validationCategory"
                >
                  <Select
                    styles={switchStyles}
                    className="col-12 p-0"
                    menuPlacement="top"
                    onChange={selectedCategory}
                    options={categories}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {errors.category?.message && (
                    <h6 className="text-danger ml-2 mt-1">
                      <small>{errors.category?.message}</small>
                    </h6>
                  )}
                </FormField>
              </Col>
              <Col xs={12} md={6}>
                <DatePicker
                  name="sails_limit"
                  leftIcon
                  label={t("events:ticketEventTransform.sailsLimit")}
                  value={sailsLimitRender}
                  showTimeSelect
                  minDate={parseISO(startDate)}
                  onChange={(value) => {
                    selectedDate(value);
                  }}
                  placeholderText={t("components:datepicker.startDate")}
                  error={
                    errors?.sails_limit?.message
                      ? t("events:ticketEventTransform.sailsLimitError")
                      : undefined
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <UploadImage
                  size="1800 x 350"
                  //   defaultValue={organization.organizationInfo.banner}
                  uploadType="banner"
                  uploadError={uploadError}
                  generatedUrl={generatedUrl}
                />
              </Col>
              <Col xs={12} md={6}>
                <UploadImage
                  size="300 x 230"
                  defaultValue={renderThumb}
                  uploadType="thumb"
                  uploadError={uploadError}
                  generatedUrl={generatedUrl}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField label="Descrição">
                  <JoditEditor
                    config={CONFIG}
                    onBlur={() => {}}
                    onChange={(e) => descriptionWriting(e)}
                  />
                </FormField>
              </Col>
            </Row>
          </Card>
          <Card header="Local do evento">
            <Row>
              <Col xs={12} md={3}>
                <FormField
                  label="Nome Fantasia do Endereço"
                  errorMessage={errors.adressName?.message}
                  controlId="validationAdressName"
                >
                  <Form.Control
                    {...register("adressName")}
                    name="adressName"
                    autoComplete="nope"
                    type="text"
                    placeholder="Nome fantasia"
                    isInvalid={errors.adressName?.message}
                  />
                </FormField>
              </Col>
              <Col xs={12} md={6}>
                <FormField label="Endereço">
                  {gmapsLoaded && (
                    <GooglePlacesAutocomplete
                      inputClassName={`form-control ${
                        Boolean(errors.formatted_address?.message)
                          ? "error"
                          : ""
                      }`}
                      placeholder="Buscar endereço"
                      onSelect={async ({ description }) => {
                        const geocode = await geocodeByAddress(description);
                        const { lat, lng } = await getLatLng(geocode[0]);
                        addressFound(geocode, lat, lng);
                      }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["br"],
                        },
                      }}
                    />
                  )}
                  {errors.formatted_address?.message && (
                    <h6 className="text-danger ml-2 mt-1">
                      <small>{errors.formatted_address?.message}</small>
                    </h6>
                  )}
                </FormField>
              </Col>
              <Col xs={12} md={3}>
                <FormField
                  label="Número"
                  errorMessage={errors.adressNumber?.message}
                  controlId="validationAdressNumber"
                >
                  <Form.Control
                    {...register("adressNumber")}
                    name="adressNumber"
                    autoComplete="nope"
                    type="number"
                    placeholder="Número do endereço"
                    isInvalid={errors.adressNumber?.message}
                  />
                </FormField>
              </Col>
            </Row>
          </Card>

          <Row className="justify-content-end">
            <Col md="3" sm="12" className="mb-2">
              <CustomButton
                buttonType
                className="btn btn-primary col-12"
                text="Salvar alterações"
              />
            </Col>
          </Row>
        </form>
      </Container>
    </Fragment>
  );
}
