import axios from "axios";

const marketplace_ticket_api = axios.create({
  baseURL: process.env.REACT_APP_MARKETPLACE_TICKET_SERVICE_URL,
});

marketplace_ticket_api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default marketplace_ticket_api;
