import React from "react";

import "./styles.css";
import csswelcome from "cssdinamico";

import SidebarCaadastro from "components/cadastroevento/sidebarcadastro";
import Cadastro2 from "components/cadastroevento/cadastroevento2";
import Cadastro3 from "components/cadastroevento/cadastroevento3";
import Cadastro4 from "components/cadastroevento/cadastroevento4";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import api from "services/api";

export default class CadastroIngresso extends React.Component {
  state = {
    processing: true,
    posicao: 2,
    categories: [],
    dates: [],
    sectors: [],
    lots: [],
    locations: [],
    dateLocations: [],
    ticketTypes: [{ name: "Inteira", discount: 0 }],
  };

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const event_id = urlParams.get("event_id");
    this.fetchDates(event_id);
    csswelcome();
  }

  fetchDates(event_id) {
    api
      .get(`event-date-locations/${event_id}`)
      .then((res) => {
        let { dates } = res.data;
        this.setState({ dates, event_id, processing: false });
      })
      .catch((e) => {
        this.setState({ processing: false });
      });
  }

  configureSectorLot() {
    return new Promise((resolve) => {
      let { dates, sectors, lots, ticketTypes } = this.state;

      if (!sectors.length) {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Favor inserir um setor",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });
        resolve(false);
      }

      if (!lots.length) {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Favor inserir um lote",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });
        resolve(false);
      }

      if (!ticketTypes.length) {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Favor adicionar um tipo de ingresso",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });
        resolve(false);
      }
      this.setState({ processing: true });

      sectors = sectors.filter((sec) => {
        return sec.name && sec.name.trim().length;
      });

      lots = lots.filter((lot) => {
        return lot.name && lot.name.trim().length;
      });

      dates = dates.map((date) => {
        date.sectors = sectors.map((sector) => {
          sector.active = true;
          sector.ticketTypes = ticketTypes.map((type) => {
            type.active = true;
            return Object.assign({}, type);
          });
          sector.lots = lots.map((lot) => {
            lot.active = true;
            return Object.assign({}, lot);
          });
          return Object.assign({}, sector);
        });
        return Object.assign({}, date);
      });
      this.setState({ sectors, lots, dates }, () => {
        resolve(true);
      });
    });
  }

  configureTickets() {
    return new Promise((resolve) => {
      this.setState({ processing: true });
      let { dates } = this.state;
      let dateLocations = [];
      dates.forEach((date) => {
        let {
          event_date_location_id,
          end_date,
          start_date,
          location,
          name,
          title,
        } = date;
        let dateLoc = {
          event_date_location_id,
          end_date,
          start_date,
          location,
          name,
          title,
          sectors: [],
        };

        date.sectors.forEach((sector) => {
          if (sector.active) {
            let sec = { name: sector.name, lots: [] };
            sector.lots.forEach((lot) => {
              if (lot.active) {
                let new_lot = { name: lot.name, qty: 0, price: 0, types: [] };
                sector.ticketTypes.forEach((type) => {
                  if (type.active) {
                    let { name, discount } = type;
                    new_lot.types.push({ name, discount, qty: null });
                  }
                });
                sec.lots.push(new_lot);
              }
            });
            dateLoc.sectors.push(sec);
          }
        });
        dateLocations.push(dateLoc);
      });
      this.setState({ dateLocations });
      resolve(true);
    });
  }

  saveEvent = () => {
    let { event_id, lots, sectors, dateLocations } = this.state;

    this.setState({ processing: true });

    for (let date of dateLocations) {
      date.sectors.forEach((sec) => {
        sec.lots.forEach((lot) => {
          for (let type of lot.types) {
            type.qty = !Number(type.qty) ? lot.qty : type.qty;
          }
        });
      });
    }
    api
      .post("event/completar-cadastro", {
        event_id,
        sectors,
        lots,
        dateLocations,
      })
      .then((res) => {
        this.setState({ processing: false, posicao: 5 });

      })
      .catch((err) => {
        this.setState({ processing: false });
      });
  };

  proximaEtapa = async () => {
    const { posicao } = this.state;
    let workou = true;
    switch (posicao) {
      case 2:
        workou = await this.configureSectorLot();
        break;
      case 3:
        workou = await this.configureTickets();
        break;
      case 4:
        this.saveEvent();
        return;
        // no default
    }

    if (posicao <= 3 && workou) {
      this.setState({ posicao: posicao + 1 });
    }
    this.setState({ processing: false });
  };

  voltaEtapa = () => {
    let { posicao } = this.state;
    if (posicao > 2 || posicao <= 4) {
      this.setState({ posicao: posicao - 1 });
    }
  };

  publicar = () => {
    let { event_id } = this.state;
    api
      .post("event/publish", { event_id })
      .then((res) => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: "Evento publicado com sucesso!",
          confirmButtonText: "Ok",
        }).then((result) => {
          window.location.href = "/eventos";
        });
      })
      .catch((e) => {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: "Houve um erro ao publicar o evento, favor tentar novamente.",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          confirmButtonText: "Ok",
        });
      });
  };

  render() {
    return (
      <>
        <div className="row">
          <SidebarCaadastro
            posicao={this.state.posicao}
          ></SidebarCaadastro>
          <div className="col-md-12 col-lg-10">
            {this.state.posicao === 2 || this.state.posicao === 3 ? (
              <Cadastro2
                sectors={this.state.sectors}
                lots={this.state.lots}
                dates={this.state.dates}
                ticketTypes={this.state.ticketTypes}
                posicao={this.state.posicao}
              />
            ) : this.state.posicao === 4 ? (
              <Cadastro3 dateLocations={this.state.dateLocations} />
            ) : (
              <Cadastro4 />
            )}
          </div>
        </div>

        {/* Finalização do evento / Barra de termos de uso */}
        <div className="content mt-3" id="event-accept">
          {this.state.posicao === 5 ? (
            <div className="form-row justify-content-between align-items-center">
              <div className="col-md-4">
                <NavLink
                  className={"btn btn-primary float-right btn-white-border"}
                  to={`/eventos`}
                >
                  ir para área administrativa
                </NavLink>
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-primary float-right btn-white"
                  disabled={!this.state.event.hasTickets}
                  onClick={(e) => {
                    this.publicar();
                  }}
                >
                  Publicar
                </button>
              </div>
            </div>
          ) : (
            <div className="form-row justify-content-between">
              <div className="col-md-3">
                {this.state.posicao > 2 && (
                  <button
                    className="btn btn-primary float-right btn-white-border"
                    style={{ width: "100%" }}
                    onClick={this.voltaEtapa}
                  >
                    Voltar
                  </button>
                )}
              </div>

              <div className="col-md-3">
                <button
                  className="btn btn-primary float-right btn-white"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={this.state.processing}
                  onClick={() => {
                    this.proximaEtapa();
                  }}
                >
                  Prosseguir
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
