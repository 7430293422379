import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Card, Form, FormControl, InputGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH, faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import ReactSwitch from "react-switch";
import AsyncCustomFieldsInput from "components/asyncCustomFieldsInput";
import { COLORS } from "services/constants";
import { useTranslation } from "react-i18next";
import New_api from "services/new-api";
import QRCode from "qrcode.react";
import { ToastContainer } from "react-toastr";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import printJS from "print-js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as SuccessIcon } from "../../assets/icons/successIcon.svg";
import Toast from "../../../src/pages/ticket-area/nominal-transfer/components/toast/index.jsx";

let container;

const MIN_NUMBER = 0.1;

const CustomTag = ({ event_id, setEnableTag }) => {
  const defaultValues = {
    width: 10,
    height: 3.5,
    show_qrcode: false,
    shown_information: [{ label: "Nome completo", value: "attendee_name" }],
  };

  const { t } = useTranslation("participants");
  const [tagData, setTagData] = useState(null);
  const [updateTag, setUpdateTag] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const printTagRef = useRef();

  const customTagSchema = yup.object({
    width: yup
      .number()
      .min(MIN_NUMBER, "A largura deve ser maior que 0.1cm")
      .transform((value) => (isNaN(value) ? 0 : value))
      .required("A largura é obrigatória."),
    height: yup
      .number()
      .min(MIN_NUMBER, "A altura deve ser maior que 0.1cm")
      .transform((value) => (isNaN(value) ? 0 : value))
      .required("A altura é obrigatória."),
    show_qrcode: yup
      .boolean()
      .default(false)
      .required("O campo de exibir QR Code é obrigatório."),
    shown_information: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().trim(),
          value: yup.string().trim(),
        })
      )
      .min(1, "Selecione pelo menos uma informação.")
      .max(3, "Selecione no máximo 3 informações."),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(customTagSchema),
    defaultValues,
  });

  const qrCode = watch("show_qrcode");
  const shownInformation = watch("shown_information");

  useEffect(() => {
    if (shownInformation.length > 3) {
      setError("shown_information", {
        type: "max",
        message: "Selecione no máximo 3 informações.",
      });
    } else if (shownInformation.length < 1) {
      setError("shown_information", {
        type: "min",
        message: "Selecione pelo menos uma informação.",
      });
    } else {
      clearErrors("shown_information");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownInformation]);

  const handlePrint = async () => {
    const input = printTagRef.current;
    input.classList.add("preview-without-shadow");

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [getValues("height"), getValues("width")],
    });

    pdf.addImage(imgData, "PNG", 0, 0, getValues("width"), getValues("height"));
    const pdfBlob = pdf.output("blob");

    const reader = new FileReader();
    reader.onload = function () {
      const dataUrl = reader.result;
      const base64 = dataUrl.split(",")[1];
      printJS({
        printable: base64,
        type: "pdf",
        base64: true,
        style: `@page { background-color: #fff !important; } body { background-color: #fff !important; }`,
      });

      input.classList.remove("preview-without-shadow");
    };
    reader.readAsDataURL(pdfBlob);
  };

  const onSubmit = async (data) => {
    try {
      let apiCall;
      let eventFields = new Set();

      if (data.shown_information.length > 0) {
        data.shown_information.forEach((item) => {
          eventFields.add(item.value);
        });
      }

      data.width = data.width * 100;
      data.height = data.height * 100;

      data.event_tag_fields = Array.from(eventFields).map((item, index) => ({
        order: index + 1,
        ...(item === "attendee_name" || item === "attendee_email"
          ? { raw_field: item }
          : { event_form_id: Number(item) }),
      }));

      delete data.shown_information;

      if (tagData) {
        apiCall = New_api.patch(`/event-tag-settings/${tagData.id}`, data);
      } else {
        apiCall = New_api.post(`/events/${event_id}/event-tag-settings`, data);
      }

      await apiCall;
      setToastOpen(true);
      setEnableTag(true);
      setUpdateTag(true);
    } catch (error) {
      container.error(t("participants:tag.saveError"));
    }
  };

  const handleSwitchChange = (value) => {
    setValue("show_qrcode", value);
  };

  const getTagData = async () => {
    const tagData = await New_api.get(
      `/events/${event_id}/event-tag-settings`
    ).catch(() => {
      return null;
    });

    if (!tagData) {
      return;
    }

    const { data } = tagData;

    const label = {
      attendee_name: "Nome completo",
      attendee_email: "E-mail",
    };

    setTagData(data);

    const informations = data.event_tag_fields.map((item) => {
      if (item.raw_field) {
        return { label: label[item.raw_field], value: item.raw_field };
      } else {
        return {
          label: item.event_form.description,
          value: item.event_form_id,
        };
      }
    });

    setValue("width", data.width / 100);
    setValue("height", data.height / 100);
    setValue("show_qrcode", !!data.show_qrcode);
    setValue("shown_information", informations, { shouldValidate: true });
  };

  useEffect(() => {
    getTagData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_id, updateTag]);

  return (
    <Fragment>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className="d-flex justify-content-center align-items-center">
        <Card className="mt-4 p-4">
          <Card.Body className="p-0" id="custom-tag-card">
            <Card.Title className="config-tag-title">
              {t("participants:tag.title")}
            </Card.Title>
            <Card.Text className="config-tag-subtitle">
              {t("participants:tag.description")}
            </Card.Text>

            <Card.Body className="p-0 mt-3 flex-col">
              <Card.Text className="config-tag-label">
                {t("participants:tag.size")}
              </Card.Text>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Col md={6}>
                    <Form.Group controlId="validationWidth">
                      <Form.Label>{t("participants:tag.width")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputTags">
                            <FontAwesomeIcon icon={faArrowsAltH} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                          name="width"
                          control={control}
                          render={({ field }) => (
                            <Fragment>
                              <FormControl
                                {...field}
                                isInvalid={errors?.width}
                                placeholder={t(
                                  "participants:tag.widthPlaceholder"
                                )}
                                value={`${field.value || 0} cm`}
                                onChange={(e) => {
                                  const cleanValue = e.target.value.replace(
                                    /[^\d.]/g,
                                    ""
                                  );

                                  field.onChange(cleanValue);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors?.width?.message}
                              </Form.Control.Feedback>
                            </Fragment>
                          )}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="validationHeight">
                      <Form.Label>{t("participants:tag.height")}</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputTags">
                            <FontAwesomeIcon icon={faArrowsAltV} />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Controller
                          name="height"
                          control={control}
                          render={({ field }) => (
                            <Fragment>
                              <FormControl
                                {...field}
                                isInvalid={errors?.height}
                                placeholder={t(
                                  "participants:tag.heightPlaceholder"
                                )}
                                value={`${field.value || 0} cm`}
                                onChange={(e) => {
                                  const cleanValue = e.target.value.replace(
                                    /[^\d.]/g,
                                    ""
                                  );

                                  field.onChange(cleanValue);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors?.height?.message}
                              </Form.Control.Feedback>
                            </Fragment>
                          )}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Card.Text className="config-tag-label">
                  {t("participants:tag.chooseInfo")}
                </Card.Text>
                <Form.Row>
                  <div className="col d-flex align-items-center">
                    <Controller
                      name="show_qrcode"
                      control={control}
                      render={({ field }) => (
                        <ReactSwitch
                          {...field}
                          handleDiameter={16}
                          onColor="#75d1f2"
                          onHandleColor={COLORS.middleBlue}
                          offColor={COLORS.weakGray}
                          boxShadow="0px 1px 3px 0px #4C4E641F"
                          activeBoxShadow="0px 1px 3px 0px #4C4E641F"
                          offHandleColor={COLORS.white}
                          height={10}
                          width={26}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          checked={field.value}
                          onChange={(value) => {
                            handleSwitchChange(value);
                            field.onChange(value);
                          }}
                          id="react_switch"
                        />
                      )}
                    />
                    <label className="ml-2 mb-0" htmlFor="react_switch">
                      {t("participants:tag.showQrCode")}
                    </label>
                  </div>
                </Form.Row>

                <label
                  className="m-0 mt-3 select-info"
                  htmlFor="custom-fields-select"
                >
                  {t("participants:tag.selectInfo")}
                </label>
                <Controller
                  name="shown_information"
                  control={control}
                  render={({ field }) => (
                    <Fragment>
                      <AsyncCustomFieldsInput
                        {...field}
                        isMulti
                        isInvalid={!!errors?.shown_information?.message}
                        id="custom-fields-select"
                        eventId={event_id}
                        defaultValue={field.value}
                        callBack={setValue}
                      />

                      {!!errors?.shown_information?.message && (
                        <h6 className="mt-1 text-left">
                          <small className="text-danger">
                            {errors.shown_information.message}
                          </small>
                        </h6>
                      )}
                    </Fragment>
                  )}
                />

                <div className="card-preview-tag mt-3">
                  <p className="preview-text">
                    {t("participants:tag.preview")}
                  </p>

                  <div className="d-flex justify-content-center align-items-center">
                    <div ref={printTagRef} className="tag-preview">
                      <div
                        className={`d-flex ${
                          qrCode
                            ? "flex-row justify-content-between"
                            : "flex-column"
                        } align-items-center m-3`}
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <div className="tag-fields-items d-flex flex-column justify-content-center align-items-center">
                          {watch("shown_information")?.map((item, index) => (
                            <p key={index}>{item.label}</p>
                          ))}
                        </div>
                        {qrCode && (
                          <div className="d-flex align-items-center">
                            <QRCode size={80} value="Preview" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3 tag-buttons">
                  <button
                    onClick={handlePrint}
                    type="button"
                    className="btn print-test"
                  >
                    {t("participants:tag.printTest")}
                  </button>
                  <button className="btn save-tag" type="submit">
                    {t("participants:tag.save")}
                  </button>
                </div>
              </Form>
            </Card.Body>
          </Card.Body>
        </Card>
      </div>
      <Toast
        message={
          <>
            <SuccessIcon />
            {t("participants:tag.saveSuccess")}
          </>
        }
        duration={3600}
        isOpen={toastOpen}
        setToastOpen={setToastOpen}
      />
    </Fragment>
  );
};

export default CustomTag;
