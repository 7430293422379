import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import api from "services/api";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";
import { SeatsioEventManager } from "@seatsio/seatsio-react";
import { useTranslation } from "react-i18next";

const SEATSIOKEY = process.env.REACT_APP_SEATSIO_KEY;

Modal.setAppElement("#root");

ModalSector.propTypes = {
  seatsCategoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  eventId: PropTypes.string,
  lotes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      esl_id: PropTypes.number,
      sector: PropTypes.string,
      min_purchase: PropTypes.number,
      max_purchase: PropTypes.number,
      cpf_max_purchase: PropTypes.number,
      cpf_limit: PropTypes.number,
      description: PropTypes.string,
      tickets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          lot: PropTypes.shape({
            name: PropTypes.string,
            expiration: PropTypes.string,
          }),
          status: PropTypes.number,
          qty: PropTypes.number,
          total_available: PropTypes.number,
          total_sold: PropTypes.number,
          value: PropTypes.number,
          types: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              value: PropTypes.number,
              sold: PropTypes.number,
              qty: PropTypes.number,
              active: PropTypes.number,
              is_public: PropTypes.number,
              available: PropTypes.number,
              type: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
  setRefetch: PropTypes.func,
  isEdit: PropTypes.bool,
  seatsEventKey: PropTypes.string,
  editSectorId: PropTypes.string,
  editSectorSeatsId: PropTypes.string,
  handleLoading: PropTypes.func,
};

export default function ModalSector(props) {
  const {
    toggleFunction,
    eventId,
    // eslint-disable-next-line no-unused-vars
    lotes,
    tickets,
    setRefetch,
    seatsCategoriesList,
    isEdit,
    seatsEventKey,
    editSectorName,
    editSectorSeatsId,
    editSectorId,
    handleLoading,
  } = props;
  const { isOpen, setIsOpen } = toggleFunction;
  const [seatCategory, setSeatCategory] = useState();
  const [nomeSector, setNomeSector] = useState();
  const [erroSector, setErroSector] = useState(false);
  const [erroCategory, setErroCategory] = useState(false);
  const [lotsSelecionados, setLotsSelecionados] = useState([]);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [isManagerOpen, setIsManagerOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modal-sector",
  });

  useEffect(() => {
    if (isEdit && editSectorName) {
      setNomeSector(editSectorName);
    } else {
      setNomeSector(null);
    }

    if (isEdit && editSectorSeatsId && editSectorSeatsId.length > 0) {
      const selectedCategory = seatsCategoriesList.find(
        (category) => category.id === parseInt(editSectorSeatsId[0])
      );
      setSeatCategory(selectedCategory);
    } else {
      setSeatCategory(null);
    }
  }, [isEdit, editSectorSeatsId, seatsCategoriesList, editSectorName]);

  // eslint-disable-next-line no-undef
  document.onkeydown = async function submit(evt) {
    if (evt.key === "Enter") {
      if (blockSubmit) {
        return;
      }
      if (isOpen) {
        setBlockSubmit(true);
        salvar();
        // eslint-disable-next-line no-magic-numbers
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setBlockSubmit(false);
      }
    }
  };

  function salvar() {
    if (!nomeSector) {
      setErroSector(t("validation-errors.name-required"));
    }

    if (!nomeSector) {
      return;
    }

    if (
      !isEdit &&
      tickets.some(
        (sector) => sector.sector === nomeSector && sector.id !== editSectorId
      )
    ) {
      setErroSector(t("validation-errors.name-unique"));
      return;
    } else if (
      isEdit &&
      tickets.some(
        (sector) => sector.sector === nomeSector && sector.id !== editSectorId
      )
    ) {
      setErroSector(t("validation-errors.name-unique"));
      return;
    }

    const lotids = [];
    lotsSelecionados.forEach((ls) => {
      lotids.push(ls.id);
    });

    const editData = {
      sec_id: editSectorId,
      name: nomeSector,
      ...(seatsEventKey && { category: seatCategory }),
    };

    setIsSubmitting(true);

    const data = {
      event_id: eventId,
      name: nomeSector,
      lotIds: lotids,
      ...(seatsEventKey && { category: seatCategory }),
    };

    setIsSubmitting(true);

    isEdit
      ? api
          .post("/sec/alter/name", editData)
          .then(() => {
            handleLoading(true, true);
            setErroCategory(false);
            setErroSector(false);
            Swal.fire({
              title: t("alert.success.title"),
              icon: "success",
              text: t("alert.success.update-text"),
              confirmButtonText: "Ok",
              preConfirm: () => {
                closeModal();
              },
            });
          })
          .catch(() => {
            Swal.fire({
              title: t("alert.error.edit.title"),
              icon: "error",
              text: t("alert.error.edit.text"),
              confirmButtonText: "Ok",
            });
          })
          .finally(() => {
            setRefetch(false);
            setIsSubmitting(false);
          })
      : api
          .post("/sector/event", data)
          .then(() => {
            setErroCategory(false);
            setErroSector(false);
            Swal.fire({
              title: t("alert.success.title"),
              icon: "success",
              text: t("alert.success.add-text"),
              preConfirm: () => {
                closeModal();
              },
            });
          })
          .catch((error) => {
            Swal.fire({
              title: t("alert.error.add.title", {
                error: error.response.status,
              }),
              icon: "error",
              text: t("alert.error.add.text"),
              confirmButtonText: "Ok",
            });
          })

          .finally(() => {
            setRefetch(false);
            setIsSubmitting(false);
          });
  }

  function closeModal() {
    setIsOpen(false);
    setErroSector("");
    setErroCategory("");
    setNomeSector("");
    setLotsSelecionados([]);
    setSeatCategory();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      contentLabel="SectorModal"
      className="shadow side-modal"
    >
      <StyledModal
        isOpen={isManagerOpen}
        onRequestClose={() => setIsManagerOpen(false)}
      >
        <div
          style={{
            borderBottom: "1px solid lightgray",
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px",
          }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsManagerOpen(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div style={{ height: "532px", width: "100%" }}>
          <SeatsioEventManager
            secretKey={SEATSIOKEY}
            event={seatsEventKey}
            mode="static"
            region={process.env.REACT_APP_SEATSIO_REGION}
            language="pt"
            showFullScreenButton={false}
          />
        </div>
      </StyledModal>

      <div className="card-header">
        <h4 className="card-header-title">
          {t(isEdit ? "editTitle" : "createTitle")}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 mt-4">
            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor="sector_name">{t("label.name")}</label>
                <input
                  required
                  placeholder={t("label.name")}
                  name="sector_name"
                  type="text"
                  value={nomeSector}
                  onChange={(e) => setNomeSector(e.target.value)}
                  className="form-control"
                />
                {erroSector && <p style={{ color: "red" }}>{erroSector}</p>}
              </div>

              {seatsEventKey && (
                <>
                  <div className="form-group col-lg-8 sm-12">
                    <label htmlFor="seats_categories">
                      {t("label.category")}
                    </label>
                    <Select
                      name="seats_categories"
                      isClearable={true}
                      backspaceRemovesValue={true}
                      value={
                        seatCategory
                          ? {
                              value: seatCategory,
                              label: (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      backgroundColor: seatCategory.color,
                                      marginRight: "5px",
                                    }}
                                  />
                                  <span>{seatCategory.name}</span>
                                </div>
                              ),
                            }
                          : null
                      }
                      onChange={(value) => setSeatCategory(value?.value)}
                      placeholder={t("placeholder.category")}
                      options={seatsCategoriesList.map((category) => ({
                        value: category,
                        label: (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: category.color,
                                marginRight: "5px",
                              }}
                            />
                            <span>{category.name}</span>
                          </div>
                        ),
                      }))}
                    />

                    {erroCategory && (
                      <p style={{ color: "red" }}>{erroCategory}</p>
                    )}
                  </div>
                  <div
                    className="form-group col-lg-4 sm-12"
                    style={{ display: "flex", alignItems: "end" }}
                  >
                    <button
                      style={{ width: "100%" }}
                      className="btn btn-primary"
                      onClick={() => setIsManagerOpen(true)}
                    >
                      {t("button.seats-map")}
                    </button>
                  </div>
                </>
              )}

              <div
                style={{
                  paddingBottom: "40px",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
                className="form-group offset-8 col-4 mt-4"
              >
                <button
                  disabled={isSubmitting}
                  style={{ width: "130px" }}
                  type="button"
                  onClick={() => salvar()}
                  className="btn btn-primary c3 "
                >
                  {t("button.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

//////////////////////////////////////// MODAL /////////////////////////////

const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: auto;
  background-color: white;
  overflow: auto;
  border: 1px solid lightgray;
  @media (max-width: 768px) {
    width: 100vw;
  }
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;
