import React, { useState } from "react";
import PageHeader from "components/page-header";
import { SeatsioDesigner } from "@seatsio/seatsio-react";
import Loading from "components/loading";
import Swal from "sweetalert2";

const SEATSIOKEY = process.env.REACT_APP_SEATSIO_KEY;

export default function SeatsMapCreate() {
  const [isLoading, setIsLoading] = useState(true);

  function stopLoading() {
    setIsLoading(false);
  }

  function handleRenderFail() {
    Swal.fire({
      title: "Ops!",
      icon: "error",
      text: "Não foi possível carregar o criador de mapa de lugares. Por favor, tente novamente.",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      preConfirm: () => {
        window.history.back();
      },
    });
  }

  return (
    <>
      {isLoading && <Loading title="Aguarde..." />}
      <PageHeader
        title="Novo mapa de assentos"
        text="Tela de criação de um mapa de assentos"
      />
      <div style={{ height: "calc(100% - 140px)", minHeight: "650px" }}>
        <SeatsioDesigner
          secretKey={SEATSIOKEY}
          region={process.env.REACT_APP_SEATSIO_REGION}
          language="pt"
          onDesignerRendered={stopLoading}
          onDesignerRenderingFailed={handleRenderFail}
        />
      </div>
    </>
  );
}
