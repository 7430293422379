// The style swap logic is based on sending the React select custom style to the component
const user = JSON.parse(localStorage.getItem("user"));

const lightStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#F5F5F7",
    boxShadow: "none",
    height: "30px",
    minHeight: "30px",
    border: state.isFocused ? "0.5px solid #767380" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "#767380" : "none",
    },
  }),
  dropdownIndicator: (provided) =>
    user.is_admin
      ? {
          ...provided,
          color: "#767380",
          "&:hover": {
            color: "#767380",
          },
          padding: "5px 8px 8px 8px",
        }
      : {
          display: "none",
        },
  indicatorSeparator: (provided, state) =>
    user.is_admin
      ? {
          ...provided,
          marginTop: "0px",
          height: "90%",
          backgroundColor: "#767380",
        }
      : {
          display: "none",
        },
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: "0px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#2F2E33",
    fontWeight: "500",
    fontSize: "0.75rem",
    marginBottom: "3px",
  }),
};

const successStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#CEF2E5",
    boxShadow: "none",
    height: "30px",
    minHeight: "30px",
    border: state.isFocused ? "0.5px solid #00C67F" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "#00C67F" : "none",
    },
  }),
  indicatorSeparator: (provided, state) =>
    user.is_admin
      ? {
          ...provided,
          marginTop: "0px",
          height: "90%",
          backgroundColor: "#00C67F",
        }
      : {
          display: "none",
        },
  dropdownIndicator: (provided) =>
    user.is_admin
      ? {
          ...provided,
          color: "#00422A",
          "&:hover": {
            color: "#00422A",
          },
          padding: "5px 8px 8px 8px",
        }
      : {
          display: "none",
        },
  singleValue: (provided) => ({
    ...provided,
    color: "#00422A",
    fontWeight: "500",
    fontSize: "0.75rem",
    marginBottom: "3px",
  }),
};

export const stylesByStatus = {
  SOLICITADO: lightStyle,
  "EM ANÁLISE": {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#FFF4D9",
      boxShadow: "none",
      height: "30px",
      minHeight: "30px",
      border: state.isFocused ? "0.5px solid #F3AF00" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#F3AF00" : "none",
      },
    }),
    dropdownIndicator: (provided) =>
      user.is_admin
        ? {
            ...provided,
            color: "#F3AF00",
            "&:hover": {
              color: "#F3AF00",
            },
            padding: "5px 8px 8px 8px",
          }
        : {
            display: "none",
          },
    indicatorSeparator: (provided, state) =>
      user.is_admin
        ? {
            ...provided,
            marginTop: "0px",
            height: "90%",
            backgroundColor: "#F3AF00",
          }
        : {
            display: "none",
          },

    singleValue: (provided) => ({
      ...provided,
      color: "#694C00",
      fontWeight: "500",
      fontSize: "0.75rem",
      marginBottom: "3px",
    }),
  },
  AGUARDANDO: successStyle,
  PAGO: successStyle,
  CANCELADO: lightStyle,
  RECUSADO: {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#FFE1D7",
      boxShadow: "none",
      height: "30px",
      minHeight: "30px",
      border: state.isFocused ? "0.5px solid #F53C5A" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#F53C5A" : "none",
      },
    }),
    dropdownIndicator: (provided) =>
      user.is_admin
        ? {
            ...provided,
            color: "#F53C5A",
            "&:hover": {
              color: "#F53C5A",
            },
            padding: "5px 8px 8px 8px",
          }
        : {
            display: "none",
          },
    indicatorSeparator: (provided, state) =>
      user.is_admin
        ? {
            ...provided,
            marginTop: "0px",
            height: "90%",
            backgroundColor: "#F53C5A",
          }
        : {
            display: "none",
          },
    singleValue: (provided) => ({
      ...provided,
      color: "#730A46",
      fontWeight: "500",
      fontSize: "0.75rem",
      marginBottom: "3px",
    }),
  },
};
