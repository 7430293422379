import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { COLORS, SPACINGS, breakPoints } from "services/constants.js";
import { IoIosCloseCircleOutline } from "react-icons/io";
import styled from "styled-components";
import api from "services/api";

Modal.setAppElement("#root");
ModalReset.propTypes = {
  isOpen: PropTypes.bool,
  sectors: PropTypes.array,
  setIsOpen: PropTypes.func,
  event_id: PropTypes.number,
  callBack: PropTypes.func,
  toast: PropTypes.any,
};

export default function ModalReset(props) {
  const { event_id, sectors, isOpen, setIsOpen, toast, callBack } = props;
  const [selectedSectors, setSelectedSectors] = useState([]);

  const addOrRemoveSectors = (ids) => {
    const arraySectors = selectedSectors || [];
    const ticket_type_ids = ids
      .reduce((r, obj) => r.concat(obj.ticketType), [])
      .map((ticket) => ticket.id);

    for (const id of ticket_type_ids) {
      const checkId = arraySectors.includes(id);
      if (checkId) {
        setSelectedSectors((oldArray) =>
          oldArray.filter((newArray) => newArray !== id)
        );
      } else {
        setSelectedSectors(arraySectors.concat(ticket_type_ids));
      }
    }
  };

  const resetTicket = () => {
    api
      .put(`/resetCheckin/${event_id}`, { ticket_type_ids: selectedSectors })
      .then(() => {
        toast.success("Tickets resetados com sucesso!");
      })
      .catch(() => {
        toast.error("Ocorreu um erro por favor tente novamente.");
      })
      .finally(() => {
        const page = 1,
          perPage = 25;
        onClose(false);
        callBack("initial", page, perPage);
      });
  };

  const onClose = (status) => {
    setSelectedSectors([]);
    setIsOpen(status);
  };

  return (
    <ModalComponent
      className="shadow middle-modal"
      isOpen={isOpen}
      onRequestClose={() => onClose(false)}
    >
      <ModalContainer>
        <HeaderContainer>
          <HeaderText>Selecione os setores:</HeaderText>
          <CloseButton onClick={() => onClose(false)}>
            <CloseIcon size={32} />
          </CloseButton>
        </HeaderContainer>
        <SectorsContainer>
          <Table className="table">
            <Thead>
              <TrBold>
                <Td>Setor</Td>
                <TdEnd>Selecione</TdEnd>
              </TrBold>
            </Thead>
            <Tbody>
              {sectors.map((sector) => (
                <Tr key={sector.id}>
                  <Td>{sector.name}</Td>
                  <TdCheckbox>
                    <InputCheckbox
                      onChange={() => {
                        addOrRemoveSectors(sector.eventsectorlot);
                      }}
                    />
                  </TdCheckbox>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </SectorsContainer>
        {selectedSectors.length > 0 ? (
          <ButtonContainer>
            <Button className="btn btn-secondary" onClick={() => resetTicket()}>
              Resetar
            </Button>
          </ButtonContainer>
        ) : null}
      </ModalContainer>
    </ModalComponent>
  );
}

////////////// styles ///////////////////

// Styles Modal
const ModalComponent = styled(Modal)`
  width: 90%;
  height: auto;
  left: 5%;
  position: absolute;
  top: 22%;
  right: 0;
  overflow-y: auto;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  @media ${breakPoints.mobileL} {
    width: 440px;
    height: auto;
    left: 50%;
    transform: translate(-40%, 0);
  }
  @media screen and (max-width: 768px) {
    top: 25%;
  }
`;
const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} ${SPACINGS.space5} ${SPACINGS.space2};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: visible;
`;
//

//Styles Header
const HeaderContainer = styled.div`
  padding: ${SPACINGS.space3} ${SPACINGS.space3} 0 ${SPACINGS.space3};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45px;
`;
const HeaderText = styled.span`
  color: ${COLORS.dark};
  text-align: left;
  font-size: 15px;
  display: flex;
  line-height: 45px;
  align-items: center;
`;
//

//Styles ClosedButton
const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 34px;
`;
//

//Styles SectorContainer
const SectorsContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`;
//

//Styles Table
const Table = styled.table``;
const Thead = styled.thead``;
const Tbody = styled.tbody``;
const Tr = styled.tr``;
const Td = styled.td``;
const TdCheckbox = styled.td`
  text-align: center;
  width: 10%;
`;
const TdEnd = styled.td`
  text-align: end;
`;
const TrBold = styled.tr`
  font-weight: bold;
`;
//

//Styles Input
const InputCheckbox = styled.input.attrs({
  type: "checkbox",
})``;

//Styles Button
const ButtonContainer = styled.div`
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Button = styled.button.attrs({
  type: "button",
})``;
//
