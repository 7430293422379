import React, { useState, useEffect } from "react";
import Api from "services/api.js";
import Swal from "sweetalert2";

const RecuperarSenha = () => {
  const [isValid, setIsValid] = useState(false);


  const [data, setData] = useState({
    password: "",
    confirmpassword: "",
    token: "",
    email: ""
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    Api.get(`valida_token/${token}`)
      .then(res => {
        setIsValid(true);
        setData({
          ...data,
          token: token,
          email: res.data.user.email
        });
      })
      .catch(err => {
        setIsValid(false);

          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })

      });
  }, [data]);


  const updateField = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const resetPassword = e => {
    e.preventDefault();
    if (data.password !== data.confirmpassword || data.password === "") {
      var x = document.getElementById("alert");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    } else {
      Api.post("reset", data).then(res => {
        alert("Senha alterada com sucesso!");
        window.location.href = '/'
      }).catch(err=>{
        alert("Senha inalterada");
      });
    }
  };

  return (
    <div className="container">
      {isValid ? (
        <form onSubmit={resetPassword} id="form-login">
          <img src="../../logo_login.svg" alt="Logo" />
          <div
            id="alert"
            className="alert alert-danger"
            style={{ display: "none" }}
          >
            <span>As senhas devem ser idênticas</span>
          </div>
          <span className="subtitle" style={{ marginBottom: "10px" }}>
            Restaurar senha
          </span>
          <input
            type="text"
            readOnly
            className="form-control"
            value={data.email}
          />
          <label htmlFor="password">Nova Senha</label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            onChange={updateField}
            placeholder="Nova Senha"
          />
          <label htmlFor="confirmpassword">Confirme a nova senha</label>
          <input
            type="password"
            id="confirmpassword"
            name="confirmpassword"
            className="form-control"
            onChange={updateField}
            placeholder="Confirme a nova senha"
          />
          <button type="submit">Salvar</button>
        </form>
      ) : (
        <div id="form-login">
          <img src="../../logo_login.svg" alt="Logo" />
          <span className="subtitle">Token ou e-mail invalido</span>
        </div>
      )}
    </div>
  );
};

export default RecuperarSenha;
