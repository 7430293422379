import PropTypes from "prop-types";
import React from "react";

import CentralizedModal from "components/centralizedModal";

import { ReactComponent as Close } from "../../../assets/icons/closeIcon.svg";

import "./styles.css";

IntegrationModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default function IntegrationModal({ title, onClose, isOpen, children }) {
  return (
    <CentralizedModal isOpen={isOpen} onRequestClose={onClose}>
      <div className="row justify-content-between integration-header-container align-items-center">
        <div className="integration-header-title">{title}</div>
        <Close className="close-icon-integration" onClick={onClose} />
      </div>
      {children}
    </CentralizedModal>
  );
}
