import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function ApproveButton(props) {
  const { title } = props;
  const { t } = useTranslation("transfers");

  return (
    <ActionButton block variant="success" {...props}>
      <>
        <FaRegCheckCircle className="mr-2" />
        {title ? title : t("transfers:form.approveButton")}
      </>
    </ActionButton>
  );
}

//------ Styled Components -----//
const ActionButton = styled(Button)`
  svg {
    font-size: 24px;
  }
`;
