import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SuperRadioButton = (props) => {
  function callback(newValue) {
    props.callback(newValue);
  }

  return (
    <>
      {!props.space && <label className="form-label">&nbsp;</label>}
      <div
        className={`custom-input-option ${props.className || ''} ${
          props.value === props.requiredValue ? "active" : ""
        }`}
        onClick={(e) => {
          callback(props.requiredValue);
        }}
      >
        {props.value === props.requiredValue ? (
          <FontAwesomeIcon icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon icon={faCircle} />
        )}{" "}
        {props.label}
      </div>
    </>
  );
};

export default SuperRadioButton;
