import React, { useState, useEffect } from "react";
import PageHeader from "components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import {
  faPen,
  faUnlockAlt,
  faSearch,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import Api from "services/api.js";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastr";
import Loading from "components/loading";
import CustomButtonSpinner from "components/buttonSpinner";
import ModalUsers from "./modalUsers/index";

let container;
let porPagina = 25;

const Users = () => {
  const [UserID, setUserID] = useState("");
  const [statusModal, setStatusModal] = useState("modal-hidden");

  const [users, setUsers] = useState([]);
  const [, /*listamail*/ setListaMail] = useState([]);

  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });

  useEffect(() => {
    listUsers("initial", 1, porPagina);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function HandleEmail(user_id) {
    Swal.fire({
      title: "Resetar Senha",
      icon: "warning",
      text: "Deseja realmente resetar a senha e enviar o email a este usuario?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        Api.post("forgot_password", { user_id })
          .then((res) => {
            container.success("Senha encaminhada para o email do usuário!");
          })
          .catch((err) => {
            container.error("Erro");
          });
      }
    });
  }

  const atualizaStatus = (e, item) => {
    item.status = Number(!item.status);
    Api.get("user/" + item.id + "/status/" + item.status)
      .then((res) => {
        if (item.status === 1) {
          container.success("Usuário ativado com sucesso!");
        } else {
          container.success("Usuário desativado com sucesso!");
        }
      })
      .catch((err) => {
        container.error("Usuário não ativado/desativado!");
      });
  };

  function openModal(id) {
    setUserID(id);
    setStatusModal("modal-show");
  }

  function closeModal(e) {
    setUserID("");
    setStatusModal("modal-hidden");
  }
  //  Listar Users função para identificar qual botão esta sendo clicado no front - DEV Rodiney
  const listUsers = (typePage, numberPage, perPage, search) => {
    setIsLoading(true);

    let pageConsult = { page: numberPage, perPage: perPage, search: search };

    switch (typePage) {
      case "Initial":
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }

        break;

      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
        };
        break;
      default:
        pageConsult = { page: 1, perPage: perPage, search: pesquisa.pesquisa };
    }

    Api.post("listUser", pageConsult)
      .then((res) => {
        setUsers(res.data.data);
        setListaMail(res.data.data);

        setAtualizaPaginaAtual(res.data.page);
        setAtualizaUltimaPagina(res.data.lastPage);
        setAtualizaQuantPesquisa(res.data.total);
        setIsLoading(false);

        //Encerra loading
        document.getElementById("loading").classList.add("esconde");
      })
      .catch((err) => {
        if (err.response.data.error.status === 500) {
          container.error("Rota não encontrada");
        } else {
          container.error(err.response.data.error.message);
        }
        //Encerra loading
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      listUsers("initial", 1, porPagina, pesquisa.pesquisa);
    }
  };
  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Loading title="aguarde..." />

      <PageHeader
        title="Usuários"
        text="Gerencie os usuários cadastrados no sistema."
        btnAction={() => openModal(null)}
        btnText="criar usuário"
      ></PageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="col-12">
          <div className="row">
            <div className="col-md-10 mb-2">
              <div className="card-search d-flex">
                <FontAwesomeIcon icon={faSearch} className="icon-search" />
                <input
                  className="input-custom"
                  name="pesquisa"
                  value={pesquisa.pesquisa || ""}
                  onChange={updateData}
                  autoComplete="off"
                  placeholder="Pesquise pelo Nome, CPF, Telefone ou e-mail"
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <div className="col-md-2 mb-2">
              {/* <button className='btn btn-outline-secondary btn-height'
                value={pesquisa.pesquisa || ''}
                onClick={e => listUsers("initial", 1, porPagina, e.target.value)}>
                Pesquisar
              </button> */}
              <CustomButtonSpinner
                customStyle={"btn btn-outline-secondary btn-height"}
                triggerOnClick={listUsers}
                isLoading={isLoading}
                text={"Pesquisar"}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table table-sm card-table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col-3">Nome</th>
                    <th scope="col-3">Email</th>
                    <th scope="col-3">Status</th>
                    <th scope="col-3" className="text-right">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((users, i) => (
                    <tr key={i}>
                      <td>
                        {users.first_name} {users.last_name}
                      </td>
                      <td>{users.email}</td>
                      <td>
                        {users.status === 1 ? (
                          <div className="badge badge-pill badge-success">
                            Ativo
                          </div>
                        ) : (
                          <div className="badge badge-pill badge-danger">
                            Desativado
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="card-table-actions">
                          <ReactTooltip
                            className="tooltip-custom"
                            place="top"
                            effect="solid"
                          />
                          <span className="mr-3">Usuário Ativo</span>
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={users.id}
                              name={users.id}
                              defaultChecked={users.status}
                              onChange={(e) => atualizaStatus(e, users)}
                            ></input>
                            <label
                              className="custom-control-label"
                              htmlFor={users.id}
                            ></label>
                          </div>
                          <button
                            className="btn-table"
                            onClick={() => openModal(users.id)}
                            data-tip="Editar"
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                          <button
                            className="btn-table"
                            onClick={() => HandleEmail(users.id)}
                            data-tip="Resetar Senha"
                          >
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="col-12">
                <div className="row d-flex justify-content-between datatable-footer">
                  <div className="col-md-6 d-flex align-items-center">
                    <span className="mr-2">Resultados por Página:</span>
                    <div>
                      <select
                        name="SelecaoPorPagina"
                        onChange={(e) =>
                          listUsers("initial", 1, (porPagina = e.target.value))
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                    {porPagina * atualizaPaginaAtual >=
                    atualizaQuantPesquisa ? (
                      <span>
                        {" "}
                        Exibindo {atualizaQuantPesquisa} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    ) : (
                      <span>
                        {" "}
                        Exibindo {porPagina * atualizaPaginaAtual} de{" "}
                        {atualizaQuantPesquisa}{" "}
                      </span>
                    )}

                    <button onClick={() => listUsers("initial", 1, porPagina)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </button>
                    <button
                      onClick={() =>
                        listUsers("left", atualizaPaginaAtual, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <span>
                      Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                    </span>

                    <button
                      onClick={() =>
                        listUsers("right", atualizaPaginaAtual, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                    <button
                      onClick={() =>
                        listUsers("lastPage", atualizaUltimaPagina, porPagina)
                      }
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalUsers status={statusModal} id={UserID} callback={closeModal} />
      </div>
    </>
  );
};

export default Users;
