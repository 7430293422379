import PropTypes from "prop-types";
import "./styles.css";
import React from "react";

GroupHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    hr: PropTypes.bool,
    tclass: PropTypes.bool,
    dclass: PropTypes.bool,
};


export function GroupHeader({title, description, hr, tclass, dclass}) {
    return(
        <div className="d-flex flex-column">
            <p className={tclass}>{title}</p>
            <p className={dclass}>{description}</p>
            {hr && <hr className="hr"></hr>}
        </div>
    )
}