import React, { Fragment } from "react";
import {
  ActionButton,
  DrawerContent,
  OwnerDetail,
  OwnerLabel,
  OwnerValue,
} from "../../styles";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/closeIcon.svg";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "utils/date-formatter";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { formatCurrency } from "utils";
import ReturnStatus from "../return-status";

const CardSectionContent = ({ items }) => {
  return (
    <div className="card-section-content">
      {items.map((item, index) => (
        <div className="card-section-card-content" key={index}>
          <span>{item.label}</span>
          <h4>{item.value}</h4>
        </div>
      ))}
    </div>
  );
};

const OwnerSection = ({ header, entries }) => (
  <div className="owner-section">
    <h6>{header}</h6>
    {entries.map((entry, index) => (
      <OwnerDetail key={index}>
        <OwnerLabel>{entry.label}</OwnerLabel>
        <OwnerValue>{entry.value}</OwnerValue>
      </OwnerDetail>
    ))}
  </div>
);

const TransfersDetailDrawer = ({
  drawerContent,
  closeDrawer,
  setModalOpen,
  isAdmin,
}) => {
  const { ticketInfo, details } = drawerContent;
  const currentDetails = details.ticketTransfer[0];
  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");

  const { currency } = useCurrencyEventContext();

  return (
    <DrawerContent>
      <div>
        <div className="drawer-header">
          <h6>{t("nominalTransfers.detailsDrawer.header")}</h6>
          <CloseIcon onClick={closeDrawer} />
        </div>
        {drawerContent && (
          <Fragment>
            <hr />
            <ReturnStatus status={ticketInfo.transferStatus} t={t} />
            <div className="card-section">
              <div className="ticket-section-header">
                <h2>{ticketInfo.ticket.ticketType.name}</h2>
                <span>#{ticketInfo.code}</span>
              </div>

              <CardSectionContent
                items={[
                  ...(ticketInfo?.seat?.seatName
                    ? [
                        {
                          label: t("nominalTransfers.detailsDrawer.cards.seat"),
                          value: ticketInfo?.ticket?.seatName,
                        },
                      ]
                    : []),
                  {
                    label: t("nominalTransfers.detailsDrawer.cards.sector"),
                    value: currentDetails?.sector,
                  },
                  {
                    label: t("nominalTransfers.detailsDrawer.cards.price"),
                    value: formatCurrency(currentDetails.value, {
                      currency,
                    }),
                  },
                ]}
              />
            </div>
            <hr />
            <OwnerSection
              header={t("nominalTransfers.detailsDrawer.ownerSection.sentBy")}
              entries={[
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.name"),
                  value: currentDetails?.sendName || "-",
                },
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.email"),
                  value: currentDetails?.sendEmail,
                },
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.phone"),
                  value: currentDetails?.sendTelephone || "-",
                },
                {
                  label: t(
                    "nominalTransfers.detailsDrawer.ownerSection.transferedOn"
                  ),
                  value: dateFormatter(currentDetails?.sendDate, {
                    shouldShowTime: true,
                    locale: language,
                  }),
                },
              ]}
            />
            <hr />
            <OwnerSection
              header={t(
                "nominalTransfers.detailsDrawer.ownerSection.receivedBy"
              )}
              entries={[
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.name"),
                  value: currentDetails?.destinationName || "-",
                },
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.email"),
                  value: currentDetails?.destinationEmail,
                },
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.phone"),
                  value: currentDetails?.destinationTelephone || "-",
                },
                {
                  label: t(
                    "nominalTransfers.detailsDrawer.ownerSection.receivedOn"
                  ),
                  value: dateFormatter(
                    currentDetails?.destinationDateReceived,
                    {
                      shouldShowTime: true,
                      locale: language,
                    }
                  ),
                },
              ]}
            />
            <hr />
            <OwnerSection
              header={t(
                "nominalTransfers.detailsDrawer.ownerSection.headerBuyer"
              )}
              entries={[
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.name"),
                  value: currentDetails?.buyName,
                },
                {
                  label: t("nominalTransfers.detailsDrawer.ownerSection.email"),
                  value: currentDetails?.buyEmail,
                },
                {
                  label: t(
                    "nominalTransfers.detailsDrawer.ownerSection.completedOn"
                  ),
                  value: dateFormatter(currentDetails?.buyDate, {
                    shouldShowTime: true,
                    locale: language,
                  }),
                },
              ]}
            />
            <hr />
          </Fragment>
        )}
      </div>
      {ticketInfo?.transferStatus === "completed" &&
        !ticketInfo.ticket.isConsumed &&
        currentDetails.active === 1 &&
        !!isAdmin && (
          <ActionButton cancel onClick={() => setModalOpen(true)}>
            {t("nominalTransfers.modal.button.confirm")}
          </ActionButton>
        )}
    </DrawerContent>
  );
};

export default TransfersDetailDrawer;
