import React from "react";
import HeaderCadastro from "../headercadastro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

export default class ConfiguracaoIngresso extends React.Component {
  state = {
    dates: this.props.dates,
  };
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
        <>
        <HeaderCadastro
          title= "3. Configurações gerais"
          text= "configure suas vendas. <a href='/evento-help' target='_blank'>Saiba mais como configurar.</a>"
        >
        </HeaderCadastro>

        <div className="mt-4 event-step">
          <div className="col-12 pl-0">

            <div className="card mt-0">
              <div className="card-body">
                <b>Configurações dos ingressos</b>
                {this.state.dates &&
                  this.state.dates.length &&
                  this.state.dates.map((date, date_i) => (
                    <form key={date_i}>
                      <div className="form-row">
                        <div className="col-md-12 mb-3 evento-name">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          {date.title}{" "}
                          {date.location
                            ? `- ${
                                date.location.name
                                  ? date.location.name
                                  : date.location
                              }`
                            : ""}
                        </div>
                      </div>
                        <p className="titulo-info">
                          Quais setores, lotes e tipos você possui para essa data?
                        </p>
                        <p className="fonte-label">
                          Ao desmarcar um setor você não exibe ele para essa data / evento. Você também pode configurar a permissão de tipos de ingresso e lotes disponíveis para cada setor do evento.
                        </p>
                      {date.sectors?.map((sector, sector_i) => (
                      <div key={sector_i}>
                        <div className="form-row setup-tickets">
                          <div className="col-md-3">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                defaultChecked={sector.active}
                                onChange={(e) => {
                                  sector.active = e.target.checked;
                                  this.setState({});
                                }}
                                id={`sector-${sector_i}`}
                              />
                              <label
                                className="custom-control-label"
                                style={{ color: "#484848" }}
                                htmlFor={`sector-${sector_i}`}
                              >
                                {sector.name}
                              </label>
                            </div>
                            <div className="tiposIngressosDiv">
                              <ul className="principalIngressos">
                                <li>tipos de ingresso</li>
                                <li>
                                  <ul className="secundarioIngressos">
                                    {sector.ticketTypes.map((type, type_i) => (
                                      <li key={type_i}>
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked={type.active}
                                            onChange={(e) => {
                                              type.active = e.target.checked;
                                              this.setState({});
                                            }}
                                            id={`sector-${sector_i}-type-${type_i}`}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`sector-${sector_i}-type-${type_i}`}
                                          >
                                            {type.name}
                                          </label>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                                <li>lotes disponíveis</li>
                                <li>
                                  <ul className="secundarioIngressos">
                                    {sector.lots.map((lot, lot_i) => (
                                      <li key={lot_i}>
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked={lot.active}
                                            onChange={(e) => {
                                              lot.active = e.target.checked;
                                              this.setState({});
                                            }}
                                            id={`sector-${sector_i}-lot-${lot_i}`}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`sector-${sector_i}-lot-${lot_i}`}
                                          >
                                            {lot.name}
                                          </label>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      ))}
                    </form>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
