import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Loading from "components/loading";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import CardWhite from "newComponents/cardWhite";
import api from "services/api";
import financialApi from "services/financial-api";
import { formatCurrency } from "utils";
import { capitalizeEachFirstWord } from "utils/str-format";

import csswelcome from "../../../cssdinamico";

import "./styles.css";
import NewPageHeader from "components/newPageHeader";
import NewSelect from "components/newSelect";
import { NoSalesYet } from "./components/NoSalesYet";
import { TotalSalesOrPaidSales } from "./components/TotalSalesOrPaidSales";
import { FreeTicketsReports } from "./components/FreeTicketsReport";
import DataTip from "components/data-tip";
import { ReactComponent as InfoTool } from "../../../assets/icons/infoTool.svg";
import { isWhitelabel } from "services/constants";

const defaultPaymentMethods = {
  "pt-BR": [
    { name: "Boleto", qty: 0, value: 0 },
    { name: "Crédito", qty: 0, value: 0 },
    { name: "PIX", qty: 0, value: 0 },
  ],
  en: [
    { name: "Credit", qty: 0, value: 0 },
    { name: "Debit", qty: 0, value: 0 },
  ],
  es: [
    { name: "Crédito", qty: 0, value: 0 },
    { name: "Débito", qty: 0, value: 0 },
  ],
};

export default function Eventodetalhes() {
  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const [relatorio, setRelatorio] = useState({});
  const [summary, setSummary] = useState({
    available: 0,
    waiting: 0,
    paid: 0,
    analyze: 0,
    pending: 0,
    blocked: 0,
    pre_approved: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation(["tickets", "global", "backend"]);
  const dashboardFilters = [
    { value: undefined, label: t("global:allTickets") },
    { value: "paid", label: t("global:paidTickets") },
    { value: "free", label: t("global:freeTickets") },
  ];
  const [filter, setFilter] = useState(dashboardFilters[0]);
  useEffect(() => {
    csswelcome();
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, filter]);
  const { currency } = useCurrencyEventContext();

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  const load = async () => {
    financialApi
      .get(`transfers/summary/${event_id}`)
      .then((res) => setSummary(res.data));
    setIsLoading(true);
    api
      .get(`event/dashboard/${event_id}`, {
        params: { type_ticket: filter.value },
      })
      .then((res) => {
        let relatorio = res.data;
        if (!relatorio.origem_receitas) {
          relatorio.origem_receitas = [
            {
              name: t("global:onlineSales"),
              value: 0,
              types: [
                {
                  name: capitalizeEachFirstWord(t("global:online")),
                  qty: 0,
                  value: 0,
                  payments: defaultPaymentMethods[i18n.language],
                },
              ],
            },
          ];
        } else {
          relatorio.origem_receitas = relatorio.origem_receitas.map(
            (origem) => {
              return {
                name: capitalizeEachFirstWord(
                  t(origem.name, { ns: "backend" })
                ),
                value: origem.value,
                types: origem.types.map((type) => {
                  return {
                    name: capitalizeEachFirstWord(
                      t(type.name, { ns: "backend" })
                    ),
                    qty: type.qty,
                    value: type.value,
                    payments: type.payments.map((payment) => {
                      return {
                        name: capitalizeEachFirstWord(
                          t(payment.name, { ns: "backend" })
                        ),
                        qty: payment.qty,
                        value: payment.value,
                      };
                    }),
                  };
                }),
              };
            }
          );
        }
        setRelatorio(relatorio);
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loading title={t("global:loadingText")} />
      ) : (
        <Fragment>
          <NewPageHeader
            title={t("tickets:dashboard.title")}
            subtitle={t("tickets:dashboard.subtitle")}
          />
          <div
            id="page-dashboard-ticket"
            className="container-fluid page-content"
          >
            <div id="card" className="col-12 mt-3">
              <div className="row pr-2 pl-2">
                <div className={`${isWhitelabel ? 'col-md-4' : 'col-md-3'} mb-3 pr-2 pl-2`}>
                  <CardWhite
                    name="totalSales"
                    isDashboard
                    containerStyle={{
                      minHeight: "147px",
                    }}
                    title={t("tickets:dashboard.cards.totalSold.title")}
                    value={formatCurrency(relatorio.totalSales || 0, {
                      isCents: false,
                      currency,
                    })}
                    icon={
                      <DataTip
                        icon={<InfoTool width="1rem" height="1rem" />}
                        tipContent={t(
                          "tickets:dashboard.cards.totalSold.tooltip"
                        )}
                        tipContainerClass="icon-white mt-1"
                      />
                    }
                    subTitle={
                      <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                        <span>
                          <p className="custom-card-subtitle">
                            {`${formatCurrency(relatorio.vendas_totais || 0, {
                              isCents: false,
                              currency,
                            })} - `}
                            {t(
                              "tickets:dashboard.cards.totalSold.producerReceipt"
                            )}
                          </p>
                          <p className="custom-card-subtitle">
                            {`${formatCurrency(
                              relatorio.vendas_dinheiro_totais,
                              {
                                isCents: false,
                                currency,
                              }
                            )} - `}
                            {t(
                              "tickets:dashboard.cards.totalSold.moneyReceipt"
                            )}
                          </p>
                        </span>
                      </div>
                    }
                  />
                </div>
                <div className={`${isWhitelabel ? 'col-md-4' : 'col-md-3'} mb-3 pr-2 pl-2`}>
                  <CardWhite
                    name="totalTickets"
                    isDashboard
                    containerStyle={{
                      minHeight: "147px",
                    }}
                    title={t("tickets:dashboard.cards.emittedTickets.title")}
                    value={
                      relatorio.ingressos_vendidos +
                        relatorio.cortesias_emitidas || 0
                    }
                    subTitle={
                      <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                        <span>
                          <p className="custom-card-subtitle">
                            {t("tickets:dashboard.cards.emittedTickets.sold", {
                              count: relatorio.ingressos_vendidos || 0,
                            })}{" "}
                            +{" "}
                            {t(
                              "tickets:dashboard.cards.emittedTickets.courtesy",
                              {
                                count: relatorio.cortesias_emitidas || 0,
                              }
                            )}
                          </p>
                        </span>
                      </div>
                    }
                  />
                </div>
                <div className={`${isWhitelabel ? 'col-md-4' : 'col-md-3'} mb-3 pr-2 pl-2`}>
                  <CardWhite
                    name="average"
                    isDashboard
                    containerStyle={{
                      minHeight: "147px",
                    }}
                    title={t("tickets:dashboard.cards.average.title")}
                    value={formatCurrency(relatorio.ticket_medio || 0, {
                      isCents: false,
                      currency,
                    })}
                    subTitle={
                      <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                        <span>
                          <p className="custom-card-subtitle">
                            {t("tickets:dashboard.cards.average.buyingAverage")}
                          </p>
                        </span>
                      </div>
                    }
                  />
                </div>
                {!isWhitelabel && (
                  <div className="col-md-3 mb-3 pr-2 pl-2">
                    <CardWhite
                      name="totalAvailable"
                      isDashboard
                      containerStyle={{
                        minHeight: "147px",
                      }}
                      icon={
                        <DataTip
                          icon={<InfoTool width="1rem" height="1rem" />}
                          tipContent={t(
                            "tickets:dashboard.cards.available.tooltip"
                          )}
                          tipContainerClass="icon-white mt-1"
                        />
                      }
                      title={t("tickets:dashboard.cards.available.title")}
                      value={formatCurrency(
                        summary.available > 0 ? summary.available : 0,
                        {
                          isCents: true,
                          currency,
                        }
                      )}
                      subTitle={
                        <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                          <span>
                            {summary.available <= 0 ? (
                              <p className="custom-card-subtitle">
                                {summary.available === 0
                                  ? t(
                                      "tickets:dashboard.cards.available.zeroAvailableSubtitle"
                                    )
                                  : t(
                                      "tickets:dashboard.cards.available.negativeAvailableSubtitle",
                                      {
                                        availableAmount: formatCurrency(
                                          Math.abs(summary.available),
                                          {
                                            currency,
                                            isCents: true,
                                          }
                                        ),
                                      }
                                    )}
                              </p>
                            ) : (
                              <>
                                <p className="custom-card-subtitle">
                                  {t(
                                    "tickets:dashboard.cards.available.preApproved"
                                  )}
                                </p>
                              </>
                            )}
                          </span>
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            {(relatorio.vendas_ingresso || relatorio.origem_receitas) && (
              <div
                className="col-12 md-layout md-alignment-center-space-around"
                id="bottom-cards"
              >
                <div className="col-12 col-md-3 d-flex flex-row p-0 mt-3 mb-2">
                  <NewSelect
                    isSearchable={false}
                    options={dashboardFilters}
                    value={filter}
                    onChange={handleFilter}
                  />
                </div>
                {relatorio.vendas_ingresso?.sectors?.length === 0 ||
                !relatorio.vendas_ingresso?.sectors ||
                !relatorio.origem_receitas ||
                relatorio.origem_receitas.payments?.length === 0 ? (
                  <NoSalesYet />
                ) : filter.value === "free" ? (
                  <FreeTicketsReports
                    sectors={relatorio.vendas_ingresso?.sectors}
                    originSales={relatorio.origem_receitas || []}
                  />
                ) : (
                  <TotalSalesOrPaidSales
                    sectors={relatorio.vendas_ingresso?.sectors}
                    totalTicketSales={relatorio.totalTicketSales}
                    absorbedTaxes={relatorio.absorbed_taxes}
                    originSales={relatorio.origem_receitas || []}
                    filter={filter}
                  />
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
