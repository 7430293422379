import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamation,
} from "@fortawesome/free-solid-svg-icons";


export default function HeaderCadastro(props){
    return(
        <>

      <div className="alert alert-primary mt-2" role="alert">
        <span className="icon-circle-sm"><FontAwesomeIcon icon={faExclamation} /></span>
        <span>
          Você está no novo módulo de criação de evento. Para sair da criação e
          <a href="/eventos"><u> retornar ao seu painel, clique aqui.</u></a>
        </span>
      </div>

      <div className="header">
        <div className="header-body">
          <h1 className="header-title">
            {props.title}
          </h1>
          <p className="header-postitle" dangerouslySetInnerHTML={{__html:props.text}}>

          </p>
        </div>
      </div>

        </>
    )
}
