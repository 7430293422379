import React, { useState, useEffect, Fragment } from "react";
import { ToastContainer } from "react-toastr";
import Switch from "react-switch";
import CurrencyInput from "react-currency-input";
import "./styles.css";
import { ReactComponent as Info } from "../../../../../../assets/icons/infoTool.svg";

// Components
import EventCreateHeader from "../../event-create-header";
import { GroupHeader } from "../event-create-step-1/groupHeader";
import api from "services/api";
import Swal from "sweetalert2";
import { COLORS } from "services/constants";
import { DatePicker } from "components/datepicker";
import { useTranslation } from "react-i18next";

// Extra
let container;

export function validateStep4(data) {
  let error = 0;

  data.forEach((validation) => {
    validation.sectors.forEach((sector) => {
      sector.lots.forEach((item, i) => {
        const element_price = document.getElementById(`lot_input-${i}`);
        const element_qty = document.getElementById(`LotId-${i}`);
        const element_date = document.getElementById(`DatePickerid-${i}`);

        if (element_price.classList.contains("is-invalid")) {
          element_price.classList.remove("is-invalid");
        }

        if (element_qty.classList.contains("is-invalid")) {
          element_qty.classList.remove("is-invalid");
        }

        if (element_date.classList.contains("is-invalid")) {
          element_date.classList.remove("is-invalid");
        }

        if (!item.price || item.price === "") {
          element_price.classList.add("is-invalid");
          container.error("Ingresso deve possuir valor.");
          error++;
          return false;
        }

        if (!item.qty || item.qty === "") {
          element_qty.classList.add("is-invalid");
          container.error("Digite a quantidade de ingressos disponíveis.");
          error++;
          return false;
        }

        if (!item.expiration || item.expiration === "") {
          element_date.classList.add("is-invalid");
          container.error(
            "Selecione a data de expiração do lote para cada setor."
          );
          error++;
          return false;
        }
      });
    });
  });

  if (error === 0) {
    return true;
  }
}

const EventCreateStep4 = (props) => {
  const [stepData, setStepData] = useState(props.datesLocations);
  const [dataEvent, setDataEvent] = useState(props.event);
  const [nomeclatures, setNomeclatures] = useState([]);
  const { t } = useTranslation();

  const updateDataEvent = (e) => {
    setDataEvent({
      ...dataEvent,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    api
      .get(`/system/nomenclature`)
      .then((res) => {
        setNomeclatures(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.stepCheck) {
      return;
    }

    props.callBack(stepData, dataEvent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stepCheck]);

  useEffect(() => {
    props.dataEventCallback(dataEvent);
    // eslint-disable-next-line
  }, [dataEvent]);

  const handleFee = (e) => {
    setDataEvent({
      ...dataEvent,
      taxes: e ? "Absorver" : "Repassar",
    });
  };

  return (
    <Fragment>
      <EventCreateHeader
        title={`${props.sectors?.length > 1 ? "4" : "3"}. Preços e quantidades`}
        text="configure os valores e quantidades dos seus ingressos. <a href='/evento-help' target='_blank'>Saiba mais como configurar.</a>"
      ></EventCreateHeader>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className="mt-4 event-step">
        <div className="col-12 pl-0">
          <div className="card mt-0">
            <div className="card-body">
              <GroupHeader
                title="Informações detalhadas"
                tclass="t t-bold"
                hr
              />
              <div>
                <div className="form-row">
                  <div className="col-md-6">
                    <label className="form-label">
                      Nomenclatura do bilhete
                    </label>
                    <select
                      className="custom-select"
                      name="ticket_nomenclature"
                      value={dataEvent.ticket_nomenclature}
                      onChange={updateDataEvent}
                    >
                      <option key={`nomeclature-0`} value="Ingressos">
                        Ingressos
                      </option>
                      {nomeclatures.map((item, i) => (
                        <option key={`nomeclature-${i}`} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {stepData.map((date, i) => (
                <div key={i} className="mt-3">
                  {date.sectors.map((sector, sector_i) => (
                    <div key={`sector-${sector_i}`}>
                      <div className="card">
                        <div className="table-create-events">
                          <div className="event-sector">
                            <div className="col-md-12">
                              <span>Setor: {sector.name}</span>
                            </div>
                          </div>
                          <table
                            // id="table-totalsales"
                            className="table table-sm card-table"
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" className="text-center">
                                  Lote
                                </th>
                                <th scope="col" className="text-center">
                                  Valor
                                </th>
                                <th scope="col" className="text-center">
                                  Qtd
                                </th>
                                <th scope="col" className="text-center">
                                  Data Expiração
                                </th>
                                <th scope="col" className="text-center">
                                  Canais de vendas
                                </th>
                              </tr>
                            </thead>
                            {sector.lots.map((lot, lot_i) => (
                              <tbody
                                key={`lot-${lot_i}`}
                                className="event-lot-group"
                              >
                                <tr>
                                  <td className="text-center">{lot.name}</td>
                                  <td>
                                    <div className="input-group value-create-events mt-1">
                                      <div className="input-group-prepend">
                                        <div className="input-group-text">
                                          R$
                                        </div>
                                      </div>
                                      <CurrencyInput
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        className="form-control text-center"
                                        value={lot.price}
                                        id={`lot_input-${lot_i}`}
                                        onChangeEvent={(e) => {
                                          lot.price = e.target.value;
                                          setStepData([...stepData]);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      className="form-control text-center qtd-create-events mt-1"
                                      type="number"
                                      placeholder="0"
                                      value={lot.qty}
                                      id={`LotId-${lot_i}`}
                                      onChange={(e) => {
                                        lot.qty = Number(
                                          e.target.value
                                        ).toString();
                                        setStepData([...stepData]);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className="filter-sale create-events-date mt-1">
                                      <DatePicker
                                        className="text-center"
                                        value={lot.expiration}
                                        onChange={(value) => {
                                          lot.expiration = value;
                                          setStepData([...stepData]);
                                        }}
                                        minDate={new Date()}
                                        placeholder={t("events:createEvent.expirationDate")}
                                        textAlign="center"
                                      />
                                    </div>
                                  </td>

                                  <td className="text-center">
                                    <div className="item-score badge bg-soft-success ">
                                      todos
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="form-row mt-1">
                <div class="col d-flex align-items-center">
                  <Switch
                    handleDiameter={16}
                    onColor="#75d1f2"
                    onHandleColor={COLORS.middleBlue}
                    offColor={COLORS.weakGray}
                    boxShadow="0px 1px 3px 0px #4C4E641F"
                    activeBoxShadow="0px 1px 3px 0px #4C4E641F"
                    offHandleColor={COLORS.white}
                    height={10}
                    width={26}
                    onChange={(value) => handleFee(value)}
                    checked={dataEvent.taxes === "Absorver" ? true : false}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    id="react_switch"
                  />
                  {/* <input defaultChecked={false} onChange={(e) => handleFee(e)} type="checkbox" class="custom-control-input" id="customSwitch1"></input> */}
                  <label className="ml-2 mb-0" for="react_switch">
                    Absorver taxa de conveniência
                  </label>
                  <span className="tips">
                    <Info width={"1.5rem"} height={"1.5rem"} />
                    <div className="tips-content">
                      Absorção pela sua empresa do valor integral ou parcial da
                      Taxa de Conveniência das compras online.
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventCreateStep4;
