import React from "react";
import { DatePicker } from "components/datepicker";
import HeaderCadastro from "../headercadastro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faImage,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import JoditEditor from "jodit-react";
import api from "services/api";
import { mask } from "mask.js";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/assets/index.css";
import "./index.css";
const config = {
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
  ],
};
const linkEmpresa = process.env.REACT_APP_LINK_EMPRESA;

export default class Cadastroevento1 extends React.Component {
  state = {
    gmapsLoaded: false,
    organizations: [],
    nomeclatures: [],
    categories: this.props.categories,
    event: this.props.event,
    dates: this.props.dates,
    locations: this.props.locations,
    bannerloading: false,
    thumbloading: false,
    date: { name: "" },
    location: {
      name: "",
      street: "",
      neighborhood: "",
      number: "",
      city: "",
      state: "",
      zip_code: "",
      complement: "",
      formatted_address: "",
    },
    dateLocation: "",
    visivel: 1,
  };

  constructor(props) {
    super(props);
    let { event } = this.state;
    event.is_public = 1;
    this.setState({ event });
    api
      .get(`users/events/categories-and-organizations`)
      .then((res) => {
        let { categories, organizations } = res.data;
        this.setState({ categories, organizations });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });

    api
      .get(`/system/nomenclature`)
      .then((res) => {
        this.setState({ nomeclatures: res.data });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGpw6QCOspt7qdLnpwWV9Aw3lypy246uU&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  // Função que converte nome do evento em slug
  slugify(text, ampersand = "e") {
    const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
    const b = "aaaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
    const p = new RegExp(a.split("").join("|"), "g");

    text = text
      .toString()
      .toLowerCase()
      .replace(/[\s_]+/g, "-")
      .replace(p, (c) => b.charAt(a.indexOf(c)))
      .replace(/&/g, `-${ampersand}-`)
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+|-+$/g, "");

    let { event } = this.state;
    event.slug = text;
    this.setState({ event });
  }

  isPublicChange = (changeEvent) => {
    let { event } = this.state;
    event.is_public = parseInt(changeEvent.target.value);
    this.setState({ event });
  };

  taxesChange = (changeEvent) => {
    let { event } = this.state;
    event.taxes = changeEvent.target.value;
    this.setState({ event });
  };

  nameChange = (e) => {
    let text = e.target.value;
    let { event } = this.state;
    event.name = text;
    this.slugify(text.trim());
    this.setState({ event });
  };

  categoryChange = (e) => {
    let { event } = this.state;
    event.category_id = e.target.value;
    this.setState({ event });
  };

  uploadImage(input_id) {
    let input = document.getElementById(input_id);
    var form_data = new FormData();
    let file_data = input.files[0];
    let { event } = this.state;

    let banner = input_id === "event-banner";
    let thumb = input_id === "event-thumbnail";

    // Atualiza testo
    // var nextSibling = e.target.nextElementSibling;
    // nextSibling.innerText = file_data.name;

    if (banner) {
      // this.bannerError = false;
      this.setState({ bannerloading: true });
    } else if (thumb) {
      // this.logoError = false;
      this.setState({ thumbloading: true });
    }

    form_data.append("image", file_data);

    api
      .post("uploads?local=eventos", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (banner) {
          document.getElementById("banner-label").innerText = file_data.name;
          event.banner = res.data.url;
          this.setState({
            bannerloading: false,
            event,
          });
        } else if (thumb) {
          document.getElementById("thumb-label").innerText = file_data.name;
          event.thumb = res.data.url;
          this.setState({
            thumbloading: false,
            event,
          });
        } else {
          // this.state.sectorLot.eventBanner = res.data;
        }
        this.setState({});
      })
      .catch((err) => {
        if (banner) {
          // this.bannerError = true;
          this.setState({ bannerloading: false });
        }
        if (thumb) {
          // this.logoError = true;
          this.setState({ thumbloading: false });
        }
      });
  }

  fetchCEP = () => {
    let { location } = this.state;
    let cep = location.zip_code;
    if (cep && cep.length === 9) {
      let api = axios.create();
      api
        .get(`https://viacep.com.br/ws/${cep.replace("-", "")}/json/`, {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          let address = await response.data;

          location.street = address.logradouro;
          location.neighborhood = address.bairro;
          location.city = address.localidade;
          location.state = address.uf;
          location.complement = address.complemento;

          this.setState({});
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  addLocation = (online = null) => {
    let { location, locations, dateLocation, visivel } = this.state;
    if (online === 1) {
      visivel = 0;
      location.name = "Online";
      location.street = ".";
      location.neighborhood = ".";
      location.number = ".";
      location.city = ".";
      location.state = ".";
      location.zip_code = ".";
      location.number = ".";
      location.complement = ".";
      this.setState({ address: "." });
      locations.push(Object.assign({}, location));
      dateLocation = JSON.stringify(locations[0]);
      this.setState({ location, locations, dateLocation, visivel });
    } else {
      if (
        (location.name && location.city && location.state && location.street) ||
        location.formatted_address !== ""
      ) {
        // Verifica se existe local com msm nome
        let locationExists =
          locations.findIndex((loc) => {
            return loc.name === location.name;
          }) > -1;

        // Adiciona ao array de locations
        // Object.assign é utilizado para n pegar a referencia de location
        if (!locationExists) {
          locations.push(Object.assign({}, location));
          location.name = "";
          location.street = "";
          location.neighborhood = "";
          location.number = "";
          location.city = "";
          location.state = "";
          location.zip_code = "";
          location.number = "";
          location.complement = "";
          this.setState({ address: "" });
          dateLocation = JSON.stringify(locations[0]);
          this.setState({ location, locations, dateLocation });
          // this.locationErrors = false;
        } else {
          if (visivel === 0) {
            location.name = "";
            location.street = "";
            location.neighborhood = "";
            location.number = "";
            location.city = "";
            location.state = "";
            location.zip_code = "";
            location.number = "";
            location.complement = "";
            this.setState({ address: "" });
            visivel = 1;
            locations = [];
            dateLocation = "";
            this.setState({ location, locations, dateLocation, visivel });
          } else {
            Swal.fire({
              title: "Ops!",
              icon: "warning",
              text: "Você já possui um evento neste local",
              confirmButtonText: "Ok",
            });
          }
        }
      } else {
        Swal.fire({
          title: "Falha",
          icon: "warning",
          text: "Endereço incompleto",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  removeLocation = (locationName) => {
    let { locations } = this.state;

    let i = 0;
    while (i < locations.length) {
      if (locations[i].name === locationName) {
        locations.splice(i, 1);
      } else {
        ++i;
      }
    }

    this.setState({ locations });
  };

  // Listener para adição de tags
  pushTag = (e) => {
    if (e.key === "Enter") {
      let { tags } = this.state.event;
      let currentTag = e.target.value.trim().toLowerCase();
      let index = tags.indexOf(currentTag);
      // Caso a tag seja repetida acende a tag já existente por 2seg
      if (index > -1) {
        let tag = document.getElementById(`tag-${index}`);
        tag.className = "event-tag c3";
        setTimeout(() => {
          tag.className = "event-tag";
        }, 2000);
      } else {
        // Caso contrário adiciona à lista de tags e reseta o input
        if (currentTag.length) {
          tags.push(currentTag);
        }
        this.forceUpdate();
        e.target.value = "";
      }
    }
  };

  addDate = () => {
    let { date, dates, dateLocation } = this.state;
    if (!date.start_date || !date.end_date) {
      Swal.fire({
        title: "Falha",
        icon: "warning",
        text: "Favor preencher data de inicio e fim.",
        confirmButtonText: "Ok",
      });
      return;
    }
    let start_date = moment(date.start_date);
    // if (!start_date.isValid()) {
    //   this.$props.dateError = "Data inicial inválida.";
    //   this.displayElement("date-error");
    //   return;
    // }
    let end_date = moment(date.end_date);
    // if (!end_date.isValid()) {
    //   this.$props.dateError = "Data final inválida.";
    //   this.displayElement("date-error");
    //   return;
    // }
    if (start_date.isAfter(end_date)) {
      Swal.fire({
        title: "Falha",
        icon: "warning",
        text: "A data de inicio deve ser menor do que a de término.",
        confirmButtonText: "Ok",
      });
      return;
    }
    start_date = start_date.toDate();
    end_date = end_date.toDate();
    let { name } = date;
    let nameRepeated =
      dates.findIndex((date) => {
        return date.name === name;
      }) > -1;

    if (nameRepeated) {
      Swal.fire({
        title: "Falha",
        icon: "warning",
        text: "Já existe uma data com este nome.",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!dateLocation.length) {
      Swal.fire({
        title: "Falha",
        icon: "warning",
        text: "Favor escolher um local.",
        confirmButtonText: "Ok",
      });
      return;
    }

    let location = JSON.parse(dateLocation);

    let title =
      `${name} - ${moment(start_date).format("[de] DD/MM/YYYY [às] HH:mm")}` +
      moment(end_date).format(" [até] DD/MM/YYYY [às] HH:mm");

    dates.push({
      start_date: moment.utc(date.start_date),
      end_date: moment.utc(date.end_date),
      title,
      name,
      location,
    });
    this.setState({ date: {}, dateLocation: "" });
  };

  eventoDescricao = (valor) => {
    let { event } = this.state;
    event.description = valor;
    this.setState({
      event,
    });
  };

  checkSlug = () => {
    let { event } = this.state;
    api
      .get(`check/event/slug/${event.slug}`)
      .then((res) => {})
      .catch((err) => {
        Swal.fire({
          title: "Falha",
          icon: "error",
          text: err.response.data.message,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      });
  };

  render() {
    return (
      <>
        <HeaderCadastro
          title="1. Informações básicas"
          text="Insira os dados básicos para a criação de seu evento. Nessa tela você cadastrará informações como nome, categoria e local. Pode também adicionar imagens de banner e miniatura para seu evento, bem como atrelar uma ou mais datas para a realização e definir o local. <a href='/evento-help' target='_blank'>Saiba mais como criar um evento do zero.</a>"
        ></HeaderCadastro>

        <div className="mt-4 event-step" id="create-event-1">
          <div className="col-12 pl-0">
            <div className="card mt-0">
              <div className="card-body">
                <form>
                  <b>Informações</b>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control inputs"
                        id="validationDefault01"
                        placeholder="Nome do evento"
                        value={this.state.event.name}
                        onChange={this.nameChange}
                        onBlur={this.checkSlug}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text inputs text-small"
                            id="inputTags"
                          >
                            {linkEmpresa}/eventos/
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control inputs"
                          id="slug"
                          value={this.state.event.slug}
                          onChange={(e) => {
                            let { event } = this.state;
                            event.slug = e.target.value;
                            this.setState({
                              event,
                            });
                          }}
                          onBlur={(e) => {
                            this.slugify(e.target.value);
                          }}
                          placeholder="Slug do evento (link)"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <select
                        className="custom-select"
                        value={this.state.event.organization_id}
                        onChange={(e) => {
                          let { event } = this.state;
                          event.organization_id = e.target.value;
                          event.organizations = [e.target.value];
                          this.setState({ event });
                        }}
                      >
                        <option value={null}>Escolha a organização</option>
                        {this.state.organizations &&
                          this.state.organizations.map((val, i) => (
                            <option key={i} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <select
                        className="custom-select"
                        value={this.state.event.categories[0]}
                        onChange={(e) => {
                          let { event } = this.state;
                          event.categories = [e.target.value];
                          this.setState({ event });
                        }}
                      >
                        <option value={null}>Escolha a categoria</option>
                        {this.state.categories &&
                          this.state.categories.map((val, i) => (
                            <option key={i} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <select
                        className="custom-select"
                        value={this.state.event.is_online}
                        onChange={(e) => {
                          let { event } = this.state;
                          event.is_online = e.target.value;
                          this.setState({ event });
                          if (e.target.value.toString() === "1") {
                            this.addLocation(1);
                          } else {
                            this.addLocation(null);
                          }
                        }}
                      >
                        <option value={0}>Evento Físico</option>
                        <option value={1}>Evento Online</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text inputs"
                            id="inputTags"
                          >
                            Tags
                          </span>
                        </div>
                        <input
                          type="text"
                          placeholder="Digite a tag e pressione enter para adicionar."
                          className="form-control inputs"
                          id="tags"
                          onKeyUp={this.pushTag}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row event-tags">
                    {this.state.event.tags.length > 0 &&
                      this.state.event.tags.map((tag, i) => (
                        <span
                          id={`tag-${i}`}
                          key={`tag-${i}`}
                          className="event-tag"
                        >
                          {tag}{" "}
                          <FontAwesomeIcon
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.state.event.tags.splice(i, 1);
                              this.setState({});
                            }}
                            icon={faTimesCircle}
                          />
                        </span>
                      ))}
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="card card-img-upload">
                        <div className="card-body row">
                          <div className="col-md-8">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faImage} /> Banner
                                </span>
                              </div>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  className="custom-file-input"
                                  id="event-banner"
                                  disabled={this.state.bannerloading}
                                  onChange={(e) => {
                                    this.uploadImage("event-banner");
                                  }}
                                  aria-describedby="inputGroupFileAddon01"
                                ></input>
                                <label
                                  className="custom-file-label"
                                  id="banner-label"
                                  htmlFor="event-banner"
                                >
                                  <div
                                    hidden={!this.state.bannerloading}
                                    className="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  {!this.state.bannerloading &&
                                  !this.state.event.banner
                                    ? "Procurar..."
                                    : null}
                                </label>
                              </div>
                            </div>
                            <span className="upload-desc text-small">
                              A dimensão recomendada é de <b>800 x 350</b>
                            </span>
                          </div>
                          <div className="col-md-4">
                            {this.state.event && this.state.event.banner && (
                              <div
                                className="banner-uploaded"
                                style={{
                                  backgroundImage: `url(${this.state.event.banner})`,
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card card-img-upload">
                        <div className="card-body row">
                          <div className="col-md-8">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faImage} /> Miniatura
                                </span>
                              </div>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  className="custom-file-input"
                                  id="event-thumbnail"
                                  disabled={this.state.thumbloading}
                                  onChange={(e) => {
                                    this.uploadImage("event-thumbnail");
                                  }}
                                  aria-describedby="inputGroupFileAddon02"
                                ></input>
                                <label
                                  className="custom-file-label"
                                  id="thumb-label"
                                  htmlFor="event-thumbnail"
                                >
                                  <div
                                    hidden={!this.state.thumbloading}
                                    className="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                  {!this.state.thumbloading &&
                                  !this.state.event.thumb
                                    ? "Procurar..."
                                    : null}
                                </label>
                              </div>
                            </div>
                            <span className="upload-desc text-small">
                              <b>300 x 230</b>
                            </span>
                          </div>
                          <div className="col-md-4">
                            {this.state.event && this.state.event.thumb && (
                              <div
                                className="img-uploaded"
                                style={{
                                  backgroundImage: `url(${this.state.event.thumb})`,
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-6 col-md-3 mb-3">
                      <label className="fonte-label">Visibilidade</label>
                      <div
                        className="form-control"
                        id="forms"
                        // style={{ textAlign: "center" }}
                      >
                        <div className="custom-input custom-checkbox">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input custom-control-input"
                              type="radio"
                              name="isPublic"
                              id="inlineRadio1"
                              value={1}
                              checked={this.state.event.is_public === 1}
                              onChange={this.isPublicChange}
                            />
                            <label
                              className="form-check-label custom-control-label"
                              htmlFor="inlineRadio1"
                            >
                              Público
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input custom-control-input"
                              type="radio"
                              name="isPublic"
                              id="inlineRadio2"
                              value={0}
                              checked={this.state.event.is_public === 0}
                              onChange={this.isPublicChange}
                            />
                            <label
                              className="form-check-label custom-control-label"
                              htmlFor="inlineRadio2"
                            >
                              Privado
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-3">
                      <label className="fonte-label">Política de taxas</label>
                      <div
                        id="forms"
                        className="form-control"
                        // style={{ textAlign: "center" }}
                      >
                        <div className="custom-input custom-checkbox">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input custom-control-input"
                              type="radio"
                              name="taxes"
                              id="inlineRadio3"
                              checked={this.state.event.taxes === "Repassar"}
                              value="Repassar"
                              onChange={this.taxesChange}
                            />
                            <label
                              className="form-check-label custom-control-label"
                              htmlFor="inlineRadio3"
                              style={{ fontSize: "13px" }}
                            >
                              Repassar
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input custom-control-input"
                              type="radio"
                              name="taxes"
                              id="inlineRadio4"
                              checked={this.state.event.taxes === "Absorver"}
                              value="Absorver"
                              onChange={this.taxesChange}
                            />
                            <label
                              className="form-check-label custom-control-label"
                              htmlFor="inlineRadio4"
                              style={{ fontSize: "13px" }}
                            >
                              Absorver
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label className="fonte-label">
                        Nomenclatura dos bilhetes
                      </label>
                      <div className="input-group">
                        <select
                          id="inputCategoria"
                          onChange={(e) => {
                            let { event } = this.state;
                            event.ticket_nomenclature = e.target.value;
                            this.setState({
                              event,
                            });
                          }}
                          defaultValue={this.state.event.ticket_nomenclature}
                          className="form-control"
                        >
                          <option value="Ingresso">Ingressos</option>
                          {this.state.nomeclatures.map((item) => (
                            <option value={item.name}>{item.name}</option>
                          ))}
                          {/* <option value="Ingresso">Ingresso</option>
                          <option value="Inscrição">Inscrição</option>
                          <option value="Doação">Doação</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label className="fonte-label">Faixa etária</label>
                      <div className="input-group">
                        <input
                          id="inputAgeRange"
                          onChange={(e) => {
                            let { event } = this.state;
                            let { value } = e.target;
                            if (value < 0) {
                              value = 0;
                            } else if (value > 100) {
                              value = 100;
                            }
                            event.age_range = value;
                            this.setState({
                              event,
                            });
                          }}
                          value={this.state.event.age_range}
                          className="form-control"
                          type="number"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <b>Detalhes do evento</b>
                  <JoditEditor
                    // ref={editor}
                    defaultValue={this.state.event.description}
                    config={config}
                    onBlur={(newContent) => {}}
                    onChange={(newContent) => {
                      this.eventoDescricao(newContent);
                    }}
                  />
                  {/* <textarea className="form-control mb-3" rows="10"></textarea> */}
                </form>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                {this.state.visivel === 1 && (
                  <>
                    <b>Locais</b>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        {this.state.gmapsLoaded && (
                          <GooglePlacesAutocomplete
                            inputClassName="form-control"
                            placeholder="Buscar endereço"
                            onSelect={async ({ description }) => {
                              let { location } = this.state;
                              location.formatted_address = description;
                              // this.setState({ location });
                              let geocode = await geocodeByAddress(description);
                              if (geocode[0]) {
                                let { location } = this.state;
                                location.formatted_address =
                                  geocode[0].formatted_address;
                                geocode[0].address_components.forEach(
                                  (item) => {
                                    if (
                                      item.types.indexOf("street_number") > -1
                                    ) {
                                      location.number = item.short_name;
                                    }
                                    if (item.types.indexOf("route") > -1) {
                                      location.street = item.short_name;
                                    }
                                    if (
                                      item.types.indexOf(
                                        "sublocality_level_1"
                                      ) > -1
                                    ) {
                                      location.neighborhood = item.short_name;
                                    }
                                    if (
                                      item.types.indexOf(
                                        "administrative_area_level_2"
                                      ) > -1
                                    ) {
                                      location.city = item.short_name;
                                    }
                                    if (
                                      item.types.indexOf(
                                        "administrative_area_level_1"
                                      ) > -1
                                    ) {
                                      location.state = item.short_name;
                                    }
                                  }
                                );
                                let { lat, lng } = await getLatLng(geocode[0]);
                                let { is_online } = this.state;
                                location.lat = `${lat}`;
                                location.lng = `${lng}`;
                                if (is_online) {
                                  location = {
                                    number: ".",
                                    street: ".",
                                    neighborhood: ".",
                                    city: ".",
                                    state: ".",
                                    zip_code: ".",
                                    lat: ".",
                                    lng: ".",
                                  };
                                  this.setState({ location });
                                } else {
                                  this.setState({ location });
                                }
                              }
                            }}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["br"],
                              },
                            }}
                          />
                        )}
                      </div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          placeholder="Nome fantasia"
                          className="form-control"
                          value={this.state.location.name}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.name = "On-line";
                            } else {
                              location.name = e.target.value;
                            }
                            this.setState({ location });
                          }}
                        />
                      </div>
                      <div className="col-md-2">
                        <input
                          type="text"
                          placeholder="Número"
                          className="form-control"
                          name="number"
                          value={this.state.location.number}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.number = ".";
                            } else {
                              location.number = e.target.value;
                            }
                            this.setState({ location });
                          }}
                          // onChange={updateField}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          placeholder="Complemento"
                          className="form-control"
                          name="complement"
                          value={this.state.location.complement}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.complement = ".";
                            } else {
                              location.complement = e.target.value;
                            }
                            this.setState({ location });
                          }}
                          // onChange={updateField}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <button
                          className="btn c1"
                          onClick={this.addLocation}
                          style={{ width: "100%" }}
                          type="submit"
                        >
                          Adicionar Local
                        </button>
                      </div>
                      <div
                        style={{ visibility: "hidden", height: 0 }}
                        className="col-md-4"
                      >
                        <input
                          type="text"
                          placeholder="Cep"
                          className="form-control"
                          maxLength="9"
                          value={this.state.location.zip_code}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.zip_code = ".";
                            } else {
                              location.zip_code = mask(
                                e.target.value,
                                "#####-###"
                              );
                            }
                            this.setState({ location });
                          }}
                          // onBlur={this.fetchCEP}
                        />
                        <div className="invalid-cep hide" id="invalid-cep">
                          <span style={{ color: "#e63757" }}>
                            Cep Inválido.
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ visibility: "hidden", height: 0 }}
                        className="col-md-8"
                      >
                        <input
                          type="text"
                          placeholder="Rua"
                          className="form-control"
                          name="street"
                          value={this.state.location.street}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.street = ".";
                            } else {
                              location.street = e.target.value;
                            }
                            this.setState({ location });
                          }}
                          // onChange={updateField}
                        />
                      </div>
                    </div>
                    <div className="form-row mb-3">
                      <div
                        style={{ visibility: "hidden", height: 0 }}
                        className="col-md-6"
                      >
                        <input
                          type="text"
                          placeholder="Bairro"
                          className="form-control"
                          name="neighborhood"
                          value={this.state.location.neighborhood}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.neighborhood = ".";
                            } else {
                              location.neighborhood = e.target.value;
                            }
                            this.setState({
                              location,
                            });
                          }}
                          // onChange={updateField}
                        />
                      </div>
                      <div
                        style={{ visibility: "hidden", height: 0 }}
                        className="col-md-3"
                      >
                        <input
                          type="text"
                          placeholder="Estado"
                          className="form-control"
                          name="state"
                          value={this.state.location.state}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.state = ".";
                            } else {
                              location.state = e.target.value;
                            }
                            this.setState({ location });
                          }}
                          // onChange={updateField}
                        />
                      </div>
                      <div
                        style={{ visibility: "hidden", height: 0 }}
                        className="col-md-3"
                      >
                        <input
                          type="text"
                          placeholder="Cidade"
                          className="form-control"
                          name="city"
                          value={this.state.location.city}
                          onChange={(e) => {
                            let { location, is_online } = this.state;
                            if (is_online) {
                              location.city = ".";
                            } else {
                              location.city = e.target.value;
                            }
                            this.setState({ location });
                          }}
                          // onChange={updateField}
                        />
                      </div>

                      <div className="col-md-12">
                        Locais Adicionados:
                        <table
                          className="table table-striped"
                          style={{
                            display:
                              this.state.locations.length > 0 ? "" : "none",
                          }}
                        >
                          <tbody>
                            {this.state.locations.map((location, i) => (
                              <tr key={i}>
                                <td>{location.name}</td>
                                <td>{location.city}</td>
                                <td>{location.neighborhood}</td>
                                <td>{location.street}</td>
                                <td>{location.number}</td>
                                <td>{location.complement}</td>
                                <td
                                  className="event-bt-delete"
                                  onClick={() =>
                                    this.removeLocation(location.name)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}

                <hr></hr>
                <b>Datas</b>
                <div className="form-row">
                  <div className="col-md-3 mb-3">
                    <div className="input-group" style={{ padding: "0px" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text inputs hidecallendar">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                      </div>
                      <DatePicker
                        value={this.state.date.start_date}
                        showTimeSelect
                        onChange={(value) => {
                          let { date } = this.state;
                          date.start_date = value;
                          this.setState({ date });
                        }}
                        placeholderText="Data de Inicio"
                      />
                    </div>
                  </div>

                  <div className="col-md-3 mb-3">
                    <div className="input-group" style={{ padding: "0px" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text inputs hidecallendar">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                      </div>
                      <DatePicker
                        value={this.state.date.end_date}
                        showTimeSelect
                        onChange={(value) => {
                          let { date } = this.state;
                          date.end_date = value;
                          this.setState({ date });
                        }}
                        placeholderText="Data de Término"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <input
                      className="form-control"
                      placeholder="Nome fantasia da data"
                      onChange={(e) => {
                        let { date } = this.state;
                        date.name = e.target.value;
                        this.setState({ date });
                      }}
                      type="text"
                    />
                  </div>
                  <div
                    style={{ display: "none", height: 0 }}
                    className="col-md-3 mb-3"
                  >
                    <select
                      onChange={(e) => {
                        this.setState({ dateLocation: e.target.value });
                      }}
                      value={this.state.dateLocation}
                      className="form-control"
                    >
                      <option value="">Escolher local</option>
                      {this.state.locations.map((location, i) => (
                        <option key={i} value={JSON.stringify(location)}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <button
                      className="btn c1"
                      style={{ width: "100%" }}
                      onClick={this.addDate}
                    >
                      Adicionar data
                    </button>
                  </div>

                  <div className="col-md-12 event-extraspace">
                    Datas criadas:
                    <table
                      className="table table-striped"
                      style={{
                        display: this.state.locations.length > 0 ? "" : "none",
                      }}
                    >
                      <tbody>
                        {this.state.dates.map((date, i) => (
                          <tr key={i}>
                            <td>
                              {date.title} - {date.location.name}
                            </td>
                            {/* <td>
                            <button @click="removeLocation(i)">
                              Remover
                            </button>
                          </td>  */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
