// Common
import React, { useEffect, useRef, useState } from "react";

// Components
import { ToastContainer } from "react-toastr";
import SelectSearch from "react-select-search";
import Swal from "sweetalert2";

// Providers
import api from "../../../../services/api.js";
import { useOnClickOutside } from "App.js";

let container;

export default function ModalProductCategorie(props) {
  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close();
    }
  });

  const [categoriaArray, setCategoriaArray] = useState([]);
  const [category, setCategory] = useState({
    id: "",
    name: "",
    description: "",
    products_category_id: "",
    status: 1,
  });

  useEffect(() => {
    if (props.id) {
      api
        .get(`products/getCategorie/${props.id}`)
        .then((result) => {
          setCategory({
            id: result.data.data.id ? result.data.data.id : "",
            name: result.data.data.name ? result.data.data.name : "",
            description: result.data.data.description
              ? result.data.data.description
              : "",
            products_category_id: result.data.data.products_category_id
              ? String(result.data.data.products_category_id)
              : "",
            status: result.data.data.status ? result.data.data.status : "",
          });
        })
        .catch(() => {
          Swal.fire({
            title: 'Ops!',
            icon: 'error',
            text: 'Ocorreu um erro interno, por favor tente novamente',
            confirmButtonText: 'Ok'
          })
        })
    }

    api.get(`products/listCategories`).then((res) => {
      let temparray = [];
      res.data.forEach((dado) => {
        temparray.push({ name: dado.name, value: String(dado.id) });
      });

      setCategoriaArray(temparray);
    });
  }, [props.status, props.id]);

  // Update Generico para o Form
  const updateData = (e) => {
    setCategory({
      ...category,
      [e.target.name]: e.target.value,
    });
  };

  // Update usado para o SelectSearch
  const updateProductCategoryId = (e) => {
    setCategory({
      ...category,
      products_category_id: e,
    });
  };

  const close = () => {
    setCategory({
      id: "",
      name: "",
      description: "",
      products_category_id: "",
      status: 1,
    });
    props.callback("modal-hidden");
  };

  const salvar = () => {
    if (category.name === "") {
      container.error("Nome da categoria é obrigatório!");
      return;
    }

    if (category.id) {
      api
        .post("products/editCategorie", category)
        .then((res) => {
          container.success("Categoria criada com Sucesso!");
          close();
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    } else {
      api
        .post("products/createCategorie", category)
        .then((res) => {
          container.success("Categoria criada com Sucesso!");
          close();
        })
        .catch((err) => {
          container.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      <div
        id="venda-detalhe"
        ref={ref}
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />
        <div className="card-header">
          <h4 className="card-header-title">
            {category.id ? "Editar Categoria" : "Cadastrar Categoria"}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="name">Nome</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nome"
                    name="name"
                    value={category.name}
                    onChange={updateData}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="products_category_id">Categoria</label>
                  <SelectSearch
                    options={categoriaArray}
                    onChange={updateProductCategoryId}
                    search={true}
                    name="products_category_id"
                    value={category.products_category_id}
                    placeholder="Selecione a Categoria"
                    // className={validabankType?'select-search is-invalid':'select-search mb-0'}
                    className={"select-search mb-0"}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="status">Disponibilidade</label>
                  <select
                    className="form-control"
                    name="status"
                    value={category.status}
                    onChange={updateData}
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Desativado</option>
                  </select>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label htmlFor="description">Descrição</label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={category.description}
                    onChange={updateData}
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  {category.id ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => salvar()}
                    >
                      Editar
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => salvar()}
                    >
                      Salvar
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
