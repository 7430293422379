import axios from "axios";
import Swal from "sweetalert2";

export class IpService {
  static getMyIp = async () => {
    try {
      const result = await axios.get("https://ipapi.co/json/");
      return result.data.ip;
    } catch (error) {
      Swal.fire({
        title: "Ops!",
        icon: "error",
        text: "Ocorreu um erro interno, por favor tente novamente",
        confirmButtonText: "Ok",
      });
      throw error;
    }
  };
}
