import React from "react";
import SetorLoteTipo from "components/cadastroevento/setorLoteTipo";
import ConfiguracaoIngresso from "components/cadastroevento/configuracaoIngresso";

export default class Cadastroevento2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: this.props.dates,
      sector: { name: "" },
      sectors: this.props.sectors,
      lot: { name: "" },
      lots: this.props.lots,
      ticketTypes: this.props.ticketTypes,
      posicao: this.props.posicao,
    };
  }

  componentWillReceiveProps(props) {
    const { posicao, dates, sectors, lots } = props;
    this.setState({ posicao, dates, sectors, lots });
  }

  render() {
    return (
      <>
        {this.state.posicao === 2 && (
          <SetorLoteTipo
            sector={this.state.sector}
            sectors={this.state.sectors}
            lot={this.state.lot}
            lots={this.state.lots}
            ticketTypes={this.state.ticketTypes}
          />
        )}
        {this.state.posicao === 3 && (
          <ConfiguracaoIngresso dates={this.state.dates} />
        )}
      </>
    );
  }
}
