import React, { Fragment } from "react";
import {
  SearchBarElement,
  TableContainer,
  PaginationContainer,
} from "../../styles";
import Pagination from "../pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DebounceInput } from "react-debounce-input";
import { ReactComponent as ArrowHeadRight } from "../../../../../assets/icons/arrowHeadRight.svg";
import { ReactComponent as Download2 } from "../../../../../assets/icons/download2.svg";
import { dateFormatter } from "utils/date-formatter";
import { NavLink } from "react-router-dom";
import marketplace_ticket_api from "services/marketplace_ticket";
import ButtonContained from "components/buttonContained";
import ReturnStatus from "../return-status";
import { useTranslation } from "react-i18next";

const TransfersPanel = ({
  ticketTransfers,
  pageInfo,
  handleNavigation,
  isMobile,
  updateFilter,
  handleOpenDrawerClick,
  t,
  language,
  event,
  setPageLimit,
}) => {
  const { i18n } = useTranslation();

  const fetchCSV = async () => {
    let apiUrl = `api/v1/ticket-transfers/event/${event}/csv?lang=${i18n.language}`;
    try {
      const response = await marketplace_ticket_api.get(apiUrl, {
        responseType: "blob",
      });
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `ticket_transfers_${event}.csv`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
    }
  };

  return (
    <Fragment>
      <SearchBarElement>
        <div className="utils">
          <div className="card-search">
            <FontAwesomeIcon icon={faSearch} className="icon-search" />
            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              className="input-custom"
              onChange={updateFilter}
              autoComplete="off"
              placeholder={t("nominalTransfers.searchPlaceholder")}
            />
          </div>
          <ButtonContained
            className="secondary-outlined"
            onClick={fetchCSV}
            content={
              <Fragment>
                <Download2 stroke="#0050C3" />{" "}
                {t("nominalTransfers.exportButton")}
              </Fragment>
            }
            disabled={ticketTransfers?.length === 0}
          />
        </div>
      </SearchBarElement>
      <TableContainer>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th style={{ width: "150px" }}>
                  {t("nominalTransfers.columns.ticketCode")}
                </th>
                <th style={{ width: "150px" }}>
                  {t("nominalTransfers.columns.orderNumber")}
                </th>
                <th style={{ width: "300px" }}>
                  {t("nominalTransfers.columns.sender")}
                </th>
                <th style={{ width: "300px" }}>
                  {t("nominalTransfers.columns.receiver")}
                </th>
                <th style={{ width: "120px" }}>
                  {t("nominalTransfers.columns.receivedOn")}
                </th>
                <th style={{ width: "130px" }}>
                  {t("nominalTransfers.columns.ticketType")}
                </th>
                <th style={{ width: "120px" }}>
                  {t("nominalTransfers.columns.status")}
                </th>
                <th style={{ width: "50px" }}></th>
              </tr>
            </thead>
            <tbody>
              {ticketTransfers?.length === 0 ? (
                <tr className="empty-row">
                  <td colSpan={8}>{t("nominalTransfers.emptyList")}</td>
                </tr>
              ) : (
                ticketTransfers.map((item, index) => (
                  <tr key={index}>
                    <td>{item.code}</td>
                    <td>
                      <NavLink
                        to={`/tickets/vendas?event_id=${event}&orderNumber=${item.order.tokenTicket}`}
                        style={{ textDecoration: "underline" }}
                      >
                        {item.order.tokenTicket}
                      </NavLink>
                    </td>
                    <td className={!isMobile ? "breakable" : ""}>
                      {item.user.email}
                    </td>
                    <td className={!isMobile ? "breakable" : ""}>
                      {item.destinationEmail}
                    </td>
                    <td>
                      {dateFormatter(item.dateTransfer, {
                        shouldShowTime: false,
                        locale: language,
                      })}
                    </td>
                    <td>{item.ticket.ticketType.name}</td>
                    <td>
                      {<ReturnStatus status={item.transferStatus} t={t} />}
                    </td>
                    <td>
                      <button
                        id={`details-button-${index}`}
                        onClick={() => handleOpenDrawerClick(item)}
                      >
                        <ArrowHeadRight
                          stroke={"#0050C3"}
                          strokeWidth={"1.5px"}
                        />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <PaginationContainer>
          <Pagination
            pageInfo={pageInfo}
            handleNavigation={handleNavigation}
            isMobile={isMobile}
            setPageLimit={setPageLimit}
          />
        </PaginationContainer>
      </TableContainer>
    </Fragment>
  );
};

export default TransfersPanel;
