import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import { useResponsive } from "hooks/responsive";
import { datepickerHelper } from "utils/date-picker";

import { Container, LeftIcon, RightIcon } from "./styles";

/**
 * @typedef {import("react").CSSProperties & {
 *   customProp?: string;
 * }} CustomCSSProperties
 */

/**
 * @typedef {Object} DatePickerProps
 * @property {string} [className]
 * @property {boolean} [showTimeSelect=false]
 * @property {Date} [value]
 * @property {function} [onChange]
 * @property {string} [placeholder]
 * @property {string} [placeholderText]
 * @property {string} [name]
 * @property {number} [timeInterval]
 * @property {boolean} [disabled]
 * @property {boolean} [onlyTime]
 * @property {Date} [minDate]
 * @property {Date} [maxDate]
 * @property {Date} [minTime]
 * @property {Date} [maxTime]
 * @property {boolean} [showTime]
 * @property {boolean} [required]
 * @property {boolean} [leftIcon]
 * @property {boolean} [rightIcon]
 * @property {boolean} [todayButton]
 * @property {string} [label]
 * @property {string} [labelClassName]
 * @property {string} [autoComplete]
 * @property {string} [error]
 * @property {JSX.Element} [leftIconChild]
 * @property {JSX.Element} [rightIconChild]
 * @property {function} [onFocus]
 * @property {'calendar'|'clock'} [iconType]
 * @property {('left' | 'center' | 'right')} [textAlign]
 * @property {(string | number | string[] | number[])} [marginLeft] - Accepts a string or responsive [md, lg, xl]
 * @property {(string | number | string[] | number[])} [marginRight] - Accepts a string or responsive [md, lg, xl]
 * @property {function} [onKeyDown]
 * @property {CustomCSSProperties} [style]
 * @property {CustomCSSProperties} [labelStyle]
 * @property {boolean} [showTimeInput]
 * @property {boolean} [showYearDropdown]
 * @property {any} [register]
 * @property {boolean} [readOnly]
 * @property {'square' | 'rounded'} [borderVariant]
 * @param {DatePickerProps} props
 * @returns {JSX.Element}
 *
 */
export function DatePicker({
  showTimeSelect = false,
  value,
  onChange,
  placeholder,
  name,
  timeInterval,
  disabled = false,
  onlyTime = false,
  maxDate,
  maxTime,
  minDate,
  minTime,
  showTime,
  required,
  leftIcon,
  label,
  marginLeft,
  marginRight,
  iconType,
  className,
  onFocus,
  onBlur,
  style,
  textAlign,
  onKeyDown,
  placeholderText,
  showTimeInput,
  showYearDropdown,
  rightIcon,
  todayButton,
  labelClassName,
  labelStyle,
  error,
  leftIconChild,
  rightIconChild,
  autoComplete,
  register,
  readOnly,
  format,
  borderVariant,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    hourFormat,
    locale,
    dateFormat: timeFormat,
  } = useMemo(() => {
    let shouldShowTime = showTime;
    if (showTimeSelect && showTime === undefined) {
      shouldShowTime = true;
    }
    return datepickerHelper(language, shouldShowTime);
  }, [language, showTime, showTimeSelect]);
  const [leftMargin, setLeftMargin] = useState(marginLeft);
  const { isLg, isMd, isSm, isXl, isXs } = useResponsive();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (Array.isArray(marginLeft)) {
      if (isMd) setLeftMargin(marginLeft[0]);
      if (isLg) setLeftMargin(marginLeft[1]);
      if (isXl) setLeftMargin(marginLeft[2]);
      if (isSm || isXs) setLeftMargin(0);
    } else {
      setLeftMargin(marginLeft);
    }
  }, [marginLeft, isSm, isMd, isLg, isXl, isXs]);

  const ref = useRef(null);
  const openDatePicker = () => {
    const datepickerElement = ref.current;
    datepickerElement.setFocus(true);
  };

  return (
    <>
      {label && (
        <div style={labelStyle}>
          <label
            htmlFor={name}
            className={labelClassName ? labelClassName : "form-label"}
          >
            {label}
          </label>
        </div>
      )}
      <Container
        onClick={openDatePicker}
        hasChild={!!leftIconChild}
        leftIcon={leftIconChild || leftIcon}
        rightIcon={rightIcon || rightIconChild}
        marginLeft={leftMargin}
        marginRight={marginRight}
        className={className}
        style={style}
        disabled={disabled}
        isActive={isActive}
        error={!!error}
        rounded={borderVariant === "rounded"}
      >
        {(leftIcon || leftIconChild) && (
          <LeftIcon hasChild={!!leftIconChild}>
            <div>
              {leftIconChild ? (
                leftIconChild
              ) : (
                <FontAwesomeIcon
                  icon={iconType === "clock" ? faClock : faCalendarAlt}
                  className="icon"
                />
              )}
            </div>
          </LeftIcon>
        )}
        <div className="date-container">
          <ReactDatePicker
            ref={ref}
            id="date-picker-component"
            onFocus={() => {
              onFocus && onFocus();
              setIsActive(true);
            }}
            onBlur={() => {
              onBlur && onBlur();
              setIsActive(false);
            }}
            {...register}
            onKeyDown={onKeyDown}
            showTimeInput={showTimeInput}
            showYearDropdown={showYearDropdown}
            minDate={minDate}
            maxDate={maxDate}
            minTime={minTime}
            readOnly={readOnly}
            maxTime={maxTime}
            required={required}
            todayButton={todayButton && t("components:datepicker.todayButton")}
            name={name}
            withPortal={isSm || isXs}
            disabled={disabled}
            locale={locale}
            showTimeSelectOnly={onlyTime}
            showTimeSelect={showTimeSelect}
            timeFormat={hourFormat}
            timeIntervals={timeInterval || 15}
            timeCaption={t("components:datepicker.timeCaption")}
            dateFormat={onlyTime ? hourFormat : timeFormat}
            selected={value}
            autoComplete={autoComplete ?? "off"}
            value={value}
            onChange={onChange}
            placeholderText={placeholder || placeholderText || undefined}
            customInput={
              <input
                style={{
                  cursor: "pointer",
                  textAlign,
                  marginLeft: !!leftIconChild ? -5 : undefined,
                }}
              />
            }
          />
        </div>
        {(rightIcon || rightIconChild) && (
          <RightIcon hasChild={!!rightIconChild}>
            <div>
              {rightIconChild ? (
                rightIconChild
              ) : (
                <FontAwesomeIcon
                  icon={iconType === "clock" ? faClock : faCalendarAlt}
                  className="icon"
                />
              )}
            </div>
          </RightIcon>
        )}
      </Container>
      {error && (
        <h6 className="text-danger ml-2 mt-1">
          <small>{error}</small>
        </h6>
      )}
    </>
  );
}
