import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";

InputCheckbox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

function InputCheckbox(props) {
  const { className, value, name, active, onClick, disabled } = props;
  return (
    // eslint-disable-next-line
    <div className={className} onClick={() => onClick(value)}>
      <div
        className={`${active ? "active " : ""}custom-input-checkbox${
          disabled ? "-disabled" : ""
        }`}
      >
        {active ? (
          <FontAwesomeIcon icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon icon={faCircle} />
        )}
        <span className="ml-2">{name}</span>
      </div>
    </div>
  );
}

export default InputCheckbox;
