import React, { useRef } from "react";
import EmptyData from "components/empty-data";
import { useOnClickOutside } from "App";
import "./styles.css";
import { useTranslation } from "react-i18next";
import EventStatus from "components/eventStatusBadge";
import { dateFormatter } from "utils/date-formatter";

const ModalHistory = (props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const getEventData = props.events?.find((e) => e.id === props.idEvent);
  const eventStatusLog = getEventData?.eventStatusLog;

  // Click outside
  let ref = useRef();
  useOnClickOutside(ref, () => {
    if (props.status === "modal-show") {
      close();
    }
  });

  function close() {
    props.callback("modal-hidden");
  }

  return (
    <div
      ref={ref}
      className={"shadow side-modal " + props.status}
      role="dialog"
    >
      <div className="col-12">
        <div className="card-header container-register-user">
          <h4 className="card-header-title">
            {t("events:eventList.eventHistoryModal.title")}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => close(e)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="pb-2">
          {t("events:eventList.eventHistoryModal.eventCreationText")}:{" "}
          {dateFormatter(getEventData?.created_at, {
            locale: language,
            shouldShowTime: true,
          })}
        </div>
        {eventStatusLog?.length === 0 ? (
          <EmptyData title={t("global:EmptyDataText")} />
        ) : (
          <table className="table table-sm table-bordered">
            <thead className="">
              <tr>
                <th className="text-center" scope="col">
                  {t("global:Name")}
                </th>
                <th className="text-center" scope="col">
                  {t("global:EmailWithHifen")}
                </th>
                <th className="text-center" scope="col">
                  {t("global:From")}
                </th>
                <th className="text-center" scope="col">
                  {t("global:To")}
                </th>
                <th className="text-center" scope="col">
                  {t("global:Date")}
                </th>
              </tr>
            </thead>

            <tbody>
              {eventStatusLog?.map((eventStatusLog, i) => (
                <tr key={i}>
                  <td className="text-center">{eventStatusLog.user_name}</td>
                  <td className="text-center">{eventStatusLog.email}</td>
                  <td className="text-center">
                    <EventStatus status={eventStatusLog.from} />
                  </td>
                  <td className="text-center">
                    <EventStatus status={eventStatusLog.to} />
                  </td>
                  <td className="text-center">
                    {dateFormatter(eventStatusLog.created_at, {
                      locale: language,
                      shouldShowTime: true,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ModalHistory;
