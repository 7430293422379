import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Badge from "components/badge";
import ButtonContained from "components/buttonContained";
import FormField from "components/FormField";
import NewSelect from "components/newSelect";
import RadioButton from "components/radioButton";
import api from "services/api";
import { ValidationsHelper } from "services/validations";

import { ReactComponent as ArrowRight } from "../../../assets/icons/ArrowRight.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/checkCircle.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/link-icon.svg";

import "./styles.css";

const MIN_LENGTH_CHARACTERS = 3;
const MAX_LENGTH_CHARACTERS = 100;

const MAX_LENGTH_CHARACTERS_INFORMATIONS = 250;

const formSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(
      MIN_LENGTH_CHARACTERS,
      `O nome deve conter no mínimo ${MIN_LENGTH_CHARACTERS} caracteres.`
    )
    .max(
      MAX_LENGTH_CHARACTERS,
      `O limite máximo de caracteres é ${MAX_LENGTH_CHARACTERS}.`
    )
    .required("O nome é obrigatório."),
  address: yup
    .string()
    .trim()
    .required("O endereço é obrigatório.")
    .test(
      "isValidAddress",
      "Favor inserir um link válido.",
      (value) => !value || ValidationsHelper.isWebLinkValid(value)
    ),
  expected: yup
    .mixed()
    .oneOf(["true", "false"])
    .required("O endereço é obrigatório."),
  action_type_id: yup.string().trim(),
  discount_id: yup.number(),
  informations: yup.string().trim(),
  verb: yup.mixed().oneOf(["get", "post"]),
});

InformationsConfig.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    action_type_id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    discount_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    expected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
      .isRequired,
    informations: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    verb: PropTypes.oneOf(["GET", "POST", "get", "post"]).isRequired,
  }),
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default function InformationsConfig({
  onSubmit,
  defaultValues,
  eventId,
}) {
  const { discount_id, verb } = defaultValues;
  // const [answer, setAnswer] = useState(expected);
  const [requestType, setRequestType] = useState(verb || "get");
  const [options, setOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(null);

  // Hook-Form
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  const name = watch("name");

  const informations = watch("informations");

  // const handleAnswerField = (event) => {
  //   const { value } = event.target;
  //   setValue("expected", value);
  //   setAnswer(value);
  // };

  const handlePromocionalCode = (item) => {
    setSelectValue(item);
    setValue("discount_id", item.value);
  };

  const submit = (form) => {
    onSubmit(form);
  };

  const handleRequestType = (event) => {
    setValue("verb", event.target.value);
    setRequestType(event.target.value);
  };

  useEffect(() => {
    api
      .get(`discount/get-by-event/${eventId}`)
      .then((res) => {
        const { descontos } = res.data;
        if (descontos.length > 0) {
          if (!discount_id) {
            const { id, code } = descontos[0];
            setSelectValue({
              value: id,
              label: code,
            });
            setValue("discount_id", descontos[0].id);
          }
          setOptions(
            descontos.map(({ id, code }) => ({
              value: id,
              label: code,
            }))
          );
          if (discount_id) {
            const { id, code } = descontos.find(
              (discount) => discount.id === defaultValues.discount_id
            );
            setSelectValue({ value: id, label: code });
            setValue("discount_id", id);
          }
        }
      })
      .catch(() => setOptions([]));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="integration-body-container">
      <div className="integration-body-title">Configurar informações.</div>
      <form onSubmit={handleSubmit(submit)}>
        <FormField
          classNameGroup="mb-0"
          classNameLabel="integration-label-input"
          label="Insira um nome para a integração"
          controlId="validationName"
        >
          <Form.Control
            name="name"
            className="integration-field-custom"
            maxLength={MAX_LENGTH_CHARACTERS}
            {...register("name")}
            type="name"
            placeholder="Nome da integração"
            isInvalid={errors.name?.message}
          />
          <div className="row justify-content-between">
            <div className="col-6">
              <p className="integration-name-field-error">
                {errors.name?.message}
              </p>
            </div>
            <div className="col-md-3 col-6">
              <p className="text-right characters-integration-input">
                {MAX_LENGTH_CHARACTERS - name.length} caracteres restantes
              </p>
            </div>
          </div>
        </FormField>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormField
              classNameGroup="mb-0"
              classNameLabel="integration-label-input"
              label={
                <span>
                  Endereço de consulta
                  <p className="integration-label-info">
                    Iremos consultar esse endpoint para verificar o valor e
                    executar a ação.
                  </p>
                </span>
              }
              controlId="validationAddress"
            >
              <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <LinkIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name="address"
                  style={{
                    paddingLeft: "0",
                    ...(!errors.address?.message && { borderLeft: "none" }),
                  }}
                  className="integration-field-custom"
                  {...register("address")}
                  placeholder="Insira a URL"
                  isInvalid={errors.address?.message}
                />
              </InputGroup>
              <p className="integration-name-field-error">
                {errors.address?.message}
              </p>
            </FormField>
          </div>
          <div className="col-12 col-md-6">
            <FormField
              classNameGroup="mb-0"
              classNameLabel="integration-label-input"
              label={
                <span>
                  Tipo de requisição
                  <p className="integration-label-info">
                    Escolha se a integração espera receber dados (GET) ou enviar
                    dados (POST).
                  </p>
                </span>
              }
              controlId="validationExpectedAnswer"
            >
              <div className="row pl-3 integration-radiobutton-container">
                <RadioButton
                  id="get"
                  name="get"
                  text="GET"
                  value="get"
                  onChange={handleRequestType}
                  checked={requestType === "get"}
                />
                <RadioButton
                  id="post"
                  name="post"
                  text="POST"
                  value="post"
                  onChange={handleRequestType}
                  checked={requestType === "post"}
                />
              </div>
            </FormField>
          </div>
          {/* <div className="col-12 col-md-6">
            <FormField
              classNameGroup="mb-0"
              classNameLabel="integration-label-input"
              label={
                <span>
                  Resposta esperada
                  <p className="integration-label-info">
                    Escolha a partir de qual tipo de resposta iremos executar a
                    ação.
                  </p>
                </span>
              }
              controlId="validationExpectedAnswer"
            >
              <div className="row pl-3 integration-radiobutton-container">
                <RadioButton
                  id="truth"
                  name="truth"
                  text="Verdadeiro"
                  value={true}
                  onChange={handleAnswerField}
                  checked={answer === "true"}
                />
                <RadioButton
                  id="false"
                  name="false"
                  text="Falso"
                  value={false}
                  onChange={handleAnswerField}
                  checked={answer === "false"}
                />
              </div>
            </FormField>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormField
              classNameGroup="mb-0"
              classNameLabel="integration-label-input"
              label={
                <div className="d-flex">
                  Escolha a ação esperada
                  <Badge
                    className="card-badge mb-1 ml-2"
                    status="info"
                    text="NOVAS EM BREVE"
                  />
                </div>
              }
              errorMessage={errors.action_type_id?.message}
              controlId="validationExpectedAction"
            >
              <InputGroup>
                <Form.Control
                  style={{
                    borderRight: "none",
                  }}
                  className="integration-action-type-field"
                  name="action_type_id"
                  {...register("action_type_id")}
                  disabled
                  placeholder="Habilitar cupom de desconto"
                  isInvalid={errors.action_type_id?.message}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      backgroundColor: "transparent",
                      borderLeft: "none",
                      borderRadius: "0 0.375rem 0.375rem 0",
                    }}
                  >
                    <CheckIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </FormField>
          </div>
          <div className="col-12 col-md-6">
            <FormField
              classNameGroup="mb-0"
              classNameLabel="integration-label-input"
              label="Selecione o cupom que será aplicado"
              controlId="validationDiscount"
            >
              <NewSelect
                options={options}
                value={selectValue}
                placeholder="Selecione o cupom"
                onChange={handlePromocionalCode}
              />
            </FormField>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <FormField
              classNameGroup="mb-0"
              classNameLabel="integration-label-input"
              label={
                <span>
                  Descrição do benefício (opcional)
                  <p className="integration-label-info">
                    Essas informações serão mostradas para seu cliente no
                    momento da compra.
                  </p>
                </span>
              }
              controlId="validationInformation"
            >
              <Form.Control
                name="informations"
                className="integration-field-custom"
                maxLength={MAX_LENGTH_CHARACTERS_INFORMATIONS}
                {...register("informations")}
                type="text"
                placeholder="Exemplo: “Cupom válido para clientes cadastrados”."
              />
              <p className="text-right characters-integration-input">
                {MAX_LENGTH_CHARACTERS_INFORMATIONS - informations?.length}{" "}
                caracteres restantes
              </p>
            </FormField>
          </div>
        </div>
        <div className="row pt-3 justify-content-end">
          <div className="col-12 col-md-3">
            <ButtonContained
              type="submit"
              disabled={!isValid}
              className={isValid ? "primary-contained" : "disabled-contained-2"}
              content={
                <Fragment>
                  Avançar <ArrowRight stroke={isValid ? "white" : "black"} />
                </Fragment>
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
}
