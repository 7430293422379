import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import api from "services/api";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

let container;

const ModalToken = (props) => {
  const timeout = 60;
  const { t } = useTranslation(["global", "equipments"]);
  const [tokenArray, setTokenArray] = useState([]);
  const [token, setToken] = useState(0);
  const [counter, setCounter] = useState(0);
  const [start, setStart] = useState(false);
  const [countInterval, setCountInterval] = useState();
  const [checkInterval, setCheckInterval] = useState();
  const [validate, setValidate] = useState(false);

  function closeModal(value) {
    if (validate || value === "closed" || value === "closedcheck") {
      props.callback("modal-hidden");
    }
    setTokenArray([]);
    setCounter(0);
    setToken(0);
    setStart(false);
    if (token && value !== "closedcheck") {
      deleteToken();
    }
    if (countInterval) {
      clearInterval(countInterval);
    }
    if (checkInterval) {
      clearInterval(checkInterval);
    }
    setCountInterval(undefined);
    setCheckInterval(undefined);
  }

  const tokenGenerator = () => {
    api
      .get(`codeGenerator/${props.userId}`)
      .then((res) => {
        setToken(res.data);
        convertToken(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("internalServerError", { ns: "global" }),
          confirmButtonText: t("confirmButtonText", { ns: "global" }),
        });
      });
  };

  const convertToken = (number) => {
    const numToSeparate = number;

    const arrayOfDigits = Array.from(String(numToSeparate), Number);
    setTokenArray(arrayOfDigits);
  };

  const deleteToken = () => {
    if (token) {
      api
        .get(`deleteToken/${token}`)
        .then((res) => {
          clearInterval(countInterval);
          clearInterval(checkInterval);
          setTokenArray([]);
          setToken(0);
          setCounter(0);
          if (!validate) {
            container.error(
              t("myEquipments.modalToken.deletedCode", { ns: "equipments" })
            );
          }
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: t("internalServerError", { ns: "global" }),
            confirmButtonText: t("confirmButtonText", { ns: "global" }),
          });
        });
    }
  };

  useEffect(() => {
    if (start) {
      const id = setInterval(() => {
        setCounter((count) => {
          if (count >= timeout) {
            return setStart(false);
          } else {
            return count + 1;
          }
        });
      }, 1000);

      setCountInterval(id);
    } else {
      if (token) {
        clearInterval(countInterval);
        clearInterval(checkInterval);
        setTokenArray([]);
        setToken(0);
        setCounter(0);
        // deleteToken();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    if (token) {
      setStart(true);
      const check = setInterval(() => {
        api
          .get(`checkToken/${token}`)
          .then((res) => {
            if (
              res.data.message ===
              t("myEquipments.modalToken.successRegistered", {
                ns: "equipments",
              })
            ) {
              setToken(0);
              setValidate(true);
              closeModal("closedcheck");
              container.success(res.data.message);
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: t("internalServerError", { ns: "global" }),
              confirmButtonText: t("confirmButtonText", { ns: "global" }),
            });
          });
      }, 10000);

      setCheckInterval(check);
    } else {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div className={"shadow side-modal " + props.status} role="dialog">
        <div className="w-100 mt-2">
          <div className="card-header">
            <TitleModal>
              {t("myEquipments.modalToken.myDevices", { ns: "equipments" })}
            </TitleModal>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => closeModal("closed")}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="container-fluid mt-2">
            <TituloConteudoModal>
              {t("myEquipments.modalToken.title", { ns: "equipments" })}
            </TituloConteudoModal>
            <div className="equipment-content ml-3 mt-2">
              <TextConteudo>
                {t("myEquipments.modalToken.register-content", {
                  ns: "equipments",
                })}
              </TextConteudo>
            </div>
            <div className="col-md-12 ml-3 mt-3">
              <div className="col-md-12 mt-3 text-center">
                {token ? (
                  <ButtonDispositivo
                    className="col-md-5 col-12"
                    onClick={tokenGenerator}
                    disabled
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ marginRight: "5px" }}
                    />
                    {t("myEquipments.modalToken.generate-token", {
                      ns: "equipments",
                    })}
                  </ButtonDispositivo>
                ) : (
                  <ButtonDispositivo
                    className="col-md-5 col-12"
                    onClick={tokenGenerator}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ marginRight: "5px" }}
                    />
                    {t("myEquipments.modalToken.generate-token", {
                      ns: "equipments",
                    })}
                  </ButtonDispositivo>
                )}
              </div>
              <div className="col-md-12 mt-5 ml-2 text-center">
                <div
                  className="row token-screen"
                  style={{ justifyContent: "center" }}
                >
                  {tokenArray &&
                    tokenArray.map((item, i) => (
                      <TokenExpiration
                        disabled
                        key={i}
                        className="col-md-1 col-1 mx-2 text-center"
                      >
                        <TokenText>{item}</TokenText>
                      </TokenExpiration>
                    ))}
                </div>
              </div>
            </div>
            {token ? (
              <div className="col-md-12 modalCircularProg text-center">
                <CircularProgressbar
                  value={counter}
                  maxValue={timeout}
                  text={`${counter}`}
                  styles={buildStyles({
                    pathColor: `rgb(0 165 227)`,
                    textColor: "rgb(0 165 227)b",
                  })}
                  counterClockwise
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalToken;

const TitleModal = styled.span`
  color: #98afbc;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
`;

const TituloConteudoModal = styled.span`
  color: #00a7e5;
  text-align: center;
  font-size: 25px;
  white-space: nowrap;
  font-family: Rubik;
  font-weight: 500;
  padding: 0 10px;
`;

const TextConteudo = styled.span`
  color: #98afbc;
  text-align: left;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.5px;
`;

const ButtonDispositivo = styled.button`
  border: 1px solid #00a7e5;
  color: #fff;
  background-color: #00a7e5;
  border-radius: 5px;
  height: 40px;
`;

const TokenExpiration = styled.button`
  color: #98afbc;
  background-color: #e6f0f6;
  border-radius: 8px;
  height: 46px;
  width: 44px;
  font-weight: 800;
  border: none;
  text-align: center;
`;

const TokenText = styled.span`
  display: flex;
  position: relative;
  font-family: Rubik;
  font-size: 20px;
  color: #98afbc;
  justify-content: center;
  /* padding: 7px 7px;
  @media screen and (max-width: 768px) {
    padding: 7px 0;
  } */
`;
